import * as d3 from 'd3';
import Chart from '../../chart';
import Color from '../../visualization/color';
import Size from '../../visualization/size'
import Style from '../../visualization/style';

import metaphor29 from '../../metaphor/metaphor29.png';

const fontSize = {
    "small": {
        "axis": 12
    },
    "middle": {
        "axis": 14
    },
    "wide": {
        "axis": 16
    },
    "large": {
        "axis": 20
    }
}

const chartMargin = {
    "small": {
        "top": 5, 
        "right": 5, 
        "bottom": 20, 
        "left": 20
    },
    "middle": {
        "top": 10,
        "right": 10,
        "bottom": 24,
        "left": 24
    },
    "wide": {
        "top": 10,
        "right": 10,
        "bottom": 26,
        "left": 26
    },
    "large": {
        "top": 20,
        "right": 20,
        "bottom": 40,
        "left": 40
    }
};

const getLeastSquares = (X, Y) => {
    let ret = {}

    let sumX = 0
    let sumY = 0
    let sumXY = 0
    let sumXSq = 0
    let N = X.length

    for (let i = 0; i < N; ++i) {
        sumX += X[i]
        sumY += Y[i]
        sumXY += X[i] * Y[i]
        sumXSq += X[i] * X[i]
    }

    ret.m = ((sumXY - sumX * sumY / N)) / (sumXSq - sumX * sumX / N)
    ret.b = sumY / N - ret.m * sumX / N

    return ret;
}

function basicDraw(factData, xEncoding, yEncoding, svg, width, height) {
    let axis = svg.append("g")
        .attr("class", "axis"),
        content = svg.append("g")
            .attr("class", "content")
            .attr("chartWidth", width)
            .attr("chartHeight", height);

    // set the ranges
    let xScale = d3.scaleLinear()
        .range([0, width])
        .domain([0, d3.max(factData, d => d[xEncoding])])
        .nice();

    let yScale = d3.scaleLinear()
        .range([height, 0])
        .domain([0, d3.max(factData, d => d[yEncoding])])
        .nice();

    let axisX = d3.axisBottom(xScale)
        .ticks(5)
        .tickPadding(5)
        .tickFormat(function (d) {
            if ((d / 1000000) >= 1) {
                d = d / 1000000 + "M";
            } else if ((d / 1000) >= 1) {
                d = d / 1000 + "K";
            }
            return d;
        });

    let axisY = d3.axisLeft(yScale)
        .ticks(5)
        .tickPadding(5)
        .tickFormat(function (d) {
            if ((d / 1000000) >= 1) {
                d = d / 1000000 + "M";
            } else if ((d / 1000) >= 1) {
                d = d / 1000 + "K";
            }
            return d;
        });

    axis.append("g")
        .attr("class", "axis_x")
        .attr('transform', `translate(0, ${height})`)
        .call(axisX)

    axis.append("g")
        .attr("class", "axis_y")
        .call(axisY);

    /* draw points */
    const circleSize = Math.min(Math.ceil(Math.sqrt(height * width) / 50), 7);
    content.append("g")
        .selectAll("circle")
        .data(factData)
        .enter().append("circle")
        .attr("class", "data_item")
        .attr("r", circleSize)
        .attr("stroke", "#FFF")
        .attr("stroke-width", 0)
        .attr("fill", Color.DEFAULT)
        .attr("opacity", 0.6)
        .attr("cx", d => xScale(d[xEncoding]))
        .attr("cy", d => yScale(d[yEncoding]));

    return svg;
}

const NUMFONT = "Arial-Regular";
class Scatterplot extends Chart {
    animateTrend() {
        const chartMargin = {
            "small": {
                "top": 10,
                "right": 10,
                "bottom": 30,
                "left": 50
            },
            "middle": {
                "top": 20,
                "right": 20,
                "bottom": 40,
                "left": 40
            },
            "wide": {
                "top": 20,
                "right": 20,
                "bottom": 40,
                "left": 50
            },
            "large": {
                "top": 30,
                "right": 50,
                "bottom": 50,
                "left": 60
            }
        };
        /* -------------------------------- init data ------------------------------- */
        const factData = this.factdata(),
            measure = this.measure(),
            breakdown = this.breakdown(),
            container = this.container(),
            size = this.size();
    
        if (measure.length < 2 || breakdown.length < 2) return;

        let seriesData = d3.nest()
                            .key(function(d) {  return d[breakdown[1].field];})
                            .entries(factData);

        let duration = this.duration();
        let tick = duration / seriesData.length;
        let data_index = 0;

        
        for(let i=0; i<factData.length; i++) {
            factData[i]["Recovered"] = Math.floor(Math.random() * 11);  
        }

        /* ----------------------- graph set up (size, margin) ---------------------- */
        const margin = chartMargin[size],
            width = this.width() - margin.left - margin.right,
            height = this.height() - margin.top - margin.bottom,
            font = fontSize[size],
            strokeWidth = size === Size.SMALL || size === Size.MIDDLE ? 1 : 2;
        /* ----------------------------- data prosessing ---------------------------- */
        //association需两个measure
        const xEncoding = "measure0:" + (measure[0].aggregate === "count" ? "COUNT" : measure[0].field),
            yEncoding = "measure1:" + (measure[1].aggregate === "count" ? "COUNT" : measure[1].field);
        /* ----------------------------------- vis ---------------------------------- */
        let svg = d3.select(container)
                    .append("svg")
                    .attr("width", width + margin.left + margin.right)
                    .attr("height", height + margin.top + margin.bottom)
                    .append("g")
                    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        let axis = svg.append("g")
                        .attr("class", "axis"),
        content = svg.append("g")
                    .attr("class", "content")
                    .attr("chartWidth", width)
                    .attr("chartHeight", height);

        // set the ranges
        let xScale = d3.scaleLinear()
            .range([0, width])
            .domain([0, 1.1 * d3.max(factData, d => d[xEncoding])])
            .nice();

        let yScale = d3.scaleLinear()
            .range([height, 0])
            .domain([0, 1.1 * d3.max(factData, d => d[yEncoding])])
            .nice();
        
        let axisX = d3.axisBottom(xScale)
            .ticks(5)
            .tickPadding(5)
            .tickFormat(function (d) {
                if ((d / 1000000) >= 1) {
                    d = d / 1000000 + "M";
                } else if ((d / 1000) >= 1) {
                    d = d / 1000 + "K";
                }
                return d;
            });

        let axisY = d3.axisLeft(yScale)
            .ticks(5)
            .tickPadding(5)
            .tickFormat(function (d) {
                if ((d / 1000000) >= 1) {
                    d = d / 1000000 + "M";
                } else if ((d / 1000) >= 1) {
                    d = d / 1000 + "K";
                }
                return d;
            });

        axis.append("g")
            .attr("class", "axis_x")
            .attr('transform', `translate(0, ${height})`)
            .call(axisX)
            .call(g=>{
                g.selectAll(".tick")
                 .filter((d,i) => i === g.selectAll(".tick").size()-1)
                 .remove();

                g.selectAll(".tick")
                    .selectAll("line")
                    .attr("y2", this.height() === 640 ? 6 : 6 * this.height() / 320)
                    .attr("stroke-width", strokeWidth);

                g.selectAll("text")
                    .attr("y", this.height() === 640 ? 9 : 9 * this.height() / 320);
                g.attr('font-size', font.axis)

                let domainD = g.selectAll(".domain").attr("d");
                let start = domainD.split(",")[0].substr(1);
                let end = domainD.split("H")[1].split("V")[0];
                g.selectAll(".domain")
                    .attr("stroke-width", strokeWidth)
                    .attr('stroke', Color.AXIS)
                    .attr('d', "M"+(+start)+",0H"+(+end));
            })

        axis.append("g")
            .attr("class", "axis_y")
            .call(axisY)
            .call(g=>{
                g.selectAll(".tick")
                 .filter((d,i) => i === g.selectAll(".tick").size()-1)
                 .remove();
                g.selectAll(".tick")
                    .selectAll("line")
                    .attr("x2", this.height() === 640 ? -6 : -6 * this.height() / 320)
                    .attr("stroke-width", strokeWidth);;

                g.selectAll("text")
                    .attr("x", this.height() === 640 ? -9 : -9 * this.height() / 320);
                g.attr('font-size', font.axis)
                
                let domainDY = g.selectAll(".domain").attr("d");
                let startY = domainDY.split(",")[1].split("H")[0];
                let endY = domainDY.split("V")[1].split("H")[0];
                g.selectAll(".domain")
                    .attr("stroke-width", strokeWidth)
                    .attr('stroke', Color.AXIS)
                    .attr('d', "M0, "+startY+"V"+endY);
            });

        //change style
        /* Axis */
        const padding = font.axis * 0.6,
            triangleSize = Math.ceil(Math.sqrt(height * width) / 10);
        axis = svg.select('.axis');
        // svg.select(".axis_x").remove();
        // svg.select(".axis_y").remove();
        let textX = axis.append("text")
            .attr("x", width / 2)
            .attr("y", height + svg.selectAll(".axis_x").node().getBBox().height + padding)
            .attr("font-family", NUMFONT)
            .attr("font-size", font.axis * 1.2)
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "hanging")
            .text(measure[0].field || "Count");

        axis.append("path")
            .attr("class", "triangle")
            .attr("transform", `translate(${width - triangleSize / 25 * 2}, ${height})rotate(90)`)
            .attr("d", d3.symbol().type(d3.symbolTriangle).size(triangleSize))
            .attr("fill", Color.AXIS);


        let textY = axis.append("text")
            .attr("transform", `translate(${-padding - svg.selectAll(".axis_y").node().getBBox().width}, ${height / 2}) rotate(-90)`)
            .attr("font-family", NUMFONT)
            .attr("font-size", font.axis * 1.2)
            .attr("text-anchor", "middle")
            .text(measure[1].field || "Count");

        //wrap
        wrap(textX, width);
        wrap(textY, height);
        function wrap(self, maxWidth) {
            var textLength = self.node().getComputedTextLength(),
                text = self.text();
            while (textLength > (maxWidth) && text.length > 0) {
                text = text.slice(0, -1);
                self.text(text + '…');
                textLength = self.node().getComputedTextLength();
            }
        }

        axis.append("path")
            .attr("class", "triangle")
            .attr("transform", `translate(0, ${triangleSize / 25 * 2})`)
            .attr("d", d3.symbol().type(d3.symbolTriangle).size(triangleSize))
            .attr("fill", Color.AXIS);

        /* draw points */
        const circleSize = Math.min(Math.ceil(Math.sqrt(height * width) / 50), 7);

        let circle_content = content.append("g");
        circle_content.selectAll("circle")
            .data(seriesData[data_index].values)
            .enter().append("circle")
            .attr("class", "data_item")
            .attr("r", circleSize)
            .attr("stroke", "#FFF")
            .attr("stroke-width", 0)
            .attr("fill", Color.DEFAULT)
            // .attr("opacity", 0.7)
            .attr("cx", d => xScale(d[xEncoding]))
            .attr("cy", d => yScale(d[yEncoding]))
            .attr("opacity", 0)
            .transition()
            .duration(tick)
            .attr("opacity", 0.7)

        let time_text = content.append("g")
                .append("text")
                .attr("fill", Color.TEXT)
                .attr("font-size", font.axis * 2)
                .attr("x", svg.select(".axis_y").node().getBBox().x + svg.select(".axis_y").node().getBBox().width * 1.2)
                .attr("y", svg.select(".axis_y").node().getBBox().y)
                .text(seriesData[data_index].key)
                .attr("dominant-baseline", "hanging")

        time_text.attr("opacity", 0)
                 .transition()
                 .duration(tick)
                 .attr("opacity", 1)

        let time_id = setInterval(()=>{
            data_index += 1; // start from 1

            // redraw circles
            let circles = circle_content.selectAll("circle")
                                .data(seriesData[data_index].values);
            
            circles.enter()
                    .append("circle")
                    .attr("class", "data_item")
                    .attr("r", circleSize)
                    .attr("stroke", "#FFF")
                    .attr("stroke-width", 0)
                    .attr("fill", Color.DEFAULT)
                    .attr("opacity", 0.7)
                    .attr("cx", d => xScale(d[xEncoding]))
                    .attr("cy", d => yScale(d[yEncoding]));
            
            circles.exit().remove();

            circles.transition()
                    .duration(tick)
                    .attr("cx", d => xScale(d[xEncoding]))
                    .attr("cy", d => yScale(d[yEncoding]));

            time_text
                    .transition()
                    .duration(tick)//GAP
                    .text(seriesData[data_index].key);
            if(data_index === seriesData.length-1)    clearInterval(time_id);

        }, tick)
        
        // align horizontal center
        let marginLeft = (this.width() - svg.node().getBBox().width) / 2 - svg.node().getBBox().x;
        let marginTop = (this.height() - svg.node().getBBox().height) / 2  - svg.node().getBBox().y;
        svg.attr("transform", "translate(" + marginLeft + "," + marginTop + ")")

        return svg;
    }

    displayAssociation() {
        /* -------------------------------- init data ------------------------------- */
        const factData = this.factdata(),
            measure = this.measure(),
            breakdown = this.breakdown(),
            // container = this.container(),
            size = this.size();

        if (measure.length < 2 || breakdown.length !== 1) return;
        /* ----------------------- graph set up (size, margin) ---------------------- */
        let margin = chartMargin[size],
            width = this.width() - margin.left - margin.right,
            height = this.height() - margin.top - margin.bottom,
            font = fontSize[size],
            strokeWidth = size === Size.SMALL || size === Size.MIDDLE ? 1 : 2;
        /* ----------------------------- data prosessing ---------------------------- */
        //association需两个measure
        const xEncoding = "measure0:" + (measure[0].aggregate === "count" ? "COUNT" : measure[0].field),
            yEncoding = "measure1:" + (measure[1].aggregate === "count" ? "COUNT" : measure[1].field);
        /* ----------------------------------- vis ---------------------------------- */
        let svg = initSvg(this.container(), width, height, margin);
        if(this.style() === Style.COMICS) width = 0.8* width;
        basicDraw(factData, xEncoding, yEncoding, svg, width, height, margin);
        //change style
        /* Axis */
        const padding = font.axis * 0.6,
            triangleSize = Math.ceil(Math.sqrt(height * width) / 10);
        let axis = svg.select('.axis');
        svg.select(".axis_x").remove();
        svg.select(".axis_y").remove();

        let textX = axis.append("text")
            .attr("x", width / 2)
            .attr("y", height + padding - 1)
            .attr("font-family", NUMFONT)
            .attr("font-size", font.axis)
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "hanging")
            .text(measure[0].field || "Count");

        axis.append("path")
            .attr("class", "triangle")
            .attr("transform", `translate(${width - triangleSize / 25 * 2}, ${height})rotate(90)`)
            .attr("d", d3.symbol().type(d3.symbolTriangle).size(triangleSize))
            .attr("fill", Color.AXIS);

        axis.append("line")
            .attr("x1", -strokeWidth / 2)
            .attr("x2", width)
            .attr("y1", height)
            .attr("y2", height)
            .attr("stroke-width", strokeWidth)
            .attr("stroke", Color.AXIS);

        let textY = axis.append("text")
            .attr("transform", `translate(${-padding}, ${height / 2}) rotate(-90)`)
            .attr("font-family", NUMFONT)
            .attr("font-size", font.axis)
            .attr("text-anchor", "middle")
            .text(measure[1].field || "Count");

        //wrap
        wrap(textX, width);
        wrap(textY, height);
        function wrap(self, maxWidth) {
            var textLength = self.node().getComputedTextLength(),
                text = self.text();
            while (textLength > (maxWidth) && text.length > 0) {
                text = text.slice(0, -1);
                self.text(text + '�');
                textLength = self.node().getComputedTextLength();
            }
        }

        axis.append("path")
            .attr("class", "triangle")
            .attr("transform", `translate(0, ${triangleSize / 25 * 2})`)
            .attr("d", d3.symbol().type(d3.symbolTriangle).size(triangleSize))
            .attr("fill", Color.AXIS);

        axis.append("line")
            .attr("y1", 0)
            .attr("y2", height)
            .attr("stroke-width", strokeWidth)
            .attr("stroke", Color.AXIS);

        //trend
        let xScale = d3.scaleLinear()
            .range([0, width])
            .domain([0, d3.max(factData, d => d[xEncoding])])
            .nice();

        let yScale = d3.scaleLinear()
            .range([height, 0])
            .domain([0, d3.max(factData, d => d[yEncoding])])
            .nice();

        let ret = getLeastSquares(factData.map(d => xScale(d[xEncoding])),
            factData.map(d => yScale(d[yEncoding])));
        let x1 = 0,
            x2 = width;
        //regression in range, can not out of content
        let x_ymin = (height - ret.b) / ret.m,
            x_ymax = (0 - ret.b) / ret.m;

        if (x_ymin > x_ymax) {
            const i = x_ymin;
            x_ymin = x_ymax;
            x_ymax = i;
        }
        x1 = x1 < x_ymin ? x_ymin : x1;
        x2 = x2 > x_ymax ? x_ymax : x2;
        if (ret.m === 0) x1 = 0;
        let y1 = ret.m * x1 + ret.b,
            y2 = ret.m * x2 + ret.b;
        if (ret.m === -Infinity) {
            x1 = x2;
            y1 = 0;
            y2 = height;
        }

        let trend = svg.append("g");
        svg.node().prepend(trend.node());
        trend.append("line")
            .attr('class', "trendlineLayer")
            .attr("x1", x1)
            .attr("x2", x2)
            .attr("y1", y1)
            .attr("y2", y2)
            .attr("stroke-width", strokeWidth)
            .attr("stroke", "#A9A8A8")
            .attr("stroke-dasharray", "5,5");

        if(this.style() === Style.COMICS){
            let metaphorWidth = width*0.3,
                metaphorHeight = 1.23*metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor29)
                .attr("width", metaphorWidth)
                .attr("height", metaphorHeight);
        
            if(this.size() === Size.WIDE){
                metaphorWidth = width*0.25;
                metaphorHeight = 1.23*metaphorWidth;
                metaphor.attr("width", metaphorWidth)
                    .attr("height", metaphorHeight)
                    .attr("x", width)
                    .attr("y", height - metaphorHeight*0.96);
            }else if (this.size() === Size.MIDDLE){
                metaphorWidth = width*0.28;
                metaphorHeight = 1.23*metaphorWidth;

                metaphor.attr("width", metaphorWidth)
                    .attr("height", metaphorHeight)
                    .attr("x", width)
                    .attr("y", height - metaphorHeight*0.96);
            }else{
                metaphor.attr("x", width - metaphorWidth*0.1)
                    .attr("y", height - metaphorHeight*0.96);
            }
        }
        return svg;
    }

    animateAssociation() {
        let svg = this.displayAssociation();
        if (!svg) return;
        let duration = this.duration();
        svg.selectAll(".axis")
            .attr("opacity", 0)
            .transition()
            .duration(duration / 4)
            .attr("opacity", 1);

        svg.selectAll('circle')
            .attr("fill-opacity", 0)
            .transition()
            .duration(duration / 4 * 1.5)
            .delay(duration / 4)
            .attr("fill-opacity", 1);

        let trendline = svg.selectAll(".trendlineLayer")
        let originEndX2 = trendline.attr("x2");
        let originEndY2 = trendline.attr("y2");
        let originEndX1 = trendline.attr("x1");
        let originEndY1 = trendline.attr("y1");

        trendline.attr("x2", originEndX1)
            .attr("y2", originEndY1);

        setTimeout(() => {
            trendline.attr("x2", originEndX1)
                .attr("y2", originEndY1)
                .transition()
                .duration(duration / 4 * 1.5)
                .attr("x2", originEndX2)
                .attr("y2", originEndY2);
        }, duration /4 * 2.5);
    }
}

const initSvg = (container, width, height, margin) => {
    let svg = d3.select(container)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    return svg
}

export default Scatterplot;