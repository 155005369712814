import * as d3 from 'd3';
import Chart from '../../chart';
import Color from '../../visualization/color';
import textMultiline from '../../visualization/multiline';
import Style from '../../visualization/style';
import Size from '../../visualization/size';

import metaphor16 from '../../metaphor/metaphor16.png';//proportion

const fontSize = {
    "small":{
        "label": 14
    },
    "middle":{
        "label": 18
    },
    "wide":{
        "label": 20
    },
    "large":{
        "label": 40
    }
}

const chartMargin = {
    "proportion":{
        "small":{
            "top": 2.5,
            "right": 15,
            "bottom": 2.5,
            "left": 10
        },
        "middle":{
            "top": 5,
            "right": 25,
            "bottom": 5,
            "left": 25
        },
        "wide":{
            "top": 5,
            "right": 110,
            "bottom": 5,
            "left": 80
        },
        "large":{
            "top": 30,
            "right": 30,
            "bottom": 30,
            "left": 30
        }
    }
};
const NUMFONT = "Arial-Regular";
class DonutChart extends Chart {
    displayProportion(){
        /* -------------------------------- init data ------------------------------- */
        const factData = this.factdata(),
            measure = this.measure(),
            focus = this.focus()[0],
            breakdown = this.breakdown(),
            container = this.container(),
            size = this.size(),
            yEncoding = (measure.length > 1? "measure0:" : "") + (measure[0].aggregate === "count" ? "COUNT": measure[0].field);
        if(!focus || breakdown.length !== 1)  return;
        /* ----------------------- graph set up (size, margin) ---------------------- */
        let margin = chartMargin["proportion"][size],
            width = this.width() - margin.left - margin.right,
            height = this.height() - margin.top - margin.bottom,
            font = fontSize[size];
        /* ----------------------------- data prosessing ---------------------------- */
        let focusItem = factData.find(d => d[focus.field] === focus.value),
            restItems = factData.filter(d => d[focus.field] !== focus.value);
        if(!focusItem) return;
        let seriesData = [focusItem[yEncoding], d3.sum(restItems, d => d[yEncoding])];
        let pieData = d3.pie().sort(null)(seriesData);
        /* ----------------------------------- vis ---------------------------------- */
        let svg = initSvg(container, width, height, margin);
        let content = svg.append("g")
            .attr("class", "content"),
            hint = svg.append("g")
            .attr("class", "hint");

        let percent = pieData[0].value / d3.sum(pieData, d => d.value);
        let percentText = (percent * 100).toFixed(1) + "%";

        if(this.style() === Style.COMICS){
            width = 0.9*width;
            height = 0.9*height;
        }
        let R = Math.min(height, width)/2,
            strokeWidth = Math.min(R*0.04, 8);

        let arc = d3.arc()
            .innerRadius(R*0.63)
            .outerRadius(R);

        let arc1 = d3.arc()
            .innerRadius(R*0.65)
            .outerRadius(R*0.98);

        content.selectAll(".data_item")
            .data(pieData)
            .enter()
            .append("path")
            .lower()
            .attr("class", (d,i)=> + i===0?"data_item focusRing":"data_item")
            .attr("transform", "translate(" + R + "," + R+ ")")
            .attr("fill", (d,i) => i === 0 ? Color.HIGHLIGHT : Color.DEFAULT)
            .attr("stroke-width", strokeWidth/2)
            .attr("stroke", "#fff")
            .attr("d", (d, i)=>{
                if(i === 0) return arc(d);
                else {
                    let _d = d;
                    _d.startAngle = 0;
                    return arc1(_d)
                }
            });

        let text = hint.append("text")
            .attr("class", "percent")
            .attr("x", R)
            .attr("y", R)
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "ideographic")
            .attr("font-family", NUMFONT)
            .attr("font-size", font.label)
            .attr("font-weight", "600")
            .attr("fill", Color.HIGHLIGHT)
            .text(percentText);
        
        let textE = textMultiline(hint, focus.value, font.label, R, R, R);
        textE.attr("font-weight", "600");
        //center text
        hint.attr("transform", `translate(0, ${ (text.node().getBBox().height - textE.node().getBBox().height)/2})`)
        
        //center
        svg.attr("transform", "translate(" + ((this.width() - svg.node().getBBox().width)/2- svg.node().getBBox().x) + "," 
                                            + ((this.height()- svg.node().getBBox().height)/2) + ")");
        
        if(this.style() === Style.COMICS){
            let metaphor = svg.append("image");
            const metaphorWidth = (size === Size.LARGE) ? width*0.35: width*0.3;
            const metaphorHeight = metaphorWidth*0.74;
            metaphor.attr('xlink:href', metaphor16)
                .attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", -metaphorWidth*0.5)
                .attr("y", -metaphorHeight*0.16)
                .attr("transform", "rotate(-45)");
            if(size === Size.LARGE) metaphor.attr("y", metaphorHeight*0.1);
            else if(size === Size.SMALL) metaphor.attr("y", -metaphorHeight*0.08);
            svg.node().prepend(metaphor.node());
            //center居中
            svg.attr("transform", "translate(" + ((this.width() - svg.node().getBBox().width)/2 - svg.node().getBBox().x - metaphorWidth*0.1 )  + "," + ((this.height() - svg.node().getBBox().height)/2 - svg.node().getBBox().y - metaphorHeight*0.15) + ")"); 
        }
        return svg;
    }

    animateProportion() {
        let svg = this.displayProportion();
        if(!svg) return;

        let size = this.size(),
            margin = chartMargin["proportion"][size],
            width = this.width() - margin.left - margin.right,
            height = this.height() - margin.top - margin.bottom,
            duration = this.duration(),
            ticks = 10;

        let R = Math.min(height, width)/2;

        let arc = d3.arc()
            .innerRadius(R*0.64)
            .outerRadius(R);

        
        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            bgFadeIn: {
                duration: 3,
                index: 0
            },
            labelFadeIn: {
                duration: 2,
                index: 1
            },
            majorGrow: {
                duration: 5,
                index: 2
            }
        }
        let everyTick = duration / ticks;


        /* ----------------------------- step 0 bgFadeIn ---------------------------- */
        let bgRing = svg.selectAll(".content").select("path");
        bgRing.attr("opacity", 0);

        bgRing.transition()
              .duration(everyTick * animation.bgFadeIn.duration)
              .attr("opacity", 1);

        /* ----------------------------- step 1 labelFadeIn ---------------------------- */
        let label = svg.selectAll(".hint").selectAll("text").filter(function() {
            return !this.classList.contains('percent')
        });

        label.attr("opacity", 0);
        label.transition()
            .duration(everyTick * animation.labelFadeIn.duration)
            .delay(everyTick * countTicksBeforeIndex(animation, animation.labelFadeIn.index))
            .attr("opacity", 1);
        

        /* ---------------------------- step 1 majorGrow ---------------------------- */
        let focusRing = svg.selectAll(".content").selectAll(".focusRing");
        focusRing.attr("opacity", 0);
        focusRing.attr("d", function(d, i) {
            let _d = Object.assign({}, d);
            _d.endAngle = _d.startAngle;
            return arc(_d)
        });

        let percentText = svg.selectAll(".hint").selectAll(".percent");
        percentText.attr("opacity", 0);

        setTimeout(()=>{
            focusRing.attr("opacity", 1);
            focusRing.transition()
                    .duration(everyTick *  (animation.majorGrow.duration + 0.5))
                    .ease(d3.easeLinear)
                    .attrTween("d", function(d, i) {
                        var interpolate = d3.interpolate(d.startAngle, d.endAngle);
                        return function (t) {
                            d.endAngle = interpolate(t);
                            return arc(d)
                        }
                    });
            
            percentText.attr("opacity", 1);

            percentText.transition()
                .duration(everyTick * (animation.majorGrow.duration + 0.5))
                .ease(d3.easeLinear)
                .textTween(function(d) {
                    let final = d3.select(this).text().split("%")[0];
                    const i = d3.interpolate(0.0, final);
                    var numberFormat = d3.format(".1f");
                    return function(t) { 
                        var percent = numberFormat(i(t));
                        return percent + "%";
                    };
                });

        }, everyTick * countTicksBeforeIndex(animation, animation.majorGrow.index))

    }
}

const initSvg = (container, width, height, margin) => {
    let svg = d3.select(container)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    return svg;
}

const countTicksBeforeIndex = (animation, index) => {
    let count = 0;
    let visited = []
    for (let key in animation) {
        if(animation[key].index < index && visited.indexOf(animation[key].index) === -1) {
            count += animation[key].duration
            visited.push(animation[key].index)
        };
    }
    return count - 0.5
}


export default DonutChart;