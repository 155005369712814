import * as d3 from 'd3';

const removeOverlapX = (g, x) => {
    // get tick width;
    let tickWidth = 0;
    g.selectAll(".tick")
        .each(function (d, i) {
            let _thisWidth = d3.select(this).node().getBBox().width
            if (tickWidth < _thisWidth) tickWidth = _thisWidth;
        });
    tickWidth = tickWidth * 1.5;
    let tickCount = g.selectAll(".tick").size();
    let xAxisWidth = x.range()[1] - x.range()[0];

    if (x.range()[0] === 0) xAxisWidth = xAxisWidth * 0.8;
    else xAxisWidth = xAxisWidth * 0.9;
    let possibleTickCount = Math.round((xAxisWidth) / tickWidth) + 1;
    let enough = tickCount * tickWidth >= xAxisWidth ? false : true;
    let largestInterval = Math.floor((tickCount % 2 ? tickCount : tickCount - 1) / (possibleTickCount - 1));
    // TODO 只有一个tick 显示首尾 但是如何避免真的两个都放不下呢？
    if (possibleTickCount === 1 && 2 * tickWidth < xAxisWidth) {
        let size = g.selectAll(".tick").size()
        g.selectAll(".tick")
            .each(function (d, i) {
                if (!enough && (i !== 0 && i !== size - 1)) {
                    this.remove();
                }
            })
    } else {
        if ((g.selectAll(".tick").size() - 1) % Math.floor(tickCount / possibleTickCount) === 0) {
            // 最后一个保证会被显示
            // 间隔少
            g.selectAll(".tick")
                .each(function (d, i) {
                    if (!enough && i % Math.floor(tickCount / possibleTickCount) !== 0) {
                        this.remove();
                    }
                })
        } else {
            // 最后一个不保证会被显示
            // 间隔尽量长少
            g.selectAll(".tick")
                .each(function (d, i) {
                    if (!enough && i % largestInterval !== 0) {
                        this.remove();
                        // d3.select(this).attr("opacity", 0)
                    }
                })
        }

    }
}
export default removeOverlapX;