const templates = [
    {
        'id': 0,
        'template': 'The Pearson correlation between the {{measure1}} and the {{measure2}} is {{parameter}} when {{subspace}}.',
    },
    {
        'id': 1,
        'template': 'When {{subspace}}, the Pearson correlation coefficient between the {{measure1}} and the {{measure2}} is {{parameter}}.',
    },
    {
        'id': 2,
        'template': 'When {{subspace}}, the {{measure1}} is correlated with the {{measure2}} and the Person correlation is {{parameter}}.',
    },
]

export default templates;