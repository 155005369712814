import Style from './visualization/style';
import ChartType from './visualization/charttype';
import Size from './visualization/size';
import { VerticalBarChart, HorizentalBarChart, LineChart, AreaChart, Scatterplot, PieChart, DonutChart, BubbleChart, FilledMap, BubbleMap, ProgressChart, TreeMap, TextChart, IsotypeBar, IsotypeCluster, IsotypeProportion } from './charts';

class Visualization {
    constructor() {
        this._data = [];
        this._fact = {};
        this._size = Size.LARGE;
        this._style = Style.BUSINESS;
        this._type = ChartType.VERTICAL_BAR_CHART;
        this._duration = 0;
        this._chart = {};
        this._caption = "";
        this._showSuggestion = false; // When true, it will show the suggestion that whether the chart supports the data.
    }

    size() {
        return this._size;
    }

    data(value) {
        if (!value) {
            return this._data;
        }
        this._data = value;
    }

    fact(value) {
        if (!value) {
            return this._fact;
        }
        this._fact = value;
    }

    chart(value) {
        if (!value) {
            return this._chart;
        }
        this._chart = value;
    }

    caption(value) {
        if (!value) {
            return this._caption;
        }
        this._caption = value;
    }

    style() {
        return this._style;
    }

    showSuggestion() {
        return this._showSuggestion
    }

    load(spec) {

        this._size = spec.size ? spec.size : Size.LARGE;
        this._style = spec.style;
        this._type = spec.type;
        this._duration = spec.duration ? spec.duration : 0;
        this._showSuggestion = spec.showSuggestion ? spec.showSuggestion : false;

        return new Promise((resolve, reject) => {
            try {
                let data = this.data();
                let fact = this.fact();
                let chart = this._type2chart(spec.type);
                chart.style(this._style);
                chart.size(this._size);
                chart.factdata(data);
                chart.subspace(fact.subspace);
                chart.measure(fact.measure);
                chart.breakdown(fact.breakdown);
                chart.focus(fact.focus);
                chart.duration(this._duration);
                chart.showSuggestion(this._showSuggestion);
                this.chart(chart);
                resolve(this);
            } catch (error) {
                reject(error);
            }
        });
    }

    _type2chart(type) {
        switch (type) {
            case ChartType.VERTICAL_BAR_CHART:
                return new VerticalBarChart();
            case ChartType.HORIZENTAL_BAR_CHART:
                return new HorizentalBarChart();
            case ChartType.PROGRESS_CHART:
                return new ProgressChart();
            case ChartType.AREA_CHART:
                return new AreaChart();
            case ChartType.BUBBLE_CHART:
                return new BubbleChart();
            case ChartType.BUBBLE_MAP:
                return new BubbleMap();
            case ChartType.DONUT_CHART:
                return new DonutChart();
            case ChartType.FILLED_MAP:
                return new FilledMap();
            case ChartType.LINE_CHART:
                return new LineChart();
            case ChartType.PIE_CHART:
                return new PieChart();
            case ChartType.SCATTER_PLOT:
                return new Scatterplot();
            case ChartType.TREE_MAP:
                return new TreeMap();
            case ChartType.TEXT_CHART:
                return new TextChart();
            case ChartType.ISOTYPE_BAR:
                return new IsotypeBar();
            case ChartType.ISOTYPE_CLUSTER:
                return new IsotypeCluster();
            case ChartType.ISOTYPE_PROPORTION:
                return new IsotypeProportion();

            default:
                return new HorizentalBarChart();
        }
    }
}

export default Visualization;