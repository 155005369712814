import * as d3 from 'd3';
import Chart from '../../chart';
import Color from '../../visualization/color';
import chinaGeo from '../../visualization/map/china';
import usStateGeo from '../../visualization/map/usa';
import worldGeo from '../../visualization/map/world';
import Size from '../../visualization/size';
import Style from '../../visualization/style';
import formatNumber from '../../visualization/format';
import updateChartCenter from '../../visualization/updateChartCenter';
import generateToolTip from '../../visualization/tooltipForMap'
import metaphor4 from '../../metaphor/metaphor4.png';//value
import metaphor26 from '../../metaphor/metaphor26.png';//distribution
import metaphor3 from '../../metaphor/metaphor3.png';//categorization
import metaphor25 from '../../metaphor/metaphor25.png';//difference

const NUMFONT = "Arial-Regular";
const TEXTFONT = "Arial-Bold";

class FilledMap extends Chart {

    displayDistribution() {
        let { margin, rectWidth, rectHight } = getSizeBySize(this.size(), "distribution");
        let width = this.width() - margin.left - margin.right,
            height = this.height() - margin.top - margin.bottom;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", this.width())
            .attr("height", this.height())
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        if (this.style() === Style.COMICS) width = 0.9 * width;

        let factdata = this.factdata();
        let breakdown = this.breakdown(),
            mapType = breakdown[0].subtype;

        let measure = this.measure();
        if (measure.length > 1 || breakdown[0].type !== 'geographical') {
            return svg;
        }

        let measuredField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field;
        let data = factdata;
        let minValue = d3.min(data, d => d[measuredField]),
            maxValue = d3.max(data, d => d[measuredField]);

        if(measure[0]["min"] && measure[0].min < minValue) {
            minValue = measure[0].min;
        }
        if(measure[0]['max'] && measure[0].max > maxValue) {
            maxValue = measure[0].max;
        }


        //定义最小值和最大值对应的颜色
        let blueColor = Color.DIVERGING[1];
        let whiteColor = Color.DIVERGING[5];
        let redColor = Color.DIVERGING[9];
        let computeColor;
        let isShowWhite = false;
        if (minValue >= 0) { //如果都是大于0的数，就直接从白到红即可，不需要蓝色
            computeColor = d3.interpolate(Color.DIVERGING[4], Color.DEFAULT);
        } else if (minValue < 0 && maxValue >= 0) { //如果数据中有负数，有正数，取绝对值最大的值最大值，取相反数为最小值；负数为蓝，正数为红, 零为白色
            computeColor = d3.interpolate(blueColor, redColor);
            maxValue = Math.abs(minValue) >= maxValue ? Math.abs(minValue) : maxValue
            minValue = -maxValue;
            isShowWhite = true;
        } else {
            computeColor = d3.interpolate(blueColor, redColor);
            //console.log("数值全负数。。。")
        }
        let scale;
        scale = d3.scaleLinear().domain([minValue, maxValue]);

        let geoValues = data.map(d => d[breakdown[0].field]);
        let projection = d3.geoMercator();

        //append map
        let GeoData = chinaGeo;
        if (mapType === "world") {
            GeoData = worldGeo;
        } else if (mapType === "china") {
            GeoData = chinaGeo;
        } else if (mapType === "usa") {
            GeoData = usStateGeo;
            projection = d3.geoAlbersUsa()
        }

        projection.fitSize([width - margin.right * 3, height], GeoData);
        // 定义地理路径生成器
        const path = d3.geoPath()
            .projection(projection);

        let map_svg = svg.append('g').attr('class', 'map');
        map_svg.selectAll('path')
            .data(GeoData.features)
            .enter()
            .append('path')
            .attr('id', d => d.properties.enName)
            .attr('stroke', 'grey')
            .attr('stroke-width', 0.3)
            .attr('fill', function (d, i) {
                if (geoValues.indexOf(d.properties.enName) !== -1) {
                    let countryName = geoValues[geoValues.indexOf(d.properties.enName)]
                    let value;
                    factdata.map(data => {
                        if (data[breakdown[0].field] === countryName) {
                            value = data[measuredField];
                        }
                        return data;
                    })
                    if (!value) return Color.BACKGROUND;
                    return computeColor(scale(value));
                } else {
                    return Color.BACKGROUND;
                }
            })
            .attr('d', path);
        if (mapType === "china") {
            appendSouthChinaSea(map_svg);
        }
        //append legend 
        //定义一个线性渐变
        var defs = svg.append("defs");
        var linearGradient = defs.append("linearGradient")
            .attr("id", "linearColor")
            .attr("x1", "0%")
            .attr("y1", "0%")
            .attr("x2", "0%")
            .attr("y2", "100%");

        linearGradient.append("stop")
            .attr("offset", "0%")
            .attr("stop-color", computeColor(scale(maxValue)));
        if (isShowWhite) {
            linearGradient.append("stop")
                .attr("offset", '50%')
                .attr("stop-color", whiteColor);
        }
        linearGradient.append("stop")
            .attr("offset", "100%")
            .attr("stop-color", computeColor(scale(minValue)));

        //添加一个矩形，并应用线性渐变
        svg.append("rect")
            .attr("x", this.width() - margin.left - margin.right - margin.right)
            .attr("y", (height - rectHight) / 2)
            .attr("width", rectWidth)
            .attr("height", rectHight)
            .attr("fill", "url(#" + linearGradient.attr("id") + ")");

        let offsetText = 20;
        svg.append("text")
            .attr("class", "valueText")
            .attr("x", this.width() - margin.left - margin.right - margin.right + rectWidth / 2)
            .attr("y", height / 2 + rectHight / 2 + offsetText)
            .attr("dy", "-0.3em")
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'hanging')
            .attr('font-size', 14)
            .text(function () {
                return formatNumber(minValue);
            });
        svg.append("text")
            .attr("class", "valueText")
            .attr("x", this.width() - margin.left - margin.right - margin.right + rectWidth / 2)
            .attr("y", height / 2 - rectHight / 2 - offsetText)
            .attr("dy", "-0.3em")
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'hanging')
            .attr('font-size', 14)
            .text(function () {
                return formatNumber(maxValue);
            });
        if (this.style() === Style.COMICS) {
            let mapBBox = svg.select('.map').node().getBBox();
            let metaphorWidth = width * 0.22,
                metaphorHeight = 1.29 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor26)
                .attr("x", mapBBox.width + mapBBox.x - metaphorWidth * 0.1)
                .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 1.16);

            if (this.size() === Size.WIDE) {
                metaphorWidth = width * 0.18;
                metaphorHeight = 1.29 * metaphorWidth;
                metaphor.attr("x", mapBBox.width + mapBBox.x + metaphorWidth * 0.2)
                    .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 1.16);
            } else if (this.size() === Size.MIDDLE) {
                metaphorWidth = width * 0.23;
                metaphorHeight = 1.29 * metaphorWidth;
                metaphor.attr("x", mapBBox.width + mapBBox.x - metaphorWidth * 0.15)
                    .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 1.16);
            } else if (this.size() === Size.SMALL) {
                metaphorWidth = width * 0.23;
                metaphorHeight = 1.29 * metaphorWidth;
                metaphor.attr("x", mapBBox.width + mapBBox.x - metaphorWidth * 0.1)
                    .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 1.16);
            }

            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)

        }
        //finally update chart horizental cental
        updateChartCenter(svg, this.width(), this.height());
        return svg;
    }

    displayValue() {
        let { margin, hightLightFontSize } = getSizeBySize(this.size(), "value");
        let width = this.width() - margin.left - margin.right,
            height = this.height() - margin.top - margin.bottom;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", this.width())
            .attr("height", this.height())
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);
        if (this.style() === Style.COMICS) width = 0.85 * width;

        let subspace = this.subspace(),
            mapType = subspace[0].subtype;

        let measure = this.measure();
        if (measure.length > 1 || subspace[0].type !== 'geographical') {
            return svg;
        }

        let countryName = subspace[0].value;

        let projection = d3.geoMercator();
        //append map
        let GeoData = chinaGeo;
        if (mapType === "world") {
            GeoData = worldGeo;
        } else if (mapType === "china") {
            GeoData = chinaGeo;
        } else if (mapType === "usa") {
            GeoData = usStateGeo;
            projection = d3.geoAlbersUsa()
        }



        //append legends first to measure the width
        //legends
        // let legendW = 200,
        //     measuredWidth = 0,
        //     measuredHeight = 0;//inital 
        let seriesName = [countryName];
        // // console.log("seriesName", seriesName)
        // let _that = this;
        // svg.append("foreignObject")
        //     .style('display', 'none')
        //     .attr("x", width - legendW)
        //     .attr("y", height / 2)
        //     .attr("width", legendW)
        //     .attr("height", height)
        //     .attr("class", "foreignObject")
        //     .append("xhtml:div")
        //     .attr("class", "legends")
        //     .style("display", "flex")
        //     .style("flex-direction", "column")
        //     .style("flex-wrap", "wrap")
        //     .style("height", "100%")
        //     .selectAll(".legend")
        //     .data(seriesName)
        //     .enter()
        //     .append("xhtml:div")
        //     .attr("class", "legend")
        //     .style("line-height", 1)
        //     .style("margin-right", 5 * this.width() / 320 + "px")
        //     .each(function (d, i) {
        //         let legend = d3.select(svg.selectAll(".legend").nodes()[i]).append("svg").attr("display", "block")
        //         legend.append("rect")
        //             .attr("fill", Color.HIGHLIGHT)
        //             .attr("width", 10 * fontSize / 12)
        //             .attr('height', 10 * fontSize / 12)
        //             .attr("rx", 1.5 * _that.width() / 640)
        //             .attr("ry", 1.5 * _that.width() / 640)
        //         legend.append("text")
        //             .attr("fill", Color.TEXT)
        //             .attr("x", 12 * fontSize / 12)
        //             .text(d => {
        //                 return `${countryName}: ${valueText}`;
        //             })
        //             .attr("font-size", fontSize * 0.8)
        //             .attr("font-family", NUMFONT)
        //             .attr("alignment-baseline", "hanging");
        //         legend.attr("width", legend.node().getBBox().width);
        //         legend.attr("height", legend.node().getBBox().height);
        //         let selfWidth = d3.select(this).select("svg").node().getAttribute("width"),
        //             selfHeight = d3.select(this).select("svg").node().getAttribute("height");
        //         if (Number(selfWidth) > Number(measuredWidth)) {
        //             measuredWidth = selfWidth;
        //         }
        //         measuredHeight += Number(selfHeight);
        //     });

        // //update legend center
        // let xPos = this.width() - margin.right - measuredWidth - margin.right / 2,
        //     yPos = (this.height() - measuredHeight) / 2 - margin.top;
        // let offset = this.size() === 'small' ? 2 : 5;
        // svg.select(".foreignObject").node().setAttribute("x", xPos);
        // svg.select(".foreignObject").node().setAttribute("y", yPos);
        // svg.select(".foreignObject").node().setAttribute("width", Number(measuredWidth + offset));
        // svg.select(".foreignObject").node().setAttribute("height", Number(measuredHeight));

        // projection.fitSize([width - margin.right - measuredWidth, height], GeoData);
        projection.fitSize([width - margin.right, height], GeoData);
        // 定义地理路径生成器
        const path = d3.geoPath()
            .projection(projection);

        let map_svg = svg.append('g').attr("class", "map");
        map_svg.selectAll('path')
            .data(GeoData.features)
            .enter()
            .append('path')
            .attr('id', d => d.properties.enName)
            .attr('class', function (d, i) {
                if (d.properties.enName === countryName) {
                    return 'selected-path';
                }
                return '';
            })
            .attr('stroke', 'grey')
            .attr('stroke-width', 0.3)
            .attr('fill', function (d, i) {
                //console.log("11", i, d.properties.enName)
                if (d.properties.enName === countryName) {
                    //console.log("22")
                    return Color.DEFAULT;
                }
                return Color.BACKGROUND;
            })
            .attr('d', path);
        if (mapType === "china") {
            appendSouthChinaSea(map_svg);
        }
        /******value******/
        let factdata = this.factdata(),
            measuredField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field,
            marginTextLeft = margin.right;

        let positionL = svg.selectAll('.selected-path').node().getBBox(),
            lineWidth = this.size() === "wide" ? width / 5 : width / 10;
        if (positionL.x + positionL.width / 2 <= this.width() / 2) {//靠右
            // if (true) {
            svg.append('line')
                .attr('class', 'tooltip-line')
                .attr('x1', positionL.x + positionL.width / 2)
                .attr('x2', positionL.x + positionL.width / 2 + lineWidth)
                .attr('y1', positionL.y + positionL.height / 2)
                .attr('y2', positionL.y + positionL.height / 2)
                .attr("stroke-width", 1)
                .attr("stroke", "black")
                .property("_direction", "right");


            let valueG = svg.append("g")
                .attr("class", 'value-tooltip')
                .attr("transform", `translate(${positionL.x + (lineWidth + 3)}${positionL.y + positionL.height / 2})`)

            valueG.append("text")
                .attr('class', 'tooltip-text')
                .attr('dy', '-1.25em')
                .attr('font-size', hightLightFontSize)
                .attr('font-family', NUMFONT)
                .attr('text-anchor', 'middle')
                .text(seriesName[0]);

            valueG.append("text")
                .attr('class', 'tooltip-value')
                .attr('font-size', hightLightFontSize)
                .attr('font-family', NUMFONT)
                .attr('text-anchor', 'middle')
                .attr("fill", Color.HIGHLIGHT)
                .text(formatNumber(factdata[0][measuredField]));

            let _selfWidth = valueG.node().getBBox().width,
                _selfHeight = valueG.node().getBBox().height;
            //update
            valueG.node().setAttribute("transform", `translate(${(positionL.x + positionL.width / 2 + lineWidth) + _selfWidth / 2 + marginTextLeft} ${positionL.y + positionL.height / 2 + _selfHeight / 2})`);

        } else {
            svg.append('line')
                .attr('class', 'tooltip-line')
                .attr('x2', positionL.x + positionL.width / 2)
                .attr('x1', positionL.x + positionL.width / 2 - lineWidth)
                .attr('y1', positionL.y + positionL.height / 2)
                .attr('y2', positionL.y + positionL.height / 2)
                .attr("stroke-width", 1)
                .attr("stroke", "black")
                .property("_direction", "left");

            let valueG = svg.append("g")
                .attr("class", 'value-tooltip')
                .attr("transform", `translate(${positionL.x - (lineWidth + 3)}${positionL.y + positionL.height / 2})`)


            valueG.append("text")
                .attr('class', 'tooltip-text')
                .attr('dy', '-1.25em')
                .attr('font-size', hightLightFontSize)
                .attr('font-family', NUMFONT)
                .attr('text-anchor', 'middle')
                .text(seriesName[0]);


            valueG.append("text")
                .attr('class', 'tooltip-value')
                .attr('font-size', hightLightFontSize)
                .attr('font-family', NUMFONT)
                .attr('text-anchor', 'middle')
                .text(formatNumber(factdata[0][measuredField]));

            let _selfWidth = valueG.node().getBBox().width,
                _selfHeight = valueG.node().getBBox().height;
            //update
            valueG.node().setAttribute("transform", `translate(${(positionL.x + positionL.width / 2 - lineWidth) - _selfWidth / 2 - marginTextLeft} ${positionL.y + positionL.height / 2 + _selfHeight / 2})`);
        }
        /******value the end******/

        if (this.style() === Style.COMICS) {
            let svgBBox = svg.node().getBBox();

            let metaphorWidth = width * 0.22,
                metaphorHeight = 1.18 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor4);

            if (this.size() === Size.WIDE) {
                metaphorWidth = width * 0.20;
                metaphorHeight = 1.18 * metaphorWidth;
            } else if (this.size() === Size.MIDDLE || this.size() === Size.SMALL) {
                metaphorWidth = width * 0.24;
                metaphorHeight = 1.18 * metaphorWidth;
            }
            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", svgBBox.width + svgBBox.x + metaphorWidth * 0.06)
                .attr("y", svgBBox.height + svgBBox.y - metaphorHeight * 1.16);
        }
        //finally update chart horizental cental
        updateChartCenter(svg, this.width(), this.height());
        return svg;
    }

    displayDifference() {
        let { margin, hightLightFontSize, tickFontSize } = getSizeBySize(this.size(), "difference");
        let width = this.width() - margin.left - margin.right,
            height = this.height() - margin.left - margin.right;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", this.width())
            .attr("height", this.height())
            .append("g")
            .attr("display", "block")
            .attr("class", "chartG");
        if (this.style() === Style.COMICS) width = (this.size() === Size.LARGE) ? 0.85 * width : 0.75 * width;
        let factdata = this.factdata();
        let focus = this.focus();
        let breakdown = this.breakdown(),
            mapType = breakdown[0].subtype;


        let filteredData = [] //sorted by focus
        for (const fs of focus) {
            this.factdata().filter((x) => x[fs.field] === fs.value)[0] && filteredData.push(this.factdata().filter((x) => x[fs.field] === fs.value)[0])
        }
        let focusCName = filteredData.map(d => d[breakdown[0].field]);
        let measure = this.measure();
        if (measure.length > 1 || breakdown[0].type !== 'geographical') {
            return svg;
        }

        let measuredField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field;
        let data = factdata;

        let geoValues = data.map(d => d[breakdown[0].field]);
        let projection = d3.geoMercator();

        //(1)append valueText first to measure the width
        // let mapML = margin.left;
        // if (this.size() === "wide") {
        //     mapML = 0;
        // }
        if (!filteredData[1][measuredField]) filteredData[1][measuredField] = 0;
        if (!filteredData[0][measuredField]) filteredData[0][measuredField] = 0;
        let valueText = Math.abs(Number(filteredData[1][measuredField]) - Number(filteredData[0][measuredField]))
        let legendH = margin.bottom * 1.5; //两个legend不超过一行
        let mapWidth = width,
            mapHeight = 0;//inital
        let measuredValueTextHeight = margin.top;
        let differenceValueG;
        if (this.size() === "large") {
            differenceValueG = svg.append("g")
                .attr("display", "block")
                .attr("class", "difference-value-box");

            // differenceValueG.append("text")
            //     .attr('font-size', hightLightFontSize)
            //     .attr('font-family', TEXTFONT)
            //     .attr('fill', Color.HIGHLIGHT)
            //     .attr('text-anchor', 'middle')
            //     .attr('dominant-baseline', 'hanging')
            //     .text("Difference");

            differenceValueG.append("text")
                .attr("dy", "1.25em")
                .attr('font-size', hightLightFontSize)
                .attr('font-family', TEXTFONT)
                .attr('fill', Color.HIGHLIGHT)
                .attr('text-anchor', 'middle')
                .attr('dominant-baseline', 'hanging')
                .text(valueText < 0 ? `-${formatNumber(-valueText)}` : formatNumber(valueText));

            let _selfHeight = svg.select(".difference-value-box").node().getBBox().height;
            mapHeight = height - _selfHeight - legendH;
            measuredValueTextHeight = _selfHeight;
            differenceValueG.node().setAttribute("transform", `translate(${width / 2},${margin.top})`);
        } else {
            differenceValueG = svg.append("g")
                .attr("display", "block")
                .attr("class", "difference-value-box");

            // differenceValueG.append("text")
            //     .attr('font-size', hightLightFontSize)
            //     .attr('font-family', TEXTFONT)
            //     .attr('fill', Color.HIGHLIGHT)
            //     .attr('text-anchor', 'middle')
            //     .attr('dominant-baseline', 'hanging')
            //     .text("Difference");

            differenceValueG.append("text")
                // .attr("dy", "1.25em")
                .attr('font-size', hightLightFontSize)
                .attr('font-family', TEXTFONT)
                .attr('fill', Color.HIGHLIGHT)
                .attr('text-anchor', 'middle')
                .attr('dominant-baseline', 'hanging')
                .text(valueText < 0 ? `-${formatNumber(-valueText)}` : formatNumber(valueText));
            let _selfWidth = svg.select(".difference-value-box").node().getBBox().width;
            differenceValueG.node().setAttribute("transform", `translate(${this.width() - _selfWidth / 2 - margin.right},${(this.height() - legendH) / 2})`);
            mapWidth = this.width() - _selfWidth - margin.right * 1.5;
            mapHeight = this.height() - legendH - margin.top;
        }

        let seriesName = focusCName;
        //(2)append legend 
        //inital params
        let ellipsisNumber = 11;
        if (this.size() === "large") {
            ellipsisNumber = 15;
        } else if (this.size() === "wide") {
            ellipsisNumber = 20;
        } else if (this.size() === "middle") {
            ellipsisNumber = 11;
        } else if (this.size() === "small") {
            ellipsisNumber = 5;
        }

        //(3)append map
        let GeoData = chinaGeo;
        if (mapType === "world") {
            GeoData = worldGeo;
        } else if (mapType === "china") {
            GeoData = chinaGeo;
        } else if (mapType === "usa") {
            GeoData = usStateGeo;
            projection = d3.geoAlbersUsa();
        }

        projection.fitSize([mapWidth, mapHeight], GeoData);

        // 定义地理路径生成器
        const path = d3.geoPath()
            .projection(projection);

        let map_svg = svg.append('g')
            .attr("class", "leftGroup")
        if (this.size() === "large") {
            map_svg.attr("transform", `translate(${0} ${measuredValueTextHeight})`);
        }

        map_svg.append("g")
            .attr("class", "map")
            .selectAll('path')
            .data(GeoData.features)
            .enter()
            .append('path')
            .attr('id', d => d.properties.enName)
            .attr('stroke', 'grey')
            .attr('stroke-width', 0.3)
            .attr('fill', function (d, i) {
                if (geoValues.indexOf(d.properties.enName) !== -1) {
                    let name = geoValues[geoValues.indexOf(d.properties.enName)];
                    // console.log("ss",name)
                    if (focusCName.indexOf(name) !== -1) {
                        // console.log("ss", focusCName.indexOf(name))
                        return Color.CATEGORICAL[focusCName.indexOf(name) === 0 ? 0 : 1];
                    }
                    return Color.BACKGROUND;
                } else {
                    return Color.BACKGROUND;
                }
            })
            .attr("class", function (d) {
                if (seriesName.indexOf(d3.select(this).node().id) === 0) {
                    return 'difference-path-1'
                }
                else if (seriesName.indexOf(d3.select(this).node().id) === 1) {
                    return 'difference-path-2'
                }
                else return ''
            })
            .attr('d', path);
        //update center
        if (this.size() !== "large") {
            let measuredMapHeight = map_svg.node().getBBox().height;
            map_svg.attr("transform", `translate(${0} ${(this.height() - legendH) / 2 - measuredMapHeight / 2})`);
        }


        if (mapType === "china") {
            appendSouthChinaSea(map_svg);
        }

        if (this.style() === Style.COMICS) {
            let mapBBox = map_svg.select('.map').node().getBBox();
            let metaphorWidth = width * 0.24,
                metaphorHeight = 1.29 * metaphorWidth;

            let metaphor = map_svg.append("image")
                .attr('xlink:href', metaphor25);

            metaphor.attr("x", mapBBox.width + mapBBox.x + metaphorWidth * 0.02)
                    .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 1.1)

            if (this.size() === Size.WIDE) {
                metaphorWidth = width * 0.15;
                metaphorHeight = 1.29 * metaphorWidth;
                if (mapType === "usa") {
                    metaphor.attr("x", mapBBox.width + mapBBox.x- metaphorWidth * 0.3)
                        .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 1.1);
                } else {
                    metaphor.attr("x", mapBBox.width + mapBBox.x)
                        .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 1.1);
                }
            } else if (this.size() === Size.MIDDLE || this.size() === Size.SMALL) {
                metaphorWidth = width * 0.18;
                metaphorHeight = 1.29 * metaphorWidth;
                if (mapType === "china" || mapType === "usa") {
                    metaphor.attr("x", mapBBox.width + mapBBox.x - metaphorWidth * 0.15)
                        .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 1.1);
                } else {
                    metaphor.attr("x", mapBBox.width + mapBBox.x + metaphorWidth * 0.05)
                        .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 1.1);
                }
            }

            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight);
            width = this.width() - margin.left - margin.right;
        }
        //relocate text pos
        svg.select(".difference-value-box").node().setAttribute("x", margin.left + map_svg.node().getBBox().width + map_svg.node().getBBox().x + 2);
        //center居中
        svg.attr("transform", "translate(" + ((this.width() - svg.node().getBBox().width) / 2 - svg.node().getBBox().x) + "," + 0 + ")");

        let legendY;
        if (this.size() === "large") {
            legendY = measuredValueTextHeight + mapHeight + margin.bottom;
        } else if (this.size() === "wide") {
            legendY = margin.top + mapHeight;
        } else {
            legendY = margin.top + mapHeight + margin.bottom;
        }
        let measuredWidth = 0;
        svg.append("foreignObject")
            .attr("x", 0)
            .attr("y", legendY) //inital
            .attr("width", width)
            .attr("height", legendH)
            .attr("class", "foreignObject")
            .append("xhtml:div")
            .attr("class", "legends")
            .style("display", "grid")
            .style("width", "100%")
            .style("grid-template-rows", `repeat(1, min-content)`)
            .style("grid-template-columns", `repeat(2, auto)`)
            //.style("justify-content", "space-around")
            .style("justify-content", "center")
            .style("grid-auto-flow", "row")
            .style("height", "100%")
            .selectAll(".legend")
            .data(seriesName)
            .enter()
            .append("xhtml:div")
            .attr("class", "legend")
            .attr("width", width)
            .style("line-height", 1)
            .style("margin-right", 10 * this.width() / 320 + "px")
            .each(function (d, i) {
                let legend = d3.select(svg.selectAll(".legend").nodes()[i]).append("svg").attr("display", "block")
                legend.append("rect")
                    .attr("fill", Color.CATEGORICAL[i])
                    .attr("width", tickFontSize)
                    .attr('height', tickFontSize)
                    .attr("rx", tickFontSize / 6)
                    .attr("ry", tickFontSize / 6)
                legend.append("text")
                    .attr("fill", Color.TEXT)
                    .attr("x", tickFontSize +4)
                    .text(d => {
                        let value = filteredData.filter(data => data[breakdown[0].field] === d)[0][measuredField]
                        value = formatNumber(value)
                        return `${d.length > ellipsisNumber ? d.substring(0, ellipsisNumber - 1) + "…" : d}: ${value.length > ellipsisNumber ? value.substring(0, ellipsisNumber - 1) + "…" : value}`;
                    })
                    .attr("font-size", tickFontSize)
                    .attr("font-family", NUMFONT)
                    .attr("alignment-baseline", "hanging");
                legend.attr("width", legend.node().getBBox().width);
                legend.attr("height", legend.node().getBBox().height);

                let selfWidth = d3.select(this).select("svg").node().getAttribute("width");
                if (Number(selfWidth) > Number(measuredWidth)) {
                    measuredWidth = selfWidth;
                }
            });
        //update legend center
        svg.select(".foreignObject").node().setAttribute("width", svg.node().getBBox().width);
        if (this.size() === "large") { //update for commic
            //update difference value center
            differenceValueG.node().setAttribute("transform", `translate(${svg.node().getBBox().width / 2},${margin.top})`);
            //update map center
            map_svg.node().setAttribute("transform", `translate(${(svg.node().getBBox().width - svg.select(".leftGroup").node().getBBox().width) / 2},${measuredValueTextHeight})`);
        }
        //finally update chart horizental cental
        updateChartCenter(svg, this.width(), this.height());
        return svg;
    }

    displayCategorization() {
        let { margin, legendSize } = getSizeBySize(this.size(), "categorization");
        let width = this.width() - margin.left - margin.right,
            height = this.height() - margin.top - margin.bottom;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", this.width())
            .attr("height", this.height())
            .append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`);

        if (this.style() === Style.COMICS) width = 0.88 * width;

        let factdata = this.factdata();
        let breakdown = this.breakdown(),
            mapType = breakdown[0].subtype;
        let measureField = "COUNT";

        if (breakdown[0].type !== 'geographical') {
            return svg;
        }
        //data
        let calculateData = d3.nest().key(d => d[breakdown[0].field]).entries(factdata);
        let data = calculateData.map(function (d, i) {
            let countRows = d.values[0];
            countRows[measureField] = d.values.length;
            return countRows;
        });
        data = data.slice(0, 9);//取前9

        let geoValues = data.map(d => d[breakdown[0].field]);
        let projection = d3.geoMercator();

        //append legends first to measure the width
        //legends
        let legendW = 200,
            measuredWidth = 0,
            measuredHeight = 0;//inital 
        let seriesName = geoValues;
        //console.log("seriesName", seriesName)
        if (this.size() === Size.SMALL) {
            let _that = this;
            svg.append("foreignObject")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", legendW)
                .attr("class", "foreignObject")
                .append("xhtml:div")
                .attr("class", "legends")
                .style("display", "grid")
                .style("width", "100%")
                .style("grid-template-rows", `repeat(4, min-content)`)
                .style("grid-template-columns", `repeat(3, auto)`)
                .style("justify-content", "space-around")
                .style("grid-auto-flow", "row")
                .selectAll(".legend")
                .data(seriesName)
                .enter()
                .append("xhtml:div")
                .attr("class", "legend")
                .style("line-height", 1)
                .style("margin-right", 5 * this.width() / 320 + "px")
                .each(function (d, i) {
                    let legend = d3.select(svg.selectAll(".legend").nodes()[i]).append("svg").attr("display", "block")
                    legend.append("rect")
                        .attr("fill", countryName => {
                            //console.log("countryName", countryName, i)
                            return Color.CATEGORICAL[i];
                        })
                        .attr("width", legendSize)
                        .attr('height', legendSize)
                        .attr("rx", 1.5 * _that.width() / 320)
                        .attr("ry", 1.5 * _that.width() / 320)
                    legend.append("text")
                        .attr("fill", Color.TEXT)
                        .attr("x", legendSize + 2)
                        .text(text => {
                            text = text.length > 7 ? text.substring(0, 5) + "…" : text;
                            return text;
                        })
                        .attr("font-size", legendSize)
                        .attr("font-family", NUMFONT)
                        .attr("alignment-baseline", "hanging");
                    legend.attr("width", legend.node().getBBox().width);
                    legend.attr("height", legend.node().getBBox().height);
                });
            let maxLegendH = 40;
            measuredHeight = maxLegendH;
            svg.select(".foreignObject").attr("transform", `translate(0,${this.height() - maxLegendH - margin.top})`)
            svg.select(".foreignObject").node().setAttribute("height", maxLegendH);
        } else {
            let _that = this;
            svg.append("foreignObject")
                .attr("x", width - legendW)
                .attr("y", height / 2)
                .attr("width", legendW)
                .attr("height", height)
                .attr("class", "foreignObject")
                .append("xhtml:div")
                .attr("class", "legends")
                .style("display", "flex")
                .style("flex-direction", "column")
                .style("flex-wrap", "wrap")
                .style("height", "100%")
                .selectAll(".legend")
                .data(seriesName)
                .enter()
                .append("xhtml:div")
                .attr("class", "legend")
                .style("line-height", 1)
                .style("margin-right", 5 * this.width() / 320 + "px")
                .each(function (d, i) {
                    let legend = d3.select(svg.selectAll(".legend").nodes()[i]).append("svg").attr("display", "block")
                    legend.append("rect")
                        .attr("fill", countryName => {
                            //console.log("countryName", countryName, i)
                            return Color.CATEGORICAL[i];
                        })
                        .attr("width", legendSize)
                        .attr('height', legendSize)
                        .attr("rx", 1.5 * _that.width() / 320)
                        .attr("ry", 1.5 * _that.width() / 320)
                    legend.append("text")
                        .attr("fill", Color.TEXT)
                        .attr("x", legendSize + 2)
                        .text(text => {
                            // text = text.length > 9 ? text.substring(0, 7) + "…" : text;
                            return text;
                        })
                        .attr("font-size", legendSize)
                        .attr("font-family", NUMFONT)
                        .attr("alignment-baseline", "hanging");
                    legend.attr("width", legend.node().getBBox().width);
                    legend.attr("height", legend.node().getBBox().height);
                    let selfWidth = d3.select(this).select("svg").node().getAttribute("width"),
                        selfHeight = d3.select(this).select("svg").node().getAttribute("height");
                    // console.log("selfWidth", selfWidth, measuredWidth)
                    if (Number(selfWidth) > Number(measuredWidth)) {
                        measuredWidth = selfWidth;
                    }
                    measuredHeight += Number(selfHeight);
                });
            //update legend center
            let xPos = this.width() - margin.right - measuredWidth - margin.right / 2,
                yPos = (this.height() - measuredHeight) / 2 - margin.top;
            let offset = this.size() === 'small' ? 2 : 5;
            svg.select(".foreignObject").node().setAttribute("x", xPos);
            svg.select(".foreignObject").node().setAttribute("y", yPos);
            svg.select(".foreignObject").node().setAttribute("width", Number(measuredWidth + offset));
            svg.select(".foreignObject").node().setAttribute("height", Number(measuredHeight));
        }


        //append map
        let GeoData = chinaGeo;
        if (mapType === "world") {
            GeoData = worldGeo;
        } else if (mapType === "china") {
            GeoData = chinaGeo;
        } else if (mapType === "usa") {
            GeoData = usStateGeo;
            projection = d3.geoAlbersUsa();
        }
        if (this.size() === Size.SMALL) {
            projection.fitSize([width - margin.left, height - measuredHeight], GeoData);
        } else {
            projection.fitSize([width - measuredWidth - margin.left, height], GeoData);
        }

        // 定义地理路径生成器
        const path = d3.geoPath()
            .projection(projection);

        let map_svg = svg.append('g').attr('class', 'map');
        map_svg.selectAll('path')
            .data(GeoData.features)
            .enter()
            .append('path')
            .attr('id', d => d.properties.enName)
            .attr('stroke', 'grey')
            .attr('stroke-width', 0.3)
            .attr('fill', function (d, i) {
                if (geoValues.indexOf(d.properties.enName) !== -1) {
                    let countryName = geoValues[geoValues.indexOf(d.properties.enName)];
                    //console.log("map", countryName, seriesName.indexOf(countryName));
                    return Color.CATEGORICAL[seriesName.indexOf(countryName)];
                } else {
                    return Color.BACKGROUND;
                }
            })
            .attr('d', path);

        if (mapType === "china") {
            appendSouthChinaSea(map_svg);
        }

        if (this.style() === Style.COMICS) {
            let metaphorWidth = width * 0.20,
                metaphorHeight = 1.38 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor3);

            if (this.size() === Size.WIDE) {
                metaphorWidth = width * 0.18;
                metaphorHeight = 1.38 * metaphorWidth;
            } else if (this.size() === Size.MIDDLE || this.size() === Size.SMALL) {
                metaphorWidth = width * 0.21;
                metaphorHeight = 1.38 * metaphorWidth;
            }
            let mapBBox = svg.select('.map').node().getBBox();
            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", mapBBox.width + mapBBox.x + metaphorWidth * 0.06)
                .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 1);

            if (mapType === "usa") {
                metaphor.attr("x", mapBBox.width + mapBBox.x - metaphorWidth * 0.05);
                if (this.size() === Size.WIDE) metaphor.attr("x", mapBBox.width + mapBBox.x - metaphorWidth * 0.15);
            }
        }

        //finally update chart horizental cental
        let _h = svg.node().getBBox().height,
            _w = svg.node().getBBox().width;
        let marginTop = (this.height() - _h) / 2 - svg.node().getBBox().y,
            marginLeft = (this.width() - _w) / 2 - svg.node().getBBox().x;
        svg.attr("transform", "translate(" + marginLeft + "," + marginTop + ")");
        return svg;
    }

    displayOutlier() {
        let { margin, rectWidth, rectHight, legendSize ,tickFontSize,} = getSizeBySize(this.size(), "distribution");

        let width = this.width() - margin.left - margin.right,
            height = this.height() - margin.top - margin.bottom;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", this.width())
            .attr("height", this.height())
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        if (this.style() === Style.COMICS) width = 0.9 * width;

        let factdata = this.factdata();
        let breakdown = this.breakdown(),
            mapType = breakdown[0].subtype;

        let measure = this.measure();
        if (measure.length > 1 || breakdown[0].type !== 'geographical') {
            return svg;
        }

        let measuredField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field;
        let data = factdata;
        let minValue = d3.min(data, d => d[measuredField]),
            maxValue = d3.max(data, d => d[measuredField]);

        if (measure[0]["min"] && measure[0].min < minValue) {
            minValue = measure[0].min;
        }
        if (measure[0]['max'] && measure[0].max > maxValue) {
            maxValue = measure[0].max;
        }


        //定义最小值和最大值对应的颜色
        let blueColor = Color.DIVERGING[1];
        let whiteColor = Color.DIVERGING[5];
        let redColor = Color.DIVERGING[9];
        let computeColor;
        let isShowWhite = false;
        if (minValue >= 0) { //如果都是大于0的数，就直接从白到红即可，不需要蓝色
            computeColor = d3.interpolate(Color.SEQUENTIAL[9], Color.SEQUENTIAL[0]);
        } else if (minValue < 0 && maxValue >= 0) { //如果数据中有负数，有正数，取绝对值最大的值最大值，取相反数为最小值；负数为蓝，正数为红, 零为白色
            computeColor = d3.interpolate(blueColor, redColor);
            maxValue = Math.abs(minValue) >= maxValue ? Math.abs(minValue) : maxValue
            minValue = -maxValue;
            isShowWhite = true;
        } else {
            computeColor = d3.interpolate(blueColor, redColor);
            //console.log("数值全负数。。。")
        }
        let scale;
        scale = d3.scaleLinear().domain([minValue, maxValue]);

        let geoValues = data.map(d => d[breakdown[0].field]);
        let projection = d3.geoMercator();

        //append map
        let GeoData = chinaGeo;
        if (mapType === "world") {
            GeoData = worldGeo;
        } else if (mapType === "china") {
            GeoData = chinaGeo;
        } else if (mapType === "usa") {
            GeoData = usStateGeo;
            projection = d3.geoAlbersUsa()
        }

        projection.fitSize([width - margin.right * 3, height], GeoData);
        // 定义地理路径生成器
        const path = d3.geoPath()
            .projection(projection);
        let focusName = this.focus()[0].value;
        let map_svg = svg.append('g').attr('class', 'map');
        map_svg.selectAll('path')
            .data(GeoData.features)
            .enter()
            .append('path')
            .attr('id', d => d.properties.enName)
            .attr('class', function (d, i) {
                if (d.properties.enName === focusName) {
                    return 'selected-path';
                }
                return '';
            })
            .attr('stroke', 'grey')
            .attr('stroke-width', 0.3)
            .attr('fill', function (d, i) {
                if (geoValues.indexOf(d.properties.enName) !== -1) {
                    let countryName = geoValues[geoValues.indexOf(d.properties.enName)]
                    let value;
                    factdata.map(data => {
                        if (data[breakdown[0].field] === countryName) {
                            value = data[measuredField];
                        }
                        return data;
                    })
                    if (!value) return Color.BACKGROUND;
                    return computeColor(scale(value));
                } else {
                    return Color.BACKGROUND;
                }
            })
            .attr('d', path);
        if (mapType === "china") {
            appendSouthChinaSea(map_svg);
        }


        /******value******/
        let oulierValue = factdata.filter(d => d[this.breakdown()[0].field] === focusName)[0][measuredField]
        let focusedNodeBox = map_svg.selectAll('.selected-path').node().getBBox();

        generateToolTip(map_svg, focusedNodeBox, focusName, oulierValue, margin, width, tickFontSize);
        /******value the end******/
        //scale map to fitin
        let maxMapWidth = width * 0.9
        let mesuredMapWidth = map_svg.node().getBBox().width;


        if (mesuredMapWidth > maxMapWidth) {
            map_svg.node().setAttribute("transform", `scale(${maxMapWidth / mesuredMapWidth})`)
            map_svg.node().setAttribute("transform-origin", "center")
        }
        //append legend 
        //定义一个线性渐变
        var defs = map_svg.append("defs");
        var linearGradient = defs.append("linearGradient")
            .attr("id", "linearColor")
            .attr("x1", "0%")
            .attr("y1", "0%")
            .attr("x2", "0%")
            .attr("y2", "100%");

        linearGradient.append("stop")
            .attr("offset", "0%")
            .attr("stop-color", computeColor(scale(maxValue)));
        if (isShowWhite) {
            linearGradient.append("stop")
                .attr("offset", '50%')
                .attr("stop-color", whiteColor);
        }
        linearGradient.append("stop")
            .attr("offset", "100%")
            .attr("stop-color", computeColor(scale(minValue)));

        //添加一个矩形，并应用线性渐变
        let starX = maxMapWidth + margin.left;
        svg.append("rect")
            .attr("x", starX)
            .attr("y", (height - rectHight) / 2)
            .attr("width", rectWidth)
            .attr("height", rectHight)
            .attr("fill", "url(#" + linearGradient.attr("id") + ")");

        let offsetText = 20;
        svg.append("text")
            .attr("class", "valueText")
            .attr("x", starX + rectWidth / 2)
            .attr("y", height / 2 + rectHight / 2 + offsetText)
            .attr("dy", "-0.3em")
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'hanging')
            .attr('font-family', NUMFONT)
            .attr('font-size', legendSize)
            .text(function () {
                return formatNumber(minValue);
            });
        svg.append("text")
            .attr("class", "valueText")
            .attr("x", starX + rectWidth / 2)
            .attr("y", height / 2 - rectHight / 2 - offsetText)
            .attr("dy", "-0.3em")
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'hanging')
            .attr('font-family', NUMFONT)
            .attr('font-size', legendSize)
            .text(function () {
                return formatNumber(maxValue);
            });

        if (this.style() === Style.COMICS) {
            let mapBBox = svg.select('.map').node().getBBox();
            let metaphorWidth = width * 0.22,
                metaphorHeight = 1.29 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor26)
                .attr("x", mapBBox.width + mapBBox.x - metaphorWidth * 0.1)
                .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 1.16);

            if (this.size() === Size.WIDE) {
                metaphorWidth = width * 0.18;
                metaphorHeight = 1.29 * metaphorWidth;
                metaphor.attr("x", mapBBox.width + mapBBox.x + metaphorWidth * 0.2)
                    .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 1.16);
            } else if (this.size() === Size.MIDDLE) {
                metaphorWidth = width * 0.23;
                metaphorHeight = 1.29 * metaphorWidth;
                metaphor.attr("x", mapBBox.width + mapBBox.x - metaphorWidth * 0.15)
                    .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 1.16);
            } else if (this.size() === Size.SMALL) {
                metaphorWidth = width * 0.23;
                metaphorHeight = 1.29 * metaphorWidth;
                metaphor.attr("x", mapBBox.width + mapBBox.x - metaphorWidth * 0.1)
                    .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 1.16);
            }

            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)

        }
        //finally update chart horizental cental
        updateChartCenter(svg, this.width(), this.height());
        return svg;
    }

    displayExtreme() {
        this.displayOutlier()
    }

    displayProportion() {
        this.displayDistribution()
    }

    displayRank() {
        this.displayDistribution()
    }

    animateValue() {
        let svg = this.displayValue();
        if (!svg) return;
        let duration = this.duration();

        svg.selectAll(".map")
            .attr("opacity", 0)
            .transition()
            .duration(duration / 10 * 2)
            .attr("opacity", 1);

        // step 1 highlight
        svg.selectAll('.selected-path')
            .attr("fill", Color.BACKGROUND)
            .transition()
            .duration(duration / 10 * 4)
            .delay(duration / 10 * 2)
            .attr("fill", Color.DEFAULT)
        
        // step 2 draw line
        let tooltipLine = svg.selectAll(".tooltip-line");
        tooltipLine.attr("opacity", 0);
        
        let _xChange, _xStay;
        if(tooltipLine.property("_direction") === "right") {
            _xChange = "x2";
            _xStay = "x1";
        } else {
            _xChange = "x1";
            _xStay = "x2";
        }
        let originalX = tooltipLine.attr(_xChange);
        tooltipLine.attr(_xChange, tooltipLine.attr(_xStay));

        svg.selectAll('.value-tooltip')
            .attr("opacity", 0);


        setTimeout(() => {
            svg.selectAll('.tooltip-line')
                .attr("opacity", 1)
            svg.selectAll('.tooltip-line')
                .transition()
                .duration(duration / 10 * 2)
                .attr(_xChange, originalX);
        }, duration / 10 * 6);

        setTimeout(() => {
            svg.selectAll('.value-tooltip')
                .attr("opacity", 0)
                .transition()
                .duration(duration / 10 * 2)
                .attr("opacity", 1)
        }, duration / 10 * 8);

        // step 3 fade in text

    }

    animateDistribution() {
        let svg = this.displayDistribution();
        if (!svg) return;
        let duration = this.duration();
        svg.attr("opacity", 0)
           .transition()
           .duration(duration / 4)
           .attr("opacity", 1)
        svg.selectAll('path')
            .each(function (d) {
                let color = d3.select(this).attr("fill")
                d3.select(this)
                    .attr("fill", Color.BACKGROUND)
                    .transition()
                    .duration(duration)
                    .delay(duration / 4)
                    .attr("fill", color)
            })
    }

    animateCategorization() {
        let svg = this.displayCategorization();
        if (!svg) return;
        let duration = this.duration();

        svg.selectAll(".map")
            .attr("opacity", 0)
            .transition()
            .duration(duration / 5)
            .attr("opacity", 1)
        
        svg.select('.legends')
            .style("opacity", 0)
            .transition()
            .duration(duration / 5)
            .style("opacity", 1);

        
        let filledPath = svg.selectAll("path")
                            .filter(function(d, i) {
                                let color = d3.select(this).attr("fill");
                                return color !== Color.BACKGROUND 
                            })
                            
        let filledPathsSize = filledPath.size();

        filledPath.each(function(d, i) {
            let color = d3.select(this).attr("fill");
            d3.select(this)
                .attr("fill", Color.BACKGROUND)
                .transition()
                .duration(duration/ 5 * 4 / filledPathsSize)
                .delay(duration / 5 + duration / 5 * 4 / filledPathsSize * i)
                .attr("fill", color)
        })
        
    }

    animateDifference() {
        let svg = this.displayDifference();
        if (!svg) return;
        let duration = this.duration();
        let difference_color1 = svg.selectAll('.difference-path-1').attr("fill");
        let difference_color2 = svg.selectAll('.difference-path-2').attr("fill");

        svg.selectAll(".map")
            .attr("opacity", 0)
            .transition()
            .duration(duration / 4)
            .attr("opacity", 1)

        
        svg.selectAll('.difference-path-1')
            .attr("fill", Color.BACKGROUND)
            .transition()
            .duration(duration / 2)
            .delay(duration / 4)
            .attr("fill", difference_color1)
        svg.selectAll('.difference-path-2')
            .attr("fill", Color.BACKGROUND)
            .transition()
            .duration(duration / 2)
            .delay(duration / 4)
            .attr("fill", difference_color2)

        svg.select('.legends')
            .style("opacity", 0)
            .transition()
            .duration(duration / 2)
            .delay(duration / 4)
            .style("opacity", 1)

        svg.selectAll('.difference-value-box')
            .attr("opacity", 0)
        setTimeout(() => {
            svg.selectAll('.difference-value-box')
                .attr("opacity", 0)
                .transition()
                .duration(duration / 4)
                .attr("opacity", 1)
        }, duration / 4 * 3);
    }

    animateOutlier() {
        this.animateDistribution()
    }

    animateExtreme() {
        this.animateDistribution()
    }

    animateProportion() {
        this.animateDistribution()
    }

    animateRank() {
        this.animateDistribution()
    }
}
const appendSouthChinaSea = (map_svg) => {
    const HWRitiao = 200 / 150;
    const sea2ChinaRitiao = 0.12;

    let appendWidth = map_svg.node().getBBox().width * sea2ChinaRitiao,
        appendHeight = appendWidth * HWRitiao,
        scale = appendWidth / 150;


    let x = map_svg.node().getBBox().width + map_svg.node().getBBox().x - appendWidth,
        y = map_svg.node().getBBox().height + map_svg.node().getBBox().y - appendHeight;
    let southchinaseaG = map_svg.append("g")
        .attr("stroke", "black")
        .attr("stroke-width", 1)
        .attr('stroke', 'grey')
        .attr("fill", "#F4F5FA")//'rgb(227, 228, 229)')
        .attr("transform-origin", `${x} ${y} `)
        .attr("transform", `scale(${scale})translate(${x} ${y})`)

    southchinaseaG.append("line")
        .attr("id", "svg_1")
        .attr("y2", 7)
        .attr("x2", 145)
        .attr("y1", 7)
        .attr("x1", 20);
    southchinaseaG.append("line")
        .attr("id", "svg_2")
        .attr("y2", 24)
        .attr("x2", 6)
        .attr("y1", 7)
        .attr("x1", 20);
    southchinaseaG.append("line")
        .attr("id", "svg_3")
        .attr("y2", 195)
        .attr("x2", 145)
        .attr("y1", 7)
        .attr("x1", 145);
    southchinaseaG.append("line")
        .attr("id", "svg_4")
        .attr("y2", 195)
        .attr("x2", 6)
        .attr("y1", 24)
        .attr("x1", 6);
    southchinaseaG.append("line")
        .attr("id", "svg_5")
        .attr("y2", 195)
        .attr("x2", 145)
        .attr("y1", 195)
        .attr("x1", 6);

    southchinaseaG.append("path")
        .attr("id", "svg_6")
        .attr("d", "m6,31.5l9,7.5l15,9l15,4l18,0l17,-14l21,-31L20,7L6,24z");
    southchinaseaG
        .append("path")
        .attr("id", "svg_7")
        .attr("d", "m113,7l10,25l11,-25z");
    southchinaseaG
        .append("path")
        .attr("id", "svg_9")
        .attr("d", "m46.5,66.5l14.5,-6.5l-1,13l-7,7l-15,4l8.5,-17.5z");

    southchinaseaG.append("line")
        .attr("id", "svg_10")
        .attr("y2", 46.5)
        .attr("x2", 132.5)
        .attr("y1", 31.5)
        .attr("x1", 141.5);
    southchinaseaG.append("line")
        .attr("id", "svg_11")
        .attr("y2", 76.5)
        .attr("x2", 115.5)
        .attr("y1", 61.5)
        .attr("x1", 121.5);
    southchinaseaG.append("line")
        .attr("id", "svg_12")
        .attr("y2", 111.5)
        .attr("x2", 110.5)
        .attr("y1", 92.5)
        .attr("x1", 110.5);
    southchinaseaG.append("line")
        .attr("id", "svg_13")
        .attr("y2", 147.5)
        .attr("x2", 101.5)
        .attr("y1", 127.5)
        .attr("x1", 108.5);
    southchinaseaG.append("line")
        .attr("id", "svg_14")
        .attr("y2", 177.5)
        .attr("x2", 78.5)
        .attr("y1", 163.5)
        .attr("x1", 91.5);

    southchinaseaG.append("line")
        .attr("id", "svg_15")
        .attr("y2", 188.5)
        .attr("x2", 39.5)
        .attr("y1", 184.5)
        .attr("x1", 54.5);
    southchinaseaG.append("line")
        .attr("id", "svg_16")
        .attr("y2", 158.5)
        .attr("x2", 11.5)
        .attr("y1", 172.5)
        .attr("x1", 17.5);
    southchinaseaG.append("line")
        .attr("id", "svg_17")
        .attr("y2", 132.5)
        .attr("x2", 39.5)
        .attr("y1", 142.5)
        .attr("x1", 24.5);
    southchinaseaG.append("line")
        .attr("id", "svg_18")
        .attr("y2", 98.5)
        .attr("x2", 37.5)
        .attr("y1", 113.5)
        .attr("x1", 40.5);

}


const getSizeBySize = (size, factType) => {
    let margin, fontSize, rectWidth, rectHight, hightLightFontSize, tickFontSize, legendSize;
    switch (size) {
        case Size.WIDE:
            tickFontSize = 16;
            rectWidth = 7;
            rectHight = 80;
            margin = {
                top: 20, right: 30, bottom: 20, left: 5
            };
            fontSize = 12;
            hightLightFontSize = 26;
            legendSize = 14;
            break;
        case Size.MIDDLE:
            tickFontSize = 14;
            rectWidth = 6;
            rectHight = 65;
            margin = {
                top: 20, right: 15, bottom: 20, left: 5
            };
            if (factType === "categorization") {
                margin = {
                    top: 20, right: 30, bottom: 20, left: 30
                };
            }
            if (factType === "difference") {
                margin = {
                    top: 20, right: 30, bottom: 10, left: 30
                };
            }
            fontSize = 10;
            hightLightFontSize = 20;
            legendSize = 12;
            break;
        case Size.SMALL:
            tickFontSize = 12;
            rectWidth = 4;
            rectHight = 50;
            margin = {
                top: 20, right: 13, bottom: 20, left: 5
            };
            if (factType === "categorization" || factType === "difference") {
                margin = {
                    top: 10, right: 15, bottom: 10, left: 15
                };
            }
            fontSize = 8;
            legendSize = 10;
            hightLightFontSize = 16;
            break;
        case Size.LARGE:
        default:
            tickFontSize = 20;
            rectWidth = 12;
            rectHight = 150;
            margin = {
                top: 20, right: 40, bottom: 20, left: 40
            };
            fontSize = 14;
            hightLightFontSize = 40;
            legendSize = 21;
            break;
    }
    return {
        margin,
        rectWidth,
        rectHight,
        fontSize,
        hightLightFontSize,
        tickFontSize,
        legendSize
    }
}
export default FilledMap;