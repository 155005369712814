import Color from './color';
import * as d3 from 'd3';

const NUMFONT = "Arial-Regular";
const unsupportedchart = (svg, chartSize, annotationSize, size) => {
    let { left, top, offset, iconMargin, iconR, strokeW } = getMargin(size);
    let group = svg.append("g")
        .attr("transform", `translate(${left},${top})`);


    group.append("rect")
        .attr("width", chartSize.width - 2 * left)
        .attr("height", chartSize.height - 2 * top)
        .attr("x", offset)
        .attr("y", offset)
        .attr("fill", Color.DEFAULT);

    group.append("rect")
        .attr("width", chartSize.width - 2 * left)
        .attr("height", chartSize.height - 2 * top)
        .attr("fill", Color.BACKGROUND)
        .attr("stroke", Color.DEFAULT)
        .attr("stroke-width", strokeW * 1.2);

    let contentGroup = group.append("g");
    let r = iconR,
        startY = (chartSize.height - 2 * top) / 2;

    let arc = d3.arc()
        .innerRadius(r - strokeW * 2)
        .outerRadius(r)
        .startAngle(-1 / 2 * Math.PI - 1 / 6 * Math.PI)
        .endAngle(Math.PI + 1 / 6 * Math.PI);

    contentGroup
        .append("g")
        .attr("class", "arc")
        .attr("transform", `translate(${iconR + iconMargin},${startY})`)
        .append("path")
        .attr("d", arc())
        .attr("fill", Color.DEFAULT);

    let rectW = r * 0.2;
    contentGroup.append("rect")
        .attr("width", rectW)
        .attr("height", iconR * 0.7)
        .attr("x", iconR + iconMargin - rectW / 2)
        .attr("y", startY - iconR * 0.5)
        .attr("rx", rectW / 2)
        .attr("ry", rectW / 2)
        .attr("fill", Color.DEFAULT);

    contentGroup.append("circle")
        .attr("cx", iconR + iconMargin)
        .attr("cy", startY + iconR * 0.5)
        .attr("r", rectW / 1.5)
        .attr("fill", Color.DEFAULT);

    if (size === 'middle' || size === 'small') {
        annotationSize = annotationSize * 0.7;
    }
    let textStartX = (iconR + iconMargin) * 2;
    let textE = contentGroup.append("text")
        .attr("font-family", NUMFONT)
        .attr("font-size", annotationSize * 1.3)
        .attr("text-anchor", 'start')
        .attr("width", chartSize.width - 4 * left - 2 * r)
        .attr("height", (chartSize.height - 2 * top) / 2)
        .attr("x", textStartX)
        .attr("y", startY)
        .attr("dominant-baseline", "central");

    // let h = "2";
    // if (size === "small") {
    //     h = "1";
    // }
    textE.append("tspan")
        .attr("x", textStartX)
        // .attr("dy", `-${h / 2}rem`)
        .attr("dy", - offset)
        .text("The current data does not");

    textE.append("tspan")
        .attr("x", textStartX)
        // .attr("dy", `${h}rem`)
        .attr("dy", offset * 2)
        .text("apply to this chart.");

};
/*
 * offset 背景偏移量
 */
const getMargin = (size) => {
    switch (size) {
        case 'large':
            return {
                left: 40,
                top: 180,
                offset: 15,
                iconMargin: 35,
                iconR: 35,
                strokeW: 2.5,
            }
        case 'middle':
            return {
                left: 30,
                top: 40,
                offset: 10,
                iconMargin: 10,
                iconR: 18,
                strokeW: 1.5,
            }
        case 'wide':
            return {
                left: 60,
                top: 30,
                offset: 10,
                iconMargin: 20,
                iconR: 25,
                strokeW: 2,
            }
        case 'small':
            return {
                left: 10,
                top: 30,
                offset: 5,
                iconMargin: 10,
                iconR: 15,
                strokeW: 1.5,
            }
        default:
            return {
                left: 30,
                top: 30,
                offset: 15,
                iconMargin: 10,
                iconR: 15,
                strokeW: 2.5,
            }
    }
}

export default unsupportedchart;