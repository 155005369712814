import React from 'react';
// import Chart from '../../Chart';
// import uuidv4 from 'uuid/v4';
import { Player, BigPlayButton } from 'video-react';

import './GallaryPage.css';

export default class GallaryPage extends React.Component {

    render() {

        let info = {
            "USElection": {
                title: "2020 US presidential election",
                dataSource: "https://www.kaggle.com/unanimad/us-election-2020",
                csv: "https://autoclips.idvxlab.com/gallery/election.csv",
                json: "https://autoclips.idvxlab.com/gallery/election.json"
            },
            "AmazonBestsellers": {
                title: "Amazon Bestsellers",
                dataSource: "https://www.kaggle.com/sootersaalu/amazon-top-50-bestselling-books-2009-2019",
                csv: "https://autoclips.idvxlab.com/gallery/amazon.csv",
                json: "https://autoclips.idvxlab.com/gallery/amazon.json"
            },
            "Netflix": {
                title: "Netflix Movies",
                dataSource: "https://www.kaggle.com/shivamb/netflix-shows",
                csv: "https://autoclips.idvxlab.com/gallery/netflix.csv",
                json: "https://autoclips.idvxlab.com/gallery/netflix.json"
            },
            "App": {
                title: "Google Play Store Apps",
                dataSource: "https://www.kaggle.com/lava18/google-play-store-apps",
                csv: "https://autoclips.idvxlab.com/gallery/google.csv",
                json: "https://autoclips.idvxlab.com/gallery/google.json"
            },
            "Steam": {
                title: "Steam Store Games",
                dataSource: "https://www.kaggle.com/nikdavis/steam-store-games",
                csv: "https://autoclips.idvxlab.com/gallery/steam.csv",
                json: "https://autoclips.idvxlab.com/gallery/steam.json"
            },
            // "Women": {
            //     title: "Women Entrepreneurship and Labor Force",
            //     dataSource: "https://www.kaggle.com/babyoda/women-entrepreneurship-and-labor-force",
            //     csv: "https://autoclips.idvxlab.com/gallery/amazon.csv",
            //     json: "https://autoclips.idvxlab.com/gallery/amazon.json"
            // }

        }
        return (
            <div className='gallery-view'>
                <div className='gallery-title'><h1>Data Video Gallery</h1></div>
                <div style={{ textAlign: "center", margin: "0 auto", width: 960 }}>
                    <div className="gallery-video" >
                        <Player height={640} width={1280} src="https://autoclips.idvxlab.com/gallery/election.mp4">
                            <BigPlayButton position="center" />
                        </Player>
                        <div className="gallery-video-intro">
                            <p className="gallery-video-title">{info.USElection.title}</p>
                            <div>
                                <p className="gallery-video-source"><a target="_blank" rel="noopener noreferrer" href={info.USElection.dataSource}>Data Source</a></p>
                                <p className="gallery-video-source"><a target="_blank" rel="noopener noreferrer" href={info.USElection.csv}>CSV</a></p>
                                <p className="gallery-video-source"><a target="_blank" rel="noopener noreferrer" href={info.USElection.json}>Facts</a></p>
                            </div>

                        </div>
                    </div>
                    <div className="gallery-video" >
                        <Player height={640} width={1280} src="https://autoclips.idvxlab.com/gallery/amazon.mp4">
                            <BigPlayButton position="center" />
                        </Player>
                        <div className="gallery-video-intro">
                            <p className="gallery-video-title">{info.AmazonBestsellers.title}</p>
                            <p className="gallery-video-source"><a target="_blank" rel="noopener noreferrer" href={info.AmazonBestsellers.dataSource}>Data Source</a></p>
                            <p className="gallery-video-source"><a target="_blank" rel="noopener noreferrer" href={info.AmazonBestsellers.csv}>CSV</a></p>
                            <p className="gallery-video-source"><a target="_blank" rel="noopener noreferrer" href={info.AmazonBestsellers.json}>Facts</a></p>
                        </div>
                    </div>
                    <div className="gallery-video" >
                        <Player height={640} width={1280} src="https://autoclips.idvxlab.com/gallery/netflix.mp4">
                            <BigPlayButton position="center" />
                        </Player>
                        <div className="gallery-video-intro">
                            <p className="gallery-video-title">{info.Netflix.title}</p>
                            <p className="gallery-video-source"><a target="_blank" rel="noopener noreferrer" href={info.Netflix.dataSource}>Data Source</a></p>
                            <p className="gallery-video-source"><a target="_blank" rel="noopener noreferrer" href={info.Netflix.csv}>CSV</a></p>
                            <p className="gallery-video-source"><a target="_blank" rel="noopener noreferrer" href={info.Netflix.json}>Facts</a></p>
                        </div>
                    </div>
                    <div className="gallery-video" >
                        <Player height={640} width={1280} src="https://autoclips.idvxlab.com/gallery/google.mp4">
                            <BigPlayButton position="center" />
                        </Player>
                        <div className="gallery-video-intro">
                            <p className="gallery-video-title">{info.App.title}</p>
                            <p className="gallery-video-source"><a target="_blank" rel="noopener noreferrer" href={info.App.dataSource}>Data Source</a></p>
                            <p className="gallery-video-source"><a target="_blank" rel="noopener noreferrer" href={info.App.csv}>CSV</a></p>
                            <p className="gallery-video-source"><a target="_blank" rel="noopener noreferrer" href={info.App.json}>Facts</a></p>
                        </div>
                    </div>
                    <div className="gallery-video" >
                        <Player height={640} width={1280} src="https://autoclips.idvxlab.com/gallery/steam.mp4">
                            <BigPlayButton position="center" />
                        </Player>
                        <div className="gallery-video-intro">
                            <p className="gallery-video-title">{info.Steam.title}</p>
                            <p className="gallery-video-source"><a target="_blank" rel="noopener noreferrer" href={info.Steam.dataSource}>Data Source</a></p>
                            <p className="gallery-video-source"><a target="_blank" rel="noopener noreferrer" href={info.Steam.csv}>CSV</a></p>
                            <p className="gallery-video-source"><a target="_blank" rel="noopener noreferrer" href={info.Steam.json}>Facts</a></p>
                        </div>
                    </div>
                </div>

                <div style={{ margin: '0 auto', textAlign: 'center', marginTop: 200, marginBottom: 200 }}>
                    <p style={{ margin: 10 }}>Please refer to Narrative Chart (https://narchart.github.io)</p>
                    <img style={{ height: 30 }} src={require('./Loading.png')} alt="" />
                </div>
            </div>
        )
    }
}