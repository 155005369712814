import * as d3 from 'd3';
import Size from './visualization/size';
import Style from './visualization/style';
import FactType from './visualization/facttype';

class Chart {
    constructor() {
        this._container = document.createElement("div");
        this._style = Style.BUSINESS;
        this._size = Size.LARGE;
        this._factdata = [];
        this._subspace = [];
        this._measure = [];
        this._breakdown = [];
        this._focus = [];
        this._duration = 0;
        this._showSuggestion = false; // When true, it will show the suggestion that whether the chart supports the data.
    }

    container(value) {
        if (!value) {
            d3.select(this._container).selectAll("*").remove();
            return this._container;
        }
        this._container = value;
    }

    style(value) {
        if (!value) {
            return this._style;
        }
        this._style = value;
    }

    size(value) {
        if (!value) {
            return this._size;
        }
        this._size = value;
    }

    width() {
        switch (this.size()) {
            case Size.LARGE:
                return 640;
            case Size.WIDE:
                return 560;
            case Size.MIDDLE:
                return 360;
            case Size.SMALL:
                return 235;
            default:
                return 640;
        }
    }

    height() {
        switch (this.size()) {
            case Size.LARGE:
                return 640;
            case Size.WIDE:
                return 220;
            case Size.MIDDLE:
                return 200;
            case Size.SMALL:
                return 150;
            default:
                return 640;
        }
    }

    factdata(value) {
        if (!value) {
            return this._factdata;
        }
        this._factdata = value;
    }

    subspace(value) {
        if (!value) {
            return this._subspace;
        }
        this._subspace = value;
    }

    measure(value) {
        if (!value) {
            return this._measure;
        }
        this._measure = value;
    }

    breakdown(value) {
        if (!value) {
            return this._breakdown;
        }
        this._breakdown = value;
    }

    focus(value) {
        if (!value) {
            return this._focus;
        }
        this._focus = value
    }

    duration(value) {
        if (!value) {
            return this._duration;
        }
        this._duration = value;
    }

    showSuggestion(value) {
        if (!value) {
            return this._showSuggestion;
        }
        this._showSuggestion = value;
    }

    display(type) {
        let svg;
        switch (type) {
            case FactType.Association:
                svg = this.displayAssociation();
                break;
            case FactType.Categorization:
                svg = this.displayCategorization();
                break;
            case FactType.Difference:
                svg = this.displayDifference();
                break;
            case FactType.Distribution:
                svg = this.displayDistribution();
                break;
            case FactType.Extreme:
                svg = this.displayExtreme();
                break;
            case FactType.Outlier:
                svg = this.displayOutlier();
                break;
            case FactType.Proportion:
                svg = this.displayProportion();
                break;
            case FactType.Rank:
                svg = this.displayRank();
                break;
            case FactType.Trend:
                svg = this.displayTrend();
                break;
            case FactType.Value:
                svg = this.displayValue();
                break;
            default:
                return null;
        }
        return svg;
    }

    animate(type) {
        let svg;
        switch (type) {
            case FactType.Association:
                svg = this.animateAssociation();
                break;
            case FactType.Categorization:
                svg = this.animateCategorization();
                break;
            case FactType.Difference:
                svg = this.animateDifference();
                break;
            case FactType.Distribution:
                svg = this.animateDistribution();
                break;
            case FactType.Extreme:
                svg = this.animateExtreme();
                break;
            case FactType.Outlier:
                svg = this.animateOutlier();
                break;
            case FactType.Proportion:
                svg = this.animateProportion();
                break;
            case FactType.Rank:
                svg = this.animateRank();
                break;
            case FactType.Trend:
                svg = this.animateTrend();
                break;
            case FactType.Value:
                svg = this.animateValue();
                break;
            default:
                return null;
        }
        return svg;
    }

    toSVG() {

    }

    toJPG() {

    }

    /* static */

    displayAssociation() { }

    displayCategorization() { }

    displayDifference() { }

    displayDistribution() { }

    displayExtreme() { }

    displayOutlier() { }

    displayProportion() { }

    displayRank() { }

    displayTrend() { }

    displayValue() { }

    /* animation */

    animateAssociation() { this.displayAssociation() }

    animateCategorization() { this.displayCategorization() }

    animateDifference() { this.displayDifference() }

    animateDistribution() { this.displayDistribution() }

    animateExtreme() { this.displayExtreme() }

    animateOutlier() { this.displayOutlier() }

    animateProportion() { this.displayProportion() }

    animateRank() { this.displayRank() }

    animateTrend() { this.displayTrend() }

    animateValue() { this.displayValue() }
}

export default Chart;