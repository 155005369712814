import * as d3 from 'd3';
import Color from '../visualization/color';
import textMultiline from './multiline';

const DirectionType = {
    'Right': 0,
    'Left': 1,
    'Up': 2, 
    'Down': 3
} 

const fontFamily = {
    'temporal': 'Arial-Regular',
    'categorical': 'Arial-Regular',
    'numerical': 'Arial-Regular', 
}

let generateToolTip = (circleX, circleY, toolTipValue, svg, chartSize, annotationSize, direction = "Up", textType = "numerical") => {
    //direction:tooltip的位置, Up在上方，Down在下方，Right在右边，Left在左边
    //textType: 根据文字的类型设置字体
    let toolTipSvg = svg.append("g").attr("class", "tooltip")
    let tooltipRect = toolTipSvg
        .append('rect')
        .attr("class", "tooltipRect");

    let tooltip = toolTipSvg.append("text")
        .attr("font-size", annotationSize)
        .attr("font-family", fontFamily[textType] || 'Arial-Bold')
        .attr("fill", 'white');

    if(textType === "categorical" || textType === "geographical"){
        tooltip.remove();
        tooltip = textMultiline(toolTipSvg, toolTipValue, annotationSize, chartSize.width*0.3, circleX, circleY);
        tooltip
            .attr("transform", "translate(" + circleX + "," + 0 + ")")
            .attr("fill", 'white');
    }else{
        tooltip.append("tspan")
            .text(toolTipValue)
            .attr("dy", tooltip.selectAll("tspan").node().getBBox().height * 0.9);
    }
    let textWidth = tooltip.node().getBBox().width;
    let textHeight = tooltip.node().getBBox().height;
    let widthAlpha = 0.7;
    let rectWidth = Math.min(textWidth / widthAlpha, textWidth + 12);
    let rectHeight = textHeight / 0.8;
    tooltipRect
        .attr("rx", 0.1 * textHeight)
        .attr("ry", 0.1 * textHeight)
        .attr("width", rectWidth)
        .attr("height", rectHeight)
        .attr("fill", Color.ANNOTATION)
        .attr("opacity", 1.0);

    let tooltipTriangle = toolTipSvg.append("path")
        .attr("class", "triangle")
        .attr("transform", "translate(" + circleX + "," + (circleY) + ")rotate(180)")
        .attr("d", d3.symbol().type(d3.symbolTriangle).size(chartSize.height / 8))
        .attr("fill", Color.ANNOTATION);
    
    tooltipRect.attr("x", circleX - rectWidth / 2)
                .attr("y", circleY - rectHeight );
    
    tooltip.attr("width", textWidth)
            .attr("height", textHeight)
            .attr("x", circleX - rectWidth / 2 + (rectWidth - textWidth)/2)
            .attr("y",  circleY - rectHeight/2 - rectHeight / 2);

    switch(DirectionType[direction]){
        case 0:
            tooltipTriangle.attr("opacity", 0);
            tooltipRect.attr("x", circleX)
                .attr("y", circleY - rectHeight / 2);
            tooltip.attr("x", circleX + (rectWidth - textWidth)/2)
                .attr("y", circleY - rectHeight / 2.1);
            break;
        case 1:
            tooltipTriangle.attr("opacity", 0);
            tooltipRect.attr("x", circleX - rectWidth)
                .attr("y", circleY - rectHeight / 2);
            tooltip.attr("x", circleX - rectWidth + (rectWidth - textWidth)/2)
                .attr("y", circleY - rectHeight / 2.1);
            break;
        case 3:
            tooltipTriangle.attr("transform", "translate(" + circleX + "," + circleY + ")");
            tooltipRect.attr("y", circleY);
            tooltip.attr("y",  circleY + rectHeight / 2 - rectHeight / 2);
            break;
        default:
            break;
    }
}

export default generateToolTip;