import * as d3 from 'd3';
import Chart from '../../chart';
import Color from '../../visualization/color';
import Size from '../../visualization/size';
import Style from '../../visualization/style';
import generateToolTip from '../../visualization/tooltip';
// import unsupportedchart from '../../visualization/unsupportedchart';
import formatNumber from '../../visualization/format';
import PictorialType from '../../visualization/pictogram';
import metaphor14 from '../../metaphor/metaphor14.png';//trend
import metaphor13 from '../../metaphor/metaphor13.png';//categorization
import metaphor22_0 from '../../metaphor/metaphor22_0.png';//outlier
import metaphor22 from '../../metaphor/metaphor22.png';//outlier
import metaphor23 from '../../metaphor/metaphor23.png';//distribution

import uuidv4 from 'uuid/v4';

const fontSize = {
    "small": {
        "axis": 5,
        "label": 10
    },
    "middle": {
        "axis": 6,
        "label": 14
    },
    "wide": {
        "axis": 8,
        "label": 14
    },
    "large": {
        "axis": 12,
        "label": 20
    }
}
const NUMFONT = "Arial-Regular";
const TEXTFONT = "Arial-Bold";
class BubbleChart extends Chart {
    animateDistribution() {
        /* -------------------------------- basic vis ------------------------------- */
        let svg = this.displayDistribution();
        if (!svg) return;

        /* -------------------------------- init data ------------------------------- */
        // let ticks = 10;
        let duration = this.duration();

        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        // let animation = {
        //     majorFadeIn: {
        //         duration: 10,
        //         index: 0
        //     }
        // }
        // let everyTick = duration / ticks;

        /* --------------------------- step 0 majorFadeIn --------------------------- */
        let items = svg.selectAll(".items").selectAll('circle')
        let circleR = items.nodes().map((d, i) => {
            return Number(d.getAttribute("r"))
        })
        svg.selectAll('circle')
            .attr("r", 0)
            .transition()
            .duration(duration)
            .attr("r", function (d, i) {
                return circleR[i]
            });

        svg.selectAll('.pvalue').attr("opacity", 0);
            // .transition()
            // .duration(duration)
            // .ease(d3.easeLinear)
            // .textTween(function (d) {
            //     let final = d3.select(this).node().innerHTML.replace(/,/g, '');;
            //     const i = d3.interpolate(0, final);
            //     let format = d3.format(",d")
            //     return function (t) {
            //         var num = parseInt(i(t));
            //         return format(num);
            //     };
            // });

        // items.attr("opacity", 0);

        // items.transition()
        //     .duration(everyTick * animation.majorFadeIn.duration / items.size())
        //     .delay((d, i) => i * (everyTick * animation.majorFadeIn.duration / items.size()))
        //     .attr("opacity", 1);

    }

    animateCategorization() {
        /* -------------------------------- basic vis ------------------------------- */
        let svg = this.displayCategorization();
        if (!svg) return;

        /* -------------------------------- init data ------------------------------- */
        let ticks = 10;
        let duration = this.duration();

        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            majorFadeIn: {
                duration: 10,
                index: 0
            }
        }
        let everyTick = duration / ticks;

        /* --------------------------- step 0 majorFadeIn --------------------------- */
        let items = svg.selectAll(".items")
        items.attr("opacity", 0);

        items.transition()
            .duration(everyTick * animation.majorFadeIn.duration / items.size())
            .delay((d, i) => i * (everyTick * animation.majorFadeIn.duration / items.size()))
            .attr("opacity", 1);




    }

    animateTrend() {
        /* -------------------------------- basic vis ------------------------------- */
        let svg = this.displayTrend();
        if (!svg) return;

        /* -------------------------------- init data ------------------------------- */
        let ticks = 10;
        let duration = this.duration();

        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            majorFadeIn: {
                duration: 8,
                index: 0
            },
            trendDraw: {
                duration: 2,
                index: 1
            }
        }
        let everyTick = duration / ticks;

        /* --------------------------- step 0 majorFadeIn --------------------------- */
        let items = svg.selectAll(".items")
        items.attr("opacity", 0);

        items.transition()
            .duration(everyTick * animation.majorFadeIn.duration / items.size())
            .delay((d, i) => i * (everyTick * animation.majorFadeIn.duration / items.size()))
            .attr("opacity", 1);

        /* ---------------------------- step 1 trendDraw ---------------------------- */
        let firstCircle = items.nodes()[0],
            lastCircle = items.nodes()[items.size()-1];

        let firstCircleY = firstCircle.getBBox().y - 40,
            lastCircleY = lastCircle.getBBox().y - 40; 
        
        if(this.size() !== 'large') {
            firstCircleY = firstCircle.getBBox().y - 20;
            lastCircleY = lastCircle.getBBox().y - 20; 
        }
        
        let firstCircleX = firstCircle.getBBox().x + firstCircle.getBBox().width / 2,
            lastCircleX = lastCircle.getBBox().x + lastCircle.getBBox().width / 2;
        
        let trendLineG = d3.line()
                .x(function (d) { return d.x; })
                .y(function (d) { return d.y });
        let trendData = [{x:firstCircleX, y: firstCircleY }, {x: lastCircleX, y: lastCircleY}]
        let trendSVG = svg.select("g").append("g")
        trendSVG.append("path")
            .attr("d", trendLineG(trendData))
            .attr("stroke", Color.HIGHLIGHT)
            .attr("stroke-width", d=> {
                if (this.size() === 'small') return 2;
                if (this.size() === 'middle') return 3;
                if (this.size() === 'wide') return 4;
                if (this.size() === 'large') return 5;
            })
            .attr('stroke-dasharray', '5,5');
  

        let finalPosition = trendSVG.select("path").attr("d").split("L").slice(-1)[0];
        let secondPosition = trendSVG.select("path").attr("d").split("L").slice(-2)[0];
        secondPosition = secondPosition.substring(1)
        let f_x = finalPosition.split(",")[0],
            f_y = this.height() - finalPosition.split(",")[1],
            s_x = secondPosition.split(",")[0],
            s_y = this.height() - secondPosition.split(",")[1];
        function getTanDeg(tan) {
            var result = Math.atan(tan) / (Math.PI / 180);
            result = Math.round(result);
            return result;
        }
        let slope = (f_y - s_y) / (f_x - s_x)
        let deg;
        if (getTanDeg(slope) < 0) {
            deg = Math.abs(getTanDeg(slope)) + 90;
        } else {
            deg = - getTanDeg(slope) + 90;
        }
        
        trendSVG.append("path")
            .attr("class", "triangle")
            .attr("transform", "translate(" + finalPosition + ")rotate(" + deg + ")")
            .attr("d", d3.symbol().type(d3.symbolTriangle).size(0.16 * this.height()))
            .attr("fill", Color.HIGHLIGHT);
        

        let uuid = uuidv4();
        trendSVG.attr("id", "trendSVGClip")
            .attr("clip-path", "url(#clip_trend_"+uuid+")");

        let defsX = trendSVG.node().getBBox().x,
            defsY = trendSVG.node().getBBox().y,
            defsHeight = trendSVG.node().getBBox().height,
            defsWidth = trendSVG.node().getBBox().width;

        trendSVG.append("defs")
            .attr("class", "trend_defs")
            .append("clipPath")
            .attr("id", "clip_trend_"+uuid)
            .append("rect")
            .attr("x", defsX - 10)
            .attr("y", defsY - 10)
            .attr("width", 0)
            .attr("height", defsHeight + 20);

        setTimeout(() => {
            trendSVG.select("#clip_trend_"+uuid+" rect")
                .attr("width", 0)
                .transition()
                .duration(everyTick * (animation.trendDraw.duration))
                .ease(d3.easeLinear)
                .attr("width", defsWidth + 10)
        }, everyTick * countTicksBeforeIndex(animation, animation.trendDraw.index))
        
    }

    animateExtreme() {
        /* -------------------------------- basic vis ------------------------------- */
        let svg = this.displayExtreme();
        if (!svg) return;

        /* -------------------------------- init data ------------------------------- */
        let ticks = 10;
        let duration = this.duration();

        let breakdown = this.breakdown();
        let focus = this.focus();

        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            majorFadeIn: {
                duration: 3,
                // duration: 0,
                index: 0
            },
            fillColor: {
                duration: 3,
                index: 1
            },
            showTooltip: {
                duration: 3,
                index: 2
            },
        }
        let everyTick = duration / ticks;

        /* --------------------------- step 0 majorFadeIn --------------------------- */
        let items = svg.selectAll(".items")
        items.attr("opacity", 0);
        items.selectAll("circle").attr("fill", Color.DEFAULT)
        items.selectAll("text").attr("fill", "black")
        items.transition()
            .duration(everyTick * animation.majorFadeIn.duration)
            .attr("opacity", 1);
        // items.transition()
        //     .duration(everyTick * animation.majorFadeIn.duration / items.size())
        //     .delay((d, i) => i * (everyTick * animation.majorFadeIn.duration / items.size()))
        //     .attr("opacity", 1);

        /* ---------------------------- step 1 fillColor ---------------------------- */
        setTimeout(() => {
            items.filter(d => d[breakdown[0].field] === focus[0].value)
                .select("circle")
                .transition()
                .duration(everyTick * animation.fillColor.duration)
                .attr("fill", Color.HIGHLIGHT);

            items.filter(d => d[breakdown[0].field] === focus[0].value)
                .select(".labels")
                .transition()
                .duration(everyTick * animation.fillColor.duration)
                .attr("fill", Color.ANNOTATION)
        }, everyTick * countTicksBeforeIndex(animation, animation.fillColor.index));

        /* --------------------------- step 1 showTooltip --------------------------- */
        let tooltip = svg.selectAll(".tooltip");
        tooltip.attr("opacity", 0);

        setTimeout(() => {
            tooltip
                .transition()
                .duration(everyTick * animation.showTooltip.duration)
                .attr("opacity", 1);
        }, everyTick * countTicksBeforeIndex(animation, animation.showTooltip.index));

    }

    displayDistribution() {
        if (this.style() === Style.BUSINESS || this.style() === Style.COMICS) {
            let data = this.factdata();
            let measure = this.measure();
            let breakdown = this.breakdown();
            let measureField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field;
            // set the dimensions and margins of the graph
            // let isMatrix = style.style === 'matrix' ? true : false;
            let isMatrix = true;
            let { margin, maxBubbleNum, substringTxtL, bubbleMT } = getSizeBySize(this.size(), "distribution", isMatrix),
                width = this.width() - margin.left - margin.right,
                height = this.height() - margin.top - margin.bottom,
                font = fontSize[this.size()];

            let svg = d3.select(this.container())
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom);

            if (measure.length > 1) {
                svg.append("rect")
                    .attr("width", width)
                    .attr("height", height).attr("fill", "none");
                return svg;
            }
            if (this.style() === Style.COMICS) width = 0.8 * width;
            data = data.filter(d => d[measureField] > 0);
            data = data.slice(0, maxBubbleNum)
            // if (data.length > maxBubbleNum) {
            //     let chartSize = {
            //         width: this.width(),
            //         height: this.height()
            //     }
            //     unsupportedchart(svg, chartSize, annotationSize, this.size());
            //     return svg;
            // }

            //if same year
            let sameYear = "";
            if (breakdown[0].type === "temporal") {
                try {
                    let year = getSameYear(data.map(d => d[breakdown[0].field]));
                    sameYear = year + "/";
                } catch (e) {
                    //keep origin
                }
            }
            let contentG = svg.append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            // let textHeight = 0;
            if (this.size() === Size.LARGE && sameYear !== "" && sameYear !== "false/") {
                //let yearText = 
                svg.append("text")
                    .attr("class", "sameYear")
                    .text("Year:" + sameYear.replace("/", ""))
                    .attr("font-size", '21')
                    .attr("font-family", NUMFONT)
                    .attr("y", 60)
                    .attr("x", this.width() / 2)
                    .attr("fill", Color.TEXT)
                    .attr("text-anchor", "middle")
                    .attr('dominant-baseline', 'middle');
                //textHeight = yearText.node().getBBox().height+10;//margin
            }
            data = data.map(d => {
                d[breakdown[0].field] = d[breakdown[0].field].replace(sameYear, "");
                return d;
            })

            let row, column;
            let categories = data;
            if (isMatrix) {
                let n = categories.length;
                if (n < 6) {
                    row = 1;
                    column = n
                } else {
                    let max_sqrt = Math.floor(Math.sqrt(n));
                    let candidate;
                    if (isPrime(n)) n = n - 1
                    while (max_sqrt) {
                        if (n % max_sqrt === 0) {
                            candidate = max_sqrt
                            break;
                        }
                        max_sqrt -= 1
                    }
                    row = d3.min([candidate, n / candidate])
                    column = n / row
                }

            } else {
                row = 1;
                column = categories.length;
            }
            let maxR = d3.min([0.9 * height / (row + 1) / 2, 0.9 * width / (column + 1) / 2])
            //Size channels
            let size = d3.scaleLinear()
                .domain([0, d3.max(data, function (d) { return Math.sqrt(d[measureField]); })])
                .range([0, maxR]);

            // Draw Circles
            let rowTotalWidth;
            let _that = this;
            if (categories.length % 2 === 0) {
                rowTotalWidth = 2.4 * maxR * column;
            } else {
                rowTotalWidth = 2.4 * maxR * (column + 1);
            }
            let PcontentG = contentG.append("g");
            let PcontentG_value = contentG.append("g");

            let proportions = PcontentG.append('g')
                .selectAll('g')
                .data(data)
                .enter()
                .append('g')
                .attr("class", "items")
                .each(function (d, i) {
                    let rowNow;
                    if (isPrime(categories.length) && i === categories.length - 1) {
                        rowNow = Math.floor((i - 1) / column)
                    } else {
                        rowNow = Math.floor(i / column);
                    }
                    d3.select(this).attr("transform", `translate(0,${bubbleMT * rowNow})`); //行与行之间的间隔
                });

            let proportions_value = PcontentG_value.append('g')
                .selectAll('g')
                .data(data)
                .enter()
                .append('g')
                .attr("class", "items")
                .each(function (d, i) {
                    let rowNow;
                    if (isPrime(categories.length) && i === categories.length - 1) {
                        rowNow = Math.floor((i - 1) / column)
                    } else {
                        rowNow = Math.floor(i / column);
                    }
                    d3.select(this).attr("transform", `translate(0,${bubbleMT * rowNow})`); //行与行之间的间隔
                });
            proportions.append("circle")
                .style('stroke-width', '1')
                .attr("class", "data-item")
                .attr("size", function (d) { return d[measureField]; })
                .attr("fill", (d, i) => {
                    return Color.DEFAULT
                })
                .attr("r", function (d) { return size(Math.sqrt(d[measureField])); })
                .attr("cx", function (d, i) {
                    if (column === 1) {
                        if (data.length === 2) {//异常处理
                            return i === 0 ? size(Math.sqrt(d[measureField])) : 2.4 * maxR + size(Math.sqrt(d[measureField]))
                        }
                        return (_that.width() - size(Math.sqrt(d[measureField]))) / 2
                    }
                    if (!isMatrix) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                    else if (isPrime(categories.length) && Math.floor(i / column) === row - 1) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor(i % column))
                    } else if (isPrime(categories.length) && Math.floor(i / column) === row) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor((i - 1) % column) + 1)
                    } else {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                })
                .attr("cy", function (d, i) {
                    let startPoint = (height - 3.6 * maxR) / (row - 1) <= 2.5 * maxR ? 1.8 * maxR : (height - 2.5 * maxR * (row - 1)) / 2;
                    if (!isMatrix) {
                        return 1.8 * maxR + (height - 3.6 * maxR) / 2
                    }
                    else if (isPrime(categories.length) && i === categories.length - 1) {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i - 1) / column))
                    } else {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor(i / column))
                    }
                });
            
            
            
            //value
            proportions_value.append('text')
                .attr("class", "pvalue")
                .attr('x', function (d, i) {
                    if (column === 1) {
                        if (data.length === 2) {//异常处理
                            return i === 0 ? size(Math.sqrt(d[measureField])) : 2.4 * maxR + size(Math.sqrt(d[measureField]))
                        }
                        return (_that.width() - size(Math.sqrt(d[measureField]))) / 2
                    }
                    if (!isMatrix) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                    else if (isPrime(categories.length) && Math.floor(i / column) === row - 1) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor(i % column))
                    } else if (isPrime(categories.length) && Math.floor(i / column) === row) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor((i - 1) % column) + 1)
                    } else {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                })
                .attr('y', function (d, i) {
                    let startPoint = (height - 3.6 * maxR) / (row - 1) <= 2.5 * maxR ? 1.8 * maxR : (height - 2.5 * maxR * (row - 1)) / 2;
                    if (!isMatrix) {
                        return 1.8 * maxR + (height - 3.6 * maxR) / 2
                    }
                    else if (isPrime(categories.length) && i === categories.length - 1) {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i - 1) / column))
                    } else {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor(i / column))
                    }
                })
                .text(d => formatNumber(d[measureField]))
                //.attr('fill', 'white')
                .attr('fill', function (d, i) {
                    return "#000000"
                    // if (Color.CATEGORICAL[i % 10].length !== 7 || formatNumber(d[measureField]).length > 8) return "#000000"
                    // let { r, g, b } = hexToRGB(Color.CATEGORICAL[i % 10])
                    // if (r * 0.299 + g * 0.587 + b * 0.114 > 186) return "#000000"
                    // else
                    //     return "#ffffff"
                })
                .attr('alignment-baseline', 'middle')
                .attr('font-size', font.label * 0.9)
                .attr("font-family", NUMFONT)
                .attr('text-anchor', 'middle');

            // proportions_value.each(function (d, i) {
            //     let _g = d3.select(this);
            //     if (_g.selectAll(".pvalue").node().getBBox().width > _g.selectAll("circle").attr("r") * 2) {
            //         _g.selectAll(".pvalue").attr("fill", "#000000")
            //     }

            // })

            //breakdown
            proportions.append('text')
                .attr('x', function (d, i) {
                    if (column === 1) {
                        if (data.length === 2) {//异常处理
                            return i === 0 ? size(Math.sqrt(d[measureField])) : 2.4 * maxR + size(Math.sqrt(d[measureField]))
                        }
                        return (_that.width() - size(Math.sqrt(d[measureField]))) / 2
                    }
                    if (!isMatrix) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                    else if (isPrime(categories.length) && Math.floor(i / column) === row - 1) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor(i % column))
                    } else if (isPrime(categories.length) && Math.floor(i / column) === row) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor((i - 1) % column) + 1)
                    } else {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                })
                .attr('y', function (d, i) {
                    let startPoint = (height - 3.6 * maxR) / (row - 1) <= 2.5 * maxR ? 1.8 * maxR : (height - 2.5 * maxR * (row - 1)) / 2;
                    if (!isMatrix) {
                        return 1.8 * maxR + (height - 3.6 * maxR) / 2 + 1.1 * maxR
                    }
                    else if (isPrime(categories.length) && i === categories.length - 1) {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i - 1) / column)) + 1.1 * maxR
                    } else {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor(i / column)) + 1.1 * maxR
                    }
                })
                .text(d => {
                    let text = d[breakdown[0].field];
                    return text.length > substringTxtL ? text.substring(0, substringTxtL - 2) + "..." : text;
                })
                .attr('fill', 'black')
                //.attr('font-size', d3.min([height, width]) * 0.045)
                .attr('font-size', font.label)
                .attr("font-family", NUMFONT)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'hanging');

            if (this.style() === Style.COMICS) {
                let metaphorHeight = this.size() === Size.LARGE ? 3.4 * maxR : 3.2 * maxR,
                    metaphorWidth = metaphorHeight / 1.20;

                let metaphor = contentG.append("image")
                    .attr('xlink:href', metaphor23);

                let contentBBox = contentG.select('g').node().getBBox();
                metaphor.attr("width", metaphorWidth)
                    .attr("height", metaphorHeight)
                    .attr("x", contentBBox.width + metaphorWidth * 0.1)
                    .attr("y", contentBBox.height + contentBBox.y - metaphorHeight * 1);
            }

            let _h = PcontentG.node().getBBox().height,
                _w = PcontentG.node().getBBox().width;
            let marginTop = (this.height() - _h) / 2 - PcontentG.node().getBBox().y,
                marginLeft = (this.width() - _w) / 2 - PcontentG.node().getBBox().x;

            contentG.attr("transform", "translate(" + marginLeft + "," + marginTop + ")");
            return svg;

        }

        if (this.style() === Style.PICTOGRAPH) {
            let data = this.factdata();
            let measure = this.measure();
            let breakdown = this.breakdown();
            let pictype = measure[0].pictype;
            let measureField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field;
            // set the dimensions and margins of the graph
            // let isMatrix = style.style === 'matrix' ? true : false;
            let isMatrix = true;
            let { margin, maxBubbleNum, substringTxtL, bubbleMT } = getSizeBySize(this.size(), "distribution", isMatrix),
                width = this.width() - margin.left - margin.right,
                height = this.height() - margin.top - margin.bottom,
                font = fontSize[this.size()];

            let svg = d3.select(this.container())
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom);

            if (measure.length > 1) {
                svg.append("rect")
                    .attr("width", width)
                    .attr("height", height).attr("fill", "none");
                return svg;
            }
            data = data.filter(d => d[measureField] > 0);
            data = data.slice(0, maxBubbleNum)

            let contentG = svg.append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            //气泡布局处理
            let row, column;
            let categories = data;
            if (isMatrix) {
                let n = categories.length;
                let max_sqrt = Math.floor(Math.sqrt(n));
                let candidate;
                if (isPrime(n)) n = n - 1
                while (max_sqrt) {
                    if (n % max_sqrt === 0) {
                        candidate = max_sqrt
                        break;
                    }
                    max_sqrt -= 1
                }
                row = d3.min([candidate, n / candidate])
                column = n / row
            } else {
                row = 1;
                column = categories.length;
            }
            let maxR = d3.min([0.9 * height / (row + 1) / 2, 0.9 * width / (column + 1) / 2])


            /*------------------通过名称找寻icon----------------------------*/
            svg.append("defs")
                .append("g")
                .attr("id", `pictype${pictype}`)
                .append("path")
                .attr("d", PictorialType[pictype])

            let typesizex1 = svg.select(`#pictype${pictype}`).node().getBBox().width;
            let typesizey1 = svg.select(`#pictype${pictype}`).node().getBBox().height;
            let typex = svg.select(`#pictype${pictype}`).node().getBBox().x;
            let typey = svg.select(`#pictype${pictype}`).node().getBBox().y;

            let area1 = typesizex1 * typesizey1;
            let area2 = maxR * maxR * Math.PI;
            let scaleorign = Math.sqrt(area2 / area1);
            let scale1 = [];
            //Size channels
            let size = d3.scaleLinear()
                .domain([0, d3.max(data, function (d) { return Math.sqrt(d[measureField]); })])
                .range([0, scaleorign]);
            //Icon 进行缩放
            data.forEach((item, index) => {
                scale1.push(size(Math.sqrt(item[measureField])));
                svg.append("defs")
                    .append("g")
                    .attr("id", `pictypecate${index}`)
                    .append("path")
                    .attr("d", PictorialType[pictype])
                    .attr("transform", function () {
                        return `scale(${scale1[index]})`;
                    })
            })

            // Draw Icon
            let rowTotalWidth;
            let _that = this;
            if (categories.length % 2 === 0) {
                rowTotalWidth = 2.4 * maxR * column;
            } else {
                rowTotalWidth = 2.4 * maxR * (column + 1);
            }
            let proportions = contentG.append('g')
                .selectAll('g')
                .data(data)
                .enter()
                .append('g')
                .attr("class", "items")
                .each(function (d, i) {
                    let rowNow;
                    if (isPrime(categories.length) && i === categories.length - 1) {
                        rowNow = Math.floor((i - 1) / column)
                    } else {
                        rowNow = Math.floor(i / column);
                    }
                    d3.select(this).attr("transform", `translate(0,${bubbleMT * rowNow})`); //行与行之间的间隔
                });

            proportions.append("use")
                .attr("xlink:href", function (d, i) { return `#pictypecate${i}` })
                .attr("id", function (d, i) { return "icontype" + i })
                .attr("class", "data-item")
                .attr("fill", (d, i) => {
                    return Color.CATEGORICAL[i % 10]
                })
                .attr("x", function (d, i) {
                    if (column === 1) {
                        if (data.length === 2) {//异常处理
                            return i === 0 ? size(Math.sqrt(d[measureField])) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i]) : 2.4 * maxR + size(Math.sqrt(d[measureField])) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                        }
                        return (_that.width() - size(Math.sqrt(d[measureField]))) / 2 - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                    }
                    if (!isMatrix) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column)) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                    }
                    else if (isPrime(categories.length) && Math.floor(i / column) === row - 1) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor(i % column)) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                    } else if (isPrime(categories.length) && Math.floor(i / column) === row) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor((i - 1) % column) + 1) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                    } else {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column)) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                    }
                })
                .attr("y", function (d, i) {
                    let startPoint = (height - 3.6 * maxR) / (row - 1) <= 2.5 * maxR ? 1.8 * maxR : (height - 2.5 * maxR * (row - 1)) / 2;
                    if (!isMatrix) {
                        return 1.8 * maxR + (height - 3.6 * maxR) / 2 - typesizey1 * scale1[i] / 2 - Math.abs(typey * scale1[i])
                    }
                    else if (isPrime(categories.length) && i === categories.length - 1) {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i - 1) / column)) - typesizey1 * scale1[i] / 2 - Math.abs(typey * scale1[i])
                    } else {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor(i / column)) - typesizey1 * scale1[i] / 2 - Math.abs(typey * scale1[i])
                    }
                })

            //value
            proportions.append('text')
                .attr('x', function (d, i) {
                    if (column === 1) {
                        if (data.length === 2) {//异常处理
                            return i === 0 ? size(Math.sqrt(d[measureField])) : 2.4 * maxR + size(Math.sqrt(d[measureField]))
                        }
                        return (_that.width() - size(Math.sqrt(d[measureField]))) / 2
                    }
                    if (!isMatrix) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                    else if (isPrime(categories.length) && Math.floor(i / column) === row - 1) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor(i % column))
                    } else if (isPrime(categories.length) && Math.floor(i / column) === row) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor((i - 1) % column) + 1)
                    } else {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                })
                .attr('y', function (d, i) {
                    let startPoint = (height - 3.6 * maxR) / (row - 1) <= 2.5 * maxR ? 1.8 * maxR : (height - 2.5 * maxR * (row - 1)) / 2;
                    if (!isMatrix) {
                        return 1.8 * maxR + (height - 3.6 * maxR) / 2
                    }
                    else if (isPrime(categories.length) && i === categories.length - 1) {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i - 1) / column))
                    } else {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor(i / column))
                    }
                })
                .text(d => formatNumber(d[measureField]))
                //.attr('fill', 'white')
                .attr('fill', function (d, i) {
                    return "#000000"
                    // if (Color.CATEGORICAL[i % 10].length !== 7 || formatNumber(d[measureField]).length > 8) return "#000000"
                    // let { r, g, b } = hexToRGB(Color.CATEGORICAL[i % 10])
                    // if (r * 0.299 + g * 0.587 + b * 0.114 > 186) return "#000000"
                    // else
                    //     return "#ffffff"
                })
                .attr('alignment-baseline', 'middle')
                .attr('font-size', font.label * 0.9)
                .attr("font-family", NUMFONT)
                .attr('text-anchor', 'middle');

            //breakdown
            proportions.append('text')
                .attr('x', function (d, i) {
                    if (column === 1) {
                        if (data.length === 2) {//异常处理
                            return i === 0 ? size(Math.sqrt(d[measureField])) : 2.4 * maxR + size(Math.sqrt(d[measureField]))
                        }
                        return (_that.width() - size(Math.sqrt(d[measureField]))) / 2
                    }
                    if (!isMatrix) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                    else if (isPrime(categories.length) && Math.floor(i / column) === row - 1) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor(i % column))
                    } else if (isPrime(categories.length) && Math.floor(i / column) === row) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor((i - 1) % column) + 1)
                    } else {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                })
                .attr('y', function (d, i) {
                    let startPoint = (height - 3.6 * maxR) / (row - 1) <= 2.5 * maxR ? 1.8 * maxR : (height - 2.5 * maxR * (row - 1)) / 2;
                    if (!isMatrix) {
                        return 1.8 * maxR + (height - 3.6 * maxR) / 2 + 1.1 * maxR
                    }
                    else if (isPrime(categories.length) && i === categories.length - 1) {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i - 1) / column)) + 1.1 * maxR
                    } else {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor(i / column)) + 1.1 * maxR
                    }
                })
                .text(d => {
                    let text = d[breakdown[0].field];
                    return text.length > substringTxtL ? text.substring(0, substringTxtL - 2) + "..." : text;
                })
                .attr('fill', 'black')
                //.attr('font-size', d3.min([height, width]) * 0.045)
                .attr('font-size', font.label)
                .attr("font-family", NUMFONT)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'hanging');


            let _h = contentG.node().getBBox().height,
                _w = contentG.node().getBBox().width;
            let marginTop = (this.height() - _h) / 2 - contentG.node().getBBox().y,
                marginLeft = (this.width() - _w) / 2 - contentG.node().getBBox().x;

            contentG.attr("transform", "translate(" + marginLeft + "," + marginTop + ")");
            return svg;

        }
    }
    displayTrend() {
        if (this.style() === Style.BUSINESS || this.style() === Style.COMICS) {
            let data = this.factdata();
            let measure = this.measure();
            let breakdown = this.breakdown();
            let measureField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field
            // set the dimensions and margins of the graph
            // let isMatrix = style.style === 'matrix' ? true : false;
            let isMatrix = true;
            let { margin, maxBubbleNum, substringTxtL, bubbleMT } = getSizeBySize(this.size(), "trend", isMatrix),
                width = this.width() - margin.left - margin.right,
                height = this.height() - margin.top - margin.bottom,
                font = fontSize[this.size()];
            let svg = d3.select(this.container())
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom);
            if (this.style() === Style.COMICS) width = 0.8 * width;
            if (measure.length > 1) {
                svg.append("rect")
                    .attr("width", width)
                    .attr("height", height).attr("fill", "none");
                return svg;
            }
            data = data.filter(d => d[measureField] > 0);
            data = data.slice(0, maxBubbleNum)
            // if (data.length > maxBubbleNum) {
            //     let chartSize = {
            //         width: this.width(),
            //         height: this.height()
            //     }
            //     unsupportedchart(svg, chartSize, annotationSize, this.size());
            //     return svg;
            // }
            // console.log("data", data)

            //if same year
            let sameYear = "";
            if (breakdown[0].type === "temporal") {
                try {
                    let year = getSameYear(data.map(d => d[breakdown[0].field]));
                    sameYear = year + "/";
                } catch (e) {
                    //keep origin
                }
            }

            let contentG = svg.append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
            // let textHeight = 0;
            if (this.size() === Size.LARGE && sameYear !== "" && sameYear !== "false/") {
                //let yearText = 
                svg.append("text")
                    .attr("class", "sameYear")
                    .text("Year:" + sameYear.replace("/", ""))
                    .attr("font-size", '21')
                    .attr("font-family", NUMFONT)
                    .attr("y", 60)
                    .attr("x", this.width() / 2)
                    .attr("fill", Color.TEXT)
                    .attr("text-anchor", "middle")
                    .attr('dominant-baseline', 'middle');
                //textHeight = yearText.node().getBBox().height+10;//margin
            }
            data = data.map(d => {
                d[breakdown[0].field] = d[breakdown[0].field].replace(sameYear, "");
                return d;
            })

            let row, column;
            let categories = data;
            if (isMatrix) {
                let n = categories.length;
                if (n < 6) {
                    row = 1;
                    column = n
                } else {
                    let max_sqrt = Math.floor(Math.sqrt(n));
                    let candidate;
                    if (isPrime(n)) n = n - 1
                    while (max_sqrt) {
                        if (n % max_sqrt === 0) {
                            candidate = max_sqrt
                            break;
                        }
                        max_sqrt -= 1
                    }
                    row = d3.min([candidate, n / candidate])
                    column = n / row
                }

            } else {
                row = 1;
                column = categories.length;
            }
            let maxR = d3.min([0.9 * height / (row + 1) / 2, 0.9 * width / (column + 1) / 2])
            //Size channels
            let size = d3.scaleLinear()
                .domain([0, d3.max(data, function (d) { return Math.sqrt(d[measureField]); })])
                .range([0, maxR]);


            // Draw Circles  
            let rowTotalWidth;
            let _that = this;
            if (categories.length % 2 === 0) {
                rowTotalWidth = 2.4 * maxR * column;
            } else {
                rowTotalWidth = 2.4 * maxR * (column + 1);
            }
            let proportions = contentG.append('g')
                .selectAll('g')
                .data(data)
                .enter()
                // .append('g')
                .append('g')
                .attr("class", "items")
                .each(function (d, i) {
                    let rowNow;
                    if (isPrime(categories.length) && i === categories.length - 1) {
                        rowNow = Math.floor((i - 1) / column)
                    } else {
                        rowNow = Math.floor(i / column);
                    }
                    d3.select(this).attr("transform", `translate(0,${bubbleMT * rowNow})`); //行与行之间的间隔
                });
            proportions.append("circle")
                .style('stroke-width', '1')
                .attr("class", "data-item")
                .attr("size", function (d) { return d[measureField]; })
                .attr("fill", Color.DEFAULT)
                .attr("r", function (d) { return size(Math.sqrt(d[measureField])); })
                .attr("cx", function (d, i) {
                    if (column === 1) {
                        if (data.length === 2) {//异常处理
                            return i === 0 ? size(Math.sqrt(d[measureField])) : 2.4 * maxR + size(Math.sqrt(d[measureField]))
                        }
                        return (_that.width() - size(Math.sqrt(d[measureField]))) / 2
                    }
                    if (!isMatrix) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                    else if (isPrime(categories.length) && Math.floor(i / column) === row - 1) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor(i % column))
                    } else if (isPrime(categories.length) && Math.floor(i / column) === row) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor((i - 1) % column) + 1)
                    } else {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                })
                .attr("cy", function (d, i) {
                    let startPoint = (height - 3.6 * maxR) / (row - 1) <= 2.5 * maxR ? 1.8 * maxR : (height - 2.5 * maxR * (row - 1)) / 2;
                    if (!isMatrix) {
                        return 1.8 * maxR + (height - 3.6 * maxR) / 2
                    }
                    else if (isPrime(categories.length) && i === categories.length - 1) {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i - 1) / column))
                    } else {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i) / column))
                    }
                });


            //breakdown
            proportions.append('text')
                .attr('x', function (d, i) {
                    if (column === 1) {
                        if (data.length === 2) {//异常处理
                            return i === 0 ? size(Math.sqrt(d[measureField])) : 2.4 * maxR + size(Math.sqrt(d[measureField]))
                        }
                        return (_that.width() - size(Math.sqrt(d[measureField]))) / 2
                    }
                    if (!isMatrix) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                    else if (isPrime(categories.length) && Math.floor(i / column) === row - 1) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor(i % column))
                    } else if (isPrime(categories.length) && Math.floor(i / column) === row) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor((i - 1) % column) + 1)
                    } else {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                })
                .attr('y', function (d, i) {
                    let startPoint = (height - 3.6 * maxR) / (row - 1) <= 2.5 * maxR ? 1.8 * maxR : (height - 2.5 * maxR * (row - 1)) / 2;
                    if (!isMatrix) {
                        return 1.8 * maxR + (height - 3.6 * maxR) / 2 + 1.1 * maxR
                    }
                    else if (isPrime(categories.length) && i === categories.length - 1) {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i - 1) / column)) + 1.1 * maxR
                    } else {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor(i / column)) + 1.1 * maxR
                    }
                })
                .text(d => {
                    let text = d[breakdown[0].field];
                    return text.length > substringTxtL ? text.substring(0, substringTxtL - 2) + "..." : text;
                })
                .attr('fill', 'black')
                //.attr('font-size', d3.min([height, width]) * 0.045)
                .attr('font-size', font.label)
                .attr("font-family", NUMFONT)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'hanging');

            if (this.style() === Style.COMICS) {
                let metaphorHeight = this.size() === Size.LARGE ? 2.5 * maxR : 3 * maxR,
                    metaphorWidth = metaphorHeight / 1.22;

                let metaphor = contentG.append("image")
                    .attr('xlink:href', metaphor14);

                let contentBBox = contentG.select('g').node().getBBox();
                metaphor.attr("width", metaphorWidth)
                    .attr("height", metaphorHeight)
                    .attr("x", contentBBox.x - metaphorWidth * 1.1)
                    .attr("y", contentBBox.height + contentBBox.y - metaphorHeight * 1.05);
            }

            let _h = contentG.node().getBBox().height,
                _w = contentG.node().getBBox().width;
            let marginTop = (this.height() - _h) / 2 - contentG.node().getBBox().y,
                marginLeft = (this.width() - _w) / 2 - contentG.node().getBBox().x;

            contentG.attr("transform", "translate(" + marginLeft + "," + marginTop + ")")
            return svg
        }
        if (this.style() === Style.PICTOGRAPH) {
            let data = this.factdata();
            let measure = this.measure();
            let breakdown = this.breakdown();
            let pictype = measure[0].pictype;
            let measureField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field
            // set the dimensions and margins of the graph
            // let isMatrix = style.style === 'matrix' ? true : false;
            let isMatrix = true;
            let { margin, maxBubbleNum, substringTxtL, bubbleMT } = getSizeBySize(this.size(), "trend", isMatrix),
                width = this.width() - margin.left - margin.right,
                height = this.height() - margin.top - margin.bottom,
                font = fontSize[this.size()];
            let svg = d3.select(this.container())
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom);
            if (measure.length > 1) {
                svg.append("rect")
                    .attr("width", width)
                    .attr("height", height).attr("fill", "none");
                return svg;
            }
            data = data.filter(d => d[measureField] > 0);
            data = data.slice(0, maxBubbleNum)

            let contentG = svg.append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            let row, column;
            let categories = data;
            if (isMatrix) {
                let n = categories.length;
                let max_sqrt = Math.floor(Math.sqrt(n));
                let candidate;
                if (isPrime(n)) n = n - 1
                while (max_sqrt) {
                    if (n % max_sqrt === 0) {
                        candidate = max_sqrt
                        break;
                    }
                    max_sqrt -= 1
                }
                row = d3.min([candidate, n / candidate])
                column = n / row
            } else {
                row = 1;
                column = categories.length;
            }
            let maxR = d3.min([0.9 * height / (row + 1) / 2, 0.9 * width / (column + 1) / 2])

            /*------------------通过名称找寻icon----------------------------*/
            svg.append("defs")
                .append("g")
                .attr("id", `pictype${pictype}`)
                .append("path")
                .attr("d", PictorialType[pictype])

            let typesizex1 = svg.select(`#pictype${pictype}`).node().getBBox().width;
            let typesizey1 = svg.select(`#pictype${pictype}`).node().getBBox().height;
            let typex = svg.select(`#pictype${pictype}`).node().getBBox().x;
            let typey = svg.select(`#pictype${pictype}`).node().getBBox().y;

            let area1 = typesizex1 * typesizey1;
            let area2 = maxR * maxR * Math.PI;
            let scaleorign = Math.sqrt(area2 / area1);
            let scale1 = [];
            //Size channels
            let size = d3.scaleLinear()
                .domain([0, d3.max(data, function (d) { return Math.sqrt(d[measureField]); })])
                .range([0, scaleorign]);
            //Icon 进行缩放
            data.forEach((item, index) => {
                scale1.push(size(Math.sqrt(item[measureField])));
                svg.append("defs")
                    .append("g")
                    .attr("id", `pictypecate${index}`)
                    .append("path")
                    .attr("d", PictorialType[pictype])
                    .attr("transform", function () {
                        return `scale(${scale1[index]})`;
                    })
            })


            // Draw Icons  
            let rowTotalWidth;
            let _that = this;
            if (categories.length % 2 === 0) {
                rowTotalWidth = 2.4 * maxR * column;
            } else {
                rowTotalWidth = 2.4 * maxR * (column + 1);
            }
            let proportions = contentG.append('g')
                .selectAll('g')
                .data(data)
                .enter()
                // .append('g')
                .append('g')
                .attr("class", "items")
                .each(function (d, i) {
                    let rowNow;
                    if (isPrime(categories.length) && i === categories.length - 1) {
                        rowNow = Math.floor((i - 1) / column)
                    } else {
                        rowNow = Math.floor(i / column);
                    }
                    d3.select(this).attr("transform", `translate(0,${bubbleMT * rowNow})`); //行与行之间的间隔
                });
            proportions.append("use")
                .attr("xlink:href", function (d, i) { return `#pictypecate${i}` })
                .attr("id", function (d, i) { return "icontype" + i })
                .attr("class", "data-item")
                .attr("fill", Color.DEFAULT)
                .attr("x", function (d, i) {
                    if (column === 1) {
                        if (data.length === 2) {//异常处理
                            return i === 0 ? size(Math.sqrt(d[measureField])) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i]) : 2.4 * maxR + size(Math.sqrt(d[measureField])) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                        }
                        return (_that.width() - size(Math.sqrt(d[measureField]))) / 2 - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                    }
                    if (!isMatrix) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column)) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                    }
                    else if (isPrime(categories.length) && Math.floor(i / column) === row - 1) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor(i % column)) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                    } else if (isPrime(categories.length) && Math.floor(i / column) === row) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor((i - 1) % column) + 1) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                    } else {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column)) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                    }
                })
                .attr("y", function (d, i) {
                    let startPoint = (height - 3.6 * maxR) / (row - 1) <= 2.5 * maxR ? 1.8 * maxR : (height - 2.5 * maxR * (row - 1)) / 2;
                    if (!isMatrix) {
                        return 1.8 * maxR + (height - 3.6 * maxR) / 2 - typesizey1 * scale1[i] / 2 - Math.abs(typey * scale1[i])
                    }
                    else if (isPrime(categories.length) && i === categories.length - 1) {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i - 1) / column)) - typesizey1 * scale1[i] / 2 - Math.abs(typey * scale1[i])
                    } else {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i) / column)) - typesizey1 * scale1[i] / 2 - Math.abs(typey * scale1[i])
                    }
                });


            //breakdown
            proportions.append('text')
                .attr('x', function (d, i) {
                    if (column === 1) {
                        if (data.length === 2) {//异常处理
                            return i === 0 ? size(Math.sqrt(d[measureField])) : 2.4 * maxR + size(Math.sqrt(d[measureField]))
                        }
                        return (_that.width() - size(Math.sqrt(d[measureField]))) / 2
                    }
                    if (!isMatrix) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                    else if (isPrime(categories.length) && Math.floor(i / column) === row - 1) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor(i % column))
                    } else if (isPrime(categories.length) && Math.floor(i / column) === row) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor((i - 1) % column) + 1)
                    } else {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                })
                .attr('y', function (d, i) {
                    let startPoint = (height - 3.6 * maxR) / (row - 1) <= 2.5 * maxR ? 1.8 * maxR : (height - 2.5 * maxR * (row - 1)) / 2;
                    if (!isMatrix) {
                        return 1.8 * maxR + (height - 3.6 * maxR) / 2 + 1.1 * maxR
                    }
                    else if (isPrime(categories.length) && i === categories.length - 1) {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i - 1) / column)) + 1.1 * maxR
                    } else {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor(i / column)) + 1.1 * maxR
                    }
                })
                .text(d => {
                    let text = d[breakdown[0].field];
                    return text.length > substringTxtL ? text.substring(0, substringTxtL - 2) + "..." : text;
                })
                .attr('fill', 'black')
                //.attr('font-size', d3.min([height, width]) * 0.045)
                .attr('font-size', font.label)
                .attr("font-family", NUMFONT)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'hanging');


            let _h = contentG.node().getBBox().height,
                _w = contentG.node().getBBox().width;
            let marginTop = (this.height() - _h) / 2 - contentG.node().getBBox().y,
                marginLeft = (this.width() - _w) / 2 - contentG.node().getBBox().x;

            contentG.attr("transform", "translate(" + marginLeft + "," + marginTop + ")")
            return svg
        }

    }
    displayCategorization() {
        if (this.style() === Style.BUSINESS || this.style() === Style.COMICS) {
            let data = this.factdata();
            let breakdown = this.breakdown();
            let measureField = "COUNT";
            // set the dimensions and margins of the graph
            // let isMatrix = style.style === 'matrix' ? true : false;
            let isMatrix = true;
            let { margin, maxBubbleNum, substringTxtL, bubbleMT } = getSizeBySize(this.size(), "categorization", isMatrix),
                width = this.width() - margin.left - margin.right,
                height = this.height() - margin.top - margin.bottom,
                font = fontSize[this.size()];
            let svg = d3.select(this.container())
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom);
            if (this.style() === Style.COMICS) width = 0.8 * width;
            //data
            let calculateData = d3.nest().key(d => d[breakdown[0].field]).entries(data);
            data = calculateData.map(function (d, i) {
                let countRows = d.values[0];
                countRows[measureField] = d.values.length;
                return countRows;
            });
            data = data.slice(0, maxBubbleNum)
            // if (data.length > maxBubbleNum) {
            //     let chartSize = {
            //         width: this.width(),
            //         height: this.height()
            //     }
            //     unsupportedchart(svg, chartSize, annotationSize, this.size());
            //     return svg;
            // }
            // console.log("data", data)

            //if same year
            let sameYear = "";
            if (breakdown[0].type === "temporal") {
                try {
                    let year = getSameYear(data.map(d => d[breakdown[0].field]));
                    sameYear = year + "/";
                } catch (e) {
                    //keep origin
                }
            }

            let contentG = svg.append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            // let textHeight = 0;
            if (this.size() === Size.LARGE && sameYear !== "" && sameYear !== "false/") {
                //let yearText = 
                svg.append("text")
                    .attr("class", "sameYear")
                    .text("Year:" + sameYear.replace("/", ""))
                    .attr("font-size", '21')
                    .attr("font-family", NUMFONT)
                    .attr("y", 60)
                    .attr("x", this.width() / 2)
                    .attr("fill", Color.TEXT)
                    .attr("text-anchor", "middle")
                    .attr('dominant-baseline', 'middle');
                //textHeight = yearText.node().getBBox().height+10;//margin
            }
            data = data.map(d => {
                d[breakdown[0].field] = d[breakdown[0].field].replace(sameYear, "");
                return d;
            })


            let row, column;
            //  data = data.slice(0, 1)
            let categories = data;
            if (isMatrix) {
                let n = categories.length;
                if (n < 6) {
                    row = 1;
                    column = n
                } else {
                    let max_sqrt = Math.floor(Math.sqrt(n));
                    let candidate;
                    if (isPrime(n)) n = n - 1
                    while (max_sqrt) {
                        if (n % max_sqrt === 0) {
                            candidate = max_sqrt
                            break;
                        }
                        max_sqrt -= 1
                    }
                    row = d3.min([candidate, n / candidate])
                    column = n / row
                }
            } else {
                row = 1;
                column = categories.length;
            }
            let maxR = d3.min([0.9 * height / (row + 1) / 2, 0.9 * width / (column + 1) / 2])
            //Size channels
            let size = d3.scaleLinear()
                .domain([0, d3.max(data, function (d) { return Math.sqrt(d[measureField]); })])
                .range([0, maxR]);


            // Draw Circles
            let rowTotalWidth;
            let _that = this;
            if (categories.length % 2 === 0) {
                rowTotalWidth = 2.4 * maxR * column;
            } else {
                rowTotalWidth = 2.4 * maxR * (column + 1);
            }

            let proportions = contentG.append('g')
                .selectAll('g')
                .data(data)
                .enter()
                // .append('g')
                .append('g')
                .attr("class", 'items')
                .each(function (d, i) {
                    let rowNow;
                    if (isPrime(categories.length) && i === categories.length - 1) {
                        rowNow = Math.floor((i - 1) / column)
                    } else {
                        rowNow = Math.floor(i / column);
                    }
                    d3.select(this).attr("transform", `translate(0,${bubbleMT * rowNow})`); //行与行之间的间隔
                });
            proportions.append("circle")
                .style('stroke-width', '1')
                .attr("class", "data-item")
                .attr("size", function (d) { return d[measureField]; })
                .attr("fill", (d, i) => {
                    return Color.CATEGORICAL[i % 10]
                })
                .attr("r", function (d) { return size(Math.sqrt(d[measureField])); })
                .attr("cx", function (d, i) {
                    //console.log("i", i, column)
                    if (column === 1) {
                        if (data.length === 2) {//异常处理
                            return i === 0 ? size(Math.sqrt(d[measureField])) : 2.4 * maxR + size(Math.sqrt(d[measureField]))
                        }
                        return (_that.width() - size(Math.sqrt(d[measureField]))) / 2
                    }
                    if (!isMatrix) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                    else if (isPrime(categories.length) && Math.floor(i / column) === row - 1) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor(i % column))
                    } else if (isPrime(categories.length) && Math.floor(i / column) === row) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor((i - 1) % column) + 1)
                    } else {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                })
                .attr("cy", function (d, i) {
                    let startPoint = (height - 3.6 * maxR) / (row - 1) <= 2.5 * maxR ? 1.8 * maxR : (height - 2.5 * maxR * (row - 1)) / 2;
                    if (!isMatrix) {
                        return 1.8 * maxR + (height - 3.6 * maxR) / 2
                    }
                    else if (isPrime(categories.length) && i === categories.length - 1) {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i - 1) / column))
                    } else {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor(i / column))
                    }
                });


            //breakdown
            proportions.append('text')
                .attr('x', function (d, i) {
                    if (column === 1) {
                        if (data.length === 2) {//异常处理
                            return i === 0 ? size(Math.sqrt(d[measureField])) : 2.4 * maxR + size(Math.sqrt(d[measureField]))
                        }
                        return (_that.width() - size(Math.sqrt(d[measureField]))) / 2
                    }
                    if (!isMatrix) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                    else if (isPrime(categories.length) && Math.floor(i / column) === row - 1) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor(i % column))
                    } else if (isPrime(categories.length) && Math.floor(i / column) === row) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor((i - 1) % column) + 1)
                    } else {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                })
                .attr('y', function (d, i) {
                    let startPoint = (height - 3.6 * maxR) / (row - 1) <= 2.5 * maxR ? 1.8 * maxR : (height - 2.5 * maxR * (row - 1)) / 2;
                    if (!isMatrix) {
                        return 1.8 * maxR + (height - 3.6 * maxR) / 2 + 1.1 * maxR
                    }
                    else if (isPrime(categories.length) && i === categories.length - 1) {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i - 1) / column)) + 1.1 * maxR
                    } else {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor(i / column)) + 1.1 * maxR
                    }
                })
                .text(d => {
                    let text = d[breakdown[0].field];
                    return text.length > substringTxtL ? text.substring(0, substringTxtL - 2) + "..." : text;
                })
                .attr('fill', 'black')
                //.attr('font-size', d3.min([height, width]) * 0.045)
                .attr('font-size', font.label)
                .attr("font-family", NUMFONT)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'hanging');

            if (this.style() === Style.COMICS) {
                let metaphorHeight = this.size() === Size.LARGE ? 4 * maxR : 3.6 * maxR,
                    metaphorWidth = metaphorHeight / 1.37;

                let metaphor = contentG.append("image")
                    .attr('xlink:href', metaphor13);

                let contentBBox = contentG.select('g').node().getBBox();
                metaphor.attr("width", metaphorWidth)
                    .attr("height", metaphorHeight)
                    .attr("x", contentBBox.width + metaphorWidth * 0.1)
                    .attr("y", contentBBox.height + contentBBox.y - metaphorHeight * 1);
            }

            let _h = contentG.node().getBBox().height,
                _w = contentG.node().getBBox().width;
            let marginTop = (this.height() - _h) / 2 - contentG.node().getBBox().y,
                marginLeft = (this.width() - _w) / 2 - contentG.node().getBBox().x;

            contentG.attr("transform", "translate(" + marginLeft + "," + marginTop + ")")

            return svg
        }
        if (this.style() === Style.PICTOGRAPH) {
            let data = this.factdata();
            let breakdown = this.breakdown();
            let measureField = "COUNT";
            let measure = this.measure();
            let pictype = measure[0].pictype;
            // set the dimensions and margins of the graph
            // let isMatrix = style.style === 'matrix' ? true : false;
            let isMatrix = true;
            let { margin, maxBubbleNum, substringTxtL, bubbleMT } = getSizeBySize(this.size(), "categorization", isMatrix),
                width = this.width() - margin.left - margin.right,
                height = this.height() - margin.top - margin.bottom,
                font = fontSize[this.size()];
            let svg = d3.select(this.container())
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom);
            if (this.style() === Style.COMICS) width = 0.8 * width;
            //data
            let calculateData = d3.nest().key(d => d[breakdown[0].field]).entries(data);
            data = calculateData.map(function (d, i) {
                let countRows = d.values[0];
                countRows[measureField] = d.values.length;
                return countRows;
            });
            data = data.slice(0, maxBubbleNum)

            let contentG = svg.append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            let row, column;
            //  data = data.slice(0, 1)
            let categories = data;
            if (isMatrix) {
                let n = categories.length;
                let max_sqrt = Math.floor(Math.sqrt(n));
                let candidate;
                if (isPrime(n)) n = n - 1
                while (max_sqrt) {
                    if (n % max_sqrt === 0) {
                        candidate = max_sqrt
                        break;
                    }
                    max_sqrt -= 1
                }
                row = d3.min([candidate, n / candidate])
                column = n / row
            } else {
                row = 1;
                column = categories.length;
            }
            let maxR = d3.min([0.9 * height / (row + 1) / 2, 0.9 * width / (column + 1) / 2])
            /*------------------通过名称找寻icon----------------------------*/
            svg.append("defs")
                .append("g")
                .attr("id", `pictype${pictype}`)
                .append("path")
                .attr("d", PictorialType[pictype])

            let typesizex1 = svg.select(`#pictype${pictype}`).node().getBBox().width;
            let typesizey1 = svg.select(`#pictype${pictype}`).node().getBBox().height;
            let typex = svg.select(`#pictype${pictype}`).node().getBBox().x;
            let typey = svg.select(`#pictype${pictype}`).node().getBBox().y;

            let area1 = typesizex1 * typesizey1;
            let area2 = maxR * maxR * Math.PI;
            let scaleorign = Math.sqrt(area2 / area1);
            let scale1 = [];
            //Size channels
            let size = d3.scaleLinear()
                .domain([0, d3.max(data, function (d) { return Math.sqrt(d[measureField]); })])
                .range([0, scaleorign]);
            //Icon 进行缩放
            data.forEach((item, index) => {
                scale1.push(size(Math.sqrt(item[measureField])));
                svg.append("defs")
                    .append("g")
                    .attr("id", `pictypecate${index}`)
                    .append("path")
                    .attr("d", PictorialType[pictype])
                    .attr("transform", function () {
                        return `scale(${scale1[index]})`;
                    })
            })

            // Draw Icons
            let rowTotalWidth;
            let _that = this;
            if (categories.length % 2 === 0) {
                rowTotalWidth = 2.4 * maxR * column;
            } else {
                rowTotalWidth = 2.4 * maxR * (column + 1);
            }

            let proportions = contentG.append('g')
                .selectAll('g')
                .data(data)
                .enter()
                // .append('g')
                .append('g')
                .attr("class", 'items')
                .each(function (d, i) {
                    let rowNow;
                    if (isPrime(categories.length) && i === categories.length - 1) {
                        rowNow = Math.floor((i - 1) / column)
                    } else {
                        rowNow = Math.floor(i / column);
                    }
                    d3.select(this).attr("transform", `translate(0,${bubbleMT * rowNow})`); //行与行之间的间隔
                });
            proportions.append("use")
                .attr("xlink:href", function (d, i) { return `#pictypecate${i}` })
                .attr("id", function (d, i) { return "icontype" + i })
                .attr("class", "data-item")
                .attr("fill", (d, i) => {
                    return Color.CATEGORICAL[i % 10]
                })
                .attr("x", function (d, i) {
                    if (column === 1) {
                        if (data.length === 2) {//异常处理
                            return i === 0 ? size(Math.sqrt(d[measureField])) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i]) : 2.4 * maxR + size(Math.sqrt(d[measureField])) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                        }
                        return (_that.width() - size(Math.sqrt(d[measureField]))) / 2 - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                    }
                    if (!isMatrix) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column)) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                    }
                    else if (isPrime(categories.length) && Math.floor(i / column) === row - 1) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor(i % column)) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                    } else if (isPrime(categories.length) && Math.floor(i / column) === row) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor((i - 1) % column) + 1) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                    } else {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column)) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                    }
                })
                .attr("y", function (d, i) {
                    let startPoint = (height - 3.6 * maxR) / (row - 1) <= 2.5 * maxR ? 1.8 * maxR : (height - 2.5 * maxR * (row - 1)) / 2;
                    if (!isMatrix) {
                        return 1.8 * maxR + (height - 3.6 * maxR) / 2 - typesizey1 * scale1[i] / 2 - Math.abs(typey * scale1[i])
                    }
                    else if (isPrime(categories.length) && i === categories.length - 1) {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i - 1) / column)) - typesizey1 * scale1[i] / 2 - Math.abs(typey * scale1[i])
                    } else {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor(i / column)) - typesizey1 * scale1[i] / 2 - Math.abs(typey * scale1[i])
                    }
                });


            //breakdown
            proportions.append('text')
                .attr('x', function (d, i) {
                    if (column === 1) {
                        if (data.length === 2) {//异常处理
                            return i === 0 ? size(Math.sqrt(d[measureField])) : 2.4 * maxR + size(Math.sqrt(d[measureField]))
                        }
                        return (_that.width() - size(Math.sqrt(d[measureField]))) / 2
                    }
                    if (!isMatrix) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                    else if (isPrime(categories.length) && Math.floor(i / column) === row - 1) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor(i % column))
                    } else if (isPrime(categories.length) && Math.floor(i / column) === row) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor((i - 1) % column) + 1)
                    } else {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                })
                .attr('y', function (d, i) {
                    let startPoint = (height - 3.6 * maxR) / (row - 1) <= 2.5 * maxR ? 1.8 * maxR : (height - 2.5 * maxR * (row - 1)) / 2;
                    if (!isMatrix) {
                        return 1.8 * maxR + (height - 3.6 * maxR) / 2 + 1.1 * maxR
                    }
                    else if (isPrime(categories.length) && i === categories.length - 1) {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i - 1) / column)) + 1.1 * maxR
                    } else {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor(i / column)) + 1.1 * maxR
                    }
                })
                .text(d => {
                    let text = d[breakdown[0].field];
                    return text.length > substringTxtL ? text.substring(0, substringTxtL - 2) + "..." : text;
                })
                .attr('fill', 'black')
                //.attr('font-size', d3.min([height, width]) * 0.045)
                .attr('font-size', font.label)
                .attr("font-family", NUMFONT)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'hanging');


            let _h = contentG.node().getBBox().height,
                _w = contentG.node().getBBox().width;
            let marginTop = (this.height() - _h) / 2 - contentG.node().getBBox().y,
                marginLeft = (this.width() - _w) / 2 - contentG.node().getBBox().x;

            contentG.attr("transform", "translate(" + marginLeft + "," + marginTop + ")")

            return svg
        }
    }
    displayExtreme() {
        if (this.style() === Style.BUSINESS || this.style() === Style.COMICS) {
            let data = this.factdata();
            let measure = this.measure();
            let breakdown = this.breakdown();
            let focus = this.focus();
            let measureField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field;
            // set the dimensions and margins of the graph
            // let isMatrix = style.style === 'matrix' ? true : false;
            let isMatrix = true;
            let { margin, maxBubbleNum, annotationSize, substringTxtL, bubbleMT } = getSizeBySize(this.size(), "outlier", isMatrix),
                width = this.width() - margin.left - margin.right,
                height = this.height() - margin.top - margin.bottom,
                font = fontSize[this.size()];
            let svg = d3.select(this.container())
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom);

            if (measure.length > 1) {
                svg.append("rect")
                    .attr("width", width)
                    .attr("height", height).attr("fill", "none");
                return svg;
            }
            if (this.style() === Style.COMICS) width = 0.8 * width;
            data = data.filter(d => d[measureField] > 0);
            data = data.slice(0, maxBubbleNum);
            // if (data.length > maxBubbleNum) {
            //     let chartSize = {
            //         width: this.width(),
            //         height: this.height()
            //     }
            //     unsupportedchart(svg, chartSize, annotationSize, this.size());
            //     return svg;
            // }

            //if same year
            let sameYear = "";
            if (breakdown[0].type === "temporal") {
                try {
                    let year = getSameYear(data.map(d => d[breakdown[0].field]));
                    sameYear = year + "/";
                } catch (e) {
                    //keep origin
                }
            }

            let contentG = svg.append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            // let textHeight = 0;
            if (this.size() === Size.LARGE && sameYear !== "" && sameYear !== "false/") {
                //let yearText = 
                svg.append("text")
                    .attr("class", "sameYear")
                    .text("Year:" + sameYear.replace("/", ""))
                    .attr("font-size", '21')
                    .attr("font-family", NUMFONT)
                    .attr("y", 60)
                    .attr("x", this.width() / 2)
                    .attr("fill", Color.TEXT)
                    .attr("text-anchor", "middle")
                    .attr('dominant-baseline', 'middle');
                //textHeight = yearText.node().getBBox().height+10;//margin
            }
            data = data.map(d => {
                d[breakdown[0].field] = d[breakdown[0].field].replace(sameYear, "");
                return d;
            })

            let row, column;
            let categories = data;
            if (isMatrix) {
                let n = categories.length;
                if (n < 6) {
                    row = 1;
                    column = n
                } else {
                    let max_sqrt = Math.floor(Math.sqrt(n));
                    let candidate;
                    if (isPrime(n)) n = n - 1
                    while (max_sqrt) {
                        if (n % max_sqrt === 0) {
                            candidate = max_sqrt
                            break;
                        }
                        max_sqrt -= 1
                    }
                    row = d3.min([candidate, n / candidate])
                    column = n / row
                }

            } else {
                row = 1;
                column = categories.length;
            }
            let maxR = d3.min([0.9 * height / (row + 1) / 2, 0.9 * width / (column + 1) / 2])
            //Size channels
            let size = d3.scaleLinear()
                .domain([0, d3.max(data, function (d) { return Math.sqrt(d[measureField]); })])
                .range([0, maxR]);


            // 找到focus的在第几行
            let focusRow = 0;
            for (let i = 0; i < data.length; i++) {
                if (data[i][breakdown[0].field] === focus[0].value) {
                    if (isPrime(categories.length) && i === categories.length - 1) {
                        focusRow = Math.floor((i - 1) / column)
                    } else {
                        focusRow = Math.floor(i / column);
                    }
                    break;
                }

            }

            // Draw Circles  
            let rowTotalWidth;
            if (categories.length % 2 === 0) {
                rowTotalWidth = 2.4 * maxR * column;
            } else {
                rowTotalWidth = 2.4 * maxR * (column + 1);
            }
            let chartWidth = this.width(),
                chartHeight = this.height();
            let _that = this;
            let PcontentG = contentG.append("g")
            let proportions = PcontentG.append('g')
                .selectAll('g')
                .data(data)
                .enter()
                .append('g')
                .attr("class", "items")
                .each(function (d, i) {

                    // if (i / column >= 1) {
                    //     console.log(i, column, (i+1) / column)
                    //     d3.select(this).attr("transform", `translate(0,${bubbleMT * parseInt((i+1) / column)})`); //行与行之间的间隔
                    // }
                    //tool tip
                    if (d[breakdown[0].field] === focus[0].value) {
                        let chartSize = {
                            width: chartWidth,
                            height: chartHeight
                        }
                        let toolTipX = findToolTipPosX(column, row, d, measureField, isMatrix, maxR, categories, i, _that, size),
                            toolTipY = findToolTipPosY(height, maxR, row, isMatrix, categories, column, i) - size(Math.sqrt(d[measureField])) - 11 * chartSize.height / 640,
                            toolTipValue = formatNumber(d[measureField]);

                        generateToolTip(toolTipX, toolTipY, toolTipValue, contentG, chartSize, annotationSize * 1.5);

                    }
                });

            proportions.each(function (d, i) {
                let rowNow;
                if (isPrime(categories.length) && i === categories.length - 1) {
                    rowNow = Math.floor((i - 1) / column)
                } else {
                    rowNow = Math.floor(i / column);
                }
                d3.select(this).attr("transform", `translate(0,${bubbleMT * rowNow})`); //行与行之间的间隔
                // if (rowNow === focusRow) { // 如果是focus所在的行
                //     if (svg.select(".tooltip").node()) { //TODO: 这里在data.length===1的时候，会引起报错，暂时这样处理
                //         bubbleMT = svg.select(".tooltip").node().getBBox().height
                //         d3.select(this).attr("transform", `translate(0,${bubbleMT * rowNow})`); //行与行之间的间隔
                //     }
                // }
            })

            proportions.append("circle")
                .style('stroke-width', '1')
                .attr("class", "data-item")
                .attr("size", function (d) { return d[measureField]; })
                .attr("fill", d => d[breakdown[0].field] === focus[0].value ? Color.HIGHLIGHT : Color.DEFAULT)
                .attr("r", function (d) { return size(Math.sqrt(d[measureField])); })
                .attr("cx", function (d, i) {
                    if (column === 1) {
                        if (data.length === 2) {//异常处理
                            return i === 0 ? size(Math.sqrt(d[measureField])) : 2.4 * maxR + size(Math.sqrt(d[measureField]))
                        }
                        return (_that.width() - size(Math.sqrt(d[measureField]))) / 2
                    }
                    if (!isMatrix) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                    else if (isPrime(categories.length) && Math.floor(i / column) === row - 1) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor(i % column))
                    } else if (isPrime(categories.length) && Math.floor(i / column) === row) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor((i - 1) % column) + 1)
                    } else {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                })
                .attr("cy", function (d, i) {
                    let startPoint = (height - 3.6 * maxR) / (row - 1) <= 2.5 * maxR ? 1.8 * maxR : (height - 2.5 * maxR * (row - 1)) / 2;
                    if (!isMatrix) {
                        return 1.8 * maxR + (height - 3.6 * maxR) / 2
                    }
                    else if (isPrime(categories.length) && i === categories.length - 1) {

                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i - 1) / column))
                    } else {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor(i / column))
                    }
                });


            //breakdown
            proportions.append('text').attr("class", "labels")
                .attr('x', function (d, i) {
                    if (column === 1) {
                        if (data.length === 2) {//异常处理
                            return i === 0 ? size(Math.sqrt(d[measureField])) : 2.4 * maxR + size(Math.sqrt(d[measureField]))
                        }
                        return (_that.width() - size(Math.sqrt(d[measureField]))) / 2
                    }
                    if (!isMatrix) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                    else if (isPrime(categories.length) && Math.floor(i / column) === row - 1) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor(i % column))
                    } else if (isPrime(categories.length) && Math.floor(i / column) === row) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor((i - 1) % column) + 1)
                    } else {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                })
                .attr('y', function (d, i) {
                    let startPoint = (height - 3.6 * maxR) / (row - 1) <= 2.5 * maxR ? 1.8 * maxR : (height - 2.5 * maxR * (row - 1)) / 2;
                    if (!isMatrix) {
                        return 1.8 * maxR + (height - 3.6 * maxR) / 2 + 1.1 * maxR
                    }
                    else if (isPrime(categories.length) && i === categories.length - 1) {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i - 1) / column)) + 1.1 * maxR
                    } else {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor(i / column)) + 1.1 * maxR
                    }
                })
                .text(d => {
                    let text = d[breakdown[0].field];
                    return text.length > substringTxtL ? text.substring(0, substringTxtL - 2) + "..." : text;
                })
                .attr('fill', d => d[breakdown[0].field] === focus[0].value ? Color.HIGHLIGHT : 'black')
                .attr('font-family', d => {
                    // if (d[breakdown[0].field] === focus[0].value) {
                    //     return TEXTFONT;
                    // }
                    return NUMFONT;
                })
                //.attr('font-size', d3.min([height, width]) * 0.045)
                .attr('font-size', font.label)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'hanging');


            if (this.style() === Style.COMICS) {
                //for transform
                let focusNodeBBox = contentG.selectAll(".items").filter(d => d[breakdown[0].field] === focus[0].value).select('circle').node().getBBox();
                let cx = focusNodeBBox.x + focusNodeBBox.width / 2,
                    cy = focusNodeBBox.y + focusNodeBBox.height / 2 + bubbleMT * focusRow;

                let metaphorHeight = this.size() === Size.LARGE ? 3.4 * maxR : 3.2 * maxR,
                    metaphorWidth = metaphorHeight / 1.29;

                let metaphor = contentG.append("image")
                    .attr('xlink:href', metaphor22);

                let contentBBox = contentG.select('g').node().getBBox();
                metaphor.attr("width", metaphorWidth)
                    .attr("height", metaphorHeight)
                    .attr("x", contentBBox.width + metaphorWidth * 0.1)
                    .attr("y", cy - metaphorHeight / 2);

                let pointHeight = metaphorHeight,
                    pointWidth = metaphorHeight / 2.79;

                contentG.append("image")
                    .attr('xlink:href', metaphor22_0)
                    .attr("width", pointWidth)
                    .attr("height", pointHeight)
                    .attr("x", cx)
                    .attr("y", cy - pointHeight / 2 * 0.95);
            }

            if(this.size() === 'large' ) {
                let _h = PcontentG.node().getBBox().height,
                _w = PcontentG.node().getBBox().width;
                let marginTop = (this.height() - _h) / 2 - PcontentG.node().getBBox().y,
                    marginLeft = (this.width() - _w) / 2 - PcontentG.node().getBBox().x;

                contentG.attr("transform", "translate(" + marginLeft + "," + marginTop + ")")
            } else {
                let _h = contentG.node().getBBox().height,
                _w = contentG.node().getBBox().width;
                let marginTop = (this.height() - _h) / 2 - contentG.node().getBBox().y,
                    marginLeft = (this.width() - _w) / 2 - contentG.node().getBBox().x;

                contentG.attr("transform", "translate(" + marginLeft + "," + marginTop + ")")
            }
            

            return svg;
        }
        if (this.style() === Style.PICTOGRAPH) {
            let data = this.factdata();
            let measure = this.measure();
            let breakdown = this.breakdown();
            let pictype = measure[0].pictype;
            let focus = this.focus();
            let measureField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field;
            // set the dimensions and margins of the graph
            // let isMatrix = style.style === 'matrix' ? true : false;
            let isMatrix = true;
            let { margin, maxBubbleNum, annotationSize, substringTxtL, bubbleMT } = getSizeBySize(this.size(), "outlier", isMatrix),
                width = this.width() - margin.left - margin.right,
                height = this.height() - margin.top - margin.bottom,
                font = fontSize[this.size()];
            let svg = d3.select(this.container())
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom);

            if (measure.length > 1) {
                svg.append("rect")
                    .attr("width", width)
                    .attr("height", height).attr("fill", "none");
                return svg;
            }
            data = data.filter(d => d[measureField] > 0);
            data = data.slice(0, maxBubbleNum);

            let contentG = svg.append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            let row, column;
            let categories = data;
            if (isMatrix) {
                let n = categories.length;
                let max_sqrt = Math.floor(Math.sqrt(n));
                let candidate;
                if (isPrime(n)) n = n - 1
                while (max_sqrt) {
                    if (n % max_sqrt === 0) {
                        candidate = max_sqrt
                        break;
                    }
                    max_sqrt -= 1
                }
                row = d3.min([candidate, n / candidate])
                column = n / row
            } else {
                row = 1;
                column = categories.length;
            }
            let maxR = d3.min([0.9 * height / (row + 1) / 2, 0.9 * width / (column + 1) / 2])
            /*------------------通过名称找寻icon----------------------------*/
            svg.append("defs")
                .append("g")
                .attr("id", `pictype${pictype}`)
                .append("path")
                .attr("d", PictorialType[pictype])

            let typesizex1 = svg.select(`#pictype${pictype}`).node().getBBox().width;
            let typesizey1 = svg.select(`#pictype${pictype}`).node().getBBox().height;
            let typex = svg.select(`#pictype${pictype}`).node().getBBox().x;
            let typey = svg.select(`#pictype${pictype}`).node().getBBox().y;

            let area1 = typesizex1 * typesizey1;
            let area2 = maxR * maxR * Math.PI;
            let scaleorign = Math.sqrt(area2 / area1);
            let scale1 = [];
            //Size channels
            let size = d3.scaleLinear()
                .domain([0, d3.max(data, function (d) { return Math.sqrt(d[measureField]); })])
                .range([0, scaleorign]);
            //Icon 进行缩放
            data.forEach((item, index) => {
                scale1.push(size(Math.sqrt(item[measureField])));
                svg.append("defs")
                    .append("g")
                    .attr("id", `pictypecate${index}`)
                    .append("path")
                    .attr("d", PictorialType[pictype])
                    .attr("transform", function () {
                        return `scale(${scale1[index]})`;
                    })
            })


            // 找到focus的在第几行
            let focusRow = 0;
            for (let i = 0; i < data.length; i++) {
                if (data[i][breakdown[0].field] === focus[0].value) {
                    if (isPrime(categories.length) && i === categories.length - 1) {
                        focusRow = Math.floor((i - 1) / column)
                    } else {
                        focusRow = Math.floor(i / column);
                    }
                    break;
                }

            }

            // Draw Icons  
            let rowTotalWidth;
            if (categories.length % 2 === 0) {
                rowTotalWidth = 2.4 * maxR * column;
            } else {
                rowTotalWidth = 2.4 * maxR * (column + 1);
            }
            let chartWidth = this.width(),
                chartHeight = this.height();
            let _that = this;
            let proportions = contentG.append('g')
                .selectAll('g')
                .data(data)
                .enter()
                .append('g')
                .attr("class", "items")
                .each(function (d, i) {
                    //tool tip
                    if (d[breakdown[0].field] === focus[0].value) {
                        let chartSize = {
                            width: chartWidth,
                            height: chartHeight
                        }
                        let toolTipX = findToolTipPosX(column, row, d, measureField, isMatrix, maxR, categories, i, _that, size),
                            toolTipY = findToolTipPosY(height, maxR, row, isMatrix, categories, column, i) - scale1[i] * typesizey1 / 2 - 11 * chartSize.height / 640,
                            toolTipValue = formatNumber(d[measureField]);

                        generateToolTip(toolTipX, toolTipY, toolTipValue, d3.select(this), chartSize, annotationSize * 1.5);

                    }
                });

            proportions.each(function (d, i) {
                let rowNow;
                if (isPrime(categories.length) && i === categories.length - 1) {
                    rowNow = Math.floor((i - 1) / column)
                } else {
                    rowNow = Math.floor(i / column);
                }
                if (rowNow === focusRow) { // 如果是focus所在的行
                    if (svg.select(".tooltip").node()) { //TODO: 这里在data.length===1的时候，会引起报错，暂时这样处理
                        bubbleMT = svg.select(".tooltip").node().getBBox().height
                        d3.select(this).attr("transform", `translate(0,${bubbleMT * rowNow})`); //行与行之间的间隔
                    }
                }
            })

            proportions.append("use")
                .attr("xlink:href", function (d, i) { return `#pictypecate${i}` })
                .attr("id", function (d, i) { return "icontype" + i })
                .attr("class", "data-item")
                .attr("fill", d => d[breakdown[0].field] === focus[0].value ? Color.HIGHLIGHT : Color.DEFAULT)
                .attr("r", function (d) { return size(Math.sqrt(d[measureField])); })
                .attr("x", function (d, i) {
                    if (column === 1) {
                        if (data.length === 2) {//异常处理
                            return i === 0 ? size(Math.sqrt(d[measureField])) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i]) : 2.4 * maxR + size(Math.sqrt(d[measureField])) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                        }
                        return (_that.width() - size(Math.sqrt(d[measureField]))) / 2 - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                    }
                    if (!isMatrix) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column)) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                    }
                    else if (isPrime(categories.length) && Math.floor(i / column) === row - 1) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor(i % column)) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                    } else if (isPrime(categories.length) && Math.floor(i / column) === row) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor((i - 1) % column) + 1) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                    } else {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column)) - typesizex1 * scale1[i] / 2 - Math.abs(typex * scale1[i])
                    }
                })
                .attr("y", function (d, i) {
                    let startPoint = (height - 3.6 * maxR) / (row - 1) <= 2.5 * maxR ? 1.8 * maxR : (height - 2.5 * maxR * (row - 1)) / 2;
                    if (!isMatrix) {
                        return 1.8 * maxR + (height - 3.6 * maxR) / 2 - typesizey1 * scale1[i] / 2 - Math.abs(typey * scale1[i])
                    }
                    else if (isPrime(categories.length) && i === categories.length - 1) {

                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i - 1) / column)) - typesizey1 * scale1[i] / 2 - Math.abs(typey * scale1[i])
                    } else {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor(i / column)) - typesizey1 * scale1[i] / 2 - Math.abs(typey * scale1[i])
                    }
                });


            //breakdown
            proportions.append('text').attr("class", "labels")
                .attr('x', function (d, i) {
                    if (column === 1) {
                        if (data.length === 2) {//异常处理
                            return i === 0 ? size(Math.sqrt(d[measureField])) : 2.4 * maxR + size(Math.sqrt(d[measureField]))
                        }
                        return (_that.width() - size(Math.sqrt(d[measureField]))) / 2
                    }
                    if (!isMatrix) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                    else if (isPrime(categories.length) && Math.floor(i / column) === row - 1) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor(i % column))
                    } else if (isPrime(categories.length) && Math.floor(i / column) === row) {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor((i - 1) % column) + 1)
                    } else {
                        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
                    }
                })
                .attr('y', function (d, i) {
                    let startPoint = (height - 3.6 * maxR) / (row - 1) <= 2.5 * maxR ? 1.8 * maxR : (height - 2.5 * maxR * (row - 1)) / 2;
                    if (!isMatrix) {
                        return 1.8 * maxR + (height - 3.6 * maxR) / 2 + 1.1 * maxR
                    }
                    else if (isPrime(categories.length) && i === categories.length - 1) {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i - 1) / column)) + 1.1 * maxR
                    } else {
                        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor(i / column)) + 1.1 * maxR
                    }
                })
                .text(d => {
                    let text = d[breakdown[0].field];
                    return text.length > substringTxtL ? text.substring(0, substringTxtL - 2) + "..." : text;
                })
                .attr('fill', d => d[breakdown[0].field] === focus[0].value ? Color.HIGHLIGHT : 'black')
                .attr('font-family', d => {
                    if (d[breakdown[0].field] === focus[0].value) {
                        return TEXTFONT;
                    }
                    return NUMFONT;
                })
                //.attr('font-size', d3.min([height, width]) * 0.045)
                .attr('font-size', font.label)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'hanging');

            let _h = contentG.node().getBBox().height,
                _w = contentG.node().getBBox().width;
            let marginTop = (this.height() - _h) / 2 - contentG.node().getBBox().y,
                marginLeft = (this.width() - _w) / 2 - contentG.node().getBBox().x;

            contentG.attr("transform", "translate(" + marginLeft + "," + marginTop + ")")

            return svg;
        }
    }
}

const isPrime = num => {
    for (let i = 2, s = Math.sqrt(num); i <= s; i++)
        if (num % i === 0) return false;
    return num > 1;
};
const findToolTipPosX = (column, row, d, measureField, isMatrix, maxR, categories, i, _that, size) => {

    let rowTotalWidth;
    if (categories.length % 2 === 0) {
        rowTotalWidth = 2.4 * maxR * column;
    } else {
        rowTotalWidth = 2.4 * maxR * (column + 1);
    }
    if (column === 1) {
        if (categories.length === 2) {//异常处理
            return i === 0 ? size(Math.sqrt(d[measureField])) : 2.4 * maxR + size(Math.sqrt(d[measureField]))
        }
        return (_that.width() - size(Math.sqrt(d[measureField]))) / 2
    }
    if (!isMatrix) {
        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
    }
    else if (isPrime(categories.length) && Math.floor(i / column) === row - 1) {
        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor(i % column))
    } else if (isPrime(categories.length) && Math.floor(i / column) === row) {
        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column) * (Math.floor((i - 1) % column) + 1)
    } else {
        return 1.2 * maxR + (rowTotalWidth - 2.4 * maxR) / (column - 1) * (Math.floor(i % column))
    }
};

const findToolTipPosY = (height, maxR, row, isMatrix, categories, column, i) => {
    let startPoint = (height - 3.6 * maxR) / (row - 1) <= 2.5 * maxR ? 1.8 * maxR : (height - 2.5 * maxR * (row - 1)) / 2;
    if (!isMatrix) {
        return 1.8 * maxR + (height - 3.6 * maxR) / 2
    }
    else if (isPrime(categories.length) && i === categories.length - 1) {
        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor((i - 1) / column))
    } else {
        return startPoint + d3.min([(height - 3.6 * maxR) / (row - 1), 2.5 * maxR]) * (Math.floor(i / column))
    }
};
const getSizeBySize = (size, factType, isMatrix) => {
    let tickSize, annotationSize, maxBubbleNum, substringTxtL, bubbleMT;
    switch (size) {
        case Size.WIDE:
            tickSize = 10;
            annotationSize = 15;
            substringTxtL = 8;
            bubbleMT = 15;
            break;
        case Size.MIDDLE:
            tickSize = 10;
            annotationSize = 15;
            substringTxtL = 8;
            bubbleMT = 10;
            break;
        case Size.SMALL:
            tickSize = 7;
            annotationSize = 10;
            substringTxtL = 6;
            bubbleMT = 5;
            break;
        case Size.LARGE:
        default:
            tickSize = 19;
            annotationSize = 16;
            substringTxtL = 10;
            bubbleMT = 10;
            break;
    }
    let margin;
    switch (size) {
        case Size.LARGE:
            margin = { top: 10, right: 40, bottom: 10, left: 40 };
            maxBubbleNum = isMatrix ? 10 : 15;
            break;
        case Size.WIDE:
            margin = { top: 10, right: 20, bottom: 10, left: 20 };
            maxBubbleNum = isMatrix ? 10 : 10;
            break;
        case Size.MIDDLE:
            margin = { top: 10, right: 5, bottom: 10, left: 5 };
            maxBubbleNum = isMatrix ? 10 : 8;
            break;
        case Size.SMALL:
            margin = { top: 10, right: 5, bottom: 10, left: 5 };
            maxBubbleNum = isMatrix ? 10 : 5;
            break;
        default:
            margin = { top: 10, right: 20, bottom: 10, left: 20 };
            break;
    }
    return {
        tickSize: tickSize,
        annotationSize: annotationSize,
        margin: margin,
        maxBubbleNum: maxBubbleNum,
        substringTxtL: substringTxtL,
        bubbleMT: bubbleMT,
    }
};

// function hexToRGB(h) {
//     let r = 0, g = 0, b = 0;

//     // 3 digits
//     if (h.length === 4) {
//         r = "0x" + h[1] + h[1];
//         g = "0x" + h[2] + h[2];
//         b = "0x" + h[3] + h[3];

//         // 6 digits
//     } else if (h.length === 7) {
//         r = "0x" + h[1] + h[2];
//         g = "0x" + h[3] + h[4];
//         b = "0x" + h[5] + h[6];
//     }

//     return { r, g, b };
// }

const countTicksBeforeIndex = (animation, index) => {
    let count = 0;
    let visited = []
    for (let key in animation) {
        if (animation[key].index < index && visited.indexOf(animation[key].index) === -1) {
            count += animation[key].duration
            visited.push(animation[key].index)
        };
    }
    return count
}
const getSameYear = (array) => {
    let temp = getYear(array[0]);
    for (let date of array) {
        if (temp !== getYear(date)) return false;
    }
    return temp;
}

const getYear = (str) => {
    let year = str.split('/')[0];
    if (!isNaN(year) && year.length === 4) {//is number
        return year;
    } else return false;
}


export default BubbleChart;
