import * as d3 from 'd3';
import Chart from '../../chart';
import Color from '../../visualization/color';
import Size from '../../visualization/size';
import Style from '../../visualization/style';

import metaphor3 from '../../metaphor/metaphor3.png';//categorization
import metaphor5 from '../../metaphor/metaphor5.png';//proportion
import metaphor30 from '../../metaphor/metaphor30.png';//distribution

const chartSize = {
    "small": {
        "width": 80,
        "height": 60,
        "fontSize": 12,
        "padding": 0.3,
        "strokeWidth": 1,
        "maxRectWidth": 10,
        "maxRectHeight": 25,
        "margin": {
            left: 2,
            bottom: 2, //for categotization            
            top: 12,  //for proportion               
            lineSpace: 1  //for proportion 
        },
        "titleHeight": 46,
        "maxTitleWidth": 40,
        'paddingTop': 20
    },
    "middle": {
        "width": 140,
        "height": 100,
        "fontSize": 14,
        "padding": 0.5,
        "strokeWidth": 1,
        "maxRectWidth": 25,
        "maxRectHeight": 13,
        "margin": {
            left: 4,
            bottom: 4, //for categotization            
            top: 14,  //for proportion               
            lineSpace: 1  //for proportion  
        },
        "titleHeight": 40,
        "maxTitleWidth": 50,
        'paddingTop': 24.5
    },
    "wide": {
        "width": 280,
        "height": 100,
        "fontSize": 16,
        "padding": 0.8,
        "strokeWidth": 2,
        "maxRectWidth": 42,
        "maxRectHeight": 16,
        "margin": {
            left: 8,
            bottom: 6, //for categotization            
            top: 17,  //for proportion               
            lineSpace: 1  //for proportion  
        },
        "titleHeight": 31,
        "maxTitleWidth": 60,
        'paddingTop': 22.5
    },
    "large": {
        "width": 320,
        "height": 320,
        "fontSize": 20,
        "padding": 1,
        "strokeWidth": 3,
        "maxRectWidth": 31,
        "maxRectHeight": 23,
        "margin": {
            left: 6,
            bottom: 8, //for categotization            
            top: 22,  //for proportion               
            lineSpace: 3  //for proportion  
        },
        "titleHeight": 33,
        "maxTitleWidth": 90,
        'paddingTop': 34
    }
};
const NUMFONT = "Arial-Regular";
class TreeMap extends Chart {
    displayCategorization() {
        let factData = this.factdata()
        // let measure = this.measure();
        let breakdown = this.breakdown();
        let size = this.size();
        let chartWidth = this.width()
        let chartHeight = this.height()
        let COUNT = "COUNT"
        let data = {
            name: "treemap",
            children: [
            ]
        }
        let seriesName
        if (breakdown.length === 2) {
            let seriesData = d3.nest().key(d => d[breakdown[0].field]).entries(factData);
            seriesData.map((d) => {
                data.children.push({
                    name: d.key,
                    children: d.values.map((d) => {
                        return {
                            // name: [d[breakdown[0].field], d[breakdown[1].field]],
                            name: [d[breakdown[1].field]],
                            value: d[COUNT]
                        }
                    })
                })
                return true;
            })
        } else {
            for (let i = 0; i < factData.length; i++)
                data.children.push({
                    // name: factData[i][breakdown[0].field],
                    name: breakdown.map((d) => { return factData[i][d.field] }),
                    value: factData[i][COUNT]
                })
        }

        let margin = { top: 20 * chartHeight / 320, right: 20 * chartWidth / 320, bottom: 20 * chartHeight / 320, left: 20 * chartWidth / 320 },
            width = chartWidth - margin.left - margin.right,
            height = chartHeight - margin.top - margin.bottom + chartSize[size].paddingTop;

        if (breakdown.length === 2) {
            margin.top = margin.top - chartSize[size].paddingTop;
        }

        if (breakdown.length === 1) {
            height = chartHeight - margin.top - margin.bottom;
        }
        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        // let format = d3.format(",d")
        if (this.style() === Style.COMICS) width = this.size() === Size.WIDE ? 0.82 * width : 0.8 * width;

        let nestTreemap = data => d3.treemap()
            // .tile(tile)
            .size([width, height])
            .padding(breakdown.length === 2 ? 1 : chartSize[size].padding)
            .paddingTop(chartSize[size].paddingTop)
            // .paddingOuter(3)
            .paddingInner(1)
            .round(true)
            (d3.hierarchy(data)
                .sum(d => d.value)
                .sort((a, b) => b.value - a.value))

        let treemap = data => d3.treemap()
            .tile(d3.treemapBinary)
            .size([width, height])
            .padding(chartSize[size].padding)
            .paddingInner(1)
            .round(true)
            (d3.hierarchy(data)
                .sum(d => d.value)
                .sort((a, b) => b.value - a.value))

        let root
        if (breakdown.length === 2) {
            root = nestTreemap(data);
        } else {
            root = treemap(data);
        }

        if (breakdown.length === 2) {
            let titleLayer = svg.append('g').attr('class', 'title-layer')
            titleLayer
                // .attr('clip-path', 'url(#clip_tree)')
                .selectAll("titles")
                .data(root.descendants().filter(function (d) { return d.depth === 1 }))
                .enter()
                .append("rect")
                .attr('id', function (d, i) { return 'titleRect_' + i })
                .attr("x", function (d) { return d.x0 })
                .attr("y", function (d) { return d.y0 }) //21
                .attr("width", function (d) { return d.x1 - d.x0 >= 0 ? d.x1 - d.x0 : 0 })
                .attr("height", function (d) { return d.y1 - d.y0 })
                .attr("fill", function (d, i) {
                    if (d.depth === 2) {
                        d = d.parent;
                        return Color.CATEGORICAL[seriesName.indexOf(d.data.name) % 10]
                    }
                    else return Color.CATEGORICAL[i % 10];
                })

            titleLayer.append('defs').append('clipPath')
                .attr('id', 'clip_tree')
                .append('rect')
                .attr("width", width)
                .attr('height', height)

            // titleLayer
            //     .selectAll('rect')
            //     .append('clipPath')
            //     .append('rect')
            //     .attr('id', (d,i)=>'clip_title'+i)
            //     .attr("x", d=>d.x0+1)
            //     .attr("y", d=>d.y0 + 21 ) //21
            //     .attr("width", function (d) { return d.x1 - d.x0 >= 2 ? d.x1 - d.x0 - 2 : 0 })
            //     .attr("height", 23)

            titleLayer
                .selectAll("titles")
                .data(root.descendants().filter(function (d) { return d.depth === 1 }))
                .enter()
                .append("text")
                .attr('id', function (d, i) { return 'titleText_' + i })
                .attr("x", function (d) { return d.x0 + chartSize[size].margin.left })
                .attr("y", function (d) { return d.y0 - chartSize[size].fontSize / 2 })
                .text(d => {
                    let nShow = parseInt((d.x1 - d.x0 - chartSize[size].margin.left) / chartSize[size].fontSize * 1.5)
                    if (nShow < 3) return ''
                    if (nShow >= d.data.name.length) { return d.data.name }
                    else return d.data.name.substr(0, nShow - 3) + '...'
                })
                .attr("font-size", chartSize[size].fontSize)
                .attr("font-family", NUMFONT)
                // .attr("opacity", d => {
                //     if (d.x1 - d.x0 < chartSize[size].maxTitleWidth)
                //         return 0
                //     else return 1
                // })
                .attr("transform", `translate(0,${chartSize[size].paddingTop})`)
                .append("title")
                .text(d => {
                    return d.data.name;
                });

            // .attr('clip-path', (d, i) => 'url(#clip_' + i + ')')
        }

        const leaf = svg.append('g')
            .attr('class', 'leaf-layer')
            .selectAll("g")
            .data(root.leaves())
            .join("g")
            .attr("transform", d => `translate(${d.x0},${d.y0})`);

        seriesName = Array.from(new Set(root.leaves().map(d => d.parent.data.name)))

        leaf.append("rect")
            .attr('class', 'leaf')
            .attr('id', (d, i) => 'rect_' + i)
            .attr("fill", (d, i) => {
                if (breakdown.length === 2) return 'white'
                if (d.depth === 2) {
                    d = d.parent;
                    return Color.CATEGORICAL[seriesName.indexOf(d.data.name) % 10]
                }
                else return Color.CATEGORICAL[i % 10];
            })
            .attr('opacity', (d, i) => {
                if (breakdown.length === 2) return 0.3
                else return 1
            })
            .attr("width", d => d.x1 - d.x0)
            .attr("height", d => d.y1 - d.y0)

        // let format = d3.format(",d")
        // leaf.append("title")
        //     .text(d => {
        //         // if (d.depth === 2) return `${d.parent.data.name}\n${d.data.name}\n${format(d.value)}`
        //         // else 
        //         return `${d.data.name}\n${format(d.value)}`
        //     });
        //         leaf.append("title")
        //   .text(d => `${d.ancestors().reverse().map(d => d.data.name).join("/")}\n${format(d.value)}`);

        leaf.append("clipPath")
            .attr('id', (d, i) => 'clip_' + i)
            .append('rect')
            .attr("width", d => d.x1 - d.x0)
            .attr('height', d => d.y1 - d.y0)

        leaf.append("text")
            .attr('id', (d, i) => 'label_' + i)
            .attr('class', 'labels')
            .attr('fill', 'black')
            // .attr('fill', Color.TEXT)
            // .style('mix-blend-mode', 'difference')
            // .style('text-shadow', '0.1em 0.1em 0.1em #000000')
            .attr("font-family", NUMFONT)
            .attr('font-size', chartSize[size].fontSize)
            .attr('clip-path', (d, i) => 'url(#clip_' + i + ')')
            // .selectAll("tspan")
            // .data(d => d.data.name)
            // .enter()
            // .append("tspan")
            .attr("x", chartSize[size].margin.left)
            .attr("dy", "1.1em")
            .text(d => {
                let nShow = parseInt((d.x1 - d.x0 - chartSize[size].margin.left) / chartSize[size].fontSize * 1.5)
                if (nShow <= 3) return ''
                if (nShow >= d.data.name[0].length) return d.data.name[0]
                else return d.data.name[0].substr(0, nShow - 3) + '...'
            })

        leaf.selectAll(".labels")
            .each(function (d) {
                let opacity = 1
                let i = d3.select(this).node().id.split("_")[1]
                // let textWdith = leaf.select('#label_' + i).node().getBBox().width
                // let textHeight = leaf.select('#label_' + i).node().getBBox().height

                let rectWidth = leaf.select('#rect_' + i).node().getBBox().width
                let rectHeight = leaf.select('#rect_' + i).node().getBBox().height
                if (rectWidth < chartSize[size].maxRectWidth) opacity = 0
                if (rectHeight < chartSize[size].maxRectHeight) opacity = 0
                d3.select(this)
                    .attr('opacity', opacity)
            })

        if (this.style() === Style.COMICS) {
            let metaphorWidth = width * 0.26,
                metaphorHeight = 1.38 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor3);

            if (this.size() === Size.WIDE) {
                metaphorWidth = width * 0.21;
                metaphorHeight = 1.38 * metaphorWidth;
            } else if (this.size() === Size.MIDDLE) {
                metaphorWidth = width * 0.24;
                metaphorHeight = 1.38 * metaphorWidth;
            }

            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", width + metaphorWidth * 0.06)
                .attr("y", height - metaphorHeight * 0.96);
        }
        return svg;
    }

    displayDistribution() {
        let factData = this.factdata()
        let measure = this.measure();
        let breakdown = this.breakdown();
        let size = this.size();
        let chartWidth = this.width()
        let chartHeight = this.height()

        let data = {
            name: "treemap",
            children: [
            ]
        }
        let mesuredField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field;
        let seriesName
        if (breakdown.length === 2) {
            let seriesData = d3.nest().key(d => d[breakdown[0].field]).entries(factData);
            seriesData.map((d) => {
                data.children.push({
                    name: d.key,
                    children: d.values.map((d) => {
                        return {
                            // name: [d[breakdown[0].field], d[breakdown[1].field]],
                            name: [d[breakdown[1].field]],
                            value: d[mesuredField]
                        }
                    })
                })
                return true;
            })
        } else {
            for (let i = 0; i < factData.length; i++)
                data.children.push({
                    // name: factData[i][breakdown[0].field],
                    name: breakdown.map((d) => { return factData[i][d.field] }),
                    value: factData[i][mesuredField]
                })
        }

        let margin = { top: 20 * chartHeight / 320, right: 20 * chartWidth / 320, bottom: 20 * chartHeight / 320, left: 20 * chartWidth / 320 },
            width = chartWidth - margin.left - margin.right,
            height = chartHeight - margin.top - margin.bottom + chartSize[size].paddingTop;

        if (breakdown.length === 2) {
            margin.top = margin.top - chartSize[size].paddingTop;
        }

        if (breakdown.length === 1) {
            height = chartHeight - margin.top - margin.bottom;
        }
        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("class", 'treeG')
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        if (this.style() === Style.COMICS) width = this.size() === Size.WIDE ? 0.82 * width : 0.8 * width;
        // let format = d3.format(",d")

        let nestTreemap = data => d3.treemap()
            // .tile(tile)
            .size([width, height])
            .padding(breakdown.length === 2 ? 1 : chartSize[size].padding)
            .paddingTop(chartSize[size].paddingTop)
            // .paddingOuter(3)
            .paddingInner(1)
            .round(true)
            (d3.hierarchy(data)
                .sum(d => d.value)
                .sort((a, b) => b.value - a.value))

        let treemap = data => d3.treemap()
            // .tile(tile)
            .size([width, height])
            .padding(chartSize[size].padding)
            .paddingInner(1)
            .round(true)
            (d3.hierarchy(data)
                .sum(d => d.value)
                .sort((a, b) => b.value - a.value))

        let root
        if (breakdown.length === 2) {
            root = nestTreemap(data);
        } else {
            root = treemap(data);
        }

        if (breakdown.length === 2) {
            let titleLayer = svg.append('g').attr('class', 'title-layer')
            titleLayer
                // .attr('clip-path', 'url(#clip_tree)')
                .selectAll("titles")
                .data(root.descendants().filter(function (d) { return d.depth === 1 }))
                .enter()
                .append("rect")
                .attr('id', function (d, i) { return 'titleRect_' + i })
                .attr("x", function (d) { return d.x0 })
                .attr("y", function (d) { return d.y0 }) //21
                .attr("width", function (d) { return d.x1 - d.x0 >= 0 ? d.x1 - d.x0 : 0 })
                .attr("height", function (d) { return d.y1 - d.y0 })
                .attr("fill", function (d, i) {
                    if (d.depth === 2) {
                        d = d.parent;
                        return Color.CATEGORICAL[seriesName.indexOf(d.data.name) % 10]
                    }
                    else return Color.CATEGORICAL[i % 10];
                })
            // .attr('fill-opacity', 1)
            // .attr("transform", "translate(0,-40)")

            titleLayer.append('defs').append('clipPath')
                .attr('id', 'clip_tree')
                .append('rect')
                .attr("width", width)
                .attr('height', height)

            // titleLayer
            //     .selectAll('rect')
            //     .append('clipPath')
            //     .append('rect')
            //     .attr('id', (d,i)=>'clip_title'+i)
            //     .attr("x", d=>d.x0+1)
            //     .attr("y", d=>d.y0 + 21 ) //21
            //     .attr("width", function (d) { return d.x1 - d.x0 >= 2 ? d.x1 - d.x0 - 2 : 0 })
            //     .attr("height", 23)

            titleLayer
                .selectAll("titles")
                .data(root.descendants().filter(function (d) { return d.depth === 1 }))
                .enter()
                .append("text")
                .attr('id', function (d, i) { return 'titleText_' + i })
                .attr("x", function (d) { return d.x0 + chartSize[size].margin.left })
                .attr("y", function (d) { return d.y0 - chartSize[size].fontSize / 2 })
                .text(d => {
                    let nShow = parseInt((d.x1 - d.x0 - chartSize[size].margin.left) / chartSize[size].fontSize * 1.5)
                    if (nShow <= 3) return ''
                    if (nShow >= d.data.name.length) return d.data.name
                    else return d.data.name.substr(0, nShow - 3) + '...'
                })
                .attr("font-size", chartSize[size].fontSize)
                .attr("font-family", NUMFONT)
                // .attr("opacity", d => {
                //     if (d.x1 - d.x0 < chartSize[size].maxTitleWidth)
                //         return 0
                //     else return 1
                // })
                .attr("transform", `translate(0,${chartSize[size].paddingTop})`)
                .append("title")
                .text(d => {
                    return d.data.name;
                });

            // .attr('clip-path', (d, i) => 'url(#clip_' + i + ')')
        }

        const leaf = svg.append('g')
            .attr('class', 'leaf-layer')
            .selectAll("g")
            .data(root.leaves())
            .join("g")
            .attr("transform", d => `translate(${d.x0},${d.y0})`);

        seriesName = Array.from(new Set(root.leaves().map(d => d.parent.data.name)))

        leaf.append("rect")
            .attr('class', 'leaf')
            .attr('id', (d, i) => 'rect_' + i)
            .attr("fill", (d, i) => {
                if (breakdown.length === 1) return Color.DEFAULT
                if (breakdown.length === 2) return 'white'
                if (d.depth === 2) {
                    d = d.parent;
                    return Color.CATEGORICAL[seriesName.indexOf(d.data.name) % 10]
                }
                else return Color.CATEGORICAL[i % 10];
            })
            .attr('opacity', (d, i) => {
                if (breakdown.length === 2) return 0.3
                else return 1
            })
            .attr("width", d => d.x1 - d.x0)
            .attr("height", d => d.y1 - d.y0)

        let format = d3.format(",d")
        leaf.append("title")
            .text(d => {
                // if (d.depth === 2) return `${d.parent.data.name}\n${d.data.name}\n${format(d.value)}`
                // else 
                return `${d.data.name}\n${format(d.value)}`
            });
        //         leaf.append("title")
        //   .text(d => `${d.ancestors().reverse().map(d => d.data.name).join("/")}\n${format(d.value)}`);

        leaf.append("clipPath")
            .attr('id', (d, i) => 'clip_' + i)
            .append('rect')
            .attr("width", d => d.x1 - d.x0)
            .attr('height', d => d.y1 - d.y0)

        if (breakdown.length === 1) {
            leaf.append("text")
                .attr('id', (d, i) => 'label_' + i)
                .attr('class', 'labels')
                .attr('fill', 'black')
                // .attr('fill', Color.TEXT)
                // .style('mix-blend-mode', 'difference')
                // .style('text-shadow', '0.1em 0.1em 0.1em #000000')
                .attr("font-family", NUMFONT)
                .attr('font-size', chartSize[size].fontSize)
                .attr('clip-path', (d, i) => 'url(#clip_' + i + ')')
                // .selectAll("tspan")
                // .data(d => [d.data.name[0], d.data.value])
                // .enter()
                // .append("tspan")
                // .attr("x", chartSize[size].margin.left)
                // .attr("dy", "1.1em")
                // .text(d => {
                //     return d
                // })
                .attr("x", chartSize[size].margin.left)
                .attr("dy", "1.1em")
                .text(d => {
                    let nShow = parseInt((d.x1 - d.x0 - chartSize[size].margin.left) / chartSize[size].fontSize * 1.5)
                    if (nShow <= 3) return ''
                    if (nShow >= d.data.name[0].length) return d.data.name[0]
                    else return d.data.name[0].substr(0, nShow - 3) + '...'
                })

        } else {
            leaf.append("text")
                .attr('id', (d, i) => 'label_' + i)
                .attr('class', 'labels')
                .attr('fill', 'black')
                // .attr('fill', Color.TEXT)
                // .style('mix-blend-mode', 'difference')
                // .style('text-shadow', '0.1em 0.1em 0.1em #000000')
                .attr("font-family", NUMFONT)
                .attr('font-size', chartSize[size].fontSize)
                .attr('clip-path', (d, i) => 'url(#clip_' + i + ')')
                // .selectAll("tspan")
                // .data(d => d.data.name)
                // .enter()
                // .append("tspan")
                .attr("x", chartSize[size].margin.left)
                .attr("dy", "1.1em")
                .text(d => {
                    let nShow = parseInt((d.x1 - d.x0 - chartSize[size].margin.left) / chartSize[size].fontSize * 1.5)
                    if (nShow <= 3) return ''
                    if (nShow >= d.data.name[0].length) return d.data.name[0]
                    else return d.data.name[0].substr(0, nShow - 3) + '...'
                })
        }

        leaf.selectAll(".labels")
            .each(function (d) {
                let opacity = 1
                let i = d3.select(this).node().id.split("_")[1]
                // let textWdith = leaf.select('#label_' + i).node().getBBox().width
                // let textHeight = leaf.select('#label_' + i).node().getBBox().height

                let rectWidth = leaf.select('#rect_' + i).node().getBBox().width
                let rectHeight = leaf.select('#rect_' + i).node().getBBox().height
                if (rectWidth < chartSize[size].maxRectWidth) opacity = 0
                if (rectHeight < chartSize[size].maxRectHeight) opacity = 0
                d3.select(this)
                    .attr('opacity', opacity)
            })

        if (this.style() === Style.COMICS) {
            let metaphorWidth = width * 0.26,
                metaphorHeight = 1.25 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor30);

            if (this.size() === Size.WIDE) {
                metaphorWidth = width * 0.21;
                metaphorHeight = 1.25 * metaphorWidth;
            } else if (this.size() === Size.MIDDLE) {
                metaphorWidth = width * 0.24;
                metaphorHeight = 1.25 * metaphorWidth;
            }

            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", width + metaphorWidth * 0.06)
                .attr("y", height - metaphorHeight * 0.96);
        }
        return svg;
    }

    displayProportion() {
        let factData = this.factdata()
        let measure = this.measure();
        let breakdown = this.breakdown();
        let focus = this.focus();
        let size = this.size();
        let chartWidth = this.width()
        let chartHeight = this.height()

        let data = {
            name: "treemap",
            children: [
            ]
        }
        let sumValue = 0
        let mesuredField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field;
        factData.map(d => {
            sumValue += d[mesuredField]
            return sumValue
        })
        for (let i = 0; i < factData.length; i++)
            data.children.push({
                name: factData[i][breakdown[0].field],
                value: factData[i][mesuredField],
                percentage: Math.round(factData[i][mesuredField] / sumValue * 100) + '%'
            })

        let margin = { top: 20 * chartHeight / 320, right: 20 * chartWidth / 320, bottom: 20 * chartHeight / 320, left: 20 * chartWidth / 320 },
            width = chartWidth - margin.left - margin.right,
            height = chartHeight - margin.top - margin.bottom;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

        if (breakdown.length > 1) return svg;
        // let format = d3.format(",d")

        if (this.style() === Style.COMICS) width = this.size() === Size.WIDE ? 0.82 * width : 0.8 * width;
        let treemap = data => d3.treemap()
            // .tile(tile)
            .size([width, height])
            .padding(chartSize[size].padding)
            .round(true)
            (d3.hierarchy(data)
                .sum(d => d.value)
                .sort((a, b) => b.value - a.value))

        const root = treemap(data);
        const leaf = svg.selectAll("g")
            .attr('class', 'leaf')
            .data(root.leaves())
            .join("g")
            .attr("transform", d => `translate(${d.x0},${d.y0})`);

        leaf.append("rect")
            // .attr('id', ({ index }) => 'rect_' + index)
            .attr("id", (d, i) => 'rect_' + i)
            .attr("fill", (d, i) => { while (d.depth > 1) d = d.parent; return Color.CATEGORICAL[i % 10]; })
            .attr("width", d => d.x1 - d.x0)
            .attr("height", d => d.y1 - d.y0)


        // let format = d3.format(",d")
        leaf.append("title")
            .text(d => `${d.data.name}\n${d.data.percentage}`);

        leaf.append("clipPath")
            .attr('id', (d, i) => 'clip_' + i)
            .append('rect')
            .attr("width", d => d.x1 - d.x0)
            .attr('height', d => d.y1 - d.y0)

        leaf.append("text")
            .attr('id', (d, i) => 'label_' + i)
            .attr('class', 'label-text')
            .attr("x", chartSize[size].margin.left)
            .attr("y", chartSize[size].margin.top)
            // .attr('fill', '#ffffff')
            // .style('text-shadow', '0.1em 0.1em 0.1em #000000')
            .attr("font-family", NUMFONT)
            .attr('font-size', chartSize[size].fontSize)
            .text(d => {
                let nShow = parseInt((d.x1 - d.x0 - chartSize[size].margin.left) / chartSize[size].fontSize * 1.5)
                if (nShow <= 3) return ''
                if (nShow >= d.data.name.length) return d.data.name
                else return d.data.name.substr(0, nShow - 3) + '...'
            })
            .attr('clip-path', (d, i) => 'url(#clip_' + i + ')')
            .attr('opacity', (d, i) => {
                let opacity = 1
                let rectWidth = leaf.select('#rect_' + i).node().getBBox().width
                let rectHeight = leaf.select('#rect_' + i).node().getBBox().height
                if (rectWidth < chartSize[size].maxRectWidth) opacity = 0
                if (rectHeight < chartSize[size].maxRectHeight * 2) opacity = 0
                return opacity
                // let opacity = 1
                // let textWdith = leaf.select('#label_' + i).node().getBBox().width
                // let textHeight = leaf.select('#label_' + i).node().getBBox().height
                // let rectWidth = leaf.select('#rect_' + i).node().getBBox().width
                // let rectHeight = leaf.select('#rect_' + i).node().getBBox().height
                // if (rectWidth < textWdith + chartSize[size].margin.left) opacity = 0
                // if (rectHeight < textHeight * 2) opacity = 0
                // return opacity
            })

        leaf.append("text")
            .join("tspan")
            .attr('id', (d, i) => 'percentage_' + i)
            .attr('class', "percentage-text")
            .attr("x", chartSize[size].margin.left)
            .attr("y", chartSize[size].margin.top + chartSize[size].fontSize + chartSize[size].margin.lineSpace)
            // .attr('fill', '#ffffff')
            // .style('text-shadow', '0.1em 0.1em 0.1em #000000')
            .attr("font-family", NUMFONT)
            .attr('font-size', chartSize[size].fontSize)
            .text(d => d.data.percentage)
            .attr('clip-path', (d, i) => 'url(#clip_' + i + ')')
            .attr('opacity', (d, i) => {
                let opacity = 1
                let rectWidth = leaf.select('#rect_' + i).node().getBBox().width
                let rectHeight = leaf.select('#rect_' + i).node().getBBox().height
                if (rectWidth < chartSize[size].maxRectWidth) opacity = 0
                if (rectHeight < chartSize[size].maxRectHeight * 2) opacity = 0
                if (d3.select('#label_' + i).text().length === 0) opacity = 0
                return opacity
                // let opacity = 1
                // let textWdith = leaf.select('#label_' + i).node().getBBox().width
                // let textHeight = leaf.select('#label_' + i).node().getBBox().height
                // let rectWidth = leaf.select('#rect_' + i).node().getBBox().width
                // let rectHeight = leaf.select('#rect_' + i).node().getBBox().height
                // if (rectWidth < textWdith + chartSize[size].margin.left) opacity = 0
                // if (rectHeight < textHeight * 2) opacity = 0
                // return opacity
            })

        leaf.selectAll('rect')
            // set rest rects' opacity
            .attr('opacity', d => {
                if (d.data.name === focus[0].value)
                    return 1;
                else return 0.3;
            })
            // highlight focus
            .attr('stroke-width', d => {
                if (d.data.name === focus[0].value)
                    return chartSize[size].strokeWidth;
                else return 0;
            })
            .attr('stroke', Color.HIGHLIGHT);

        if (this.style() === Style.COMICS) {
            let metaphorWidth = width * 0.26,
                metaphorHeight = 1.31 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor5);

            if (this.size() === Size.WIDE) {
                metaphorWidth = width * 0.21;
                metaphorHeight = 1.31 * metaphorWidth;
            } else if (this.size() === Size.MIDDLE) {
                metaphorWidth = width * 0.24;
                metaphorHeight = 1.31 * metaphorWidth;
            }

            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", width + metaphorWidth * 0.06)
                .attr("y", height - metaphorHeight * 0.96);
        }
        return svg;
    }

    animateCategorization() {
        let svg = this.displayCategorization();
        if (!svg) return;
        let duration = this.duration();
        let leafNodes = svg.selectAll('.leaf').nodes()
        let rectNum = leafNodes.length
        let i = 0
        svg.select('.leaf-layer').selectAll('rect').attr("fill-opacity", 0)
        svg.select('.leaf-layer').selectAll('text').attr("fill-opacity", 0)
        let interval = setInterval(function () {
            svg.selectAll('#rect_' + i)
                // .attr("fill-opacity", 0)
                // .transition()
                // .duration(duration)
                .attr("fill-opacity", 1);
            svg.selectAll('#label_' + i)
                // .attr("fill-opacity", 0)
                // .transition()
                // .duration(duration)
                .attr("fill-opacity", 1);
            i++;
            if (i === rectNum) {
                clearInterval(interval);
            }
        }, duration / rectNum);
        // breakdown === 2
        if (this.breakdown().length === 2) {
            let titleLayerNodes = svg.selectAll('.title-layer').selectAll('rect').nodes()
            let titleLayerNum = titleLayerNodes.length
            svg.select('.title-layer').selectAll('rect').attr("fill-opacity", 0)
            svg.select('.title-layer').selectAll('text').attr("fill-opacity", 0)
            let j = 0
            let interval2 = setInterval(function () {
                svg.selectAll('#titleRect_' + j)
                    .attr("fill-opacity", 0)
                    .transition()
                    .duration(duration)
                    .attr("fill-opacity", 1);
                svg.selectAll('#titleText_' + j)
                    .attr("fill-opacity", 0)
                    .transition()
                    .duration(duration)
                    .attr("fill-opacity", 1);
                j++;
                if (j === titleLayerNum) {
                    clearInterval(interval2);
                }
            }, duration / titleLayerNum);
        }
    }

    animateDistribution() {
        let svg = this.displayDistribution();
        if (!svg) return;
        let duration = this.duration();

        let leafNodes = svg.selectAll('.leaf').nodes()
        let leafNodesObj = leafNodes.map(d => {
            return {
                width: d.getAttribute("width"),
                height: d.getAttribute("height"),
                x: d.getAttribute("x"),
                y: d.getAttribute("y"),
            }
        })
        let bg = svg.append('rect')
            .attr('class', 'bgRect')
            .attr('width', 560)
            .attr('height', 560)
            .attr('fill', '#f9f9f9')
        svg.node().prepend(bg.node())
        svg.select('.bgRect')
            .attr('opacity', 0)
            .transition()
            .duration(duration / 3)
            .attr('opacity', 1)
        // let leafNodes = svg.selectAll('.leaf').nodes()
        // let rectNum = leafNodes.length
        // let i = 0
        // svg.select('.leaf-layer').selectAll('rect').attr("fill-opacity", 0)
        svg.select('.leaf-layer').selectAll('text').attr("fill-opacity", 0)
        svg.select('.leaf-layer').selectAll('.leaf').attr("width", 0).attr("height", 0)
        setTimeout(() => {
            svg.select('.leaf-layer').selectAll('.leaf')
                .transition()
                .duration(duration * 2 / 3)
                .attr("width", (d, i) => {
                    return leafNodesObj[i].width
                })
                .attr("height", (d, i) => {
                    return leafNodesObj[i].height
                })
            svg.selectAll('text')
                .transition()
                .duration(duration * 2 / 3)
                .attr("fill-opacity", 1);
        }, duration / 3);

        // let interval = setInterval(function () {
        //     svg.selectAll('#rect_' + i)
        //         .attr("fill-opacity", 0)
        //         .transition()
        //         .duration(duration)
        //         .attr("fill-opacity", 1);
        //     svg.selectAll('#label_' + i)
        //         .attr("fill-opacity", 0)
        //         .transition()
        //         .duration(duration)
        //         .attr("fill-opacity", 1);
        //     i++;
        //     if (i === rectNum) {
        //         clearInterval(interval);
        //     }
        // }, duration / rectNum);


        // breakdown === 2
        if (this.breakdown().length === 2) {
            // let titleLayerNodes = svg.selectAll('.title-layer').selectAll('rect').nodes()
            // let titleLayerNum = titleLayerNodes.length
            svg.select('.title-layer').selectAll('rect').attr("fill-opacity", 0)
            svg.select('.title-layer').selectAll('text').attr("fill-opacity", 0)
            // let j = 0
            svg.selectAll('rect')
                .transition()
                .duration(duration)
                .attr("fill-opacity", 1);
            svg.selectAll('text')
                .transition()
                .duration(duration)
                .attr("fill-opacity", 1);
            // let interval2 = setInterval(function () {
            //     svg.selectAll('#titleRect_' + j)
            //         .attr("fill-opacity", 0)
            //         .transition()
            //         .duration(duration)
            //         .attr("fill-opacity", 1);
            //     svg.selectAll('#titleText_' + j)
            //         .attr("fill-opacity", 0)
            //         .transition()
            //         .duration(duration)
            //         .attr("fill-opacity", 1);
            //     j++;
            //     if (j === titleLayerNum) {
            //         clearInterval(interval2);
            //     }
            // }, duration / titleLayerNum);
        }
    }

    animateProportion() {
        let svg = this.displayProportion();
        if (!svg) return;
        let duration = this.duration();
        let focus = this.focus();
        // let size = this.size();
        // svg.selectAll('rect').attr('opacity', 1).attr('stroke-width', 0)
        svg.selectAll('rect').attr('fill', Color.DEFAULT).attr('opacity', 0).attr('stroke-width', 0)
        svg.selectAll('.label-text').attr('opacity', 0)
        .attr('font-size', 32)
        .attr('font-weight', 800)
        .attr('y', 32)
        .attr('x', 12)
        svg.selectAll('.percentage-text').attr('opacity', 0)
            .attr('font-size', 32)
            .attr('font-weight', 800)
            .attr('y', 64)
            .attr('x', 12)

        // svg.selectAll('rect').attr('opacity', 0).attr('stroke-width', 0)
        // svg.selectAll('text').attr('opacity', 0)

        svg.selectAll('rect')
            .transition()
            .duration(duration / 3)
            .attr('opacity', 1)
        // svg.selectAll('.label-text')
        //     .transition()
        //     .duration(duration / 2)
        //     .attr('opacity', 1)

        setTimeout(() => {
            svg.selectAll('.label-text')
                .transition()
                .duration(duration / 3)
                .attr('opacity', d => {
                    if (d.data.name === focus[0].value)
                        return 1;
                    else return 0;
                })
        }, duration / 3);

        setTimeout(() => {
            svg.selectAll('rect')
                .transition()
                .duration(duration / 3)
                .attr('fill', d => {
                    if (d.data.name === focus[0].value)
                        return Color.HIGHLIGHT;
                    else return Color.DEFAULT;
                })
            svg.selectAll('.percentage-text')
                .transition()
                .duration(duration / 3)
                .attr('opacity', d => {
                    if (d.data.name === focus[0].value)
                        return 1;
                    else return 0;
                })
        }, duration / 3 * 2);


    }

}

export default TreeMap;