import React from 'react';
import Chart from './Chart';
import GallaryPage from './pages/gallary/GallaryPage';
import './vis/font.css';
import './App.css';
import './video-react.css';

import {
  HashRouter as Router,
  Switch,
  Route,
  //useParams
} from "react-router-dom";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    let status = 'animation';
    let spec = require('./spec/animation/scatterplot+association.json');
    this.state = {
      spec: spec,
      status: status,
      clipIndex: -1,
      view: "galleryView"
    };
  }

  changeAnimationSpec = (inputspec, clipIndex) => {
    let spec = require('./spec/animation/' + inputspec + '.json');
    this.setState({
      spec,
      clipIndex,
      view: "animationView"
    })
  }

  showGallary = (props) => {
    this.setState({
      view: 'galleryView',
      clipIndex: -1,
    })
    // props.history.push('/gallary')
  }

  render() {
    const { spec } = this.state;
    const cliplist = [
      { 'type': 'association', 'charts': [{ "id": 1, "chart": "scatterplot" }] },
      { 'type': 'categorization', 'charts': [{ "id": 2, "chart": "bubblechart" }, { "id": 3, "chart": "filledmap" }, { "id": 4, "chart": "treemap" }] },
      { 'type': 'difference', 'charts': [{ "id": 5, "chart": "filledmap" }, { "id": 6, "chart": "horizontalbarchart" }, { "id": 7, "chart": "textchart" }, { "id": 8, "chart": "verticalbarchart" }] },
      { 'type': 'distribution', 'charts': [{ "id": 9, "chart": "areachart" }, { "id": 10, "chart": "bubblechart" }, { "id": 11, "chart": "bubblemap" }, { "id": 12, "chart": "filledmap" }, { "id": 13, "chart": "horizontalbarchart" }, { "id": 14, "chart": "treemap" }, { "id": 15, "chart": "verticalbarchart" }] },
      { 'type': 'extreme', 'charts': [{ "id": 16, "chart": "areachart" }, { "id": 17, "chart": "bubblechart" }, { "id": 18, "chart": "linechart" }, { "id": 19, "chart": "textchart" }, { "id": 20, "chart": "verticalbarchart" }] },
      { 'type': 'outlier', 'charts': [{ "id": 21, "chart": "linechart" }, { "id": 22, "chart": "verticalbarchart" }] },
      { 'type': 'proportion', 'charts': [{ "id": 23, "chart": "donutchart" }, { "id": 24, "chart": "piechart" }, { "id": 25, "chart": "progressbarchart" }, { "id": 26, "chart": "treemap" }] },
      { 'type': 'rank', 'charts': [{ "id": 27, "chart": "horizontalbarchart" }] },
      { 'type': 'trend', 'charts': [{ "id": 28, "chart": "areachart" }, { "id": 29, "chart": "bubblechart" }, { "id": 30, "chart": "linechart" }, { "id": 31, "chart": "verticalbarchart" }] },
      { 'type': 'value', 'charts': [{ "id": 32, "chart": "bubblemap" }, { "id": 33, "chart": "filledmap" }, { "id": 34, "chart": "horizontalbarchart" }, { "id": 35, "chart": "textchart" }, { "id": 36, "chart": "verticalbarchart" }] },
    ]

    const nameDict = {
      'scatterplot': "Scatter Plot",
      "bubblechart": "Bubble",
      "filledmap": "Filled Map",
      "treemap": "Treemap",
      "horizontalbarchart": "Horizontal Bar",
      "verticalbarchart": "Vertical Bar",
      "areachart": "Area",
      "piechart": "Pie",
      "donutchart": "Donut",
      "bubblemap": "Bubble Map",
      "progressbarchart": "Progress Bar",
      "textchart": "Number",
      "linechart": 'Line'
    }

    let displayview;
    displayview = <Chart spec={spec} />;

    let clipListView = <div className='chartPannel'>
      {displayview}
    </div>

    let galleryView = <GallaryPage />

    return (
      <Router>
        <Switch>
          {/* <Route exact path="/gallary" render={
            props => {
              return <GallaryPage
                {...props}></GallaryPage>
            }
          }>
          </Route> */}
          <Route exact path="/*" render={
            props => {
              return <div className="clipPage" style={{ height: "100vh" }}>
                <div className='pannelWrapper'>
                  <div className='clipPanel'>
                    <div className="autoclip">
                      AutoClips
                    </div>
                    <div className='clipList '>
                      <div style={{ height: 1, width: 290, backgroundColor: '#ffffff40', marginLeft: 30 }}></div>
                      <div className="gallery">Video Gallery</div>
                      <div onClick={() => this.showGallary()} className={this.state.clipIndex === -1 ? "sample sample-selected" : "sample"}>Sample Videos</div>
                      <div className="library">Clips Library</div>
                      {
                        cliplist.map(clipType => {
                          return <div className="clipGroup" key={clipType.type}>
                            <div className="clipType">{clipType.type}</div>
                            {
                              clipType.charts.map(chart => {
                                return <div className={chart.id === this.state.clipIndex ? "clipId selected" : "clipId"} key={chart.id} onClick={() => this.changeAnimationSpec(chart.chart + "+" + clipType.type, chart.id)}>

                                  {/* <span className='selected'>{chart.id === this.state.clipIndex ? '>' : ''} </span> */}
                                  <span style={{ minWidth: "30px", display: 'inline-block' }}>{chart.id}</span>   { } {nameDict[chart.chart]}
                                </div>
                              })
                            }
                          </div>
                        })
                      }
                      <div style={{ height: "20px" }}></div>
                    </div>
                  </div>
                  {this.state.view === "galleryView" ? galleryView : clipListView}
                </div>
              </div>
              // </div >
            }
          }>
          </Route>
        </Switch >
      </Router >
    )
  }
}

