import * as d3 from 'd3';
import Chart from '../../chart';
import Color from '../../visualization/color';
import formatNumber from '../../visualization/format';
import Size from '../../visualization/size';
import Style from '../../visualization/style';
import updateChartCenter from '../../visualization/updateChartCenter';
import metaphor4 from '../../metaphor/metaphor4.png';//value
import metaphor20 from '../../metaphor/metaphor20.png';//difference
import metaphor9 from '../../metaphor/metaphor9.png';//distribution
import metaphor21 from '../../metaphor/metaphor21.png';//rank

const NUMFONT = "Arial-Regular";
const TEXTFONT = "Arial-Bold";

class HorizentalBarChart extends Chart {
    displayDistribution() {
        let chartSize = {
            width: this.width(),
            height: this.height()
        };
        let { tickFontSize, tickStrokeWidth, margin, offsetY } = getSizeBySize(chartSize, this.size()),
            width = chartSize.width,
            height = chartSize.height;
        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .attr("display", "block")
            .attr("class", "barG")
            .append("g");
        if (this.style() === Style.COMICS) width = 0.8 * width;
        if (this.measure().length > 1) {
            svg.append("rect")
                .attr("width", width)
                .attr("height", height)
                .attr("fill", "none");
            return svg;
        }

        let measure = this.measure();
        let breakdown = this.breakdown();
        let mesuredField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field;


        let maxBarNumbers = 20;
        if (this.size() === "middle") {
            maxBarNumbers = 15;
        } else if (this.size() === "small") {
            maxBarNumbers = 10;
        }
        let data = this.factdata();
        if (data.length > maxBarNumbers) {
            // unsupportedchart(svg, chartSize, annotationSize, this.size());
            // return svg;
            data = data.slice(0, maxBarNumbers);
        }
        /*****(0)****/
        //if same year
        let sameYear = "";
        if (breakdown[0].type === "temporal") {
            try {
                let year = getSameYear(data.map(d => d[breakdown[0].field]));
                sameYear = year + "/";
            } catch (e) {
                //keep origin
            }
        }

        let textHeight = 0;
        if (this.size() === Size.LARGE && sameYear !== "" && sameYear !== "false/") {
            let yearText = svg.append("text")
                .text("Year:" + sameYear.replace("/", ""))
                .attr("font-size", '21')
                .attr("font-family", NUMFONT)
                .attr("y", margin.top)
                .attr("x", width / 2)
                .attr("fill", Color.TEXT)
                .attr("text-anchor", "middle");
            textHeight = yearText.node().getBBox().height + 10;//margin
        }
        data = data.map(d => {
            d[breakdown[0].field] = d[breakdown[0].field].replace(sameYear, "")
            return d
        })
        /*****(0) the end****/

        /***(1) append yAxis**/
        let startY = margin.top + textHeight,
            endY = height - margin.bottom;
        let YDomain = data.map(function (d) { return d[breakdown[0].field]; });
        initYAixsStyle(startY, endY, YDomain, svg, tickStrokeWidth, tickFontSize, margin, offsetY);


        /***(2) append xAxis**/
        let measuredYFieldsWidth = svg.select(".yAxis").node().getBBox().width + margin.left;
        let starX = measuredYFieldsWidth,
            endX = width - margin.right,
            xAxisPos = height - margin.bottom;
        let maxX = d3.max(data, d => d[mesuredField]);
        initXAxisStyle(starX, endX, maxX, svg, xAxisPos, tickStrokeWidth, tickFontSize);
        //modify x style
        svg.select(".xAxis").remove();

        //(3)draw bar 
        let y = d3.scaleBand()
            .domain(YDomain)
            .range([startY, endY])
            .paddingInner(0.5);
        let x = d3.scaleLinear()
            .domain([0, maxX])
            .range([starX, endX])

        svg.selectAll(".bar")
            .data(data)
            .enter()
            .append("rect")
            .lower()
            .attr("class", "bar")
            .attr("fill", Color.DEFAULT)
            .attr("x", starX)
            .attr("y", d => y(d[breakdown[0].field]))
            .attr("rx", y.bandwidth() / 2)
            .attr("ry", y.bandwidth() / 2)
            .attr("width", d => x(d[mesuredField]) - starX)
            .attr("height", y.bandwidth());

        //(4)draw  bar value
        let barValueX = starX + offsetY;//margin left=offsetY
        data.map(d => {
            let barValue = d[mesuredField],
                barvalueY = y(d[breakdown[0].field]),
                offsetY = y.bandwidth() / 2;
            drawBarValue(svg, offsetY, tickFontSize, barValueX, barvalueY, barValue);
            return d;
        });

        if (this.style() === Style.COMICS) {
            let svgBBox = svg.node().getBBox();

            let metaphorWidth = width * 0.25,
                metaphorHeight = 1.12 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor9);

            if (this.size() === Size.WIDE) {
                metaphorWidth = width * 0.22;
                metaphorHeight = 1.12 * metaphorWidth;
            } else if (this.size() === Size.MIDDLE || this.size() === Size.SMALL) {
                metaphorWidth = width * 0.26;
                metaphorHeight = 1.12 * metaphorWidth;
            }
            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", svgBBox.width + svgBBox.x + metaphorWidth * 0.06)
                .attr("y", svgBBox.height + svgBBox.y - metaphorHeight * 1.1);
        }
        //finally update chart horizental cental
        updateChartCenter(svg, this.width(), this.height());
        return svg;
    }
    displayRank() {
        let chartSize = {
            width: this.width(),
            height: this.height()
        };
        let { tickFontSize, tickStrokeWidth, margin, offsetY } = getSizeBySize(chartSize, this.size()),
            width = chartSize.width,
            height = chartSize.height;
        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .attr("display", "block")
            .attr("class", "barG")
            .append("g");
        if (this.style() === Style.COMICS) width = 0.8 * width;
        if (this.measure().length > 1) {
            svg.append("rect")
                .attr("width", width)
                .attr("height", height)
                .attr("fill", "none");
            return svg;
        }

        let measure = this.measure();
        let breakdown = this.breakdown();
        let mesuredField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field;

        let data = this.factdata().sort((a, b) => b[mesuredField] - a[mesuredField]),
            maxBarNumbers = 5;
        if (this.size() === "large") {
            maxBarNumbers = 10;
        }
        if (data.length > maxBarNumbers) data = data.slice(0, maxBarNumbers);
        /*****(0)****/
        //if same year
        let sameYear = "";
        if (breakdown[0].type === "temporal") {
            try {
                let year = getSameYear(data.map(d => d[breakdown[0].field]));
                sameYear = year + "/";
            } catch (e) {
                //keep origin
            }
        }

        let textHeight = 0;
        if (this.size() === Size.LARGE && sameYear !== "" && sameYear !== "false/") {
            let yearText = svg.append("text")
                .text("Year:" + sameYear.replace("/", ""))
                .attr("font-size", '21')
                .attr("font-family", NUMFONT)
                .attr("y", margin.top)
                .attr("x", width / 2)
                .attr("fill", Color.TEXT)
                .attr("text-anchor", "middle");
            textHeight = yearText.node().getBBox().height + 10;//margin
        }
        data = data.map(d => {
            d[breakdown[0].field] = d[breakdown[0].field].replace(sameYear, "")
            return d
        })
        /*****(0) the end****/

        /***(1) append yAxis**/
        let startY = margin.top + textHeight,
            endY = height - margin.bottom;
        let YDomain = data.map(function (d) { return d[breakdown[0].field]; });
        initYAixsStyle(startY, endY, YDomain, svg, tickStrokeWidth, tickFontSize, margin, offsetY);


        /***(2) append xAxis**/
        let measuredYFieldsWidth = svg.select(".yAxis").node().getBBox().width + margin.left;
        let starX = measuredYFieldsWidth,
            endX = width - margin.right,
            xAxisPos = height - margin.bottom;
        let maxX = d3.max(data, d => d[mesuredField]);
        if(measure[0]['max'] && measure[0].max > maxX) {
            maxX = measure[0].max;
        }
        initXAxisStyle(starX, endX, maxX, svg, xAxisPos, tickStrokeWidth, tickFontSize);
        //modify x style
        svg.select(".xAxis").remove();

        //(3)draw bar 
        let y = d3.scaleBand()
            .domain(YDomain)
            .range([startY, endY])
            .paddingInner(0.5);
        let x = d3.scaleLinear()
            .domain([0, maxX])
            .range([starX, endX])

        svg.selectAll(".bar")
            .data(data)
            .enter()
            .append("rect")
            .lower()
            .attr("class", "bar")
            .attr("fill", (d, i) => {
                if (i < 3) {
                    return Color.HIGHLIGHT;
                }
                return Color.DEFAULT;
            })
            .attr("x", starX)
            .attr("y", d => y(d[breakdown[0].field]))
            .attr("rx", y.bandwidth() / 2)
            .attr("ry", y.bandwidth() / 2)
            .attr("width", d => x(d[mesuredField]) - starX)
            .attr("height", y.bandwidth());

        //(4)draw  bar value
        let barValueX = starX + offsetY;//margin left=offsetY
        data.map(d => {
            let barValue = d[mesuredField],
                barvalueY = y(d[breakdown[0].field]),
                offsetY = y.bandwidth() / 2;
            drawBarValue(svg, offsetY, tickFontSize, barValueX, barvalueY, barValue);
            return d;
        });

        if (this.style() === Style.COMICS) {
            let svgBBox = svg.node().getBBox();

            let metaphorWidth = width * 0.25,
                metaphorHeight = 1.33 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor21);

            if (this.size() === Size.WIDE) {
                metaphorWidth = width * 0.22;
                metaphorHeight = 1.33 * metaphorWidth;
            } else if (this.size() === Size.MIDDLE || this.size() === Size.SMALL) {
                metaphorWidth = width * 0.26;
                metaphorHeight = 1.33 * metaphorWidth;
            }
            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", svgBBox.width + svgBBox.x + metaphorWidth * 0.06)
                .attr("y", svgBBox.height + svgBBox.y - metaphorHeight * 1.1);
        }
        //finally update chart horizental cental
        updateChartCenter(svg, this.width(), this.height());
        return svg;
    }
    displayValue() {
        let factdata = this.factdata();
        let measure = this.measure();
        let subspace = this.subspace();

        let chartSize = {
            width: this.width(),
            height: this.height()
        };
        let { hightLightFontSize, margin } = getSizeBySize(chartSize, this.size()),
            width = chartSize.width - margin.left - margin.right,
            height = chartSize.height - margin.top - margin.bottom;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("class", "svg")
            .attr("width", chartSize.width)
            .attr("height", chartSize.height)
            .append("g")
            .attr("class", "barG")
            .attr("transform", `translate(0 ${margin.top})`);
        if (this.style() === Style.COMICS) width = 0.8 * width;
        if (this.measure().length > 1) {
            svg.append("rect")
                .attr("width", width)
                .attr("height", height).attr("fill", "none");
            return svg;
        }

        let barValue;
        if (subspace.length === 0) {
            barValue = d3.sum(factdata, d => d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]);
        } else {
            barValue = factdata[0][measure[0].aggregate === 'count' ? "COUNT" : measure[0].field];
        }

        let barHeight = margin.right * 1.2;
        let YField = subspace.length === 0 ? "" : subspace[0].value;

        //draw bar 
        svg.append("rect")
            .attr("fill", Color.DEFAULT)
            .attr("rx", barHeight / 2)
            .attr("ry", barHeight / 2)
            .attr("x", 0) //inital 
            .attr("y", height / 2) //inital 
            .attr("width", width)
            .attr("height", barHeight)


        //barValue
        svg.append("g").attr("class", "tooltip")
            .append("text")
            .attr("x", width / 2)
            .attr("y", height / 2 - 40 * chartSize.height / 320)
            .attr("text-anchor", "middle")
            .attr("alignment-baseline", "baseline")
            .attr('font-family', TEXTFONT)
            .attr("font-size", hightLightFontSize)
            .attr("font-weight", 600)
            .attr("fill", Color.HIGHLIGHT)
            .text(barValue)
            .property("_value", barValue)

        //YField 
        svg.append("text").attr("class", "legend")
            .attr("x", width / 2)
            .attr("y", height / 2 + barHeight + 40 * chartSize.height / 320)
            .text(YField)
            .attr('font-size', hightLightFontSize)
            .attr("font-weight", 600)
            .attr("alignment-baseline", "hanging")
            .attr('font-family', TEXTFONT)
            // .attr('fill', Color.HIGHLIGHT)
            .attr('text-anchor', 'middle');

        if (this.style() === Style.COMICS) {
            let svgBBox = svg.node().getBBox();

            let metaphorWidth = width * 0.3,
                metaphorHeight = 1.18 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor4);

            if (this.size() === Size.WIDE) {
                metaphorWidth = width * 0.24;
                metaphorHeight = 1.18 * metaphorWidth;
            } else if (this.size() === Size.MIDDLE || this.size() === Size.SMALL) {
                metaphorWidth = width * 0.28;
                metaphorHeight = 1.18 * metaphorWidth;
            }
            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", svgBBox.width + svgBBox.x + metaphorWidth * 0.06)
                .attr("y", svgBBox.height + svgBBox.y - metaphorHeight * 0.75);
        }

        //finally update chart horizental cental
        updateChartCenter(svg, this.width(), this.height());
        return svg;
    }
    displayDifference() {
        let chartSize = {
            width: this.width(),
            height: this.height()
        };
        let { tickFontSize, tickStrokeWidth, margin, hightLightFontSize, offsetY, arrowWidth } = getSizeBySize(chartSize, this.size(), "difference"),
            width = chartSize.width,
            height = chartSize.height;
        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .attr("display", "block")
            .attr("class", "barG")
            .append("g");

        if (this.measure().length > 1 || this.focus().length < 2) {
            svg.append("rect")
                .attr("width", width)
                .attr("height", height)
                .attr("fill", "none");
            return svg;
        }

        let focus = this.focus();
        let filteredData = [] //sorted by focus
        for (const fs of focus) {
            this.factdata().filter((x) => x[fs.field] === fs.value)[0] && filteredData.push(this.factdata().filter((x) => x[fs.field] === fs.value)[0])
        }

        let measure = this.measure();
        let breakdown = this.breakdown();
        let mesuredField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field;

        let data = filteredData;
        if (data.length !== 2) return
        if (this.style() === Style.COMICS) width = 0.85 * width;
        //(1)append differenceValue first to measure the height
        let differenceValue = Math.abs(Number(data[0][mesuredField]) - Number(data[1][mesuredField]));
        let meauredTextH = 0; //inital

        // svg.append("text")
        //     .attr("class", "differenceValue")
        //     .attr("x", this.width() / 2)
        //     .attr("y", margin.top)
        //     .attr("dy", "-1.25em")
        //     .attr('font-size', hightLightFontSize)
        //     .attr('font-family', TEXTFONT)
        //     .attr('fill', Color.HIGHLIGHT)
        //     .attr('text-anchor', 'middle')
        //     .attr('dominant-baseline', 'hanging')
        //     .text("Difference");

        svg.append("text")
            .attr("class", "differenceValue")
            .attr("x", this.width() / 2)
            .attr("y", margin.top)
            .attr('font-size', hightLightFontSize)
            .attr('font-family', TEXTFONT)
            .attr('fill', Color.HIGHLIGHT)
            .attr('text-anchor', 'middle')
            .attr('dominant-baseline', 'hanging')
            .text(differenceValue < 0 ? `-${formatNumber(-differenceValue)}` : formatNumber(differenceValue));



        meauredTextH = svg.select(".differenceValue").node().getBBox().height + margin.top;
        // meauredTextH = meauredTextH;

        /***(2) append yAxis**/
        let offsetMarginXAxis = this.size() === "small" ? margin.bottom / 4 : margin.bottom / 2;
        let startY = height - margin.bottom - offsetMarginXAxis,
            endY = meauredTextH + offsetMarginXAxis;
        let YDomain = data.map(function (d) { return d[breakdown[0].field]; });
        initYAixsStyle(startY, endY, YDomain, svg, tickStrokeWidth, tickFontSize, margin, offsetY);
        //modify y style
        svg.select(".yAxis .domain").remove();
        svg.selectAll(".yAxis .tick line").attr("visibility", "hidden");

        /***(3) append xAxis**/
        let measuredYFieldsWidth = svg.select(".yAxis").node().getBBox().width + margin.left;
        let starX = measuredYFieldsWidth,
            endX = width - margin.right,
            xAxisPos = height - margin.bottom;
        let maxX = d3.max(data, d => d[mesuredField]);
        initXAxisStyle(starX, endX, maxX, svg, xAxisPos, tickStrokeWidth, tickFontSize);

        //(4)draw bar 
        let y = d3.scaleBand()
            .domain(YDomain)
            .range([startY, endY])
            .paddingInner(0.5);
        let x = d3.scaleLinear()
            .domain([0, maxX])
            .range([starX, endX])

        svg.selectAll(".bar")
            .data(data)
            .enter()
            .append("rect")
            .attr("class", "bar")
            .attr("fill", Color.DEFAULT)
            .attr("x", starX)
            .attr("y", d => y(d[breakdown[0].field]))
            .attr("rx", y.bandwidth() / 2)
            .attr("ry", y.bandwidth() / 2)
            .attr("width", d => x(d[mesuredField]) - starX)
            .attr("height", y.bandwidth());

        //(5)draw  bar value
        let barValueX = starX + offsetY;//margin left=offsetY
        data.map(d => {
            let barValue = d[mesuredField],
                barvalueY = y(d[breakdown[0].field]),
                offsetY = y.bandwidth() / 2;
            drawBarValue(svg, offsetY, tickFontSize, barValueX, barvalueY, barValue);
            return d;
        });
        //(6)draw assistant line
        svg.append("g")
            .attr("class", "referenceLs")
            .selectAll("referenceL")
            .data(data)
            .join("line")
            .attr('class', 'referenceL')
            .attr('x1', d => x(d[mesuredField]))
            .attr("y1", d => y(d[breakdown[0].field]) + y.bandwidth() / 2)
            .attr('x2', d => x(d[mesuredField]))
            .attr('y2', xAxisPos)
            .attr('stroke', Color.DASHLINE)
            .attr('stroke-width', tickStrokeWidth)
            .attr('stroke-dasharray', '5,5');

        svg.append("line")
            .attr('class', 'hightlightL')
            .attr('x1', x(data[0][mesuredField]))
            .attr('y1', xAxisPos)
            .attr('x2', x(data[1][mesuredField]))
            .attr('y2', xAxisPos)
            .attr('stroke', Color.HIGHLIGHT)
            .attr('opacity', 1)
            .attr('stroke-width', arrowWidth);

        if (this.style() === Style.COMICS) {
            let barBBox0 = svg.selectAll('.bar').nodes()[0].getBBox(),
                barBBox1 = svg.selectAll('.bar').nodes()[1].getBBox();
            let metaphorHeight = this.size() === Size.LARGE ? barBBox0.height * 1.6 : barBBox0.height * 2,
                metaphorWidth = metaphorHeight / 1.2;
            let metaphor0 = svg.append("image")
                .attr('xlink:href', metaphor20);
            let metaphor1 = svg.append("image")
                .attr('xlink:href', metaphor20);

            metaphor0.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", barBBox0.width + barBBox0.x - metaphorWidth * 0.04)
                .attr("y", barBBox0.height / 2 + barBBox0.y - metaphorHeight * 0.4);
            metaphor1.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", barBBox1.width + barBBox1.x - metaphorWidth * 0.04)
                .attr("y", barBBox1.height / 2 + barBBox1.y - metaphorHeight * 0.4);
        }
        //finally update chart horizental cental
        updateChartCenter(svg, this.width(), this.height());
        return svg;
    }

    animateTrend() {
        const parseTime = (date) => {
            if (d3.timeParse("%Y-%m-%d")(date))
                return d3.timeParse("%Y-%m-%d")(date);
            else if (d3.timeParse("%Y/%m/%d")(date))
                return d3.timeParse("%Y/%m/%d")(date);
            else if (d3.timeParse("%Y-%m")(date))
                return d3.timeParse("%Y-%m")(date);
            else if (d3.timeParse("%Y/%m")(date))
                return d3.timeParse("%Y/%m")(date);
            else if (d3.timeParse("%Y")(date))
                return d3.timeParse("%Y")(date);
            else return date
        }

        let { tickFontSize } = getSizeBySize(this, "distribution")

        let factData = this.factdata()
        let measure = this.measure();
        let breakdown = this.breakdown();
        // let size = this.size();
        let chartWidth = this.width()
        let chartHeight = this.height()
        factData.forEach(d => {
            d.value = +d[measure[0].field];
            d.name = d[breakdown[0].field];
            d.date = parseTime(d[breakdown[1].field]);
            d.category = d[breakdown[0].field];
        });

        render(factData, this.container());

        function render(data, container) {
            var thisContainer = container;
            var margin = ({ top: 16, right: 160, bottom: 6, left: 4 });
            let categories = Array.from(new Set(data.map(d => d[breakdown[0].field])));
            var n = categories.length >= 12 ? 12 : categories.length;  // 展示bar的数量
            var duration = 250;
            var width = chartWidth;
            var height = chartHeight;//margin.top + barSize * n + margin.bottom;
            var barSize = (chartHeight - margin.top - margin.bottom) / n; // bar的宽度
            var k = 10;

            var names = new Set(data.map(d => d.name));

            let arr = d3.nest()
                .key(d => +d.date)
                .key(d => d.name)
                .rollup(([d]) => d.value)
                .entries(data);
            let mapdatevalues = arr
                .map((x) => {
                    let valuemap = new Map();
                    for (const v of x.values) {
                        valuemap.set(v.key, v.value);
                    }
                    return [new Date(parseInt(x.key)), valuemap]
                })
            let datevalues = mapdatevalues
                .sort(([a], [b]) => d3.ascending(a, b));

            function rank(value) {
                const data = Array.from(names, name => ({ name, value: value(name) || 0 }));
                data.sort((a, b) => d3.descending(a.value, b.value));
                for (let i = 0; i < data.length; ++i) data[i].rank = Math.min(n, i);
                return data;
            };

            // function keyframes() {
            const keyframes = [];
            let ka, a, kb, b;
            for ([[ka, a], [kb, b]] of d3.pairs(datevalues)) {

                for (let i = 0; i < k; ++i) {
                    const t = i / k;
                    /* for clear "the Function declared in a loop contains unsafe references to variable(s) 'a', 'b'" */
                    let a1 = a;
                    let b1 = b;
                    keyframes.push([
                        new Date(ka * (1 - t) + kb * t),
                        rank(name => a1.get(name) * (1 - t) + b1.get(name) * t)
                    ]);
                }
            };
            keyframes.push([new Date(kb), rank(name => b.get(name))]);
            //     return keyframes;
            // };

            let nameframes = d3.nest().key(d => d.name).entries(keyframes.flatMap(([, data]) => data));
            nameframes = nameframes.map(x => [x.key, x.values]);
            var prev = new Map(nameframes.flatMap(([, data]) => d3.pairs(data, (a, b) => [b, a])));
            var next = new Map(nameframes.flatMap(([, data]) => d3.pairs(data)));
            var y = d3.scaleBand()
                .domain(d3.range(n + 1))
                .rangeRound([margin.top, margin.top + barSize * (n + 1 + 0.1)])
                .padding(0.6);

            var x = d3.scaleLinear([0, 1], [margin.left, width - margin.right]);

            var formatDate = d3.utcFormat("%Y-%m-%d");
            var formatNumber = d3.format(",d");

            function color(d) {
                const scale = d3.scaleOrdinal(Color.CATEGORICAL);
                // d3.schemeTableau10
                if (data.some(d => d.category !== undefined)) {
                    const categoryByName = new Map(data.map(d => [d.name, d.category]))
                    scale.domain(Array.from(categoryByName.values()));
                    return d => scale(categoryByName.get(d.name));
                }
                return d => scale(d.name);
            };

            function ticker(svg) {
                const now = svg.append("text")
                    .attr("font", `bold ${barSize}px var(--sans-serif)`)
                    .attr("font-variant-numeric", "tabular-nums")
                    .attr("text-anchor", "end")
                    .attr("x", width - 6)
                    .attr("y", margin.top + barSize * (n - 0.45))
                    .attr("dy", "0.32em")
                    .attr("font-weight", 800)
                    .attr("fill", "#cccccc")
                    .attr("font-size", tickFontSize * 2 + "px")
                    .text(formatDate(keyframes[0][0]));

                return ([date], transition) => {
                    transition.end().then(() => now.text(formatDate(date)));
                };
            };

            function axis(svg) {
                const g = svg.append("g")
                    .attr("transform", `translate(0,${margin.top})`);

                const axisX = d3.axisTop(x)
                    .ticks(width / 160)
                    .tickSizeOuter(0)
                    .tickSizeInner(-barSize * (n + y.padding()));

                return (_, transition) => {
                    g.transition(transition).call(axisX);
                    g.select(".tick:first-of-type text").remove();
                    // g.selectAll(".tick:not(:first-of-type) line").attr("stroke", "white");
                    g.selectAll(".tick line").attr("stroke", "white");
                    g.selectAll(".tick:first-of-type line").attr("opacity", 0);
                    g.select(".domain").remove();
                };
            };

            function textTween(a, b) {
                const i = d3.interpolateNumber(a, b);
                return function (t) {
                    this.textContent = formatNumber(i(t));
                };
            };

            function bars(svg) {
                let bar = svg.append("g")
                    .attr("fill-opacity", 1)
                    .selectAll("rect")

                return ([date, data], transition) => bar = bar
                    .data(data.slice(0, n), d => d.name)
                    .join(
                        enter => enter.append("rect")
                            .attr("fill", color())
                            .attr("height", y.bandwidth())
                            // .attr("rx", y.bandwidth() / 2)
                            // .attr("ry", y.bandwidth() / 2)
                            .attr("x", x(0))
                            .attr("y", d => y((prev.get(d) || d).rank))
                            .attr("width", d => x((prev.get(d) || d).value) - x(0))

                        ,
                        update => update,
                        exit => exit.transition(transition).remove()
                            .attr("y", d => y((next.get(d) || d).rank))
                            .attr("width", d => x((next.get(d) || d).value) - x(0))
                    )
                    .call(bar => bar.transition(transition)
                        .attr("y", d => y(d.rank))
                        .attr("width", d => x(d.value) - x(0)));
            };

            function labels(svg) {
                let label = svg.append("g")
                    .attr("font", "bold Arial-Regular")
                    .attr("font-size", tickFontSize + 'px')
                    .attr("font-variant-numeric", "tabular-nums")
                    .attr("text-anchor", "start")
                    .selectAll("text");

                return ([date, data], transition) => label = label
                    .data(data.slice(0, n), d => d.name)
                    .join(
                        enter => enter.append("text")
                            .text(d => d.name + ": ")
                            .attr("transform", d => `translate(${x((prev.get(d) || d).value)},${y((prev.get(d) || d).rank)})`)
                            .attr("y", y.bandwidth() / 2)
                            .attr("x", 10)
                            // return 20 + (1+d.name.length+parseInt(d.value).toString().length) * 10})
                            .attr("dy", "0.3em")
                            // .text(d => d.name + ":")
                            .attr("font-family", NUMFONT)
                            .call(text => text.append("tspan")
                                .attr("fill-opacity", 0.7)
                                .attr("font-weight", "normal")
                                // .attr("x", -6)
                                // .attr("dy", "1.15em")
                            ),
                        update => update,
                        exit => exit.transition(transition).remove()
                            .attr("transform", d => `translate(${x((next.get(d) || d).value)},${y((next.get(d) || d).rank)})`)
                            .call(g => g.select("tspan").tween("text", d => textTween(d.value, (next.get(d) || d).value)))
                    )
                    .call(bar => bar.transition(transition)
                        .attr("transform", d => `translate(${x(d.value)},${y(d.rank)})`)
                        .call(g => g.select("tspan").tween("text", d => textTween((prev.get(d) || d).value, d.value))));
            };

            async function* chart() {
                // replay;
                d3.select(thisContainer).selectAll("svg").remove()
                d3.select(thisContainer).selectAll("p").remove()
                const svg = d3.select(thisContainer).append("svg")
                    .attr("viewBox", [0, 0, width, height]);

                const updateBars = bars(svg);
                const updateAxis = axis(svg);
                const updateLabels = labels(svg);
                const updateTicker = ticker(svg);
                yield svg.node();

                for (const keyframe of keyframes) {
                    const transition = svg.transition()
                        .duration(duration)
                        .ease(d3.easeLinear);

                    // Extract the top bar’s value.
                    x.domain([0, keyframe[1][0].value]);

                    updateAxis(keyframe, transition);
                    updateBars(keyframe, transition);
                    updateLabels(keyframe, transition);
                    updateTicker(keyframe, transition);

                    // invalidation.then(() => svg.interrupt());
                    await transition.end();
                };
            };

            const c = chart();
            (async () => {
                for await (const val of c) {
                    console.log(val);
                }
            })();
        };
    }

    animateDistribution() {
        let svg = this.displayDistribution();
        if (!svg) return;
        let duration = this.duration();

        let rectsLength = svg.selectAll('.bar').nodes().map((d) => {
            return d.getAttribute('width')
        })
        svg.selectAll('.bar')
            .attr("width", 0)
            .attr("rx", 0)
            .attr("ry", 0);
        
        svg.selectAll(".yAxis")
            .attr("opacity", 0)
            .transition()
            .duration(duration / 4)
            .attr("opacity", 1)

        svg.selectAll('.bar')
            .attr("width", 0)
            .transition()
            .duration(duration / 4 * 3)
            .delay(duration / 4)
            .attr("width", (d, i) => {
                return rectsLength[i]
            })

        svg.selectAll('.barValue').attr("opacity", 0);
        setTimeout(()=>{
            svg.selectAll('.barValue').attr("opacity", 1);
        }, duration / 4)
        svg.selectAll('.barValue')
            .transition()
            .duration(duration / 4 * 3)
            .delay(duration / 4)
            .ease(d3.easeLinear)
            .textTween(function (d) {
                let final = d3.select(this).node().innerHTML.replace(/,/g, '');;
                const i = d3.interpolate(0, final);
                let format = d3.format(",d")
                return function (t) {
                    var num = parseInt(i(t));
                    return format(num);
                };
            });
    }

    animateDifference() {
        let svg = this.displayDifference();
        if (!svg) return;
        let originDuration = this.duration();

        let rectsLength = svg.selectAll('.bar').nodes().map((d) => {
            return d.getAttribute('width')
        })

        svg.selectAll(".xAxis")
            .attr("opacity", 0)
            .transition()
            .duration(originDuration / 5)
            .attr("opacity", 1);
        
        svg.selectAll(".yAxis")
            .attr("opacity", 0)
            .transition()
            .duration(originDuration / 5)
            .attr("opacity", 1)

        let duration = originDuration / 5 * 4;
        svg.selectAll('.bar')
            .attr("width", 0)
            .attr("rx", 0)
            .attr("ry", 0)

        svg.selectAll('.differenceValue')
            .attr("fill-opacity", 0)

        svg.selectAll('.bar')
            .attr("width", 0)
            .transition()
            .duration(duration / 2)
            .delay(originDuration / 5)
            .attr("width", (d, i) => {
                return rectsLength[i]
            })

        svg.selectAll('.barValue').attr("opacity", 0)
        setTimeout(()=>{
            svg.selectAll('.barValue').attr("opacity", 1)
        }, originDuration / 5)

        svg.selectAll('.barValue')
            .transition()
            .duration(duration / 2)
            .delay(originDuration / 5)
            .ease(d3.easeLinear)
            .textTween(function (d) {
                let final = d3.select(this).node().innerHTML.replace(/,/g, '');
                const i = d3.interpolate(0, final);
                let format = d3.format(",d")
                return function (t) {
                    var num = parseInt(i(t));
                    return format(num);
                };
            });


        let referenceLs = svg.selectAll('.referenceL');
        let firstReferenceL = referenceLs.filter(function (d, i) { return i === 0; })
        let secondReferenceL = referenceLs.filter(function (d, i) { return i === 1; })
        let firstL = {
            y1: firstReferenceL.attr("y1"),
            y2: firstReferenceL.attr("y2")
        }
        let secondL = {
            y1: secondReferenceL.attr("y1"),
            y2: secondReferenceL.attr("y2")
        }


        firstReferenceL.attr("opacity", 0)
        secondReferenceL.attr("opacity", 0)
        setTimeout(() => {
            firstReferenceL.attr("opacity", 1)
            secondReferenceL.attr("opacity", 1)
            firstReferenceL
                .attr("y1", firstL.y1)
                .attr("y2", firstL.y1)
                .transition()
                .duration(duration / 3)
                .attr("y1", firstL.y1)
                .attr("y2", firstL.y2)
            secondReferenceL
                .attr("y1", secondL.y1)
                .attr("y2", secondL.y1)
                .transition()
                .duration(duration / 3)
                .attr("y1", secondL.y1)
                .attr("y2", secondL.y2)
        }, duration / 3 + originDuration / 5);

        svg.selectAll('.hightlightL')
            .attr("opacity", 0)
        svg.selectAll('.tooltip')
            .attr("opacity", 0)
        setTimeout(() => {
            svg.selectAll('.hightlightL')
                .attr("opacity", 0)
                .transition()
                .duration(duration / 3)
                .attr("opacity", 1)
            svg.selectAll('.tooltip')
                .attr("opacity", 0)
                .transition()
                .duration(duration / 3)
                .attr("opacity", 1)
        }, duration / 3 * 2+ originDuration / 5);

        setTimeout(() => {
            svg.selectAll(".differenceValue")
                .transition()
                .duration(duration / 3)
                .attr("fill-opacity", 1)
        }, duration / 3 * 2+ originDuration / 5);
    }

    animateRank() {
        let svg = this.displayRank();
        if (!svg) return;
        let duration = this.duration();

        let rectsLength = svg.selectAll('.bar').nodes().map((d) => {
            return d.getAttribute('width')
        })

        svg.selectAll(".yAxis")
            .attr("opacity", 0)
            .transition()
            .duration(duration / 5)
            .attr("opacity", 1)

        svg.selectAll('.bar')
            .attr("width", 0)
            .attr("rx", 0)
            .attr("ry", 0)

        svg.selectAll('.bar')
            .attr("fill", Color.DEFAULT)

        // svg.selectAll('.barValue')
        //     .attr("fill-opacity", 0)

        svg.selectAll('.bar')
            .attr("width", 0)
            .transition()
            .duration(duration / 5 * 2)
            .delay(duration / 5)
            .attr("width", (d, i) => {
                return rectsLength[i]
            })

        svg.selectAll('.barValue').attr("opacity", 0);
        setTimeout(()=>{
            svg.selectAll('.barValue').attr("opacity", 1);
        }, duration / 5);

        svg.selectAll('.barValue')
            .transition()
            .duration(duration / 5 * 2)
            .delay(duration / 5)
            .ease(d3.easeLinear)
            .textTween(function (d) {
                let final = d3.select(this).node().innerHTML.replace(/,/g, '');
                const i = d3.interpolate(0, final);
                let format = d3.format(",d")
                return function (t) {
                    var num = parseInt(i(t));
                    return format(num);
                };
            });

        setTimeout(() => {
            let rank1 = svg.selectAll('.bar').filter((d, i) => i === svg.selectAll('.bar').size()-1)
            let rank2 = svg.selectAll('.bar').filter((d, i) => i === svg.selectAll('.bar').size()-2)
            let rank3 = svg.selectAll('.bar').filter((d, i) => i === svg.selectAll('.bar').size()-3)
            rank1.transition()
                .duration(duration / 5 * 2 / 3)
                .ease(d3.easeLinear)
                .attr("fill", Color.HIGHLIGHT)

            rank2.transition()
                .duration(duration / 5 * 2 / 3)
                .delay(duration / 5 * 2 / 3)
                .ease(d3.easeLinear)
                .attr("fill", Color.HIGHLIGHT)

            rank3.transition()
                .duration(duration / 5 * 2 / 3)
                .delay(duration / 5 * 2 / 3 * 2)
                .ease(d3.easeLinear)
                .attr("fill", Color.HIGHLIGHT)

        }, duration / 5 * 3);

        // setTimeout(() => {
        // svg.selectAll('.barValue')
        //     .attr("fill-opacity", 0)
        //     .attr("width", 0)
        //     .transition()
        //     .duration(duration / 3)
        //     .attr("fill-opacity", 1)

        // }, duration / 3 * 2);
    }

    animateValue() {
        let svg = this.displayValue();
        if (!svg) return;
        let duration = this.duration();


        /* -------------------------------- init data ------------------------------- */
        let ticks = 10;

        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            labelFadeIn: {
                duration: 4,
                index: 0
            },
            majorGrow: {
                duration: 6,
                index: 1
            }
        }
        let everyTick = duration / ticks;



        /* ----------------------------- step 0 labelFadeIn ----------------------------- */
        let legend = svg.selectAll(".legend");
        legend.attr("opacity", 0);

        legend.transition()
            .duration(everyTick * (animation.labelFadeIn.duration))
            .attr("opacity", 1)


        /* ----------------------------- step 1 majorGrow ----------------------------- */


        let tooltip = svg.selectAll(".tooltip").select("text");
        tooltip.text("0%").attr("opacity", 0);

        let originWidth = svg.selectAll('rect').attr("width")
        svg.selectAll('rect')
            .attr("width", 0)
            .attr("rx", 0)
            .attr("ry", 0)

        setTimeout(() => {
            tooltip.attr("opacity", 1)
            tooltip.transition()
                .duration(everyTick * (animation.majorGrow.duration))
                .textTween(function (d) {
                    let final = d3.select(this).property("_value");
                    const i = d3.interpolate(0, final);
                    var numberFormat = d3.format(".0f");
                    return function (t) {
                        var percent = formatNumber(+numberFormat(i(t)));
                        return percent;
                    };
                });

            svg.selectAll('rect').transition()
                .duration(everyTick * (animation.majorGrow.duration))
                .attr("width", originWidth)

        }, everyTick * countTicksBeforeIndex(animation, animation.majorGrow.index))
    }
}
/***
 * 设置 yAxis样式并且更新位置
 */
const initYAixsStyle = (starY, endY, YDomain, svg, tickStrokeWidth, tickFontSize, margin, offsetY) => {
    //console.log("initYAxisStyle", starY, starY)
    let y = d3.scaleBand()
        .domain(YDomain)
        .range([starY, endY])
        .paddingInner(0.5)

    // add the y Axis
    svg.append("g")
        .attr("class", 'yAxis')
        .call(d3.axisLeft(y)
            .tickSize(tickStrokeWidth * 2)
            .tickPadding(offsetY))
        .call(g => {
            //y Axis styles
            let YAxislineData = [[0, starY], [0, endY]];
            let newD = d3.line()(YAxislineData) //生成d
            g.select('.domain')
                .attr('d', newD)
                .attr('stroke', 'black')
                .attr("stroke-width", tickStrokeWidth);

            // tick style
            g.selectAll('.tick line')
                .attr('stroke', 'black')
                .attr("stroke-width", tickStrokeWidth);

            g.selectAll('.tick text')
                .attr('font-size', tickFontSize)
                .attr('font-family', NUMFONT)
                .attr('fill', 'black')
                .attr('text-anchor', 'end');
        })

    let measuredYFieldsWidth = svg.select(".yAxis").node().getBBox().width + margin.left;
    //平移y
    svg.select(".yAxis").attr("transform", `translate(${measuredYFieldsWidth},0)`);
}
/***
 * 设置 xAxis样式
 */
const initXAxisStyle = (starX, endX, maxX, svg, xAxisPos, tickStrokeWidth, tickFontSize) => {
    //console.log("initXAxisStyle", starX, endX)
    let x = d3.scaleLinear()
        .domain([0, maxX])
        .range([starX, endX])

    // add the x Axis
    svg.append("g")
        .attr("class", 'xAxis')
        .attr("transform", `translate(0,${xAxisPos})`) //动态计算高度后平移会向上平移
        .call(d3.axisTop(x)
            .ticks(4)
            .tickSize(tickStrokeWidth * 2)
            .tickPadding(tickStrokeWidth * 2)
            .tickFormat(function (d) {
                if ((d / 1000000) >= 1) {
                    d = d / 1000000 + "M";
                } else if ((d / 1000) >= 1) {
                    d = d / 1000 + "K";
                }
                return d;
            })
        )
        .call(g => {
            //x Axis style
            let xAxislineData = [[starX, 0], [endX, 0], [endX, - tickStrokeWidth * 2]]
            let newD = d3.line()(xAxislineData) //生成d
            g.select('.domain')
                .attr('d', newD)
                .attr('stroke', 'black')
                .attr("stroke-width", tickStrokeWidth);

            //tick style
            g.selectAll('.tick line')
                .attr('stroke', 'black')
                .attr("stroke-width", tickStrokeWidth);

            g.selectAll('.tick text')
                .attr('font-size', tickFontSize)
                .attr('font-family', NUMFONT)
                .attr('fill', 'black')
                .attr("dy", "2em")
                .attr('text-anchor', 'middle');
        })
}
/****bar上的值*/
const drawBarValue = (svg, offsetY, tickFontSize, barValueX, barvalueY, barValue) => {
    svg.append("g")
        .append("text")
        .attr("class", "barValue")
        .attr("font-family", NUMFONT)
        .attr("font-size", tickFontSize)
        // .attr("text-anchor", 'start')
        .attr("dominant-baseline", "middle")
        .attr("y", barvalueY + offsetY)
        .attr("x", barValueX)
        .text(d => formatNumber(barValue))
}
const countTicksBeforeIndex = (animation, index) => {
    let count = 0;
    let visited = []
    for (let key in animation) {
        if (animation[key].index < index && visited.indexOf(animation[key].index) === -1) {
            count += animation[key].duration
            visited.push(animation[key].index)
        };
    }
    return count - 0.5
}
/*
*  y最大值向上取整 如y轴的最大数值为6，853，129； 要取到7，000，000
*/
// const getMaxYValue = (factdata, measure) => {
//     let mesuredField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field;
//     let maxYValue = d3.max(factdata, d => {
//         return d[mesuredField];
//     })
//     if ((maxYValue / 1000000) >= 1) {
//         maxYValue = Math.ceil(maxYValue / 1000000) * 1000000;
//     } else if ((maxYValue / 1000) >= 1) {
//         maxYValue = Math.ceil(maxYValue / 1000) * 1000;
//     }
//     return maxYValue;
// }

// const getYAxis = (svg, y) => {
//     let yAxis = svg.append("g").attr('class', 'yAxis')
//         .call(d3.axisRight(y));
//     return yAxis
// }
/** 
 * tickFontSize 坐标轴刻度字号
 * tickStrokeWidth 坐标轴刻度宽度
**/
const getSizeBySize = (chartSize, size, factType) => {
    let tickFontSize, tickStrokeWidth, hightLightFontSize, arrowWidth, offsetY, bottom, annotationSize;
    switch (size) {
        case Size.WIDE:
            tickFontSize = 16;
            tickStrokeWidth = 2;
            hightLightFontSize = 26;
            arrowWidth = 4;
            offsetY = 25;
            annotationSize = 15;
            break;
        case Size.MIDDLE:
            tickFontSize = 14;
            tickStrokeWidth = 2;
            hightLightFontSize = 20;
            arrowWidth = 3;
            offsetY = 20;
            annotationSize = 15;
            break;
        case Size.SMALL:
            tickFontSize = 12;
            tickStrokeWidth = 1.5;
            hightLightFontSize = 16;
            arrowWidth = 2;
            offsetY = 10;
            annotationSize = 10;
            break;
        case Size.LARGE:
        default:
            tickFontSize = 20;
            tickStrokeWidth = 3;
            hightLightFontSize = 40;
            arrowWidth = 5;
            offsetY = 35;
            annotationSize = 20;
            break;
    }
    if (factType === "difference") {
        bottom = 150 * chartSize.height / 640;
    } else {
        bottom = 40 * chartSize.height / 640;
    }

    return {
        tickFontSize: tickFontSize,
        tickStrokeWidth: tickStrokeWidth,
        hightLightFontSize: hightLightFontSize,
        arrowWidth: arrowWidth,
        margin: {
            top: 40 * chartSize.width / 640,
            left: 50 * chartSize.width / 640,
            right: 50 * chartSize.width / 640,
            bottom: bottom,//默认x轴的高度 
        },
        offsetY: offsetY,
        annotationSize: annotationSize//unsupportedchart中变量与此有关
    }
};
const getSameYear = (array) => {
    let temp = getYear(array[0]);
    for (let date of array) {
        if (temp !== getYear(date)) return false;
    }
    return temp;
}

const getYear = (str) => {
    let year = str.split('/')[0];
    if (!isNaN(year) && year.length === 4) {//is number
        return year;
    } else return false;
}

export default HorizentalBarChart;