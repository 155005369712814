import * as d3 from 'd3';
import Chart from '../../chart';
import Color from '../../visualization/color';
import generateToolTip from '../../visualization/tooltip';
import formatNumber from '../../visualization/format';
import Size from '../../visualization/size';
import Style from '../../visualization/style';
import PictorialType from '../../visualization/pictogram';

const NUMFONT = "Arial-Regular";
const TEXTFONT = "Arial-Bold";

class IsotypeProportion extends Chart {
    displayDifference() {
        if(this.style()===Style.PICTOGRAPH){
            let { chartSize, annotationSize, margin } = getSizeBySize(this, 'difference'),
            width = chartSize.width - margin.left - margin.right,
            height = chartSize.height - margin.top - margin.bottom;
        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", chartSize.width)
            .attr("height", chartSize.height)
            .append("g")
            .attr("id","isotypeproportionDifference")
            .attr("class", "elementGroup")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        if (this.measure().length > 1 || this.focus().length < 2) {
            return svg;
        }

        let focus = this.focus();
        let filteredData = [] //sorted by focus
        for (const fs of focus) {
            this.factdata().filter((x) => x[fs.field] === fs.value)[0] && filteredData.push(this.factdata().filter((x) => x[fs.field] === fs.value)[0])
        }

        let measure = this.measure();
        let breakdown = this.breakdown();
        let pictype=measure[0].pictype;
        let maxYValue = getMaxYValue(filteredData, measure)
        let mesuredField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field;
        filteredData.map(data => {
            data.maxValue = (maxYValue - data[measure[0].aggregate === "count" ? "COUNT" : mesuredField]);
            return data;
        })
      

        let data = filteredData;
        if (data.length === 0) return

        /*------------------通过名称找寻icon----------------------------*/
        svg.append("defs")
            .append("g")
            .attr("id",`pictype${pictype}`)
            .append("path")
            .attr("d",PictorialType[pictype])

        let typesizex1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
        let typesizey1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;

        let y = d3.scaleBand()
            .domain(data.map(function (d) { return d[breakdown[0].field]; }))
            .range([height - 40 * chartSize.height / 320, 0])
            .padding(0.6);

    /*-------------根据x的数据来进行icon的缩放--------------------------------*/
      let scalex;
      let scaley;
        svg.select(`#pictype${pictype}`)
        .attr("transform", function(){                
            scalex=y.bandwidth()/typesizey1;
            scaley=scalex;
            return  `scale(${scalex},${scaley})`                       
        });
     /*----------------计算缩放后的icon长宽------------------------*/
      let typesizex=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
      let typesizey=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;
      let typex= svg.select(`#pictype${pictype}`).node().getBBox().x;
      let typey=svg.select(`#pictype${pictype}`).node().getBBox().y;

     //   let totalValue = d3.sum(data, d => d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field])
        let pictorialdata=data.map(d=>(d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]));
        // let pictorialdatapercent =[]; 
        // for(let i=0;i<pictorialdata.length;i++){   
        // pictorialdatapercent[i]=parseFloat(pictorialdata[i]/totalValue*100).toFixed(0);       
        // }

        //specify the number of columns and rows for pictogram layout
        let numCols = 5;
        let numRows = 1;
    
        //padding for the grid
        let xPadding =10;
        //let yPadding = 15;
    
        //horizontal and vertical spacing between the icons
        //let hBuffer = 30;
        let wBuffer = (typesizex1+2)*scalex;
    
        //generate a d3 range for the total number of required elements
        let myIndex = d3.range(numCols * numRows);

       //define the gradient 
        let gradient=[];
        for(let i=0;i<2;i++){
          gradient[i] = svg.append("svg:defs")
          .append("svg:linearGradient")
          .attr("id", function(){
              return "icontype"+i;
          })
          .attr("spreadMethod", "pad");
  
         // Define the gradient colors
         gradient[i].append("svg:stop")
          .attr("offset", "0%")
          .attr("stop-color", function(){
              return Color.DEFAULT;
          })
          .attr("stop-opacity", 1);
        }
        let total = numCols * numRows;
        let valuePict =[];
        let valueDecimal = [];
        for(let i=0;i<2;i++){   
           // valuePict[i]=total*(datapercent[i]/100);
           valuePict[i]=(pictorialdata[i]*total/maxYValue).toFixed(1);          
           valueDecimal[i]=(valuePict[i]%1);  
        }

        let ybianhua= typey*scaley
        for(let i=0;i<2;i++){
            //let typeindex=i*60;
            svg.append("g")
            .attr("id", function(){
                return "icon"+i;
            })
            .selectAll(".icontype"+i)
            .data(myIndex)
            .enter()
            .append("use")
            .attr("xlink:href", `#pictype${pictype}`)
            .attr("id", function(d) {
                return "icon" + d;
            })
            .attr("x", function(d) {
                let remainder = d % numCols; //calculates the x position (column number) using modulus
                return xPadding + (remainder * wBuffer); //apply the buffer and return value
            })
            .attr("y", function() {
                return y(data[i][breakdown[0].field])+y.bandwidth()/2-typesizey/2-Math.abs(ybianhua); //apply the buffer and return the value
            })
            .attr("class",function(){
                return "icontype"+i;
            });
        }
            //fill color
            for(let i=0;i<2;i++){
                d3.selectAll(".icontype"+i)
                .attr("fill", function(d) {                   
                if (d <= valuePict[i] - 1) {       
                    return Color.DEFAULT;       
                } else if (d > (valuePict[i] - 1) && d < (valuePict[i])) {
                    gradient[i].append("svg:stop")
                        .attr("offset", (valueDecimal[i] * 100) + '%')
                        .attr("stop-color", Color.DEFAULT)
                        .attr("stop-opacity", 1);
                    gradient[i].append("svg:stop")
                        .attr("offset", (valueDecimal[i] * 100) + '%')
                        .attr("stop-color", Color.BACKGROUND)
                        .attr("stop-opacity", 1);
                    gradient[i].append("svg:stop")
                        .attr("offset", '100%')
                        .attr("stop-color", Color.BACKGROUND)
                        .attr("stop-opacity", 1);
                    return "url(#icontype"+i+")";
                } else {
                    return Color.BACKGROUND;
                }
            });
            }

         // add the y Axis
        let measuredWidth = 0;
         svg.append("g")
             .attr('class', 'yAxis')
             .call(d3.axisRight(y))
             .call(g => {
                 g.select('.domain').remove();
                 g.selectAll('.tick line').remove();
                 g.selectAll(".tick text")
                     .attr("text-anchor", "end")
                     .attr("x", 0)
                     .attr("dx",-xPadding-20)
                    // .attr("dx", - 9 * chartSize.height / 320)
                     .attr("stroke-width", this.size() === "small" ? 1 : 2)
                    // .attr("dy", "0.5em")
                     .attr('font-size', annotationSize)
                     .attr('font-family', NUMFONT)
                     .each(function (d, i) {
                         let selfWidth = d3.select(this).node().getBBox().width;
                         if (Number(selfWidth) > Number(measuredWidth)) {
                             measuredWidth = Number(selfWidth);
                         }
                     });
             });
         //update y 
         d3.selectAll(".yAxis .tick text").each(function () {
             d3.select(this).node().setAttribute("x", measuredWidth);
         });  
 
        //  //bar value
         svg.append("g")
             .selectAll("text")
             .data(data)
             .enter()
             .append("text")
             .attr("font-family", NUMFONT)
             .attr("font-size", annotationSize)
             .attr("text-anchor", 'start')
             .attr("dominant-baseline", "middle")
             .attr("fill", "black")
             .attr("x", function (d) { return wBuffer*6+xPadding; })
             .attr("y", function (d) { return y(d[breakdown[0].field]) })
             .attr("dx", '-1em')
             .attr("dy", y.bandwidth() / 2)
             .text(d => formatNumber(d[measure[0].aggregate === "count" ? "COUNT" : mesuredField]));
 
         //draw reference line
         if (this.size() !== Size.SMALL) {
         svg.selectAll("referenceL")
             .data(data)
             .join("line")
             .attr('class', 'referenceL')
             .attr('x1', (d,i)=> (xPadding+ Math.floor(valuePict[i]) * wBuffer+Math.abs(typex*scalex) + valueDecimal[i]*typesizex))            
             .attr('y1', height - 80 * chartSize.height / 320)
             .attr('x2',(d,i)=>(xPadding+ Math.floor(valuePict[i]) * wBuffer +Math.abs(typex*scalex) + valueDecimal[i]*typesizex))
             .attr('y2', d => y(d[breakdown[0].field]) + y.bandwidth() / 2)
             .attr('stroke', Color.DASHLINE)
             .attr('stroke-width', 3)
             .attr('stroke-dasharray', '5,5');
 
         svg.append("line")
             .attr('class', 'hightlightL')
             .attr('x1', (xPadding+ Math.floor(valuePict[0]) * wBuffer +Math.abs(typex*scalex) + valueDecimal[0]*typesizex))
             .attr('y1', height - 80 * chartSize.height / 320)
             .attr('x2', (xPadding+ Math.floor(valuePict[1]) * wBuffer +Math.abs(typex*scalex) + valueDecimal[1]*typesizex))
             .attr('y2', height - 80 * chartSize.height / 320)
             .attr('stroke', Color.AXIS)
             .attr('opacity', 1)
             .attr('stroke-width', 3);
 
         //tool tip
         let startPos = ((xPadding+ Math.floor(valuePict[0]) * wBuffer +Math.abs(typex*scalex) + valueDecimal[0]*typesizex)+(xPadding+ Math.floor(valuePict[1]) * wBuffer +Math.abs(typex*scalex) + valueDecimal[1]*typesizex))/2;
         let toolTipX=startPos;//箭头中心所在x的位置
         let  toolTipY = height - 70 * chartSize.height / 320;
         let toolTipValue = formatNumber(Math.abs(data[0][measure[0].aggregate === "count" ? "COUNT" : mesuredField] - data[1][measure[0].aggregate === "count" ? "COUNT" : mesuredField]));
         generateToolTip(toolTipX, toolTipY, toolTipValue, svg, chartSize, annotationSize, "Down");
          }


        //使图表居中
        let cardWidth=chartSize.width;
        let cardHeight=chartSize.height;
        let a=svg.node().getBBox().width;    
        let b=svg.node().getBBox().height;      
        let c=svg.node().getBBox().x;      
        let e=svg.node().getBBox().y;      
       
        let transx=-c+cardWidth/2-a/2;
        let transy=-e+cardHeight/2-b/2;

         if(a>cardWidth) {      
            svg.attr("transform", `scale(${width/a})  translate(${cardWidth/(2*width/a)-(a/2+c)},${cardHeight/(2*width/a)-(b/2+e)}) `)
        }
         else{
            svg.attr("transform",`translate(${transx} ,${transy}) `)
         }
         return svg;

        }
    }

    displayProportion() {
        if(this.style()===Style.PICTOGRAPH){
            let { chartSize, annotationSize, margin } = getSizeBySize(this, 'proportion'),
            width = chartSize.width - margin.left - margin.right,
            height = chartSize.height - margin.top - margin.bottom;
        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", chartSize.width)
            .attr("height", chartSize.height)
            .append("g")
            .attr("id","isotypeproportionproportion")
            .attr("transform", `translate(${margin.left},${margin.top})`);

            if (this.focus().length !== 1 || this.measure().length > 1) {
                return;
            }

            let focus = this.focus();
          
            let filteredData = [] //sorted by focus
            for (const fs of focus) {
                this.factdata().filter((x) => x[fs.field] === fs.value)[0] && filteredData.push(this.factdata().filter((x) => x[fs.field] === fs.value)[0])
            }
    
            let measure = this.measure();
            let breakdown = this.breakdown();
            let pictype=measure[0].pictype;
            let maxYValue = d3.sum(this.factdata(), d => d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field])

            filteredData.map(data => {
                data.maxValue = (maxYValue - data[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]);
                return data;
            })   
            if (filteredData.length === 0) {
                return;
            }
            let data = filteredData;

            /*------------------通过名称找寻icon----------------------------*/
            svg.append("defs")
            .append("g")
            .attr("id",`pictype${pictype}`)
            .append("path")
            .attr("d",PictorialType[pictype])

            let typesizex1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
            let typesizey1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;

            let y = d3.scaleBand()
            .domain(data.map(function (d) { return d[breakdown[0].field]; }))
            .range([height - 40 * chartSize.height / 320, 0])
            .padding(0.6);

            /*-------------根据x的数据来进行icon的缩放--------------------------------*/
            let scalex;
            let scaley;
            svg.select(`#pictype${pictype}`)
            .attr("transform", function(){                
            scalex=y.bandwidth()/typesizey1;
            scaley=scalex;
            return  `scale(${scalex},${scaley})`                       
            });
            /*----------------计算缩放后的icon长宽------------------------*/
           // let typesizex=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
            let typesizey=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;
           //  let typex= svg.select(`#pictype${pictype}`).node().getBBox().x;
            let typey=svg.select(`#pictype${pictype}`).node().getBBox().y;

            /*----------------计算百分比--------------------------*/
            let pictorialdata=data[0][measure[0].aggregate === "count" ? "COUNT" : measure[0].field];
            let pictorialdatasum=d3.sum(this.factdata(), d => d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]);
            let proportionValue = data[0][measure[0].aggregate === "count" ? "COUNT" : measure[0].field] / d3.sum(this.factdata(), d => d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]);
            // let toolTipX = x(data[0][measure[0].aggregate === "count" ? "COUNT" : measure[0].field]) / 2,//箭头中心所在x的位置
            //     toolTipY = y(data[0][breakdown[0].field]) - 40 * chartSize.height / 320,
            let toolTipValue = (proportionValue * 100).toFixed(0) + "%";

              //specify the number of columns and rows for pictogram layout
            let numCols = 5;
            let numRows = 2;
        
            //padding for the grid
            let xPadding =10;
            //let yPadding = 15;
        
            //horizontal and vertical spacing between the icons
            //let hBuffer = 30;
            let wBuffer = (typesizex1+2)*scalex;
        
            //generate a d3 range for the total number of required elements
            let myIndex = d3.range(numCols * numRows);

        //define the gradient 
            let gradient= svg.append("svg:defs")
            .append("svg:linearGradient")
            .attr("id", function(){
                return "icontype";
            })
            .attr("spreadMethod", "pad");
    
            // Define the gradient colors
            gradient.append("svg:stop")
            .attr("offset", "0%")
            .attr("stop-color", function(){
                return Color.DEFAULT;
            })
            .attr("stop-opacity", 1);
            

            let total = numCols * numRows;
            let valuePict;
            let valueDecimal;
           
            valuePict=(pictorialdata*total/pictorialdatasum).toFixed(1);          
            valueDecimal=(valuePict%1);  
         

            let ybianhua= typey*scaley
            let ybuffer;
            if(this.size() === 'large') ybuffer=10;
            if(this.size() === 'middle') ybuffer=5;
            if(this.size() === 'wide') ybuffer=5;
            if(this.size() === 'small') ybuffer=3;
                //let typeindex=i*60;
                svg.append("g")
                .attr("id", function(){
                    return "icon";
                })
                .selectAll(".icontype")
                .data(myIndex)
                .enter()
                .append("use")
                .attr("xlink:href", `#pictype${pictype}`)
                .attr("id", function(d) {
                    return "icon" + d;
                })
                .attr("x", function(d) {
                    let remainder = d % numCols; //calculates the x position (column number) using modulus
                    return xPadding + (remainder * wBuffer); //apply the buffer and return value
                })
                .attr("y", function(d) {                  
                 // return y(data[i][breakdown[0].field])+y.bandwidth()/2-typesizey/2-Math.abs(ybianhua); //apply the buffer and return the value
                if(d/5<1) return height/2-typesizey-ybuffer-Math.abs(ybianhua);
                else return height/2+ ybuffer-Math.abs(ybianhua);
                })
                .attr("class",function(){
                    return "icontype";
                });
          
                //fill color
              
                    d3.selectAll(".icontype")
                    .attr("fill", function(d) {                   
                    if (d <= valuePict - 1) {       
                        return Color.DEFAULT;       
                    } else if (d > (valuePict - 1) && d < (valuePict)) {
                        gradient.append("svg:stop")
                            .attr("offset", (valueDecimal * 100) + '%')
                            .attr("stop-color", Color.DEFAULT)
                            .attr("stop-opacity", 1);
                        gradient.append("svg:stop")
                            .attr("offset", (valueDecimal * 100) + '%')
                            .attr("stop-color", Color.BACKGROUND)
                            .attr("stop-opacity", 1);
                        gradient.append("svg:stop")
                            .attr("offset", '100%')
                            .attr("stop-color", Color.BACKGROUND)
                            .attr("stop-opacity", 1);
                        return "url(#icontype)";
                    } else {
                        return Color.BACKGROUND;
                    }
                });
               
                svg.append("g")
                .append("text")
                .attr("x", 0)
                .attr("y", height/2+typesizey/2+ybuffer)
                .attr("text-anchor", "end")
                .attr("dominant-baseline", "middle")
                .attr('font-family',TEXTFONT)
                .attr("font-size", annotationSize)
                .attr("font-weight", 600)
                .attr("fill", Color.HIGHLIGHT)
                .text(toolTipValue)
               // .property("_value", toolTipValue)

               svg.append("g")
                .append("text")
                .attr("x", 0)
                .attr("y", height/2-typesizey/2-ybuffer)
                .attr("text-anchor", "end")
                .attr("alignment-baseline", "middle")
                .attr('font-family',TEXTFONT)
                .attr("font-size", annotationSize)
                .attr("font-weight", 600)
                .attr("fill", Color.HIGHLIGHT)
                .text(focus[0].value)

        //使图表居中
        let cardWidth=chartSize.width;
        let cardHeight=chartSize.height;
        let a=svg.node().getBBox().width;    
        let b=svg.node().getBBox().height;      
        let c=svg.node().getBBox().x;      
        let e=svg.node().getBBox().y;      
       
        let transx=-c+cardWidth/2-a/2;
        let transy=-e+cardHeight/2-b/2;
        if(a>cardWidth) {      
            svg.attr("transform", `scale(${width/a})  translate(${cardWidth/(2*width/a)-(a/2+c)},${cardHeight/(2*width/a)-(b/2+e)}) `)
        }
         else{
            svg.attr("transform",`translate(${transx} ,${transy}) `)
         }

            return svg;
            }      
    }
}


/** 
 * tickSize 坐标轴字号
 * annotationSize 标注字号
**/
const getSizeBySize = (_this, factType) => {
    let tickSize, annotationSize, maxTicksNum, xAxisH;

    switch (_this.size()) {
        case Size.WIDE:
            tickSize = 10;
            if (factType === 'proportion') annotationSize = 35;
            else annotationSize=15;
            maxTicksNum = 10;
            xAxisH = 10;
            break;
        case Size.MIDDLE:
            tickSize = 10;
            if (factType === 'proportion') annotationSize = 20;
            else annotationSize=12;
            maxTicksNum = 10;
            xAxisH = 9;
            break;
        case Size.SMALL:
            tickSize = 7;
            if (factType === 'proportion') annotationSize = 25;
            else annotationSize=10;
            maxTicksNum = 8;
            xAxisH = 6;
            break;
        case Size.LARGE:
        default:
            tickSize = 12;
            if (factType === 'proportion')  annotationSize = 50;
            else annotationSize=20;
            maxTicksNum = 21;
            xAxisH = 40;
            break;
    }
    let margin;
    switch (_this.size()) {
        case Size.LARGE:
            margin = { top: 40, right: 80, bottom: 40, left: 80 };
            if (factType === 'value') {
                margin = { top: 40, right: 40, bottom: 40, left: 30 };
            }
            if (factType === 'rank' || factType === 'distribution') {
                margin = { top: 40, right: 80, bottom: 40, left: 40 };
            }
            break;
        case Size.WIDE:
            margin = { top: 10, right: 50, bottom: 10, left: 50 };
            if (factType === 'value') {
                margin = { top: 10, right: 20, bottom: 10, left: 20 };
            }

            if (factType === 'difference') {
                margin = { top: 5, right: 20, bottom: 10, left: 20 };
            }

            if (factType === 'rank' || factType === 'distribution') {
                margin = { top: 10, right: 60, bottom: 10, left: 20 };
            }
            break;
        case Size.MIDDLE:
            margin = { top: 10, right: 40, bottom: 10, left: 40 };
            if (factType === 'value' || factType === 'difference') {
                margin = { top: 10, right: 20, bottom: 10, left: 10 };
            }
            if (factType === 'rank' || factType === 'distribution') {
                margin = { top: 10, right: 40, bottom: 10, left: 10 };
            }
            break;
        case Size.SMALL:
            margin = { top: 8, right: 35, bottom: 8, left: 35 };
            if (factType === 'value') {
                margin = { top: 10, right: 10, bottom: 10, left: 10 };
            }
            if (factType === 'difference') {
                margin = { top: 10, right: 20, bottom: 5, left: 10 };
            }

            if (factType === 'rank' || factType === 'distribution') {
                margin = { top: 8, right: 30, bottom: 8, left: 8 };
            }
            break;
        default:
            margin = { top: 40, right: 40, bottom: 40, left: 40 };
            break;
    }
    return {
        chartSize: {
            width: _this.width(),
            height: _this.height()
        },
        tickSize: tickSize,
        annotationSize: annotationSize,
        margin: margin,
        maxTicksNum: maxTicksNum,
        maxYAxisW: margin.left,
        xAxisH: xAxisH,
    }
};
const getMaxYValue = (factdata, measure) => {
    let maxYValue = d3.max(factdata, d => {
        return d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field];
    })
    if ((maxYValue / 1000000) >= 1) {
        maxYValue = Math.ceil(maxYValue / 1000000) * 1000000;
    } else if ((maxYValue / 1000) >= 1) {
        maxYValue = Math.ceil(maxYValue / 1000) * 1000;
    }
    return maxYValue
}
export default IsotypeProportion;
