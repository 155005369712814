import * as d3 from 'd3';
import Chart from '../../chart';
import Color from '../../visualization/color';
import Style from '../../visualization/style';
import Size from '../../visualization/size';
import generateToolTip from '../../visualization/tooltip';
import formatNumber from '../../visualization/format';

import metaphor7 from '../../metaphor/metaphor7.png';//trend, down
import metaphor8 from '../../metaphor/metaphor8.png';//trend, up
import metaphor2 from '../../metaphor/metaphor2.png';//trend, series
import metaphor6 from '../../metaphor/metaphor6.png';//trend, many
import metaphor10 from '../../metaphor/metaphor10.png';
import metaphor17 from '../../metaphor/metaphor17.png';

import uuidv4 from 'uuid/v4';

let hasSeries = false;

const NUMFONT = "Arial-Regular";
const ENGFONT = "Arial-Regular";

class AreaChart extends Chart {
    constructor() {
        super();
        this._x = '';
        this._y = '';
    }
    displayDistribution() {
        let factData = this.factdata()
        let measure = this.measure();
        let breakdown = this.breakdown();
        // let focus = this.focus();
        let hasSeries = breakdown[1] && breakdown[1].field ? true :false;
        // let seriesName = hasSeries ? d3.map(factData, function(d){return d[breakdown[1].field];}).keys() : [];
        
        let measureName = measure[0].aggregate === 'count' ? "COUNT" : measure[0].field
        // set data
        let data = factData;
        let stackedData;
        if (hasSeries) {
            let calculateData = d3.nest().key(d => d[breakdown[0].field]).entries(data);
            let categories = Array.from(new Set(data.map(d => d[breakdown[1].field])))
            categories = categories.slice(0, 10)
            // categories =categories.sort(() => .5 - Math.random()).slice(0,10)
            let objList = new Array(calculateData.length)
            for (let i = 0; i < calculateData.length; i++) {
                let obj = {};
                calculateData[i].values.map((d, i) => {
                    obj.x = d[breakdown[0].field]
                    obj[d[breakdown[1].field]] = d[measureName]
                    return obj;
                })
                objList[i] = obj
            }
            // complete the missed data = 0
            for (let k = 0; k < calculateData.length; k++) {
                for (let i = 0; i < categories.length; i++) {
                    if (!objList[k].hasOwnProperty(categories[i])) {
                        objList[k][categories[i]] = 0;
                    }
                }
            }
            objList = objList.sort((a, b) => parseTime(a.x) - parseTime(b.x))
            stackedData = d3.stack()
                .keys(categories)
                (objList)
            // stackedData = stackedData.slice(0, 10)
        
        }
        // stackedData = stackedData.slice(0, 10)
        data = data.sort((a, b) => parseTime(a[breakdown[0].field]) - parseTime(b[breakdown[0].field]))

        
        let moreThan6 = false
        // if(seriesName.length > 5)   moreThan6=true
        // set the dimensions and margins of the graph
        let chartSize = { width: this.width(), height: this.height() };
        let seriesName = hasSeries ? d3.map(stackedData, function(d){return d.key;}).keys() : [];
        // seriesName = seriesName.slice(0, 4)
        let legendRowNum = Math.ceil(seriesName.length / 3);
        const chartMargin = {
            "small":{
                "top": hasSeries ? 10 : 20,
                "right": 35,
                "bottom": hasSeries? (55 / 4) * legendRowNum + 25 : 25,
                "left": 35
            },
            "middle":{
                "top": hasSeries ? 10 : 20,
                "right": 45,
                "bottom": hasSeries ? (66 / 4) * legendRowNum + 35 : 40,
                "left": 60
            },
            "wide":{
                "top": 20,
                "right": hasSeries?  200 : 50,
                "bottom": 40,
                "left": 70
            },
            "large":{
                "top": 40,
                "right": 75,
                "bottom": hasSeries ? 150 + 50 : 80 + 50,
                "left": 100
            }
        };
        let margin = chartMargin[this.size()];
        let { tickSize, tickWidth } = getSizeBySize(this.size(), chartSize, 'distribution', hasSeries, moreThan6),
            width = chartSize.width - margin.left - margin.right,
            height = chartSize.height - margin.top - margin.bottom;

       

        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .attr("font-family", ENGFONT);

        if(this.style() === Style.COMICS) width = 0.8* width;

         // set the ranges
         let x = d3.scaleTime()
            .range([0, width])
        // .padding(0.1);
        let y = d3.scaleLinear()
            .range([height, 0]);


        // Scale the range of the data in the domains
        if(hasSeries)
            x.domain(d3.extent(data, function (d) { return parseTime(d[breakdown[0].field]); }));
        else 
            x.domain(d3.extent(data, function (d) { return parseTime(d[breakdown[0].field]); }));
        if (hasSeries)
            y.domain([0, d3.max(stackedData[stackedData.length - 1], d => d[1])])
        else
            y.domain([0, d3.max(data, function (d) { return d[measureName]; })]);

        this._x = x;
        this._y = y;

        let area_generator = d3.area().curve(d3.curveMonotoneX)
            .x(function (d) {
                return x(parseTime(d[breakdown[0].field]));
            })
            .y0(height)
            .y1(function (d) {
                return y(Math.max(d[measureName], 0));
            })

        // let initialarea = d3.area()
        //     .x(function (d) {
        //         return x(d[breakdown[0].field]);
        //     })
        //     .y0(height)
        //     .y1(height);

        let stacked_area_generator = d3.area().curve(d3.curveMonotoneX)
            .x(d => x(parseTime(d.data.x)))
            .y0(d => { 
                let y0=d[0]; 
                return y(Math.max(y0, 0))
            })
            .y1(d => {
                if(d[0] === d[1]) return y(Math.max(d[1], 0)) - 0.065
                else return y(Math.max(d[1], 0))
            })

        // let stack_initialarea = d3.area()
        //     .x(d => x(d.data.x))
        //     .y0(height)
        //     .y1(height);
        // add the x Axis
        let format_TicksCount = formatTicksCount(data[0][breakdown[0].field]);
        let tick_format = formatTick(data[0][breakdown[0].field]);
        let axisX = d3.axisBottom(x).tickFormat(tick_format)
        if(format_TicksCount === d3.timeYear) {
            axisX.ticks(format_TicksCount)
        } else if(format_TicksCount === d3.timeDay) {
            axisX.ticks(format_TicksCount)
        }
        svg.append("g")
            .attr("class", "xAxis")
            .attr("transform", "translate(0," + (height) + ")")
            .call(axisX)
            .call(g => {
                g.attr("font-size", this.size() === 'wide' && seriesName.length > 8 ? tickSize-2 : tickSize);
                g.attr("font-family", ENGFONT);

                let domainD = g.selectAll(".domain").attr("d");
                domainD = domainD.replace("6V", (6 * chartSize.height / 320)+ "V")
                domainD = domainD.replace("V6", "V" + (6 * chartSize.height / 320))
                g.selectAll(".domain").attr("d", domainD).attr("stroke-width", tickWidth);
                g.selectAll(".tick")
                    .selectAll("line")
                    .attr("stroke", Color.AXIS)
                    .attr("stroke-width", tickWidth)
                    .attr("y2", 6 * chartSize.height / 320);

                g.selectAll("text")
                    .attr("y", 9 * chartSize.height / 320); 
                // removeOverlapX(g, x)
                if(this.size() === 'large') {
                    // 检查够不够放
                    let tickWidth = 0;
                    let xRange = x.range()[1]-x.range()[0];
                    g.selectAll(".tick")
                        .each(function(d, i) {
                            let _thisWidth = d3.select(this).node().getBBox().width
                            tickWidth += _thisWidth
                        });
                    if(tickWidth > xRange*0.99) { //横的不够放
                        g.selectAll("text")
                            .attr("transform", `translate(-${5},0)rotate(-45)`)
                            .attr("text-anchor", "end");
                        // 检查斜着有没有遮挡
                        let prev = g.select("text").node().getBBox().height;
                        
                        let rotateAble = Math.floor(xRange/ prev) >= g.selectAll(".tick").size();
                        // 如果遮挡 摆回正的
                        if(!rotateAble) {
                            g.selectAll("text")
                                .attr("transform", "")
                                .attr("text-anchor", "middle");
                            removeOverlapX(g, x)
                        }
                    }
                    
                } else removeOverlapX(g, x)
            });
        svg.append("g")
            .attr("class", "yAxis")
            .call(d3.axisLeft(y))
            .attr("font-family", ENGFONT)
            // .attr("transform", `translate(-${8 * chartSize.width / 320},0)`)
            .call(d3.axisLeft(y).ticks(5).tickFormat(function (d) {
                if ((d / 1000000) >= 1) {
                    d = d / 1000000 + "M";
                } else if ((d / 1000) >= 1) {
                    d = d / 1000 + "K";
                }
                return d;
            }))
            .call(g => {
                g.attr("font-size", this.size() === 'wide' && seriesName.length > 8 ? tickSize-2 : tickSize);
                g.attr("font-family", ENGFONT);

                let domainD = g.selectAll(".domain").attr("d");
                domainD = domainD.replace("M-6", "M-" + (6 * chartSize.height / 320))
                domainD = domainD.replace("H-6", "H-" + (6 * chartSize.height / 320))
                g.selectAll(".domain").attr("d", domainD).attr("stroke-width", tickWidth);
                g.selectAll(".tick")
                    .select("line")
                    .attr("stroke", Color.AXIS)
                    .attr("stroke-width", tickWidth)
                    .attr("x2", -6 * chartSize.height / 320);

                g.selectAll("text")
                    .attr("x", -9 * chartSize.height / 320);
                
                if(this.size() === "small") {
                    g.selectAll(".domain").attr("display", "none")
                    g.selectAll(".tick").select("line").attr("display", "none")
                    g.selectAll(".tick").select("text").attr("display", "none")
                }
                // else {
                //     g.selectAll(".tick")
                //     .filter(function(d, i, list){ return i=== 0 || i === list.length-1})
                //     .select("line")
                //     .remove()
                // };
            })
                

        // axis_y
        //     .selectAll('.tick')
        //     .append('line')
        //     .attr("x2", width)
        //     .attr('stroke', Color.DASHLINE)
        //     .attr("stroke-width", tickWidth / 2)
        //     .attr("transform", `translate(${8* chartSize.width / 320},0)`);

        // append legend 
        // let seriesName = hasSeries ? d3.map(stackedData, function(d){return d.key;}).keys() : [];
        let seriesNameCopy = [...seriesName];
        if(hasSeries) {
            seriesNameCopy.sort((a, b)=>a.length -b.length)
            seriesName= [];
            let i=0
            while(seriesNameCopy.length) {
                if(i % 2) seriesName.push(seriesNameCopy.shift())
                else seriesName.push(seriesNameCopy.pop());
                i+=1
            }
        }
        
        if(hasSeries) {   
            if(this.size() === "wide") {
                svg.append("foreignObject")
                    .attr("x", width + 30)
                    .attr("y", seriesName.length > 8? -margin.top * 0.5 : 0)
                    .attr("width", margin.right - 10)
                    .attr("height", height + margin.bottom * 0.6 + margin.top * 0.5)
                    .append("xhtml:div")
                    .attr("class", "legends")
                    .style("display", "flex")
                    .style("flex-direction", "column")
                    .style("flex-wrap", "wrap")
                    .style("align-content", "space-around")
                    .style("justify-content", "space-around")
                    .style("height", Math.round(seriesName.length > 8? height + margin.bottom * 0.6 + margin.top * 0.5 : height + margin.bottom * 0.3)+"px")
                    .selectAll(".legend")
                    .data(seriesName)
                    .enter()
                    .append("xhtml:div")
                    .attr("class","legend")
                    .style("line-height",  0)
                    .style("margin-right",  "1px")
                    .each(function(d, i) {
                        let legend = d3.select(svg.selectAll(".legend").nodes()[i]).append("svg")
                        legend.append("rect")
                            .attr("fill", d => Color.CATEGORICAL[seriesName.indexOf(d)])
                            .attr("width", seriesName.length > 8 ? tickSize-2 +"px": tickSize+"px")
                            .attr('height', seriesName.length > 8 ? tickSize-2 +"px": tickSize+"px")
                            .attr("rx", Math.floor(1.5 * chartSize.width / 320) )
                            .attr("ry", Math.floor(1.5 * chartSize.width / 320) )
                        legend.append("text")
                            .attr("x", tickSize+3+"px")
                            .text(d => d.length > 11 ? d.substring(0, 10)+"…": d)
                            .attr("font-size", seriesName.length > 8 ? tickSize-2 +"px": tickSize+"px")
                            .attr("font-family", ENGFONT)
                            .attr("dominant-baseline", "hanging");
                        legend.attr("width", Math.round(legend.node().getBBox().width))
                        legend.attr("height", Math.floor(legend.node().getBBox().height))
 
                    })
            }
            else {
                let thisSize = this.size();
                let xAxisHeightOffset
                let isRotate = svg.selectAll(".xAxis").select("text").attr("transform") ? true : false
                if(this.size() === 'small')
                    xAxisHeightOffset = svg.selectAll(".xAxis").node().getBBox().height * 1.4
                else if(this.size() === 'middle')
                    xAxisHeightOffset = svg.selectAll(".xAxis").node().getBBox().height * 1.4
                else {
                    if(isRotate) xAxisHeightOffset = svg.selectAll(".xAxis").node().getBBox().height * 1.2 
                    else xAxisHeightOffset = svg.selectAll(".xAxis").node().getBBox().height * 2
                }
                svg.append("foreignObject")
                    .attr("x", ()=>{
                        if (this.size() === 'small')  return -margin.left * 0.5;
                        else if(this.size() === 'middle') return -margin.left * 0.4
                        else return margin.right - margin.left//0
                    })
                    .attr("y", height + xAxisHeightOffset )
                    .attr("width", ()=>{
                        if (this.size() === 'small')  return  width + margin.left * 0.5 * 2;
                        else if(this.size() === 'middle') return  width + margin.left * 0.4 * 2;
                        else return width - (margin.right - margin.left);
                    })
                    .attr("height", (margin.bottom - xAxisHeightOffset))
                    .append("xhtml:div")
                    .attr("class", "legends")
                    .style("display", "grid")
                    .style("grid-template-columns", `repeat(${Math.min(seriesName.length, thisSize === 'small' ? 3 : 3)}, auto)`)
                    .style("grid-template-rows", `repeat(4, min-content)`)
                    .style("grid-auto-flow", "row")
                    .style("justify-content", "space-around")
                    .style("align-content", "center")
                    .style("align-items", "end")
                    .style("padding-top", "1px")
                    .style("height", Math.round((margin.bottom - xAxisHeightOffset))+"px")
                    .selectAll(".legend")
                    .data(seriesName)
                    .enter()
                    .append("xhtml:div")
                    .attr("class","legend")
                    .style("line-height", 0)
                    // .style("margin-right", 5 * chartSize.width/320 + "px")
                    .each(function(d, i) {

                        let legend = d3.select(svg.selectAll(".legend").nodes()[i]).append("svg")
                        legend.append("rect")
                            .attr("fill", d => Color.CATEGORICAL[seriesName.indexOf(d)])
                            .attr("width", thisSize === 'large' ? tickSize + 1 : tickSize)
                            .attr('height', thisSize === 'large' ? tickSize + 1 : tickSize)
                            .attr("rx", 1.5 * chartSize.width / 320 )
                            .attr("ry", 1.5 * chartSize.width / 320 )
                        // .attr("cy", -5);
                        legend.append("text")
                            .attr("x", thisSize === 'large' ? tickSize + 4 : tickSize+1)
                            .text(d => {
                                let text = d
                                if(thisSize === 'small') { // small
                                    if(text.length > 8) text = text.substring(0, 7) + "…"
                                } else if(thisSize === 'middle' ) { //middle
                                    if(text.length > 10) text = text.substring(0, 9) + "…"
                                } else {
                                    if(text.length> 10) text = text.substring(0, 9) + "…"
                                    // if(text.length>15) text = text.substring(0, 14) + "…"
                                }
                                return text
                            })
                            .attr("font-size", thisSize === "large" ? tickSize + 2 : tickSize + 1)
                            .attr("font-family", ENGFONT)
                            .attr("dominant-baseline", "hanging");
                        legend.attr("width", Math.round(legend.node().getBBox().width))
                        legend.attr("height", Math.floor(legend.node().getBBox().height))
                        
                    })

            }
            
        }
        if (hasSeries) {
            svg.append('g')
                .attr("class", "areas")
                .selectAll("path")
                .data(stackedData)
                .join("path")
                .attr('id', ({ key }) => 'series_' + key.replace(/\s/g, ""))
                .attr("fill", d=>Color.CATEGORICAL[seriesName.indexOf(d.key)])// ({ index }) => Color.CATEGORICAL[index])
                .attr("class", "area")
                .attr("d", stacked_area_generator)
        } else {
            svg.append('g')
                .lower()
                .attr("class", "areas")
                .append("path")
                .attr('class', 'areaG')
                .attr("fill", Color.DEFAULT)
                // .attr("d", initialarea(data))
                // .transition()
                // .duration(4000)
                .attr("d", area_generator(data))
        }

         
        if(this.style() === Style.COMICS){
            let metaphorWidth = width*0.35,
                metaphorHeight = 1.45*metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor10)
                .attr("width", metaphorWidth)
                .attr("height", metaphorHeight);

            if(this.size() === Size.WIDE){
                metaphor.attr("x", width)
                    .attr("y", height - metaphorHeight*0.96);
                svg.select("foreignObject")
                    .attr("x", width/0.8 + 10);
            }else if (this.size() === Size.MIDDLE){
                metaphorWidth = width*0.3;
                metaphorHeight = 1.5*metaphorWidth;

                metaphor.attr("width", metaphorWidth)
                    .attr("height", metaphorHeight)
                    .attr("x", width)
                    .attr("y", height - metaphorHeight*0.96);
                svg.select("foreignObject").attr("width", width/0.8 + margin.left * 0.4 * 2)
            }else{
                metaphor.attr("x", width)
                    .attr("y", height - metaphorHeight*0.96);
                svg.select("foreignObject").attr("width", this.size() === 'small' ? width/0.8 + margin.left * 0.5 * 2 : width/0.8 - (margin.right - margin.left))
            }
        }

        return svg;
    }

    displayTrend() {
        let factData = this.factdata();
        let measure = this.measure();
        let breakdown = this.breakdown();
        let measureName = measure[0].aggregate === 'count' ? "COUNT" : measure[0].field;
        let hasSeries = breakdown[1] && breakdown[1].field ? true : false;
        let moreThan6 = false

        // set data
        let data = factData;
        let stackedData;
        if (hasSeries) {
            let calculateData = d3.nest().key(d => d[breakdown[0].field]).entries(data);
            let categories = Array.from(new Set(data.map(d => d[breakdown[1].field])))
            categories = categories.slice(0, 10)
            // categories =categories.sort(() => .5 - Math.random()).slice(0,10)
            let objList = new Array(calculateData.length)
            for (let i = 0; i < calculateData.length; i++) {
                let obj = {};
                calculateData[i].values.map((d, i) => {
                    obj.x = d[breakdown[0].field]
                    obj[d[breakdown[1].field]] = d[measureName]
                    return obj;
                })
                objList[i] = obj
            }
            // complete the missed data = 0
            for (let k = 0; k < calculateData.length; k++) {
                for (let i = 0; i < categories.length; i++) {
                    if (!objList[k].hasOwnProperty(categories[i])) {
                        objList[k][categories[i]] = 0;
                    }
                }
            }
            objList = objList.sort((a, b) => parseTime(a.x) - parseTime(b.x))
            stackedData = d3.stack()
                .keys(categories)
                (objList)
            stackedData = stackedData.slice(0, 10)
        }

        data = data.sort((a, b) => parseTime(a[breakdown[0].field]) - parseTime(b[breakdown[0].field]))

        // set the dimensions and margins of the graph
        let chartSize = { width: this.width(), height: this.height() };
        let seriesName = hasSeries ? d3.map(stackedData, function(d){return d.key;}).keys() : [];
        // seriesName = seriesName.slice(0, 8)
        let legendRowNum = Math.ceil(seriesName.length / 3);
        const chartMargin = {
            "small":{
                "top": 20,
                "right": 35,
                "bottom": hasSeries? (55 / 4) * legendRowNum + 25 : 25,
                "left": 35
            },
            "middle":{
                "top": 20,
                "right": 45,
                "bottom": hasSeries ? (66 / 4) * legendRowNum + 35:  40,
                "left": 60
            },
            "wide":{
                "top": 20,
                "right": hasSeries?  200 : 50,
                "bottom": 40,
                "left": 70
            },
            "large":{
                "top": 40,
                "right": 75,
                "bottom": hasSeries ? 150 + 50 : 80 + 50,
                "left": 100
            }
        };
        let margin = chartMargin[this.size()];
        let { tickSize, tickWidth, strokeWidth } = getSizeBySize(this.size(), chartSize, 'trend', hasSeries, moreThan6),
            width = chartSize.width - margin.left - margin.right,
            height = chartSize.height - margin.top - margin.bottom;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        if(this.style() === Style.COMICS){
            if(hasSeries || data.length > 7) width *= 0.8;
            else if(this.size() === Size.WIDE){
                height *= 0.85;
            }
        }
        
        // set the ranges
        let x = d3.scaleTime()
            .range([0, width])
        // .padding(0.1);
        let y = d3.scaleLinear()
            .range([height, 0]);

        // if (breakdown[1] && breakdown[1].field) return svg;
        
        // Scale the range of the data in the domains
        x.domain(d3.extent(data, function (d) { return parseTime(d[breakdown[0].field]); }));
        // y.domain([0, d3.max(data, function (d) { return d[measureName]; })]);
        if (hasSeries)
            y.domain([0, d3.max(stackedData[stackedData.length - 1], d => d[1])])
        else
            y.domain([0, d3.max(data, function (d) { return d[measureName]; })]);

        let area_generator = d3.area().curve(d3.curveMonotoneX)
            .x(function (d) {
                return x(parseTime(d[breakdown[0].field]));
            })
            .y0(height)
            .y1(function (d) {
                return y(d[measureName]);
            })
        let stacked_area_generator = d3.area().curve(d3.curveMonotoneX)
            .x(d => x(parseTime(d.data.x)))
            .y0(d => { 
                let y0=d[0]; 
                return y(Math.max(y0, 0))
            })
            .y1(d => {
                if(d[0] === d[1]) return y(Math.max(d[1], 0)) - 0.065
                else 
                    return y(Math.max(d[1], 0))
            })
        // let initialarea = d3.area()
        //     .x(function (d) {
        //         return x(d[breakdown[0].field]);
        //     })
        //     .y0(height)
        //     .y1(height);

        // add the x Axis
        let format_TicksCount = formatTicksCount(data[0][breakdown[0].field]);
        let tick_format = formatTick(data[0][breakdown[0].field]);
        let axisX = d3.axisBottom(x).tickFormat(tick_format)
        if(format_TicksCount === d3.timeYear) {
            axisX.ticks(format_TicksCount)
        } else if(format_TicksCount === d3.timeDay) {
            axisX.ticks(format_TicksCount)
        }

        // legend data init
        
        let seriesNameCopy = [...seriesName];
        if(hasSeries) {
            seriesNameCopy.sort((a, b)=>a.length -b.length)
            seriesName= [];
            let i=0
            while(seriesNameCopy.length) {
                if(i % 2) seriesName.push(seriesNameCopy.shift())
                else seriesName.push(seriesNameCopy.pop());
                i+=1
            }
        }

        // draw axis
        svg.append("g")
            .attr("class", "xAxis")
            .attr("transform", "translate(0," + (height) + ")")
            .call(axisX)
            .call(g => {
                g.attr("font-size", this.size() === 'wide' && seriesName.length > 8 ? tickSize-2 : tickSize);
                g.attr("font-family", ENGFONT);

                let domainD = g.selectAll(".domain").attr("d");
                domainD = domainD.replace("6V", (6 * chartSize.height / 320)+ "V")
                domainD = domainD.replace("V6", "V" + (6 * chartSize.height / 320))
                g.selectAll(".domain").attr("d", domainD).attr("stroke-width", tickWidth);
                g.selectAll(".tick")
                    .selectAll("line")
                    .attr("stroke", Color.AXIS)
                    .attr("stroke-width", tickWidth)
                    .attr("y2", 6 * chartSize.height / 320);

                g.selectAll("text")
                    .attr("y", 9 * chartSize.height / 320);

                if(this.size() === 'large') {
                    // 检查够不够放
                    let _tickWidth = 0;
                    let xRange = x.range()[1]-x.range()[0];
                    g.selectAll(".tick")
                        .each(function(d, i) {
                            let _thisWidth = d3.select(this).node().getBBox().width
                            _tickWidth += _thisWidth
                        });
                    if(_tickWidth > xRange*0.99) { //横的不够放
                        g.selectAll("text")
                            .attr("transform", `translate(-${5},0)rotate(-45)`)
                            .attr("text-anchor", "end");
                        // 检查斜着有没有遮挡
                        let prev = g.select("text").node().getBBox().height;
                        
                        let rotateAble = Math.floor(xRange/ prev) >= g.selectAll(".tick").size();
                        // 如果遮挡 摆回正的
                        if(!rotateAble) {
                            g.selectAll("text")
                                .attr("transform", "")
                                .attr("text-anchor", "middle");
                            removeOverlapX(g, x)
                        }
                    }
                    
                } else removeOverlapX(g, x)
            });;

        // add the y Axis
        svg.append("g")
            .attr("class", "yAxis")
            .call(d3.axisLeft(y))
            .attr("font-family", ENGFONT)
            // .attr("transform", `translate(-${8 * chartSize.width / 320},0)`)
            .call(d3.axisLeft(y).ticks(5).tickFormat(function (d) {
                if ((d / 1000000) >= 1) {
                    d = d / 1000000 + "M";
                } else if ((d / 1000) >= 1) {
                    d = d / 1000 + "K";
                }
                return d;
            }))
            .call(g => {
                g.attr("font-size", this.size() === 'wide' && seriesName.length > 8 ? tickSize-2 : tickSize);
                g.attr("font-family", ENGFONT);

                let domainD = g.selectAll(".domain").attr("d");
                domainD = domainD.replace("M-6", "M-" + (6 * chartSize.height / 320))
                domainD = domainD.replace("H-6", "H-" + (6 * chartSize.height / 320))
                g.selectAll(".domain").attr("d", domainD).attr("stroke-width", tickWidth);
                g.selectAll(".tick")
                    .select("line")
                    .attr("stroke", Color.AXIS)
                    .attr("stroke-width", tickWidth)
                    .attr("x2", -6 * chartSize.height / 320);
                g.selectAll("text")
                    .attr("x", -9 * chartSize.height / 320);
                
                // if(this.size() === "middle") {
                //     g.selectAll(".domain").attr("stroke-width", 1)
                // }
                if(this.size() === "small") {
                    g.selectAll(".domain").attr("display", "none")
                    g.selectAll(".tick").select("line").attr("display", "none")
                    g.selectAll(".tick").select("text").attr("display", "none")
                }
            });

        // axis_y
        //     .selectAll('.tick')
        //     .append('line')
        //     .attr("x2", width)
        //     .attr('stroke', Color.DASHLINE)
        //     .attr("stroke-width", tickWidth / 2)
        //     .attr("transform", `translate(${8* chartSize.width / 320},0)`)

        // append legend 
        
        if(hasSeries) {
            if(this.size() === "wide") {
                svg.append("foreignObject")
                    .attr("x", chartSize.width - margin.left - margin.right + 30)
                    .attr("y", seriesName.length > 8? -margin.top * 0.5 : 0)
                    .attr("width", margin.right - 10)
                    .attr("height", height + margin.bottom * 0.6 + margin.top * 0.5)
                    .append("xhtml:div")
                    .attr("class", "legends")
                    .style("display", "flex")
                    .style("flex-direction", "column")
                    .style("flex-wrap", "wrap")
                    .style("align-content", "space-around")
                    .style("justify-content", "space-around")
                    .style("height", Math.round(seriesName.length > 8? height + margin.bottom * 0.6 + margin.top * 0.5 : height + margin.bottom * 0.3)+"px")
                    .selectAll(".legend")
                    .data(seriesName)
                    .enter()
                    .append("xhtml:div")
                    .attr("class","legend")
                    .style("line-height",  0)
                    .style("margin-right", 5 * chartSize.width/320 + "px")
                    .each(function(d, i) {
                        let legend = d3.select(svg.selectAll(".legend").nodes()[i]).append("svg")
                        legend.append("rect")
                            .attr("fill", d => Color.CATEGORICAL[seriesName.indexOf(d)])
                            .attr("width", seriesName.length > 8 ? tickSize-2 : tickSize)
                            .attr('height', seriesName.length > 8 ? tickSize-2 : tickSize)
                            .attr("rx", 1.5 * chartSize.width / 320 )
                            .attr("ry", 1.5 * chartSize.width / 320 )
                        legend.append("text")
                            .attr("x", tickSize+3)
                            .text(d => d.length > 11 ? d.substring(0, 10)+"…": d)
                            .attr("font-size", seriesName.length > 8 ? tickSize-2 : tickSize)
                            .attr("font-family", ENGFONT)
                            .attr("dominant-baseline", "hanging");
                        legend.attr("width", Math.round(legend.node().getBBox().width))
                        legend.attr("height", Math.floor(legend.node().getBBox().height))
                        
                        
                    })
            }
            else {
                let thisSize = this.size();
                let xAxisHeightOffset
                let isRotate = svg.selectAll(".xAxis").select("text").attr("transform") ? true : false
                if(this.size() === 'small')
                    xAxisHeightOffset = svg.selectAll(".xAxis").node().getBBox().height * 1.4
                else if(this.size() === 'middle')
                    xAxisHeightOffset = svg.selectAll(".xAxis").node().getBBox().height * 1.4
                else {
                    if(isRotate) xAxisHeightOffset = svg.selectAll(".xAxis").node().getBBox().height * 1.2 
                    else xAxisHeightOffset = svg.selectAll(".xAxis").node().getBBox().height * 2
                }
                svg.append("foreignObject")
                    .attr("x", ()=>{
                        if (this.size() === 'small')  return -margin.left * 0.5;
                        else if(this.size() === 'middle') return -margin.left * 0.4
                        else return margin.right - margin.left//0
                    })
                    .attr("y", height + xAxisHeightOffset )
                    .attr("width", ()=>{
                        if (this.size() === 'small')  return  chartSize.width - margin.left - margin.right + margin.left * 0.5 * 2;
                        else if(this.size() === 'middle') return  chartSize.width - margin.left - margin.right + margin.left * 0.4 * 2;
                        else return chartSize.width - margin.left - margin.right - (margin.right - margin.left);
                    } )
                    .attr("height", (margin.bottom - xAxisHeightOffset))
                    .append("xhtml:div")
                    .attr("class", "legends")
                    .style("display", "grid")
                    .style("grid-template-columns", `repeat(${Math.min(seriesName.length, thisSize === 'small' ? 3 : 3)}, auto)`)
                    .style("grid-template-rows", `repeat(4, min-content)`)
                    .style("grid-auto-flow", "row")
                    .style("justify-content", "space-between")
                    .style("align-content", "center")
                    .style("align-items", "end")
                    .style("padding-top", "1px")
                    .style("height", Math.round((margin.bottom - xAxisHeightOffset))+"px")
                    .selectAll(".legend")
                    .data(seriesName)
                    .enter()
                    .append("xhtml:div")
                    .attr("class","legend")
                    .style("line-height", 0)
                    // .style("margin-right", 5 * chartSize.width/320 + "px")
                    .each(function(d, i) {

                        let legend = d3.select(svg.selectAll(".legend").nodes()[i]).append("svg")
                        legend.append("rect")
                            .attr("fill", d => Color.CATEGORICAL[seriesName.indexOf(d)])
                            .attr("width", thisSize === 'large' ? tickSize + 1 : tickSize)
                            .attr('height', thisSize === 'large' ? tickSize + 1 : tickSize)
                            .attr("rx", 1.5 * chartSize.width / 320 )
                            .attr("ry", 1.5 * chartSize.width / 320 )
                        // .attr("cy", -5);
                        legend.append("text")
                            .attr("x", thisSize === 'large' ? tickSize + 2 : tickSize+1)
                            .text(d => {
                                let text = d
                                if(thisSize === 'small') { // small
                                    if(text.length > 8) text = text.substring(0, 7) + "…"
                                } else if(thisSize === 'middle' ) { //middle
                                    if(text.length > 10) text = text.substring(0, 9) + "…"
                                } else {
                                    if(text.length> 10) text = text.substring(0, 9) + "…"
                                    // if(text.length>15) text = text.substring(0, 14) + "…"
                                }
                                return text
                            })
                            .attr("font-size", thisSize === "large" ? tickSize + 2 : tickSize + 1)
                            .attr("font-family", ENGFONT)
                            .attr("dominant-baseline", "hanging");
                        legend.attr("width", Math.round(legend.node().getBBox().width))
                        legend.attr("height", Math.floor(legend.node().getBBox().height))
                        
                    })

            }
            
        }
        if (!hasSeries) {
            svg.append('g')
                .lower()
                .attr("class", "areas")
                .append("path")
                .attr("fill", Color.DEFAULT)
                .attr("d", area_generator(data))

            let lineLayer = svg.append("g").attr('id', 'lineLayer')
            let lineGen = d3.line().curve(d3.curveMonotoneX)
                .x(function (d) {
                    return x(parseTime(d[breakdown[0].field]));
                })
                .y(function (d) {
                    return y(d[measureName]);
                })
            lineLayer.append('path')
                .attr('d', lineGen(data))
                .attr('stroke', Color.HIGHLIGHT)
                .attr('stroke-width', strokeWidth)
                .attr('fill', 'none')
                .attr("stroke-dasharray", `${strokeWidth*2}, ${strokeWidth}`);;
        } else {
            svg.append('g')
            .lower()
            .attr("class", "areas")
            .selectAll("path")
            .data(stackedData)
            .join("path")
            .attr('id', ({ key }) => 'series_' + key.replace(/\s/g, ""))
            .attr("fill", d=>Color.CATEGORICAL[seriesName.indexOf(d.key)])//({ index }) => Color.CATEGORICAL[index])
            .attr("class", "area")
            // .attr("d", stack_initialarea)
            // .transition()
            // .duration(4000)
            .attr("d", stacked_area_generator)
        }

        if(this.style() === Style.COMICS){
            if(hasSeries){
                let metaphorWidth = width*0.34,
                metaphorHeight = 1.25*metaphorWidth;

                let metaphor = svg.append("image")
                    .attr('xlink:href', metaphor2);
            
                if(this.size() === Size.WIDE){
                    metaphorWidth = width*0.34;
                    metaphorHeight = 1.25*metaphorWidth;
                }else if (this.size() === Size.MIDDLE){
                    metaphorWidth = width*0.32;
                    metaphorHeight = 1.25*metaphorWidth;
                }
                
                metaphor.attr("width", metaphorWidth)
                    .attr("height", metaphorHeight)
                    .attr("x", width + metaphorWidth*0.12)
                    .attr("y", height - metaphorHeight*0.96);
            }else{
                //points
                // let filterPoints = [];
                // svg.selectAll(".tick").each(function(d){
                //     let item = factData.find(i => parseTime(i[breakdown[0].field]).getTime() === d.getTime());
                //     filterPoints.push(item);
                // });
                let filterPoints = data;
                if(filterPoints.length > 7){//too much point
                    //draw dash line
                    let x0 = x(parseTime(filterPoints[0][breakdown[0].field])),
                        x1 = x(parseTime(filterPoints.slice(-1)[0][breakdown[0].field])),
                        y0 = y(filterPoints[0][measureName]),
                        y1 = y(filterPoints.slice(-1)[0][measureName]),
                        x2 = x1 + width*0.14,
                        y2 = (x2 - x1) * (y1-y0)/(x1-x0) + y1;
                    let line_m = svg.append('line')
                        .attr("x1", x1)
                        .attr("x2", x2)
                        .attr("y1", y1)
                        .attr("y2", y2)
                        .attr("stroke", Color.DASHLINE)
                        .attr("stroke-width", strokeWidth)
                        .attr("stroke-dasharray", `${strokeWidth*2}, ${strokeWidth}`);
                    svg.node().prepend(line_m.node());

                    let metaphorWidth = width*0.26,
                        metaphorHeight = 1.24*metaphorWidth;

                    let metaphor = svg.append("image")
                        .attr('xlink:href', metaphor6);
            
                    if(this.size() === Size.WIDE){
                        metaphorWidth = width*0.20;
                        metaphorHeight = 1.24*metaphorWidth;
                    }else if (this.size() === Size.MIDDLE || this.size() === Size.SMALL){
                        metaphorWidth = width*0.24;
                        metaphorHeight = 1.24*metaphorWidth;
                    }
                    
                    metaphor.attr("width", metaphorWidth)
                        .attr("height", metaphorHeight)
                        .attr("x", x2 - metaphorWidth*0.06)
                        .attr("y", y2 - metaphorHeight*0.06);
                    
                }else{
                    const metaphorWidth7 = width/(filterPoints.length-1)*0.6,
                        metaphorWidth8 = metaphorWidth7 / 1.14;
                    const metaphorHeight7 = metaphorWidth7*0.95;
                    const metaphorHeight8 = metaphorWidth8*1.2;

                    for(let i=1; i<filterPoints.length; i++){
                        let middleX = (x(parseTime(filterPoints[i][breakdown[0].field])) + x(parseTime(filterPoints[i-1][breakdown[0].field])))/2;
                        let middleY = (y(filterPoints[i][measureName]) + y(filterPoints[i-1][measureName]))/2;
                        if(filterPoints[i][measureName] - filterPoints[i-1][measureName] > 0){//up
                            svg.append("image")
                                .attr('xlink:href', metaphor8)
                                .attr("width", metaphorWidth8)
                                .attr("height", metaphorHeight8)
                                .attr("x", middleX - metaphorWidth8*0.7)
                                .attr("y", middleY - metaphorHeight8*0.96);
                        }else{//down
                            svg.append("image")
                                .attr('xlink:href', metaphor7)
                                .attr("width", metaphorWidth7)
                                .attr("height", metaphorHeight7)
                                .attr("x", middleX - metaphorWidth7*0.5)
                                .attr("y", middleY - metaphorHeight7*1);
                        }
                    }
                }
                //center居中
                svg.attr("transform", "translate(" + ((this.width() - svg.node().getBBox().width)/2 - svg.node().getBBox().x)  + "," + ((this.height() - svg.node().getBBox().height)/2 - svg.node().getBBox().y) + ")"); 
            }
        }
        
        return svg;
    }

    displayOutlier() {
        let factData = this.factdata()
        let measure = this.measure();
        let breakdown = this.breakdown();
        let focus = this.focus();
        // set data
        factData = factData.sort((a, b) => parseTime(a[breakdown[0].field]) - parseTime(b[breakdown[0].field]))

        let data = factData;
        let measureName = measure[0].aggregate === 'count' ? "COUNT" : measure[0].field
        if (focus.length === 0) return
        // for(let f of focus) {
        //     if(f.field !== breakdown[0].field) return 
        // }
        // 只取focus首个
        if(!focus[0].field || focus[0].field !== breakdown[0].field) return 
        if (breakdown[1] && breakdown[1].field) return;

        // set the dimensions and margins of the graph
        let chartSize = { width: this.width(), height: this.height() }
        let { margin, tickSize, annotationSize, tickWidth, dotR } = getSizeBySize(this.size(), chartSize, 'outlier');
        
        
        // get tootltip size
        let focusValueArray = factData.filter(d => {
            return d[focus[0].field] === focus[0].value
        })
        let focusValue = focusValueArray[0][measureName]
        let _tooltip = d3.select(this.container())
                        .append("svg")
                        .attr("class", "testNode")
                        .attr("opacity", 0);
        _tooltip.append("text")
            .text(formatNumber(focusValue)).attr("font-size", annotationSize)
            .attr("font-family", NUMFONT);
                        
        let tooltipHeight =  d3.select(".testNode").select("text").node().getBBox().height;
        let tooltipWidth =  d3.select(".testNode").select("text").node().getBBox().width;
        if( margin.top < tooltipHeight/0.8 + dotR * 2.5 ) { // 30 * chartSize.height / 640
            margin.top = tooltipHeight/0.8 + dotR * 3.5//30 * chartSize.height / 640 * 1.5

        }
        if(factData[0][focus[0].field] === focus[0].value  && margin.left < Math.min(tooltipWidth/0.7/2, (tooltipWidth + 12)/2)) {
            margin.left = Math.ceil( Math.min(tooltipWidth/0.7/2, (tooltipWidth + 12)/2))*1.1;
        }
        if(factData[factData.length-1][focus[0].field] === focus[0].value && margin.right < Math.min(tooltipWidth/0.7/2, (tooltipWidth + 12)/2)) {
            margin.right = Math.ceil( Math.min(tooltipWidth/0.7/2, (tooltipWidth + 12)/2))*1.1;
        }

        
        _tooltip.remove();

        let width = chartSize.width - margin.left - margin.right,
        height = chartSize.height - margin.top - margin.bottom;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .attr("font-family", ENGFONT);
        
        if(this.style() === Style.COMICS) width = (this.size() === Size.LARGE || this.size() === Size.SMALL)? 0.8* width : 0.85 * width;
        
        // set the ranges
        let x = d3.scaleTime()
            .range([0, width])
        // .padding(0.1);
        let y = d3.scaleLinear()
            .range([height, 0]);

        // Scale the range of the data in the domains
        x.domain(d3.extent(data, function (d) {return parseTime(d[breakdown[0].field]); }));
        y.domain([0, d3.max(data, function (d) { return d[measureName]; })]);

        let area_generator = d3.area().curve(d3.curveMonotoneX)
            .x(function (d) {
                return x(parseTime(d[breakdown[0].field]));
            })
            .y0(height)
            .y1(function (d) {
                return y(Math.max(d[measureName], 0));
            })

        // add the x Axis
        let format_TicksCount = formatTicksCount(data[0][breakdown[0].field]);
        let tick_format = formatTick(data[0][breakdown[0].field]);
        let axisX = d3.axisBottom(x).tickFormat(tick_format)
        if(format_TicksCount === d3.timeYear) {
            axisX.ticks(format_TicksCount)
        }
        if(focus.length !== 0) {
            // 如果不在当前的列表里，就添加
            let i;
            for(i=0; i<x.ticks().length; i++) {
                if(x.ticks()[i].toString() === parseTime(focus[0].value).toString()) break;
            }
            if(i === x.ticks().length) {
                let tickValues = x.ticks();
                tickValues.push(parseTime(focus[0].value))
                axisX.tickValues(tickValues)
            }
        }
        svg.append("g")
            .attr("class", "xAxis")
            .attr("transform", "translate(0," + (height + 8 * chartSize.height / 320) + ")")
            .call(axisX)
            .attr("font-family", ENGFONT)
            .call(g => {
                g.attr("font-size", tickSize);
                g.attr("font-family", ENGFONT);

                let domainD = g.selectAll(".domain").attr("d");
                domainD = domainD.replace("6V", (6 * chartSize.height / 320)+ "V")
                domainD = domainD.replace("V6", "V" + (6 * chartSize.height / 320))
                g.selectAll(".domain").attr("d", domainD).attr("stroke-width", tickWidth);
                g.selectAll(".tick")
                    .selectAll("line")
                    .attr("stroke", Color.AXIS)
                    .attr("stroke-width", tickWidth)
                    .attr("y2", 6 * chartSize.height / 320);

                g.selectAll("text")
                    .attr("y", 9 * chartSize.height / 320);

                
                g.selectAll(".tick")
                    .filter(d => parseTime(focus[0].value).toString() !== d.toString())
                    .remove();
                // console.log(parseTime(focus[0].value).toString(), g.select(".tick").node().getBBox(), margin.left)
            });

        // add the y Axis
         svg.append("g")
            .attr("class", "yAxis")
            .call(d3.axisLeft(y))
            .attr("font-family", ENGFONT)
            .attr("transform", `translate(-${8 * chartSize.width / 320},0)`)
            .call(d3.axisLeft(y).ticks(5).tickFormat(function (d) {
                if ((d / 1000000) >= 1) {
                    d = d / 1000000 + "M";
                } else if ((d / 1000) >= 1) {
                    d = d / 1000 + "K";
                }
                return d;
            }))
            .call(g => {
                g.attr("font-size", tickSize);
                g.attr("font-family", ENGFONT);

                if(this.size() === "middle") {
                    g.selectAll(".domain").attr("stroke-width", 1)
                }
                if(this.size() === "small") {
                    g.selectAll(".domain").attr("display", "none")
                    g.selectAll(".tick").select("line").attr("display", "none")
                    g.selectAll(".tick").select("text").attr("display", "none")
                }
                // else {
                //     g.selectAll(".tick")
                //     .filter(function(d, i, list){ return i=== 0 || i === list.length-1})
                //     .select("line")
                //     .remove()
                // };
                let domainD = g.selectAll(".domain").attr("d");
                domainD = domainD.replace("M-6", "M-" + (6 * chartSize.width / 640))
                domainD = domainD.replace("H-6", "H-" + (6 * chartSize.width / 640))
                g.selectAll(".domain").attr("d", domainD).attr("stroke-width", tickWidth);
                g.selectAll(".tick")
                    .select("line")
                    .attr("stroke", Color.AXIS)
                    .attr("stroke-width", tickWidth)
                    .attr("x2", -6 * chartSize.width / 640);

                g.selectAll("text")
                    .attr("x", -9 * chartSize.width / 640);
            });

        if (!hasSeries) {
            svg.append('g')
                .attr("class", "areas")
                .append("path")
                .attr("fill", Color.DEFAULT)
                .attr("opacity", 0.5)
                .attr("d", area_generator(data))
        }

        let lineLayer = svg.append("g").attr('class', 'lineLayer')
        let lineGen = d3.line().curve(d3.curveMonotoneX)
            .x(function (d) {
                return x(parseTime(d[breakdown[0].field]));
            })
            .y(function (d) {
                return y(d[measureName]);
            })
        lineLayer.append('path')
            .attr('d', lineGen(data))
            .attr('stroke', Color.HIGHLIGHT)
            .attr('stroke-width', dotR / 2)
            .attr('fill', 'none')

        //tool tip
        let toolTipX = x(parseTime(focus[0].value))
        let toolTipY;
        
        toolTipY = y(focusValue)
        // let toolTipSpec = {
        //     toolTipWidth: 80, //todo 自适应
        //     toolTipHeight: 30, //todo 自适应
        //     toolTipX,
        //     toolTipY,
        // }

        let toolTipValue = formatNumber(focusValue)
        generateToolTip(toolTipX, toolTipY- dotR * 2.5, toolTipValue, svg, chartSize, annotationSize, "Up", "numerical");
        svg.selectAll(".tooltip").selectAll("text").attr("font-weight", 500)
        lineLayer.append('circle').attr("class", "focusDot")
            .attr('cx', toolTipX)
            .attr('cy', toolTipY)
            .attr('r', this.size() === 'large' ? 10:6)
            .attr('fill', Color.HIGHLIGHT)

        if(this.style() === Style.COMICS){
            let metaphorWidth = width*0.3,
                metaphorHeight = 1.43*metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor17)
                .attr("width", metaphorWidth)
                .attr("height", metaphorHeight);
        
            if(this.size() === Size.WIDE){
                metaphorWidth = width*0.25;
                metaphorHeight = 1.43*metaphorWidth;
                metaphor.attr("width", metaphorWidth)
                    .attr("height", metaphorHeight)
                    .attr("x", width + metaphorWidth*0.06)
                    .attr("y", height - metaphorHeight*0.96);
                svg.select("foreignObject")
                    .attr("x", width/0.8 + 10);
            }else if (this.size() === Size.MIDDLE){
                metaphorWidth = width*0.28;
                metaphorHeight = 1.43*metaphorWidth;

                metaphor.attr("width", metaphorWidth)
                    .attr("height", metaphorHeight)
                    .attr("x", width + metaphorWidth*0.06)
                    .attr("y", height - metaphorHeight*0.96);
            }else{
                metaphor.attr("x", width + metaphorWidth*0.06)
                    .attr("y", height - metaphorHeight*0.96);
            }
        }
        return svg;
    }

    displayExtreme() {
        let factData = this.factdata()
        let measure = this.measure();
        let breakdown = this.breakdown();
        let focus = this.focus();
        // set data
        factData = factData.sort((a, b) => parseTime(a[breakdown[0].field]) - parseTime(b[breakdown[0].field]))

        let data = factData;
        let measureName = measure[0].aggregate === 'count' ? "COUNT" : measure[0].field
        if (focus.length === 0) return
        // for(let f of focus) {
        //     if(f.field !== breakdown[0].field) return 
        // }
        // 只取focus首个
        if(!focus[0].field || focus[0].field !== breakdown[0].field) return 
        if (breakdown[1] && breakdown[1].field) return;

        // set the dimensions and margins of the graph
        let chartSize = { width: this.width(), height: this.height() }
        let { margin, tickSize, annotationSize, tickWidth, dotR } = getSizeBySize(this.size(), chartSize, 'outlier');
        
        
        // get tootltip size
        let focusValueArray = factData.filter(d => {
            return d[focus[0].field] === focus[0].value
        })
        let focusValue = focusValueArray[0][measureName]
        let _tooltip = d3.select(this.container())
                        .append("svg")
                        .attr("class", "testNode")
                        .attr("opacity", 0);
        _tooltip.append("text")
            .text(formatNumber(focusValue)).attr("font-size", annotationSize)
            .attr("font-family", NUMFONT);
                        
        let tooltipHeight =  d3.select(".testNode").select("text").node().getBBox().height;
        let tooltipWidth =  d3.select(".testNode").select("text").node().getBBox().width;
        if( margin.top < tooltipHeight/0.8 + dotR * 2.5 ) { // 30 * chartSize.height / 640
            margin.top = tooltipHeight/0.8 + dotR * 3.5//30 * chartSize.height / 640 * 1.5

        }
        if(factData[0][focus[0].field] === focus[0].value  && margin.left < Math.min(tooltipWidth/0.7/2, (tooltipWidth + 12)/2)) {
            margin.left = Math.ceil( Math.min(tooltipWidth/0.7/2, (tooltipWidth + 12)/2))*1.1;
        }
        if(factData[factData.length-1][focus[0].field] === focus[0].value && margin.right < Math.min(tooltipWidth/0.7/2, (tooltipWidth + 12)/2)) {
            margin.right = Math.ceil( Math.min(tooltipWidth/0.7/2, (tooltipWidth + 12)/2))*1.1;
        }

        
        _tooltip.remove();

        let width = chartSize.width - margin.left - margin.right,
        height = chartSize.height - margin.top - margin.bottom;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .attr("font-family", ENGFONT);
        
        if(this.style() === Style.COMICS) width = (this.size() === Size.LARGE || this.size() === Size.SMALL)? 0.8* width : 0.85 * width;
        
        // set the ranges
        let x = d3.scaleTime()
            .range([0, width])
        // .padding(0.1);
        let y = d3.scaleLinear()
            .range([height, 0]);

        // Scale the range of the data in the domains
        x.domain(d3.extent(data, function (d) {return parseTime(d[breakdown[0].field]); }));
        y.domain([0, d3.max(data, function (d) { return d[measureName]; })]);

        let area_generator = d3.area().curve(d3.curveMonotoneX)
            .x(function (d) {
                return x(parseTime(d[breakdown[0].field]));
            })
            .y0(height)
            .y1(function (d) {
                return y(Math.max(d[measureName], 0));
            })

        // add the x Axis
        let format_TicksCount = formatTicksCount(data[0][breakdown[0].field]);
        let tick_format = formatTick(data[0][breakdown[0].field]);
        let axisX = d3.axisBottom(x).tickFormat(tick_format)
        if(format_TicksCount === d3.timeYear) {
            axisX.ticks(format_TicksCount)
        }
        if(focus.length !== 0) {
            // 如果不在当前的列表里，就添加
            let i;
            for(i=0; i<x.ticks().length; i++) {
                if(x.ticks()[i].toString() === parseTime(focus[0].value).toString()) break;
            }
            if(i === x.ticks().length) {
                let tickValues = x.ticks();
                tickValues.push(parseTime(focus[0].value))
                axisX.tickValues(tickValues)
            }
        }
        svg.append("g")
            .attr("class", "xAxis")
            .attr("transform", "translate(0," + (height) + ")")
            .call(axisX)
            .attr("font-family", ENGFONT)
            .call(g => {
                g.attr("font-size", tickSize);
                g.attr("font-family", ENGFONT);

                let domainD = g.selectAll(".domain").attr("d");
                domainD = domainD.replace("6V", (6 * chartSize.height / 320)+ "V")
                domainD = domainD.replace("V6", "V" + (6 * chartSize.height / 320))
                g.selectAll(".domain").attr("d", domainD).attr("stroke-width", tickWidth);
                g.selectAll(".tick")
                    .selectAll("line")
                    .attr("stroke", Color.AXIS)
                    .attr("stroke-width", tickWidth)
                    .attr("y2", 6 * chartSize.height / 320);

                g.selectAll("text")
                    .attr("y", 9 * chartSize.height / 320);

                
                g.selectAll(".tick")
                    .filter(d => parseTime(focus[0].value).toString() !== d.toString())
                    .remove();
                // console.log(parseTime(focus[0].value).toString(), g.select(".tick").node().getBBox(), margin.left)
            });

        // add the y Axis
         svg.append("g")
            .attr("class", "yAxis")
            .call(d3.axisLeft(y))
            .attr("font-family", ENGFONT)
            // .attr("transform", `translate(-${8 * chartSize.width / 320},0)`)
            .call(d3.axisLeft(y).ticks(5).tickFormat(function (d) {
                if ((d / 1000000) >= 1) {
                    d = d / 1000000 + "M";
                } else if ((d / 1000) >= 1) {
                    d = d / 1000 + "K";
                }
                return d;
            }))
            .call(g => {
                g.attr("font-size", tickSize);
                g.attr("font-family", ENGFONT);

                if(this.size() === "middle") {
                    g.selectAll(".domain").attr("stroke-width", 1)
                }
                if(this.size() === "small") {
                    g.selectAll(".domain").attr("display", "none")
                    g.selectAll(".tick").select("line").attr("display", "none")
                    g.selectAll(".tick").select("text").attr("display", "none")
                }
                // else {
                //     g.selectAll(".tick")
                //     .filter(function(d, i, list){ return i=== 0 || i === list.length-1})
                //     .select("line")
                //     .remove()
                // };
                let domainD = g.selectAll(".domain").attr("d");
                domainD = domainD.replace("M-6", "M-" + (6 * chartSize.width / 640))
                domainD = domainD.replace("H-6", "H-" + (6 * chartSize.width / 640))
                g.selectAll(".domain").attr("d", domainD).attr("stroke-width", tickWidth);
                g.selectAll(".tick")
                    .select("line")
                    .attr("stroke", Color.AXIS)
                    .attr("stroke-width", tickWidth)
                    .attr("x2", -6 * chartSize.width / 640);

                g.selectAll("text")
                    .attr("x", -9 * chartSize.width / 640);
            });

        if (!hasSeries) {
            svg.append('g').lower()
                .attr("class", "areas")
                .append("path")
                .attr("fill", Color.DEFAULT)
                .attr("opacity", 0.5)
                .attr("d", area_generator(data))
        }

        let lineLayer = svg.append("g").attr('class', 'lineLayer')
        let lineGen = d3.line().curve(d3.curveMonotoneX)
            .x(function (d) {
                return x(parseTime(d[breakdown[0].field]));
            })
            .y(function (d) {
                return y(d[measureName]);
            })
        lineLayer.append('path').lower()
            .attr('d', lineGen(data))
            .attr('stroke', Color.HIGHLIGHT)
            .attr('stroke-width', dotR / 2)
            .attr('fill', 'none')

        //tool tip
        let toolTipX = x(parseTime(focus[0].value))
        let toolTipY;
        
        toolTipY = y(focusValue)
        // let toolTipSpec = {
        //     toolTipWidth: 80, //todo 自适应
        //     toolTipHeight: 30, //todo 自适应
        //     toolTipX,
        //     toolTipY,
        // }

        let toolTipValue = formatNumber(focusValue)
        generateToolTip(toolTipX, toolTipY- dotR * 2.5, toolTipValue, svg, chartSize, annotationSize, "Up", "numerical");
        svg.selectAll(".tooltip").selectAll("text").attr("font-weight", 500)
        lineLayer.append('circle').attr("class", "focusDot")
            .attr('cx', toolTipX)
            .attr('cy', toolTipY)
            .attr('r', this.size() === 'large' ? 10:6)
            .attr('fill', Color.HIGHLIGHT)

        if(this.style() === Style.COMICS){
            let metaphorWidth = width*0.3,
                metaphorHeight = 1.43*metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor17)
                .attr("width", metaphorWidth)
                .attr("height", metaphorHeight);
        
            if(this.size() === Size.WIDE){
                metaphorWidth = width*0.25;
                metaphorHeight = 1.43*metaphorWidth;
                metaphor.attr("width", metaphorWidth)
                    .attr("height", metaphorHeight)
                    .attr("x", width + metaphorWidth*0.06)
                    .attr("y", height - metaphorHeight*0.96);
                svg.select("foreignObject")
                    .attr("x", width/0.8 + 10);
            }else if (this.size() === Size.MIDDLE){
                metaphorWidth = width*0.28;
                metaphorHeight = 1.43*metaphorWidth;

                metaphor.attr("width", metaphorWidth)
                    .attr("height", metaphorHeight)
                    .attr("x", width + metaphorWidth*0.06)
                    .attr("y", height - metaphorHeight*0.96);
            }else{
                metaphor.attr("x", width + metaphorWidth*0.06)
                    .attr("y", height - metaphorHeight*0.96);
            }
        }

        this._y = y
        return svg;
    }

    animateDistribution() {
        let svg = this.displayDistribution();
        if (!svg) return;

        /* -------------------------------- init data ------------------------------- */
        let ticks = 10;
        let layback = 0
        let duration = this.duration();
        let factData = this.factdata()
        let breakdown = this.breakdown();
        let measure = this.measure();
        // let focus = this.focus();
        let hasSeries = breakdown[1] && breakdown[1].field ? true :false;
        let measureName = measure[0].aggregate === 'count' ? "COUNT" : measure[0].field

        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            axisFadeIn: {
                duration: 2,
                index: 0
            },
            legendFadeIn: {
                duration: 2,
                index: 1
            }, 
            areaFadeIn: {
                duration: 8,
                index: 1
            }
        }
        let everyTick = duration / ticks;

        let x = this._x;
        let y = this._y;

        let stacked_area_generator = d3.area().curve(d3.curveMonotoneX)
            .x(d => x(parseTime(d.data.x)))
            .y0(d => { 
                let y0=d[0]; 
                return y(Math.max(y0, 0))
            })
            .y1(d => {
                if(d[0] === d[1]) return y(Math.max(d[1], 0)) - 0.065
                else return y(Math.max(d[1], 0))
            })
            
        let initial_stacked_area_generator = d3.area().curve(d3.curveMonotoneX)
            .x(d => x(parseTime(d.data.x)))
            .y0(d => { 
                // let y0=d[0]; 
                // return y(Math.max(y0, 0))
                return y(0)
            })
            .y1(d => {
                // if(d[0] === d[1]) return y(Math.max(d[1], 0)) - 0.065
                // else return y(Math.max(d[1], 0))
                // let y0=d[0] - 0.065; 
                // return y(Math.max(y0, 0))
                return y(0)
            })
        
        let area_generator = d3.area().curve(d3.curveMonotoneX)
            .x(function (d) {
                return x(parseTime(d[breakdown[0].field]));
            })
            .y0(y(0))
            .y1(function (d) {
                return y(Math.max(d[measureName], 0));
            });
        
        let init_area_generator = d3.area().curve(d3.curveMonotoneX)
            .x(function (d) {
                return x(parseTime(d[breakdown[0].field]));
            })
            .y0(y(0))
            .y1(y(0))

        /* ---------------------------- step 0 axisFadeIn --------------------------- */
        let xAxis = svg.selectAll(".xAxis");
        let yAxis = svg.selectAll(".yAxis");


        xAxis.attr("opacity", 0)
            .transition()
            .duration(everyTick * animation.axisFadeIn.duration)
            .attr("opacity", 1);

        yAxis.attr("opacity", 0)
            .transition()
            .duration(everyTick * animation.axisFadeIn.duration)
            .attr("opacity", 1);

        /* --------------------------- step 1 legendFadeIn -------------------------- */
        let legends = svg.selectAll(".legends");

        legends.style("opacity", 0);

        setTimeout(()=>{
            legends.transition()
                    .duration(everyTick * (animation.legendFadeIn.duration + 0.5))
                    .style("opacity", 1)
        }, everyTick * (countTicksBeforeIndex(animation, animation.legendFadeIn.index)-0.5))

        
        /* ---------------------------- step 1 areaFadeIn --------------------------- */
        let areas = svg.selectAll(".areas");
        areas.attr("opacity", 0);
        if(hasSeries) {
            setTimeout(()=>{
                areas.attr("opacity", 1) 
                areas.selectAll(".area")
                    .each(function(d, i) {
                        d3.select(this)
                            .attr("d", initial_stacked_area_generator)
                            .transition()
                            .duration(everyTick * (animation.areaFadeIn.duration + layback))
                            .attr("d", stacked_area_generator)
                    })
            }, everyTick * (countTicksBeforeIndex(animation, animation.areaFadeIn.index)-layback))
    
        } else {
            let data = factData;
            data = data.sort((a, b) => parseTime(a[breakdown[0].field]) - parseTime(b[breakdown[0].field]))

            setTimeout(()=>{
                areas.attr("opacity", 1) 
                areas.selectAll(".areaG")
                    .each(function(d, i) {
                        d3.select(this)
                            .attr("d", init_area_generator(data))
                            .transition()
                            .duration(everyTick * (animation.areaFadeIn.duration + layback))
                            .attr("d", area_generator(data))
                    })
            }, everyTick * (countTicksBeforeIndex(animation, animation.areaFadeIn.index)-layback))
    
        }

    }

    animateTrend() {
        // let svg = this.displayTrend();
        let factData = this.factdata();
        let measure = this.measure();
        let breakdown = this.breakdown();
        let measureName = measure[0].aggregate === 'count' ? "COUNT" : measure[0].field;
        let hasSeries = breakdown[1] && breakdown[1].field ? true : false;
        let moreThan6 = false

        // set data
        let data = factData;
        let stackedData;
        if (hasSeries) {
            let calculateData = d3.nest().key(d => d[breakdown[0].field]).entries(data);
            let categories = Array.from(new Set(data.map(d => d[breakdown[1].field])))
            categories = categories.slice(0, 10)
            // categories =categories.sort(() => .5 - Math.random()).slice(0,10)
            let objList = new Array(calculateData.length)
            for (let i = 0; i < calculateData.length; i++) {
                let obj = {};
                calculateData[i].values.map((d, i) => {
                    obj.x = d[breakdown[0].field]
                    obj[d[breakdown[1].field]] = d[measureName]
                    return obj;
                })
                objList[i] = obj
            }
            // complete the missed data = 0
            for (let k = 0; k < calculateData.length; k++) {
                for (let i = 0; i < categories.length; i++) {
                    if (!objList[k].hasOwnProperty(categories[i])) {
                        objList[k][categories[i]] = 0;
                    }
                }
            }
            objList = objList.sort((a, b) => parseTime(a.x) - parseTime(b.x))
            stackedData = d3.stack()
                .keys(categories)
                (objList)
            stackedData = stackedData.slice(0, 10)
        }

        data = data.sort((a, b) => parseTime(a[breakdown[0].field]) - parseTime(b[breakdown[0].field]))

        // set the dimensions and margins of the graph
        let chartSize = { width: this.width(), height: this.height() };
        let seriesName = hasSeries ? d3.map(stackedData, function(d){return d.key;}).keys() : [];
        // seriesName = seriesName.slice(0, 8)
        let legendRowNum = Math.ceil(seriesName.length / 3);
        const chartMargin = {
            "small":{
                "top": 20,
                "right": 35,
                "bottom": hasSeries? (55 / 4) * legendRowNum + 25 : 25,
                "left": 35
            },
            "middle":{
                "top": 20,
                "right": 45,
                "bottom": hasSeries ? (66 / 4) * legendRowNum + 35:  40,
                "left": 60
            },
            "wide":{
                "top": 20,
                "right": hasSeries?  200 : 50,
                "bottom": 40,
                "left": 70
            },
            "large":{
                "top": 40,
                "right": 75,
                "bottom": hasSeries ? 150 + 50 : 80 + 50,
                "left": 100
            }
        };
        let margin = chartMargin[this.size()];
        let { tickSize, tickWidth, strokeWidth } = getSizeBySize(this.size(), chartSize, 'trend', hasSeries, moreThan6),
            width = chartSize.width - margin.left - margin.right,
            height = chartSize.height - margin.top - margin.bottom;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        if(this.style() === Style.COMICS){
            if(hasSeries || data.length > 7) width *= 0.8;
            else if(this.size() === Size.WIDE){
                height *= 0.85;
            }
        }
        
        // set the ranges
        let x = d3.scaleTime()
            .range([0, width])
        // .padding(0.1);
        let y = d3.scaleLinear()
            .range([height, 0]);

        // if (breakdown[1] && breakdown[1].field) return svg;
        
        // Scale the range of the data in the domains
        x.domain(d3.extent(data, function (d) { return parseTime(d[breakdown[0].field]); }));
        // y.domain([0, d3.max(data, function (d) { return d[measureName]; })]);
        if (hasSeries)
            y.domain([0, d3.max(stackedData[stackedData.length - 1], d => d[1])])
        else
            y.domain([0, d3.max(data, function (d) { return d[measureName]; })]);

        let area_generator = d3.area().curve(d3.curveMonotoneX)
            .x(function (d) {
                return x(parseTime(d[breakdown[0].field]));
            })
            .y0(height)
            .y1(function (d) {
                return y(d[measureName]);
            })
        let stacked_area_generator = d3.area().curve(d3.curveMonotoneX)
            .x(d => x(parseTime(d.data.x)))
            .y0(d => { 
                let y0=d[0]; 
                return y(Math.max(y0, 0))
            })
            .y1(d => {
                if(d[0] === d[1]) return y(Math.max(d[1], 0)) - 0.065
                else 
                    return y(Math.max(d[1], 0))
            })
        // let initialarea = d3.area()
        //     .x(function (d) {
        //         return x(d[breakdown[0].field]);
        //     })
        //     .y0(height)
        //     .y1(height);

        // add the x Axis
        let format_TicksCount = formatTicksCount(data[0][breakdown[0].field]);
        let tick_format = formatTick(data[0][breakdown[0].field]);
        let axisX = d3.axisBottom(x).tickFormat(tick_format)
        if(format_TicksCount === d3.timeYear) {
            axisX.ticks(format_TicksCount)
        } else if(format_TicksCount === d3.timeDay) {
            axisX.ticks(format_TicksCount)
        }

        // legend data init
        
        let seriesNameCopy = [...seriesName];
        if(hasSeries) {
            seriesNameCopy.sort((a, b)=>a.length -b.length)
            seriesName= [];
            let i=0
            while(seriesNameCopy.length) {
                if(i % 2) seriesName.push(seriesNameCopy.shift())
                else seriesName.push(seriesNameCopy.pop());
                i+=1
            }
        }

        // draw axis
        svg.append("g")
            .attr("class", "xAxis")
            .attr("transform", "translate(0," + (height) + ")")
            .call(axisX)
            .call(g => {
                g.attr("font-size", this.size() === 'wide' && seriesName.length > 8 ? tickSize-2 : tickSize);
                g.attr("font-family", ENGFONT);

                let domainD = g.selectAll(".domain").attr("d");
                domainD = domainD.replace("6V", (6 * chartSize.height / 320)+ "V")
                domainD = domainD.replace("V6", "V" + (6 * chartSize.height / 320))
                g.selectAll(".domain").attr("d", domainD).attr("stroke-width", tickWidth);
                g.selectAll(".tick")
                    .selectAll("line")
                    .attr("stroke", Color.AXIS)
                    .attr("stroke-width", tickWidth)
                    .attr("y2", 6 * chartSize.height / 320);

                g.selectAll("text")
                    .attr("y", 9 * chartSize.height / 320);

                if(this.size() === 'large') {
                    // 检查够不够放
                    let _tickWidth = 0;
                    let xRange = x.range()[1]-x.range()[0];
                    g.selectAll(".tick")
                        .each(function(d, i) {
                            let _thisWidth = d3.select(this).node().getBBox().width
                            _tickWidth += _thisWidth
                        });
                    if(_tickWidth > xRange*0.99) { //横的不够放
                        g.selectAll("text")
                            .attr("transform", `translate(-${5},0)rotate(-45)`)
                            .attr("text-anchor", "end");
                        // 检查斜着有没有遮挡
                        let prev = g.select("text").node().getBBox().height;
                        
                        let rotateAble = Math.floor(xRange/ prev) >= g.selectAll(".tick").size();
                        // 如果遮挡 摆回正的
                        if(!rotateAble) {
                            g.selectAll("text")
                                .attr("transform", "")
                                .attr("text-anchor", "middle");
                            removeOverlapX(g, x)
                        }
                    }
                    
                } else removeOverlapX(g, x)
            });;

        // add the y Axis
        svg.append("g")
            .attr("class", "yAxis")
            .call(d3.axisLeft(y))
            .attr("font-family", ENGFONT)
            // .attr("transform", `translate(-${8 * chartSize.width / 320},0)`)
            .call(d3.axisLeft(y).ticks(5).tickFormat(function (d) {
                if ((d / 1000000) >= 1) {
                    d = d / 1000000 + "M";
                } else if ((d / 1000) >= 1) {
                    d = d / 1000 + "K";
                }
                return d;
            }))
            .call(g => {
                g.attr("font-size", this.size() === 'wide' && seriesName.length > 8 ? tickSize-2 : tickSize);
                g.attr("font-family", ENGFONT);

                let domainD = g.selectAll(".domain").attr("d");
                domainD = domainD.replace("M-6", "M-" + (6 * chartSize.height / 320))
                domainD = domainD.replace("H-6", "H-" + (6 * chartSize.height / 320))
                g.selectAll(".domain").attr("d", domainD).attr("stroke-width", tickWidth);
                g.selectAll(".tick")
                    .select("line")
                    .attr("stroke", Color.AXIS)
                    .attr("stroke-width", tickWidth)
                    .attr("x2", -6 * chartSize.height / 320);
                g.selectAll("text")
                    .attr("x", -9 * chartSize.height / 320);
                
                // if(this.size() === "middle") {
                //     g.selectAll(".domain").attr("stroke-width", 1)
                // }
                if(this.size() === "small") {
                    g.selectAll(".domain").attr("display", "none")
                    g.selectAll(".tick").select("line").attr("display", "none")
                    g.selectAll(".tick").select("text").attr("display", "none")
                }
            });

        // axis_y
        //     .selectAll('.tick')
        //     .append('line')
        //     .attr("x2", width)
        //     .attr('stroke', Color.DASHLINE)
        //     .attr("stroke-width", tickWidth / 2)
        //     .attr("transform", `translate(${8* chartSize.width / 320},0)`)

        // append legend 
        
        if(hasSeries) {
            if(this.size() === "wide") {
                svg.append("foreignObject")
                    .attr("x", chartSize.width - margin.left - margin.right + 30)
                    .attr("y", seriesName.length > 8? -margin.top * 0.5 : 0)
                    .attr("width", margin.right - 10)
                    .attr("height", height + margin.bottom * 0.6 + margin.top * 0.5)
                    .append("xhtml:div")
                    .attr("class", "legends")
                    .style("display", "flex")
                    .style("flex-direction", "column")
                    .style("flex-wrap", "wrap")
                    .style("align-content", "space-around")
                    .style("justify-content", "space-around")
                    .style("height", Math.round(seriesName.length > 8? height + margin.bottom * 0.6 + margin.top * 0.5 : height + margin.bottom * 0.3)+"px")
                    .selectAll(".legend")
                    .data(seriesName)
                    .enter()
                    .append("xhtml:div")
                    .attr("class","legend")
                    .style("line-height",  0)
                    .style("margin-right", 5 * chartSize.width/320 + "px")
                    .each(function(d, i) {
                        let legend = d3.select(svg.selectAll(".legend").nodes()[i]).append("svg")
                        legend.append("rect")
                            .attr("fill", d => Color.CATEGORICAL[seriesName.indexOf(d)])
                            .attr("width", seriesName.length > 8 ? tickSize-2 : tickSize)
                            .attr('height', seriesName.length > 8 ? tickSize-2 : tickSize)
                            .attr("rx", 1.5 * chartSize.width / 320 )
                            .attr("ry", 1.5 * chartSize.width / 320 )
                        legend.append("text")
                            .attr("x", tickSize+3)
                            .text(d => d.length > 11 ? d.substring(0, 10)+"…": d)
                            .attr("font-size", seriesName.length > 8 ? tickSize-2 : tickSize)
                            .attr("font-family", ENGFONT)
                            .attr("dominant-baseline", "hanging");
                        legend.attr("width", Math.round(legend.node().getBBox().width))
                        legend.attr("height", Math.floor(legend.node().getBBox().height))
                        
                        
                    })
            }
            else {
                let thisSize = this.size();
                let xAxisHeightOffset
                let isRotate = svg.selectAll(".xAxis").select("text").attr("transform") ? true : false
                if(this.size() === 'small')
                    xAxisHeightOffset = svg.selectAll(".xAxis").node().getBBox().height * 1.4
                else if(this.size() === 'middle')
                    xAxisHeightOffset = svg.selectAll(".xAxis").node().getBBox().height * 1.4
                else {
                    if(isRotate) xAxisHeightOffset = svg.selectAll(".xAxis").node().getBBox().height * 1.2 
                    else xAxisHeightOffset = svg.selectAll(".xAxis").node().getBBox().height * 2
                }
                svg.append("foreignObject")
                    .attr("x", ()=>{
                        if (this.size() === 'small')  return -margin.left * 0.5;
                        else if(this.size() === 'middle') return -margin.left * 0.4
                        else return margin.right - margin.left//0
                    })
                    .attr("y", height + xAxisHeightOffset )
                    .attr("width", ()=>{
                        if (this.size() === 'small')  return  chartSize.width - margin.left - margin.right + margin.left * 0.5 * 2;
                        else if(this.size() === 'middle') return  chartSize.width - margin.left - margin.right + margin.left * 0.4 * 2;
                        else return chartSize.width - margin.left - margin.right - (margin.right - margin.left);
                    } )
                    .attr("height", (margin.bottom - xAxisHeightOffset))
                    .append("xhtml:div")
                    .attr("class", "legends")
                    .style("display", "grid")
                    .style("grid-template-columns", `repeat(${Math.min(seriesName.length, thisSize === 'small' ? 3 : 3)}, auto)`)
                    .style("grid-template-rows", `repeat(4, min-content)`)
                    .style("grid-auto-flow", "row")
                    .style("justify-content", "space-between")
                    .style("align-content", "center")
                    .style("align-items", "end")
                    .style("padding-top", "1px")
                    .style("height", Math.round((margin.bottom - xAxisHeightOffset))+"px")
                    .selectAll(".legend")
                    .data(seriesName)
                    .enter()
                    .append("xhtml:div")
                    .attr("class","legend")
                    .style("line-height", 0)
                    // .style("margin-right", 5 * chartSize.width/320 + "px")
                    .each(function(d, i) {

                        let legend = d3.select(svg.selectAll(".legend").nodes()[i]).append("svg")
                        legend.append("rect")
                            .attr("fill", d => Color.CATEGORICAL[seriesName.indexOf(d)])
                            .attr("width", thisSize === 'large' ? tickSize + 1 : tickSize)
                            .attr('height', thisSize === 'large' ? tickSize + 1 : tickSize)
                            .attr("rx", 1.5 * chartSize.width / 320 )
                            .attr("ry", 1.5 * chartSize.width / 320 )
                        // .attr("cy", -5);
                        legend.append("text")
                            .attr("x", thisSize === 'large' ? tickSize + 2 : tickSize+1)
                            .text(d => {
                                let text = d
                                if(thisSize === 'small') { // small
                                    if(text.length > 8) text = text.substring(0, 7) + "…"
                                } else if(thisSize === 'middle' ) { //middle
                                    if(text.length > 10) text = text.substring(0, 9) + "…"
                                } else {
                                    if(text.length> 10) text = text.substring(0, 9) + "…"
                                    // if(text.length>15) text = text.substring(0, 14) + "…"
                                }
                                return text
                            })
                            .attr("font-size", thisSize === "large" ? tickSize + 2 : tickSize + 1)
                            .attr("font-family", ENGFONT)
                            .attr("dominant-baseline", "hanging");
                        legend.attr("width", Math.round(legend.node().getBBox().width))
                        legend.attr("height", Math.floor(legend.node().getBBox().height))
                        
                    })

            }
            
        }
        if (!hasSeries) {
            svg.append('g')
                .lower()
                .attr("class", "areas")
                .append("path")
                .attr("fill", Color.DEFAULT)
                .attr("d", area_generator(data));

            let ret = getLeastSquares(factData.map(d => x(parseTime(d[breakdown[0].field]))),
            factData.map(d => y(d[measureName])));

            let x1 = 0,
                x2 = width;
            //regression in range, can not out of content
            let x_ymin = (height - ret.b) / ret.m,
                x_ymax = (0 - ret.b) / ret.m;

            if (x_ymin > x_ymax) {
                const i = x_ymin;
                x_ymin = x_ymax;
                x_ymax = i;
            }
            x1 = x1 < x_ymin ? x_ymin : x1;
            x2 = x2 > x_ymax ? x_ymax : x2;
            if (ret.m === 0) x1 = 0;
            let y1 = ret.m * x1 + ret.b,
                y2 = ret.m * x2 + ret.b;
            if (ret.m === -Infinity) {
                x1 = x2;
                y1 = 0;
                y2 = height;
            }
            let trendlineLayer = svg.append("g")
                .attr("class", "trendlineLayer");
            
            trendlineLayer
                .append("line")
                .datum(data)
                .attr("class", d => "trendline " + d.key)
                .attr("x1", x1)
                .attr("x2", x2)
                .attr("y1", y1 - 100)
                .attr("y2", y2 - 100)
                .attr("stroke", Color.HIGHLIGHT)
                .attr("stroke-width", strokeWidth)
                .attr("stroke-dasharray", `${strokeWidth*2}, ${strokeWidth}`);

            // add triangle
            let finalPosition = `${x2}, ${y2 - 100}`
            let f_x = x2,
                f_y = y2 - 100,
                s_x = x1,
                s_y = y1 - 100;
            function getTanDeg(tan) {
                var result = Math.atan(tan) / (Math.PI / 180);
                result = Math.round(result);
                return result;
            }
            let slope = (f_y - s_y) / (f_x - s_x)
            let deg;
            if (getTanDeg(slope) < 0) {
                deg = 90-Math.abs(getTanDeg(slope));
            } else {
                deg = 90+getTanDeg(slope);
            }
            
            trendlineLayer.append("path")
                .attr("class", "triangle")
                .attr("transform", "translate(" + finalPosition + ")rotate(" + deg + ")")
                .attr("d", d3.symbol().type(d3.symbolTriangle).size(0.16 * height))
                .attr("fill", Color.HIGHLIGHT);

            // let lineLayer = svg.append("g").attr('id', 'lineLayer')
            // let lineGen = d3.line().curve(d3.curveMonotoneX)
            //     .x(function (d) {
            //         return x(parseTime(d[breakdown[0].field]));
            //     })
            //     .y(function (d) {
            //         return y(d[measureName]);
            //     })
            // lineLayer.append('path')
            //     .attr('d', lineGen(data))
            //     .attr('stroke', Color.HIGHLIGHT)
            //     .attr('stroke-width', strokeWidth)
            //     .attr('fill', 'none')
            //     .attr("stroke-dasharray", `${strokeWidth*2}, ${strokeWidth}`);;
        } else {
            svg.append('g')
            .lower()
            .attr("class", "areas")
            .selectAll("path")
            .data(stackedData)
            .join("path")
            .attr('id', ({ key }) => 'series_' + key.replace(/\s/g, ""))
            .attr("fill", d=>Color.CATEGORICAL[seriesName.indexOf(d.key)])//({ index }) => Color.CATEGORICAL[index])
            .attr("class", "area")
            // .attr("d", stack_initialarea)
            // .transition()
            // .duration(4000)
            .attr("d", stacked_area_generator)
        }

        if(this.style() === Style.COMICS){
            if(hasSeries){
                let metaphorWidth = width*0.34,
                metaphorHeight = 1.25*metaphorWidth;

                let metaphor = svg.append("image")
                    .attr('xlink:href', metaphor2);
            
                if(this.size() === Size.WIDE){
                    metaphorWidth = width*0.34;
                    metaphorHeight = 1.25*metaphorWidth;
                }else if (this.size() === Size.MIDDLE){
                    metaphorWidth = width*0.32;
                    metaphorHeight = 1.25*metaphorWidth;
                }
                
                metaphor.attr("width", metaphorWidth)
                    .attr("height", metaphorHeight)
                    .attr("x", width + metaphorWidth*0.12)
                    .attr("y", height - metaphorHeight*0.96);
            }else{
                //points
                // let filterPoints = [];
                // svg.selectAll(".tick").each(function(d){
                //     let item = factData.find(i => parseTime(i[breakdown[0].field]).getTime() === d.getTime());
                //     filterPoints.push(item);
                // });
                let filterPoints = data;
                if(filterPoints.length > 7){//too much point
                    //draw dash line
                    let x0 = x(parseTime(filterPoints[0][breakdown[0].field])),
                        x1 = x(parseTime(filterPoints.slice(-1)[0][breakdown[0].field])),
                        y0 = y(filterPoints[0][measureName]),
                        y1 = y(filterPoints.slice(-1)[0][measureName]),
                        x2 = x1 + width*0.14,
                        y2 = (x2 - x1) * (y1-y0)/(x1-x0) + y1;
                    let line_m = svg.append('line')
                        .attr("x1", x1)
                        .attr("x2", x2)
                        .attr("y1", y1)
                        .attr("y2", y2)
                        .attr("stroke", Color.DASHLINE)
                        .attr("stroke-width", strokeWidth)
                        .attr("stroke-dasharray", `${strokeWidth*2}, ${strokeWidth}`);
                    svg.node().prepend(line_m.node());

                    let metaphorWidth = width*0.26,
                        metaphorHeight = 1.24*metaphorWidth;

                    let metaphor = svg.append("image")
                        .attr('xlink:href', metaphor6);
            
                    if(this.size() === Size.WIDE){
                        metaphorWidth = width*0.20;
                        metaphorHeight = 1.24*metaphorWidth;
                    }else if (this.size() === Size.MIDDLE || this.size() === Size.SMALL){
                        metaphorWidth = width*0.24;
                        metaphorHeight = 1.24*metaphorWidth;
                    }
                    
                    metaphor.attr("width", metaphorWidth)
                        .attr("height", metaphorHeight)
                        .attr("x", x2 - metaphorWidth*0.06)
                        .attr("y", y2 - metaphorHeight*0.06);
                    
                }else{
                    const metaphorWidth7 = width/(filterPoints.length-1)*0.6,
                        metaphorWidth8 = metaphorWidth7 / 1.14;
                    const metaphorHeight7 = metaphorWidth7*0.95;
                    const metaphorHeight8 = metaphorWidth8*1.2;

                    for(let i=1; i<filterPoints.length; i++){
                        let middleX = (x(parseTime(filterPoints[i][breakdown[0].field])) + x(parseTime(filterPoints[i-1][breakdown[0].field])))/2;
                        let middleY = (y(filterPoints[i][measureName]) + y(filterPoints[i-1][measureName]))/2;
                        if(filterPoints[i][measureName] - filterPoints[i-1][measureName] > 0){//up
                            svg.append("image")
                                .attr('xlink:href', metaphor8)
                                .attr("width", metaphorWidth8)
                                .attr("height", metaphorHeight8)
                                .attr("x", middleX - metaphorWidth8*0.7)
                                .attr("y", middleY - metaphorHeight8*0.96);
                        }else{//down
                            svg.append("image")
                                .attr('xlink:href', metaphor7)
                                .attr("width", metaphorWidth7)
                                .attr("height", metaphorHeight7)
                                .attr("x", middleX - metaphorWidth7*0.5)
                                .attr("y", middleY - metaphorHeight7*1);
                        }
                    }
                }
                //center居中
                svg.attr("transform", "translate(" + ((this.width() - svg.node().getBBox().width)/2 - svg.node().getBBox().x)  + "," + ((this.height() - svg.node().getBBox().height)/2 - svg.node().getBBox().y) + ")"); 
            }
        }
        if (!svg) return;

        /* -------------------------------- init data ------------------------------- */
        let ticks = 10;
        let layback = 0
        let duration = this.duration();

        // let breakdown = this.breakdown();
        // let hasSeries = breakdown[1] && breakdown[1].field ? true : false;
        // let moreThan6 = false
        // set the dimensions and margins of the graph
        // let chartSize = { width: this.width(), height: this.height() }
        // let { margin } = getSizeBySize(this.size(), chartSize, 'distribution', hasSeries, moreThan6),
        //     width = chartSize.width - margin.left - margin.right,
        //     height = chartSize.height - margin.top - margin.bottom;

        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            axisFadeIn: {
                duration: 1.5,
                index: 0
            },
            legendFadeIn: {
                duration: 1.5,
                index: 1
            },
            areaSlide: {
                duration: hasSeries ? 7 : 5,
                index: 2
            }, 
            trendDraw: {
                duration: 2,
                index: 3
            }
        }
        let everyTick = duration / ticks;

        /* ---------------------------- step 0 axisFadeIn --------------------------- */
        let xAxis = svg.selectAll(".xAxis");
        let yAxis = svg.selectAll(".yAxis");


        xAxis.attr("opacity", 0)
            .transition()
            .duration(everyTick * animation.axisFadeIn.duration)
            .attr("opacity", 1);

        yAxis.attr("opacity", 0)
            .transition()
            .duration(everyTick * animation.axisFadeIn.duration)
            .attr("opacity", 1);

        /* --------------------------- step 1 legendFadeIn -------------------------- */
        let legends = svg.selectAll(".legends");

        legends.style("opacity", 0);

        setTimeout(()=>{
            legends.transition()
                    .duration(everyTick * (animation.legendFadeIn.duration + 0.5))
                    .style("opacity", 1)
        }, everyTick * (countTicksBeforeIndex(animation, animation.legendFadeIn.index)-0.5))

            
        /* ---------------------------- step 2 areaSlide ---------------------------- */
        let areas = svg.selectAll(".areas");
        // defs
        let uuid1 = uuidv4();
        areas.attr('id', 'areasClip')
                .attr('clip-path', 'url(#clip_areasGraph_'+uuid1+')');
        areas.append('defs')
                .attr('class', 'trend_defs')
                .append('clipPath')
                .attr('id', 'clip_areasGraph_'+uuid1)
                .append('rect')
                .attr('x', 0)
                .attr('width', 0)
                .attr('height', height);

        let uuid2 = uuidv4();
        let line = svg.selectAll("#lineLayer");
        line.attr('clip-path', 'url(#clip_trendline_'+uuid2+')');

        line.append('defs')
                .attr('class', 'trendline_defs')
                .append('clipPath')
                .attr('id', 'clip_trendline_'+uuid2)
                .append('rect')
                .attr('x', 0)
                .attr('width', 0)
                .attr('height', height);
        
        setTimeout(()=>{
            areas.select("#clip_areasGraph_"+uuid1+" rect")
                .attr('width',0)
                .transition()
                .duration(everyTick * (animation.areaSlide.duration + layback))
                .ease(d3.easeLinear)
                .attr('width', width);

            line.select("#clip_trendline_"+uuid2+" rect")
                .attr('width',0)
                .transition()
                .duration(everyTick * (animation.areaSlide.duration + layback))
                .ease(d3.easeLinear)
                .attr('width', width);

        }, everyTick * (countTicksBeforeIndex(animation, animation.areaSlide.index) - layback))

        if(!hasSeries) {
            let trendSVG = svg.select(".trendlineLayer");
            let uuid = uuidv4();
        trendSVG.attr("id", "trendSVGClip")
            .attr("clip-path", "url(#clip_trend_"+uuid+")");

        let defsX = trendSVG.node().getBBox().x,
            defsY = trendSVG.node().getBBox().y,
            defsHeight = trendSVG.node().getBBox().height,
            defsWidth = trendSVG.node().getBBox().width;

        trendSVG.append("defs")
            .attr("class", "trend_defs")
            .append("clipPath")
            .attr("id", "clip_trend_"+uuid)
            .append("rect")
            .attr("x", defsX - 10)
            .attr("y", defsY - 10)
            .attr("width", 0)
            .attr("height", defsHeight + 20);

        setTimeout(() => {
            trendSVG.select("#clip_trend_"+uuid+" rect")
                .attr("width", 0)
                .transition()
                .duration(everyTick * (animation.trendDraw.duration))
                .ease(d3.easeLinear)
                .attr("width", defsWidth + 10)
        }, everyTick * countTicksBeforeIndex(animation, animation.trendDraw.index))
        }

    }

    animateOutlier() {
        let svg = this.displayOutlier();
        if (!svg) return;
        /* -------------------------------- init data ------------------------------- */
        let ticks = 10;
        let layback = 0
        let duration = this.duration();
        let focus = this.focus();


        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            axisFadeIn: {
                duration: 2,
                index: 0
            },
            areaFadeIn: {
                duration: 2,
                index: 1
            },
            tooltipFadeIn: {
                duration: 6,
                index: 2
            },
        }
        let everyTick = duration / ticks;

        // remove tooltip
        svg.selectAll('.tooltip').remove();

        /* ---------------------------- step 0 axisFadeIn --------------------------- */
        let xAxis = svg.selectAll(".xAxis");
        let yAxis = svg.selectAll(".yAxis");


        xAxis.attr("opacity", 0)
            .transition()
            .duration(everyTick * animation.axisFadeIn.duration)
            .attr("opacity", 1);

        yAxis.attr("opacity", 0)
            .transition()
            .duration(everyTick * animation.axisFadeIn.duration)
            .attr("opacity", 1);

        xAxis.select(".tick").attr("opacity", 0);

        /* ---------------------------- step 1 areaFadeIn --------------------------- */
        let areas = svg.selectAll(".areas");
        areas.attr("opacity", 0);
        setTimeout(()=>{
            areas.transition()
                .duration(everyTick * (animation.areaFadeIn.duration + layback) )
                .attr("opacity", 1)
        }, everyTick * (countTicksBeforeIndex(animation, animation.areaFadeIn.index) - layback));

        let lineLayer = svg.selectAll(".lineLayer")
        let trendLine = lineLayer.selectAll("path");
        

        trendLine.attr("opacity", 0);
        
        setTimeout(()=>{
            trendLine.transition()
                .duration(everyTick * (animation.areaFadeIn.duration + layback) )
                .attr("opacity", 1);
        }, everyTick * (countTicksBeforeIndex(animation, animation.areaFadeIn.index) - layback));

        /* ----------------------------- step 3 dotPopUp ---------------------------- */   
        // show tick
        let tick = xAxis.selectAll('.tick');
        tick.attr("opacity", 0);

        let dotFocus = lineLayer.selectAll("circle");
        dotFocus.attr("opacity", 0);

        /* -------------------------- step 4 tooltipFadeIn -------------------------- */
        let tooltip = svg.selectAll(".tooltip");
        tooltip.attr("opacity", 0);
        let circleR = dotFocus.attr("r") * 2,
            circleX = dotFocus.attr("cx"),
            circleY = dotFocus.attr("cy");
        setTimeout(()=>{
            
            let circle_data = {
                    "x": parseFloat(circleX),
                    "y": parseFloat(circleY),
                    "startAngle":0,
                    "endAngle": 2 * Math.PI
                };
            let arc = d3.arc()
                .innerRadius(circleR * 0.9)
                .outerRadius(circleR);

            svg.append("path")
                    .datum(circle_data)
                    .attr("class", "animation")
                    .attr("stroke", Color.ANNOTATION)
                    .attr("fill", Color.ANNOTATION)
                    .attr("x", circleX)
                    .attr("y", circleY)
                    .attr("transform", "translate("+ circleX + "," + circleY + ")")
                    .attr("d", arc)
                    .transition()
                    .duration(everyTick * animation.tooltipFadeIn.duration * 0.5)
                    .attrTween('d', function(d) {
                        var i = d3.interpolate(d.startAngle, d.endAngle);
                        return function(t) {
                            d.endAngle = i(t);
                            return arc(d);
                        }
                    });
            
        }, everyTick * (countTicksBeforeIndex(animation, animation.tooltipFadeIn.index) ));

        let fontSize = xAxis.attr("font-size");
        svg.append("text")
            .attr("fill", Color.ANNOTATION)
            .attr("font-size", fontSize)
            .attr("x", circleX)
            .attr("y", circleY - circleR * 2)
            .attr("dominant-baseline", "baseline")
            .attr("text-anchor", "middle")
            .text(focus[0].value)
            .attr("fill-opacity", 0)
            .transition()
            .duration(everyTick * animation.tooltipFadeIn.duration)
            .delay(everyTick * countTicksBeforeIndex(animation, animation.tooltipFadeIn.index))
            .attr("fill-opacity", 1)

    }

    animateExtreme() {
        let svg = this.displayExtreme();
        if (!svg) return;
        /* -------------------------------- init data ------------------------------- */
        let ticks = 10;
        let layback = 0
        let duration = this.duration();


        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            axisFadeIn: {
                duration: 1,
                index: 0
            },
            areaFadeIn: {
                duration: 1,
                index: 1
            },
            refLineDraw: {
                duration: 2,
                index: 2
            },
            xTickShow: {
                duration: 2,
                index: 2
            },
            tooltipFadeIn: {
                duration: 2,
                index: 3
            },
            dotPopUp : {
                duration: 4,
                index: 4
            }
        }
        let everyTick = duration / ticks;

        /* ---------------------------- step 0 axisFadeIn --------------------------- */
        let xAxis = svg.selectAll(".xAxis");
        let yAxis = svg.selectAll(".yAxis");


        xAxis.attr("opacity", 0)
            .transition()
            .duration(everyTick * animation.axisFadeIn.duration)
            .attr("opacity", 1);

        yAxis.attr("opacity", 0)
            .transition()
            .duration(everyTick * animation.axisFadeIn.duration)
            .attr("opacity", 1);

        /* ---------------------------- step 1 areaFadeIn --------------------------- */
        let areas = svg.selectAll(".areas");
        areas.attr("opacity", 0);
        setTimeout(()=>{
            areas.transition()
                .duration(everyTick * (animation.areaFadeIn.duration + layback) )
                .attr("opacity", 1)
        }, everyTick * (countTicksBeforeIndex(animation, animation.areaFadeIn.index) - layback));

        let lineLayer = svg.selectAll(".lineLayer")
        let trendLine = lineLayer.selectAll("path");
        

        trendLine.attr("opacity", 0);
        
        setTimeout(()=>{
            trendLine.transition()
                .duration(everyTick * (animation.areaFadeIn.duration + layback) )
                .attr("opacity", 1);
        }, everyTick * (countTicksBeforeIndex(animation, animation.areaFadeIn.index) - layback));

        /* --------------------------- step 2 refLineDraw --------------------------- */
        let dotFocus = lineLayer.selectAll("circle");
        dotFocus.attr("opacity", 0);

        let xOrigin = dotFocus.attr("cx"),
            yOrigin = dotFocus.attr("cy");
        let xTick = xAxis.selectAll(".tick");
        xTick.select("text").attr("fill", Color.ANNOTATION)
            xTick.attr("opacity", 0)
        setTimeout(()=>{
            dotFocus.attr("opacity", 1)
            svg.selectAll(".lineLayer").append("line").lower()
                .attr("x1", xOrigin)
                .attr("y1", yOrigin)
                .attr("x2", xOrigin)
                .attr("y2", yOrigin)
                .attr("stroke", Color.DASHLINE)
                .attr('stroke-dasharray', '5,5')
                .attr("stroke-width", xAxis.selectAll(".domain").attr("stroke-width"))
                .transition()
                .duration(everyTick * animation.refLineDraw.duration)
                .attr("x2", xOrigin)
                .attr("y2", this._y(0));
            
            xTick.transition()
                .duration(everyTick * animation.tooltipFadeIn.duration)
                .attr("opacity", 1)
        },  everyTick * (countTicksBeforeIndex(animation, animation.refLineDraw.index)))


        /* -------------------------- step 3 tooltipFadeIn -------------------------- */
        let tooltip = svg.selectAll(".tooltip");
        tooltip.attr("opacity", 0);

        
        setTimeout(()=>{
            tooltip.transition()
                    .duration(everyTick * animation.tooltipFadeIn.duration)
                    .attr("opacity", 1)
            
            
        }, everyTick * (countTicksBeforeIndex(animation, animation.tooltipFadeIn.index) ))


        /* ----------------------------- step 4 dotPopUp ---------------------------- */   
        

        let originalR = dotFocus.attr("r");
        let shortest = 150
        setTimeout(() => {
            let count = 0;
            dotFocus.attr("opacity", 1);
            count += shortest*2;
            dotFocus.transition()
                    .duration(shortest)
                    .attr("r", originalR * 1.5)
                    .transition()
                    .duration(shortest)
                    .attr("r", originalR);

            let poping = setInterval(()=>{
                if(count >= everyTick * animation.dotPopUp.duration) {
                    clearInterval(poping)
                }
                dotFocus.transition()
                        .duration(shortest)
                        .attr("r", originalR * 1.5)
                        .transition()
                        .duration(shortest)
                        .attr("r", originalR);
                count += shortest*2;

            }, shortest * 2)

        }, everyTick * (countTicksBeforeIndex(animation, animation.dotPopUp.index) - layback));


    }
}


let getSizeBySize = (size, chartSize, fact, hasSeries=false, moreThan6=false) => {
    let tickSize, annotationSize, tickWidth, dotR, strokeWidth;
    
    switch (size) {
        case "small":
            tickSize = 10;
            annotationSize = 16;
            tickWidth = 1.5;
            dotR = 5;
            strokeWidth = 2;
            break;
        case "middle":
            tickSize = 13;
            annotationSize = 20;
            tickWidth = 2;
            dotR = 5;
            strokeWidth = 3;
            break;
        case "wide":
            tickSize = 16;
            annotationSize = 26;
            dotR = 7;
            tickWidth = 2;
            strokeWidth = 3;
            break;
        case "large":
        default:
            tickSize = 20;
            annotationSize = 40;
            tickWidth = 3;
            dotR = 10;
            strokeWidth = 3;
            break;
    }
    const chartMargin = {
        "small":{
            "top": fact==='outlier' ? 20 : 10,
            "right": 25,
            "bottom": hasSeries? 60 : 25,
            "left": 25
        },
        "middle":{
            "top": fact==='outlier' ? 10 : 10,
            "right": 40,
            "bottom": hasSeries ? 80 : 40,
            "left": 50
        },
        "wide":{
            "top": 12,
            "right": hasSeries?  160 : 50,
            "bottom": 40,
            "left": 70
        },
        "large":{
            "top": fact==='outlier' ? 40 : 40,
            "right": 50,
            "bottom": hasSeries ? 150 + 50 : 80,
            "left": 80
        }
    };
    let margin = chartMargin[size];
    return { chartSize: chartSize, margin: margin, tickSize: tickSize, annotationSize: annotationSize, tickWidth, dotR, strokeWidth}
}
const removeOverlapX = (g, x) => {
    // get tick width;
    let tickWidth = 0;
    g.selectAll(".tick")
        .each(function(d, i) {
            let _thisWidth = d3.select(this).node().getBBox().width
            if(tickWidth < _thisWidth) tickWidth = _thisWidth;
        });
    tickWidth = tickWidth * 1.5;
    let tickCount = g.selectAll(".tick").size();
    let xAxisWidth = x.range()[1] - x.range()[0];
    
    if(x.range()[0] === 0) xAxisWidth = xAxisWidth * 0.8;
    else xAxisWidth = xAxisWidth * 0.9;
    let possibleTickCount =  Math.round((xAxisWidth) / tickWidth) + 1;
    let enough = tickCount * tickWidth >= xAxisWidth ? false : true;
    let largestInterval = Math.floor((tickCount%2? tickCount:tickCount-1) / (possibleTickCount - 1));
    // TODO 只有一个tick 显示首尾 但是如何避免真的两个都放不下呢？
    if(possibleTickCount === 1 && 2 * tickWidth < xAxisWidth) {
        let size = g.selectAll(".tick").size()
        g.selectAll(".tick")
        .each(function(d, i) {
            if(!enough &&  (i !== 0 && i !== size-1)) {
                this.remove();
            } 
        })
    } else {
        if((g.selectAll(".tick").size()-1) % Math.floor(tickCount/possibleTickCount) ===0) {
            // 最后一个保证会被显示
            // 间隔少
            g.selectAll(".tick")
            .each(function(d, i) {
                if(!enough && i % Math.floor(tickCount/possibleTickCount) !== 0) {
                    this.remove();
                } 
            })
        } else {
            // 最后一个不保证会被显示
            // 间隔尽量长少
            g.selectAll(".tick")
                .each(function(d, i) {
                    if(!enough && i % largestInterval !== 0 ) { 
                        this.remove();
                        // d3.select(this).attr("opacity", 0)
                    } 
                })
        }
        
    }
}
// const removeOverlapX = (g, x) => {
//     // get tick width;
//     let tickWidth = 0
//     g.selectAll(".tick")
//         .each(function(d, i) {
//             let _thisWidth = d3.select(this).node().getBBox().width
//             if(tickWidth < _thisWidth) tickWidth = _thisWidth;
//         });

//     tickWidth = tickWidth * 1.4;
//     // firstTick.getBBox().width * 1.35;

//     let tickCount = g.selectAll(".tick").size();

//     let xAxisWidth = x.range()[1] - x.range()[0];
//     let possibleTickCount =  Math.floor((xAxisWidth) / tickWidth) + 1;
//     let enough = tickCount * tickWidth >= xAxisWidth ? false : true

//     g.selectAll(".tick")
//         .each(function(d, i) {
//             if(!enough &&  i % Math.floor(tickCount/possibleTickCount) !== 0) {
//                 this.remove();
//             }
//         })

// }

const getLeastSquares = (X, Y) => {
    let ret = {}

    let sumX = 0
    let sumY = 0
    let sumXY = 0
    let sumXSq = 0
    let N = X.length

    for (let i = 0; i < N; ++i) {
        sumX += X[i]
        sumY += Y[i]
        sumXY += X[i] * Y[i]
        sumXSq += X[i] * X[i]
    }

    ret.m = ((sumXY - sumX * sumY / N)) / (sumXSq - sumX * sumX / N)
    ret.b = sumY / N - ret.m * sumX / N

    return ret;
}


const countTicksBeforeIndex = (animation, index) => {
    let count = 0;
    let visited = []
    for (let key in animation) {
        if(animation[key].index < index && visited.indexOf(animation[key].index) === -1) {
            count += animation[key].duration
            visited.push(animation[key].index)
        };
    }
    return count
}

const parseTime = (date) => {
    if (d3.timeParse("%Y-%m-%d")(date)) 
        return d3.timeParse("%Y-%m-%d")(date);
    else if (d3.timeParse("%Y/%m/%d")(date)) 
        return d3.timeParse("%Y/%m/%d")(date);
    else if (d3.timeParse("%Y-%m")(date)) 
        return d3.timeParse("%Y-%m")(date);
    else if (d3.timeParse("%Y/%m")(date)) 
        return d3.timeParse("%Y/%m")(date);
    else if (d3.timeParse("%Y")(date))
        return d3.timeParse("%Y")(date);
    else return date
} 
const formatTick = (date) => {
    if (d3.timeParse("%Y-%m-%d")(date)) 
        return d3.timeFormat("%Y-%-m-%-d");
    else if (d3.timeParse("%Y/%m/%d")(date))
        return d3.timeFormat("%Y/%-m/%-d")
    else if (d3.timeParse("%Y-%m")(date))
        return d3.timeFormat("%Y-%m")
    else if (d3.timeParse("%Y/%m")(date))
        return d3.timeFormat("%Y/%m")
    else if (d3.timeParse("%Y")(date))
        return d3.timeFormat("%Y")
}

const formatTicksCount = (date) => {
    if (d3.timeParse("%Y-%m-%d")(date)) 
        return d3.timeDay
    else if (d3.timeParse("%Y/%m/%d")(date))
        return d3.timeDay
    else if (d3.timeParse("%Y-%m")(date))
        return d3.timeMonth
    else if (d3.timeParse("%Y/%m")(date))
        return d3.timeMonth
    else if (d3.timeParse("%Y")(date))
        return d3.timeYear
}

export default AreaChart;