import React, { Component } from 'react';
import { AutoVis } from './vis';

export default class Chart extends Component {

    componentDidMount() {
        const { id } = this.props.spec.chart;
        let spec = this.props.spec;
        let container = id ? `#vischart_${id}` : "#demo-chart";
        this.autovis = new AutoVis();
        this.autovis.container(container);
        this.autovis.load(spec);
        this.autovis.generate();
    }

    componentDidUpdate(preProps) {
        const { id } = this.props.spec.chart;
        let container = id ? `#vischart_${id}` : "#demo-chart";
        let spec = this.props.spec;
        this.autovis.container(container);
        this.autovis.load(spec);
        // this.autovis.shouldShowCaption(true)
        this.autovis.generate();
    }

    render() {
        let height = 640, width = 640;
        if (this.props.spec.chart) {
            let size = this.props.spec.chart.size;
            switch (size) {
                case 'wide':
                    height = 220;
                    width = 560;
                    break;
                case 'middle':
                    height = 200;
                    width = 360;
                    break;
                case 'small':
                    height = 150;
                    width = 235;
                    break;
            
                default:
                    break;
            }
        }
        const { id } = this.props.spec.chart ? this.props.spec.chart : {id : "demo-chart"};
        return (
            <div id='frame' style={{ margin: 'auto', height: height, width: width}}>
                <div id={id ? `vischart_${id}` : 'demo-chart'} style={{ height: height, width: width }} />
            </div>
        )
    }
}
