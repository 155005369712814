import * as d3 from 'd3';
import Chart from '../../chart';
import Color from '../../visualization/color';
import textMultiline from '../../visualization/multiline';
import Style from '../../visualization/style';
import Size from '../../visualization/size';

import metaphor12 from '../../metaphor/metaphor12.png';//difference
import metaphor15 from '../../metaphor/metaphor15.png';//distribution
import metaphor16 from '../../metaphor/metaphor16.png';//proportion

const fontSize = {
    "small":{
        "axis": 7,
        "label": 14,
        "legend":10
    },
    "middle":{
        "axis": 10,
        "label": 18,
        "legend":12
    },
    "wide":{
        "axis": 12,
        "label": 20,
        "legend":14
    },
    "large":{
        "axis": 14,
        "label": 40,
        "legend":21
    }
}

const chartMargin = {
    "proportion":{
        "small":{
            "top": 5,
            "right": 24,
            "bottom": 5,
            "left": 6
        },
        "middle":{
            "top": 10,
            "right": 45,
            "bottom": 10,
            "left": 15
        },
        "wide":{
            "top": 10,
            "right": 115,
            "bottom": 10,
            "left": 85
        },
        "large":{
            "top": 60,
            "right": 60,
            "bottom": 60,
            "left": 60
        }
    },
    "difference":{
        "small":{
            "top": 10,
            "right": 6,
            "bottom": 10,
            "left": 6
        },
        "middle":{
            "top": 14,
            "right": 10,
            "bottom": 14,
            "left": 10
        },
        "wide":{
            "top": 14,
            "right": 23,
            "bottom": 14,
            "left": 23
        },
        "large":{
            "top": 50,
            "right": 10,
            "bottom": 50,
            "left": 10
        }
    },
    "distribution":{
        "small":{
            "top": 5,
            "right": 15,
            "bottom": 5,
            "left": 15
        },
        "middle":{
            "top": 10,
            "right": 30,
            "bottom": 40,
            "left": 30
        },
        "wide":{
            "top": 10,
            "right": 100,
            "bottom": 10,
            "left": 100
        },
        "large":{
            "top": 15,
            "right": 55,
            "bottom": 60,
            "left": 55
        }
    },
};
const NUMFONT = "Arial-Regular";
class PieChart extends Chart {
    displayProportion() {
        /* -------------------------------- init data ------------------------------- */
        const factData = this.factdata(),
            measure = this.measure(),
            focus = this.focus()[0],
            breakdown = this.breakdown(),
            container = this.container(),
            size = this.size(),
            style = this.style(),
            yEncoding = (measure.length > 1? "measure0:" : "") + (measure[0].aggregate === "count" ? "COUNT": measure[0].field);
        if(!focus || breakdown.length !== 1)  return;
        /* ----------------------- graph set up (size, margin) ---------------------- */
        let margin = chartMargin["proportion"][size],
            width = this.width() - margin.left - margin.right,
            height = this.height() - margin.top - margin.bottom,
            font = fontSize[size];
        /* ----------------------------- data prosessing ---------------------------- */
        let focusItem = factData.find(d => d[focus.field] === focus.value),
            restItems = factData.filter(d => d[focus.field] !== focus.value);
        if(!focusItem) return;
        let seriesData = [focusItem[yEncoding], d3.sum(restItems, d => d[yEncoding])];
        let pieData = d3.pie().sort(null)(seriesData);
        /* ----------------------------------- vis ---------------------------------- */
        let svg = initSvg(container, width, height, margin);
        let content = svg.append("g")
            .attr("class", "content"),
            hint = svg.append("g")
            .attr("class", "hint");

        let percent = pieData[0].value / d3.sum(pieData, d => d.value);
        let percentText = (percent * 100).toFixed(1) + "%";

        if(style === Style.COMICS){
            width = 0.9*width;
            height = 0.9*height;
        }
        let R = Math.min(height, width)/2,
            strokeWidth = Math.min(R*0.04, 8),
            textPadding = height * 0.002;
            
        let arc = d3.arc()
            .innerRadius(0)
            .outerRadius(R);
        let arc1 = d3.arc()
            .innerRadius(0)
            .outerRadius(R*0.9);
        let leftR = pieData[0].endAngle > Math.PI + Math.PI / 4 ? R : R * 0.9;

        content
            .attr("transform", "translate(" + leftR + "," + R + ")")
            .selectAll(".data_item")
            .data(pieData)
            .enter()
            .append("path")
            .lower()
            .attr("class", "data_item")
            .attr("fill", (d,i) => i === 0 ? Color.HIGHLIGHT : Color.DEFAULT)
            .attr("stroke-width", (d,i) => i === 0 ? strokeWidth / 2 : 0 )
            .attr("stroke", (d,i) => i === 0 ? "#fff": null  )
            .attr("d", (d,i) => i === 0 ? arc(d) : arc1(d));

        if(this.size() === Size.LARGE){
            let v = textMultiline(hint, focus.value, font.label, 1.8*R, 0, 2*R+textPadding);
            v.attr("font-weight", 600)
            .attr("fill", "black")

            hint.append("text").attr("class", "percent")
            .attr("x", 0)//for %
            .attr("y", -textPadding)
            .attr("dominant-baseline", "ideographic")
            .attr("text-anchor", "middle")
            .attr("font-family", NUMFONT)
            .attr("font-size", font.label)
            .attr("font-weight", 600)
            .attr("fill", Color.HIGHLIGHT)
            .text(percentText);

            hint.attr("transform", "translate("+ content.node().getBBox().width/2 +",0)");
        }else{
            let v = textMultiline(hint, focus.value, font.label, 1.8*R, textPadding, 0);
            v.attr("font-weight", 600)
            .attr("fill", "black")
            .attr("text-anchor", "inherit");

            hint.append("text").attr("class", "percent")
            .attr("x", 0)//for %
            .attr("y", -textPadding)
            .attr("dominant-baseline", "ideographic")
            .attr("font-family", NUMFONT)
            .attr("font-size", font.label)
            .attr("font-weight", 600)
            .attr("fill", Color.HIGHLIGHT)
            .text(percentText);

            hint.attr("transform", "translate("+ content.node().getBBox().width*1.1 +","+content.node().getBBox().height/2+")");
        }


        //center居中
        svg.attr("transform", "translate(" + ((this.width() - svg.node().getBBox().width)/2 - svg.node().getBBox().x)  + "," + ((this.height() - svg.node().getBBox().height)/2 - svg.node().getBBox().y) + ")"); 

        if(style === Style.COMICS){
            let metaphor = svg.append("image");
            const metaphorWidth = (size === Size.LARGE) ? width*0.35: width*0.3;
            const metaphorHeight = metaphorWidth*0.74;
            metaphor.attr('xlink:href', metaphor16)
                .attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", -metaphorWidth*0.5)
                .attr("y", -metaphorHeight*0.16)
                .attr("transform", "rotate(-45)");
            if(size === Size.LARGE) metaphor.attr("y", metaphorHeight*0.1);
            else if(size === Size.SMALL) metaphor.attr("y", -metaphorHeight*0.08);
            svg.node().prepend(metaphor.node());
            //center居中
            if(size !== Size.LARGE){
                svg.attr("transform", "translate(" + ((this.width() - svg.node().getBBox().width)/2 - svg.node().getBBox().x)  + "," + ((this.height() - svg.node().getBBox().height)/2 - svg.node().getBBox().y - metaphorHeight*0.15) + ")"); 
            }
        }

        return svg;
    }

    displayDifference(){
        /* -------------------------------- init data ------------------------------- */
        const factData = this.factdata(),
            measure = this.measure(),
            focus = this.focus(),
            breakdown = this.breakdown(),
            container = this.container(),
            size = this.size(),
            style = this.style(),
            yEncoding = (measure.length > 1? "measure0:" : "") + (measure[0].aggregate === "count" ? "COUNT": measure[0].field);
        if(focus.length < 2 || breakdown.length !== 1)  return;
        /* ----------------------- graph set up (size, margin) ---------------------- */
        let margin = chartMargin["difference"][size],
            width = this.width() - margin.left - margin.right,
            height = this.height() - margin.top - margin.bottom,
            font = fontSize[size];
        /* ----------------------------- data prosessing ---------------------------- */
        let focusItem1 = factData.find(d => d[focus[0].field] === focus[0].value),
            focusItem2 = factData.find(d => d[focus[1].field] === focus[1].value);
        if(!focusItem1 || !focusItem2) return;
        let focusItems = [focusItem1[yEncoding], focusItem2[yEncoding]];
        /* ----------------------------------- vis ---------------------------------- */
        let svg = initSvg(container, width, height, margin);
        let content = svg.append("g")
            .attr("class", "content");
        let sum = d3.sum(factData, d => d[[yEncoding]]);

        if(style === Style.COMICS) width = 0.8*width;
        let piePadding = size === Size.WIDE ? width * 0.05: width * 0.02,
            textPadding = height * 0.002;
        
        let R = Math.min(height*0.7, (width - piePadding)/2)/2,
            strokeWidth = Math.min(R*0.08, 8);
        let arc = d3.arc()
            .innerRadius(0)
            .outerRadius(R);
        let arc1 = d3.arc()
            .innerRadius(0)
            .outerRadius(R*0.9);

        let leftR = 0;
        let items = content.selectAll(".data_item")
            .data(focusItems)
            .enter()
            .append('g')
            .attr("class", "data_item");
        
        //value
        items.each(function(d, i){
            let v = textMultiline(d3.select(this), focus[i].value, font.label, 1.8*R, 0, R+textPadding);
            v.attr("font-weight", 600);
        });
        items.selectAll("text").attr("class", "label")
        
        //percent
        items.append("text")
            .attr("class", "percent")
            .attr("x", font.label/4)//for %
            .attr("y", -R-textPadding)
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "ideographic")
            .attr("font-family", NUMFONT)
            .attr("font-size", font.label)
            .attr("font-weight", 600)
            .text(d => (d/sum * 100).toFixed(1)+"%");

        items.each(function(item, index){
            let seriesData = [focusItems[index], sum - focusItems[index]];
            let pieData = d3.pie().sort(null)(seriesData);

            d3.select(this)
                .selectAll('.data_item_detail')
                .data(pieData)
                .enter()
                .append("path")
                .lower()
                .attr("fill", (d,i) => i === 0 ? Color.HIGHLIGHT : Color.DEFAULT)
                .attr("stroke-width",  (d,i) => i === 0 ? strokeWidth/2 : 0)
                .attr("stroke", "#fff")
                .attr("d", (d,i) => i === 0 ? arc(d) : arc1(d));
            d3.select(this)
                .attr("transform", (d,i) =>{
                    leftR = pieData[0].endAngle > Math.PI + Math.PI / 4 ? R : R * 0.9;
                    return "translate(" + (index*leftR+index*R + index *piePadding + leftR) + "," + height / 2 + ")"
                });
        });
                                  
        if(style === Style.COMICS){
            let metaphorWidth = width*0.2;
            let metaphorHeight = metaphorWidth*1.3;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor12);
            if(size === Size.WIDE){
                metaphor.attr("x", 4*R + 2*piePadding)
                    .attr("y", 3*R-font.label - metaphorHeight);
            }else if(size === Size.LARGE){
                metaphorWidth = width*0.3;
                metaphorHeight = metaphorWidth*1.09;
                metaphor.attr("x", 4*R)
                    .attr("y", 3*R+textPadding+font.label/2 - metaphorHeight);
            }else if(size === Size.MIDDLE){
                metaphorWidth = width*0.25;
                metaphorHeight = metaphorWidth*1.09;
                metaphor.attr("x", 4*R)
                    .attr("y", 2*R - 0.6*metaphorHeight);
            }else if(size === Size.SMALL){
                metaphorWidth = width*0.3;
                metaphorHeight = metaphorWidth*1.09;
                metaphor.attr("x", 4*R)
                    .attr("y", 2*R- 0.6*metaphorHeight);
            }
            metaphor.attr("width", metaphorWidth)
                    .attr("height", metaphorHeight);

        }

        //center居中
        svg.attr("transform", "translate(" + ((this.width() - svg.node().getBBox().width)/2 - svg.node().getBBox().x)  + "," + ((this.height() - svg.node().getBBox().height)/2 - svg.node().getBBox().y) + ")"); 
        return svg;
    }

    displayDistribution() {
        /* -------------------------------- init data ------------------------------- */
        const factData = this.factdata(),
            measure = this.measure(),
            container = this.container(),
            size = this.size(),
            style = this.style(),
            yEncoding = (measure.length > 1? "measure0:" : "") + (measure[0].aggregate === "count" ? "COUNT": measure[0].field);
        let breakdown = this.breakdown();
        if(breakdown.length !== 1) return;
        breakdown = breakdown[0];
        /* ----------------------- graph set up (size, margin) ---------------------- */
        let margin = chartMargin["distribution"][size],
            width = this.width() - margin.left - margin.right,
            height = this.height() - margin.top - margin.bottom,
            font = fontSize[size];
        /* ----------------------------- data prosessing ---------------------------- */
        factData.sort((a, b) => b[yEncoding] - a[yEncoding]);
        let seriesData = factData;
        if(seriesData.length > 10){ // 超过10个
            seriesData = factData.slice(0, 9); // 先取前9个
            let rest = {};
            rest[breakdown.field] = "Others"
            rest[yEncoding] = d3.sum(factData.slice(9), d => d[yEncoding]);  // 第十个开始group
            seriesData.push(rest)
        }

        let pieData = d3.pie()
            .value(d=>d[yEncoding])
            .sort(null)(seriesData);
        // /* ----------------------------------- vis ---------------------------------- */
        let svg = initSvg(container, width, height, margin);
        let content = svg.append("g")
            .attr("class", "content");
        let sum = d3.sum(pieData, d => d.value);

        if(style === Style.COMICS) width = 0.8*width;
        let R = Math.min(height, width)/2;

        let arc = d3.arc()
            .innerRadius(0)
            .outerRadius(R);

        content.selectAll(".data_item")
            .data(pieData)
            .enter()
            .append("path")
            .attr("class", "data_item")
            .attr("transform", "translate(" + R + "," + R + ")")
            .attr("fill", (d,i)=>Color.CATEGORICAL[i])//(d,i) => i < 10 ? Color.CATEGORICAL[i] : Color.DASHLINE)
            .attr("d", arc);

        //legend
        seriesData = seriesData.slice(0, 9);//hide others

        //if same year
        let sameYear = "";
        if(breakdown.type === "temporal"){
            try{
                let year = getSameYear(seriesData.map(d => d[breakdown.field]));
                sameYear = year + "/";
            }catch (e) {
                //keep origin
            }
        }
        
        const tickSize = font.legend,
            chartWidth = this.width();
        if(this.size() === Size.WIDE) {
            let foreignObject = svg.append("foreignObject")
                .attr("x", 2*R*1.2)
                .attr("y", 8)
                .attr("width", chartWidth/2.05)
                .attr("height", (2*R-5));

            let legends = foreignObject.append("xhtml:div")
                .attr("class", "legends")
                .style("display", "flex")
                .style("flex-direction", "column")
                .style("flex-wrap", "wrap")
                .style("justify-content", "space-around")
                .style("height", Math.ceil(2*R-5)+"px");

            legends.selectAll(".legend")
                .data(seriesData)
                .enter()
                .append("xhtml:div")
                .attr("class","legend")
                .style("line-height",  1)
                .style("margin-right", 5 * chartWidth/320 + "px")
                .each(function(d, i) {
                    let legend = d3.select(this).append("svg")
                    legend.append("rect")
                        .attr("fill", Color.CATEGORICAL[i])
                        .attr("width", tickSize)
                        .attr('height', tickSize)
                        .attr("rx", 1.5 * chartWidth / 320 )
                        .attr("ry", 1.5 * chartWidth / 320 );
                    let text = d[breakdown.field].replace(sameYear, "");
                    text = text.length > 20 ? text.substring(0, 18)+"…": text;
                    text += `:${(d[yEncoding]/ sum * 100).toFixed(1)}%`;
                    legend.append("text")
                        .attr("fill", Color.TEXT)
                        .attr("x", tickSize+2 )
                        .text(text)
                        .attr("font-size", tickSize)
                        .attr("font-family", NUMFONT)
                        .attr("alignment-baseline", "hanging");
                    legend.attr("width", legend.node().getBBox().width)
                    legend.attr("height", legend.node().getBBox().height)
                });

            legends.style("width", "fit-content");
            foreignObject.attr("width", legends.node().offsetWidth);
            
            svg.attr("transform", "translate(" + (this.width() - svg.node().getBBox().width)/2 + "," 
                        + (this.height() - svg.node().getBBox().height)/2 + ")");
        }else {
            let textHeight = 0;
            if(this.size() === Size.LARGE && sameYear !== ""){
                let yearText = svg.append("text")
                    .text("Year:" +sameYear.replace("/", ""))
                    .attr("font-size", tickSize)
                    .attr("font-family", NUMFONT)
                    .attr("y", margin.top)
                    .attr("x", width/2)
                    .attr("fill", Color.TEXT)
                    .attr("text-anchor", "middle");
                textHeight = yearText.node().getBBox().height+10;//margin
            }

            let foreignObject = svg.append("foreignObject")
                .attr("x", chartWidth*0.02 - margin.left)
                .attr("width", chartWidth*0.96)
                .attr("height", margin.bottom)
            let legends = foreignObject.append("xhtml:div")
                .attr("class", "legends")
                .style("display", "grid")
                .style("width", "100%")
                .style("grid-template-rows", `repeat(4, min-content)`)
                .style("grid-template-columns", `repeat(3, auto)`)
                .style("justify-content", "space-around")
                .style("grid-auto-flow", "row");
                
            legends.selectAll(".legend")
                .data(seriesData)
                .enter()
                .append("xhtml:div")
                .attr("class","legend")
                .style("display", "inline-grid")
                .style("width", "fit-content")
                .style("line-height", 0)
                .each(function(d, i) {
                    let legend = d3.select(this).append("svg")
                    legend.append("rect")
                        .attr("fill", Color.CATEGORICAL[i])
                        .attr("width", tickSize )
                        .attr('height', tickSize)
                        .attr("rx", 1.5 * chartWidth / 320 )
                        .attr("ry", 1.5 * chartWidth / 320 );
                    let text = d[breakdown.field].replace(sameYear, "");
                    if(size === Size.SMALL){
                        text = text.length > 4 ? text.substring(0, 2)+"…": text;
                    }else{
                        text = text.length > 9 ? text.substring(0, 7)+"…": text;
                    }
                    text += `:${(d[yEncoding]/ sum * 100).toFixed(1)}%`;
                    legend.append("text")
                        .attr("fill", Color.TEXT)
                        .attr("x", tickSize + 2 )
                        .text(text)
                        .attr("font-size", tickSize)
                        .attr("font-family", NUMFONT)
                        .attr("alignment-baseline", "hanging");
                    legend.attr("width", legend.node().getBBox().width)
                    legend.attr("height", legend.node().getBBox().height)
                });

                //fit size
                let legendsHeight = legends.node().offsetHeight;

                foreignObject.attr("height", legendsHeight);
                //除legend以外的空间用来绘制pie
                R = Math.min((height+margin.top+margin.bottom-legendsHeight - textHeight)*0.85, width)/2;
                arc.outerRadius(R);
                content.selectAll(".data_item")
                    .attr("transform", "translate(" + R + "," + (R + textHeight) + ")")
                    .attr("d", arc);
                foreignObject.attr("y", 2.15*R + textHeight);

                //center居中
                svg.attr("transform", "translate(" + (margin.left) + "," 
                        + ((this.height() - svg.node().getBBox().height)/2 - svg.node().getBBox().y) + ")");
                content.attr("transform", `translate(${(width - 2*R)/2}, 0)`);
        }

        if(style === Style.COMICS){
            let metaphorWidth = width*0.3;
            let metaphorHeight = metaphorWidth*1.09;

            let metaphor = content.append("image")
                .attr('xlink:href', metaphor15)
                .attr("width", metaphorWidth)
                .attr("height", metaphorHeight);

            if(size === Size.WIDE){
                metaphorWidth = width*0.3;
                metaphorHeight = metaphorWidth*1.09;
                metaphor.attr("width", metaphorWidth)
                    .attr("height", metaphorHeight)
                    .attr("x", 2*R - metaphorWidth*0.2)
                    .attr("y", 2*R - metaphorHeight*1.1);
                //center
                svg.select("foreignObject")
                    .attr("x", 2*R + metaphorWidth);
                svg.attr("transform", "translate(" + (this.width() - svg.node().getBBox().width)/2 + "," 
                    + (this.height() - svg.node().getBBox().height)/2 + ")");
            }else{
                if(size === Size.LARGE){
                    metaphorWidth = width*0.35;
                    metaphorHeight = metaphorWidth*1.09;
                    metaphor.attr("x", 2*R);
                }else if(size === Size.MIDDLE){
                    metaphorWidth = width*0.3;
                    metaphorHeight = metaphorWidth*1.09;
                    metaphor.attr("x", 2.1*R);
                }else if(size === Size.SMALL){
                    metaphorWidth = width*0.35;
                    metaphorHeight = metaphorWidth*1.09;
                    metaphor.attr("x", 2.1*R);
                }
                metaphor.attr("width", metaphorWidth)
                        .attr("height", metaphorHeight)
                        .attr("y", 2*R - metaphorHeight*1.1);
                //center
                content.attr("transform", `translate(${(width/0.8 - content.node().getBBox().width)/2}, 0)`);
            }
        }
        content.property("_r", R);

        return svg;
    }

    animateProportion() {
        let svg = this.displayProportion();
        if(!svg)    return;

        /* ----------------------- graph set up (size, margin) ---------------------- */
        let size = this.size(), 
            margin = chartMargin["proportion"][size],
            width = this.width() - margin.left - margin.right,
            height = this.height() - margin.top - margin.bottom,
            duration = this.duration(),
            ticks = 10;
        

        let R = Math.min(height, width)/2;
        
        let arc = d3.arc()
            .innerRadius(0)
            .outerRadius(R);
        let arc_others = d3.arc()
            .innerRadius(0)
            .outerRadius(R * 0.9);
        
        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            bgFadeIn: {
                duration: 2,
                index: 0
            },
            textFadeIn: {
                duration: 2,
                index: 1
            },
            majorGrow: {
                duration: 6,
                index: 2
            }
        }
        let everyTick = duration / ticks;

        /* ----------------------------- step 0 bgFadeIn ---------------------------- */
        let bg = svg.selectAll(".data_item").filter((d, i) => i === 0),
            arcFocus = svg.selectAll(".data_item").filter((d, i) => i !== 0);
        
            arcFocus.attr("d", function(d, i) {
            let _d = Object.assign({}, d);
            _d.endAngle = _d.startAngle;
            
            return arc(_d)
        });

        bg.attr("d", function(d, i) {
            let _d = Object.assign({}, d);
            _d.startAngle = 0;
            
            return arc_others(_d)
        });

        bg.attr("opacity", 0)
          .transition()
          .duration(everyTick * animation.bgFadeIn.duration)
          .attr("opacity", 1);

        /* ---------------------------- step 1 textFadeIn --------------------------- */     
        let hintText = svg.selectAll(".hint");

        let label = hintText.selectAll("text").filter(function() {
            return !this.classList.contains('percent')
          });
        

          label.attr("opacity", 0);

        setTimeout(()=>{
            label.transition()
                    .duration(everyTick * (animation.textFadeIn.duration + 0.5))
                    .attr("opacity", 1);
        }, everyTick * countTicksBeforeIndex(animation, animation.textFadeIn.index));

        /* ---------------------------- step 2 majorGrow --------------------------- */     
        arcFocus.attr("opacity", 0);
        let percent = svg.selectAll(".percent");
        percent.attr("opacity", 0)

        
        setTimeout(()=>{
            percent.attr("opacity", 1)
            percent.transition()
                .duration(everyTick * animation.majorGrow.duration)
                // .ease(d3.easeLinear)
                .textTween(function(d) {
                    let final = d3.select(this).text().split("%")[0];
                    const i = d3.interpolate(0.0, final);
                    var numberFormat = d3.format(".1f");
                    return function(t) { 
                        var _percent = numberFormat(i(t));
                        return _percent + "%";
                    };
                });

            arcFocus.attr("opacity", 1);
            arcFocus
                .transition()
                .duration((d,i) => everyTick * animation.majorGrow.duration)
                .attrTween("d", function (d, i) {
                    var interpolate = d3.interpolate(d.startAngle, d.endAngle);
                    return function (t) {
                        d.endAngle = interpolate(t);
                        return arc(d)
                    }
                });
        }, everyTick * countTicksBeforeIndex(animation, animation.majorGrow.index))
        

        /* ---------------------------- step 1 majorGrow --------------------------- */     
        // let hintText = svg.selectAll(".hint");
        // hintText.style("opacity", 0);
        
        // setTimeout(()=>{
        //     hintText.transition()
        //             .duration(everyTick * (animation.textFadeIn.duration + 0.5))
        //             .style("opacity", 1);
        // }, everyTick * countTicksBeforeIndex(animation, animation.textFadeIn.index))

    }

    
    animateDifference() {
        let svg = this.displayDifference();
        if(!svg)    return;

        /* ----------------------- graph set up (size, margin) ---------------------- */
        let size = this.size(), 
            margin = chartMargin["difference"][size],
            width = this.width() - margin.left - margin.right,
            height = this.height() - margin.top - margin.bottom,
            duration = this.duration(),
            ticks = 10;
        
        let piePadding = size === Size.WIDE ? width * 0.05: width * 0.02;
        let R = Math.min(height*0.7, (width - piePadding)/2)/2;

        let arc = d3.arc()
            .innerRadius(0)
            .outerRadius(R);
        let arc_others = d3.arc()
            .innerRadius(0)
            .outerRadius(R * 0.9);
        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            labelFadeIn: {
                duration: 2,
                index: 0
            },
            majorGrow: {
                duration: 8,
                index: 1
            }
        }
        let everyTick = duration / ticks;

        /* --------------------------- step 0 labelFadeIn --------------------------- */
        let labels = svg.selectAll(".data_item").selectAll(".label");
        labels.attr("opacity", 0);

        labels.transition()
                .duration(everyTick * animation.labelFadeIn.duration)
                .attr("opacity", 1);

        svg.selectAll(".data_item")
            .selectAll("path")
            .filter(function(d, i) {
                return d3.select(this).attr("fill") === Color.DEFAULT
            })
            .attr("d", function(d, i) {
                let _d = Object.assign({}, d);
                _d.startAngle = 0;
                
                return arc_others(_d)
            })
            .attr("opacity", 0)
            .transition()
            .duration(everyTick * animation.labelFadeIn.duration)
            .attr("opacity", 1)

        /* ---------------------------- step 1 majorGrow ---------------------------- */
        let percents = svg.selectAll(".data_item").selectAll(".percent");
        percents.attr("opacity", 0)
        svg.selectAll(".data_item")
            .selectAll("path")
            .filter(function(d, i) {
                return d3.select(this).attr("fill") !== Color.DEFAULT
            })
            .attr("d", function(d, i) {
                let _d = Object.assign({}, d);
                _d.endAngle = _d.startAngle;
                return arc(_d)
                // return i === 1 ? arc(_d) : arc_others(_d)
            })

        svg.selectAll(".data_item")
            .selectAll("path")
            .filter(function(d, i) {
                return d3.select(this).attr("fill") !== Color.DEFAULT
            }).attr("opacity", 0)
        setTimeout(()=>{
            percents.attr("opacity", 1);

            percents.transition()
                .duration(everyTick * animation.majorGrow.duration)
                .ease(d3.easeLinear)
                .textTween(function(d) {
                    let final = d3.select(this).text().split("%")[0];
                    const i = d3.interpolate(0.0, final);
                    var numberFormat = d3.format(".1f");
                    return function(t) { 
                        var percent = numberFormat(i(t));
                        return percent + "%";
                    };
                });
            svg.selectAll(".data_item")
                .selectAll("path")
                .filter(function(d, i) {
                    return d3.select(this).attr("fill") !== Color.DEFAULT
                })
                .attr("opacity", 1)
                .transition()
                .duration(everyTick * animation.majorGrow.duration)
                .ease(d3.easeLinear)
                .attrTween("d", function (d, i) {
                    
                    var interpolate = d3.interpolate(d.startAngle, d.endAngle);
                    return function (t) {
                        d.endAngle = interpolate(t);
                        return arc(d)
                        // return i === 1 ? arc(d) : arc_others(d)
                    }
                });
        }, everyTick * countTicksBeforeIndex(animation, animation.majorGrow.index))

    }

    animateDistribution() {
        let svg = this.displayDistribution();
        if(!svg)    return;

        /* ----------------------- graph set up (size, margin) ---------------------- */
        let duration = this.duration(),
            ticks = 10;
        

        let R = svg.select(".content").property("_r");
        
        let arc = d3.arc()
            .innerRadius(0)
            .outerRadius(R)
        
        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            bgCircleGrow: {
                duration: 2,
                index: 0
            },
            legendFadeIn: {
                duration: 4,
                index: 1
            },
            arcGrow: {
                duration: 8,
                index: 1
            }
        }
        let everyTick = duration / ticks;
        /* --------------------------- step 0 bgCircleGrow -------------------------- */
        svg.select(".content").append("circle").lower()
            .attr("cx", svg.select(".content").node().getBBox().width / 2)
            .attr("cy", svg.select(".content").node().getBBox().height / 2)
            .attr("fill", Color.BACKGROUND)
            .attr("r", 0)
            .transition()
            .duration(everyTick * animation.bgCircleGrow.duration)
            .attr("r", R)

        /* --------------------------- step 1 legendFadeIn -------------------------- */
        let legends = svg.selectAll(".legends");

        legends.style("opacity", 0);

        setTimeout(()=>{
            legends.transition()
                    .duration(everyTick * (animation.legendFadeIn.duration + 0.5))
                    .style("opacity", 1)
        }, everyTick * countTicksBeforeIndex(animation, animation.legendFadeIn.index))


        /* ----------------------------- step 1 arcGrow ----------------------------- */
        svg.selectAll(".data_item")
            .attr("d", function(d, i) {
                let _d = Object.assign({}, d);
                _d.endAngle = _d.startAngle;
                
                return arc(_d)
            })
        setTimeout(()=>{
            svg.selectAll(".data_item")
            .transition()
                .duration(everyTick * animation.arcGrow.duration)
                .ease(d3.easeLinear)
                .attrTween("d", function (d, i) {
                    
                    var interpolate = d3.interpolate(d.startAngle, d.endAngle);
                    return function (t) {
                        d.endAngle = interpolate(t);
                        return arc(d)
                    }
                });
        }, everyTick * (countTicksBeforeIndex(animation, animation.arcGrow.index)+0.5))
    
    }
}

const initSvg = (container, width, height, margin) => {
    let svg = d3.select(container)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    return svg;
}

const countTicksBeforeIndex = (animation, index) => {
    let count = 0;
    let visited = []
    for (let key in animation) {
        if(animation[key].index < index && visited.indexOf(animation[key].index) === -1) {
            count += animation[key].duration
            visited.push(animation[key].index)
        };
    }
    return count - 0.5
}

const getSameYear = (array) => {
    let temp = getYear(array[0]);
    for(let date of array){
        if(temp !== getYear(date)) return false;
    }
    return temp;
}

const getYear = (str) => {
    let year = str.split('/')[0];
    if(!isNaN(year) && year.length === 4){//is number
        return year;
    }else return false;
}

export default PieChart;