import Chart from '../../chart';
import * as d3 from 'd3';
import Color from '../../visualization/color';
import formatNumber from '../../visualization/format';
import chinaGeo from '../../visualization/map/china';
import usStateGeo from '../../visualization/map/usa';
import worldGeo from '../../visualization/map/world';
import Size from '../../visualization/size';
import Style from '../../visualization/style';

import metaphor4 from '../../metaphor/metaphor4.png';//value
import metaphor24 from '../../metaphor/metaphor24.png';//distribution

const NUMFONT = "Arial-Regular";
class BubbleMap extends Chart {
    displayDistribution() {
        let chartSize = {
            width: this.width(),
            height: this.height()
        };
        let { margin, hightLightFontSize } = getSizeBySize(chartSize, this.size(), "distribution");
        let width = this.width() - margin.left - margin.right,
            height = this.height() - margin.top - margin.bottom;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", this.width())
            .attr("height", this.height())

        let contentG = svg.append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);
        if (this.style() === Style.COMICS) width = 0.9 * width;

        let factdata = this.factdata();
        let breakdown = this.breakdown(),
            mapType = breakdown[0].subtype;

        let measure = this.measure();
        if (measure.length > 1 || breakdown[0].type !== 'geographical') {
            return svg;
        }

        let measuredField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field;
        let data = factdata;

        let minValue = d3.min(data, d => d[measuredField]),
            maxValue = d3.max(data, d => d[measuredField]);
        
        if(measure[0]["min"] && measure[0].min < minValue) {
            minValue = measure[0].min;
        }
        if(measure[0]['max'] && measure[0].max > maxValue) {
            maxValue = measure[0].max;
        }

        let maxR = hightLightFontSize / 2 * this.width() / 640;
        if (this.size() === 'small' || this.size() === 'middle') {
            maxR = hightLightFontSize * this.width() / 640;
        }

        let scale = d3.scaleSqrt([minValue, maxValue], [0, maxR]);
        let geoValues = data.map(d => d[breakdown[0].field]);
        let projection = d3.geoMercator();


        //init legends data
        let stepsLen = 3,
            stepWidth = (maxValue - minValue) / stepsLen;

        stepWidth = getFormatedValue(stepWidth);
        // console.log("getFormatedValue", stepWidth)
        let legendsData = [];
        for (let i = 1; i < stepsLen + 1; i++) {
            legendsData.push(stepWidth * i)
        }

        let marginBt = hightLightFontSize / 4,
            offsetY = 0;

        //append legends circle first
        let measuredWidth = margin.left; //inital
        let legends = contentG.append("g")
            .attr("class", "legendsG")
            .attr("transform", `translate(${width - measuredWidth} ${0})`);

        legends.append("g")
            .attr("class", "circleL")
            .selectAll('.circle')
            .data(legendsData)
            .enter()
            .append("circle")
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", 0.5)
            .attr("cx", 0)
            .attr("cy", (g, i) => {
                let selfR = scale(legendsData[i]);
                offsetY += 2 * selfR + marginBt
                return offsetY;
            })
            .attr("r", (g, i) => {
                return scale(legendsData[i]);
            });

        //legends text
        if (this.size() === "small") {

        } else if (this.size() === "middle") {
            hightLightFontSize = hightLightFontSize / 1.5
        } else {
            hightLightFontSize = hightLightFontSize / 2
        }

        let textOffsetY = 0;
        legends.append("g")
            .attr("class", "textL")
            .selectAll('.text')
            .data(legendsData)
            .enter()
            .append("text")
            .attr("fill", "black")
            .attr('font-size', hightLightFontSize)
            .attr('font-family', NUMFONT)
            .attr("stroke", "black")
            .attr("stroke-width", 0.5)
            .attr("dominant-baseline", "central")
            .attr("x", (g, i) => scale(legendsData[i]) + marginBt)
            .attr("y", (g, i) => {
                let selfR = scale(legendsData[i]);
                textOffsetY += 2 * selfR + marginBt
                return textOffsetY;
            })
            .text((g, i) => getTickFormat(legendsData[i]));

        measuredWidth = contentG.select(".legendsG").node().getBBox().width;
        //svg.select(".legendsG").node().setAttribute("x", width - measuredWidth)
        //console.log("measuredWidth", measuredWidth)

        if (this.size() === "large") {
            measuredWidth = 63;
        }
        //append map
        let GeoData = chinaGeo;
        if (mapType === "world") {
            GeoData = worldGeo;
        } else if (mapType === "china") {
            GeoData = chinaGeo;
        } else if (mapType === "usa") {
            GeoData = usStateGeo;
            projection = d3.geoAlbersUsa()
        }

        projection.fitSize([width - measuredWidth, height], GeoData);
        // 定义地理路径生成器
        const path = d3.geoPath()
            .projection(projection);

        let map_svg = contentG.append('g').attr('class', 'map');
        map_svg.selectAll('path')
            .data(GeoData.features)
            .enter()
            .append('path')
            .attr('id', d => d.properties.enName)
            .attr('stroke', 'grey')
            .attr('stroke-width', 0.3)
            .attr('fill', Color.BACKGROUND)
            .attr('d', path);

        if (mapType === "china") {
            appendSouthChinaSea(map_svg);
        }

        //draw circles
        let bubbleColor = Color.DEFAULT
        if(measure[0]["color"]) {
            bubbleColor = measure[0]["color"];
        }
        contentG.append("g")
            .attr("fill", bubbleColor)
            .attr("fill-opacity", 0.8)
            .attr("stroke", bubbleColor)
            .attr("stroke-width", 0.8)
            .selectAll('.bubble')
            .data(GeoData.features)
            .enter()
            .filter(d => {
                if(d.properties.enName === 'Puerto Rico') return false;
                return geoValues.indexOf(d.properties.enName) !== -1;
            })
            .append('circle')
            .attr("class", d=>"bubble "+d.properties.enName)
            .attr("cx", (d, i) => {
                return path.centroid(d)[0];
            })
            .attr("cy", (d, i) => {
                return path.centroid(d)[1];
            })
            .attr("r", d => {
                let countryName = geoValues[geoValues.indexOf(d.properties.enName)]
                let value;
                factdata.map(data => {
                    if (data[breakdown[0].field] === countryName) {
                        value = data[measuredField];
                    }
                    return data;
                })
                //console.log("r", scale.domain(),scale(value))
                return scale(value);
            });
        //finally update chart horizental cental
        if (this.size() !== 'large') {
            let _h = contentG.node().getBBox().height,
                _w = contentG.node().getBBox().width;
            let marginTop = (this.height() - _h) / 2 - contentG.node().getBBox().y,
                marginLeft = (this.width() - _w) / 2 - contentG.node().getBBox().x;
            contentG.attr("transform", "translate(" + marginLeft + "," + marginTop + ")")
        }

        if (this.style() === Style.COMICS) {
            let mapBBox = svg.select('.map').node().getBBox();
            let metaphorWidth = width * 0.22,
                metaphorHeight = 1.30 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor24)
                .attr("x", mapBBox.width + mapBBox.x + metaphorWidth * 0.5)
                .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 0.5);

            if (this.size() === Size.MIDDLE) {
                metaphorWidth = width * 0.2;
                metaphorHeight = 1.30 * metaphorWidth;
                metaphor
                    .attr("x", mapBBox.width + mapBBox.x + metaphorWidth * 0.55)
                    .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 0.9);
                if (mapType === "usa") {
                    metaphor
                        .attr("x", mapBBox.width + mapBBox.x + metaphorWidth * 0.55)
                        .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 0.75);
                }
            } else if (this.size() === Size.WIDE) {
                metaphorWidth = width * 0.18;
                metaphorHeight = 1.30 * metaphorWidth;
                metaphor
                    .attr("x", mapBBox.width + mapBBox.x + metaphorWidth * 0.35)
                    .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 1);
            } else if (this.size() === Size.SMALL) {
                metaphorWidth = width * 0.24;
                metaphorHeight = 1.30 * metaphorWidth;
                metaphor
                    .attr("x", mapBBox.width + mapBBox.x + metaphorWidth * 0.4)
                    .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 0.7);
            }

            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight);

            legends.attr("transform", `translate(${width / 0.9 - margin.left * 2} ${0})`);
        }
        return svg;
    }
    displayValue() {
        let chartSize = {
            width: this.width(),
            height: this.height()
        };
        let { margin, hightLightFontSize } = getSizeBySize(chartSize, this.size(), "value");
        let width = this.width() - margin.left - margin.right,
            height = this.height() - margin.top - margin.bottom;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", this.width())
            .attr("height", this.height())

        let contentG = svg.append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);
        if (this.style() === Style.COMICS) {
            width *= 0.9;
            height *= 0.9;
        }
        let factdata = this.factdata();
        let subspace = this.subspace(),
            mapType = subspace[0].subtype;

        let measure = this.measure();
        if (measure.length > 1 || subspace[0].type !== 'geographical') {
            return svg;
        }

        let measuredField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field;

        let maxR = hightLightFontSize / 2 * this.width() / 640;
        if (this.size() === 'small' || this.size() === 'middle') {
            maxR = hightLightFontSize * this.width() / 640;
        }
        let projection = d3.geoMercator();


        //append map
        let GeoData = chinaGeo;
        if (mapType === "world") {
            GeoData = worldGeo;
        } else if (mapType === "china") {
            GeoData = chinaGeo;
        } else if (mapType === "usa") {
            GeoData = usStateGeo;
            projection = d3.geoAlbersUsa()
        }

        projection.fitSize([width - margin.right, height], GeoData);
        // 定义地理路径生成器
        const path = d3.geoPath()
            .projection(projection);

        let map_svg = contentG.append('g').attr("class", "map");
        let countryName = subspace[0].value;
        map_svg.selectAll('path')
            .data(GeoData.features)
            .enter()
            .append('path')
            .attr('id', d => d.properties.enName)
            .attr('stroke', 'grey')
            .attr('stroke-width', 0.3)
            .attr('fill', Color.BACKGROUND)
            .attr('class', function (d, i) {
                if (d.properties.enName === countryName) {
                    return 'selected-path';
                }
                return '';
            })
            .attr('d', path);

        if (mapType === "china") {
            appendSouthChinaSea(map_svg);
        }

        //draw circles
        contentG.append("g")
            .attr("fill", Color.DEFAULT)
            .attr("fill-opacity", 0.8)
            .attr("stroke", Color.DEFAULT)
            .attr("stroke-width", 0.8)
            .selectAll('.bubble')
            .data(GeoData.features)
            .enter()
            .append('circle')
            .attr("class", "bubble")
            .attr("cx", (d, i) => {
                return path.centroid(GeoData.features[i])[0] ? path.centroid(GeoData.features[i])[0] : 0;
            })
            .attr("cy", (d, i) => {
                return path.centroid(GeoData.features[i])[1] ? path.centroid(GeoData.features[i])[1] : 0;
            })
            .attr("r", (d, i) => {
                let value = 0;
                if (d.properties.enName === subspace[0].value) {
                    value = 1; //非零即可
                }
                return value ? maxR : 0
            });
        /******value******/
        let marginTextLeft = margin.right;

        let positionL = svg.selectAll('.selected-path').node().getBBox(),
            lineWidth = this.size() === "wide" ? width / 5 : width / 10;

        let onlyCircle = contentG.selectAll("circle").filter((d, i) => d.properties.enName === subspace[0].value);

        if (positionL.x + positionL.width / 2 <= this.width() / 2) {//靠右
            // if (true) {
            contentG.append('line')
                .attr('class', 'tooltip-line')
                .attr('x1', positionL.x + positionL.width / 2)
                .attr('x2', positionL.x + positionL.width / 2 + lineWidth)
                .attr('y1', onlyCircle.attr("cy")) // positionL.y + positionL.height / 2)
                .attr('y2', onlyCircle.attr("cy")) // positionL.y + positionL.height / 2)
                .attr("stroke-width", 1)
                .attr("stroke", "black")
                .property("_direction", "right");


            let valueG = contentG.append("g")
                .attr("class", 'value-tooltip')
                .attr("transform", `translate(${positionL.x + (lineWidth + 3)}${positionL.y + positionL.height / 2})`)

            valueG.append("text")
                .attr('class', 'tooltip-text')
                .attr('dy', '-1.25em')
                .attr('font-size', hightLightFontSize)
                .attr('font-family', NUMFONT)
                .attr('text-anchor', 'middle')
                .text(countryName);

            valueG.append("text")
                .attr('class', 'tooltip-value')
                .attr('font-size', hightLightFontSize)
                .attr('font-family', NUMFONT)
                .attr('text-anchor', 'middle')
                .attr("fill", Color.HIGHLIGHT)
                .text(formatNumber(factdata[0][measuredField]));

            let _selfWidth = valueG.node().getBBox().width,
                _selfHeight = valueG.node().getBBox().height;
            //update
            valueG.node().setAttribute("transform", `translate(${(positionL.x + positionL.width / 2 + lineWidth) + _selfWidth / 2 + marginTextLeft} ${positionL.y + positionL.height / 2 + _selfHeight / 2})`);

        } else {
            contentG.append('line')
                .attr('class', 'tooltip-line')
                .attr('x2', positionL.x + positionL.width / 2)
                .attr('x1', positionL.x + positionL.width / 2 - lineWidth)
                .attr('y1', onlyCircle.attr("cy")) // .attr('y1', positionL.y + positionL.height / 2)
                .attr('y2', onlyCircle.attr("cy")) // .attr('y2', positionL.y + positionL.height / 2)
                .attr("stroke-width", 1)
                .attr("stroke", "black")
                .property("_direction", "left");

            let valueG = contentG.append("g")
                .attr("class", 'value-tooltip')
                .attr("transform", `translate(${positionL.x - (lineWidth + 3)}${positionL.y + positionL.height / 2})`)


            valueG.append("text")
                .attr('class', 'tooltip-text')
                .attr('dy', '-1.25em')
                .attr('font-size', hightLightFontSize)
                .attr('font-family', NUMFONT)
                .attr('text-anchor', 'middle')
                .text(countryName);


            valueG.append("text")
                .attr('class', 'tooltip-value')
                .attr('font-size', hightLightFontSize)
                .attr('font-family', NUMFONT)
                .attr('text-anchor', 'middle')
                .text(formatNumber(factdata[0][measuredField]));

            let _selfWidth = valueG.node().getBBox().width,
                _selfHeight = valueG.node().getBBox().height;
            //update
            valueG.node().setAttribute("transform", `translate(${(positionL.x + positionL.width / 2 - lineWidth) - _selfWidth / 2 - marginTextLeft} ${positionL.y + positionL.height / 2 + _selfHeight / 2})`);
        }
        // //append legends
        // //legends circle
        // let legends = contentG.append("g")
        //     .attr("class", "legendsG")
        //     .attr("transform", `translate(${width - margin.left * 2} ${0})`);

        // legends.append("g")
        //     .attr("class", "circleL")
        //     .selectAll('.circle')
        //     .data(subspace)
        //     .enter()
        //     .append("circle")
        //     .attr("fill", "none")
        //     .attr("stroke", "black")
        //     .attr("stroke-width", 0.5)
        //     .attr("cx", -maxR * 2)
        //     .attr("cy", 0)
        //     .attr("r", maxR);

        // //legends text
        // legends.append("g")
        //     .attr("class", "textL")
        //     .selectAll('.text')
        //     .data(subspace)
        //     .enter()
        //     .append("text")
        //     .attr("fill", "black")
        //     .attr('font-size', hightLightFontSize)
        //     .attr('font-family', NUMFONT)
        //     .attr("stroke", "black")
        //     .attr("stroke-width", 0.5)
        //     .attr("dominant-baseline", "central")
        //     .attr("x", (g, i) => -maxR + 10) //margin left
        //     .attr("y", 0)
        //     .text((g, i) => {
        //         return formatNumber(factdata[0][measuredField]);
        //     });

        if (this.style() === Style.COMICS) {
            let mapBBox = svg.select('.map').node().getBBox();

            let metaphorWidth = width * 0.22,
                metaphorHeight = 1.18 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor4)
                .attr("x", mapBBox.width + mapBBox.x + metaphorWidth * 0.2)
                .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 0.4);

            if (this.size() === Size.WIDE) {
                metaphorWidth = width * 0.20;
                metaphorHeight = 1.18 * metaphorWidth;
                metaphor
                    .attr("x", mapBBox.width + mapBBox.x + metaphorWidth * 0.2)
                    .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 0.8);
            } else if (this.size() === Size.MIDDLE || this.size() === Size.SMALL) {
                metaphorWidth = width * 0.24;
                metaphorHeight = 1.18 * metaphorWidth;
                metaphor
                    .attr("x", mapBBox.width + mapBBox.x + metaphorWidth * 0.2)
                    .attr("y", mapBBox.height + mapBBox.y - metaphorHeight * 0.8);
            }

            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight);
            // if (this.size() === Size.SMALL) {
            //     legends.attr("transform", `translate(${width / 0.95 - margin.left * 2} ${0})`);
            // } else legends.attr("transform", `translate(${width / 0.9 - margin.left * 2} ${0})`);

        }
        //finally update chart horizental cental
        let _h = contentG.node().getBBox().height,
            _w = contentG.node().getBBox().width;
        let marginTop = (this.height() - _h) / 2 - contentG.node().getBBox().y,
            marginLeft = (this.width() - _w) / 2 - contentG.node().getBBox().x;
        contentG.attr("transform", "translate(" + marginLeft + "," + marginTop + ")");
        return svg;
    }

    // displayOutlier() {
    //     this.displayDistribution()
    // }

    // displayExtreme() {
    //     this.displayDistribution()
    // }

    displayProportion() {
        this.displayDistribution()
    }

    displayRank() {
        this.displayDistribution()
    }

    animateDistribution() {
        let svg = this.displayDistribution();
        if (!svg) return;
        let duration = this.duration();
        let circleR = svg.selectAll('.bubble').nodes().map(d => d.getAttribute('r'));

        svg.selectAll(".map").attr("opacity", 0)
            .transition()
            .duration(duration / 4)
            .attr("opacity", 1);
        
        svg.selectAll(".legendsG").style("opacity", 0)
            .transition()
            .duration(duration / 4)
            .style("opacity", 1);

        svg.selectAll('.bubble')
            .attr("r", 0)
            .transition()
            .duration(duration / 4 * 3)
            .delay(duration / 4)
            .attr("r", function (d, i) {
                return circleR[i]
            });
    }

    animateValue() {
        let svg = this.displayValue();
        if (!svg) return;
        let duration = this.duration();
        let circleR = svg.selectAll('.bubble').nodes().map(d => d.getAttribute('r'));

        svg.selectAll(".map")
            .attr("opacity", 0)
            .transition()
            .duration(duration / 10 * 2)
            .attr("opacity", 1);

        svg.selectAll('.bubble')
            .attr("r", 0)
            .transition()
            .duration(duration / 10 * 4)
            .delay(duration / 10 * 2)
            .attr("r", function (d, i) {
                return circleR[i]
            });
        
        let tooltip = svg.selectAll(".value-tooltip");
        let tooltipLine = svg.selectAll(".tooltip-line");

        tooltip.attr("opacity", 0);
        let _xChange, _xStay;
        if(tooltipLine.property("_direction") === "right") {
            _xChange = "x2";
            _xStay = "x1";
        } else {
            _xChange = "x1";
            _xStay = "x2";
        }

        let originalX = tooltipLine.attr(_xChange);
        tooltipLine.attr(_xChange, tooltipLine.attr(_xStay));

        tooltipLine.transition()
                    .duration(duration / 10 * 2)
                    .delay(duration / 10 * 6)
                    .attr(_xChange, originalX);
        tooltip.transition()
                .duration(duration / 10 * 2)
                .delay(duration / 10 * 8)
                .attr("opacity", 1)
        
    }

    animateOutlier() {
        this.animateDistribution()
    }

    animateExtreme() {
        this.animateDistribution()
    }

    animateProportion() {
        this.animateDistribution()
    }

    animateRank() {
        this.animateDistribution()
    }
}
/****tick取整数 */
const getFormatedValue = (d) => {
    // console.log("d", d)
    if ((d / 1000000) >= 1) {
        d = parseInt(d / 1000000) * 1000000;
    } else if ((d / 1000) >= 1) {
        d = parseInt(d / 1000) * 1000;
    }
    return parseInt(d)
}

const getTickFormat = (d) => {
    if ((d / 1000000) >= 1) {
        d = d / 1000000 + "M";
    } else if ((d / 1000) >= 1) {
        d = d / 1000 + "K";
    }
    return d;
}

const appendSouthChinaSea = (map_svg) => {
    const HWRitiao = 200 / 150;
    const sea2ChinaRitiao = 0.12;

    let appendWidth = map_svg.node().getBBox().width * sea2ChinaRitiao,
        appendHeight = appendWidth * HWRitiao,
        scale = appendWidth / 150;


    let x = map_svg.node().getBBox().width + map_svg.node().getBBox().x - appendWidth,
        y = map_svg.node().getBBox().height + map_svg.node().getBBox().y - appendHeight;
    let southchinaseaG = map_svg.append("g")
        .attr("stroke", "black")
        .attr("stroke-width", 1)
        .attr('stroke', 'grey')
        .attr("fill", 'rgb(227, 228, 229)')
        .attr("transform-origin", `${x} ${y} `)
        .attr("transform", `scale(${scale})translate(${x} ${y})`)

    southchinaseaG.append("line")
        .attr("id", "svg_1")
        .attr("y2", 7)
        .attr("x2", 145)
        .attr("y1", 7)
        .attr("x1", 20);
    southchinaseaG.append("line")
        .attr("id", "svg_2")
        .attr("y2", 24)
        .attr("x2", 6)
        .attr("y1", 7)
        .attr("x1", 20);
    southchinaseaG.append("line")
        .attr("id", "svg_3")
        .attr("y2", 195)
        .attr("x2", 145)
        .attr("y1", 7)
        .attr("x1", 145);
    southchinaseaG.append("line")
        .attr("id", "svg_4")
        .attr("y2", 195)
        .attr("x2", 6)
        .attr("y1", 24)
        .attr("x1", 6);
    southchinaseaG.append("line")
        .attr("id", "svg_5")
        .attr("y2", 195)
        .attr("x2", 145)
        .attr("y1", 195)
        .attr("x1", 6);

    southchinaseaG.append("path")
        .attr("id", "svg_6")
        .attr("d", "m6,31.5l9,7.5l15,9l15,4l18,0l17,-14l21,-31L20,7L6,24z");
    southchinaseaG
        .append("path")
        .attr("id", "svg_7")
        .attr("d", "m113,7l10,25l11,-25z");
    southchinaseaG
        .append("path")
        .attr("id", "svg_9")
        .attr("d", "m46.5,66.5l14.5,-6.5l-1,13l-7,7l-15,4l8.5,-17.5z");

    southchinaseaG.append("line")
        .attr("id", "svg_10")
        .attr("y2", 46.5)
        .attr("x2", 132.5)
        .attr("y1", 31.5)
        .attr("x1", 141.5);
    southchinaseaG.append("line")
        .attr("id", "svg_11")
        .attr("y2", 76.5)
        .attr("x2", 115.5)
        .attr("y1", 61.5)
        .attr("x1", 121.5);
    southchinaseaG.append("line")
        .attr("id", "svg_12")
        .attr("y2", 111.5)
        .attr("x2", 110.5)
        .attr("y1", 92.5)
        .attr("x1", 110.5);
    southchinaseaG.append("line")
        .attr("id", "svg_13")
        .attr("y2", 147.5)
        .attr("x2", 101.5)
        .attr("y1", 127.5)
        .attr("x1", 108.5);
    southchinaseaG.append("line")
        .attr("id", "svg_14")
        .attr("y2", 177.5)
        .attr("x2", 78.5)
        .attr("y1", 163.5)
        .attr("x1", 91.5);

    southchinaseaG.append("line")
        .attr("id", "svg_15")
        .attr("y2", 188.5)
        .attr("x2", 39.5)
        .attr("y1", 184.5)
        .attr("x1", 54.5);
    southchinaseaG.append("line")
        .attr("id", "svg_16")
        .attr("y2", 158.5)
        .attr("x2", 11.5)
        .attr("y1", 172.5)
        .attr("x1", 17.5);
    southchinaseaG.append("line")
        .attr("id", "svg_17")
        .attr("y2", 132.5)
        .attr("x2", 39.5)
        .attr("y1", 142.5)
        .attr("x1", 24.5);
    southchinaseaG.append("line")
        .attr("id", "svg_18")
        .attr("y2", 98.5)
        .attr("x2", 37.5)
        .attr("y1", 113.5)
        .attr("x1", 40.5);

}
const getSizeBySize = (chartSize, size) => {
    let fontSize, rectWidth, rectHight, hightLightFontSize;
    switch (size) {
        case Size.WIDE:
            rectWidth = 7;
            rectHight = 80;
            fontSize = 12;
            hightLightFontSize = 26;
            break;
        case Size.MIDDLE:
            rectWidth = 6;
            rectHight = 65;
            fontSize = 10;
            hightLightFontSize = 20;
            break;
        case Size.SMALL:
            rectWidth = 4;
            rectHight = 50;
            fontSize = 8;
            hightLightFontSize = 16;
            break;
        case Size.LARGE:
        default:
            rectWidth = 12;
            rectHight = 150;
            fontSize = 14;
            hightLightFontSize = 40;
            break;
    }
    return {
        margin: {
            top: 40 * chartSize.height / 640, right: 40 * chartSize.width / 640, bottom: 40 * chartSize.height / 640, left: 40 * chartSize.width / 640
        },
        rectWidth,
        rectHight,
        fontSize,
        hightLightFontSize
    }
}
export default BubbleMap;