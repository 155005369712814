import * as d3 from 'd3';
import Chart from '../../chart';
import Color from '../../visualization/color';
import unsupportedchart from '../../visualization/unsupportedchart';
import formatNumber from '../../visualization/format';
import Size from '../../visualization/size';
import generateToolTip from '../../visualization/tooltip';
import removeOverlapX from '../../visualization/removeOverlapX'
import Style from '../../visualization/style';
import updateChartCenter from '../../visualization/updateChartCenter';
import metaphor7 from '../../metaphor/metaphor7.png';//trend, down
import metaphor8 from '../../metaphor/metaphor8.png';//trend, up
import metaphor2 from '../../metaphor/metaphor2.png';//trend, series
import metaphor6 from '../../metaphor/metaphor6.png';//trend, many
import metaphor4 from '../../metaphor/metaphor4.png';//value
import metaphor9 from '../../metaphor/metaphor9.png';//distribution
import metaphor3 from '../../metaphor/metaphor3.png';//categorization
import metaphor1 from '../../metaphor/metaphor1.png';//difference
import metaphor5 from '../../metaphor/metaphor5.png';//proportion
import metaphor17 from '../../metaphor/metaphor17.png';//outlier
import metaphor18 from '../../metaphor/metaphor18.png';//extreme

import uuidv4 from 'uuid/v4';

const NUMFONT = "Arial-Regular";
const TEXTFONT = "Arial-Bold";

class VerticalBarChart extends Chart {
    constructor() {
        super();
        this._y = '';
    }

    animateDistribution() {
        /* -------------------------------- basic vis ------------------------------- */
        let svg = this.displayDistribution();
        if (!svg) return;

        /* -------------------------------- init data ------------------------------- */
        let ticks = 10;
        let duration = this.duration();
        let chartSize = {
            width: this.width(),
            height: this.height()
        };
        let height = chartSize.height;
 
        if(this.size() === "large") updateChartCenter(svg, this.width(), this.height() + 65);
        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            labelFadeIn: {
                duration: 2,
                index: 0
            },
            barGrow: {
                duration: 8,
                index: 1
            }
        }
        let everyTick = duration / ticks;

        /* --------------------------- step 0 labelFadeIn --------------------------- */
        let labels = svg.selectAll(".xAxis,.yAxis");

        labels.attr("opacity", 0);

        labels.transition()
            .duration(everyTick * animation.labelFadeIn.duration)
            .attr("opacity", 1);
        /* ----------------------------- step 1 barGrow ----------------------------- */
        let bars = svg.selectAll(".barSeries").selectAll(".bars");

        bars.attr("height", 0)
            .attr("y", height)

        let y = this._y;
        setTimeout(() => {
            bars.transition()
                .duration(everyTick * (animation.barGrow.duration))
                .attr("height", d => y(d[0]) - y(d[1]) > 0 ? y(d[0]) - y(d[1]) : 0)
                .attr("y", d => y(d[1]))
        }, everyTick * countTicksBeforeIndex(animation, animation.barGrow.index))

    }

    animateCategorization() {
        /* -------------------------------- basic vis ------------------------------- */
        let svg = this.displayCategorization();
        if (!svg) return;

        /* -------------------------------- init data ------------------------------- */
        let ticks = 10;
        let duration = this.duration();
        // let chartSize = {
        //     width: this.width(),
        //     height: this.height()
        // };
        // let height = chartSize.height;

        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            majorGrow: {
                duration: 10,
                index: 0
            },
            // labelFadeIn: {
            //     duration: 2,
            //     index: 0
            // },
            // barGrow: {
            //     duration: 8,
            //     index: 1
            // }
        }
        let everyTick = duration / ticks;

        /* ---------------------------- step 0 majorGrow ---------------------------- */
        let bars = svg.selectAll(".barSeries").selectAll(".bars");
        // bars.attr("height", 0)
        //     .attr("y", height)
        bars.attr("opacity", 0)


        // let y = this._y;
        bars.transition()
            .duration(everyTick * (animation.majorGrow.duration) / bars.size())
            .ease(d3.easeLinear)
            .delay((d, i) => i * everyTick * (animation.majorGrow.duration) / bars.size())
            .attr("opacity", 1)
        // .attr("height", d => y(d[0]) - y(d[1]) > 0 ? y(d[0]) - y(d[1]) : 0)
        // .attr("y", d => y(d[1]))

        let xAxis = svg.selectAll(".xAxis")
        xAxis.selectAll("text")
            .attr("opacity", 0)
            .transition()
            .delay((_, i) => everyTick * (animation.majorGrow.duration) / (xAxis.selectAll("text").size()) * i)
            .duration(everyTick * (animation.majorGrow.duration) / (xAxis.selectAll("text").size()))
            .attr("opacity", 1)

        // /* --------------------------- step 0 labelFadeIn --------------------------- */
        // let labels = svg.selectAll(".xAxis").selectAll(".tick");
        // let labelsY = svg.selectAll(".yAxis").selectAll(".tick");
        // labels.attr("opacity", 0);
        // labelsY.attr("opacity", 0);

        // labels.transition()
        //     .duration(everyTick * animation.labelFadeIn.duration)
        //     .attr("opacity", 1);
        // labelsY.transition()
        //     .duration(everyTick * animation.labelFadeIn.duration)
        //     .attr("opacity", 1);

        // /* ----------------------------- step 1 barGrow ----------------------------- */
        // let bars = svg.selectAll(".barSeries").selectAll(".bars");

        // bars.attr("height", 0)
        //     .attr("y", height)

        // let y = this._y;
        // setTimeout(() => {
        //     bars.transition()
        //         .duration(everyTick * (animation.barGrow.duration ))
        //         .attr("height", d => y(d[0]) - y(d[1]) > 0 ? y(d[0]) - y(d[1]) : 0)
        //         .attr("y", d => y(d[1]))
        // }, everyTick * countTicksBeforeIndex(animation, animation.barGrow.index))
    }
    animateValue() {
        let factdata = this.factdata();
        let measure = this.measure();
        let subspace = this.subspace();
        // set the dimensions and margins of the graph
        let chartSize = {
            width: this.width(),
            height: this.height()
        };
        let { tickStrokeWidth, tickFontSize, margin, hightLightFontSize } = getSizeBySize(chartSize, this.size()),
            width = chartSize.width,
            height = chartSize.height;
        let svg = d3.select(this.container())
            .append("svg")
            .attr("class", "svg")
            .attr("width", chartSize.width)
            .attr("height", chartSize.height)
            .append("g")
            .attr("display", "block")
            .attr("class", "chartG");

        if (this.measure().length > 1) {
            return
        }

        let barValue;
        if (subspace.length === 0) {
            barValue = d3.sum(factdata, d => d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]);
        } else {
            barValue = factdata[0][measure[0].aggregate === 'count' ? "COUNT" : measure[0].field];
        }

        /***(1) append xAxis**/
        let xField = subspace.length === 0 ? "" : subspace[0].value;
        let starX = margin.left+this.width()/4,
            endX = this.width() - margin.right,
            xAxisPos = height;//inital 
        let xDomain = [xField];
        initXAxisStyle(starX, endX, xDomain, svg, xAxisPos, tickStrokeWidth, tickFontSize);

        /***(2) check rotation before update **/
        let x = d3.scaleBand()
            .domain(xDomain)
            .range([starX, endX])
            .padding(0.8),
            xAxis = svg.select(".xAxis");
        checkAndRotate(xAxis, x);
        checkMaxAxisHeight(x, this.height(), tickFontSize, svg);
        //add style
        xAxis.selectAll('.tick text')
            .attr('dy', '0.7em')
            .attr('font-family', TEXTFONT)
            .attr('font-size', hightLightFontSize)
            // .attr('fill', Color.HIGHLIGHT);

        /***(3) update y**/
        let startY = height,
            endY = 0; //inital
        let measuredAxisHeight = xAxis.node().getBBox().height + margin.bottom / 2;
        endY = measuredAxisHeight + margin.top * 2;//y根据 x轴文字的高度动态缩减

        /***(4) update chart**/
        xAxisPos = measuredAxisHeight;
        // console.log("向上方移动图表", xAxisPos)
        svg.node().setAttribute("transform", `translate(0,-${xAxisPos})`); //向上方移动图表 


        // append the rectangles for the bar chart       
        let valueHeight = margin.top;
        let initBarHeght = startY - endY - valueHeight;
        let barHeght = initBarHeght
        if(measure.length) {
            if(measure[0].max !== undefined && measure[0].min !== undefined && measure[0].max > measure[0].min){
                if(barValue >= measure[0].min && barValue <= measure[0].max) {
                    let scaleRatio = (barValue - measure[0].min) / (measure[0].max - measure[0].min)
                    barHeght = (startY - endY - valueHeight) * scaleRatio
                }            
            }
        }            
        svg.append("rect").lower()
            .attr("class", "bar")
            .attr("fill", Color.DEFAULT)
            .attr("x", (width - x.bandwidth()*2) / 2 + this.width()/8)
            .attr("y", height - barHeght)
            .attr("height", barHeght)
            .attr("width", x.bandwidth()*2);
        //tool tip
        let toolTipX = width / 2,
            toolTipY = height - initBarHeght,
            toolTipValue = formatNumber(barValue);

        svg.append("text")
            .attr("class", "tooltip")
            .attr("x", toolTipX+ this.width()/8)
            .attr("y", toolTipY - valueHeight)
            .attr('font-size', hightLightFontSize)
            .attr('font-weight', 'bolder')
            .attr('font-family', TEXTFONT)
            .attr('fill', Color.HIGHLIGHT)
            .attr('text-anchor', 'middle')
            .text(toolTipValue);

        svg.append("text")
            .attr("class", "measureTooltip")
            .attr("x", toolTipX - this.width()/3)
            // .attr("y", toolTipY - valueHeight * 2)
            .attr("y", toolTipY - valueHeight * 2+ this.height()/2)
            .attr('font-size', hightLightFontSize*2)
            .attr('font-family', "impact")
            .attr('fill', Color.DEFAULT)
            .attr('text-anchor', 'middle')
            .text(measure[0].field);

        if (subspace.length === 0) {
            svg.selectAll('.tick').selectAll("line").attr("opacity", 0)
        }

        if (this.style() === Style.COMICS) {
            let metaphorWidth = width * 0.24,
                metaphorHeight = 1.18 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor4);

            if (this.size() === Size.WIDE) {
                metaphorWidth = width * 0.20;
                metaphorHeight = 1.18 * metaphorWidth;
            } else if (this.size() === Size.MIDDLE || this.size() === Size.SMALL) {
                metaphorWidth = width * 0.25;
                metaphorHeight = 1.18 * metaphorWidth;
            }
            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", width * 0.9 - metaphorWidth)
                .attr("y", height - metaphorHeight * 1);
        }
        //finally update chart horizental cental
        updateChartCenter(svg, this.width(), this.height());
        /* -------------------------------- basic vis ------------------------------- */
        // let svg = this.displayValue();
        if (!svg) return;

        /* -------------------------------- init data ------------------------------- */
        let ticks = 10;
        let duration = this.duration();
        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            labelFadeIn: {
                duration: this.subspace().length === 0 ? 0 : 2,
                index: 0
            },
            axisFadeIn: {
                duration: 1,
                index: 1
            },
            barGrow: {
                duration: 8,
                index: 2
            },
            textGrow: {
                duration: 8,
                index: 2
            }
        }
        let everyTick = duration / ticks;

        /* --------------------------- step 0 labelFadeIn --------------------------- */
        let labels = svg.selectAll(".xAxis").selectAll(".tick");
        labels.attr("opacity", 0);

        svg.selectAll(".xAxis")
            .selectAll("path")
            .attr("opacity", 0)
            .transition()
            .duration(everyTick * animation.axisFadeIn.duration)
            .delay(everyTick * countTicksBeforeIndex(animation, animation.axisFadeIn.index))
            .attr("opacity", 1)
        
        /* ----------------------------- step 1 barGrow ----------------------------- */
        let bar = svg.select(".bar");

        let originY = bar.attr("y"),
            originHeight = bar.attr("height");

        bar.attr("height", 0)
            .attr("y", +originY + +originHeight);

        
        setTimeout(() => {
            bar.transition()
                .duration(everyTick * (animation.barGrow.duration))
                .attr("height", originHeight)
                .attr("y", originY)

                labels.transition()
            .duration(everyTick * animation.labelFadeIn.duration)
            .attr("opacity", 1);

        }, everyTick * countTicksBeforeIndex(animation, animation.barGrow.index));

        /* ----------------------------- step 1 textGrow ---------------------------- */
        let tooltip = svg.selectAll(".tooltip");
        let measureTooltip = svg.selectAll(".measureTooltip");
        tooltip.attr("opacity", 0);
        measureTooltip.attr("opacity", 0);


        measureTooltip
        .transition()
        .duration(everyTick * animation.textGrow.duration * 0.2)
        .attr("opacity", 1);

        setTimeout(() => {
            // tooltip.attr("opacity", 1);


            // text grow;
            tooltip.transition()
                .attr("opacity", 1)
                .duration(everyTick * animation.textGrow.duration * 0.8)
                .delay(everyTick * animation.textGrow.duration * 0.2)
                .textTween(function (d) {
                    let final = d3.select(this).node().innerHTML.replace(/,/g, '');
                    const i = d3.interpolate(0, final);
                    let format = d3.format(",d")
                    return function (t) {
                        var num = parseInt(i(t));
                        return format(num);
                    };
                });
        }, everyTick * countTicksBeforeIndex(animation, animation.textGrow.index))


    }
    animateProportion() {
        /* -------------------------------- basic vis ------------------------------- */
        let svg = this.displayProportion();
        if (!svg) return;

        /* -------------------------------- init data ------------------------------- */
        let ticks = 10;
        let duration = this.duration();
        let chartSize = {
            width: this.width(),
            height: this.height()
        };
        let height = chartSize.height;
        let measure = this.measure();
        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            labelFadeIn: {
                duration: 2,
                index: 0
            },
            barGrow: {
                duration: 8,
                index: 1
            },
            textGrow: {
                duration: 8,
                index: 1
            }
        }
        let everyTick = duration / ticks;

        /* --------------------------- step 0 labelFadeIn --------------------------- */
        let labels = svg.selectAll(".xAxis").selectAll(".tick");
        labels.attr("opacity", 0);

        labels.transition()
            .duration(everyTick * animation.labelFadeIn.duration)
            .attr("opacity", 1);

        /* ----------------------------- step 1 barGrow ----------------------------- */
        let bars = svg.selectAll(".barSeries").selectAll(".bars");

        bars.attr("height", 0)
            .attr("y", height)

        let y = this._y;
        setTimeout(() => {
            bars.transition()
                .duration(everyTick * (animation.barGrow.duration))
                .attr("height", d => y(d[0]) - y(d[1]) > 0 ? y(d[0]) - y(d[1]) : 0)
                .attr("y", d => y(d[1]))
        }, everyTick * countTicksBeforeIndex(animation, animation.barGrow.index));

        /* ----------------------------- step 1 textGrow ---------------------------- */
        let values = svg.selectAll(".values").selectAll("text");
        values.attr("y", y(0)).text("0%");
        setTimeout(() => {
            values.transition()
                .duration(everyTick * (animation.textGrow.duration))
                .attr("y", function (d) { return y(d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]) })
                .textTween(function (d) {
                    let final = d3.select(this).property("_value");
                    const i = d3.interpolate(0, +final);
                    var numberFormat = d3.format(".0f");
                    return function (t) {
                        var percent = numberFormat(i(t));
                        return percent + "%";
                    };
                });

        }, everyTick * countTicksBeforeIndex(animation, animation.textGrow.index))



    }
    animateOutlier() {
        if(this.size() !== 'large') return this.animateExtreme();
        let focus = this.focus();

            let factdata = this.factdata();
            let measure = this.measure(),
                measuredField = measure[0].aggregate === 'count' ? "COUNT" : measure[0].field;
            let breakdown = this.breakdown();
            let hasSeries = false;
            if (breakdown[1] && breakdown[1].field) hasSeries = true;
            // set the dimensions and margins of the graph
            let chartSize = {
                width: this.width(),
                height: this.height()
            };
            let { tickFontSize, tickStrokeWidth, annotationSize, margin, strokeWidth } = getSizeBySize(chartSize, this.size()),
                width = chartSize.width,
                height = chartSize.height;
            let svg = d3.select(this.container())
                .append("svg")
                .attr("width", chartSize.width)
                .attr("height", chartSize.height)
                .append("g")
                .attr("display", "block")
                .attr("class", "chartG");
            if (this.style() === Style.COMICS) width = (this.size() === Size.LARGE && hasSeries) ? width : 0.85 * width;
            if (this.measure().length > 1) {
                // svg.append("rect")
                //     .attr("width", width)
                //     .attr("height", height)
                //     .attr("fill", "none");
                return;
            }
    
            let data = factdata;
            if (breakdown[0].type === 'temporal') {
                data = data.sort(sortByDateAscending(breakdown))
            }

            let sumValue = 0
            data.forEach((d) =>{
                sumValue += d[measuredField]
            })
            let avgValue = sumValue / data.length;

            let seriesData;
            let calculateData;
            let maxYValue = getMaxYValue(factdata, measure);
            if (hasSeries) {
                calculateData = d3.nest().key(d => d[breakdown[0].field]).entries(data);
                let categories = Array.from(new Set(data.map(d => d[breakdown[1].field])));
                categories = categories.slice(0, 10);
                let objList = new Array(calculateData.length)
                for (let i = 0; i < calculateData.length; i++) {
                    let obj = {};
                    calculateData[i].values.map((d, i) => {
                        obj.x = d[breakdown[0].field]
                        obj[d[breakdown[1].field]] = d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]
                        return obj;
                    })
                    objList[i] = obj
                }
                // complete the missed data = 0
                for (let k = 0; k < calculateData.length; k++) {
                    for (let i = 0; i < categories.length; i++) {
                        if (!objList[k].hasOwnProperty(categories[i])) {
                            objList[k][categories[i]] = 0;
                        }
                    }
                }
                seriesData = d3.stack()
                    .keys(categories)
                    (objList);
            } else {
                data.map(data => {
                    data.maxValue = (maxYValue - data[measuredField]);
                    return data;
                })
                seriesData = d3.stack()
                    .keys([measuredField])
                    (data);
            }
            seriesData = seriesData.slice(0, 10);
            //console.log("seriesData",seriesData)
    
            // append legend before chart
            let measuredWidth = 0;
            if (hasSeries) {
                let rightLegendsW = margin.right,//inital
                    measuredHeight = 0;
                let seriesName = d3.map(seriesData, function (d) { return d.key; }).keys();
                svg.append("foreignObject")
                    .attr("x", width - margin.right)
                    .attr("y", height / 2)
                    .attr("width", rightLegendsW)
                    .attr("height", height)
                    .attr("class", "foreignObject")
                    .append("xhtml:div")
                    .attr("class", "legends")
                    .style("display", "flex")
                    .style("flex-direction", "column")
                    .style("flex-wrap", "wrap")
                    // .style("align-content", "space-around")
                    // .style("height", height + "px")
                    .style("height", "100%")
                    .selectAll(".legend")
                    .data(seriesName)
                    .enter()
                    .append("xhtml:div")
                    .attr("class", "legend")
                    .style("line-height", 1)
                    .style("margin-right", 5 * chartSize.width / 640 + "px")
                    .each(function (d, i) {
                        let legend = d3.select(this).append("svg")
                        legend.append("rect")
                            .attr("fill", d => Color.CATEGORICAL[seriesName.indexOf(d)])
                            .attr("width", 10 * tickFontSize / 12)
                            .attr('height', 10 * tickFontSize / 12)
                            .attr("rx", 1.5 * chartSize.width / 640)
                            .attr("ry", 1.5 * chartSize.width / 640)
                        legend.append("text")
                            .attr("fill", Color.TEXT)
                            .attr("x", 12 * tickFontSize / 12)
                            .text(d)
                            .attr("font-size", tickFontSize * 0.8)
                            .attr("font-family", "RobotoMono-Regular")
                            .attr("alignment-baseline", "hanging");
    
                        legend.attr("width", legend.node().getBBox().width);
                        legend.attr("height", legend.node().getBBox().height);
                        let selfWidth = legend.node().getAttribute("width"),
                            selfHeight = legend.node().getAttribute("height");
                        if (Number(selfWidth) > Number(measuredWidth)) {
                            measuredWidth = Number(selfWidth);
                        }
                        measuredHeight += Number(selfHeight);
                    });
    
                //update legend center
                measuredWidth = measuredWidth + margin.right;
                let yPos = (chartSize.height - measuredHeight) / 2;
    
                if (margin.right < measuredWidth) {
                    svg.select(".foreignObject").node().setAttribute("x", this.width() - measuredWidth - margin.right / 2);
                }
                svg.select(".foreignObject").node().setAttribute("y", yPos);
                svg.select(".foreignObject").node().setAttribute("width", Number(measuredWidth));
                svg.select(".foreignObject").node().setAttribute("height", height);
            }
    
            /***(1) append yAxis  to measure the leftTick width **/
            let maxY;
            if (hasSeries) {
                maxY = d3.max(seriesData[seriesData.length - 1], d => d[1])
            } else {
                maxY = d3.max(data, d => d[measuredField]);
            }
            let startY = height,
                endY = 0; //inital
            initYAixsStyle(startY, endY, maxY, svg, tickStrokeWidth, tickFontSize, margin);
    
            /***(2) append xAxis**/
            let measuredYFieldsWidth = svg.select(".yAxis").node().getBBox().width + margin.left;
            // console.log("measuredYFieldsWidth", measuredYFieldsWidth)
            let starX = measuredYFieldsWidth,
                endX = width - measuredWidth - margin.right,
                xAxisPos = height;//inital 
            let xDomain = data.map(function (d) { return d[breakdown[0].field]; });
            initXAxisStyle(starX, endX, xDomain, svg, xAxisPos, tickStrokeWidth, tickFontSize);
    
            /***(3) check rotation before update y**/
            let x = d3.scaleBand()
                .domain(xDomain)
                .range([starX, endX])
                .padding(0.5),
                xAxis = svg.select(".xAxis");
    
            let unsupportedchartPrams = {
                svg,
                chartSize,
                annotationSize,
                size: this.size()
            }
            let isShowSuggestion = this.showSuggestion();
            let result = checkXAxis(xAxis, x, breakdown[0].type, unsupportedchartPrams, isShowSuggestion);
            if (result === "unsupportedChart")
                return svg;
            checkMaxAxisHeight(x, this.height(), tickFontSize, svg);
            //update y 
            let measuredAxisHeight = xAxis.node().getBBox().height + margin.bottom / 2;
            endY = measuredAxisHeight + margin.top;//y根据 x轴文字的高度动态缩减
            initYAixsStyle(startY, endY, maxY, svg, tickStrokeWidth, tickFontSize, margin);//更新endY
    
            let y = d3.scaleLinear().nice()
                .range([startY, endY])
                .domain([0, maxY])
                .clamp(true);
    
            /***(4) update chart**/
            xAxisPos = measuredAxisHeight;
            // console.log("向上方移动图表", xAxisPos)
            svg.node().setAttribute("transform", `translate(0,-${xAxisPos})`); //向上方移动图表 
            this._y = y;
    
            // append the rectangles for the bar chart
            if (hasSeries) {
                svg.selectAll(".barSeries")
                    .data(seriesData)
                    .enter()
                    .append("g")
                    .attr("class", "barSeries")
                    .attr("fill", (d, i) => Color.CATEGORICAL[i])
                    .selectAll("bars")
                    .data(d => d)
                    .enter()
                    .append("rect")
                    .attr("class", "bars")
                    .attr("x", (d, i) => x(calculateData[i].key))
                    .attr("y", d => y(d[1]))
                    .attr("height", d => y(d[0]) - y(d[1]) > 0 ? y(d[0]) - y(d[1]) : 0)
                    .attr("width", x.bandwidth());
    
            } else {
                svg
                    .append("g")
                    .lower()
                    .datum(seriesData[0])
                    .attr("class", "barSeries")
                    .attr("fill", Color.DEFAULT)
                    .selectAll("bars")
                    .data(d => d)
                    .enter()
                    .append("rect")
                    .attr("class", "bars")
                    .attr("x", (d, i) => x(d.data[breakdown[0].field]))
                    .attr("y", d => y(d[1]))
                    .attr("height", d => y(d[0]) - y(d[1]) > 0 ? y(d[0]) - y(d[1]) : 0)
                    .attr("width", x.bandwidth());
    
            }


    let yAxisWidth = svg.selectAll(".yAxis").node().getBBox().width
    let x1 = 0 + margin.left + yAxisWidth,
    x2 = width - margin.right;
    let y1 = y(avgValue),
    y2 = y1

    svg.append("g")
        .attr("class", "trendlineLayer")
        .selectAll(".trendline")
        .data([seriesData[0]])
        .enter()
        .append("line")
        .attr("class", d => "trendline " + d.key)
        .attr("x1", x1)
        .attr("x2", x2)
        .attr("y1", y1)
        .attr("y2", y2)
        .attr("stroke", Color.DASHLINE)
        .attr("stroke-width", strokeWidth)
        .attr("stroke-dasharray", `${strokeWidth * 2}, ${strokeWidth}`);

    let bars = svg.selectAll(".barSeries").selectAll(".bars");
    let barsFocus = bars.filter(function (d, i) {
        return d.data[focus[0].field] === focus[0].value
    })
    // let barHeight = barsFocus.node().getAttribute('height')
    let barWidth = barsFocus.node().getAttribute('width')
    // let barX = barsFocus.node().getAttribute('x')

    let arrowLayer = svg.append('g').attr("class", 'arrowLayer')
    let focusValueArray = factdata.filter(d => {
        return d[focus[0].field] === focus[0].value
    });
    let focusData = focusValueArray[0];

    let toolTipX = x(focus[0].value) + x.bandwidth() / 2,//箭头中心所在x的位置
        toolTipY = y(focusData[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]) - 30 * chartSize.height / 640; //箭头中心所在y的位置
    // let arrowTriangle = 
    arrowLayer.append("path")
        .attr("class", "triangle")
        .attr("transform", "translate(" + toolTipX + "," + (toolTipY) + ")rotate(180)")
        .attr("d", d3.symbol().type(d3.symbolTriangle).size(barWidth * 30))
        .attr("fill", Color.ANNOTATION);

    // let arrowRect = 
    arrowLayer
        .append('rect')
        .attr("class", "arrowRect")
        .attr("width", barWidth)
        .attr("height", 35)
        .attr("fill", Color.ANNOTATION)
        .attr("x", toolTipX - barWidth / 2)
        .attr("y", toolTipY - 35)
    
            if (this.style() === Style.COMICS) {
                let metaphorWidth = width * 0.22,
                    metaphorHeight = 1.12 * metaphorWidth;
    
                let metaphor = svg.append("image")
                    .attr('xlink:href', metaphor9);
    
                if (this.size() === Size.WIDE) {
                    metaphorWidth = width * 0.18;
                    metaphorHeight = 1.12 * metaphorWidth;
                } else if (this.size() === Size.MIDDLE || this.size() === Size.SMALL) {
                    metaphorWidth = width * 0.20;
                    metaphorHeight = 1.12 * metaphorWidth;
                }
                metaphor.attr("width", metaphorWidth)
                    .attr("height", metaphorHeight)
                    .attr("x", endX + metaphorWidth * 0.05)
                    .attr("y", height - metaphorHeight * 0.96);
                //center居中
                svg.attr("transform", "translate(" + ((this.width() - svg.node().getBBox().width)/2 - svg.node().getBBox().x)  + "," + ((this.height() - svg.node().getBBox().height)/2 - svg.node().getBBox().y) + ")"); 
            }
            //finally update chart horizental cental
            updateChartCenter(svg, this.width(), this.height());
        
        /*** animation ***/ 
        // this.animateExtreme();
        // let svg = this.displayOutlier();
        if (!svg) return;
        let duration = this.duration();
        let trendline = svg.selectAll(".trendline")
        let originEndX2 = trendline.node().getAttribute("x2");
        let originEndY2 = trendline.node().getAttribute("y2");
        let originEndX1 = trendline.node().getAttribute("x1");
        let originEndY1 = trendline.node().getAttribute("y1");
        trendline.attr("x2", originEndX1)
            .attr("y2", originEndY1);

        bars = svg.selectAll(".barSeries").selectAll(".bars");
        // let y = this._y;
        bars.attr("opacity", 0)
            .transition()
            .duration(duration / 4)
            .attr("fill", Color.DEFAULT)
            .attr("opacity", 1)
        svg.selectAll(".yAxis").attr("opacity", 0)
            .transition()
            .duration(duration / 4)
            .attr("opacity", 1);
        svg.selectAll(".xAxis").attr("opacity", 0)
            .transition()
            .duration(duration / 4)
            .attr("opacity", 1);
        // bars.transition()
        //     .duration(duration / 4)
        //     .attr("y", d => y(d[1]))
        //     .attr("height", d => y(d[0]) - y(d[1]) > 0 ? y(d[0]) - y(d[1]) : 0)

        // let focus = this.focus();
        let barsOthers = bars.filter(function (d, i) {
            return d.data[focus[0].field] !== focus[0].value
        })
        // draw avg line
        trendline.attr("x2", originEndX1)
            .attr("y2", originEndY1)
            .transition()
            .delay(duration / 4)
            .duration(duration / 4)
            .attr("x2", originEndX2)
            .attr("y2", originEndY2);
            
        let fontSize = xAxis.select("text").attr("font-size");
        let avgFontSize = fontSize * 1.5;
        svg.append("g")
            .append("text")
            .attr("x", originEndX2)
            .attr("y", originEndY2-avgFontSize*0.5)
            .attr("text-anchor", "end")
            // .attr()
            .text("average")
            .attr("fill", Color.DASHLINE)
            .attr("font-size", avgFontSize)
            .attr("opacity", 0)
            .transition()
            .delay(duration / 4)
            .duration(duration / 4)
            .attr("opacity", 1);

        setTimeout(() => {
            barsOthers.attr("opacity", 1)
                .transition()
                .duration(duration / 4)
                .attr("opacity", 0.2)
        }, duration / 4 * 2);

        let labels = svg.selectAll(".xAxis").selectAll(".tick");
        
        // labels.attr("opacity", 0);
        svg.select('.arrowLayer').attr("opacity", 0);
        setTimeout(() => {
            labels.transition()
                .duration(duration / 4)
                .attr("opacity", 1)
            svg.select('.arrowLayer').transition()
                .duration(duration / 4)
                .attr("opacity", 1)
            labels.filter(d => d === focus[0].value).selectAll("text")
                .transition()
                .duration(duration / 4)
                .attr("fill", Color.ANNOTATION);
        }, duration / 4 * 3);
    }
    animateExtreme() {
        /* -------------------------------- basic vis ------------------------------- */
        let svg = this.displayExtreme();
        if (!svg) return;

        /* -------------------------------- init data ------------------------------- */
        let ticks = 10;
        let duration = this.duration();

        let focus = this.focus();
        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            axisFadeIn: {
                duration: 1,
                index: 0
            },
            barGrow: {
                duration: 2,
                index: 1
            },
            fillColor: {
                duration: 4,
                index: 2
            },
            tickHighlight: {
                duration: 3,
                index: 2
            },
            valueFadeIn: {
                duration: 3,
                index: 3
            }
        }
        let everyTick = duration / ticks;

        svg.selectAll(".xAxis").selectAll("path")
            .attr("opacity", 0)
            .transition()
            .duration(everyTick * animation.axisFadeIn.duration)
            .attr("opacity", 1)
        
        svg.selectAll(".yAxis")
            .attr("opacity", 0)
            .transition()
            .duration(everyTick * animation.axisFadeIn.duration)
            .attr("opacity", 1)

        /* ----------------------------- step 0 barGrow ----------------------------- */
        let bars = svg.selectAll(".barSeries").selectAll(".bars");
        let y = this._y;
        bars.attr("height", 0)
            .attr("y", y(0))
            .attr("fill", Color.DEFAULT)
        bars.transition()
            .duration(everyTick * (animation.barGrow.duration))
            .delay(everyTick * countTicksBeforeIndex(animation, animation.barGrow.index))
            .attr("y", d => y(d[1]))
            .attr("height", d => y(d[0]) - y(d[1]) > 0 ? y(d[0]) - y(d[1]) : 0)
        /* -------------------------- step 1 fillColor -------------------------- */
        let barsFocus = bars.filter(function (d, i) {
            return d.data[focus[0].field] === focus[0].value
        })
        setTimeout(() => {
            barsFocus.transition()
                .duration(everyTick * (animation.fillColor.duration))
                .attr("fill", Color.HIGHLIGHT)
        }, everyTick * countTicksBeforeIndex(animation, animation.fillColor.index));

        /* -------------------------- step 2 othersFadeOut -------------------------- */
        // let barsOthers = bars.filter(function (d, i) {
        //     return d.data[focus[0].field] !== focus[0].value
        // })
        // setTimeout(() => {
        //     barsOthers.attr("opacity", 1)
        //         .transition()
        //         .duration(everyTick * (animation.othersFadeOut.duration))
        //         .attr("opacity", 0.2)
        // }, everyTick * countTicksBeforeIndex(animation, animation.othersFadeOut.index));



        /* --------------------------- step 3 valueFadeIn --------------------------- */
        let tooltip = svg.selectAll(".tooltip");
        tooltip.attr("opacity", 0);

        setTimeout(() => {
            tooltip.transition()
                .duration(everyTick * (animation.valueFadeIn.duration))
                .attr("opacity", 1)
        }, everyTick * countTicksBeforeIndex(animation, animation.valueFadeIn.index));

        /* -------------------------- step 3 tickHighlight -------------------------- */
        let labels = svg.selectAll(".xAxis").selectAll(".tick");
        labels.filter(d => d === focus[0].value).selectAll("text").attr("fill", Color.ANNOTATION);
        labels.attr("opacity", 0);
        setTimeout(() => {
            labels.transition()
                .duration(everyTick * (animation.tickHighlight.duration)).attr("opacity", 1)

        }, everyTick * (countTicksBeforeIndex(animation, animation.tickHighlight.index)+1));
    }
    animateDifference() {
        /* -------------------------------- basic vis ------------------------------- */
        let svg = this.displayDifference();
        if (!svg) return;

        /* -------------------------------- init data ------------------------------- */
        let ticks = 10;
        let duration = this.duration();
        let chartSize = {
            width: this.width(),
            height: this.height()
        };
        let height = chartSize.height;

        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            labelFadeIn: {
                duration: 1,
                index: 0
            },
            barGrow: {
                duration: 3,
                index: 1
            },
            drawHLines: {
                duration: 3,
                index: 2
            },
            drawArrow: {
                duration: 0,
                index: 3
            },
            drawVLines: {
                duration: 3,
                index: 3
            },
            valueFadeIn: {
                duration: 3,
                index: 3
            },
        }
        let everyTick = duration / ticks;
        /* ----------------------------- step 0 barGrow ----------------------------- */
        let bars = svg.selectAll(".barSeries").selectAll(".bars");

        bars.attr("height", 0)
            .attr("y", height)

        let y = this._y;
        bars.transition()
            .duration(everyTick * (animation.barGrow.duration))
            .attr("height", d => y(d[0]) - y(d[1]) > 0 ? y(d[0]) - y(d[1]) : 0)
            .attr("y", d => y(d[1]))
        /* --------------------------- step 0 labelFadeIn --------------------------- */
        // let labels = svg.selectAll(".xAxis").selectAll(".tick");
        let labels = svg.selectAll(".xAxis,.yAxis");

        labels.attr("opacity", 0);

        labels.transition()
            .duration(everyTick * animation.labelFadeIn.duration)
            .attr("opacity", 1);

        /* --------------------------- step 1 valueFadeIn --------------------------- */
        let diffValue = svg.selectAll(".differenceValue");
        diffValue.attr("opacity", 0);

        setTimeout(() => {
            diffValue.transition()
                .duration(everyTick * (animation.valueFadeIn.duration))
                .attr("opacity", 1)
        }, everyTick * countTicksBeforeIndex(animation, animation.valueFadeIn.index))

        /* ---------------------------- step 2 drawHLines --------------------------- */
        let referenceLs = svg.selectAll(".referenceLs").selectAll("line");
        referenceLs.each(function () {
            let originX = d3.select(this).attr("x2");
            d3.select(this).attr("x1", originX)
        })
        let { margin } = getSizeBySize(chartSize, this.size())
        let measuredYFieldsWidth = svg.select(".yAxis").node().getBBox().width + margin.left;
        setTimeout(() => {
            referenceLs.transition()
                .duration(everyTick * (animation.drawHLines.duration))
                // .attr("x1", 0)
                .attr("x1", measuredYFieldsWidth);
        }, everyTick * countTicksBeforeIndex(animation, animation.drawHLines.index))

        /* ---------------------------- step 3 drawArrow ---------------------------- */
        // let uuid = uuidv4();
        let trendSVG = svg.selectAll(".trendSVG");
        trendSVG.remove();
        // trendSVG.attr("id", "trendSVGClip")
        //     .attr("clip-path", "url(#clip_trend_"+uuid+")");

        // let defsX = trendSVG.node().getBBox().x,
        //     defsY = trendSVG.node().getBBox().y,
        //     defsHeight = trendSVG.node().getBBox().height,
        //     defsWidth = trendSVG.node().getBBox().width;

        // trendSVG.append("defs")
        //     .attr("class", "trend_defs")
        //     .append("clipPath")
        //     .attr("id", "clip_trend_"+uuid)
        //     .append("rect")
        //     .attr("x", defsX)
        //     .attr("y", defsY)
        //     .attr("width", 0)
        //     .attr("height", defsHeight);

        // setTimeout(() => {
        //     trendSVG.select("#clip_trend_"+uuid+" rect")
        //         .attr("width", 0)
        //         .transition()
        //         .duration(everyTick * (animation.drawArrow.duration))
        //         .attr("width", defsWidth)
        // }, everyTick * countTicksBeforeIndex(animation, animation.drawArrow.index))

        /* ---------------------------- step 3 drawVLines --------------------------- */
        let vLine = svg.selectAll(".hightlightL");
        vLine.attr("opacity", 0);

        setTimeout(() => {
            vLine.transition()
                .duration(everyTick * (animation.drawVLines.duration))
                .attr("opacity", 1)
        }, everyTick * countTicksBeforeIndex(animation, animation.drawVLines.index))
    }
    animateTrend() {
        /* -------------------------------- basic vis ------------------------------- */
        let svg = this.displayTrend();
        if (!svg) return;

        /* -------------------------------- init data ------------------------------- */
        let ticks = 10;
        let duration = this.duration();
        let chartSize = {
            width: this.width(),
            height: this.height()
        };
        let height = chartSize.height;

        let breakdown = this.breakdown();
        let hasSeries = false;
        if (breakdown[1] && breakdown[1].field) hasSeries = true;

        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            axisFadeIn: {
                duration: 1,
                index: 0
            },
            majorGrow: {
                duration: 6,
                index: 1
            }, 
            trendGrow: {
                duration: 3,
                index: 2
            }
        }
        let everyTick = duration / ticks;

        svg.selectAll(".xAxis").selectAll("text").attr("dy", "1em");

        svg.selectAll(".xAxis")
            .selectAll("path")
            .attr("opacity", 0)
            .transition()
            .duration(everyTick * animation.axisFadeIn.duration)
            .attr("opacity", 1);

        svg.selectAll(".yAxis")
            .attr("opacity", 0)
            .transition()
            .duration(everyTick * animation.axisFadeIn.duration)
            .attr("opacity", 1);
        /* --------------------------- step 0 majorGrow --------------------------- */
        if (!hasSeries) {
            let bars = svg.selectAll(".barSeries").selectAll(".bars");
                bars.attr("height", 0)
                    .attr("y", height);
            let xAxis = svg.selectAll(".xAxis")
            xAxis.selectAll(".tick")
                .attr("opacity", 0)
            setTimeout(()=>{
    
                let y = this._y;
                bars.transition()
                    .duration(everyTick * (animation.majorGrow.duration) / bars.size())
                    .ease(d3.easeLinear)
                    .delay((d, i) => i * everyTick * (animation.majorGrow.duration) / bars.size())
                    .attr("height", d => y(d[0]) - y(d[1]) > 0 ? y(d[0]) - y(d[1]) : 0)
                    .attr("y", d => y(d[1]))
    
               
                xAxis.selectAll(".tick")
                    .attr("opacity", 0)
                    .transition()
                    .delay((_, i) => everyTick * (animation.majorGrow.duration) / (xAxis.selectAll("text").size()) * i)
                    .duration(everyTick * (animation.majorGrow.duration) / (xAxis.selectAll("text").size()))
                    .attr("opacity", 1)
            }, everyTick * countTicksBeforeIndex(animation, animation.majorGrow.index))
            

            let trendSVG = svg.selectAll(".trendLine");

            let uuid = uuidv4();
            trendSVG.attr("id", "trendSVGClip")
                .attr("clip-path", "url(#clip_trend_"+uuid+")");

            let defsX = trendSVG.node().getBBox().x,
                defsY = trendSVG.node().getBBox().y,
                defsHeight = trendSVG.node().getBBox().height,
                defsWidth = trendSVG.node().getBBox().width;

            trendSVG.append("defs")
                .attr("class", "trend_defs")
                .append("clipPath")
                .attr("id", "clip_trend_"+uuid)
                .append("rect")
                .attr("x", defsX)
                .attr("y", defsY)
                .attr("width", 0)
                .attr("height", defsHeight);

            setTimeout(() => {
                trendSVG.select("#clip_trend_"+uuid+" rect")
                    .attr("width", 0)
                    .transition()
                    .duration(everyTick * (animation.trendGrow.duration))
                    .ease(d3.easeLinear)
                    .attr("width", defsWidth)
            }, everyTick * countTicksBeforeIndex(animation, animation.trendGrow.index))
        } else {
            let bars = svg.selectAll(".barSeries");
            let y = this._y;
            bars.selectAll(".bars")
                .attr("height", 0)
                .attr("y", d => y(d[0]))



            bars.transition()

                .each(function (d, i) {
                    let indexOfSeries = i;
                    let seriesCount = bars.size();
                    let barCount = d3.select(this).selectAll(".bars").size();
                    let timeUnit = everyTick * (animation.majorGrow.duration) / seriesCount / barCount;
                    d3.select(this).selectAll(".bars")
                        .transition()
                        .duration(timeUnit)
                        .delay((d, i) => seriesCount * i * timeUnit + indexOfSeries * timeUnit)
                        .ease(d3.easeLinear)
                        .attr("height", d => y(d[0]) - y(d[1]) > 0 ? y(d[0]) - y(d[1]) : 0)
                        .attr("y", d => y(d[1]))
                })



            let xAxis = svg.selectAll(".xAxis")
            xAxis.selectAll("text")
                .attr("opacity", 0)
                .transition()
                .delay((_, i) => everyTick * (animation.majorGrow.duration) / (xAxis.selectAll("text").size()) * i)
                .duration(everyTick * (animation.majorGrow.duration) / (xAxis.selectAll("text").size()))
                .ease(d3.easeLinear)
                .attr("opacity", 1)

        }

    }
    displayDifference() {
        let chartSize = {
            width: this.width(),
            height: this.height()
        };
        let { tickFontSize, tickStrokeWidth, margin, hightLightFontSize, arrowWidth } = getSizeBySize(chartSize, this.size()),
            width = chartSize.width - margin.left - margin.right,
            height = chartSize.height;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", this.width())
            .attr("height", this.height())
            .append("g")
            .attr("display", "block")
            .attr("class", "chartG");
        // if(this.style() === Style.COMICS) width = this.size() === Size.LARGE ? width :0.85* width;

        //difference类型会在filteredData里生成两条数据
        if (this.focus().length < 2 || this.measure().length > 1) {
            svg.append("rect")
                .attr("width", this.width())
                .attr("height", this.height())
                .attr("fill", "none");
            return svg;
        }
        let focus = this.focus();
        let filteredData = [] //sorted by focus
        for (const fs of focus) {
            this.factdata().filter((x) => x[fs.field] === fs.value)[0] && filteredData.push(this.factdata().filter((x) => x[fs.field] === fs.value)[0])
        }

        let measure = this.measure();
        let breakdown = this.breakdown();
        let measuredField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field;
        //console.log('11', maxYValue)
        let maxYValue = getMaxYValue(filteredData, measure);
        filteredData.map(data => {
            data.maxValue = (maxYValue - data[measuredField]);
            return data;
        })
        //console.log('showDifference in child')

        let data = filteredData;
        let seriesData = d3.stack()
            .keys([measuredField, "maxValue"])
            (data);
        //console.log("series...", seriesData)

        //(1)append valueText first to measure the width
        let valueText = Math.abs(Number(seriesData[0][1][1]) - Number(seriesData[0][0][1]));
        let meauredTextWidth = 0;

        let differenceValueG = svg.append("g")
            .attr("class", "differenceValue")
            .attr("transform", `translate(${width},${height / 2})`);

        differenceValueG.append("text")
            .attr('font-size', hightLightFontSize)
            .attr('font-family', TEXTFONT)
            .attr("font-weight",'bold')
            .attr('fill', Color.HIGHLIGHT)
            .attr('text-anchor', 'middle')
            .attr('dominant-baseline', 'middle')
            .text(valueText < 0 ? `-${formatNumber(-valueText)}` : formatNumber(valueText));

        // differenceValueG.append("text")
        //     .attr("dy", "-1.25em")
        //     .attr('font-size', hightLightFontSize)
        //     .attr('font-family', TEXTFONT)
        //     .attr("font-weight",'bold')
        //     .attr('fill', Color.HIGHLIGHT)
        //     .attr('text-anchor', 'middle')
        //     .attr('dominant-baseline', 'hanging')
        //     .text("Difference");
        let _selfWidth = svg.select(".differenceValue").node().getBBox().width;
        //_selfHeight = svg.select(".difference-value-box").node().getBBox().height;

        meauredTextWidth = _selfWidth + margin.right / 2;

        /***(2) append yAxis  to measure the leftTick width **/
        let maxY = d3.max(data, d => d[measuredField]);
        let startY = height,
            endY = 0; //inital
        initYAixsStyle(startY, endY, maxY, svg, tickStrokeWidth, tickFontSize, margin);
        /***(3) append xAxis**/
        let measuredYFieldsWidth = svg.select(".yAxis").node().getBBox().width + margin.left;
        let starX = measuredYFieldsWidth,
            endX = this.width() - meauredTextWidth,
            xAxisPos = height;//inital 
        let xDomain = data.map(function (d) { return d[breakdown[0].field]; });
        initXAxisStyle(starX, endX, xDomain, svg, xAxisPos, tickStrokeWidth, tickFontSize);

        /***(4) check rotation before update y**/
        let x = d3.scaleBand()
            .domain(xDomain)
            .range([starX, endX])
            .padding(0.5),
            xAxis = svg.select(".xAxis");
        checkAndRotate(xAxis, x);
        checkMaxAxisHeight(x, this.height(), tickFontSize, svg);
        let measuredAxisHeight = xAxis.node().getBBox().height + margin.bottom / 2;
        endY = measuredAxisHeight + margin.top;//y根据 x轴文字的高度动态缩减
        initYAixsStyle(startY, endY, maxY, svg, tickStrokeWidth, tickFontSize, margin);//更新endY
        //update textValue
        let y = d3.scaleLinear().nice()
            .range([startY, endY])
            .domain([0, maxY])
            .clamp(true);
        //update differenceValue group
        differenceValueG.node().setAttribute("transform", `translate(${width - margin.right / 2} ${y(maxY / 2)})`);
        // /***(5) update chart**/
        // xAxisPos = measuredAxisHeight;
        // // console.log("向上方移动图表", xAxisPos)
        // svg.node().setAttribute("transform", `translate(0,-${xAxisPos})`); //向上方移动图表 
        this._y = y;

        // append the rectangles for the bar chart
        svg.insert("g", ".xAxis")
            .datum(seriesData[0])
            .attr("class", "barSeries")
            .attr("fill", Color.DEFAULT)
            .selectAll("bars")
            .data(d => d)
            .enter()
            .append("rect")
            .attr("class", "bars")
            .attr("x", (d, i) => x(d.data[breakdown[0].field]))
            .attr("y", d => y(d[1]))
            .attr("height", d => y(d[0]) - y(d[1]))
            .attr("width", x.bandwidth());

        svg.append("g")
            .attr("class", "referenceLs")
            .selectAll("referenceL")
            .data(data)
            .join("line")
            .attr('class', 'referenceL')
            .attr('x1', measuredYFieldsWidth)
            .attr('y1', d => y(d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]))
            .attr('x2', d => x(d[breakdown[0].field]) + x.bandwidth() / 2)
            .attr('y2', d => y(d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]))
            .attr('stroke', Color.DASHLINE)
            .attr('stroke-width', tickStrokeWidth)
            .attr('stroke-dasharray', '5,5');

        svg.append("line")
            .attr('class', 'hightlightL')
            .attr('x1', measuredYFieldsWidth)
            .attr('y1', y(data[0][measure[0].aggregate === "count" ? "COUNT" : measure[0].field]))
            .attr('x2', measuredYFieldsWidth)
            .attr('y2', y(data[1][measure[0].aggregate === "count" ? "COUNT" : measure[0].field]))
            .attr('stroke', Color.HIGHLIGHT)
            .attr('opacity', 1)
            .attr('stroke-width', arrowWidth);


        // draw trend line
        let barW1 = x(data[0][breakdown[0].field]) + x.bandwidth() / 2;
        let barW2 = x(data[1][breakdown[0].field]) + x.bandwidth() / 2;
        let barH1 = y(data[0][measure[0].aggregate === "count" ? "COUNT" : measure[0].field]);
        let barH2 = y(data[1][measure[0].aggregate === "count" ? "COUNT" : measure[0].field]);

        let h1 = barH1 + height / 20 < height ? barH1 + height / 20 : height - height / 50;
        let h2 = barH2 + height / 20 < height ? barH2 + height / 20 : height - height / 50;
        var trendLine = d3.line()
            .x(function (d) { return d.x; })
            .y(function (d) { return d.y; })
            .curve(d3.curveMonotoneY)
        let trendData = [
            { x: barW1, y: h1 },
            { x: (barW1 + barW2) / 2, y: (h1 + h2) / 2 + Math.abs(h1 - h2) / 4 },
            { x: barW2, y: h2 },
        ]
        function getTanDeg(tan) {
            var result = Math.atan(tan) / (Math.PI / 180);
            result = Math.round(result);
            return result;
        }
        let slope = ((height - h2) - (height - ((h1 + h2) / 2 + Math.abs(h1 - h2) / 4))) / (barW2 - barW1) * 2
        let deg
        if (getTanDeg(slope) < 0) {
            deg = Math.abs(getTanDeg(slope)) + 90
        } else {
            deg = - getTanDeg(slope) + 90
        }
        let trendSVG = svg.append("g").attr("class", "trendSVG")
        trendSVG.append('path')
            .attr("stroke", "black")
            .attr("stroke-width", arrowWidth)
            .attr('fill', 'none')
            .attr('d', trendLine(trendData))
        trendSVG.append("path")
            .attr("transform", "translate(" + barW2 + "," + h2 + ")rotate(" + deg + ")")
            .attr("d", d3.symbol().type(d3.symbolTriangle).size(0.4 * arrowWidth / 5 * height))
            .attr("fill", 'black')

        if (this.style() === Style.COMICS) {
            let metaphorWidth = width * 0.26,
                metaphorHeight = 1.33 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor1)
                .attr("y", height - metaphorHeight);

            let textHeight = differenceValueG.node().getBBox().height;
            if (this.size() !== Size.LARGE) {
                metaphorHeight = startY - endY - textHeight;
                metaphorWidth = metaphorHeight / 1.33;
                metaphor.attr("y", endY + textHeight);
                differenceValueG.node().setAttribute("transform", `translate(${width - margin.right / 2} ${endY + textHeight / 2})`);
            }

            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                // .attr("x", x(data[1][breakdown[0].field])+ x.bandwidth() / 2 + ((this.size() === Size.MIDDLE || this.size() === Size.SMALL)? metaphorWidth*0.2 : metaphorWidth*0.8))
                .attr("x", width * 0.98 - metaphorWidth * 0.75);
        }
        //finally update chart horizental cental
        updateChartCenter(svg, this.width(), this.height());
        return svg;

    }
    displayProportion() {
        let chartSize = {
            width: this.width(),
            height: this.height()
        };
        let { tickFontSize, tickStrokeWidth, margin, annotationSize } = getSizeBySize(chartSize, this.size()),
            width = this.width(),
            height = this.height();

        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("display", "block")
            .attr("class", "chartG");
        if (this.style() === Style.COMICS) width = 0.85 * width;
        if (this.focus().length !== 1 || this.measure().length > 1) {
            return
        }
        let focus = this.focus();
        let measure = this.measure(),
            measuredField = measure[0].aggregate === "count" ? "COUNT" : measure[0].field;
        let breakdown = this.breakdown();
        let maxYValue = d3.sum(this.factdata(), d => d[measuredField])
        // getMaxYValue(this.factdata(), measure, true);
        let data = this.factdata().map(data => {
            data.maxValue = (maxYValue - data[measuredField]);
            return data;
        })

        let seriesData = d3.stack()
            .keys([measuredField, "maxValue"])
            (data);

        let contentG = svg.append("g");
        /***(2) append yAxis  to measure the leftTick width **/
        let maxY = d3.sum(data, d => d[measuredField])
        //d3.max(data, d => d[measuredField]);

        let startY = height,
            endY = 0; //inital
        initYAixsStyle(startY, endY, maxY, svg, tickStrokeWidth, tickFontSize, margin);

        /***(1) append xAxis**/
        let measuredYFieldsWidth = svg.select(".yAxis").node().getBBox().width + margin.left;
        let starX = measuredYFieldsWidth / 2,
            endX = width - margin.right,
            xAxisPos = height;//inital 
        let xDomain = data.map(function (d) { return d[breakdown[0].field]; });
        initXAxisStyle(starX, endX, xDomain, svg, xAxisPos, tickStrokeWidth, tickFontSize);

        /***(2) check rotation before update y**/
        let x = d3.scaleBand()
            .domain(xDomain)
            .range([starX, endX])
            .padding(0.5),
            xAxis = svg.select(".xAxis");

        let unsupportedchartPrams = {
            svg,
            chartSize,
            annotationSize,
            size: this.size()
        }
        let isShowSuggestion = this.showSuggestion();
        let result = checkXAxis(xAxis, x, breakdown[0].type, unsupportedchartPrams, isShowSuggestion);

        if (result === "unsupportedChart")
            return svg;
        checkMaxAxisHeight(x, this.height(), tickFontSize, svg);
        //proportion style
        svg.select(".xAxis .domain").remove();
        svg.selectAll(".xAxis line").remove();
        xAxis.selectAll('.tick text')
            .attr('fill', d => {
                if (d === focus[0].value)
                    return Color.HIGHLIGHT
                return "black";
            });

        let upValueH = 30;
        let measuredAxisHeight = xAxis.node().getBBox().height + margin.bottom / 2;
        endY = measuredAxisHeight + margin.top + upValueH;//y根据 x轴文字的高度动态缩减
        initYAixsStyle(startY, endY, maxY, svg, tickStrokeWidth, tickFontSize, margin);//更新endY

        /***(3) update chart**/
        xAxisPos = measuredAxisHeight;
        // console.log("向上方移动图表", xAxisPos)
        svg.node().setAttribute("transform", `translate(0,-${xAxisPos})`); //向上方移动图表 
        svg.select(".yAxis").remove();

        let y = d3.scaleLinear().nice()
            .range([startY, endY])
            .domain([0, maxY])
            .clamp(true);
        this._y = y;

        // append the rectangles for the bar chart
        contentG
            .insert("g", ".xAxis")
            .datum(seriesData[1])
            .attr("class", "barSeriesBG")
            .selectAll("bars")
            .data(d => d)
            .enter()
            .append("rect")
            .attr("class", "bars")
            .attr("fill", Color.BACKGROUND)
            .attr("x", (d, i) => x(d.data[breakdown[0].field]))
            .attr("y", d => y(d[1]))
            .attr("height", d => y(0) - y(d[1]))
            .attr("width", x.bandwidth());
        contentG
            .insert("g", ".xAxis")
            .datum(seriesData[0])
            .attr("class", "barSeries")
            .selectAll("bars")
            .data(d => d)
            .enter()
            .append("rect")
            .attr("class", "bars")
            .attr("fill", (d, i) => {
                //通过d[0]===0来判断是第一层数据
                if (d.data[focus[0].field] === focus[0].value) {
                    return Color.HIGHLIGHT;
                }
                return Color.DEFAULT;
            })
            .attr("x", (d, i) => x(d.data[breakdown[0].field]))
            .attr("y", d => y(d[1]))
            .attr("height", d => y(d[0]) - y(d[1]))
            .attr("width", x.bandwidth());


        //bar value
        //console.log("filteredData", data)
        let totalValue = d3.sum(data, d => d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field])
        contentG.append("g")
            .attr("class", "values")
            .selectAll("text")
            .data(data)
            .enter()
            .append("text")
            .attr("font-size", annotationSize)
            .attr('font-family', d => {
                if (d[breakdown[0].field] === focus[0].value) {
                    // console.log("bold")
                    return TEXTFONT;
                }
                return NUMFONT
            })
            .attr("fill", d => {
                if (d[breakdown[0].field] === focus[0].value) {
                    return Color.HIGHLIGHT;
                }
                return "black"
            })
            .attr("text-anchor", 'middle')
            .attr("x", function (d) { return x(d[breakdown[0].field]); })
            .attr("y", function (d) { return y(d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]) })
            .attr("dx", x.bandwidth() / 2)
            .attr("dy", "-1em")
            .text(d => (d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field] / totalValue * 100).toFixed(0) + "%")
            .property("_value", d => (d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field] / totalValue * 100).toFixed(0));

        if (this.style() === Style.COMICS) {
            let metaphorWidth = width * 0.24,
                metaphorHeight = 1.31 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor5);

            if (this.size() === Size.WIDE) {
                metaphorWidth = width * 0.18;
                metaphorHeight = 1.31 * metaphorWidth;
            } else if (this.size() === Size.MIDDLE) {
                metaphorWidth = width * 0.22;
                metaphorHeight = 1.31 * metaphorWidth;
            }

            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", width * 0.92)
                .attr("y", height - metaphorHeight * 0.96);
            //center居中
            svg.attr("transform", "translate(" + ((this.width() - svg.node().getBBox().width) / 2 - svg.node().getBBox().x) + "," + ((this.height() - svg.node().getBBox().height) / 2 - svg.node().getBBox().y) + ")");
        }
        //finally update chart horizental cental
        updateChartCenter(svg, this.width(), this.height());
        return svg;
    }

    displayCategorization() {
        let factdata = this.factdata();
        let breakdown = this.breakdown();
        let chartSize = {
            width: this.width(),
            height: this.height()
        };
        let { tickFontSize, tickStrokeWidth, margin, annotationSize } = getSizeBySize(chartSize, this.size()),
            height = chartSize.height,
            width = chartSize.width;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", this.width())
            .attr("height", this.height())
            .append("g")
            .attr("display", "block")
            .attr("class", "chartG");
        if (this.style() === Style.COMICS) width = 0.85 * width;
        if (this.breakdown().length > 1) {
            return;
        }

        let data = factdata;
        let measureField = "COUNT";
        //data
        let calculateData = d3.nest().key(d => d[breakdown[0].field]).entries(data);
        data = calculateData.map(function (d, i) {
            let countRows = d.values[0];
            countRows[measureField] = d.values[0].COUNT; //d.values.length
            return countRows;
        });

        let contentG = svg.append("g");
        let maxYValue = d3.max(factdata, d => {
            return d[measureField];
        })

        data.map(data => {
            data.maxValue = (maxYValue - data[measureField]);
            return data;
        })
        let seriesData = d3.stack()
            .keys([measureField, "maxValue"])
            (data);
        seriesData = seriesData.slice(0, 10);
        /***(1) append yAxis  to measure the leftTick width **/
        let maxY = d3.max(data, d => d[measureField]);
        maxY = getMaxY(maxY);
        let startY = height,
            endY = 0; //inital
        initYAixsStyle(startY, endY, maxY, svg, tickStrokeWidth, tickFontSize, margin);

        /***(2) append xAxis**/
        let measuredYFieldsWidth = svg.select(".yAxis").node().getBBox().width + margin.left;
        let starX = measuredYFieldsWidth,
            endX = width - margin.right,
            xAxisPos = height;//inital 
        let xDomain = data.map(function (d) { return d[breakdown[0].field]; });
        initXAxisStyle(starX, endX, xDomain, svg, xAxisPos, tickStrokeWidth, tickFontSize);

        /***(3) check rotation before update y**/
        let x = d3.scaleBand()
            .domain(xDomain)
            .range([starX, endX])
            .padding(0.5),
            xAxis = svg.select(".xAxis");

        let unsupportedchartPrams = {
            svg,
            chartSize,
            annotationSize,
            size: this.size()
        }
        let isShowSuggestion = this.showSuggestion();
        let result = checkXAxis(xAxis, x, breakdown[0].type, unsupportedchartPrams, isShowSuggestion);
        if (result === "unsupportedChart")
            return svg;
        checkMaxAxisHeight(x, this.height(), tickFontSize, svg);
        //update y 
        let measuredAxisHeight = xAxis.node().getBBox().height + margin.bottom / 2;
        endY = measuredAxisHeight + margin.top;//y根据 x轴文字的高度动态缩减
        initYAixsStyle(startY, endY, maxY, svg, tickStrokeWidth, tickFontSize, margin);//更新endY

        let y = d3.scaleLinear().nice()
            .range([startY, endY])
            .domain([0, maxY])
            .clamp(true);

        /***(4) update chart**/
        xAxisPos = measuredAxisHeight;
        // console.log("向上方移动图表", xAxisPos)
        svg.node().setAttribute("transform", `translate(0,-${xAxisPos})`); //向上方移动图表 
        this._y = y;

        contentG
            .insert("g", ".xAxis")
            .datum(seriesData[0])
            // .append("g")
            .attr("class", "barSeries")
            .attr("transform", `translate(0,0)`)
            .selectAll("bars")
            .data(d => d)
            .enter()
            .append("rect")
            .attr("class", "bars")
            .attr("fill", function (d, i) {
                let color = Color.CATEGORICAL[i % 10];
                return color;
            })
            .attr("x", (d, i) => x(d.data[breakdown[0].field]))
            .attr("y", d => y(d[1]))
            .attr("height", d => y(d[0]) - y(d[1]) > 0 ? y(d[0]) - y(d[1]) : 0)
            .attr("width", x.bandwidth());

        if (this.style() === Style.COMICS) {
            let metaphorWidth = width * 0.24,
                metaphorHeight = 1.38 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor3);

            if (this.size() === Size.WIDE) {
                metaphorWidth = width * 0.18;
                metaphorHeight = 1.38 * metaphorWidth;
            } else if (this.size() === Size.MIDDLE) {
                metaphorWidth = width * 0.22;
                metaphorHeight = 1.38 * metaphorWidth;
            }

            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", width * 0.91)
                .attr("y", height - metaphorHeight * 0.96);

            //center居中
            svg.attr("transform", "translate(" + ((this.width() - svg.node().getBBox().width) / 2 - svg.node().getBBox().x) + "," + ((this.height() - svg.node().getBBox().height) / 2 - svg.node().getBBox().y) + ")");
        }
        //finally update chart horizental cental
        updateChartCenter(svg, this.width(), this.height());
        return svg;
    }

    displayExtreme() {
        return displayExtremeAndOutlier(this, "Extreme")
    }

    displayOutlier() {
        return displayExtremeAndOutlier(this, "Outlier")
    }

    displayValue() {
        let factdata = this.factdata();
        let measure = this.measure();
        let subspace = this.subspace();
        // set the dimensions and margins of the graph
        let chartSize = {
            width: this.width(),
            height: this.height()
        };
        let { tickStrokeWidth, tickFontSize, margin, hightLightFontSize } = getSizeBySize(chartSize, this.size()),
            width = chartSize.width,
            height = chartSize.height;
        let svg = d3.select(this.container())
            .append("svg")
            .attr("class", "svg")
            .attr("width", chartSize.width)
            .attr("height", chartSize.height)
            .append("g")
            .attr("display", "block")
            .attr("class", "chartG");

        if (this.measure().length > 1) {
            return
        }

        let barValue;
        if (subspace.length === 0) {
            barValue = d3.sum(factdata, d => d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]);
        } else {
            barValue = factdata[0][measure[0].aggregate === 'count' ? "COUNT" : measure[0].field];
        }

        /***(1) append xAxis**/
        let xField = subspace.length === 0 ? "" : subspace[0].value;
        let starX = margin.left+this.width()/4,
            endX = this.width() - margin.right,
            xAxisPos = height;//inital 
        let xDomain = [xField];
        initXAxisStyle(starX, endX, xDomain, svg, xAxisPos, tickStrokeWidth, tickFontSize);

        /***(2) check rotation before update **/
        let x = d3.scaleBand()
            .domain(xDomain)
            .range([starX, endX])
            .padding(0.8),
            xAxis = svg.select(".xAxis");
        checkAndRotate(xAxis, x);
        checkMaxAxisHeight(x, this.height(), tickFontSize, svg);
        //add style
        xAxis.selectAll('.tick text')
            .attr('dy', '0.7em')
            .attr('font-family', TEXTFONT)
            .attr('font-size', hightLightFontSize)
            // .attr('fill', Color.HIGHLIGHT);

        /***(3) update y**/
        let startY = height,
            endY = 0; //inital
        let measuredAxisHeight = xAxis.node().getBBox().height + margin.bottom / 2;
        endY = measuredAxisHeight + margin.top * 2;//y根据 x轴文字的高度动态缩减

        /***(4) update chart**/
        xAxisPos = measuredAxisHeight;
        // console.log("向上方移动图表", xAxisPos)
        svg.node().setAttribute("transform", `translate(0,-${xAxisPos})`); //向上方移动图表 


        // append the rectangles for the bar chart       
        let valueHeight = margin.top;
        let initBarHeght = startY - endY - valueHeight;
        let barHeght = initBarHeght
        if(measure.length) {
            if(measure[0].max !== undefined && measure[0].min !== undefined && measure[0].max > measure[0].min){
                if(barValue >= measure[0].min && barValue <= measure[0].max) {
                    let scaleRatio = (barValue - measure[0].min) / (measure[0].max - measure[0].min)
                    barHeght = (startY - endY - valueHeight) * scaleRatio
                }            
            }
        }            
        svg.append("rect").lower()
            .attr("class", "bar")
            .attr("fill", Color.DEFAULT)
            .attr("x", (width - x.bandwidth()) / 2 + this.width()/8)
            .attr("y", height - barHeght)
            .attr("height", barHeght)
            .attr("width", x.bandwidth());
        //tool tip
        let toolTipX = width / 2,
            toolTipY = height - initBarHeght,
            toolTipValue = formatNumber(barValue);

        svg.append("text")
            .attr("class", "tooltip")
            .attr("x", toolTipX+ this.width()/8)
            .attr("y", toolTipY - valueHeight)
            .attr('font-size', hightLightFontSize)
            .attr('font-family', TEXTFONT)
            .attr('fill', Color.HIGHLIGHT)
            .attr('text-anchor', 'middle')
            .text(toolTipValue);

        svg.append("text")
            .attr("class", "measureTooltip")
            .attr("x", toolTipX - this.width()/3)
            // .attr("y", toolTipY - valueHeight * 2)
            .attr("y", toolTipY - valueHeight * 2+ this.height()/2)
            .attr('font-size', hightLightFontSize*1.5)
            .attr('font-family', TEXTFONT)
            .attr('fill', Color.DEFAULT)
            .attr('text-anchor', 'middle')
            .text(measure[0].field);

        if (subspace.length === 0) {
            svg.selectAll('.tick').selectAll("line").attr("opacity", 0)
        }

        if (this.style() === Style.COMICS) {
            let metaphorWidth = width * 0.24,
                metaphorHeight = 1.18 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor4);

            if (this.size() === Size.WIDE) {
                metaphorWidth = width * 0.20;
                metaphorHeight = 1.18 * metaphorWidth;
            } else if (this.size() === Size.MIDDLE || this.size() === Size.SMALL) {
                metaphorWidth = width * 0.25;
                metaphorHeight = 1.18 * metaphorWidth;
            }
            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", width * 0.9 - metaphorWidth)
                .attr("y", height - metaphorHeight * 1);
        }
        //finally update chart horizental cental
        updateChartCenter(svg, this.width(), this.height());
        return svg;
    }

    displayDistribution() {
        let factdata = this.factdata();
        let measure = this.measure(),
            measuredField = measure[0].aggregate === 'count' ? "COUNT" : measure[0].field;
        let breakdown = this.breakdown();
        let hasSeries = false;
        if (breakdown[1] && breakdown[1].field) hasSeries = true;
        // set the dimensions and margins of the graph
        let chartSize = {
            width: this.width(),
            height: this.height()
        };
        let { tickFontSize, tickStrokeWidth, annotationSize, margin } = getSizeBySize(chartSize, this.size()),
            width = chartSize.width,
            height = chartSize.height;
        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", chartSize.width)
            .attr("height", chartSize.height)
            .append("g")
            .attr("display", "block")
            .attr("class", "chartG");
        if (this.style() === Style.COMICS) width = (this.size() === Size.LARGE && hasSeries) ? width : 0.85 * width;
        if (this.measure().length > 1) {
            // svg.append("rect")
            //     .attr("width", width)
            //     .attr("height", height)
            //     .attr("fill", "none");
            return;
        }

        let data = factdata;
        if (breakdown[0].type === 'temporal') {
            data = data.sort(sortByDateAscending(breakdown))
        }
        let seriesData;
        let calculateData;
        let maxYValue = getMaxYValue(factdata, measure);
        if (hasSeries) {
            calculateData = d3.nest().key(d => d[breakdown[0].field]).entries(data);
            let categories = Array.from(new Set(data.map(d => d[breakdown[1].field])));
            categories = categories.slice(0, 10);
            let objList = new Array(calculateData.length)
            for (let i = 0; i < calculateData.length; i++) {
                let obj = {};
                calculateData[i].values.map((d, i) => {
                    obj.x = d[breakdown[0].field]
                    obj[d[breakdown[1].field]] = d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]
                    return obj;
                })
                objList[i] = obj
            }
            // complete the missed data = 0
            for (let k = 0; k < calculateData.length; k++) {
                for (let i = 0; i < categories.length; i++) {
                    if (!objList[k].hasOwnProperty(categories[i])) {
                        objList[k][categories[i]] = 0;
                    }
                }
            }
            seriesData = d3.stack()
                .keys(categories)
                (objList);
        } else {
            data.map(data => {
                data.maxValue = (maxYValue - data[measuredField]);
                return data;
            })
            seriesData = d3.stack()
                .keys([measuredField])
                (data);
        }
        seriesData = seriesData.slice(0, 10);
        //console.log("seriesData",seriesData)

        // append legend before chart
        let measuredWidth = 0;
        if (hasSeries) {
            let rightLegendsW = margin.right,//inital
                measuredHeight = 0;
            let seriesName = d3.map(seriesData, function (d) { return d.key; }).keys();
            svg.append("foreignObject")
                .attr("x", width - margin.right)
                .attr("y", height / 2)
                .attr("width", rightLegendsW)
                .attr("height", height)
                .attr("class", "foreignObject")
                .append("xhtml:div")
                .attr("class", "legends")
                .style("display", "flex")
                .style("flex-direction", "column")
                .style("flex-wrap", "wrap")
                // .style("align-content", "space-around")
                // .style("height", height + "px")
                .style("height", "100%")
                .selectAll(".legend")
                .data(seriesName)
                .enter()
                .append("xhtml:div")
                .attr("class", "legend")
                .style("line-height", 1)
                .style("margin-right", 5 * chartSize.width / 640 + "px")
                .each(function (d, i) {
                    let legend = d3.select(this).append("svg")
                    legend.append("rect")
                        .attr("fill", d => Color.CATEGORICAL[seriesName.indexOf(d)])
                        .attr("width", 10 * tickFontSize / 12)
                        .attr('height', 10 * tickFontSize / 12)
                        .attr("rx", 1.5 * chartSize.width / 640)
                        .attr("ry", 1.5 * chartSize.width / 640)
                    legend.append("text")
                        .attr("fill", Color.TEXT)
                        .attr("x", 12 * tickFontSize / 12)
                        .text(d)
                        .attr("font-size", tickFontSize * 0.8)
                        .attr("font-family", NUMFONT)
                        .attr("alignment-baseline", "hanging");

                    legend.attr("width", legend.node().getBBox().width);
                    legend.attr("height", legend.node().getBBox().height);
                    let selfWidth = legend.node().getAttribute("width"),
                        selfHeight = legend.node().getAttribute("height");
                    if (Number(selfWidth) > Number(measuredWidth)) {
                        measuredWidth = Number(selfWidth);
                    }
                    measuredHeight += Number(selfHeight);
                });

            //update legend center
            measuredWidth = measuredWidth + margin.right;
            let yPos = (chartSize.height - measuredHeight) / 2;

            if (margin.right < measuredWidth) {
                svg.select(".foreignObject").node().setAttribute("x", this.width() - measuredWidth - margin.right / 2);
            }
            svg.select(".foreignObject").node().setAttribute("y", yPos);
            svg.select(".foreignObject").node().setAttribute("width", Number(measuredWidth));
            svg.select(".foreignObject").node().setAttribute("height", height);
        }

        /***(1) append yAxis  to measure the leftTick width **/
        let maxY;
        if (hasSeries) {
            maxY = d3.max(seriesData[seriesData.length - 1], d => d[1])
        } else {
            maxY = d3.max(data, d => d[measuredField]);
        }
        let startY = height,
            endY = 0; //inital
        initYAixsStyle(startY, endY, maxY, svg, tickStrokeWidth, tickFontSize, margin);

        /***(2) append xAxis**/
        let measuredYFieldsWidth = svg.select(".yAxis").node().getBBox().width + margin.left;
        // console.log("measuredYFieldsWidth", measuredYFieldsWidth)
        let starX = measuredYFieldsWidth,
            endX = width - measuredWidth - margin.right,
            xAxisPos = height;//inital 
        let xDomain = data.map(function (d) { return d[breakdown[0].field]; });
        initXAxisStyle(starX, endX, xDomain, svg, xAxisPos, tickStrokeWidth, tickFontSize);

        /***(3) check rotation before update y**/
        let x = d3.scaleBand()
            .domain(xDomain)
            .range([starX, endX])
            .padding(0.5),
            xAxis = svg.select(".xAxis");

        let unsupportedchartPrams = {
            svg,
            chartSize,
            annotationSize,
            size: this.size()
        }
        let isShowSuggestion = this.showSuggestion();
        let result = checkXAxis(xAxis, x, breakdown[0].type, unsupportedchartPrams, isShowSuggestion);
        if (result === "unsupportedChart")
            return svg;
        checkMaxAxisHeight(x, this.height(), tickFontSize, svg);
        //update y 
        let measuredAxisHeight = xAxis.node().getBBox().height + margin.bottom / 2;
        endY = measuredAxisHeight + margin.top;//y根据 x轴文字的高度动态缩减
        initYAixsStyle(startY, endY, maxY, svg, tickStrokeWidth, tickFontSize, margin);//更新endY

        let y = d3.scaleLinear().nice()
            .range([startY, endY])
            .domain([0, maxY])
            .clamp(true);

        /***(4) update chart**/
        xAxisPos = measuredAxisHeight;
        // console.log("向上方移动图表", xAxisPos)
        svg.node().setAttribute("transform", `translate(0,-${xAxisPos})`); //向上方移动图表 
        this._y = y;

        // append the rectangles for the bar chart
        if (hasSeries) {
            svg.selectAll(".barSeries")
                .data(seriesData)
                .enter()
                .append("g")
                .attr("class", "barSeries")
                .attr("fill", (d, i) => Color.CATEGORICAL[i])
                .selectAll("bars")
                .data(d => d)
                .enter()
                .append("rect")
                .attr("class", "bars")
                .attr("x", (d, i) => x(calculateData[i].key))
                .attr("y", d => y(d[1]))
                .attr("height", d => y(d[0]) - y(d[1]) > 0 ? y(d[0]) - y(d[1]) : 0)
                .attr("width", x.bandwidth());

        } else {
            svg
                .append("g")
                .lower()
                .datum(seriesData[0])
                .attr("class", "barSeries")
                .attr("fill", Color.DEFAULT)
                .selectAll("bars")
                .data(d => d)
                .enter()
                .append("rect")
                .attr("class", "bars")
                .attr("x", (d, i) => x(d.data[breakdown[0].field]))
                .attr("y", d => y(d[1]))
                .attr("height", d => y(d[0]) - y(d[1]) > 0 ? y(d[0]) - y(d[1]) : 0)
                .attr("width", x.bandwidth());

        }

        if (this.style() === Style.COMICS) {
            let metaphorWidth = width * 0.22,
                metaphorHeight = 1.12 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor9);

            if (this.size() === Size.WIDE) {
                metaphorWidth = width * 0.18;
                metaphorHeight = 1.12 * metaphorWidth;
            } else if (this.size() === Size.MIDDLE || this.size() === Size.SMALL) {
                metaphorWidth = width * 0.20;
                metaphorHeight = 1.12 * metaphorWidth;
            }
            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", endX + metaphorWidth * 0.05)
                .attr("y", height - metaphorHeight * 0.96);
            //center居中
            svg.attr("transform", "translate(" + ((this.width() - svg.node().getBBox().width)/2 - svg.node().getBBox().x)  + "," + ((this.height() - svg.node().getBBox().height)/2 - svg.node().getBBox().y) + ")"); 
        }
        //finally update chart horizental cental
        updateChartCenter(svg, this.width(), this.height());
        return svg;
    }
    displayTrend() {
        let factdata = this.factdata();
        let measure = this.measure(),
            measuredField = measure[0].aggregate === 'count' ? "COUNT" : measure[0].field;
        let breakdown = this.breakdown();
        let hasSeries = false;
        if (breakdown[1] && breakdown[1].field) hasSeries = true;
        // set the dimensions and margins of the graph
        let chartSize = {
            width: this.width(),
            height: this.height()
        }
        let { tickFontSize, tickStrokeWidth, annotationSize, margin, strokeWidth } = getSizeBySize(chartSize, this.size(), hasSeries);
        let width = chartSize.width,
            height = chartSize.height;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("class", "svg")
            .attr("display", "block")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("display", "block")
            .attr("class", "chartG");

        if (this.measure().length > 1) {
            return
        }

        let data = factdata;
        if (breakdown[0].type === 'temporal') {
            data = data.sort(sortByDateAscending(breakdown))
        }
        let seriesData;
        let calculateData;
        let maxYValue = getMaxYValue(factdata, measure);
        if (hasSeries) {
            calculateData = d3.nest().key(d => d[breakdown[0].field]).entries(data);

            let categories = Array.from(new Set(data.map(d => d[breakdown[1].field])));
            categories = categories.slice(0, 10);
            let objList = new Array(calculateData.length)
            for (let i = 0; i < calculateData.length; i++) {
                let obj = {};
                calculateData[i].values.map((d, i) => {
                    obj.x = d[breakdown[0].field]
                    obj[d[breakdown[1].field]] = d[measuredField]
                    return obj;
                })
                objList[i] = obj
            }
            // complete the missed data = 0
            for (let k = 0; k < calculateData.length; k++) {
                for (let i = 0; i < categories.length; i++) {
                    if (!objList[k].hasOwnProperty(categories[i])) {
                        objList[k][categories[i]] = 0;
                    }
                }
            }
            seriesData = d3.stack()
                .keys(categories)
                (objList);
        } else {
            data.map(data => {
                data.maxValue = (maxYValue - data[measuredField]);
                return data;
            })
            seriesData = d3.stack()
                .keys([measuredField])
                (data);
        }
        seriesData = seriesData.slice(0, 10);

        if (this.style() === Style.COMICS) {
            if (hasSeries) {
                width = (this.size() === Size.LARGE && hasSeries) ? width : 0.85 * width;
            } else if (seriesData[0].length > 7) width *= 0.85;
            else if (this.size() === Size.WIDE) {
                height *= 0.85;
            } else if (this.size() !== Size.LARGE) {
                height *= 0.9;
            }
        }

        let contentG = svg.append("g");

        // append legend before chart 
        let measuredWidth = 0;
        if (hasSeries) {
            let rightLegendsW = margin.right,//inital
                measuredHeight = 0;
            let seriesName = d3.map(seriesData, function (d) { return d.key; }).keys();
            svg.append("foreignObject")
                .attr("x", chartSize.width - margin.right)
                .attr("y", height / 2)
                .attr("width", rightLegendsW)
                .attr("height", height)
                .attr("class", "foreignObject")
                .append("xhtml:div")
                .attr("class", "legends")
                .style("display", "flex")
                .style("flex-direction", "column")
                // .style("flex-wrap", "wrap")
                // .style("align-content", "space-around")
                // .style("height", height + "px")
                .style("height", "100%")
                .selectAll(".legend")
                .data(seriesName)
                .enter()
                .append("xhtml:div")
                .attr("class", "legend")
                .style("line-height", 1)
                .style("margin-right", 5 * chartSize.width / 640 + "px")
                .each(function (d, i) {
                    let legend = d3.select(this).append("svg")
                    legend.append("rect")
                        .attr("fill", d => Color.CATEGORICAL[seriesName.indexOf(d)])
                        .attr("width", 10 * tickFontSize / 12)
                        .attr('height', 10 * tickFontSize / 12)
                        .attr("rx", 1.5 * chartSize.width / 640)
                        .attr("ry", 1.5 * chartSize.width / 640)
                    legend.append("text")
                        .attr("fill", Color.TEXT)
                        .attr("x", 12 * tickFontSize / 12)
                        .text(d)
                        .attr("font-size", tickFontSize * 0.8)
                        .attr("font-family", NUMFONT)
                        .attr("alignment-baseline", "hanging");

                    legend.attr("width", legend.node().getBBox().width);
                    legend.attr("height", legend.node().getBBox().height);
                    let selfWidth = legend.node().getAttribute("width"),
                        selfHeight = legend.node().getAttribute("height");
                    if (Number(selfWidth) > Number(measuredWidth)) {
                        measuredWidth = Number(selfWidth);
                    }
                    measuredHeight += Number(selfHeight);
                });
            //update legend center
            measuredWidth = measuredWidth + margin.right;
            let yPos = (chartSize.height - measuredHeight) / 2;
            if (margin.right < measuredWidth) {
                svg.select(".foreignObject").node().setAttribute("x", this.width() - measuredWidth - margin.right / 2);
            }

            svg.select(".foreignObject").node().setAttribute("y", yPos);
            svg.select(".foreignObject").node().setAttribute("width", Number(measuredWidth));
            svg.select(".foreignObject").node().setAttribute("height", height);
        }
        /***(1) append yAxis  to measure the leftTick width **/
        let maxY;
        if (hasSeries) {
            maxY = d3.max(seriesData[seriesData.length - 1], d => d[1])
        } else {
            maxY = d3.max(data, d => d[measuredField]);
        }
        let startY = height,
            endY = 0; //inital
        initYAixsStyle(startY, endY, maxY, svg, tickStrokeWidth, tickFontSize, margin);
        /***(2) append xAxis**/
        let measuredYFieldsWidth = svg.select(".yAxis").node().getBBox().width + margin.left;
        // console.log("measuredYFieldsWidth", measuredYFieldsWidth)
        let starX = measuredYFieldsWidth,
            endX = width - measuredWidth - margin.right,
            xAxisPos = height;//inital 
        let xDomain = data.map(function (d) { return d[breakdown[0].field]; });
        initXAxisStyle(starX, endX, xDomain, svg, xAxisPos, tickStrokeWidth, tickFontSize);

        /***(3) check rotation before update y**/
        let x = d3.scaleBand()
            .domain(xDomain)
            .range([starX, endX])
            .padding(0.5),
            xAxis = svg.select(".xAxis");

        let unsupportedchartPrams = {
            svg,
            chartSize,
            annotationSize,
            size: this.size()
        }
        let isShowSuggestion = this.showSuggestion();
        let result = checkXAxis(xAxis, x, breakdown[0].type, unsupportedchartPrams, isShowSuggestion);
        if (result === "unsupportedChart")
            return svg;
        checkMaxAxisHeight(x, this.height(), tickFontSize, svg);
        //update y 
        let measuredAxisHeight = xAxis.node().getBBox().height + margin.bottom / 2;
        endY = measuredAxisHeight + margin.top;//y根据 x轴文字的高度动态缩减
        initYAixsStyle(startY, endY, maxY, svg, tickStrokeWidth, tickFontSize, margin);//更新endY

        let y = d3.scaleLinear().nice()
            .range([startY, endY])
            .domain([0, maxY])
            .clamp(true);

        /***(4) update chart**/
        xAxisPos = measuredAxisHeight;
        // console.log("向上方移动图表", xAxisPos)
        svg.node().setAttribute("transform", `translate(0,-${xAxisPos})`); //向上方移动图表 
        this._y = y;

        // append the rectangles for the bar chart
        if (hasSeries) {
            contentG.selectAll(".barSeries")
                .data(seriesData)
                .enter()
                .append("g")
                .attr("class", "barSeries")
                .attr("fill", (d, i) => Color.CATEGORICAL[i])
                .selectAll("bars")
                .data(d => d)
                .enter()
                .append("rect")
                .attr("class", "bars")
                .attr("x", (d, i) => x(calculateData[i].key))
                .attr("y", d => y(d[1]))
                .attr("height", d => y(d[0]) - y(d[1]) > 0 ? y(d[0]) - y(d[1]) : 0)
                .attr("width", x.bandwidth());

        } else {
            contentG
                // .selectAll(".barSeries")
                //     .data()
                //     .enter()
                .append("g")
                .datum(seriesData[0])
                .attr("class", "barSeries")
                .attr("transform", `translate(0,0)`)
                .attr("fill", Color.DEFAULT)//(d, i) => (i === seriesData.length - 1) ? Color.BACKGROUND : )
                .attr("opacity", 0.5)
                .selectAll("bars")
                .data(d => d)
                .enter()
                .append("rect")
                .attr("class", "bars")
                .attr("x", (d, i) => x(d.data[breakdown[0].field]))
                .attr("y", d => y(d[1]))
                .attr("height", d => y(d[0]) - y(d[1]) > 0 ? y(d[0]) - y(d[1]) : 0)
                .attr("width", x.bandwidth());
        }

        //trend line
        if (hasSeries) {
            // for (let i = 0; i < seriesData.length; i++) {
            //     let trendLineG = d3.line()
            //         .x(function (d) { return x(d.data.x) + x.bandwidth() / 2; })
            //         .y(function (d) { return y(d[1]) });

            //     contentG.append("g").attr("class", "trendLine").append("path")
            //         .attr("d", trendLineG(seriesData[i]))
            //         .attr("fill", "none")
            //         .attr('stroke-width', d => {
            //             if (this.size() === 'small') return 1;
            //             if (this.size() === 'middle') return 2;
            //             if (this.size() === 'wide') return 3;
            //             if (this.size() === 'large') return 4;

            //         })
            //         .attr('stroke-dasharray', '5,5')
            //         .attr("stroke", Color.HIGHLIGHT);
            // }
        } else {
            let offsetY = this.size() === 'large' ? 5 : 2; //离x轴的距离
            let trendLineG = d3.line()
                .x(function (d) { return x(d[breakdown[0].field]) + x.bandwidth() / 2; })
                .y(function (d) { return y(d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]) - offsetY; });
            let trendline = contentG.append("g").attr("class", "trendLine");

            // console.log(data)
            // let firstY = seriesData[0][1],
            //     lastY = seriesData[seriesData.slice(-1)[0]][1];
            let _data = [data[0], data.slice(-1)[0]]
            
            trendline.append("path")
                .attr("d", trendLineG(_data))
                .attr("fill", "none")
                .attr('stroke-width', d => {
                    if (this.size() === 'small') return 2;
                    if (this.size() === 'middle') return 3;
                    if (this.size() === 'wide') return 4;
                    if (this.size() === 'large') return 5;

                })
                .attr('stroke-dasharray', '5,5')
                .attr("stroke", Color.HIGHLIGHT);

            let finalPosition = trendline.select("path").attr("d").split("L").slice(-1)[0];
            let secondPosition = trendline.select("path").attr("d").split("L").slice(-2)[0];
            secondPosition = secondPosition.substring(1)
            let f_x = finalPosition.split(",")[0],
                f_y = height - finalPosition.split(",")[1],
                s_x = secondPosition.split(",")[0],
                s_y = height - secondPosition.split(",")[1];
            function getTanDeg(tan) {
                var result = Math.atan(tan) / (Math.PI / 180);
                result = Math.round(result);
                return result;
            }
            let slope = (f_y - s_y) / (f_x - s_x)
            let deg;
            if (getTanDeg(slope) < 0) {
                deg = Math.abs(getTanDeg(slope)) + 90;
            } else {
                deg = - getTanDeg(slope) + 90;
            }
            
            trendline.append("path")
                .attr("class", "triangle")
                .attr("transform", "translate(" + finalPosition + ")rotate(" + deg + ")")
                .attr("d", d3.symbol().type(d3.symbolTriangle).size(0.16 * height))
                .attr("fill", Color.HIGHLIGHT);
        }

        if (this.style() === Style.COMICS) {
            if (hasSeries) {
                let metaphorWidth = width * 0.22,
                    metaphorHeight = 1.25 * metaphorWidth;

                let metaphor = svg.append("image")
                    .attr('xlink:href', metaphor2);

                if (this.size() === Size.WIDE) {
                    metaphorWidth = width * 0.18;
                    metaphorHeight = 1.25 * metaphorWidth;
                } else if (this.size() === Size.MIDDLE) {
                    metaphorWidth = width * 0.2;
                    metaphorHeight = 1.25 * metaphorWidth;
                }

                metaphor.attr("width", metaphorWidth)
                    .attr("height", metaphorHeight)
                    .attr("x", endX + metaphorWidth * 0.05)
                    .attr("y", height - metaphorHeight * 0.96);
            } else {
                let filterPoints = seriesData[0];
                if (filterPoints.length > 7) {//too much point
                    //draw dash line
                    let x0 = x(filterPoints[0].data[breakdown[0].field]) + x.bandwidth() / 2,
                        x1 = x(filterPoints.slice(-1)[0].data[breakdown[0].field]) + x.bandwidth() / 2,
                        y0 = y(filterPoints[0].data[measuredField]),
                        y1 = y(filterPoints.slice(-1)[0].data[measuredField]),
                        x2 = x1 + width * 0.1,
                        y2 = (x2 - x1) * (y1 - y0) / (x1 - x0) + y1;
                    let line_m = svg.append('line')
                        .attr("x1", x1)
                        .attr("x2", x2)
                        .attr("y1", y1)
                        .attr("y2", y2)
                        .attr("stroke", Color.DASHLINE)
                        .attr("stroke-width", strokeWidth)
                        .attr("stroke-dasharray", `${strokeWidth * 2}, ${strokeWidth}`);
                    svg.node().prepend(line_m.node());

                    let metaphorWidth = width * 0.22,
                        metaphorHeight = 1.24 * metaphorWidth;

                    let metaphor = svg.append("image")
                        .attr('xlink:href', metaphor6);

                    if (this.size() === Size.WIDE) {
                        metaphorWidth = width * 0.18;
                        metaphorHeight = 1.24 * metaphorWidth;
                    } else if (this.size() === Size.MIDDLE || this.size() === Size.SMALL) {
                        metaphorWidth = width * 0.2;
                        metaphorHeight = 1.24 * metaphorWidth;
                    }

                    metaphor.attr("width", metaphorWidth)
                        .attr("height", metaphorHeight)
                        .attr("x", x2 - metaphorWidth * 0.06)
                        .attr("y", y2 - metaphorHeight * 0.06);

                } else {
                    const metaphorWidth7 = (endX - starX) / (filterPoints.length - 1) * 0.6,
                        metaphorWidth8 = metaphorWidth7 / 1.14;
                    const metaphorHeight7 = metaphorWidth7 * 0.95;
                    const metaphorHeight8 = metaphorWidth8 * 1.2;
                    let offsetY = this.size() === 'large' ? 5 : 2; //离x轴的距离
                    for (let i = 1; i < filterPoints.length; i++) {
                        let middleX = (x(filterPoints[i].data[breakdown[0].field]) + x(filterPoints[i - 1].data[breakdown[0].field])) / 2 + x.bandwidth() / 2;
                        let middleY = (y(filterPoints[i].data[measuredField]) + y(filterPoints[i - 1].data[measuredField])) / 2 - offsetY;
                        if (filterPoints[i].data[measuredField] - filterPoints[i - 1].data[measuredField] > 0) {//up
                            svg.append("image")
                                .attr('xlink:href', metaphor8)
                                .attr("width", metaphorWidth8)
                                .attr("height", metaphorHeight8)
                                .attr("x", middleX - metaphorWidth8 * 0.7)
                                .attr("y", middleY - metaphorHeight8 * 0.96);
                        } else {//down
                            svg.append("image")
                                .attr('xlink:href', metaphor7)
                                .attr("width", metaphorWidth7)
                                .attr("height", metaphorHeight7)
                                .attr("x", middleX - metaphorWidth7 * 0.5)
                                .attr("y", middleY - metaphorHeight7 * 1);
                        }
                    }
                }
                //center居中
                svg.attr("transform", "translate(" + ((this.width() - svg.node().getBBox().width) / 2 - svg.node().getBBox().x) + "," + ((this.height() - svg.node().getBBox().height) / 2 - svg.node().getBBox().y) + ")");
            }
        }
        //finally update chart horizental cental
        updateChartCenter(svg, this.width(), this.height());
        return svg;
    }
}

const displayExtremeAndOutlier = (_this, factType) => {
    let factdata = _this.factdata();
    let measure = _this.measure(),
        measuredField = measure[0].aggregate === 'count' ? "COUNT" : measure[0].field;
    let breakdown = _this.breakdown();
    let focus = _this.focus();
    // set the dimensions and margins of the graph
    let chartSize = {
        width: _this.width(),
        height: _this.height()
    };
    let { tickStrokeWidth, tickFontSize, margin, hightLightFontSize, annotationSize } = getSizeBySize(chartSize, _this.size()),
        width = chartSize.width,
        height = chartSize.height;

    let svg = d3.select(_this.container())
        .append("svg")
        .attr("class", "svg")
        .attr("width", chartSize.width)
        .attr("height", chartSize.height)
        .append("g")
        .attr("display", "block")
        .attr("class", "chartG");
    if (_this.style() === Style.COMICS) width = 0.8 * width;
    if (_this.breakdown().length > 1 || _this.measure().length > 1) {
        return
    }

    // if breakdown is temporal, order factData
    if (breakdown[0] && breakdown[0].type === 'temporal') {
        factdata = factdata.sort(sortByDateAscending(breakdown))
    }
    let data = factdata;

    let contentG = svg.append("g");
    let seriesData;
    let maxYValue = getMaxYValue(factdata, measure);

    data.map(data => {
        data.maxValue = (maxYValue - data[measuredField]);
        return data;
    })
    seriesData = d3.stack()
        .keys([measuredField, "maxValue"])
        (data);

    seriesData = seriesData.slice(0, 10);

    let startY = height - 40,
        endY = margin.top; //inital
    let maxY = d3.max(data, d => d[measuredField]);

    initYAixsStyle(startY, endY, maxY, svg, tickStrokeWidth, tickFontSize, margin);

    /***(1) append xAxis**/
    // let starX = margin.left,
    //     endX = width - margin.right,
    //     xAxisPos = height;//inital 
    let measuredYFieldsWidth = svg.select(".yAxis").node().getBBox().width + margin.left;
    let starX = measuredYFieldsWidth,
        endX = width - margin.right,
        xAxisPos = height - 40;//inital 
    let xDomain = data.map(function (d) { return d[breakdown[0].field]; });
    initXAxisStyle(starX, endX, xDomain, svg, xAxisPos, tickStrokeWidth, tickFontSize);

    /***(2) check rotation before update **/
    let x = d3.scaleBand()
        .domain(xDomain)
        .range([starX, endX])
        .padding(0.5),
        xAxis = svg.select(".xAxis");
    let hasOneValue = true;
    checkAndRotate(xAxis, x, hasOneValue);
    checkMaxAxisHeight(x, _this.height(), tickFontSize, svg);
    //add style
    xAxis.selectAll('.tick text')
        .attr('dy', '0.7em')
        .attr('font-family', d => {
            if (d === focus[0].value) {
                return TEXTFONT;
            }
            return NUMFONT;
        })
        .attr('font-size', hightLightFontSize)
        .attr("display", d => {
            if (d === focus[0].value) {
                return "block";
            }
            return "none";
        })
        .attr('fill', d => {
            if (d === focus[0].value)
                return Color.HIGHLIGHT
        });
    xAxis.selectAll('.tick line')
        .attr("display", d => {
            if (d === focus[0].value) {
                return "block";
            }
            return "none";
        });

    /***(3) update y**/
    let measuredAxisHeight = xAxis.node().getBBox().height + margin.bottom / 2;
    endY = measuredAxisHeight + margin.top * 3;//y根据 x轴文字的高度动态缩减 //3倍中，有一部分是tooltip的高度

    /***(4) update chart**/
    xAxisPos = measuredAxisHeight;
    // console.log("向上方移动图表", xAxisPos)
    svg.node().setAttribute("transform", `translate(0,-${xAxisPos})`); //向上方移动图表 

    let y = d3.scaleLinear().nice()
        .range([startY, endY])
        .domain([0, maxY])
        .clamp(true);
    _this._y = y;
    // append the rectangles for the bar chart
    contentG
        .append("g")
        .lower()
        .datum(seriesData[0])
        .attr("class", "barSeries")
        .selectAll("bars")
        .data(d => d)
        .enter()
        .append("rect")
        .attr("class", "bars")
        .attr("fill", (d, i) => {
            if (d.data[focus[0].field] === focus[0].value) {
                return Color.HIGHLIGHT;
            }
            return Color.DEFAULT;
        })
        .attr("x", (d, i) => x(d.data[breakdown[0].field]))
        .attr("y", d => y(d[1]))
        .attr("height", d => y(d[0]) - y(d[1]) > 0 ? y(d[0]) - y(d[1]) : 0)
        .attr("width", x.bandwidth());

    //tool tip
    let focusValueArray = factdata.filter(d => {
        return d[focus[0].field] === focus[0].value
    });
    let focusData = focusValueArray[0];
    let toolTipX = x(focus[0].value) + x.bandwidth() / 2,//箭头中心所在x的位置
        toolTipY = y(focusData[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]) - 40 * chartSize.height / 640, //箭头中心所在y的位置
        toolTipValue = formatNumber(focusData[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]);
    generateToolTip(toolTipX, toolTipY, toolTipValue, contentG, chartSize, annotationSize * 1.5);
    svg.selectAll(".tooltip").attr("font-weight", 500)
    // contentG.append("text")
    //     .attr("x", toolTipX)
    //     .attr("y", toolTipY)
    //     .attr('font-size', hightLightFontSize)
    //     .attr('font-family', NUMFONT)
    //     .attr('fill', Color.HIGHLIGHT)
    //     .attr('text-anchor', 'middle')
    //     .text(toolTipValue);

    if (_this.style() === Style.COMICS) {
        if (factType === "Outlier") {
            let metaphorWidth = width * 0.24,
                metaphorHeight = 1.43 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor17);

            if (_this.size() === Size.WIDE) {
                metaphorWidth = width * 0.20;
                metaphorHeight = 1.43 * metaphorWidth;
            } else if (_this.size() === Size.MIDDLE) {
                metaphorWidth = width * 0.22;
                metaphorHeight = 1.43 * metaphorWidth;
            }

            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", width * 0.92)
                .attr("y", height - metaphorHeight * 0.96);
        } else if (factType === "Extreme") {
            let metaphorWidth = width * 0.24,
                metaphorHeight = 1.34 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor18);

            if (_this.size() === Size.WIDE) {
                metaphorWidth = width * 0.20;
                metaphorHeight = 1.34 * metaphorWidth;
            } else if (_this.size() === Size.MIDDLE) {
                metaphorWidth = width * 0.22;
                metaphorHeight = 1.34 * metaphorWidth;
            }

            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", width * 0.92)
                .attr("y", height - metaphorHeight * 0.96);
        }
        //center居中
        svg.attr("transform", "translate(" + ((_this.width() - svg.node().getBBox().width) / 2 - svg.node().getBBox().x) + "," + ((_this.height() - svg.node().getBBox().height) / 2 - svg.node().getBBox().y) + ")");
    }
    //finally update chart horizental cental
    updateChartCenter(svg, _this.width(), _this.height());
    return svg;
}

/******
 * 
 * 
 */
const getMaxY = (maxYValue) => {
    if ((maxYValue / 1000000) >= 1) {
        maxYValue = Math.ceil(maxYValue / 1000000) * 1000000;
    } else if ((maxYValue / 1000) >= 1) {
        maxYValue = Math.ceil(maxYValue / 1000) * 1000;
    } else if ((maxYValue / 100) >= 1) {
        // maxYValue = Math.ceil(maxYValue / 1000) * 1000;
    }
    return maxYValue
}
/***
 * 检查并且设置x轴label的高度不超过chart height的1/2
 */
const checkMaxAxisHeight = (x, chartHeight, tickSize, svg) => {
    let xAxis = svg.select(".xAxis"),
        maxXAxisH = chartHeight / 4;
    if (xAxis.node().getBBox().height > maxXAxisH) {
        xAxis.selectAll('.tick text').call(g => {
            changXFildStyle(g, x, maxXAxisH, tickSize, svg);
        });
    }
}
/** 
 * 对lable文字的在最大高度内显示,超出显示...
**/
const changXFildStyle = (g, x, maxXAxisH, tickSize, svg) => {
    let gG = g._groups[0];
    for (let i = 0; i < gG.length; ++i) {
        let selfWidth = gG[i].getBBox().width;
        if (selfWidth > maxXAxisH) {
            let str = gG[i].innerHTML,
                displayStr = '';
            for (let index = str.length - 1; index > 0; index--) {
                let str1 = str.substring(0, index)
                let str1Node = svg
                    .append('g')
                    .append("text")
                    .attr("class", 'virtumDom')
                    .attr('font-size', tickSize)
                    .attr('font-family', 'Arial-Bold')
                    .text(str1);
                if (str1Node.node().getBBox().width > maxXAxisH) { //查找能放下的最小字符串长度
                    svg.select(".virtumDom").remove()
                    continue;
                }
                svg.select(".virtumDom").remove()
                displayStr = str1
                //console.log("最终可以显示的字符串是", str1)
                break;
            }
            gG[i].innerHTML = displayStr + '...'
        }
    }
}

/***
 * 设置 xAxis样式并且更新位置
 */
const initXAxisStyle = (starX, endX, xDomain, svg, xAxisPos, tickStrokeWidth, tickFontSize) => {
    //console.log("initXAxisStyle", starX, endX)
    let x = d3.scaleBand()
        .domain(xDomain)
        .range([starX, endX])
        .padding(0.5);

    // add the x Axis
    //let xAxis = 
    svg.append("g")
        .attr("class", 'xAxis')
        .attr("transform", `translate(0,${xAxisPos})`) //动态计算高度后平移会向上平移
        .call(d3.axisBottom(x)
            .tickSize(tickStrokeWidth * 2)
            .tickPadding(tickStrokeWidth * 2))
        .call(g => {
            //x Axis style
            let xAxislineData = [[starX, 0], [endX, 0]]
            let newD = d3.line()(xAxislineData) //生成d
            g.select('.domain')
                .attr('d', newD)
                .attr('stroke', 'black')
                .attr("stroke-width", tickStrokeWidth);

            //tick style
            g.selectAll('.tick line')
                .attr('stroke', 'black')
                .attr("stroke-width", tickStrokeWidth);

            g.selectAll('.tick text')
                .attr('font-size', tickFontSize)
                .attr('font-family', NUMFONT)
                .attr('fill', 'black')
                .attr('text-anchor', 'middle');
        })


}
/***
 * 设置 yAxis样式并且更新位置
 */
const initYAixsStyle = (startY, endY, maxY, svg, tickStrokeWidth, tickFontSize, margin) => {
    svg.selectAll(".yAxis").remove();

    let mockY = d3.scaleLinear().nice()
        .range([startY, endY])
        .domain([0, maxY])
        .clamp(true);

    let ticks = 6; //inital 为2的倍数
    if ((startY - endY) < 50) { //50px 
        ticks = 4; //todo 动态计算
    }

    svg.append("g")
        .lower()
        .attr('class', 'yAxis')
        .call(d3.axisLeft(mockY)
            .ticks(ticks)
            .tickSize(tickStrokeWidth * 2)
            .tickPadding(tickStrokeWidth * 2)
            .tickFormat(function (d) {
                if ((d / 1000000) >= 1) {
                    d = d / 1000000 + "M";
                } else if ((d / 1000) >= 1) {
                    d = d / 1000 + "K";
                }
                return d;
            }))
        .call(g => {
            let YAxislineData = [[0, startY], [0, endY], [-tickStrokeWidth * 2, endY]];
            let newYD = d3.line()(YAxislineData); //生成d
            g.select('.domain')
                .attr("d", newYD)
                .attr('stroke', 'black')
                .attr("stroke-width", tickStrokeWidth)
            g.selectAll('.tick line')
                .attr('stroke', 'black')
                .attr("stroke-width", tickStrokeWidth)
            g.select('.tick line').remove();
            g.selectAll('.tick text')
                .attr('font-size', tickFontSize)
                .attr('font-family', NUMFONT)
                .attr('fill', 'black')
                .attr('text-anchor', 'end');
        })
    /*****the end*****/
    let measuredYFieldsWidth = svg.select(".yAxis").node().getBBox().width + margin.left;
    //平移y
    svg.select(".yAxis").attr("transform", `translate(${measuredYFieldsWidth},0)`);
}

/***
 * 判断是否旋转,需要的话，处理
 * hasOneValue不需要旋转
 */
const checkAndRotate = (xAxis, x, hasOneValue = false) => {
    let isRotate = false;
    xAxis.selectAll('.tick text')
        .each(function (d, i) {
            let text = d3.select(this).node();
            if (text.getBBox().width > x.step()) {
                isRotate = true;
            }
        });
    if (!hasOneValue && isRotate) {
        xAxis.selectAll("text")
            .attr("transform", `translate(-${5},0)rotate(-45)`)
            .attr("text-anchor", "end"); //旋转45度
    }
}
/***
 * 判断是否旋转,缩略，还是显示unsupported chart
 */
const checkXAxis = (xAxis, x, xFiledType, unsupportedchartPrams, isShowSuggestion) => {
    //【1】检查是否有遮挡，如果遮挡则需要旋转45度
    let isRotate = false;
    let { svg, chartSize, annotationSize, size, } = unsupportedchartPrams;
    xAxis.selectAll('.tick text')
        .each(function (d, i) {
            let text = d3.select(this).node();
            if (text.getBBox().width > x.step()) {
                isRotate = true;
            }
        });

    let labelHeight;
    xAxis.select('.tick text')
        .each(function (d, i) {
            let text = d3.select(this).node();
            labelHeight = text.getBBox().height;
        });
    if (isRotate) {
        xAxis.selectAll("text")
            .attr("transform", `translate(-${5},0)rotate(-45)`)
            .attr("text-anchor", "end"); //旋转45度
        let rotateAble = labelHeight < x.step() * 1.2;
        // console.log("rotateAble", labelHeight, x.step() * 1.5)
        // 【2】如果横的有遮挡并且是temporal数据类型，则摆回正的，缩略显示
        if (!rotateAble) {
            if (xFiledType === 'temporal') {
                xAxis.selectAll(".xAxis text")
                    .attr("transform", "")
                    .attr("text-anchor", "middle");
                removeOverlapX(xAxis, x)
            } else {
                //【3】显示unsupportedChart 关闭
                if (isShowSuggestion) {
                    svg.select(".chartG").remove();//清除画布
                    svg.select(".yAxis").remove();//清除画布 
                    svg.select(".xAxis").remove();//清除画布    
                    unsupportedchart(svg, chartSize, annotationSize, size);
                    return "unsupportedChart";
                }
            }
        }
    }
    return "";
}
/*
*  y最大值向上取整 如y轴的最大数值为6，853，129； 要取到7，000，000
*/
const getMaxYValue = (factdata, measure, isProportion = false) => {
    let maxYValue = d3.max(factdata, d => {
        return d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field];
    })
    if (isProportion) {
        if ((maxYValue / 1000000) >= 1) {
            maxYValue = Math.ceil(maxYValue / 1000000) * 1000000;
        } else if ((maxYValue / 1000) >= 1) {
            maxYValue = Math.ceil(maxYValue / 1000) * 1000;
        }
    }

    return maxYValue
}

/** 
 * tickFontSize 坐标轴刻度字号
 * annotationSize 标注字号
 * tickStrokeWidth 坐标轴刻度宽度
**/
const getSizeBySize = (chartSize, size, hasSeries = false) => {
    let tickFontSize, tickStrokeWidth, annotationSize, hightLightFontSize, arrowWidth, strokeWidth;
    switch (size) {
        case Size.WIDE:
            tickFontSize = 16;
            annotationSize = 15;
            tickStrokeWidth = 2;
            hightLightFontSize = 26;
            arrowWidth = 4;
            strokeWidth = 3;
            break;
        case Size.MIDDLE:
            tickFontSize = 14;
            annotationSize = 15;
            tickStrokeWidth = 2;
            hightLightFontSize = 20;
            arrowWidth = 3;
            strokeWidth = 3;
            break;
        case Size.SMALL:
            tickFontSize = 12;
            annotationSize = 10;
            tickStrokeWidth = 1.5;
            hightLightFontSize = 16;
            arrowWidth = 2;
            strokeWidth = 2;
            break;
        case Size.LARGE:
        default:
            tickFontSize = 20;
            annotationSize = 20;
            tickStrokeWidth = 3;
            hightLightFontSize = 40;
            arrowWidth = 5;
            strokeWidth = 3;
            break;
    }

    return {
        tickFontSize: tickFontSize,
        annotationSize: annotationSize,
        tickStrokeWidth: tickStrokeWidth,
        hightLightFontSize: hightLightFontSize,
        arrowWidth: arrowWidth,
        margin: {
            top: 60 * chartSize.height / 640,
            left: 50 * chartSize.width / 640,
            right: 50 * chartSize.width / 640,
            bottom: 60 * chartSize.height / 640,//默认x轴的高度 
        },
        strokeWidth: strokeWidth
    }
};

const countTicksBeforeIndex = (animation, index) => {
    let count = 0;
    let visited = []
    for (let key in animation) {
        if (animation[key].index < index && visited.indexOf(animation[key].index) === -1) {
            count += animation[key].duration
            visited.push(animation[key].index)
        };
    }
    return count
}

const sortByDateAscending = (breakdown) => {
    // Dates will be cast to numbers automagically:
    return function (a, b) {
        return parseTime(a[breakdown[0].field]) - parseTime(b[breakdown[0].field]);
    }
}

const parseTime = (date) => {
    if (d3.timeParse("%Y-%m-%d")(date))
        return d3.timeParse("%Y-%m-%d")(date);
    else if (d3.timeParse("%Y/%m/%d")(date))
        return d3.timeParse("%Y/%m/%d")(date);
    else if (d3.timeParse("%Y-%m")(date))
        return d3.timeParse("%Y-%m")(date);
    else if (d3.timeParse("%Y/%m")(date))
        return d3.timeParse("%Y/%m")(date);
    else if (d3.timeParse("%Y")(date))
        return d3.timeParse("%Y")(date);
    else return date
}

// const getLeastSquares = (X, Y) => {
//     let ret = {}

//     let sumX = 0
//     let sumY = 0
//     let sumXY = 0
//     let sumXSq = 0
//     let N = X.length

//     for (let i = 0; i < N; ++i) {
//         sumX += X[i]
//         sumY += Y[i]
//         sumXY += X[i] * Y[i]
//         sumXSq += X[i] * X[i]
//     }

//     ret.m = ((sumXY - sumX * sumY / N)) / (sumXSq - sumX * sumX / N)
//     ret.b = sumY / N - ret.m * sumX / N

//     return ret;
// }


export default VerticalBarChart;

