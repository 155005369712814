import * as d3 from 'd3';
import Chart from '../../chart';
import Color from '../../visualization/color';
// import generateToolTip from '../../visualization/tooltip';
import Size from '../../visualization/size';
import Style from '../../visualization/style';

import metaphor20 from '../../metaphor/metaphor20.png';
const TEXTFONT = "Arial-Bold";

class ProgressChart extends Chart {
    constructor() {
        super();
        this._x = '';
    }

    animateProportion() {
        /* -------------------------------- basic vis ------------------------------- */
        let svg = this.displayProportion();
        if (!svg) return;

        /* -------------------------------- init data ------------------------------- */
        let ticks = 10;
        let duration = this.duration();

        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            bgFadeIn: {
                duration: 1,
                index: 0
            },
            labelFadeIn: {
                duration: 3,
                index: 1
            },
            majorGrow: {
                duration: 6,
                index: 2
            }
        }
        let everyTick = duration / ticks;

        /* --------------------------- step 0 bgFadeIn --------------------------- */
        let bgBar = svg.selectAll(".barBgSeries");

        bgBar.attr("opacity", 0);

        bgBar.transition()
            .duration(everyTick * animation.bgFadeIn.duration)
            .attr("opacity", 1);

        /* ----------------------------- step 1 labelFadeIn ----------------------------- */
        let legend = svg.selectAll(".legend");
        legend.attr("opacity", 0);

        setTimeout(() => {
            legend.transition()
                .duration(everyTick * (animation.labelFadeIn.duration))
                .attr("opacity", 1)
        }, everyTick * (countTicksBeforeIndex(animation, animation.labelFadeIn.index) + 0.5))

        /* ---------------------------- step 2 majorGrow ---------------------------- */

        let bars = svg.selectAll(".barSeries");

        bars.attr("width", 0)

        let x = this._x;
        setTimeout(() => {
            bars.transition()
                .duration(everyTick * (animation.majorGrow.duration + 0.5))
                .attr("width", d => x(d[0][1]) - x(d[0][0]))
        }, everyTick * countTicksBeforeIndex(animation, animation.majorGrow.index))

        let tooltip = svg.selectAll(".tooltip").select("text");
        tooltip.text("0%").attr("opacity", 0);

        setTimeout(() => {
            tooltip.attr("opacity", 1)
            tooltip.transition()
                .duration(everyTick * (animation.majorGrow.duration + 0.5))
                .textTween(function (d) {
                    let final = d3.select(this).property("_value").split("%")[0];
                    const i = d3.interpolate(0, final);
                    var numberFormat = d3.format(".0f");
                    return function (t) {
                        var percent = numberFormat(i(t));
                        return percent + "%";
                    };
                });
        }, everyTick * countTicksBeforeIndex(animation, animation.majorGrow.index))


    }
    displayProportion() {
        // set the dimensions and margins of the graph
        let { chartSize, margin, hightLightFontSize } = getSizeBySize(this),
            width = chartSize.width - margin.left - margin.right,
            height = chartSize.height - margin.top - margin.bottom;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", chartSize.width)
            .attr("height", chartSize.height)
            .append("g")
            .attr("class", "elementGroup")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        if (this.focus().length !== 1 || this.measure().length > 1) {
            return;
        }

        let focus = this.focus();
        let filteredData = [] //sorted by focus
        for (const fs of focus) {
            this.factdata().filter((x) => x[fs.field] === fs.value)[0] && filteredData.push(this.factdata().filter((x) => x[fs.field] === fs.value)[0])
        }

        let measure = this.measure();
        let breakdown = this.breakdown();
        let maxYValue = d3.sum(this.factdata(), d => d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field])

        //console.log('11', maxYValue)
        filteredData.map(data => {
            data.maxValue = (maxYValue - data[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]);
            return data;
        })
        //console.log('showDifference in child')
        if (filteredData.length === 0) {
            return;
        }
        let data = filteredData;

        let seriesData = d3.stack()
            .keys([measure[0].aggregate === "count" ? "COUNT" : measure[0].field, "maxValue"])
            (data).sort((a, b) => b[0][0] - a[0][0]); //控制背景色先绘制
        if (this.style() === Style.COMICS) {
            const a1 = seriesData[0][0][0],
                a2 = seriesData[0][0][1];
            if (a1 > a2 * 0.85) {
                width -= width * (a1 / a2 - 0.85);
            }
        }
        // set the ranges
        let x = d3.scaleLinear()
            // .nice()
            .domain([0, maxYValue])
            .range([0, width]);

        this._x = x;

        let y = d3.scaleBand()
            .domain(data.map(function (d) { return d[breakdown[0].field]; }))
            .range([height, 0])
            .padding(0.6);

        //append the rectangles for the bar chart
        svg.selectAll(".bars")
            .data(seriesData)
            .enter()
            .append("rect")
            .attr("class", (d, i) => {
                return d.key !== "maxValue" ? "barSeries bars" : "barBgSeries bars"
            })
            .attr("fill", (d, i) => {
                if (d.key !== "maxValue") { //value bar
                    return Color.HIGHLIGHT;
                } else {
                    return Color.DEFAULT
                }
            })
            .attr("y", (d) => {
                return y(d[0].data[breakdown[0].field])
            })
            .attr("x", 0)
            .attr("width", (d, i) => {
                return d.key === "maxValue" ? width : x(d[0][1]) - x(d[0][0]);
            })
            .attr("height", y.bandwidth());

        // add the x Axis
        svg.append("g")
            .attr("class", 'xAxis')
            // .attr("transform", `translate(0,${height})`)
            .call(d3.axisBottom(x))
            .call(g => {
                g.select('.domain').remove();
                g.selectAll('.tick line').remove();
                g.selectAll('.tick text').remove();
            });

        // add the y Axis
        svg.append("g")
            .attr('class', 'yAxis')
            .call(d3.axisRight(y))
            .call(g => {
                g.select('.domain').remove();
                g.selectAll('.tick line').remove();
                g.selectAll(".tick text").remove();
            });

        //tool tip
        let proportionValue = data[0][measure[0].aggregate === "count" ? "COUNT" : measure[0].field] / d3.sum(this.factdata(), d => d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]);
        // let toolTipX = x(data[0][measure[0].aggregate === "count" ? "COUNT" : measure[0].field]) / 2,//箭头中心所在x的位置
        //     toolTipY = y(data[0][breakdown[0].field]) - 40 * chartSize.height / 320,
        let toolTipValue = (proportionValue * 100).toFixed(0) + "%";
        //console.log("proportionValue", proportionValue, data)
        // generateToolTip(toolTipX, toolTipY, toolTipValue, svg, chartSize, annotationSize);
        svg.append("g").attr("class", "tooltip")
            .append("text")
            .attr("x", width / 2)
            .attr("y", y(data[0][breakdown[0].field]) - 40 * chartSize.height / 320)
            .attr("text-anchor", "middle")
            .attr("alignment-baseline", "baseline")
            .attr('font-family', TEXTFONT)
            .attr("font-size", hightLightFontSize)
            .attr("font-weight", 600)
            .attr("fill", Color.HIGHLIGHT)
            .text(toolTipValue)
            .property("_value", toolTipValue)


        //text
        svg.append("text").attr("class", "legend")
            .attr("x", width / 2)
            .attr("y", y(data[0][breakdown[0].field]) + y.bandwidth() + 40 * chartSize.height / 320)
            .text(focus[0].value.length > 22 ? focus[0].value.substring(0, 22) + "…" : focus[0].value)
            .attr('font-size', hightLightFontSize)
            .attr("font-weight", 600)
            .attr("alignment-baseline", "hanging")
            .attr('font-family', TEXTFONT)
            // .attr('fill', Color.HIGHLIGHT)
            .attr('text-anchor', 'middle');

        if (this.style() === Style.COMICS) {
            let metaphorWidth = width * 0.22,
                metaphorHeight = 1.2 * metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor20);

            if (this.size() === Size.WIDE) {
                metaphorWidth = width * 0.18;
                metaphorHeight = 1.2 * metaphorWidth;
            } else if (this.size() === Size.MIDDLE) {
                metaphorWidth = width * 0.2;
                metaphorHeight = 1.2 * metaphorWidth;
            }
            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", svg.select('.barSeries').attr("width") - metaphorWidth * 0.04)
                .attr("y", height / 2 - metaphorHeight * 0.4);
        }

        //center居中
        svg.attr("transform", "translate(" + ((this.width() - svg.node().getBBox().width) / 2 - svg.node().getBBox().x) + "," + ((this.height() - svg.node().getBBox().height) / 2 - svg.node().getBBox().y) + ")");

        return svg;
    }
}
/** 
 * tickSize 坐标轴字号 调整为：比标准尺寸大2px
 * annotationSize 标注字号
**/
const getSizeBySize = (_this) => {
    let tickSize, annotationSize, hightLightFontSize;
    switch (_this.size()) {
        case Size.WIDE:
            tickSize = 14;
            annotationSize = 15;
            hightLightFontSize = 26;
            break;
        case Size.MIDDLE:
            tickSize = 14;
            annotationSize = 15;
            hightLightFontSize = 20;
            break;
        case Size.SMALL:
            tickSize = 12;
            annotationSize = 10;
            hightLightFontSize = 16;
            break;
        case Size.LARGE:
        default:
            tickSize = 16;
            annotationSize = 30;
            hightLightFontSize = 40;
            break;
    }
    let margin;
    switch (_this.size()) {
        case Size.LARGE:
            margin = { top: 60, right: 40, bottom: 60, left: 40 };
            break;
        case Size.WIDE:
            margin = { top: 20, right: 50, bottom: 20, left: 50 };
            break;
        case Size.MIDDLE:
            margin = { top: 20, right: 20, bottom: 20, left: 20 };
            break;
        case Size.SMALL:
            margin = { top: 9, right: 15, bottom: 9, left: 15 };
            break;
        default:
            margin = { top: 50, right: 40, bottom: 50, left: 40 };
            break;
    }
    return {
        chartSize: {
            width: _this.width(),
            height: _this.height()
        },
        tickSize: tickSize,
        annotationSize: annotationSize,
        margin: margin,
        hightLightFontSize: hightLightFontSize
    }
};

const countTicksBeforeIndex = (animation, index) => {
    let count = 0;
    let visited = []
    for (let key in animation) {
        if (animation[key].index < index && visited.indexOf(animation[key].index) === -1) {
            count += animation[key].duration
            visited.push(animation[key].index)
        };
    }
    return count - 0.5
}
export default ProgressChart;