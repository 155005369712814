import * as d3 from 'd3';
import Chart from '../../chart';
import Color from '../../visualization/color';
import formatNumber from '../../visualization/format';
import Size from '../../visualization/size';
import Style from '../../visualization/style';

import metaphor4 from '../../metaphor/metaphor4.png';//value
// import metaphor27 from '../../metaphor/metaphor27.png';//difference up
import metaphor28 from '../../metaphor/metaphor28.png';//difference
import metaphor18 from '../../metaphor/metaphor18.png';//extreme

// import difference from './difference.png';

class TextChart extends Chart {
    /*-------------------------------------------------------------------------- */
    animateValue() {
        let svg = this.displayValue();
        if (!svg) return;
        /* ------------ init data ----------- */
        let duration = this.duration();

        /* ---------- step 1 textnameAppear ---------- */
        let valuefield = svg.select("#valuefield")
        valuefield.attr("opacity", 0);
        valuefield.transition()
            .duration(duration * 0.3)
            .attr("opacity", 1)
        /* ---------- step 1 textGrow --------------- */
        let textvalue = svg.select("#valuetext");
        // let showedValue = textvalue.node().innerHTML.replace(/,/g, '');
        textvalue.attr("opacity", 0)
                // .text(0);
        
        // text grow;
        textvalue.transition()
            .delay(duration * 0.3)
            .duration(duration * 0.7)
            .ease(d3.easeLinear)
            .attr("opacity", 1)
        // textvalue.transition()
        //     .delay(duration * 0.3 + 10)
        //     .duration(duration * 0.7)
        //     .ease(d3.easeLinear)
        //     .textTween(function (d) {
        //         // let final = d3.select(this).text();
        //         let final = showedValue;
        //         const i = d3.interpolate(0, +final);
        //         var numberFormat = d3.format(".0f");
        //         return function (t) {
        //             var percent = formatNumber(+numberFormat(i(t)));
        //             return percent;
        //         };
        //     });
    }


    displayValue() {
        let measure = this.measure();
        let factdata = this.factdata()
        let cardWidth = this.width();
        let cardHeight = this.height();

        let margin = getMarginBySize(this.size(), "value"),
            width = cardWidth - margin.left - margin.right;
        // let  height = cardHeight - margin.top - margin.bottom;


        //  let  arr=[]
        //    d3.map(factData, d => {
        //     arr.push( d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field])
        //     return 0;
        //  })

        //vis
        let svg = d3.select(this.container())
            .append("svg")
            .attr("id", "valuetextchartsvg")
            .attr("width", cardWidth)
            .attr("height", cardHeight)
            .append("g")
            .attr("id", "valuetextchart")

        if (this.measure().length > 1 || this.breakdown().length > 0) {
            return svg;
        }

        //data processing
        let textvalue;
        // if (subspace.length === 0) {
        if (measure[0].aggregate === 'max' || measure[0].aggregate === 'min' || measure[0].aggregate === 'none')
            textvalue = factdata[0][measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]

        else textvalue = factdata[0][measure[0].aggregate === 'count' ? "COUNT" : measure[0].field];
        // textvalue = d3.sum(factdata, d => d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]);
        // } else {

        //    textvalue = factdata[0][measure[0].aggregate === 'count' ? "COUNT" : measure[0].field];
        //textvalue = d3.map(factdata, d => d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]);
        // }

        let fontsize = getFontSize(this.size(), "value")
        if (textvalue % 1 !== 0) textvalue = textvalue.toFixed(2);

        let subspaceField = this.subspace().length ? this.subspace()[0].value : "";

        svg.append("text")
            .attr("id", "valuetext")
            .attr("text-anchor", "middle")
            // .attr("dominant-baseline","middle")
            .text(formatNumber(textvalue))
            .attr("x", cardWidth / 2)
            .attr("y", cardHeight / 2 + fontsize / 2 - 5)
            .attr("fill", Color.HIGHLIGHT)
            .attr('font-size', fontsize)
            .attr('font-family', 'impact')

        svg.append("text")
            .attr("id", "valuefield")
            .attr("text-anchor", "middle")
            // .attr("dominant-baseline","middle")
            .text(measure[0].field ? measure[0].field : subspaceField)
            .attr("x", cardWidth / 2)
            .attr("y", cardHeight / 2 - fontsize / 3 * 2 - 2)
            .attr("fill", Color.DEFAULT)
            .attr('font-size', fontsize)
            .attr('font-family', 'impact')


        //使图表居中
        //  let a=d3.select("#valuetextchart").node().getBoundingClientRect().width;
        //  let b=d3.select("#valuetextchart").node().getBoundingClientRect().height;      
        //  let c=d3.select("#valuetextchart").node().getBoundingClientRect().left;      
        //  let d=d3.select("#valuetextchartsvg").node().getBoundingClientRect().left; 
        //  let e=d3.select("#valuetextchart").node().getBoundingClientRect().top;      
        //  let f=d3.select("#valuetextchartsvg").node().getBoundingClientRect().top; 
        //  let transx=d-c+cardWidth/2-a/2;
        //  let transy=f-e+cardHeight/2-b/2;

        let a = svg.node().getBBox().width;
        let b = svg.node().getBBox().height;
        let c = svg.node().getBBox().x;
        let e = svg.node().getBBox().y;


        // let a1=d3.select("#valuetextchart").node().getBBox().width;
        // let b1=d3.select("#valuetextchart").node().getBBox().height;      
        // let c1=d3.select("#valuetextchart").node().getBBox().x;      
        // let e1=d3.select("#valuetextchart").node().getBBox().y;  

        let transx = -c + cardWidth / 2 - a / 2;
        let transy = -e + cardHeight / 2 - b / 2;

        if (this.style() === Style.COMICS) {
            let metaphorHeight = this.size() === Size.WIDE ? svg.select("#valuetext").node().getBBox().height * 0.8 : d3.select("#valuetext").node().getBBox().height * 0.9,
                metaphorWidth = metaphorHeight / 1.18;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor4);

            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", svg.select("#valuetext").node().getBBox().width / 2 + cardWidth / 2 + metaphorWidth * 0.1)
                .attr("y", cardHeight / 2 - metaphorHeight * 0.68);

            a = svg.node().getBBox().width;
            b = svg.node().getBBox().height;
            c = svg.node().getBBox().x;
            e = svg.node().getBBox().y;

            transx = -c + cardWidth / 2 - a / 2;
            transy = -e + cardHeight / 2 - b / 2;
        }

        //center
        if (a > cardWidth) {
            //  d3.select("#valuetextchart").attr("transform", `scale(${width/a})  translate(${cardWidth/(2*width/a)-(a/2-d+c)},${cardHeight/(2*width/a)-(b/2-f+e)}) `)
            svg.attr("transform", `scale(${width / a})  translate(${cardWidth / (2 * width / a) - (a / 2 + c)},${cardHeight / (2 * width / a) - (b / 2 + e)}) `)
        } else {
            svg.attr("transform", `translate(${transx} ,${transy}) `)
        }
        return svg;
    }

    /*---------------------------------------------------------------------------*/
    animateDifference() {
        let svg = this.displayDifference();
        if (!svg) return;
        /* ------------ init data ----------- */
        let duration = this.duration();

        /* ---------- step 1 textnameAppear ---------- */
        
        let arrowiit = svg.select("#valuefield");


        arrowiit.attr("opacity", 0);
        
        arrowiit.transition()
            .duration(duration * 0.3)
            .attr("opacity", 1)
        /* ---------- step 1 textGrow --------------- */
        let textvalue = svg.select("#valuetext");
        // let showedValue = textvalue.node().innerHTML.replace(/,/g, '');
        textvalue.attr("opacity", 0)
                // .text(0);

        textvalue.transition()
            .delay(duration * 0.3)
            .duration(duration * 0.7)
            .ease(d3.easeLinear)
            .attr("opacity", 1)

        // // text grow;
        // textvalue.transition()
        //     .delay(duration * 0.3 + 10)
        //     .duration(duration * 0.7)
        //     .ease(d3.easeLinear)
        //     .textTween(function (d) {
        //         // let final = d3.select(this).text();
        //         let final = showedValue;
        //         const i = d3.interpolate(0, final);
        //         var numberFormat = d3.format(".0f");
        //         return function (t) {
        //             var percent = formatNumber(+numberFormat(i(t)));
        //             return percent;
        //         };
        //     });
    }
    /*-------------------------------------------------------------------------- */
    displayDifference() {
        let focus = this.focus();
        let measure = this.measure();
        let cardWidth = this.width();
        let cardHeight = this.height();

        let filteredData = []         //sorted by focus
        for (const fs of focus) {
            this.factdata().filter((x) => x[fs.field] === fs.value)[0] && filteredData.push(this.factdata().filter((x) => x[fs.field] === fs.value)[0])
        }


        let margin = getMarginBySize(this.size(), "difference"),
            width = cardWidth - margin.left - margin.right,
            height = cardHeight - margin.top - margin.bottom;

        //data processing
        let calculatedifference;
        let sarr = [];
        // let arrowtype;//判断数值上升下降
        filteredData.map((item, index, arr) => {
            sarr.push(item[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field])
            return 0;
        })


        if (sarr[0] > sarr[1]) {
            // arrowtype = "up"
            calculatedifference = (sarr[0] - sarr[1])
        } else if (sarr[0] < sarr[1]) {
            // arrowtype = "down"
            calculatedifference = (sarr[1] - sarr[0])
        } 
        // else {
        //     arrowtype = "steady"
        // }


        //vis
        let svg = d3.select(this.container())
            .append("svg")
            .attr("id", "differencetextchartsvg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("id", "differencetextchart")

        if (filteredData.length < 2 || this.measure().length > 1) {
            return svg;
        }
        //添加文字    
        // let fontsize = getFontSize(this.size(), "extreme")
        // let arrowx;
        // let size1 = this.size();

        let textvalue = calculatedifference;

        let fontsize = getFontSize(this.size(), "value");
        if (textvalue % 1 !== 0) textvalue = textvalue.toFixed(2);

        // let subspaceField = this.subspace().length ? this.subspace()[0].value : "";

        let diff = svg.append("g").attr("id", "diff")
        diff.append("text")
            .attr("id", "valuetext")
            .attr("text-anchor", "middle")
            // .attr("dominant-baseline","middle")
            .text(formatNumber(textvalue))
            .attr("x", cardWidth / 2)
            .attr("y", cardHeight / 2 + fontsize / 2 - 5)
            .attr("fill", Color.HIGHLIGHT)
            .attr('font-size', fontsize)
            .attr('font-family', 'impact')

        svg.append("text").attr("id", "valuefield")
            .attr("text-anchor", "middle")
            .attr("x", cardWidth / 2)
            .attr("y", cardHeight / 2 - fontsize / 3 * 2 - 2)
            .attr('font-size', fontsize)
            .attr('font-family', 'impact')
            .attr("fill", Color.DEFAULT)
            .text(focus[0].value)
            .append("tspan")
            .attr("fill", Color.DIVIDER)
            .text(" vs ")
            .append("tspan")
            .attr("fill", Color.DEFAULT)
            .text(focus[1].value)



        //使图表居中
        //  let a=d3.select("#valuetextchart").node().getBoundingClientRect().width;
        //  let b=d3.select("#valuetextchart").node().getBoundingClientRect().height;      
        //  let c=d3.select("#valuetextchart").node().getBoundingClientRect().left;      
        //  let d=d3.select("#valuetextchartsvg").node().getBoundingClientRect().left; 
        //  let e=d3.select("#valuetextchart").node().getBoundingClientRect().top;      
        //  let f=d3.select("#valuetextchartsvg").node().getBoundingClientRect().top; 
        //  let transx=d-c+cardWidth/2-a/2;
        //  let transy=f-e+cardHeight/2-b/2;

        let a = svg.node().getBBox().width;
        let b = svg.node().getBBox().height;
        let c = svg.node().getBBox().x;
        let e = svg.node().getBBox().y;


        // let a1=d3.select("#valuetextchart").node().getBBox().width;
        // let b1=d3.select("#valuetextchart").node().getBBox().height;      
        // let c1=d3.select("#valuetextchart").node().getBBox().x;      
        // let e1=d3.select("#valuetextchart").node().getBBox().y;  

        let transx = -c + cardWidth / 2 - a / 2;
        let transy = -e + cardHeight / 2 - b / 2;

        if (this.style() === Style.COMICS) {
            let metaphorHeight = this.size() === Size.WIDE ? svg.select("#valuetext").node().getBBox().height * 0.8 : d3.select("#valuetext").node().getBBox().height * 0.9,
                metaphorWidth = metaphorHeight / 1.18;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor28);

            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", svg.select("#valuetext").node().getBBox().width / 2 + cardWidth / 2 + metaphorWidth * 0.1)
                .attr("y", cardHeight / 2 - metaphorHeight * 0.6);

            a = svg.node().getBBox().width;
            b = svg.node().getBBox().height;
            c = svg.node().getBBox().x;
            e = svg.node().getBBox().y;

            transx = -c + cardWidth / 2 - a / 2;
            transy = -e + cardHeight / 2 - b / 2;
        }

        //center
        if (a > cardWidth) {
            //  d3.select("#valuetextchart").attr("transform", `scale(${width/a})  translate(${cardWidth/(2*width/a)-(a/2-d+c)},${cardHeight/(2*width/a)-(b/2-f+e)}) `)
            svg.attr("transform", `scale(${width / a})  translate(${cardWidth / (2 * width / a) - (a / 2 + c)},${cardHeight / (2 * width / a) - (b / 2 + e)}) `)
        } else {
            svg.attr("transform", `translate(${transx} ,${transy}) `)
        }


        
        return svg;
    }


    /*-----------------------------------------------------------------------*/
    animateExtreme() {
        let svg = this.displayExtreme();
        if (!svg) return;
        /* ------------ init data ----------- */
        let duration = this.duration();

        /* ---------- step 1 textnameAppear ---------- */
        let arrowiit = svg.select("#extreme-Arrow");
        let arrowTopBar = arrowiit.select(".topBar");
        // arrowiit.attr("opacity", 0);
        arrowTopBar.transition()
            .duration(duration * 0.2)
            .attr("opacity", 1);
        let arrowBody = arrowiit.select(".arrowBody");
        let arrowBodyY = arrowBody.node().getBBox().y;
        
        arrowBody.attr("transform", `translate(0, ${(this.height() - arrowBodyY) / 3})`)
                .attr("opacity", 0)
                .transition()
                .duration(duration * 0.2)
                .delay(duration * 0.2)
                .attr("transform", `translate(0, 0)`)
                .attr("opacity", 1)

        /* ---------- step 1 textGrow --------------- */
        let textvalue = svg.select("#extremetext");
        // let showedValue = textvalue.node().innerHTML.replace(/,/g, '');
        textvalue.attr("opacity", 0)
                // .text(0);
        textvalue.transition()
            .delay(duration * 0.4)
            .duration(duration * 0.6)
            .ease(d3.easeLinear)
            .attr("opacity", 1);
        // // text grow;
        // textvalue.transition()
        //     .delay(duration * 0.3 + 10)
        //     .duration(duration * 0.7)
        //     .ease(d3.easeLinear)
        //     .textTween(function (d) {
        //         // let final = d3.select(this).text();
        //         let final = showedValue;
        //         const i = d3.interpolate(0, final);
        //         var numberFormat = d3.format(".0f");
        //         return function (t) {
        //             var percent = formatNumber(+numberFormat(i(t)));
        //             return percent;
        //         };
        //     });
    }
    /*-------------------------------------------------------------------------- */
    displayExtreme() {
        let factData = this.factdata();
        let measure = this.measure();
        let cardWidth = this.width();
        let cardHeight = this.height();


        let margin = getMarginBySize(this.size(), "extreme"),
            width = cardWidth - margin.left - margin.right,
            height = cardHeight - margin.top - margin.bottom;


        //vis
        let svg = d3.select(this.container())
            .append("svg")
            .attr("id", "extremetextchartsvg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("id", "extremetextchart")


        if (this.measure().length > 1 || this.breakdown().length > 1) {
            return svg;
        }

        //data processing
        let maxYValue = d3.max(factData, d => {
            return d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field];
        })
        // let minYValue=d3.min(factData,d=>{   
        //return d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field];
        // })
        let maxNumber = formatNumber(maxYValue);
        // let minNumber=formatNumber(minYValue);
        let arrowtype = "up"

        //添加文字    
        let fontsize = getFontSize(this.size(), "extreme")
        let size1 = this.size();
        svg.append("text")
            .attr("id", "extremetext")
            .text(maxNumber)
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "middle")
            .attr("x", function () {
                if (size1 === "small") return cardWidth / 3 * 2 - 5
                else return cardWidth / 2 + 15
            })
            .attr("y", cardHeight / 2 + 'px')
            .attr("fill", Color.HIGHLIGHT)
            .attr('font-size', fontsize)
            .attr('font-family', 'impact')

        let typesizex = svg.select("#extremetext").node().getBBox().width;

        if (size1 === "small") {
            drawArrow(arrowtype, cardWidth / 3 * 2 - 5 - typesizex / 2 - 8, cardHeight / 2 + fontsize / 2, fontsize, this.size(), "extreme", svg, cardWidth, cardHeight)
        }
        else {
            drawArrow(arrowtype, cardWidth / 2 + 8 - typesizex / 2 - fontsize / 4, cardHeight / 2 + fontsize / 2, fontsize, this.size(), "extreme", svg, cardWidth, cardHeight)
        }

        //使图表居中
        // let a=d3.select("#extremetextchart").node().getBoundingClientRect().width;
        // let b=d3.select("#extremetextchart").node().getBoundingClientRect().height;      
        // let c=d3.select("#extremetextchart").node().getBoundingClientRect().left;      
        // let d=d3.select("#extremetextchartsvg").node().getBoundingClientRect().left; 
        // let e=d3.select("#extremetextchart").node().getBoundingClientRect().top;      
        // let f=d3.select("#extremetextchartsvg").node().getBoundingClientRect().top; 

        // let transx=d-c+cardWidth/2-a/2;
        // let transy=f-e+cardHeight/2-b/2;
        // d3.select("#extremetextchart").attr("transform",function(){
        //  if(a>cardWidth) return `scale(${width/a})  translate(${cardWidth/(2*width/a)-(a/2-d+c)},${cardHeight/(2*width/a)-(b/2-f+e)}) `
        //   else  return  `translate(${transx} ,${transy}) `

        // })

        // return svg;

        let a = svg.node().getBBox().width;
        let b = svg.node().getBBox().height;
        let c = svg.node().getBBox().x;
        let e = svg.node().getBBox().y;

        let transx = -c + cardWidth / 2 - a / 2;
        let transy = -e + cardHeight / 2 - b / 2;

        if (this.style() === Style.COMICS) {
            let metaphorHeight = this.size() === Size.WIDE ? b * 0.8 : b * 0.9,
                metaphorWidth = metaphorHeight / 1.34;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor18);

            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", svg.select("#extremetext").node().getBBox().width / 2 + parseInt(svg.select("#extremetext").attr("x")) + metaphorWidth * 0.05)
                .attr("y", cardHeight / 2 - metaphorHeight * 0.68);

            a = svg.node().getBBox().width;
            b = svg.node().getBBox().height;
            c = svg.node().getBBox().x;
            e = svg.node().getBBox().y;

            transx = -c + cardWidth / 2 - a / 2;
            transy = -e + cardHeight / 2 - b / 2;
        }

        if (a > cardWidth) {
            svg.attr("transform", `scale(${width / a})  translate(${cardWidth / (2 * width / a) - (a / 2 + c)},${cardHeight / (2 * width / a) - (b / 2 + e)}) `)
        } else {
            svg.attr("transform", `translate(${transx} ,${transy}) `)
        }

        return svg;
    }
}
// /--------------------------------------------draw arrow-----------------------------------------/
const drawArrow = (arrowtype, left, top, fontsize, chartSize, factType, svg, chartWidth, chartHeight) => {
    switch (factType) {
        /*------------------------------ Extreme------------------------------------------------------ */
        case "extreme":
            let strokewidth;
            let linewidth;//箭头上面的横杠
            if (chartSize === Size.LARGE) {
                strokewidth = 30;
                linewidth = 10;
            }
            if (chartSize === Size.WIDE) {
                strokewidth = 25;
                linewidth = 10;
            }
            if (chartSize === Size.MIDDLE) {
                strokewidth = 16;
                linewidth = 10;
            }
            if (chartSize === Size.SMALL) {
                strokewidth = 10;
                linewidth = 6;
            }
            if (arrowtype === "up") {
                let arrow = svg.append("g").attr("id", "extreme-Arrow")
                let arrowBody = arrow.append("g").attr("class", "arrowBody");

                arrowBody.append("line")
                    .attr("class", "arrowBar")
                    .attr("x1", left - strokewidth)
                    .attr("y1", top)
                    .attr("x2", left - strokewidth)
                    .attr("y2", top - fontsize / 3 * 2 - 1)
                    .attr("stroke", Color.DEFAULT)
                    .attr("stroke-width", strokewidth)
                arrowBody.append('polygon')
                    .attr("class", "arrowTriangle")
                    .attr("points", `${left - strokewidth - strokewidth / 3 * 4},${top - fontsize / 3 * 2} ${left - strokewidth + strokewidth / 3 * 4},${top - fontsize / 3 * 2} ${left - strokewidth},${top - fontsize / 3 * 4}`)
                    .attr("fill", Color.DEFAULT)
                    .attr("stroke", "none")
                arrow.append("line")
                    .attr("class", "topBar")
                    .attr("x1", left - strokewidth - strokewidth / 3 * 4)
                    .attr("y1", top - fontsize / 3 * 4 - linewidth)
                    .attr("x2", left - strokewidth + strokewidth / 3 * 4)
                    .attr("y2", top - fontsize / 3 * 4 - linewidth)
                    .attr("stroke", Color.DEFAULT)
                    .attr("stroke-width", linewidth)
            }
            else if (arrowtype === "both") {

                let lineHeight = ((top) - (top - fontsize / 3 * 2 - 1 ) ) * 0.95;
                let traingleHeight =( (top - fontsize / 3 * 2) - (top - fontsize / 3 * 4)) * 1;

                let arrow = svg.append("g").attr("id", "extreme-Arrow")
                            .attr("transform", `translate(0, ${-(traingleHeight ) / 3 })`)
                
                            strokewidth = strokewidth * 0.8

                arrow.append('polygon')
                    .attr("points", `${left - strokewidth - strokewidth / 3 * 4},${top - fontsize / 3 * 2} ${left - strokewidth + strokewidth / 3 * 4},${top - fontsize / 3 * 2} ${left - strokewidth},${top - fontsize / 3 * 2 - traingleHeight}`)
                    .attr("fill", Color.DEFAULT)
                    .attr("stroke", "none");

                arrow.append('polygon')
                    .attr("points", `${left - strokewidth - strokewidth / 3 * 4},${top - fontsize / 3 * 2 + lineHeight} ${left - strokewidth + strokewidth / 3 * 4},${top - fontsize / 3 * 2 + lineHeight} ${left - strokewidth},${top - fontsize / 3 * 2 + lineHeight + traingleHeight}`)
                    .attr("fill", Color.DEFAULT)
                    .attr("stroke", "none")
                    .attr("stroke-width", 0);

                arrow.append("line")
                    .attr("x1", left - strokewidth)
                    .attr("y1", top)
                    .attr("x2", left - strokewidth)
                    .attr("y2", top - fontsize / 3 * 2 - 1)
                    .attr("stroke", Color.DEFAULT)
                    .attr("stroke-width", chartSize === 'large' ? strokewidth / 2 : strokewidth / 2);

                arrow.append("line")
                    .attr("x1", left - strokewidth - strokewidth / 3 * 4)
                    .attr("y1", top - fontsize / 3 * 4 - linewidth)
                    .attr("x2", left - strokewidth + strokewidth / 3 * 4)
                    .attr("y2", top - fontsize / 3 * 4 - linewidth)
                    .attr("stroke", Color.DEFAULT)
                    .attr("stroke-width", linewidth);
                
                arrow.append("line")
                    .attr("x1", left - strokewidth - strokewidth / 3 * 4)
                    .attr("y1", top - fontsize / 3 * 4 - linewidth + lineHeight + traingleHeight * 2 + linewidth * 2)
                    .attr("x2", left - strokewidth + strokewidth / 3 * 4)
                    .attr("y2", top - fontsize / 3 * 4 - linewidth + lineHeight + traingleHeight * 2 + linewidth * 2)
                    .attr("stroke", Color.DEFAULT)
                    .attr("stroke-width", linewidth);
                

                
            }
            break;
        /*---------------------------------Difference----ArrowTYPE：UP\DOWN\STEADY ------------------------------------------------ */
        case "difference":
            let strokewidth1;
            let margin1 = getMarginBySize(chartSize, factType);
            //let chartsize=getCardSize(chartSize);

            let uparrowheight;
            if (chartSize === Size.LARGE) {
                strokewidth1 = 30;
                uparrowheight = 60;
            }
            if (chartSize === Size.WIDE) {
                strokewidth1 = 30;
                uparrowheight = 50;
            }
            if (chartSize === Size.MIDDLE) {
                strokewidth1 = 22;
                uparrowheight = 40;
            }
            if (chartSize === Size.SMALL) {
                strokewidth1 = 15;
                uparrowheight = 30;
            }
            if (arrowtype === "down") {
                let arrow = svg.append("g").attr("id", "difference-DownArrow").attr("class", "difference-arrow")
                let typesizex1 = svg.select("#differencetext").node().getBBox().width / 2;
                let typesizey1 = svg.select("#differencetext").node().getBBox().height / 2;

                let arrowx4 = left - typesizex1 - 5;
                let arrowx1 = arrowx4 - chartWidth / 5 * 2;
                let arrowWidth = (arrowx4 - arrowx1) / 8;
                let arrowx2 = arrowWidth * 3 + arrowx1;
                let arrowx3 = arrowWidth * 5 + arrowx1;

                let arrowy4 = chartHeight / 2 + typesizey1 / 4 * 3;
                let arrowy1 = arrowy4 - arrowWidth * 4;
                let arrowy2 = arrowy4 - arrowWidth;
                let arrowy3 = arrowy4 - arrowWidth * 3;

                let triangle;
                if (chartSize === Size.LARGE) triangle = strokewidth1 / 2 * 4;
                if (chartSize === Size.WIDE) triangle = strokewidth1 / 2 * 3;
                if (chartSize === Size.MIDDLE) triangle = strokewidth1 / 2 * 3;
                if (chartSize === Size.SMALL) triangle = strokewidth1 / 2 * 3

                arrow.append("polyline")
                    .attr("points", function () {
                        return `${arrowx1 - 10 - triangle / Math.sqrt(2)},${arrowy1 - 10 - triangle / Math.sqrt(2)} ${arrowx2 - triangle / Math.sqrt(2)},${arrowy2 - triangle / Math.sqrt(2)} ${arrowx3 - triangle / Math.sqrt(2)},${arrowy3 - triangle / Math.sqrt(2)} ${arrowx4 + 1 - triangle / Math.sqrt(2)},${arrowy4 + 1 - triangle / Math.sqrt(2)}`
                    })
                    .attr("fill", "none")
                    .attr("stroke", Color.DEFAULT)
                    .attr("stroke-width", strokewidth1)

                arrow.append('polygon')
                    .attr("points", function () {
                        return `${arrowx4},${arrowy4} ${arrowx4 - 2 * triangle / Math.sqrt(2)},${arrowy4} ${arrowx4},${arrowy4 - 2 * triangle / Math.sqrt(2)}`
                    })
                    .attr("fill", Color.DEFAULT)
                    .attr("stroke", "none")

                if (chartSize === Size.LARGE || chartSize === Size.WIDE) svg.select("#difference-DownArrow").attr("transform", "translate(-10,0  )")

            }

            if (arrowtype === "steady") {
                let arrow = svg.append("g").attr("id", "difference-SteadyArrow").attr("class", "difference-arrow")

                let center;

                arrow.append("line")
                    .attr("x1", margin1.left)
                    .attr("y1", chartHeight / 2)
                    .attr("x2", function () {
                        if (chartSize === Size.LARGE) {
                            center = margin1.left + (chartHeight / 2 - margin1.top) * 1.8
                        }
                        if (chartSize === Size.WIDE) {
                            center = margin1.left + (chartHeight / 2 - margin1.top) * 3.8;
                        }
                        if (chartSize === Size.MIDDLE) {
                            center = margin1.left + (chartHeight / 2 - margin1.top) * 2.5;
                        }
                        if (chartSize === Size.SMALL) {
                            center = margin1.left + (chartHeight / 2 - margin1.top) * 2.2;
                        }
                        return center + 1;
                    })
                    .attr("y2", chartHeight / 2)
                    .attr("stroke", Color.DEFAULT)
                    .attr("stroke-width", function () {
                        if (chartSize === Size.LARGE) {
                            return 40;
                        }
                        if (chartSize === Size.WIDE) {
                            return 30;
                        }
                        if (chartSize === Size.MIDDLE) {
                            return 25;
                        }
                        if (chartSize === Size.SMALL) {
                            return 20;
                        }
                    })

                arrow.append('polygon')
                    .attr("points", function () {
                        if (chartSize === Size.LARGE) return `${center},${chartHeight / 4 + 50} ${chartWidth - margin1.right},${chartHeight / 2} ${center},${chartHeight / 4 * 3 - 50}`
                        else return `${center},${chartHeight / 4} ${chartWidth - margin1.right},${chartHeight / 2} ${center},${chartHeight / 4 * 3}`
                    })
                    .attr("fill", Color.DEFAULT)
                    .attr("stroke", "none")
            }
            /********************************************************* */
            //  ⬆
            if (arrowtype === "up") {
                let arrow = svg.append("g").attr("id", "difference-UpArrow").attr("class", "difference-arrow")
                let typesizexx = svg.select("#differencetext").node().getBBox().width;
                // console.log("drawArrow -> typesizexx", typesizexx)

                let strokewidth2;
                if (chartSize === Size.LARGE) {
                    strokewidth2 = 30;
                }
                if (chartSize === Size.WIDE) {
                    strokewidth2 = 30;
                }
                if (chartSize === Size.MIDDLE) {
                    strokewidth2 = 25;
                }
                if (chartSize === Size.SMALL) {
                    strokewidth2 = 16;
                }
                let arrowx1 = left - typesizexx / 2 - (uparrowheight + strokewidth2) * 2 / Math.sqrt(2);
                arrow.append("line")
                    .attr("x1", arrowx1)
                    .attr("y1", chartHeight / 2 - uparrowheight - 1)
                    .attr("x2", arrowx1)
                    .attr("y2", chartHeight / 2 + uparrowheight + 1)
                    .attr("stroke", Color.DEFAULT)
                    .attr("stroke-width", strokewidth2)
                //箭头底部
                arrow.append('polygon')
                    .attr("points", function () {
                        return `${arrowx1 - strokewidth2 / 2},${chartHeight / 2 + uparrowheight} ${arrowx1 + strokewidth2 / 2},${chartHeight / 2 + uparrowheight} ${arrowx1 + strokewidth2 / 2},${chartHeight / 2 + uparrowheight + strokewidth2}`
                    })
                    .attr("fill", Color.DEFAULT)
                    .attr("stroke", "none")
                //箭头顶部                    
                arrow.append('polygon')
                    .attr("points", function () {
                        return `${arrowx1},${chartHeight / 2 - uparrowheight - strokewidth2 * 2} ${arrowx1 - strokewidth2 * 2},${chartHeight / 2 - uparrowheight} ${arrowx1 + strokewidth2 * 2},${chartHeight / 2 - uparrowheight}`
                    })
                    .attr("fill", Color.DEFAULT)
                    .attr("stroke", "none")

                svg.select("#difference-UpArrow")
                    .attr("transform", function () {
                        return `rotate(${45}, ${arrowx1} ${chartHeight / 2})`
                    })

            }

            break;

        default:

            break;

    }

}
/*--get fontSize-----------------------------------------------------------------*/
const getFontSize = (chartSize, factType) => {
    let fontsize;
    switch (chartSize) {
        case Size.LARGE:
            if (factType === "difference") fontsize = 150;
            if (factType === "extreme") fontsize = 100;
            if (factType === "value") fontsize = 200;
            break;

        case Size.WIDE:
            if (factType === "difference") fontsize = 140;
            if (factType === "extreme") fontsize = 80;
            if (factType === "value") fontsize = 100;
            break;

        case Size.MIDDLE:
            if (factType === "difference") fontsize = 100;
            if (factType === "extreme") fontsize = 60;
            if (factType === "value") fontsize = 80;
            break;

        case Size.SMALL:
            if (factType === "difference") fontsize = 70;
            if (factType === "extreme") fontsize = 40;
            if (factType === "value") fontsize = 50;
            break;

        default:
            fontsize = 50;
            break;

    }
    return fontsize;
}

// TODO fit by chartSize
const getMarginBySize = (chartSize, factType) => {
    let margin;
    switch (factType) {
        case "difference":
            if (chartSize === Size.LARGE)
                margin = { top: 75, right: 30, bottom: 75, left: 30 };
            if (chartSize === Size.WIDE)
                margin = { top: 10, right: 40, bottom: 10, left: 40 };
            if (chartSize === Size.MIDDLE)
                margin = { top: 10, right: 10, bottom: 10, left: 10 };
            if (chartSize === Size.SMALL)
                margin = { top: 8, right: 10, bottom: 8, left: 10 };
            break
        case "extreme":
            if (chartSize === Size.LARGE)
                margin = { top: 40, right: 20, bottom: 40, left: 20 };
            if (chartSize === Size.WIDE)
                margin = { top: 65, right: 20, bottom: 30, left: 20 };
            if (chartSize === Size.MIDDLE)
                margin = { top: 50, right: 10, bottom: 30, left: 10 };
            if (chartSize === Size.SMALL)
                margin = { top: 20, right: 2, bottom: 10, left: 2 };
            break
        case "value":
            if (chartSize === Size.LARGE)
                margin = { top: 20, right: 20, bottom: 40, left: 20 };
            if (chartSize === Size.WIDE)
                margin = { top: 30, right: 10, bottom: 10, left: 10 };
            if (chartSize === Size.MIDDLE)
                margin = { top: 15, right: 10, bottom: 40, left: 10 };
            if (chartSize === Size.SMALL)
                margin = { top: 10, right: 5, bottom: 40, left: 5 };
            break
        default:
            margin = { top: 20, right: 30, bottom: 40, left: 70 };
            break
    }

    return margin

}


export default TextChart;
