import * as d3 from 'd3';
import Chart from '../../chart';
import Color from '../../visualization/color';
import generateToolTip from '../../visualization/tooltip';
import formatNumber from '../../visualization/format';
import Size from '../../visualization/size';
import Style from '../../visualization/style';
import PictorialType from '../../visualization/pictogram';

const NUMFONT = "Arial-Regular";
class IsotypeBar extends Chart {
/*----------------------------------------------------------------------------------------------------------*/
    displayDistribution() {
    if(this.style()===Style.PICTOGRAPH){
        let factdata = this.factdata();
        let measure = this.measure();
        let pictype=measure[0].pictype;  //获取相应的icon名称
     
        let breakdown = this.breakdown();
        let hasSeries = false;
        if (breakdown[1] && breakdown[1].field) hasSeries = true;
        // set the dimensions and margins of the graph
        let { chartSize, tickSize,axisWidth, margin, maxTicksNum } = getSizeBySize(this, "distribution", hasSeries);
        let width = chartSize.width - margin.left - margin.right,
            height = chartSize.height - margin.top - margin.bottom;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("class", "svg")
            .attr("width", chartSize.width)
            .attr("height", chartSize.height)
            .append("g")
            .attr("id","isotypebarDistribution")
            .attr("transform", `translate(${margin.left} ${margin.top})`);   
            
        if (this.measure().length > 1|| hasSeries) {
            return svg;
        }


    /*----------------------处理数据------------------------*/

        let data = factdata;
        let maxYValue = getMaxYValue(factdata, measure); 
        let pictorialdata=data.map(d=>(d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]));
        pictorialdata=pictorialdata.slice(0,10);
        let pictorialx=data.map(d=>(d[breakdown[0].field]));
        pictorialx=pictorialx.slice(0,10);
        let pictorialdatapercent =[]; 
        for(let i=0;i<pictorialdata.length;i++){   
            pictorialdatapercent[i]=parseFloat(pictorialdata[i]/maxYValue*100).toFixed(2);       
        }
 
     /*------------------通过名称找寻icon----------------------------*/
        svg.append("defs")
            .append("g")
            .attr("id",`pictype${pictype}`)
            .append("path")
            .attr("d",PictorialType[pictype])

            let typesizex1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
            let typesizey1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;
 /*-------------------默认Gradient------------------------------*/
            // Define the gradient
            let gradient=[];
            for(let i=0;i<pictorialdata.length;i++){
             gradient[i] = svg.append("svg:defs")
                .append("svg:linearGradient")
                .attr("id",`icontype${i}`)
                .attr("x1","0")
                .attr("y1", "1")
                .attr("x2","0")
                .attr("y2", "0")
                .attr("spreadMethod", "pad");

            // Define the gradient colors
            gradient[i].append("svg:stop")
          
                .attr("offset", "0%")
                .attr("stop-color", function(){
                    return Color.DEFAULT;
                })
                .attr("stop-opacity", 1);
            }

     
        // append legend before chart 
        let chartMeasuredWidth = width;//inital 
    
        // set the ranges
      
        let offsetY = this.size() === 'large' ? 5 : 2; //离x轴的距离     
        let ysizescale=height-offsetY;//icon长度的拉伸
        if(this.size()==="large") ysizescale=height/3*2-offsetY
        let x = d3.scaleBand()
                .range([0, width])
                .domain(data.map(function (d) { return d[breakdown[0].field]; }))
                .padding(0.2);
        let y = d3.scaleLinear().nice()
                .range([ysizescale, 0])
                .domain([0, maxYValue])
                .clamp(true);

      /*-------------根据x的数据来进行icon的缩放--------------------------------*/
      let scalex;
      let scaley;
        svg.select(`#pictype${pictype}`)
        .attr("transform", function(){                
            scalex=x.bandwidth()/typesizex1;
            scaley=y(0)/typesizey1;
            return  `scale(${scalex},${scaley})`                       
        });
     /*----------------计算缩放后的icon长宽------------------------*/
      let typesizex=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
     // let typesizey=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;
      let typex= svg.select(`#pictype${pictype}`).node().getBBox().x;
      let typey=svg.select(`#pictype${pictype}`).node().getBBox().y;
    /*------------------在图表中添加icon----------------------------*/
      svg.append("g")
         .attr("id","pictoLayer")
         .selectAll("use")
         .data(pictorialx)
         .enter()
         .append("use")
         .attr("xlink:href",`#pictype${pictype}`)
         .attr("id",function(d,i){return "icontype"+i})  
         .attr("x", function (d,i) { 
                return x(d)+x.bandwidth()/2-Math.abs(typex*scalex)-typesizex/2;
             })
        .attr("y", function (d) {
             return y(maxYValue)-Math.abs(typey*scaley);
             })

        for(let i=0;i<pictorialdata.length;i++){
        svg.selectAll("#icontype"+i)
        .attr("fill", function(d) {
            gradient[i].append("svg:stop")
                .attr("offset", pictorialdatapercent[i] + '%')
                .attr("stop-color", Color.DEFAULT)
                .attr("stop-opacity", 1);
            gradient[i].append("svg:stop")
                .attr("offset", pictorialdatapercent[i] + '%')
                .attr("stop-color", Color.BACKGROUND)
                .attr("stop-opacity", 1);
            gradient[i].append("svg:stop")
                .attr("offset", '100%')
                .attr("stop-color", Color.BACKGROUND)
                .attr("stop-opacity", 1);
            return `url(#icontype${i})`;
        }) 
    }

        //bar value 超多一定数量，不显示bar value
        if (!hasSeries && this.size() !== 'small' && data.length < maxTicksNum) {
            svg.append("g")
                .selectAll("text")
                .data(data)
                .enter()
                .append("text")
                .attr("font-family", NUMFONT)
                .attr("font-size", tickSize)
                .attr("text-anchor", 'middle')
                .attr("x", function (d) { return x(d[breakdown[0].field]); })
                .attr("y", function (d) { return y(d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]) })
                .attr("dx", x.bandwidth() / 2)
                .attr("dy", "-1em")
                .text(d => formatNumber(d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]));
        }

        // add the x Axis
        let isRotate = false;
        let xAxis = svg.append("g")
            .attr("class", 'xAxis')
            .attr("transform", `translate(0,${ysizescale+offsetY})`);
        //间隔显示ticks 
            if (data.length > maxTicksNum) {
                let selectedTickValueArray = [];
                let ordinal = d3.scalePoint()
                    .domain(d3.range(0, maxTicksNum))
                    .rangeRound([0, data.length - 1]);
                for (let i = 0; i < maxTicksNum; i++) {
                    selectedTickValueArray.push(data[ordinal(i)][breakdown[0].field])
                }
                xAxis.call(d3.axisBottom(x).tickValues(selectedTickValueArray));
            } else {
                xAxis.call(d3.axisBottom(x));
            }
        

        xAxis.call(g => {
            let xAxislineData = [[0, 0], [hasSeries ? chartMeasuredWidth : width, 0]]
            let newD = d3.line()(xAxislineData) //生成d
            g.select('.domain')
                .attr('d', newD)
                .attr('stroke', 'black')
                .attr("stroke-width", axisWidth);
            g.selectAll('.tick line')
                .attr('stroke', 'black')
                .attr('y2', 6 * chartSize.height / 320)
                .attr("stroke-width", axisWidth);
            g.selectAll('.tick text')
                .attr('y', 9 * chartSize.height / 320)
                .attr('font-size', tickSize)
                .attr('font-family', NUMFONT)
                .attr('fill', 'black')
                .attr('text-anchor', 'middle')
                .each(function (d, i) {
                    let text = d3.select(this).node();
                    if (text.getBBox().width > x.step()) {
                        isRotate = true;
                    }
                })
        });
        //超过bar宽度就旋转45度
        if (isRotate) {
            svg.selectAll(".xAxis .tick text").each(function (d, i) {
                let textNode = d3.select(this).node();
                textNode.setAttribute("text-anchor", "end");
                textNode.setAttribute("transform-origin", `0 ${textNode.getBBox().y}`);
                textNode.setAttribute("transform", `rotate(-45)`);
            })
        }

         //使图表居中
        //  let a=d3.select("#isotypebarDistribution").node().getBoundingClientRect().width;
        //  let b=d3.select("#isotypebarDistribution").node().getBoundingClientRect().height;      
        //  let c=d3.select("#isotypebarDistribution").node().getBoundingClientRect().left;      
        //  let d=d3.select("svg").node().getBoundingClientRect().left; 
        //  let e=d3.select("#isotypebarDistribution").node().getBoundingClientRect().top;      
        //  let f=d3.select("svg").node().getBoundingClientRect().top; 
      
        //  let transx=d-c+chartSize.width/2-a/2;
        //  let transy=f-e+chartSize.height/2-b/2;
        
        //  d3.select("#isotypebarDistribution").attr("transform", ` translate(${margin.left+transx},${margin.top+ transy}) `)       
        // return svg;

        let cardWidth=chartSize.width;
        let cardHeight=chartSize.height;
        let a=svg.node().getBBox().width;    
        let b=svg.node().getBBox().height;      
        let c=svg.node().getBBox().x;      
        let e=svg.node().getBBox().y;      
       
        let transx=-c+cardWidth/2-a/2;
        let transy=-e+cardHeight/2-b/2;

         if(a>cardWidth) {      
            svg.attr("transform", `scale(${width/a})  translate(${cardWidth/(2*width/a)-(a/2+c)},${cardHeight/(2*width/a)-(b/2+e)}) `)
        }
         else{
            svg.attr("transform",`translate(${transx} ,${transy}) `)
         }

         return svg;
    }
  }

/*---------------------------------------------------------------------------------------------------------*/
  displayOutlier() {
    if(this.style()===Style.PICTOGRAPH){
        let factdata = this.factdata();
        let measure = this.measure();
        let breakdown = this.breakdown();
        let focus = this.focus();
        let pictype=measure[0].pictype;  //获取相应的icon名称

        // set the dimensions and margins of the graph
        let { chartSize, axisWidth, annotationSize, margin } = getSizeBySize(this, "outlier"),
            width = chartSize.width - margin.left - margin.right,
            height = chartSize.height - margin.top - margin.bottom;
        //console.log("height", chartSize.height, margin.top, margin.bottom)
        let svg = d3.select(this.container())
            .append("svg")
            .attr("class", "svg")
            .attr("width", chartSize.width)
            .attr("height", chartSize.height)
            .append("g")
            .attr("id","isotypebarOutlier")
            .attr("transform", `translate(${margin.left} ${margin.top})`);

        if (this.measure().length > 1 || this.breakdown().length > 1) {
            return svg;
        }

      
    /*----------------------处理数据------------------------*/

    let data = factdata;
    let maxYValue = getMaxYValue(factdata, measure); 
    let pictorialdata=data.map(d=>(d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]));
    pictorialdata=pictorialdata.slice(0,10);
    let pictorialx=data.map(d=>(d[breakdown[0].field]));
    pictorialx=pictorialx.slice(0,10);
    let pictorialdatapercent =[]; 
    for(let i=0;i<pictorialdata.length;i++){   
        pictorialdatapercent[i]=parseFloat(pictorialdata[i]/maxYValue*100).toFixed(2);       
    }

    /*------------------通过名称找寻icon----------------------------*/
        svg.append("defs")
            .append("g")
           .attr("id",`pictype${pictype}`)
            .append("path")
            .attr("d",PictorialType[pictype])

        let typesizex1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
        let typesizey1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;
    /*-------------------默认Gradient------------------------------*/
       //判断outlier的位置
        let aa=[];
        data.forEach((element,index) => {
            if (element[focus[0].field]===focus[0].value) aa.push(index);
        });
        // Define the gradient
        let gradient=[];
        for(let i=0;i<pictorialdata.length;i++){
         gradient[i] = svg.append("svg:defs")
            .append("svg:linearGradient")
            .attr("id",`icontype${i}`)
            .attr("x1","0")
            .attr("y1", "1")
            .attr("x2","0")
            .attr("y2", "0")
            .attr("spreadMethod", "pad");

        // Define the gradient colors
        gradient[i].append("svg:stop")
            .attr("offset", "0%")
            .attr("stop-color", function(){
                if (i===aa[0]) return Color.HIGHLIGHT; 
                else return Color.DEFAULT;
            })
            .attr("stop-opacity", 1);
        }


        // set the ranges
        let x;
        let y;
        let offsetY = this.size() === 'large' ? 5 : 2; //离x轴的距离
        let ysizescale=height/3*2-offsetY;//icon长度的拉伸
        if(this.size()==="large") ysizescale=height/3*2-offsetY

        x = d3.scaleBand()
            .range([0, width])
            .domain(data.map(function (d) { return d[breakdown[0].field]; }))
            .padding(0.5);
        y = d3.scaleLinear().nice()
            //.range([height - offsetY, 0])
            .range([ysizescale, 40 * chartSize.height / 320 + offsetY])
            .domain([0, maxYValue])
            .clamp(true);


         /*-------------根据x的数据来进行icon的缩放--------------------------------*/
      let scalex;
      let scaley;
        svg.select(`#pictype${pictype}`)
        .attr("transform", function(){                
            scalex=x.bandwidth()/typesizex1;
            scaley=(y(0)-y(maxYValue))/typesizey1;
            return  `scale(${scalex},${scaley})`                       
        });
     /*----------------计算缩放后的icon长宽------------------------*/
      let typesizex=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
     // let typesizey=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;
      let typex= svg.select(`#pictype${pictype}`).node().getBBox().x;
      let typey=svg.select(`#pictype${pictype}`).node().getBBox().y;
    /*------------------在图表中添加icon----------------------------*/
      svg.append("g")
         .attr("id","pictoLayer")
         .selectAll("use")
         .data(pictorialx)
         .enter()
         .append("use")
         .attr("xlink:href",`#pictype${pictype}`)
         .attr("id",function(d,i){return "icontype"+i})  
         .attr("x", function (d,i) { 
                return x(d)+x.bandwidth()/2-Math.abs(typex*scalex)-typesizex/2;
             })
        .attr("y", function (d) {
             return y(maxYValue)-Math.abs(typey*scaley);
             })

        for(let i=0;i<pictorialdata.length;i++){
        svg.selectAll("#icontype"+i)
        .attr("fill", function(d) {
            gradient[i].append("svg:stop")
                .attr("offset", pictorialdatapercent[i] + '%')
                .attr("stop-color", function(){  
                    if (i===aa[0]) return Color.HIGHLIGHT;               
                   
                    else return Color.DEFAULT;
                })
                .attr("stop-opacity", 1);
            gradient[i].append("svg:stop")
                .attr("offset", pictorialdatapercent[i] + '%')
                .attr("stop-color", Color.BACKGROUND)
                .attr("stop-opacity", 1);
            gradient[i].append("svg:stop")
                .attr("offset", '100%')
                .attr("stop-color", Color.BACKGROUND)
                .attr("stop-opacity", 1);
            return `url(#icontype${i})`;
        }) 
    }
        // add the x Axis
        svg.append("g")
            .attr("class", 'xAxis')
            .attr("transform", `translate(0,${ysizescale+offsetY})`)
            .call(d3.axisBottom(x))
            .call(g => {
                let xAxislineData = [[0, 0], [width, 0]]
                let newD = d3.line()(xAxislineData) //生成d
                g.select('.domain')
                    .attr('d', newD)
                    .attr('stroke', 'black')
                    .attr("stroke-width", axisWidth);
                g.selectAll('.tick line').remove();
                g.selectAll('.tick text')
                    .attr('y', 9 * chartSize.height / 320)
                    .attr('font-size', annotationSize)
                    .attr("font-weight","bold")
                    .attr('font-family', NUMFONT)
                    .attr('fill', d => d === focus[0].value ? Color.HIGHLIGHT : 'black')
                    .attr('text-anchor', 'middle')
                    .attr('display', d => d === focus[0].value ? 'block' : 'none')
            });

        // //tool tip
        let focusValueArray = factdata.filter(d => {
            return d[focus[0].field] === focus[0].value
        });
         let focusData = focusValueArray[0];
         let toolTipX = x(focus[0].value) + x.bandwidth() / 2,//箭头中心所在x的位置
         toolTipY=y(maxYValue)- 40 * chartSize.height / 640,
         //toolTipY = y(focusData[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]) - 40 * chartSize.height / 320, //箭头中心所在y的位置
         toolTipValue = formatNumber(focusData[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]);
        generateToolTip(toolTipX, toolTipY, toolTipValue, svg, chartSize, annotationSize);
   
        //    svg.append("text")
    //     .attr("class","outliertext")
    //     .attr("text-anchor", "middle")
    //     .attr('font-size', annotationSize)
    //     .attr("font-weight","bold")
    //     .attr('font-family', NUMFONT)
    //     .attr('fill',  Color.HIGHLIGHT)
    //     .attr("x", x(focus[0].value) + x.bandwidth() / 2)
    //     //.attr("y", y(focusData[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]) - 40 * chartSize.height / 320)
    //     .attr("y",y(maxYValue))
    //     .attr("dy",'-0.5em')
    //     .text(toolTipValue)
       
    //xField 
        // if (this.size() !== 'small') {
        //     svg.append("text")
        //         .attr("class", "xField")
        //         .attr("text-anchor", "start")
        //         .attr("transform", `translate(${width + margin.right / 4},${height})`)
        //         .text(breakdown[0].field)
        //         .attr('y', '0.5em')
        //         .attr('font-size', tickSize)
        //         .attr('font-family', NUMFONT);
        // }
         //使图表居中
         let cardWidth=chartSize.width;
        let cardHeight=chartSize.height;
        let a=svg.node().getBBox().width;    
        let b=svg.node().getBBox().height;      
        let c=svg.node().getBBox().x;      
        let e=svg.node().getBBox().y;      
       
        let transx=-c+cardWidth/2-a/2;
        let transy=-e+cardHeight/2-b/2;

         if(a>cardWidth) {      
            svg.attr("transform", `scale(${width/a})  translate(${cardWidth/(2*width/a)-(a/2+c)},${cardHeight/(2*width/a)-(b/2+e)}) `)
        }
         else{
            svg.attr("transform",`translate(${transx} ,${transy}) `)
         }

         return svg;
    }
  }
/*-------------------------------------------------------------------------------------------------------------------------------- */
  displayCategorization() {
    if(this.style()===Style.PICTOGRAPH){
        let factdata = this.factdata();
        let measure = this.measure();
        let pictype=measure[0].pictype;  //获取相应的icon名称
     
        let breakdown = this.breakdown();
        let hasSeries = false;
        if (breakdown[1] && breakdown[1].field) hasSeries = true;
        // set the dimensions and margins of the graph
        let { chartSize, tickSize,axisWidth, margin, maxTicksNum } = getSizeBySize(this, "categorization", hasSeries);
        let width = chartSize.width - margin.left - margin.right,
            height = chartSize.height - margin.top - margin.bottom;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("class", "svg")
            .attr("width", chartSize.width)
            .attr("height", chartSize.height)
            .append("g")
            .attr("id","isotypebarCategorization")
            .attr("transform", `translate(${margin.left} ${margin.top})`);

        if (this.measure().length > 1|| hasSeries) {
            return svg;
        }
    /*----------------------处理数据------------------------*/

        let data = factdata;
        let maxYValue = getMaxYValue(factdata, measure); 
        let pictorialdata=data.map(d=>(d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]));
        pictorialdata=pictorialdata.slice(0,10);
        let pictorialx=data.map(d=>(d[breakdown[0].field]));
        pictorialx=pictorialx.slice(0,10);
        let pictorialdatapercent =[]; 
        for(let i=0;i<pictorialdata.length;i++){   
            pictorialdatapercent[i]=parseFloat(pictorialdata[i]/maxYValue*100).toFixed(2);       
        }
 
     /*------------------通过名称找寻icon----------------------------*/
        svg.append("defs")
            .append("g")
           .attr("id",`pictype${pictype}`)
            .append("path")
            .attr("d",PictorialType[pictype])

            let typesizex1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
            let typesizey1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;
 /*-------------------默认Gradient------------------------------*/
            // Define the gradient
            let gradient=[];
            for(let i=0;i<pictorialdata.length;i++){
             gradient[i] = svg.append("svg:defs")
                .append("svg:linearGradient")
                .attr("id",`icontype${i}`)
                .attr("x1","0")
                .attr("y1", "1")
                .attr("x2","0")
                .attr("y2", "0")
                .attr("spreadMethod", "pad");

            // Define the gradient colors
            gradient[i].append("svg:stop")
          
                .attr("offset", "0%")
                .attr("stop-color", function(){
                    return Color.CATEGORICAL[i % 10];
                })
                .attr("stop-opacity", 1);
            }



        // append legend before chart 
        let chartMeasuredWidth = width;//inital 
    
        // set the ranges
      
        let offsetY = this.size() === 'large' ? 5 : 2; //离x轴的距离     
        let ysizescale=height-offsetY;//icon长度的拉伸
        if(this.size()==="large") ysizescale=height/3*2-offsetY
        let x = d3.scaleBand()
                .range([0, width])
                .domain(data.map(function (d) { return d[breakdown[0].field]; }))
                .padding(0.2);
        let y = d3.scaleLinear().nice()
                .range([ysizescale, 0])
                .domain([0, maxYValue])
                .clamp(true);

      /*-------------根据x的数据来进行icon的缩放--------------------------------*/
      let scalex;
      let scaley;
        svg.select(`#pictype${pictype}`)
        .attr("transform", function(){                
            scalex=x.bandwidth()/typesizex1;
            scaley=y(0)/typesizey1;
            return  `scale(${scalex},${scaley})`                       
        });
     /*----------------计算缩放后的icon长宽------------------------*/
      let typesizex=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
     // let typesizey=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;
      let typex= svg.select(`#pictype${pictype}`).node().getBBox().x;
      let typey=svg.select(`#pictype${pictype}`).node().getBBox().y;

      svg.append("g")
         .attr("id","pictoLayer")
         .selectAll("use")
         .data(pictorialx)
         .enter()
         .append("use")
         .attr("xlink:href",`#pictype${pictype}`)
         .attr("id",function(d,i){return "icontype"+i})  
         .attr("x", function (d,i) { 
                return x(d)+x.bandwidth()/2-Math.abs(typex*scalex)-typesizex/2;
             })
        .attr("y", function (d) {
             return y(maxYValue)-Math.abs(typey*scaley);
             })

        for(let i=0;i<pictorialdata.length;i++){
        svg.selectAll("#icontype"+i)
        .attr("fill", function(d) {
            gradient[i].append("svg:stop")
                .attr("offset", pictorialdatapercent[i] + '%')
                .attr("stop-color", Color.CATEGORICAL[i % 10])
                .attr("stop-opacity", 1);
            gradient[i].append("svg:stop")
                .attr("offset", pictorialdatapercent[i] + '%')
                .attr("stop-color", Color.BACKGROUND)
                .attr("stop-opacity", 1);
            gradient[i].append("svg:stop")
                .attr("offset", '100%')
                .attr("stop-color", Color.BACKGROUND)
                .attr("stop-opacity", 1);
            return `url(#icontype${i})`;
        }) 
    }

        //bar value 超多一定数量，不显示bar value
        if (!hasSeries && this.size() !== 'small' && data.length < maxTicksNum) {
            svg.append("g")
                .selectAll("text")
                .data(data)
                .enter()
                .append("text")
                .attr("font-family", NUMFONT)
                .attr("font-size", tickSize)
                .attr("text-anchor", 'middle')
                .attr("x", function (d) { return x(d[breakdown[0].field]); })
                .attr("y", function (d) { return y(d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]) })
                .attr("dx", x.bandwidth() / 2)
                .attr("dy", "-1em")
                .text(d => formatNumber(d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]));
        }

        // add the x Axis
        let isRotate = false;
        let xAxis = svg.append("g")
            .attr("class", 'xAxis')
            .attr("transform", `translate(0,${ysizescale+offsetY})`);
        //间隔显示ticks 
            if (data.length > maxTicksNum) {
                let selectedTickValueArray = [];
                let ordinal = d3.scalePoint()
                    .domain(d3.range(0, maxTicksNum))
                    .rangeRound([0, data.length - 1]);
                for (let i = 0; i < maxTicksNum; i++) {
                    selectedTickValueArray.push(data[ordinal(i)][breakdown[0].field])
                }
                xAxis.call(d3.axisBottom(x).tickValues(selectedTickValueArray));
            } else {
                xAxis.call(d3.axisBottom(x));
            }
        

        xAxis.call(g => {
            let xAxislineData = [[0, 0], [hasSeries ? chartMeasuredWidth : width, 0]]
            let newD = d3.line()(xAxislineData) //生成d
            g.select('.domain')
                .attr('d', newD)
                .attr('stroke', 'black')
                .attr("stroke-width", axisWidth);
            g.selectAll('.tick line')
                .attr('stroke', 'black')
                .attr('y2', 6 * chartSize.height / 320)
                .attr("stroke-width", axisWidth);
            g.selectAll('.tick text')
                .attr('y', 9 * chartSize.height / 320)
                .attr('font-size', tickSize)
                .attr('font-family', NUMFONT)
                .attr('fill', 'black')
                .attr('text-anchor', 'middle')
                .each(function (d, i) {
                    let text = d3.select(this).node();
                    if (text.getBBox().width > x.step()) {
                        isRotate = true;
                    }
                })
        });
        //超过bar宽度就旋转45度
        if (isRotate) {
            svg.selectAll(".xAxis .tick text").each(function (d, i) {
                let textNode = d3.select(this).node();
                textNode.setAttribute("text-anchor", "end");
                textNode.setAttribute("transform-origin", `0 ${textNode.getBBox().y}`);
                textNode.setAttribute("transform", `rotate(-45)`);
            })
        }

         //使图表居中
         let cardWidth=chartSize.width;
         let cardHeight=chartSize.height;
         let a=svg.node().getBBox().width;    
         let b=svg.node().getBBox().height;      
         let c=svg.node().getBBox().x;      
         let e=svg.node().getBBox().y;      
        
         let transx=-c+cardWidth/2-a/2;
         let transy=-e+cardHeight/2-b/2;
 
          if(a>cardWidth) {      
             svg.attr("transform", `scale(${width/a})  translate(${cardWidth/(2*width/a)-(a/2+c)},${cardHeight/(2*width/a)-(b/2+e)}) `)
         }
          else{
             svg.attr("transform",`translate(${transx} ,${transy}) `)
          }
 
          return svg;
    }
  }   

  /*-------------------------------------------------------------------------------------------------*/
  displayExtreme() {
    if(this.style()===Style.PICTOGRAPH){
        let factdata = this.factdata();
        let measure = this.measure();
        let breakdown = this.breakdown();
        // let focus = this.focus();
        let pictype=measure[0].pictype;  //获取相应的icon名称

        // set the dimensions and margins of the graph
        let { chartSize, axisWidth, annotationSize, margin } = getSizeBySize(this, "extreme"),
            width = chartSize.width - margin.left - margin.right,
            height = chartSize.height - margin.top - margin.bottom;
        //console.log("height", chartSize.height, margin.top, margin.bottom)
        let svg = d3.select(this.container())
            .append("svg")
            .attr("class", "svg")
            .attr("width", chartSize.width)
            .attr("height", chartSize.height)
            .append("g")
            .attr("id","isotypebarExtreme")
            .attr("transform", `translate(${margin.left} ${margin.top})`);

        if (this.measure().length > 1 || this.breakdown().length > 1) {
            return svg;
        }

      
    /*----------------------处理数据------------------------*/

    let data = factdata;
    let maxYValue = getMaxYValue(factdata, measure); 
    let pictorialdata=data.map(d=>(d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]));
    pictorialdata=pictorialdata.slice(0,10);
    let maxdata=d3.max(pictorialdata);
     //判断extreme的位置
     let aa=[];
     data.forEach((element,index) => {
         if (element[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]===maxdata) aa.push(index);
     });
    let pictorialx=data.map(d=>(d[breakdown[0].field]));
    pictorialx=pictorialx.slice(0,10);
    let pictorialdatapercent =[]; 
    for(let i=0;i<pictorialdata.length;i++){   
        pictorialdatapercent[i]=parseFloat(pictorialdata[i]/maxYValue*100).toFixed(2);       
    }

    /*------------------通过名称找寻icon----------------------------*/
        svg.append("defs")
            .append("g")
           .attr("id",`pictype${pictype}`)
            .append("path")
            .attr("d",PictorialType[pictype])

        let typesizex1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
        let typesizey1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;
    /*-------------------默认Gradient------------------------------*/
      
        // Define the gradient
        let gradient=[];
        for(let i=0;i<pictorialdata.length;i++){
         gradient[i] = svg.append("svg:defs")
            .append("svg:linearGradient")
            .attr("id",`icontype${i}`)
            .attr("x1","0")
            .attr("y1", "1")
            .attr("x2","0")
            .attr("y2", "0")
            .attr("spreadMethod", "pad");

        // Define the gradient colors
        gradient[i].append("svg:stop")
            .attr("offset", "0%")
            .attr("stop-color", function(){
                if (i===aa[0]) return Color.HIGHLIGHT; 
                else return Color.DEFAULT;
            })
            .attr("stop-opacity", 1);
        }


        // set the ranges
        let x;
        let y;
        let offsetY = this.size() === 'large' ? 5 : 2; //离x轴的距离
        let ysizescale=height/3*2-offsetY;//icon长度的拉伸
        x = d3.scaleBand()
            .range([0, width])
            .domain(data.map(function (d) { return d[breakdown[0].field]; }))
            .padding(0.5);
        y = d3.scaleLinear().nice()
            //.range([height - offsetY, 0])
            .range([ysizescale, 40 * chartSize.height / 320 + offsetY])
            .domain([0, maxYValue])
            .clamp(true);


         /*-------------根据x的数据来进行icon的缩放--------------------------------*/
      let scalex;
      let scaley;
        svg.select(`#pictype${pictype}`)
        .attr("transform", function(){                
            scalex=x.bandwidth()/typesizex1;
            scaley=(y(0)-y(maxYValue))/typesizey1;
            return  `scale(${scalex},${scaley})`                       
        });
     /*----------------计算缩放后的icon长宽------------------------*/
      let typesizex=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
     // let typesizey=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;
      let typex= svg.select(`#pictype${pictype}`).node().getBBox().x;
      let typey=svg.select(`#pictype${pictype}`).node().getBBox().y;
    /*------------------在图表中添加icon----------------------------*/
      svg.append("g")
         .attr("id","pictoLayer")
         .selectAll("use")
         .data(pictorialx)
         .enter()
         .append("use")
         .attr("xlink:href",`#pictype${pictype}`)
         .attr("id",function(d,i){return "icontype"+i})  
         .attr("x", function (d,i) { 
                return x(d)+x.bandwidth()/2-Math.abs(typex*scalex)-typesizex/2;
             })
        .attr("y", function (d) {
             return y(maxYValue)-Math.abs(typey*scaley);
             })

    
        for(let i=0;i<pictorialdata.length;i++){
        svg.selectAll("#icontype"+i)
        .attr("fill", function(d) {
            gradient[i].append("svg:stop")
                .attr("offset", pictorialdatapercent[i] + '%')
                .attr("stop-color", function(){  
                    if (i===aa[0]) return Color.HIGHLIGHT;               
                   
                    else return Color.DEFAULT;
                })
                .attr("stop-opacity", 1);
            gradient[i].append("svg:stop")
                .attr("offset", pictorialdatapercent[i] + '%')
                .attr("stop-color", Color.BACKGROUND)
                .attr("stop-opacity", 1);
            gradient[i].append("svg:stop")
                .attr("offset", '100%')
                .attr("stop-color", Color.BACKGROUND)
                .attr("stop-opacity", 1);
            return `url(#icontype${i})`;
        }) 
    }
        // add the x Axis
        svg.append("g")
            .attr("class", 'xAxis')
            .attr("transform", `translate(0,${ysizescale+offsetY})`)
            .call(d3.axisBottom(x))
            .call(g => {
                let xAxislineData = [[0, 0], [width, 0]]
                let newD = d3.line()(xAxislineData) //生成d
                g.select('.domain')
                    .attr('d', newD)
                    .attr('stroke', 'black')
                    .attr("stroke-width", axisWidth);
                g.selectAll('.tick line').remove();
                g.selectAll('.tick text')
                    .attr('y', 9 * chartSize.height / 320)
                    .attr('font-size', annotationSize)
                    .attr("font-weight","bold")
                    .attr('font-family', NUMFONT)
                    .attr('fill', d => d === pictorialx[aa[0]] ? Color.HIGHLIGHT : 'black')
                    .attr('text-anchor', 'middle')
                    .attr('display', d => d === pictorialx[aa[0]] ? 'block' : 'none')
            });

        //tool tip
        let focusValueArray = factdata.filter(d => {
            return d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]===maxdata
        });
        let focusData = focusValueArray[0];
        let toolTipX = x(pictorialx[aa[0]]) + x.bandwidth() / 2,//箭头中心所在x的位置
        toolTipY=y(maxYValue)- 40 * chartSize.height / 640,
           // toolTipY = y(focusData[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]) - 40 * chartSize.height / 320, //箭头中心所在y的位置
       toolTipValue = formatNumber(focusData[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]);
        generateToolTip(toolTipX, toolTipY, toolTipValue, svg, chartSize, annotationSize);
     
   
   
       //    svg.append("text")
    //    .attr("class","extremetext")
    //    .attr("text-anchor", "middle")
    //    .attr('font-size', annotationSize)
    //    .attr("font-weight","bold")
    //    .attr('font-family', NUMFONT)
    //    .attr('fill',  Color.HIGHLIGHT)
    //    .attr("x",x(pictorialx[aa[0]]) + x.bandwidth() / 2 + x.bandwidth() / 2)
    //    //.attr("y", y(focusData[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]) - 40 * chartSize.height / 320)
    //    .attr("y",y(maxYValue))
    //    .attr("dy",'-0.5em')
    //    .text(toolTipValue)
        //xField 
        // if (this.size() !== 'small') {
        //     svg.append("text")
        //         .attr("class", "xField")
        //         .attr("text-anchor", "start")
        //         .attr("transform", `translate(${width + margin.right / 4},${height})`)
        //         .text(breakdown[0].field)
        //         .attr('y', '0.5em')
        //         .attr('font-size', tickSize)
        //         .attr('font-family', NUMFONT);
        // }
         //使图表居中
         let cardWidth=chartSize.width;
         let cardHeight=chartSize.height;
         let a=svg.node().getBBox().width;    
         let b=svg.node().getBBox().height;      
         let c=svg.node().getBBox().x;      
         let e=svg.node().getBBox().y;      
        
         let transx=-c+cardWidth/2-a/2;
         let transy=-e+cardHeight/2-b/2;
 
          if(a>cardWidth) {      
             svg.attr("transform", `scale(${width/a})  translate(${cardWidth/(2*width/a)-(a/2+c)},${cardHeight/(2*width/a)-(b/2+e)}) `)
         }
          else{
             svg.attr("transform",`translate(${transx} ,${transy}) `)
          }
 
          return svg;
    }
  }
/*-------------------------------------------------------------------------------------------*/
displayTrend() {
    if(this.style()===Style.PICTOGRAPH){
        let factdata = this.factdata();
        let measure = this.measure();
        let pictype=measure[0].pictype;  //获取相应的icon名称
     
        let breakdown = this.breakdown();
        let hasSeries = false;
        if (breakdown[1] && breakdown[1].field) hasSeries = true;
        // set the dimensions and margins of the graph
        let { chartSize, tickSize,axisWidth, margin, maxTicksNum } = getSizeBySize(this, "trend", hasSeries);
        let width = chartSize.width - margin.left - margin.right,
            height = chartSize.height - margin.top - margin.bottom;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("class", "svg")
            .attr("width", chartSize.width)
            .attr("height", chartSize.height)
            .append("g")
            .attr("id","isotypebarTrend")
            .attr("transform", `translate(${margin.left} ${margin.top})`);   
            
        if (this.measure().length > 1|| hasSeries) {
            return svg;
        }


    /*----------------------处理数据------------------------*/

        let data = factdata;
        let maxYValue = getMaxYValue(factdata, measure); 
        let pictorialdata=data.map(d=>(d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]));
        pictorialdata=pictorialdata.slice(0,10);
        let pictorialx=data.map(d=>(d[breakdown[0].field]));
        pictorialx=pictorialx.slice(0,10);
        let pictorialdatapercent =[]; 
        for(let i=0;i<pictorialdata.length;i++){   
            pictorialdatapercent[i]=parseFloat(pictorialdata[i]/maxYValue*100).toFixed(2);       
        }
 
     /*------------------通过名称找寻icon----------------------------*/
        svg.append("defs")
            .append("g")
           .attr("id",`pictype${pictype}`)
            .append("path")
            .attr("d",PictorialType[pictype])

            let typesizex1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
            let typesizey1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;
 /*-------------------默认Gradient------------------------------*/
            // Define the gradient
            let gradient=[];
            for(let i=0;i<pictorialdata.length;i++){
             gradient[i] = svg.append("svg:defs")
                .append("svg:linearGradient")
                .attr("id",`icontype${i}`)
                .attr("x1","0")
                .attr("y1", "1")
                .attr("x2","0")
                .attr("y2", "0")
                .attr("spreadMethod", "pad");

            // Define the gradient colors
            gradient[i].append("svg:stop")
          
                .attr("offset", "0%")
                .attr("stop-color", function(){
                    return Color.DEFAULT;
                })
                .attr("stop-opacity", 1);
            }

     
        // append legend before chart 
        let chartMeasuredWidth = width;//inital 
    
        // set the ranges
      
        let offsetY = this.size() === 'large' ? 5 : 2; //离x轴的距离    
        let ysizescale=height-offsetY;//icon长度的拉伸
        if(this.size()==="large") ysizescale=height/3*2-offsetY 
        let x = d3.scaleBand()
                .range([0, width])
                .domain(data.map(function (d) { return d[breakdown[0].field]; }))
                .padding(0.2);
        let y = d3.scaleLinear().nice()
                .range([ysizescale, 0])
                .domain([0, maxYValue])
                .clamp(true);

      /*-------------根据x的数据来进行icon的缩放--------------------------------*/
      let scalex;
      let scaley;
        svg.select(`#pictype${pictype}`)
        .attr("transform", function(){                
            scalex=x.bandwidth()/typesizex1;
            scaley=(y(0)-y(maxYValue))/typesizey1;
            return  `scale(${scalex},${scaley})`                       
        });
     /*----------------计算缩放后的icon长宽------------------------*/
      let typesizex=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
     // let typesizey=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;
      let typex= svg.select(`#pictype${pictype}`).node().getBBox().x;
      let typey=svg.select(`#pictype${pictype}`).node().getBBox().y;
    /*------------------在图表中添加icon----------------------------*/
      svg.append("g")
         .attr("id","pictoLayer")
         .selectAll("use")
         .data(pictorialx)
         .enter()
         .append("use")
         .attr("xlink:href",`#pictype${pictype}`)
         .attr("id",function(d,i){return "icontype"+i})  
         .attr("x", function (d,i) { 
                return x(d)+x.bandwidth()/2-Math.abs(typex*scalex)-typesizex/2;
             })
        .attr("y", function (d) {
             return y(maxYValue)-Math.abs(typey*scaley);
             })

        for(let i=0;i<pictorialdata.length;i++){
        svg.selectAll("#icontype"+i)
        .attr("fill", function(d) {
            gradient[i].append("svg:stop")
                .attr("offset", pictorialdatapercent[i] + '%')
                .attr("stop-color", Color.DEFAULT)
                .attr("stop-opacity", 1);
            gradient[i].append("svg:stop")
                .attr("offset", pictorialdatapercent[i] + '%')
                .attr("stop-color", Color.BACKGROUND)
                .attr("stop-opacity", 1);
            gradient[i].append("svg:stop")
                .attr("offset", '100%')
                .attr("stop-color", Color.BACKGROUND)
                .attr("stop-opacity", 1);
            return `url(#icontype${i})`;
        }) 
    }

        //bar value 超多一定数量，不显示bar value
        if (!hasSeries && this.size() !== 'small' && data.length < maxTicksNum) {
            svg.append("g")
                .selectAll("text")
                .data(data)
                .enter()
                .append("text")
                .attr("font-family", NUMFONT)
                .attr("font-size", tickSize)
                .attr("text-anchor", 'middle')
                .attr("x", function (d) { return x(d[breakdown[0].field]); })
                .attr("y", function (d) { return y(d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]) })
                .attr("dx", x.bandwidth() / 2)
                .attr("dy", "-1em")
                .text(d => formatNumber(d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]));
        }

        // add the x Axis
        let isRotate = false;
        let xAxis = svg.append("g")
            .attr("class", 'xAxis')
            .attr("transform", `translate(0,${ysizescale+offsetY})`);
        //间隔显示ticks 
            if (data.length > maxTicksNum) {
                let selectedTickValueArray = [];
                let ordinal = d3.scalePoint()
                    .domain(d3.range(0, maxTicksNum))
                    .rangeRound([0, data.length - 1]);
                for (let i = 0; i < maxTicksNum; i++) {
                    selectedTickValueArray.push(data[ordinal(i)][breakdown[0].field])
                }
                xAxis.call(d3.axisBottom(x).tickValues(selectedTickValueArray));
            } else {
                xAxis.call(d3.axisBottom(x));
            }
        

        xAxis.call(g => {
            let xAxislineData = [[0, 0], [hasSeries ? chartMeasuredWidth : width, 0]]
            let newD = d3.line()(xAxislineData) //生成d
            g.select('.domain')
                .attr('d', newD)
                .attr('stroke', 'black')
                .attr("stroke-width",axisWidth);
            g.selectAll('.tick line')
                .attr('stroke', 'black')
                .attr('y2', 6 * chartSize.height / 320)
                .attr("stroke-width",axisWidth);
            g.selectAll('.tick text')
                .attr('y', 9 * chartSize.height / 320)
                .attr('font-size', tickSize+3)
                .attr('font-family', NUMFONT)
                .attr('fill', 'black')
                .attr('text-anchor', 'middle')
                .each(function (d, i) {
                    let text = d3.select(this).node();
                    if (text.getBBox().width > x.step()) {
                        isRotate = true;
                    }
                })
        });
        //超过bar宽度就旋转45度
        if (isRotate) {
            svg.selectAll(".xAxis .tick text").each(function (d, i) {
                let textNode = d3.select(this).node();
                textNode.setAttribute("text-anchor", "end");
                textNode.setAttribute("transform-origin", `0 ${textNode.getBBox().y}`);
                textNode.setAttribute("transform", `rotate(-45)`);
            })
        }

        //增加趋势线
       // let offsetY = this.size() === 'large' ? 5 : 2; //离x轴的距离
        let trendLineG = d3.line()
            .x(function (d) { return x(d[breakdown[0].field]) + x.bandwidth() / 2; })
            .y(function (d) { return y(d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]) ; });
        let trendline = svg.append("g").attr("class", "trendLine")
        trendline.append("path")
            .attr("d", trendLineG(data))
            .attr("fill", "none")
            .attr('stroke-width', d => {
                if (this.size() === 'small') return 2;
                if (this.size() === 'middle') return 3;
                if (this.size() === 'wide') return 4;
                if (this.size() === 'large') return 5;

            })
            .attr('stroke-dasharray', '5,5')
            .attr("stroke", Color.HIGHLIGHT);

        let finalPosition = trendline.select("path").attr("d").split("L").slice(-1)[0];
        let secondPosition = trendline.select("path").attr("d").split("L").slice(-2)[0];
        let f_x = finalPosition.split(",")[0],
            f_y = height - finalPosition.split(",")[1],
            s_x = secondPosition.split(",")[0],
            s_y = height - secondPosition.split(",")[1];
        function getTanDeg(tan) {
            var result = Math.atan(tan) / (Math.PI / 180);
            result = Math.round(result);
            return result;
        }
        let slope = (f_y - s_y) / (f_x - s_x)
        let deg;
        if (getTanDeg(slope) < 0) {
            deg = Math.abs(getTanDeg(slope)) + 90;
        } else {
            deg = - getTanDeg(slope) + 90;
        }
        trendline.append("path")
            .attr("class", "triangle")
            .attr("transform", "translate(" + finalPosition + ")rotate(" + deg + ")")
            .attr("d", d3.symbol().type(d3.symbolTriangle).size(0.16 * height))
            .attr("fill", Color.HIGHLIGHT);
        // let trendLineG = d3.line()
        //     .x(function (d) { return x(d[breakdown[0].field]) + x.bandwidth() / 2; })
        //     .y(function (d) { return y(d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]) ; });
        // let linewidth='10,10';
        // if(this.size() === 'middle'||this.size()==='small') linewidth='5,5';

        // svg.append("path")
        //     .attr("d", trendLineG(data))
        //     .attr("fill", "none")
        //     .attr('stroke-width', 3)
        //     .attr('stroke-dasharray', linewidth)
        //     .attr("stroke", Color.DASHLINE);

         //使图表居中
         let cardWidth=chartSize.width;
         let cardHeight=chartSize.height;
         let a=svg.node().getBBox().width;    
         let b=svg.node().getBBox().height;      
         let c=svg.node().getBBox().x;      
         let e=svg.node().getBBox().y;      
        
         let transx=-c+cardWidth/2-a/2;
         let transy=-e+cardHeight/2-b/2;
 
          if(a>cardWidth) {      
             svg.attr("transform", `scale(${width/a})  translate(${cardWidth/(2*width/a)-(a/2+c)},${cardHeight/(2*width/a)-(b/2+e)}) `)
         }
          else{
             svg.attr("transform",`translate(${transx} ,${transy}) `)
          }
 
          return svg;
    }
  }
/*-----------------------------------------------------------------------------------------------------*/
  displayValue() {
    if(this.style()===Style.PICTOGRAPH){
        let factdata = this.factdata();
        let measure = this.measure();
        let breakdown = this.breakdown();
        let subspace = this.subspace();
        let pictype=measure[0].pictype;  //获取相应的icon名称

        // set the dimensions and margins of the graph
        let { chartSize, axisWidth, margin,annotationSize } = getSizeBySize(this, "value"),
            width = chartSize.width - margin.left - margin.right,
            height = chartSize.height - margin.top - margin.bottom;
        //console.log("height", chartSize.height, margin.top, margin.bottom)


     
            let svg = d3.select(this.container())
            .append("svg")
            .attr("class", "svg")
            .attr("width", chartSize.width)
            .attr("height", chartSize.height)
            .append("g")
            .attr("id","isotypebarValue")
            .attr("transform", `translate(${margin.left} ${margin.top})`);

        if (this.measure().length > 1 || this.breakdown().length > 1) {
            return svg;
        }

           /*------------------通过名称找寻icon----------------------------*/
           svg.append("defs")
           .append("g")
          .attr("id",`pictype${pictype}`)
           .append("path")
           .attr("d",PictorialType[pictype])
   
           let typesizex1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
           let typesizey1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;
   
         /*-------------根据chartsize的数据来进行icon的缩放--------------------------------*/
         let scalex;
         let scalexsize=1/2;
         if(this.size()==='large') scalexsize=3/4
           svg.select(`#pictype${pictype}`)
           .attr("transform", function(){                
               scalex=(width>height?height*scalexsize:width*scalexsize)/(typesizex1>typesizey1?typesizex1:typesizey1);
               return  `scale(${scalex})`                       
           });
        /*----------------计算缩放后的icon长宽------------------------*/
         let typesizex=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
         let typesizey=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;
         let typex= svg.select(`#pictype${pictype}`).node().getBBox().x;
         let typey=svg.select(`#pictype${pictype}`).node().getBBox().y;
           /*-------------------默认Gradient------------------------------*/
           // Define the gradient
        //    let gradient= svg.append("svg:defs")
        //        .append("svg:linearGradient")
        //        .attr("id","icontype1")
        //        .attr("x1","0")
        //        .attr("y1", "1")
        //        .attr("x2","0")
        //        .attr("y2", "0")
        //        .attr("spreadMethod", "pad");
   
        //    // Define the gradient colors
        //    gradient.append("svg:stop")
        //        .attr("offset", "0%")
        //        .attr("stop-color", Color.DEFAULT)
        //        .attr("stop-opacity", 1);
   

       
        let barValue;

        if (subspace.length === 0) {
            barValue = d3.sum(factdata, d => d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]);
            factdata[0][measure[0].field] = barValue;
            factdata[0][breakdown[0].field] = '';
            factdata = [factdata[0]]
        } else {
            barValue = factdata[0][measure[0].aggregate === 'count' ? "COUNT" : measure[0].field];
        }
        
        //let maxYValue = (1.5 * barValue+100);
        // let pictorialdatapercent=parseFloat(barValue/maxYValue*100).toFixed(2);
        // set the ranges
        let x;
        let y;
        let offsetY = this.size() === 'large' ? 5 : 2; //离x轴的距离
        let xField = subspace.length === 0 ? breakdown[0].field : subspace[0].value;
        x = d3.scaleBand()
            .range([0, width])
            .domain([xField])
            .padding(0.8);
        y = d3.scaleLinear().nice()
            // .range([height - offsetY, 0])
            //.range([chartSize.height/2+typesizey/2+offsetY, 0])
            .range([height+offsetY,height-typesizey-offsetY])
           // .domain([0, maxYValue])
           .domain([0, barValue])
            .clamp(true);

         /*--------------------------添加icon-----------------------------------*/
        svg.append("g")
        .attr("id","pictoLayer")
        .append("use")
        .attr("xlink:href",`#pictype${pictype}`)
        .attr("id", "icontype")  
        .attr("x", function () { 
               return (width) / 2-Math.abs(typex*scalex)-typesizex/2;
            })
       .attr("y", function(){return  height-typesizey-offsetY-Math.abs(typey*scalex)})
       .attr("fill",Color.DEFAULT)

    //    svg.select("#icontype")
    //    .attr("fill", function(d) {
    //        gradient.append("svg:stop")
    //            .attr("offset", pictorialdatapercent + '%')
    //            .attr("stop-color", Color.DEFAULT)
    //            .attr("stop-opacity", 1);
    //        gradient.append("svg:stop")
    //            .attr("offset", pictorialdatapercent + '%')
    //            .attr("stop-color", Color.BACKGROUND)
    //            .attr("stop-opacity", 1);
    //        gradient.append("svg:stop")
    //            .attr("offset", '100%')
    //            .attr("stop-color", Color.BACKGROUND)
    //            .attr("stop-opacity", 1);
    //        return `url(#icontype1)`;
    //    }) 


        // add the x Axis
        svg.append("g")
            .attr("class", 'xAxis')
            .attr("transform", `translate(0,${height})`)
            .call(d3.axisBottom(x))
            .call(g => {
                let xAxislineData = [[0, 0], [width, 0]]
                let newD = d3.line()(xAxislineData) //生成d
                g.select('.domain')
                    .attr('d', newD)
                    .attr('stroke', 'black')
                    .attr("stroke-width", axisWidth);
                g.selectAll('.tick text')
                    .attr('y', 9 * chartSize.height / 320)
                    .attr('font-size', annotationSize)
                    .attr('font-family', NUMFONT)
                    .attr('fill', 'black')
                    .attr('text-anchor', 'middle')
                if (subspace.length !== 0) {
                    g.selectAll('.tick line')
                        .attr('stroke', 'black')
                        .attr('y2', 6 * chartSize.height / 320)
                        .attr("stroke-width", axisWidth);
                } else {
                    g.selectAll('.tick line').remove();
                }
            });

        //tool tip
        let toolTipX = width / 2,//箭头中心所在x的位置
            toolTipY = y(barValue) - 18 * chartSize.height / 320, //箭头中心所在y的位置 ,原先是40*
            toolTipValue = formatNumber(barValue);
        let tooltiptext;//重新规定尺寸
        if(this.size()==="large") tooltiptext=50;
        if(this.size()==="wide") tooltiptext=35;
        if(this.size()==="middle") tooltiptext=25;
        if(this.size()==="small") tooltiptext=20;
        
        generateToolTip(toolTipX, toolTipY, toolTipValue, svg, chartSize, tooltiptext);


           //使图表居中
           let cardWidth=chartSize.width;
           let cardHeight=chartSize.height;
           let a=svg.node().getBBox().width;    
           let b=svg.node().getBBox().height;      
           let c=svg.node().getBBox().x;      
           let e=svg.node().getBBox().y;      
          
           let transx=-c+cardWidth/2-a/2;
           let transy=-e+cardHeight/2-b/2;
   
           if(a>cardWidth) {      
               svg.attr("transform", `scale(${width/a})  translate(${cardWidth/(2*width/a)-(a/2+c)},${cardHeight/(2*width/a)-(b/2+e)}) `)
           }
            else{
               svg.attr("transform",`translate(${transx} ,${transy}) `)
            }
            return svg;
        }
   }
   /*---------------------------------------------------------------------------------------------*/
   displayProportion(){
    if(this.style()===Style.PICTOGRAPH){
        let factdata = this.factdata();
        let measure = this.measure();
        let breakdown = this.breakdown();
        let focus = this.focus();
        let pictype=measure[0].pictype;  //获取相应的icon名称

        // set the dimensions and margins of the graph
        let { chartSize, axisWidth, margin ,maxTicksNum, tickSize} = getSizeBySize(this, "proportion"),
            width = chartSize.width - margin.left - margin.right,
            height = chartSize.height - margin.top - margin.bottom;
        //console.log("height", chartSize.height, margin.top, margin.bottom)
        let svg = d3.select(this.container())
            .append("svg")
            .attr("class", "svg")
            .attr("width", chartSize.width)
            .attr("height", chartSize.height)
            .append("g")
            .attr("id","isotypebarProportion")
            .attr("transform", `translate(${margin.left} ${margin.top})`);

        if (this.measure().length > 1 || this.breakdown().length > 1|| this.focus().length>2) {
            return svg;
        }

      
    /*----------------------处理数据------------------------*/

    let data = factdata;
    let maxYValue = getMaxYValue(factdata, measure); 
    let pictorialdata=data.map(d=>(d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]));
    pictorialdata=pictorialdata.slice(0,10);
    let pictorialdataSum=d3.sum(pictorialdata);
    let pictorialx=data.map(d=>(d[breakdown[0].field]));
    pictorialx=pictorialx.slice(0,10);
    let pictorialdatapercent =[]; 
    for(let i=0;i<pictorialdata.length;i++){   
        pictorialdatapercent[i]=parseFloat(pictorialdata[i]/pictorialdataSum*100).toFixed(1);       
    }

    /*------------------通过名称找寻icon----------------------------*/
        svg.append("defs")
            .append("g")
           .attr("id",`pictype${pictype}`)
            .append("path")
            .attr("d",PictorialType[pictype])

        let typesizex1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
        let typesizey1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;
    /*-------------------默认Gradient------------------------------*/
       //判断proportion 高亮位置
        let aa=[];
        data.forEach((element,index) => {
            if (element[focus[0].field]===focus[0].value) aa.push(index);
        });
        // Define the gradient
        let gradient=[];
        for(let i=0;i<pictorialdata.length;i++){
         gradient[i] = svg.append("svg:defs")
            .append("svg:linearGradient")
            .attr("id",`icontype${i}`)
            .attr("x1","0")
            .attr("y1", "1")
            .attr("x2","0")
            .attr("y2", "0")
            .attr("spreadMethod", "pad");

        // Define the gradient colors
        gradient[i].append("svg:stop")
            .attr("offset", "0%")
            .attr("stop-color", function(){
                if (i===aa[0]) return Color.HIGHLIGHT; 
                else return Color.DEFAULT;
            })
            .attr("stop-opacity", 1);
        }


        // set the ranges
        let x;
        let y;
        let offsetY = this.size() === 'large' ? 5 : 2; //离x轴的距离
        let ysizescale=height/3*2-offsetY;//icon长度的拉伸
        if(this.size()==="large") ysizescale=height/3*2-offsetY

        x = d3.scaleBand()
            .range([0, width])
            .domain(data.map(function (d) { return d[breakdown[0].field]; }))
            .padding(0.5);
        y = d3.scaleLinear().nice()
            //.range([height - offsetY, 0])
            .range([ysizescale, 40 * chartSize.height / 320 + offsetY])
            .domain([0, maxYValue])
            .clamp(true);


         /*-------------根据x的数据来进行icon的缩放--------------------------------*/
      let scalex;
      let scaley;
        svg.select(`#pictype${pictype}`)
        .attr("transform", function(){                
            scalex=x.bandwidth()/typesizex1;
            scaley=(y(0)-y(maxYValue))/typesizey1;
            return  `scale(${scalex},${scaley})`                       
        });
     /*----------------计算缩放后的icon长宽------------------------*/
      let typesizex=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
     // let typesizey=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;
      let typex= svg.select(`#pictype${pictype}`).node().getBBox().x;
      let typey=svg.select(`#pictype${pictype}`).node().getBBox().y;
    /*------------------在图表中添加icon----------------------------*/
      svg.append("g")
         .attr("id","pictoLayer")
         .selectAll("use")
         .data(pictorialx)
         .enter()
         .append("use")
         .attr("xlink:href",`#pictype${pictype}`)
         .attr("id",function(d,i){return "icontype"+i})  
         .attr("x", function (d,i) { 
                return x(d)+x.bandwidth()/2-Math.abs(typex*scalex)-typesizex/2;
             })
        .attr("y", function (d) {
             return y(maxYValue)-Math.abs(typey*scaley);
             })

        for(let i=0;i<pictorialdata.length;i++){
        svg.selectAll("#icontype"+i)
        .attr("fill", function(d) {
            gradient[i].append("svg:stop")
                .attr("offset", pictorialdatapercent[i] + '%')
                .attr("stop-color", function(){  
                    if (i===aa[0]) return Color.HIGHLIGHT;               
                   
                    else return Color.DEFAULT;
                })
                .attr("stop-opacity", 1);
            gradient[i].append("svg:stop")
                .attr("offset", pictorialdatapercent[i] + '%')
                .attr("stop-color", Color.BACKGROUND)
                .attr("stop-opacity", 1);
            gradient[i].append("svg:stop")
                .attr("offset", '100%')
                .attr("stop-color", Color.BACKGROUND)
                .attr("stop-opacity", 1);
            return `url(#icontype${i})`;
        }) 
    }
    let chartMeasuredWidth = width;//inital 
    // if ( this.size() !== 'small' && data.length < maxTicksNum) {
        svg.append("g")
            .selectAll("text")
            .data(data)
            .enter()
            .append("text")
            .attr("font-family", NUMFONT)
            .attr('fill', function(d,i){
                if (i===aa[0]) return Color.HIGHLIGHT;  
                else return "black" ;
            })
            .attr('font-weight', function(d,i){
                if (i===aa[0]) return "bold";  
                else return "normal" ;
            })
            .attr("font-size", tickSize)
            .attr("text-anchor", 'middle')
            .attr("x", function (d) { return x(d[breakdown[0].field]); })
            .attr("y",y(maxYValue))
            //.attr("y", function (d) { return y(d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]) })
            .attr("dx", x.bandwidth() / 2)
            .attr("dy", "-1em")
            .text((d,i) => pictorialdatapercent[i]+"%");
  //  }

    // add the x Axis
    let isRotate = false;
    let xAxis = svg.append("g")
        .attr("class", 'xAxis')
        .attr("transform", `translate(0,${ysizescale+offsetY})`);
    //间隔显示ticks 
        if (data.length > maxTicksNum) {
            let selectedTickValueArray = [];
            let ordinal = d3.scalePoint()
                .domain(d3.range(0, maxTicksNum))
                .rangeRound([0, data.length - 1]);
            for (let i = 0; i < maxTicksNum; i++) {
                selectedTickValueArray.push(data[ordinal(i)][breakdown[0].field])
            }
            xAxis.call(d3.axisBottom(x).tickValues(selectedTickValueArray));
        } else {
            xAxis.call(d3.axisBottom(x));
        }
    

    xAxis.call(g => {
        let xAxislineData = [[0, 0], [false ? chartMeasuredWidth : width, 0]]
        let newD = d3.line()(xAxislineData) //生成d
        g.select('.domain')
            .attr('d', newD)
            .attr('stroke', 'black')
            .attr("stroke-width", axisWidth);
        g.selectAll('.tick line')
            .attr('stroke', 'black')
            .attr('y2', 6 * chartSize.height / 320)
            .attr("stroke-width", axisWidth);
        g.selectAll('.tick text')
            .attr('y', 9 * chartSize.height / 320)
            .attr('font-size', tickSize)
            .attr('font-family', NUMFONT)
            .attr('fill', function(d,i){
                if (i===aa[0]) return Color.HIGHLIGHT;  
                else return "black" ;
            })
            .attr('font-weight', function(d,i){
                if (i===aa[0]) return "bold";  
                else return "normal" ;
            })
            .attr('text-anchor', 'middle')
            .each(function (d, i) {
                let text = d3.select(this).node();
                if (text.getBBox().width > x.step()) {
                    isRotate = true;
                }
            })
    });
    //超过bar宽度就旋转45度
    if (isRotate) {
        svg.selectAll(".xAxis .tick text").each(function (d, i) {
            let textNode = d3.select(this).node();
            textNode.setAttribute("text-anchor", "end");
            textNode.setAttribute("transform-origin", `0 ${textNode.getBBox().y}`);
            textNode.setAttribute("transform", `rotate(-45)`);
        })
    }
   
         //使图表居中
         let cardWidth=chartSize.width;
         let cardHeight=chartSize.height;
         let a=svg.node().getBBox().width;    
         let b=svg.node().getBBox().height;      
         let c=svg.node().getBBox().x;      
         let e=svg.node().getBBox().y;      
        
         let transx=-c+cardWidth/2-a/2;
         let transy=-e+cardHeight/2-b/2;
 
          if(a>cardWidth) {      
             svg.attr("transform", `scale(${width/a})  translate(${cardWidth/(2*width/a)-(a/2+c)},${cardHeight/(2*width/a)-(b/2+e)}) `)
         }
          else{
             svg.attr("transform",`translate(${transx} ,${transy}) `)
          }
 
          return svg;
    }
   }
   /*---------------------------------------------------------------------------------------------*/
   displayProportion1() {
    if(this.style()===Style.PICTOGRAPH){
          // set the dimensions and margins of the graph
          let { chartSize, tickSize, annotationSize, margin } = getSizeBySize(this, "proportion"),
          width = chartSize.width - margin.left - margin.right,
          height = chartSize.height - margin.top - margin.bottom;

        let svg = d3.select(this.container())
            .append("svg")
            .attr("width", chartSize.width)
            .attr("height", chartSize.height)
            .append("g")
            .attr("id","isotypebarProportion")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        if (this.focus().length < 2 || this.measure().length > 1) {
            return svg;
        }
        let focus = this.focus();
        let filteredData = [] //sorted by focus
        for (const fs of focus) {
            this.factdata().filter((x) => x[fs.field] === fs.value)[0] && filteredData.push(this.factdata().filter((x) => x[fs.field] === fs.value)[0])
        }

        let measure = this.measure();
        let breakdown = this.breakdown();
        let maxYValue = getMaxYValue(this.factdata(), measure);
        let pictype=measure[0].pictype;  //获取相应的icon名称
        // filteredData.map(data => {
        //     data.maxValue = (maxYValue - data[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]);
        //     return data;
        // })
        // //console.log('showDifference in child')

         let data = filteredData;
        // let seriesData = d3.stack()
        //     .keys([measure[0].aggregate === "count" ? "COUNT" : measure[0].field, "maxValue"])
        //     (data);
        // //console.log("series...", seriesData)
      

        // set the ranges
        let x = d3.scaleBand()
            .range([0, width])
            .padding(0.2);
        let y = d3.scaleLinear().nice()
            .range([height, 0]);

        
        // Scale the range of the data in the domains
        x.domain(data.map(function (d) { return d[breakdown[0].field]; }));
        y.domain([0, maxYValue]);

         /*------------------通过名称找寻icon----------------------------*/
         svg.append("defs")
         .append("g")
        .attr("id",`pictype${pictype}`)
         .append("path")
         .attr("d",PictorialType[pictype])

         let typesizex1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
         let typesizey1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;

        /*-------------------默认Gradient------------------------------*/
         // Define the gradient
         let gradient=[];
         for(let i=0;i<2;i++){
          gradient[i] = svg.append("svg:defs")
             .append("svg:linearGradient")
             .attr("id",`icontype${i}`)
             .attr("x1","0")
             .attr("y1", "1")
             .attr("x2","0")
             .attr("y2", "0")
             .attr("spreadMethod", "pad");

         // Define the gradient colors
         gradient[i].append("svg:stop")
       
             .attr("offset", "0%")
             .attr("stop-color", function(){
                 return Color.DEFAULT;
             })
             .attr("stop-opacity", 1);
         }
 

   /*-------------根据x的数据来进行icon的缩放--------------------------------*/
   let scalex;
   let scaley;
     svg.select(`#pictype${pictype}`)
     .attr("transform", function(){          
        scalex=typesizex1>typesizey1?x.bandwidth()/typesizex1:x.bandwidth()/typesizey1;      
         //scalex=x.bandwidth()/typesizex1;
        // scaley=(y(0)-y(maxYValue))/typesizey1;
         scaley=scalex;
         return  `scale(${scalex},${scaley})`                       
     });
  /*----------------计算缩放后的icon长宽------------------------*/
   let typesizex=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
   let typesizey=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;
   let typex= svg.select(`#pictype${pictype}`).node().getBBox().x;
   let typey=svg.select(`#pictype${pictype}`).node().getBBox().y;
 /*------------------在图表中添加icon----------------------------*/
   svg.append("g")
      .attr("id","pictoLayer")
      .selectAll("use")
      .data(data)
      .enter()
      .append("use")
      .attr("xlink:href",`#pictype${pictype}`)
      .attr("id",function(d,i){return "icontype"+i})  
      .attr("x", function (d,i) { 
             return x(d[breakdown[0].field])+x.bandwidth()/2-Math.abs(typex*scalex)-typesizex/2;
          })
          .attr("y", function(){return  height-typesizey-Math.abs(typey*scalex)})
    //  .attr("y", function (d) {
    //       return y(maxYValue)-Math.abs(typey*scaley);
    //       })

   let totalValue = d3.sum(data, d => d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field])
   let pictorialdata=data.map(d=>(d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]));
   let pictorialdatapercent =[]; 
   for(let i=0;i<pictorialdata.length;i++){   
       pictorialdatapercent[i]=parseFloat(pictorialdata[i]/totalValue*100).toFixed(0);       
   }
     for(let i=0;i<2;i++){
     svg.selectAll("#icontype"+i)
     .attr("fill", function(d) {
         gradient[i].append("svg:stop")
             .attr("offset", pictorialdatapercent[i] + '%')
             .attr("stop-color", Color.DEFAULT)
             .attr("stop-opacity", 1);
         gradient[i].append("svg:stop")
             .attr("offset", pictorialdatapercent[i] + '%')
             .attr("stop-color", Color.BACKGROUND)
             .attr("stop-opacity", 1);
         gradient[i].append("svg:stop")
             .attr("offset", '100%')
             .attr("stop-color", Color.BACKGROUND)
             .attr("stop-opacity", 1);
         return `url(#icontype${i})`;
     }) 
 }


        // append the rectangles for the bar chart
        // svg.selectAll(".barSeries")
        //     //.data(data)
        //     .data(seriesData)
        //     .enter()
        //     .append("g")
        //     .attr("class", "barSeries")
        //     .attr("fill", (d, i) => (i === seriesData.length - 1) ? Color.BACKGROUND : Color.DEFAULT)
        //     .selectAll("bars")
        //     .data(d => d)
        //     .enter()
        //     .append("rect")
        //     .attr("class", "bars")
        //     .attr("x", (d, i) => x(d.data[breakdown[0].field]))
        //     .attr("y", d => y(d[1]))
        //     .attr("height", d => y(d[0]) - y(d[1]))
        //     .attr("width", x.bandwidth());

    
        svg.append("g")
            .selectAll("text")
            .data(data)
            .enter()
            .append("text")
            .attr("font-family", NUMFONT)
            .attr("font-size", annotationSize)
            .attr("text-anchor", 'middle')
            .attr("x", function (d) { return x(d[breakdown[0].field]); })
            .attr("y",function(d,i){
                return height-typesizey*pictorialdatapercent[i]/100;
            })
            //.attr("y", function (d) { return y(d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]) })
            .attr("dx", x.bandwidth() / 2)
            .attr("dy", "-1em")
            .text(d => (d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field] / totalValue * 100).toFixed(0) + "%");

        // add the x Axis
        let xAxis = svg.append("g")
            .attr("class", 'xAxis')
            .attr("transform", `translate(0,${height})`)
            .call(d3.axisBottom(x));

        //x Axis style
        xAxis.select('.domain').remove();

        //tick style
        xAxis.selectAll('.tick line').remove();

        xAxis.selectAll('.tick text')
            .attr('y', 9 * chartSize.height / 320)
            .attr('font-size', tickSize+3)
            .attr('font-family', NUMFONT);

          //使图表居中
          let cardWidth=chartSize.width;
          let cardHeight=chartSize.height;
          let a=svg.node().getBBox().width;    
          let b=svg.node().getBBox().height;      
          let c=svg.node().getBBox().x;      
          let e=svg.node().getBBox().y;      
         
          let transx=-c+cardWidth/2-a/2;
          let transy=-e+cardHeight/2-b/2;
  
           if(a>cardWidth) {      
              svg.attr("transform", `scale(${width/a})  translate(${cardWidth/(2*width/a)-(a/2+c)},${cardHeight/(2*width/a)-(b/2+e)}) `)
          }
           else{
              svg.attr("transform",`translate(${transx} ,${transy}) `)
           }
  
           return svg;
    }
  }

  displayDifference() {
    if(this.style()===Style.PICTOGRAPH){
        // set the dimensions and margins of the graph
        let { chartSize, tickSize, annotationSize, margin } = getSizeBySize(this, "difference"),
        width = chartSize.width - margin.left - margin.right,
        height = chartSize.height - margin.top - margin.bottom;

     let svg = d3.select(this.container())
         .append("svg")
         .attr("width", chartSize.width)
         .attr("height", chartSize.height)
         .append("g")
         .attr("id","isotypebarDifference")
         .attr("transform", `translate(${margin.left},${margin.top})`);

     //difference类型会在filteredData里生成两条数据
     if (this.measure().length > 1 || this.focus().length < 2) {
         return svg;
     }
     let focus = this.focus();
     let filteredData = [] //sorted by focus
     for (const fs of focus) {
         this.factdata().filter((x) => x[fs.field] === fs.value)[0] && filteredData.push(this.factdata().filter((x) => x[fs.field] === fs.value)[0])
     }

     let measure = this.measure();
     let breakdown = this.breakdown();
     let pictype=measure[0].pictype;

     let maxYValue = getMaxYValue(filteredData, measure);
     let data = filteredData;


    // set the ranges
    let xwidthmax;
    let paddingx;
    if (this.size() !== Size.SMALL) {
        xwidthmax=width/5*3;
        paddingx=0.1;
    }else{
        xwidthmax=width;
        paddingx=0.2;
    }
    let x = d3.scaleBand()
      .range([0, xwidthmax])
      .padding(paddingx);
    let y = d3.scaleLinear().nice()
        .range([height, 0]);

    
    // Scale the range of the data in the domains
    x.domain(data.map(function (d) { return d[breakdown[0].field]; }));
    y.domain([0, maxYValue]);

    /*------------------通过名称找寻icon----------------------------*/
    svg.append("defs")
    .append("g")
   .attr("id",`pictype${pictype}`)
    .append("path")
    .attr("d",PictorialType[pictype])

    let typesizex1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
    let typesizey1=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;

    /*-------------------默认Gradient------------------------------*/
    // Define the gradient
    let gradient=[];
    for(let i=0;i<2;i++){
        gradient[i] = svg.append("svg:defs")
        .append("svg:linearGradient")
        .attr("id",`icontype${i}`)
        .attr("x1","0")
        .attr("y1", "1")
        .attr("x2","0")
        .attr("y2", "0")
        .attr("spreadMethod", "pad");

    // Define the gradient colors
    gradient[i].append("svg:stop")
    
        .attr("offset", "0%")
        .attr("stop-color", function(){
            return Color.DEFAULT;
        })
        .attr("stop-opacity", 1);
    }


    /*-------------根据x的数据来进行icon的缩放--------------------------------*/
    let scalex;
    let scaley;
    svg.select(`#pictype${pictype}`)
    .attr("transform", function(){          
    scalex=typesizex1>typesizey1?x.bandwidth()/typesizex1:x.bandwidth()/typesizey1;      
    scaley=scalex;
    return  `scale(${scalex},${scaley})`                       
    });
    /*----------------计算缩放后的icon长宽------------------------*/
    let typesizex=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().width;
    let typesizey=svg.select(`#pictype${pictype}`).node().getBoundingClientRect().height;
    let typex= svg.select(`#pictype${pictype}`).node().getBBox().x;
    let typey=svg.select(`#pictype${pictype}`).node().getBBox().y;
    /*------------------在图表中添加icon----------------------------*/
    svg.append("g")
    .attr("id","pictoLayer")
    .selectAll("use")
    .data(data)
    .enter()
    .append("use")
    .attr("xlink:href",`#pictype${pictype}`)
    .attr("id",function(d,i){return "icontype"+i})  
    .attr("x", function (d,i) { 
        return x(d[breakdown[0].field])+x.bandwidth()/2-Math.abs(typex*scalex)-typesizex/2;
        })
    .attr("y", function(){return  height-typesizey-Math.abs(typey*scalex)})

    let totalValue = d3.sum(data, d => d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field])
    let pictorialdata=data.map(d=>(d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field]));
    let pictorialdatapercent =[]; 
    for(let i=0;i<pictorialdata.length;i++){   
    pictorialdatapercent[i]=parseFloat(pictorialdata[i]/totalValue*100).toFixed(0);       
    }
    //比较两者的difference占比 并进行填色
    if(pictorialdatapercent[0]>pictorialdatapercent[1]){
        svg.selectAll("#icontype0")
        .attr("fill",function(){
        gradient[0].append("svg:stop")
        .attr("offset",pictorialdatapercent[1]+"%")
        .attr("stop-color",Color.DEFAULT)
        .attr("stop-opacity",1);
        gradient[0].append("svg:stop")
        .attr("offset",pictorialdatapercent[1]+"%")
        .attr("stop-color",Color.HIGHLIGHT)
        .attr("stop-opacity",1);
        gradient[0].append("svg:stop")
        .attr("offset", pictorialdatapercent[0] + '%')
        .attr("stop-color", Color.HIGHLIGHT)
        .attr("stop-opacity", 1);
        gradient[0].append("svg:stop")
        .attr("offset", pictorialdatapercent[0] + '%')
        .attr("stop-color", Color.BACKGROUND)
        .attr("stop-opacity", 1);
        gradient[0].append("svg:stop")
        .attr("offset", '100%')
        .attr("stop-color", Color.BACKGROUND)
        .attr("stop-opacity", 1);
         return `url(#icontype${0})`;
        })
        svg.selectAll("#icontype1")
        .attr("fill",function(){
        gradient[1].append("svg:stop")
        .attr("offset",pictorialdatapercent[1]+"%")
        .attr("stop-color",Color.DEFAULT)
        .attr("stop-opacity",1);
        gradient[1].append("svg:stop")
        .attr("offset", pictorialdatapercent[1] + '%')
        .attr("stop-color", Color.BACKGROUND)
        .attr("stop-opacity", 1);
        gradient[1].append("svg:stop")
        .attr("offset", '100%')
        .attr("stop-color", Color.BACKGROUND)
        .attr("stop-opacity", 1);
         return `url(#icontype${1})`;
        })
    }else{
        svg.selectAll("#icontype0")
        .attr("fill",function(){
        gradient[0].append("svg:stop")
        .attr("offset",pictorialdatapercent[0]+"%")
        .attr("stop-color",Color.DEFAULT)
        .attr("stop-opacity",1);
        gradient[0].append("svg:stop")
        .attr("offset", pictorialdatapercent[0] + '%')
        .attr("stop-color", Color.BACKGROUND)
        .attr("stop-opacity", 1);
        gradient[0].append("svg:stop")
        .attr("offset", '100%')
        .attr("stop-color", Color.BACKGROUND)
        .attr("stop-opacity", 1);
         return `url(#icontype${0})`;
        })
        svg.selectAll("#icontype1")
        .attr("fill",function(){
        gradient[1].append("svg:stop")
        .attr("offset",pictorialdatapercent[0]+"%")
        .attr("stop-color",Color.DEFAULT)
        .attr("stop-opacity",1);
        gradient[1].append("svg:stop")
        .attr("offset",pictorialdatapercent[0]+"%")
        .attr("stop-color",Color.HIGHLIGHT)
        .attr("stop-opacity",1);
        gradient[1].append("svg:stop")
        .attr("offset", pictorialdatapercent[1] + '%')
        .attr("stop-color", Color.HIGHLIGHT)
        .attr("stop-opacity", 1);
        gradient[1].append("svg:stop")
        .attr("offset", pictorialdatapercent[1] + '%')
        .attr("stop-color", Color.BACKGROUND)
        .attr("stop-opacity", 1);
        gradient[1].append("svg:stop")
        .attr("offset", '100%')
        .attr("stop-color", Color.BACKGROUND)
        .attr("stop-opacity", 1);
         return `url(#icontype${1})`;
        })
    }

if(pictorialdata[0]&&pictorialdata[1]&&focus[0].value!==focus[1].value){
    svg.selectAll(".referenceL")
    .data(data)
    .join("line")
    .attr('class', 'referenceL')
    .attr('x1', d=>x(d[breakdown[0].field])+x.bandwidth()/2+typesizex/2)
    .attr('y1', (d,i) => height-typesizey*pictorialdatapercent[i]/100)
    .attr('x2', xwidthmax+5)
    .attr('y2',  (d,i) => height-typesizey*pictorialdatapercent[i]/100)
    .attr('stroke', Color.DASHLINE)
    .attr('stroke-width', 3)
    .attr('stroke-dasharray', '5,5');

    svg.selectAll(".referenceL1")
    .data(data)
    .join("line")
    .attr('class', 'referenceL1')
    .attr('x1',xwidthmax+5+2)
    .attr('y1', (d,i) => height-typesizey*pictorialdatapercent[i]/100)
    .attr('x2', xwidthmax+5+8)
    .attr('y2',  (d,i) => height-typesizey*pictorialdatapercent[i]/100)
    .attr('stroke', Color.AXIS)
    .attr('stroke-width', 2)

    svg.append("line")
    .attr('class', 'verticalL')
    .attr('x1', xwidthmax+5+8)
    .attr('y1', height-typesizey*pictorialdatapercent[0]/100 )
    .attr('x2', xwidthmax+5+8)
    .attr('y2', height-typesizey*pictorialdatapercent[1]/100)
    .attr('stroke', Color.AXIS)
    .attr('stroke-width', 2)

    if (this.size() !== Size.SMALL) {
    svg.append("text")
    .attr("class","differenceN")
    .attr("font-family", NUMFONT)
    .attr("font-size", annotationSize)
    .attr("text-anchor", 'start')
    .attr("dominant-baseline","middle")
    .attr("fill",Color.HIGHLIGHT)
    .attr("font-weight","bold")
    .attr("x",xwidthmax+5+15)
    .attr("y",height-typesizey*(pictorialdatapercent[0]/100+pictorialdatapercent[1]/100)/2)
    .text(formatNumber( Math.abs(pictorialdata[0]-pictorialdata[1])))
    }
}
    svg.append("g")
            .selectAll(".textnumber")
            .data(data)
            .enter()
            .append("text")
            .attr("class","textnumber")
            .attr("font-family", NUMFONT)
            .attr("font-size", tickSize)
            .attr("text-anchor", 'middle')
            .attr("x", function (d) { return x(d[breakdown[0].field]); })
            .attr("y",function(d,i){
                // return height-typesizey*pictorialdatapercent[i]/100;
               return height-typesizey;
            })
            //.attr("y", function (d) { return y(d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]) })
            .attr("dx", x.bandwidth() / 2)
            .attr("dy", "-1em")
            .text(d => formatNumber(d[measure[0].aggregate === "count" ? "COUNT" : measure[0].field]) );

        // add the x Axis
        let xAxis = svg.append("g")
            .attr("class", 'xAxis')
            .attr("transform", `translate(0,${height})`)
            .call(d3.axisBottom(x));

        //x Axis style
        xAxis.select('.domain').remove();

        //tick style
        xAxis.selectAll('.tick line').remove();

        xAxis.selectAll('.tick text')
            .attr('y', 9 * chartSize.height / 320)
            .attr('font-size', tickSize)
            .attr('font-family', NUMFONT);

        //使图表居中
        let cardWidth=chartSize.width;
        let cardHeight=chartSize.height;
        let a=svg.node().getBBox().width;    
        let b=svg.node().getBBox().height;      
        let c=svg.node().getBBox().x;      
        let e=svg.node().getBBox().y;      
       
        let transx=-c+cardWidth/2-a/2;
        let transy=-e+cardHeight/2-b/2;

         if(a>cardWidth) {      
            svg.attr("transform", `scale(${width/a})  translate(${cardWidth/(2*width/a)-(a/2+c)},${cardHeight/(2*width/a)-(b/2+e)}) `)
        }
         else{
            svg.attr("transform",`translate(${transx} ,${transy}) `)
         }

         return svg;

    }
  }

}


/** 
 * tickSize 坐标轴字号
 * annotationSize 标注字号
 * maxTicksNum xField显示的个数
**/
const getSizeBySize = (_this, factType, hasSeries = false) => {
    let tickSize, annotationSize, axisWidth,maxTicksNum, maxXAxisH;
    switch (_this.size()) {
        case Size.WIDE:
            tickSize = 15;
            annotationSize = 35;
            maxTicksNum = 10;
            axisWidth=2;
            break;
        case Size.MIDDLE:
            tickSize = 15;
            annotationSize = 25;
            maxTicksNum = 6;
            axisWidth=2;
            break;
        case Size.SMALL:
            tickSize = 10;
            annotationSize =20;
            maxTicksNum = 5;
            axisWidth=1.5;
            break;
        case Size.LARGE:
        default:
            tickSize = 20;
            annotationSize = 50;
            maxTicksNum = 10;
            axisWidth=3;
            break;
    }
    let margin;
    switch (_this.size()) {
        case Size.LARGE:
            margin = {
                top: 20, right: 40, bottom: hasSeries ? 80 : 20, left: 40
            };
            maxXAxisH = 20;
            if (factType === "distribution" || factType === "trend") {
                margin = { top: 40, right: 40, bottom: 80, left: 40 };
            }
            if (factType === "difference") {
                margin = { top: 20, right: 40, bottom: 20, left: 60 };
            }
            if (factType === "extreme") {
                margin = { top: 20, right: 40, bottom: 80, left: 40 };
            }
            if (factType === "outlier" || factType === "value") {
                margin = { top: 20, right: 50, bottom: 40, left: 40 };
            }
            

            if (factType === "proportion") {
                margin = { top: 40, right: 40, bottom: 40, left: 40 };
            }
            if (factType === "categorization") {
                margin = { top: 60, right: 40, bottom: 80, left: 45 };
            }
            break;
        case Size.WIDE:
            margin = {
                top: 10,
                right: hasSeries ? 120 : 20,
                bottom: 20,
                left: 20
            };
            maxXAxisH = 10;

            if (factType === "distribution" || factType === "trend") {
                margin = { top: hasSeries ? 5 : 20, right: 30, bottom: 40, left: 30 };
            }

            if (factType === "difference") {
                margin = { top: 10, right: 20, bottom: 20, left: 30 };
            }

            if (factType === "extreme") {
                margin = { top: 7, right: 30, bottom: 40, left: 30 };
            }
            if (factType === "outlier" || factType === "value") {
                margin = { top: 7, right: 30, bottom: 20, left: 30 };
            }

            if (factType === "proportion") {
                margin = { top: 20, right: 50, bottom: 20, left: 50 };
            }
            if (factType === "categorization") {
                margin = { top: 10, right: 30, bottom: 40, left: 30 };
            }
            break;
        case Size.MIDDLE:
            margin = { top: 10, right: 20, bottom: hasSeries ? 40 : 10, left: 20 };
            maxXAxisH = 10;
            if (factType === "distribution" || factType === "trend") {
                margin = { top: 10, right: 20, bottom: hasSeries ? 35 : 35, left: 20 };
            }
            if (factType === "difference") {
                margin = { top: 10, right: 20, bottom: 10, left: 30 };
            }

            if (factType === "extreme") {
                margin = { top: 5, right: 20, bottom: 35, left: 20 };
            }
            if (factType === "outlier" || factType === "value") {
                margin = { top: 5, right: 25, bottom: 15, left: 20 };
            }
            if (factType === "proportion") {
                margin = { top: 20, right: 20, bottom: 20, left: 20 };
            }
            if (factType === "categorization") {
                margin = { top: 10, right: 20, bottom: 35, left: 25 };
            }
            break;
        case Size.SMALL:
            margin = { top: 5, right: 5, bottom: hasSeries ? 30 : 8, left: 5 };
            maxXAxisH = 8;
            if (factType === "distribution" || factType === "trend") {
                margin = { top: 10, right: 10, bottom: 25, left: 10 };
            }
            if (factType === "extreme") {
                margin = { top: 2, right: 15, bottom: 25, left: 15 };
            }
            if (factType === "outlier" || factType === "value") {
                margin = { top: 2, right: 15, bottom: 15, left: 15 };
            }

            if (factType === "proportion") {
                margin = { top: 15, right: 5, bottom: 10, left: 5 };
            }
            if (factType === "categorization") {
                margin = { top: 10, right: 15, bottom: 25, left: 15 };
            }
            break;
        default:
            margin = { top: 20, right: 40, bottom: 20, left: 40 };
            maxXAxisH = 20;
            break;
    }
    return {
        chartSize: {
            width: _this.width(),
            height: _this.height()
        },
        tickSize: tickSize,
        annotationSize: annotationSize,
        axisWidth:axisWidth,
        margin: margin,
        maxTicksNum: maxTicksNum,
        maxXAxisH: maxXAxisH,
    }
};
const getMaxYValue = (factdata, measure) => {
    let maxYValue = d3.max(factdata, d => {
        return d[measure[0].aggregate === 'count' ? "COUNT" : measure[0].field];
    })
    if ((maxYValue / 1000000) >= 1) {
        maxYValue = Math.ceil(maxYValue / 1000000) * 1000000;
    } else if ((maxYValue / 1000) >= 1) {
        maxYValue = Math.ceil(maxYValue / 1000) * 1000;
    }
    return maxYValue
}
export default IsotypeBar;
