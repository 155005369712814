const textMultiline = function(container, text, textSize, maxWidth, x, y, textAnchor = "middle"){
    let words = text.split(" ").filter(d => d !== "");

    let virtualE = container.append("text")
        .attr("font-family", 'Arial-Regular')
        .attr("font-size", textSize)
        .text(words[0]);

    let textE = container.append("text")
        .attr("transform", "translate(" + x + "," + (y) + ")")
        .attr("font-family", 'Arial-Regular')
        .attr("font-size", textSize)
        .attr("text-anchor", textAnchor);

    maxWidth = Math.max(virtualE.node().getComputedTextLength(), maxWidth);
    const lineHeight = virtualE.node().getBBox().height * 0.9;

    let line = '';
    let rowCount = 0;
    const maxRow = 2;
    for (let n = 0; n < words.length; n++) {
        let testLine = line + ' ' + words[n];
        /*  Add line in testElement */
        if(rowCount === maxRow - 1){
            virtualE.text(testLine+ "…");
        }else{
            virtualE.text(testLine);
        }
        /* Messure textElement */
        let testWidth = virtualE.node().getComputedTextLength();
        if (testWidth > maxWidth) {
            if(rowCount === maxRow - 1){//最后一行还有文字没显示
                line += "…";
                break;
            }else{//new row
                textE.append("tspan")
                    .attr("x", 0)
                    .attr("dy", lineHeight)
                    .text(line);
                line = words[n];
                rowCount ++;
            }
        } else {
            line = testLine;
        }
    }
    
    textE.append("tspan")
        .attr("x", 0)
        .attr("dy", lineHeight)
        .text(line);
    virtualE.remove();

    return textE;
}

export default textMultiline;