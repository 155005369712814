import * as d3 from 'd3';
import FactType from './visualization/facttype';
import AggregationType from './visualization/aggregationtype';

class Fact {
    constructor() {
        // table, factspec
        this._table = [];
        this._schema = [];
        this._type = "";
        this._measure = [];
        this._subspace = [];
        this._breakdown = [];
        this._parameter = "";
        this._focus = [];
        this._data = [];
    }

    table(value) {
        this._data = value;
        this._table = value;
    }

    schema(value) {
        this._schema = value;
    }

    fact() {
        return {
            type: this._type,
            measure: this._measure,
            subspace: this._subspace,
            breakdown: this._breakdown,
            focus: this._focus,
            parameter: this._parameter
        }
    }

    data() {
        return this._data;
    }

    load(spec) {
        this._type = spec.type ? spec.type : FactType.Distribution;
        this._measure = spec.measure ? spec.measure : [];
        if (this._measure.length === 0) {
            this._measure = [{ 'aggregate': 'count' }];
        }
        this._subspace = spec.subspace ? spec.subspace : [];
        this._breakdown = spec.breakdown ? spec.breakdown : [];
        this._focus = spec.focus ? spec.focus : [];

        let schemaDict = {};
        for (const column of this._schema) {
            schemaDict[column.field] = {};
            schemaDict[column.field]['subtype'] = column.subtype;
            schemaDict[column.field]['pictype'] = column.pictype;
            schemaDict[column.field]['type'] = column.type;
            schemaDict[column.field]['values'] = column.values;
        }
        this._measure = this._measure.map(x => {
            if ('field' in x) {
                x['type'] = schemaDict[x.field]['type'];
                if (schemaDict[x.field]['subtype']) {
                    x['subtype'] = schemaDict[x.field]['subtype'];
                }
                if (schemaDict[x.field]['pictype']) {
                    x['pictype'] = schemaDict[x.field]['pictype'];
                }
            }
            return x;
        });
        this._subspace = this._subspace.map(x => {
            x['type'] = schemaDict[x.field]['type'];
            if (schemaDict[x.field]['subtype']) {
                x['subtype'] = schemaDict[x.field]['subtype'];
            }
            if (schemaDict[x.field]['pictype']) {
                x['pictype'] = schemaDict[x.field]['pictype'];
            }
            return x;
        });
        this._breakdown = this._breakdown.map(x => {
            x['type'] = schemaDict[x.field]['type'];
            if (schemaDict[x.field]['subtype']) {
                x['subtype'] = schemaDict[x.field]['subtype'];
            }
            if (schemaDict[x.field]['pictype']) {
                x['pictype'] = schemaDict[x.field]['pictype'];
            }
            if (schemaDict[x.field]['values']) {
                x['values'] = schemaDict[x.field]['values'];
            }
            return x;
        });
        this._focus = this._focus.map(x => {
            x['type'] = schemaDict[x.field]['type'];
            if (schemaDict[x.field]['subtype']) {
                x['subtype'] = schemaDict[x.field]['subtype'];
            }
            if (schemaDict[x.field]['pictype']) {
                x['pictype'] = schemaDict[x.field]['pictype'];
            }
            return x;
        });

        /** subspace **/
        if (this._subspace.length > 0) {
            this._data = this.filter(this.data(), this._subspace);
        }
        /** aggregate **/
        if (this._measure.length > 0) {
            let measure = this._measure;
            let breakdown = this._breakdown;
            this._data = this.aggregate(this.data(), measure, breakdown);
        }

        /** parameter **/
        try {
            this._parameter = this.parameter()
        } catch (error) {
            console.log('parameter is wrong')
        }
        
        return new Promise((resolve, reject) => {
            resolve(this);
        });
    }

    parameter() {
        let data = this.data();
        let fact = this.fact();
        let breakdown = fact.breakdown;
        let focus = fact.focus;
        let measure = fact.measure;

        switch (fact.type) {
            case FactType.Association:
                function pearson(items, k1, k2) {
                    var x = 0,
                        xx = 0,
                        y = 0,
                        yy = 0,
                        xy = 0;

                    items.forEach(item => {
                        xx += item[k1] * item[k1];
                        x += item[k1];

                        yy += item[k2] * item[k2];
                        y += item[k2]

                        xy += item[k1] * item[k2];
                    });

                    var Lxx = xx - ((x * x) / items.length),
                        Lyy = yy - ((y * y) / items.length),
                        Lxy = xy - ((x * y) / items.length);

                    if (Lxx === 0 || Lyy === 0)
                        return 0;

                    return Lxy / Math.sqrt(Lxx * Lyy);
                }

                let pearsons = pearson(data, 'measure0:Confirmed Cases', 'measure1:Deaths')
                return pearsons;

            case FactType.Categorization:
                let categories = data.map((d) => {
                    return d[breakdown[0].field]
                })
                return Array.from(new Set(categories));

            case FactType.Rank:
                let rankList = data.sort((a, b) => b['Recovered'] - a['Recovered'])
                let rankCaetgories = rankList.map((d) => {
                    return d[breakdown[0].field]
                })
                return rankCaetgories;

            case FactType.Proportion:
                let focusItem = data.find(d => d[focus[0].field] === focus[0].value),
                    restItems = data.filter(d => d[focus[0].field] !== focus[0].value);
                let yEncoding = measure[0].field;
                let seriesData = [focusItem[yEncoding], d3.sum(restItems, d => d[yEncoding])];
                let pieData = d3.pie().sort(null)(seriesData);
                let percent = pieData[0].value / d3.sum(pieData, d => d.value);
                let percentText = (percent * 100).toFixed(1) + "%";
                return percentText;

            case FactType.Value:
                return data[0][measure[0].field];

            case FactType.Trend:
                const getLeastSquares = (X, Y) => {
                    let ret = {}

                    let sumX = 0
                    let sumY = 0
                    let sumXY = 0
                    let sumXSq = 0
                    let N = X.length

                    for (let i = 0; i < N; ++i) {
                        sumX += X[i]
                        sumY += Y[i]
                        sumXY += X[i] * Y[i]
                        sumXSq += X[i] * X[i]
                    }

                    ret.m = ((sumXY - sumX * sumY / N)) / (sumXSq - sumX * sumX / N)
                    ret.b = sumY / N - ret.m * sumX / N

                    return ret;
                }

                let arr = Array.apply(null, { length: data.length }).map((item, index) => {
                    return index
                })

                let ret = getLeastSquares(arr, data.map(d => d[measure[0].field]))

                if (ret.m > 0) return 'increasing'
                if (ret.m < 0) return 'decreasing'
                return 'flat'

            case FactType.Difference:
                let filteredData = []
                for (const fs of focus) {
                    data.filter((x) => x[fs.field] === fs.value)[0] && filteredData.push(data.filter((x) => x[fs.field] === fs.value)[0])
                }
                let differenceValue = Math.abs(Number(filteredData[0][measure[0].field]) - Number(filteredData[1][measure[0].field]));
                return differenceValue;

            case FactType.Extreme:
                let extremeItem = data.find(d => d[focus[0].field] === focus[0].value)
                let maxValue = d3.max(data, d => d[measure[0].field])
                let minValue = d3.min(data, d => d[measure[0].field])
                extremeItem.extremeFocus = ''
                if(extremeItem[measure[0].field] === minValue) extremeItem.extremeFocus = "minimum" 
                if(extremeItem[measure[0].field] === maxValue) extremeItem.extremeFocus = "maximum" 
                return Object.values(extremeItem);

            default:
                return "";
        }
    }

    filter(data, subspace) {
        let filteredData = data;
        /** filter rows **/
        for (const sub of subspace) {
            filteredData = filteredData.filter((x) => x[sub.field] === sub.value)
        }
        return filteredData
    }

    aggregate(data, measures, breakdowns) {
        let aggdata = [];
        if (measures.length < 1) {
            aggdata = data;
        } else if (measures.length === 1) {
            let measure = measures[0];
            aggdata = this.agg(data, measure, breakdowns)

        } else if (measures.length === 2) {
            // over one measure
            let aggdata1 = this.agg(data, measures[0], breakdowns);
            let aggdata2 = this.agg(data, measures[1], breakdowns);
            let breakdownfields = breakdowns.map(x => x.field)
            for (let index = 0; index < aggdata1.length; index++) {
                const element1 = aggdata1[index];
                for (const key in element1) {
                    if (!breakdownfields.includes(key)) {
                        element1["measure0:" + key] = element1[key];
                        delete element1[key];
                    }
                }
                const element2 = aggdata2[index];
                for (const key in element2) {
                    if (!breakdownfields.includes(key)) {
                        element2["measure1:" + key] = element2[key];
                        delete element2[key];
                    }
                }
                aggdata.push(Object.assign(element1, element2));
            }
        }
        return aggdata;
    }

    agg(data, measure, breakdowns) {
        let aggdata = {};
        /**
         * filter columns
         */
        let columns = [];
        columns = columns.concat(breakdowns.map(x => x.field));
        if (measure.aggregate !== "count") {
            columns.push(measure.field);
        }
        aggdata = data.map(x => {
            let y = {};
            for (const column of columns) {
                y[column] = x[column];
            }
            return y;
        });

        switch (measure.aggregate) {
            case AggregationType.SUM:
                aggdata = this.sum(aggdata, measure, breakdowns);
                break;
            case AggregationType.AVG:
                aggdata = this.avg(aggdata, measure, breakdowns);
                break;
            case AggregationType.MAX:
                aggdata = this.max(aggdata, measure, breakdowns);
                break;
            case AggregationType.MIN:
                aggdata = this.min(aggdata, measure, breakdowns);
                break;
            case AggregationType.COUNT:
                aggdata = this.count(aggdata, measure, breakdowns)
                break;

            default:
                aggdata = this.max(aggdata, measure, breakdowns);
                break;
        }
        return aggdata;
    }

    sum(data, measure, breakdowns) {
        let factdata = [];
        if (breakdowns.length > 1) {
            /** has series **/
            let seriesData = d3.nest().key(d => d[breakdowns[1].field]).entries(data);
            for (const series of seriesData) {
                let calculateData = d3.nest().key(d => d[breakdowns[0].field]).entries(series.values);
                let sumData = new Array(calculateData.length).fill(0);
                let categoryData = calculateData.map(function (d, i) {
                    d.values.forEach(d => {
                        sumData[i] += d[measure.field]
                    })
                    let sumRows = Object.assign({}, d.values[0])
                    sumRows[measure.field] = sumData[i]
                    return sumRows
                });
                factdata = factdata.concat(categoryData);
            }

        } else if (breakdowns.length === 1) {
            /** no series **/
            let calculateData = d3.nest().key(d => d[breakdowns[0].field]).entries(data);
            let sumData = new Array(calculateData.length).fill(0);
            factdata = calculateData.map(function (d, i) {
                d.values.forEach(d => {
                    sumData[i] += d[measure.field]
                })
                let sumRows = Object.assign({}, d.values[0])
                sumRows[measure.field] = sumData[i]
                return sumRows
            });
        } else {
            /** no breakdown **/
            let agg = {};
            agg[measure.field] = 0;
            for (let index = 0; index < data.length; index++) {
                agg[measure.field] += data[index][measure.field];
            }
            factdata.push(agg);
        }
        return factdata;
    }

    avg(data, measure, breakdowns) {
        let factdata = [];
        if (breakdowns.length > 1) {
            /** has series **/
            let seriesData = d3.nest().key(d => d[breakdowns[1].field]).entries(data);
            for (const series of seriesData) {
                let calculateData = d3.nest().key(d => d[breakdowns[0].field]).entries(series.values);
                let sumData = new Array(calculateData.length).fill(0);
                let categoryData = calculateData.map(function (d, i) {
                    d.values.forEach(d => {
                        sumData[i] += d[measure.field]
                    })
                    let sumRows = Object.assign({}, d.values[0])
                    sumRows[measure.field] = sumData[i] / d.values.length;
                    return sumRows;
                });
                factdata = factdata.concat(categoryData);
            }

        } else if (breakdowns.length === 1) {
            /** no series **/
            let calculateData = d3.nest().key(d => d[breakdowns[0].field]).entries(data);
            let sumData = new Array(calculateData.length).fill(0);
            factdata = calculateData.map(function (d, i) {
                d.values.forEach(d => {
                    sumData[i] += d[measure.field]
                })
                let sumRows = Object.assign({}, d.values[0])
                sumRows[measure.field] = sumData[i] / d.values.length;
                return sumRows;
            });
        } else {
            /** no breakdown **/
            let agg = {};
            agg[measure.field] = 0;
            for (let index = 0; index < data.length; index++) {
                agg[measure.field] += data[index][measure.field];
            }
            agg[measure.field] /= data.length;
            factdata.push(agg);
        }
        return factdata;
    }

    max(data, measure, breakdowns) {

        let factdata = [];
        if (breakdowns.length > 1) {
            /** has series **/
            let seriesData = d3.nest().key(d => d[breakdowns[1].field]).entries(data);
            for (const series of seriesData) {
                let calculateData = d3.nest().key(d => d[breakdowns[0].field]).entries(series.values);
                let categoryData = calculateData.map(function (d, i) {
                    let index = d3.scan(d.values, function (a, b) {
                        if (a[measure.field] && b[measure.field])
                            return b[measure.field] - a[measure.field];
                    });
                    if (index >= 0) {
                        return d.values[index]
                    } else {
                        return d.values[0];
                    }
                });
                factdata = factdata.concat(categoryData);
            }

        } else if (breakdowns.length === 1) {
            /** no series **/
            let calculateData = d3.nest().key(d => d[breakdowns[0].field]).entries(data);
            factdata = calculateData.map(function (d, i) {
                let index = d3.scan(d.values, function (a, b) {
                    if (a[measure.field] && b[measure.field])
                        return b[measure.field] - a[measure.field];
                });
                if (index >= 0) {
                    return d.values[index]
                } else {
                    return d.values[0];
                }
            });
        } else {
            /** no breakdown **/
            let agg = {};
            agg[measure.field] = data[0][measure.field];
            for (let index = 0; index < data.length; index++) {
                if (agg[measure.field] < data[index][measure.field]) {
                    agg[measure.field] = data[index][measure.field];
                }
            }
            factdata.push(agg);
        }
        return factdata;
    }

    min(data, measure, breakdowns) {
        let factdata = [];
        if (breakdowns.length > 1) {
            /** has series **/
            let seriesData = d3.nest().key(d => d[breakdowns[1].field]).entries(data);
            for (const series of seriesData) {
                let calculateData = d3.nest().key(d => d[breakdowns[0].field]).entries(series.values);
                let categoryData = calculateData.map(function (d) {
                    let index = d3.scan(d.values, function (a, b) {
                        if (a[measure.field] && b[measure.field])
                            return a[measure.field] - b[measure.field];
                    });
                    if (index >= 0) {
                        return d.values[index]
                    } else {
                        return d.values[0]
                    }
                });
                factdata = factdata.concat(categoryData);
            }

        } else if (breakdowns.length === 1) {
            /** no series **/
            let calculateData = d3.nest().key(d => d[breakdowns[0].field]).entries(data);
            factdata = calculateData.map(function (d) {
                let index = d3.scan(d.values, function (a, b) {
                    if (a[measure.field] && b[measure.field])
                        return a[measure.field] - b[measure.field];
                });
                if (index >= 0) {
                    return d.values[index]
                } else {
                    return d.values[0]
                }
            });
        } else {
            /** no breakdown **/
            let agg = {};
            agg[measure.field] = data[0][measure.field];
            for (let index = 0; index < data.length; index++) {
                if (agg[measure.field] > data[index][measure.field]) {
                    agg[measure.field] = data[index][measure.field];
                }
            }
            factdata.push(agg);
        }
        return factdata;
    }

    count(data, measure, breakdowns) {
        let factdata = [];
        if (breakdowns.length > 1) {
            /** has series **/
            let seriesData = d3.nest().key(d => d[breakdowns[1].field]).entries(data);
            for (const series of seriesData) {
                let calculateData = d3.nest().key(d => d[breakdowns[0].field]).entries(series.values);
                let countData = new Array(calculateData.length).fill(0);
                let categoryData = calculateData.map(function (d, i) {
                    d.values.forEach(() => {
                        countData[i] += 1;
                    })
                    let countRows = d.values[0];
                    countRows['COUNT'] = countData[i];
                    return countRows;
                });
                factdata = factdata.concat(categoryData);
            }

        } else if (breakdowns.length === 1) {
            /** no series **/
            let calculateData = d3.nest().key(d => d[breakdowns[0].field]).entries(data);
            let countData = new Array(calculateData.length).fill(0);
            factdata = calculateData.map(function (d, i) {
                d.values.forEach(() => {
                    countData[i] += 1
                })
                let countRows = d.values[0];
                countRows['COUNT'] = countData[i];
                return countRows;
            });
        } else {
            /** no breakdown **/
            let agg = { 'COUNT': data.length };
            factdata.push(agg);
        }
        return factdata;
    }
}

export default Fact;