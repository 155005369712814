import DataTable from './data';
import Fact from './fact';
import Visualization from './visualization';
import Display from './display';
import sentencer from './sentencer';

class AutoVis {
    constructor() {
        this._container = document.createElement("div");
        this._paragraph = document.createElement("p");
        this._spec = {};
        this._shouldShowCaption = false;
    }

    container(value) {
        if (!value) {
            return this._container;
        }
        this._container = value;
    }

    paragraph(value) {
        if (!value) {
            return this._paragraph;
        }
        this._paragraph = value;
    }

    load(spec) {
        this._spec = spec;
    }

    shouldShowCaption(value) {
        if (!value) {
            return this._shouldShowCaption;
        }
        this._shouldShowCaption = value;
    }

    generate() {
        // STEP 0: parse specification
        let spec = this._spec;
        let dataspec = spec.data ? spec.data : {};
        let factspec = spec.fact ? spec.fact : {};
        let chartspec = spec.chart ? spec.chart : {};

        let data = new DataTable();
        // STEP 1: data
        data.load(dataspec)
            .then((data) => {
                // STEP 2: fact
                let fact = new Fact();
                fact.table(data.table());
                fact.schema(data.schema());
                return fact.load(factspec);
            })
            .then((fact) => {
                // STEP 3: generate caption and setup visualization
                let vis = new Visualization();
                vis.data(fact.data());
                vis.fact(fact.fact());
                try {
                    let caption = "";
                    if (chartspec.caption && chartspec.caption !== "") {
                        caption = chartspec.caption;
                    } else {
                        caption = sentencer(fact.fact());
                    }
                    vis.caption(caption)
                    // console.log("Caption: "+caption);
                } catch (error) {
                    console.log(error);
                }
                return vis.load(chartspec);
            })
            .then((vis) => {
                // STEP 4: display
                let display = new Display();
                display.container(this.container());
                display.paragraph(this.paragraph());
                display.vis(vis);
                display.render();
                if (this.shouldShowCaption()) {
                    display.showCaption();
                }
            })
            .catch((reason) => {
                console.log(reason);
            })
    }
}

export default AutoVis;