import * as d3 from 'd3';
import Chart from '../../chart';
import Color from '../../visualization/color';
import Style from '../../visualization/style';
import Size from '../../visualization/size';
import generateToolTip from '../../visualization/tooltip';
import formatNumber from '../../visualization/format';

import metaphor7 from '../../metaphor/metaphor7.png';//trend, down
import metaphor8 from '../../metaphor/metaphor8.png';//trend, up
import metaphor2 from '../../metaphor/metaphor2.png';//trend, series
import metaphor6 from '../../metaphor/metaphor6.png';//trend, many
import metaphor11 from '../../metaphor/metaphor11.png';//difference
import metaphor17 from '../../metaphor/metaphor17.png';//outlier
import metaphor18 from '../../metaphor/metaphor18.png';//extreme
import metaphor19 from '../../metaphor/metaphor19.png';//rank
import uuidv4 from 'uuid/v4';

const NUMFONT = "Arial-Regular";
const ENGFONT = "Arial-Regular";

class LineChart extends Chart {
    constructor() {
        super();
        this._x = '';
        this._xAxis = '';
        this._y = '';
        this._width = '';
        this._height = '';
        this._strokeWidth = '';
    }
    displayExtreme() {
        /* -------------------------------- init data ------------------------------- */
        let factData = this.factdata();
        let measure = this.measure();
        let breakdown = this.breakdown();
        let focus = this.focus();
        /* ----------------------- graph set up (size, margin) ---------------------- */
        const chartMargin = {
            "small":{
                "top": 5,
                "right": 12,
                "bottom": 30,
                "left": 12
            },
            "middle":{
                "top":  10,
                "right": 15,
                "bottom": 50,
                "left": 15
            },
            "wide":{
                "top": 12,
                "right": 20,// hasSeries?  moreThan ? 160 : 100 : 20,
                "bottom": 50,
                "left": 20
                // fact === "difference" ? 30 : 20
            },
            "large":{
                "top": 30,
                "right": 30,
                "bottom": 80 + 50,
                "left": 30
            }
        };
        let margin = chartMargin[this.size()];
        let chartSize = { width: this.width(), height: this.height()};
        let {tickSize, annotationSize, dotR, strokeWidth, padding, tickWidth } = getSizeBySize(this.size(), chartSize, "extreme");
        /* ----------------------------- data prosessing ---------------------------- */
        // data example
        // [{Sales: 1054260, Year: "2010", Brand: "Ford"}
        // {Sales: 1296077, Year: "2011", Brand: "Ford"}]
        let hasSeries = breakdown.length > 1 ? true : false;
        if(hasSeries) return
        // for(let f of focus) {
        //     if(f.field !== breakdown[0].field) return
        // }
        // 只取focus首个
        if(!focus[0].field || focus[0].field !== breakdown[0].field) return 
        let seriesName = hasSeries ? d3.map(factData, function(d){return d[breakdown[1].field];}).keys() : [];
        let seriesData = [];
        seriesData.push({
            key: "All",
            values: factData.sort(sortByDateAscending(breakdown)),
        });

        let measureName = measure[0].aggregate === 'count' ? "COUNT" : measure[0].field

        // get tootltip size
        let focusValueArray = factData.filter(d => {
            return d[focus[0].field] === focus[0].value
        })
        let focusValue = focusValueArray[0][measureName]
        let _tooltip = d3.select(this.container())
                        .append("svg")
                        .attr("class", "testNode")
                        .attr("opacity", 0);
        _tooltip.append("text")
            .text(formatNumber(focusValue)).attr("font-size", annotationSize)
            .attr("font-family", NUMFONT);
                            
        let tooltipHeight =  d3.select(".testNode").select("text").node().getBBox().height;
        let tooltipWidth =  d3.select(".testNode").select("text").node().getBBox().width;
        if( margin.top < tooltipHeight/0.8 + 30 * chartSize.height / 640 ) {
            margin.top = tooltipHeight/0.8 + 30 * chartSize.height / 640
        }
        if(factData[0][focus[0].field] === focus[0].value  && ( margin.left + padding ) <  Math.min(tooltipWidth/0.7/2, (tooltipWidth + 12)/2)) {
            margin.left = Math.ceil( Math.min(tooltipWidth/0.7/2, (tooltipWidth + 12)/2) - padding) * 1.1
        }
        if(factData[factData.length-1][focus[0].field] === focus[0].value && (margin.right + padding ) <  Math.min(tooltipWidth/0.7/2, (tooltipWidth + 12)/2)) {
            margin.right = Math.ceil( Math.min(tooltipWidth/0.7/2, (tooltipWidth + 12)/2) - padding) * 1.1
        }
        
        _tooltip.remove();

        let width = chartSize.width - margin.left - margin.right,
        height = chartSize.height - margin.top - margin.bottom;

        /* ----------------------------------- vis ---------------------------------- */
        let svg = initSvg(this.container(), width, height, margin);
        if(this.style() === Style.COMICS) width = 0.8* width;

        /* ------------------------------ axis setting ------------------------------ */
        let { x, y } = setupXAsix(seriesData, factData, breakdown, measure, padding, width, height, true);

        // line Function
        var lineGen = lineGeneration(breakdown, measure, x, y);

        let lingGraph = svg.append("g").attr("class", "lineGraph");
        lingGraph.selectAll(".series")
                .data(seriesData)
                .enter()
                .append("g")
                .attr("class", d=>"series "+ d.key.replace(/\s/g, ""))
                .each(function(d, i) {
                    
                    // append the line for the line chart
                    drawLines(d3.select(this), d, hasSeries, seriesName, lineGen, strokeWidth);

                    // append the dot for the line chart
                    let isDotSolid = true;
                    drawDots(d3.select(this), d, breakdown, measure, focus, hasSeries, seriesName, x, y, dotR, isDotSolid, strokeWidth, Color);
                })
        
        
        // add tooltip of the focus one
        // let focusValueArray = factData.filter(d => {
        //     return d[focus[0].field] === focus[0].value
        // });
        let focusData = focusValueArray[0];

        let toolTipX = x(parseTime(focus[0].value)),//箭头中心所在x的位置
            toolTipY = y(focusData[measureName]) - dotR * 2.5,// - 25 * chartSize.height / 640 , //箭头中心所在y的位置;20是标注离value的距离
            toolTipValue = formatNumber(focusData[measureName]);
        generateToolTip(toolTipX, toolTipY, toolTipValue, svg, chartSize, annotationSize);
        svg.selectAll(".tooltip").attr("font-weight", 500)
        // add the x Axis
        // variables for arranging X ticks
        let axisX = drawXAsix(seriesData, factData, breakdown, x, focus[0].value);

        // custom x Axis
        svg.append("g")
            .lower()
            .attr("class", "xAxis")
            .attr("transform", "translate(0," + y.range()[0] + ")")
            .call(axisX)
            .call(g => {
                g.attr('font-size', tickSize)
                g.attr("font-family", ENGFONT)
                paddingXAsix(g, padding);

                g.selectAll(".tick")
                    .selectAll("line")
                    .attr("stroke", Color.AXIS)
                    .attr("stroke-width", tickWidth)
                    .attr("y2", 6 * chartSize.height / 320);
                g.selectAll(".domain").attr("stroke-width", tickWidth);
                g.selectAll("text")
                    .attr("y", 12 * chartSize.height / 320)
                    .attr("font-size", annotationSize)
                    .attr("fill", Color.HIGHLIGHT)
                    .attr("font-weight", 600)
                

                g.selectAll(".tick")
                    .filter(d => parseTime(focus[0].value).toString() === d.toString())
                    .attr("id", "tickFocus")
                    .property("_index", (d, i) =>i);
                

                g.selectAll(".tick")
                    .filter(d => parseTime(focus[0].value).toString() !== d.toString())
                    .remove();
                
                g.selectAll(".tick").selectAll("text")
                    .attr("font-size", annotationSize)
                    .attr("fill", Color.HIGHLIGHT)
                    .attr("font-weight", 600);
                let minX = g.selectAll(".domain").node().getBoundingClientRect().x,
                    maxX = g.selectAll(".domain").node().getBoundingClientRect().x + g.selectAll(".domain").node().getBoundingClientRect().width,
                    nowX = g.selectAll(".tick").selectAll("text").node().getBoundingClientRect().x,
                    nowWidth = g.selectAll(".tick").selectAll("text").node().getBoundingClientRect().width;
                if(nowX < minX) {
                    g.selectAll(".tick").selectAll("text")
                        .attr("dx", minX - nowX)
                } else if (nowX + nowWidth >  maxX) {
                    g.selectAll(".tick").selectAll("text")
                        .attr("dx", maxX - nowX - nowWidth)
                }
                return g
            });

        // ref line
        svg.append("g")
            .lower()
            .attr("class", "refline")
            .append("line")
            .attr("x1", toolTipX)
            .attr("x2", toolTipX)
            .attr("y1", y(focusData[measureName]))
            .attr("y2", y(0))
            .attr("stroke", Color.DASHLINE)
            .attr('stroke-dasharray', '5,5')
            .attr("stroke-width", strokeWidth);
        
        if(this.style() === Style.COMICS){
            let metaphorWidth = width*0.26,
                metaphorHeight = 1.34*metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor18);
        
            if(this.size() === Size.WIDE){
                metaphorWidth = width*0.21;
                metaphorHeight = 1.34*metaphorWidth;
            }else if (this.size() === Size.MIDDLE){
                metaphorWidth = width*0.24;
                metaphorHeight = 1.34*metaphorWidth;
            }
            
            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", width + metaphorWidth*0.06)
                .attr("y", height - metaphorHeight*0.96);
        }
        return svg
    }
    displayOutlier() {
        /* -------------------------------- init data ------------------------------- */
        let factData = this.factdata();
        let measure = this.measure();
        let breakdown = this.breakdown();
        let focus = this.focus();
        /* ----------------------- graph set up (size, margin) ---------------------- */
        const chartMargin = {
            "small":{
                "top": 5,
                "right": 12,
                "bottom": 30,
                "left": 12
            },
            "middle":{
                "top":  10,
                "right": 15,
                "bottom": 50,
                "left": 15
            },
            "wide":{
                "top": 12,
                "right": 20,// hasSeries?  moreThan ? 160 : 100 : 20,
                "bottom": 50,
                "left": 20
                // fact === "difference" ? 30 : 20
            },
            "large":{
                "top": 30,
                "right": 30,
                "bottom": 80 + 50,
                "left": 80
            }
        };
        let margin = chartMargin[this.size()];
        let chartSize = { width: this.width(), height: this.height()};
        let { tickSize, annotationSize, dotR, strokeWidth, padding, tickWidth } = getSizeBySize(this.size(), chartSize, "outlier");
        /* ----------------------------- data prosessing ---------------------------- */
        // data example
        // [{Sales: 1054260, Year: "2010", Brand: "Ford"}
        // {Sales: 1296077, Year: "2011", Brand: "Ford"}]
        let hasSeries = breakdown.length > 1 ? true : false;
        if(hasSeries) return
        if(focus.length === 0) return
        // for(let f of focus) {
        //     if(f.field !== breakdown[0].field) return
        // }
        // 只取focus首个
        if(!focus[0].field || focus[0].field !== breakdown[0].field) return 

        let seriesName = hasSeries ? d3.map(factData, function(d){return d[breakdown[1].field];}).keys() : [];
        let seriesData = [];
        seriesData.push({
            key: "All",
            values: factData.sort(sortByDateAscending(breakdown)),
        });

        let measureName = measure[0].aggregate === 'count' ? "COUNT" : measure[0].field


        // get tootltip size
        let focusValueArray = factData.filter(d => {
            return d[focus[0].field] === focus[0].value
        })
        let focusValue = focusValueArray[0][measureName]
        let _tooltip = d3.select(this.container())
                        .append("svg")
                        .attr("class", "testNode")
                        .attr("opacity", 0);
                        _tooltip.append("text")
                        .text(formatNumber(focusValue)).attr("font-size", annotationSize)
                        .attr("font-family", NUMFONT);
                        
        let tooltipHeight =  d3.select(".testNode").select("text").node().getBBox().height;
        let tooltipWidth =  d3.select(".testNode").select("text").node().getBBox().width;
        if( margin.top < tooltipHeight/0.8 + 30 * chartSize.height / 640 ) {
            margin.top = tooltipHeight/0.8 + 30 * chartSize.height / 640
        }
        if(factData[0][focus[0].field] === focus[0].value  && ( margin.left + padding ) <  Math.min(tooltipWidth/0.7/2, (tooltipWidth + 12)/2)) {
            margin.left = Math.ceil( Math.min(tooltipWidth/0.7/2, (tooltipWidth + 12)/2) - padding) * 1.1//+= Math.ceil(tooltipWidth/0.7/2 - margin.left - padding) * 1.2
        }
        if(factData[factData.length-1][focus[0].field] === focus[0].value && (margin.right + padding ) <  Math.min(tooltipWidth/0.7/2, (tooltipWidth + 12)/2)) {
            margin.right = Math.ceil( Math.min(tooltipWidth/0.7/2, (tooltipWidth + 12)/2) - padding) * 1.1// += (tooltipWidth/0.7/2 - margin.right - padding) * 1.2
        }
        
        _tooltip.remove();

        let width = chartSize.width - margin.left - margin.right,
        height = chartSize.height - margin.top - margin.bottom;

        /* ----------------------------------- vis ---------------------------------- */
        let svg = initSvg(this.container(), width, height, margin);

        if(this.style() === Style.COMICS) width = 0.8* width;
        /* ------------------------------ axis setting ------------------------------ */
        let { x, y } = setupXAsix(seriesData, factData, breakdown, measure, padding, width, height, true );
        // line Function
        var lineGen = lineGeneration(breakdown, measure, x, y);
        
        let lingGraph = svg.append("g").attr("class", "lineGraph");
        lingGraph.selectAll(".series")
                .data(seriesData)
                .enter()
                .append("g")
                .attr("class", d=>"series "+ d.key.replace(/\s/g, ""))
                .each(function(d, i) {
                            
                    // append the line for the line chart
                    drawLines(d3.select(this), d, hasSeries, seriesName, lineGen, strokeWidth);

                    // append the dot for the line chart
                    let isDotSolid = true;
                    drawDots(d3.select(this), d, breakdown, measure, focus, hasSeries, seriesName, x, y, dotR, isDotSolid, strokeWidth, Color);
                })

        
        // add tooltip of the focus one
        let focusData = focusValueArray[0];

        let toolTipX = x(parseTime(focus[0].value)),//箭头中心所在x的位置
            toolTipY = y(focusData[measureName]) - dotR * 2.5, //30 * chartSize.height / 640 , //箭头中心所在y的位置;20是标注离value的距离
            toolTipValue = formatNumber(focusData[measureName]);
        generateToolTip(toolTipX, toolTipY, toolTipValue, svg, chartSize, annotationSize);
        svg.selectAll(".tooltip").attr("font-weight", 500)     

        // add the x Axis
        let axisX = drawXAsix(seriesData, factData, breakdown, x, focus[0].value);

        // custom x Axis
        svg.append("g")
            .attr("class", "xAxis")
            .attr("transform", "translate(0," + height + ")")
            .call(axisX)
            .call(g => {
                g.attr('font-size', tickSize);
                g.attr("font-family", ENGFONT);
                g.selectAll("text")
                    .attr("y", 9 * chartSize.height / 320)
                g.selectAll(".domain")
                    .attr("stroke-width", tickWidth)
                    .attr('stroke', Color.AXIS)
                    .attr('d', "M0.5,0H" + (width + 0.5));
                g.selectAll(".tick").selectAll("line").attr("stroke-width", tickWidth)
                // if(this.size() !== "small")
                //     g.append("path")
                //         .attr("class", "xArrow")
                //         .attr("d", drawArrow(this.size(), width))
                //         .attr("fill", Color.AXIS)
                // let tickY = g.selectAll(".tick").select("text").attr("y")
                
                // g.append("text")
                //     .attr("class", "xLabel")
                //     .attr("text-anchor", "center")
                //     .attr("x", width)
                //     .attr("y", tickY)
                //     .attr("dy", "1em")
                //     .text(breakdown[0].field)
                //     .attr("text-anchor", "end")
                //     .attr("fill", Color.AXIS);
                // if(g.select(".xLabel").node().getBBox().width / 2 > margin.right) {
                //     g.select(".xLabel")
                //         .attr("x", width )
                //         .attr("text-anchor", "end")

                // }

                g.selectAll(".tick")
                    .filter(d => parseTime(focus[0].value).toString() !== d.toString())
                    .remove();

                // let tickSpace = g.select(".tick").node().getBoundingClientRect().x +g.select(".tick").node().getBoundingClientRect().width;

                g.selectAll(".tick")
                    .selectAll("line")
                    .attr("stroke", Color.AXIS)
                    .attr("y2", 6 * chartSize.height / 320);
                g.selectAll(".tick")
                    .selectAll("text")
                    .attr("dy", "1em")
                    .attr("font-size", annotationSize)
                    .attr("fill", Color.HIGHLIGHT)
                    .attr("font-weight", 600)
                let minX = g.selectAll(".domain").node().getBoundingClientRect().x,
                    maxX = g.selectAll(".domain").node().getBoundingClientRect().x + g.selectAll(".domain").node().getBoundingClientRect().width,
                    nowX = g.selectAll(".tick").selectAll("text").node().getBoundingClientRect().x,
                    nowWidth = g.selectAll(".tick").selectAll("text").node().getBoundingClientRect().width;
                if(nowX < minX) {
                    g.selectAll(".tick").selectAll("text")
                        .attr("dx", minX - nowX)
                } else if (nowX + nowWidth >  maxX) {
                    g.selectAll(".tick").selectAll("text")
                        .attr("dx", maxX - nowX - nowWidth)
                }
                return g
            });

        let axisY = d3.axisLeft(y).ticks(6)
        svg.append("g").attr("class", "yAxis")
            .call(axisY)
            .call(g => {
                g.attr('font-size', tickSize);
                g.attr("font-family", ENGFONT);
                g.selectAll(".tick").selectAll("line").attr("stroke-width", tickWidth);
                g.selectAll(".domain")
                    .attr("stroke-width", tickWidth)
                    .attr('stroke', Color.AXIS);
                let domainDY = g.selectAll(".domain").attr("d");
                let startY = domainDY.split(",")[1].split("H")[0];
                let endY = domainDY.split("V")[1].split("H")[0];
                g.selectAll(".domain")
                    .attr("stroke-width", tickWidth)
                    .attr('stroke', Color.AXIS)
                    .attr('d', "M0, "+startY+"V"+endY);
                g.select('.tick line').remove();
                return g
            });
        
        // ref line
        svg.append("g")
            .lower()
            .attr("class", "refline")
            .append("line")
            .attr("x1", toolTipX)
            .attr("x2", toolTipX)
            .attr("y1", y(focusData[measureName]))
            .attr("y2", y(0))
            .attr("stroke", Color.DASHLINE)
            .attr('stroke-dasharray', '5,5')
            .attr("stroke-width", strokeWidth);

        if(this.style() === Style.COMICS){
            let metaphorWidth = width*0.26,
                metaphorHeight = 1.43*metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor17);
        
            if(this.size() === Size.WIDE){
                metaphorWidth = width*0.21;
                metaphorHeight = 1.43*metaphorWidth;
            }else if (this.size() === Size.MIDDLE){
                metaphorWidth = width*0.24;
                metaphorHeight = 1.43*metaphorWidth;
            }
            
            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", width + metaphorWidth*0.06)
                .attr("y", height - metaphorHeight*0.96);
        }

        this._x = x;
        this._y = y;
        this._strokeWidth = strokeWidth;
        this._height = height;
        this._width = width;
        return svg
    }
    displayTrend() {
        /* -------------------------------- init data ------------------------------- */
        let factData = this.factdata();
        let measure = this.measure();
        let breakdown = this.breakdown();

        let hasSeries = breakdown.length > 1 ? true : false;
        let measureName = measure[0] && measure[0].aggregate === 'count' ? "COUNT" : measure[0].field

        /* ----------------------------- data prosessing ---------------------------- */
        // data example
        // [{Sales: 1054260, Year: "2010", Brand: "Ford"}
        // {Sales: 1296077, Year: "2011", Brand: "Ford"}]
        // let hasSeries = breakdown.length > 1 ? true : false;
        let seriesData = [];
        if(hasSeries) {
            seriesData = d3.nest()
                            .key(function(d) { return d[breakdown[1].field]; })
                            .sortValues(sortByDateAscending(breakdown))
                            .entries(factData);

            // seriesData =seriesData.sort(() => .5 - Math.random()).slice(0,10)
            seriesData = seriesData.slice(0, 10) 
        } else {
            seriesData.push({
                key: "All",
                values: factData.sort(sortByDateAscending(breakdown)),
            });
        }

        /* ----------------------- graph set up (size, margin) ---------------------- */
        let seriesName = hasSeries? d3.map(seriesData, function(d){return d.key;}).keys():[];
        let moreThan = false;
        let chartSize = { width: this.width(), height: this.height()};
        let legendRowNum = Math.ceil(seriesName.length / 3);
    
        const chartMargin = {
            "small":{
                "top": 5,
                "right": 20,
                "bottom": hasSeries? (63 / 4) * legendRowNum + 25 : 30,
                "left": 20
            },
            "middle":{
                "top":  10,
                "right": 25,
                "bottom": hasSeries ? (74 / 4) * legendRowNum + 30: 40,
                "left": 25
            },
            "wide":{
                "top": 12,
                "right": hasSeries ? 180: 30,// hasSeries?  moreThan ? 160 : 100 : 20,
                "bottom": 40,
                "left": hasSeries ? 20 : 30
                // fact === "difference" ? 30 : 20
            },
            "large":{
                "top": 30,
                "right": 30,
                "bottom": hasSeries ? 150 + 50 : 80 + 50,
                "left": 30
            }
        };
        let margin = chartMargin[this.size()]
        let { tickSize, dotR, strokeWidth, padding, tickWidth } = getSizeBySize(this.size(), chartSize,  'trend', hasSeries, moreThan),
            width = chartSize.width - margin.left - margin.right,
            height = chartSize.height - margin.top - margin.bottom;

        /* ----------------------------------- vis ---------------------------------- */
        let svg = initSvg(this.container(), width, height, margin);
        if(this.style() === Style.COMICS){
            if(hasSeries || seriesData[0].values.length > 7) width *= 0.8;
            else if(this.size() === Size.WIDE){
                height *= 0.85;
            }
        }
        
        /* ------------------------------ axis setting ------------------------------ */
        let { x, y } = setupXAsix(seriesData, factData, breakdown, measure, padding, width, height, !hasSeries );
        

        // line Function
        var lineGen = lineGeneration(breakdown, measure, x, y);

        // add the x Axis
        let axisX = drawXAsix(seriesData, factData, breakdown, x);

        let xAxisOffset = height;
        if(y.domain()[0] !== 0) {
            xAxisOffset = y(0)
        }
        // custom x Axis
        svg.append("g")
            .attr("class", "xAxis")
            .attr("transform", "translate(0," + xAxisOffset + ")")
            .call(axisX)
            .call(g => {
                g.attr('font-size', this.size() === 'wide' && seriesName.length > 8 ? tickSize-2 : tickSize);
                g.attr("font-family", ENGFONT);
                paddingXAsix(g, padding);
                g.selectAll(".tick")
                    .selectAll("line")
                    .attr("stroke", Color.AXIS)
                    .attr("stroke-width", tickWidth)
                    .attr("y2", 6 * chartSize.height / 320);
                g.selectAll(".domain").attr("stroke-width", tickWidth)
                g.selectAll("text")
                    .attr("y", Math.max(9 * chartSize.height / 320, dotR * 1.5))

                if(this.size() === 'large') {
                    // 检查够不够放
                    let tickWidth = 0;
                    let xRange = x.range()[1]-x.range()[0];
                    g.selectAll(".tick")
                        .each(function(d, i) {
                            let _thisWidth = d3.select(this).node().getBBox().width
                            tickWidth += _thisWidth
                        });
                    if(tickWidth > xRange*0.99) { //横的不够放
                        g.selectAll("text")
                            .attr("transform", `translate(-${5},0)rotate(-45)`)
                            .attr("text-anchor", "end");
                        // 检查斜着有没有遮挡
                        let prev = g.select("text").node().getBBox().height;
                        
                        let rotateAble = Math.floor(xRange/ prev) >= g.selectAll(".tick").size();
                        // 如果遮挡 摆回正的
                        if(!rotateAble) {
                            g.selectAll("text")
                                .attr("transform", "")
                                .attr("text-anchor", "middle");
                            removeOverlapX(g, x)
                        }
                    }
                    
                } else removeOverlapX(g, x)

                return g
            });
        // append legend 
        if(hasSeries) {
            let seriesNameCopy = [...seriesName];
            seriesNameCopy.sort((a, b)=>a.length -b.length)
            seriesName= [];
            let i=0;
            while(seriesNameCopy.length) {
                if(i !== 0 && (i+1) % 4 === 0) seriesName.push(seriesNameCopy.pop())
                else seriesName.push(seriesNameCopy.shift());
                i+=1
            }
            let thisSize = this.size();

            if (this.size() === "wide") {
                svg.append("foreignObject")
                    .attr("x", chartSize.width - margin.left - margin.right + 30)
                    .attr("y", 0)
                    .attr("width", margin.right - 10)
                    .attr("height",height + margin.bottom * 0.6)
                    .append("xhtml:div")
                    .attr("class", "legends")
                    .style("display", "flex")
                    .style("flex-direction", "column")
                    // .style("flex-wrap", "wrap")
                    .style("align-content", "center")// "space-around")
                    .style("justify-content", moreThan? "": "space-evenly")
                    .style("height", Math.round(seriesName.length > 8? height + margin.bottom * 0.6 : height + margin.bottom * 0.3) + "px") //height + margin.bottom * 0.3
                    .selectAll(".legend")
                    .data(seriesName)
                    .enter()
                    .append("xhtml:div")
                    .attr("class", "legend")
                    .style("line-height", 0)
                    .style("margin-right", 5 * chartSize.width / 320 + "px")
                    .each(function (d, i) {
                        let legend = d3.select(svg.selectAll(".legend").nodes()[i]).append("svg")
                        legend.append("rect")
                            .attr("fill", d => Color.CATEGORICAL[seriesName.indexOf(d)])
                            .attr("width", seriesName.length > 8 ? tickSize-2 : tickSize)
                            .attr('height', seriesName.length > 8 ? tickSize-2 : tickSize)
                            .attr("rx", 1.5 * chartSize.width / 320)
                            .attr("ry", 1.5 * chartSize.width / 320)
                        legend.append("text")
                            .attr("x", tickSize+2)
                            .text(d => d.length > 13 ? d.substring(0, 12) + "…" : d)
                            .attr("font-size", seriesName.length > 8 ? tickSize-2 : tickSize)
                            .attr("font-family", ENGFONT)
                            .attr("dominant-baseline", "hanging");
                        legend.attr("width", Math.round(legend.node().getBBox().width))
                        legend.attr("height", Math.floor(legend.node().getBBox().height))

                    })
            } else {
                // seriesName = seriesName.slice(0,3)
                let xAxisHeightOffset;
                let isRotate = svg.selectAll(".xAxis").select("text").attr("transform") ? true : false
                if(this.size() === 'small')
                    xAxisHeightOffset = svg.selectAll(".xAxis").node().getBBox().height * 1.35
                else if(this.size() === 'middle')
                    xAxisHeightOffset = svg.selectAll(".xAxis").node().getBBox().height * 1.35
                else {
                    if(isRotate) xAxisHeightOffset = svg.selectAll(".xAxis").node().getBBox().height * 1.2 
                    else xAxisHeightOffset = svg.selectAll(".xAxis").node().getBBox().height * 2
                }
        
                svg.append("foreignObject")
                    .attr("x", 0) //padding
                    .attr("y", height + xAxisHeightOffset)
                    .attr("width", chartSize.width - margin.left - margin.right) //width - padding * 2 
                    .attr("height", (margin.bottom - xAxisHeightOffset))
                    .append("xhtml:div")
                    .attr("class", "legends")
                    .style("display", "grid")
                    .style("grid-template-columns", `repeat(${Math.min(seriesName.length, thisSize === 'small' ? 3 : 3)}, auto)`)
                    .style("grid-template-rows", `repeat(${thisSize === 'small' ? 3 : 4}, min-content)`)
                    .style("justify-content", seriesName.length < 3 ? "center" : "space-around")
                    .style("align-items", "center")
                    .style("align-content", "center")
                    .style("justify-items", seriesName.length >= 3 ? "start" : "center")
                    .style("padding-top", thisSize === 'small'? "":"1px")
                    // .style("height", Math.round((margin.bottom - xAxisHeightOffset)) + "px")
                    .selectAll(".legend")
                    .data(seriesName)
                    .enter()
                    .append("xhtml:div")
                    .attr("class", "legend")
                    .style("line-height", 0)
                    // .style("margin-right", 5 * chartSize.width / 320 + "px")
                    .each(function (d, i) {
                        let legend = d3.select(svg.selectAll(".legend").nodes()[i]).append("svg")
                        legend.append("rect")
                            .attr("fill", d => Color.CATEGORICAL[seriesName.indexOf(d)])
                            .attr("width", thisSize === 'large' ? tickSize + 1 : tickSize)
                            .attr('height', thisSize === 'large' ? tickSize + 1 : tickSize)
                            .attr("rx", 1.5 * chartSize.width / 320)
                            .attr("ry", 1.5 * chartSize.width / 320)
                        // .attr("cy", -5);
                        legend.append("text")
                            .attr("x", thisSize === 'large' ? tickSize + 2 : tickSize+1)
                            .text(d => {
                                let text = d
                                if(thisSize === 'small') { // small
                                    if(text.length > 7) text = text.substring(0, 6) + "…"
                                } else if(thisSize === 'middle' ) { //middle
                                    if(text.length > 10) text = text.substring(0, 9) + "…"
                                } else {
                                    if(text.length>13) text = text.substring(0, 12) + "…"
                                }
                                return text // large
                            })
                            .attr("font-size", thisSize === "small" || thisSize === "middle" ? tickSize : tickSize+1)
                            .attr("font-family", ENGFONT)
                            .attr("dominant-baseline", "hanging");
                        legend.attr("width", Math.round(legend.node().getBBox().width))
                        legend.attr("height", Math.floor(legend.node().getBBox().height))
    
                    })


    
            }
        }

        // add trend line 
        // draw trendline first to ensure it stay in the bottom of the line
        if(!hasSeries) {
            let ret = getLeastSquares(factData.map(d => x(parseTime(d[breakdown[0].field]))),
                factData.map(d => y(d[measureName])));

            let x1 = 40,
                x2 = width - 40;
            //regression in range, can not out of content
            let x_ymin = (height - ret.b) / ret.m,
                x_ymax = (0 - ret.b) / ret.m;

            if (x_ymin > x_ymax) {
                const i = x_ymin;
                x_ymin = x_ymax;
                x_ymax = i;
            }
            x1 = x1 < x_ymin ? x_ymin : x1;
            x2 = x2 > x_ymax ? x_ymax : x2;
            if (ret.m === 0) x1 = 0;
            let y1 = ret.m * x1 + ret.b,
                y2 = ret.m * x2 + ret.b;
            if (ret.m === -Infinity) {
                x1 = x2;
                y1 = 0;
                y2 = height;
            }
            svg.append("g")
                .attr("class", "trendlineLayer").selectAll(".trendline")
                .data(seriesData)
                .enter()
                .append("line")
                .attr("class", d => "trendline " + d.key)
                .attr("x1", x1)
                .attr("x2", x2)
                .attr("y1", y1)
                .attr("y2", y2)
                .attr("stroke", Color.DEFAULT)
                .attr("stroke-width", strokeWidth)
                .attr("stroke-dasharray", `${strokeWidth*2}, ${strokeWidth}`);
        }

        let lineGraph = svg.append("g").attr("class", "lineGraph")
        lineGraph.selectAll(".series")
                .data(seriesData)
                .enter()
                .append("g")
                .attr("class", d=>"series "+ d.key.replace(/\s/g, ""))
                .each(function(d, i) {
                    // append the line for the line chart
                    drawLines(d3.select(this), d, hasSeries, seriesName, lineGen, strokeWidth, true);

                    // append the dot for the line chart
                    let isDotSolid = true;
                    drawDots(d3.select(this), d, breakdown, measure, [], hasSeries, seriesName, x, y, dotR, isDotSolid, strokeWidth, Color, true);
                })

        if(this.style() === Style.COMICS){
            if(hasSeries){
                let metaphorWidth = width*0.26,
                metaphorHeight = 1.25*metaphorWidth;

                let metaphor = svg.append("image")
                    .attr('xlink:href', metaphor2);
            
                if(this.size() === Size.WIDE){
                    metaphorWidth = width*0.24;
                    metaphorHeight = 1.25*metaphorWidth;
                }else if (this.size() === Size.MIDDLE){
                    metaphorWidth = width*0.24;
                    metaphorHeight = 1.25*metaphorWidth;
                }
                
                metaphor.attr("width", metaphorWidth)
                    .attr("height", metaphorHeight)
                    .attr("x", width + metaphorWidth*0.06)
                    .attr("y", height - metaphorHeight*0.96);
            }else{
                //points
                // let filterPoints = [];
                // svg.selectAll(".tick").each(function(d){
                //     let item = factData.find(i => parseTime(i[breakdown[0].field]).getTime() === d.getTime());
                //     filterPoints.push(item);
                // });
                let filterPoints = seriesData[0].values;
                if(filterPoints.length > 7){//too much point
                    //draw dash line
                    let x0 = x(parseTime(filterPoints[0][breakdown[0].field])),
                        x1 = x(parseTime(filterPoints.slice(-1)[0][breakdown[0].field])),
                        y0 = y(filterPoints[0][measureName]),
                        y1 = y(filterPoints.slice(-1)[0][measureName]),
                        x2 = x1 + width*0.14,
                        y2 = (x2 - x1) * (y1-y0)/(x1-x0) + y1;
                    let line_m = svg.append('line')
                        .attr("x1", x1)
                        .attr("x2", x2)
                        .attr("y1", y1)
                        .attr("y2", y2)
                        .attr("stroke", Color.DASHLINE)
                        .attr("stroke-width", strokeWidth)
                        .attr("stroke-dasharray", `${strokeWidth*2}, ${strokeWidth}`);
                    svg.node().prepend(line_m.node());

                    let metaphorWidth = width*0.26,
                        metaphorHeight = 1.24*metaphorWidth;

                    let metaphor = svg.append("image")
                        .attr('xlink:href', metaphor6);
            
                    if(this.size() === Size.WIDE){
                        metaphorWidth = width*0.20;
                        metaphorHeight = 1.24*metaphorWidth;
                    }else if (this.size() === Size.MIDDLE || this.size() === Size.SMALL){
                        metaphorWidth = width*0.24;
                        metaphorHeight = 1.24*metaphorWidth;
                    }
                    
                    metaphor.attr("width", metaphorWidth)
                        .attr("height", metaphorHeight)
                        .attr("x", x2 - metaphorWidth*0.06)
                        .attr("y", y2 - metaphorHeight*0.06);
                    
                }else{
                    const metaphorWidth7 = width/(filterPoints.length-1)*0.6,
                        metaphorWidth8 = metaphorWidth7 / 1.14;
                    const metaphorHeight7 = metaphorWidth7*0.95;
                    const metaphorHeight8 = metaphorWidth8*1.2;

                    for(let i=1; i<filterPoints.length; i++){
                        let middleX = (x(parseTime(filterPoints[i][breakdown[0].field])) + x(parseTime(filterPoints[i-1][breakdown[0].field])))/2;
                        let middleY = (y(filterPoints[i][measureName]) + y(filterPoints[i-1][measureName]))/2;
                        if(filterPoints[i][measureName] - filterPoints[i-1][measureName] > 0){//up
                            svg.append("image")
                                .attr('xlink:href', metaphor8)
                                .attr("width", metaphorWidth8)
                                .attr("height", metaphorHeight8)
                                .attr("x", middleX - metaphorWidth8*0.7)
                                .attr("y", middleY - metaphorHeight8*0.96);
                        }else{//down
                            svg.append("image")
                                .attr('xlink:href', metaphor7)
                                .attr("width", metaphorWidth7)
                                .attr("height", metaphorHeight7)
                                .attr("x", middleX - metaphorWidth7*0.5)
                                .attr("y", middleY - metaphorHeight7*1);
                        }
                    }
                }
                //center居中
                svg.attr("transform", "translate(" + ((this.width() - svg.node().getBBox().width)/2 - svg.node().getBBox().x)  + "," + ((this.height() - svg.node().getBBox().height)/2 - svg.node().getBBox().y) + ")"); 
            }
        }
        
        return svg;
    }
    displayRank() {
        /* -------------------------------- init data ------------------------------- */
        let factData = this.factdata();
        let measure = this.measure();
        let breakdown = this.breakdown();
        let focus = this.focus()

        let hasSeries = breakdown.length > 1 ? true : false;
        if (!hasSeries) return;
        if(focus.length === 0) return;
        for(let f of focus) {
            if(f.field !== breakdown[1].field) return
        }
        // let measureName = measure[0] && measure[0].aggregate === 'count' ? "COUNT" : measure[0].field

        /* ----------------------- graph set up (size, margin) ---------------------- */
        focus = focus.slice(0, 3);
        
        let seriesData = d3.nest()
                            .key(function(d) { return d[breakdown[1].field]; })
                            .sortValues(sortByDateAscending(breakdown))
                            .entries(factData);
        seriesData = seriesData.filter(function (series) {
            for(let f of focus) {
                if(f.value === series.key) return true
            }
            return false
        });
        // if(focus.length > 10) seriesData = seriesData.slice(0, 10) 
        // sort by input focus order
        let focusOrder = focus.map(d=>d.value)
        seriesData.sort((a, b)=> focusOrder.indexOf(a.key) - focusOrder.indexOf(b.key))
        
        let seriesName = d3.map(seriesData, function(d){return d.key;}).keys();
        let moreThan =  false;
        let chartSize = { width: this.width(), height: this.height()};

        const chartMargin = {
            "small":{
                "top": 5,
                "right": 33,
                "bottom": 50,
                "left": 33
            },
            "middle":{
                "top":  10,
                "right": 50,
                "bottom": 55,
                "left": 50
            },
            "wide":{
                "top": 12,
                "right": 180, 
                "bottom": 40,
                "left": 15 + 20 * chartSize.width / 320
                // fact === "difference" ? 30 : 20
            },
            "large":{
                "top": 30,
                "right": 20 + 20 * chartSize.width / 320,
                "bottom": 150 + 50,
                "left": 20 + 20 * chartSize.width / 320
            }
        };
        let margin = chartMargin[this.size()] 

        let { tickSize, dotR, strokeWidth, padding} = getSizeBySize(this.size(),chartSize,  "rank", hasSeries, moreThan),
            width = chartSize.width - margin.left - margin.right,
            height = chartSize.height - margin.top - margin.bottom;
        /* ----------------------------- data prosessing ---------------------------- */
        // data example
        // [{Sales: 1054260, Year: "2010", Brand: "Ford"}
        // {Sales: 1296077, Year: "2011", Brand: "Ford"}]

        
        
        let svg = initSvg(this.container(), width, height, margin);
        if(this.style() === Style.COMICS) width = 0.8* width;
        /* ------------------------------ axis setting ------------------------------ */
        // padding is 0 !!!
        let { x, y } = setupXAsix(seriesData, factData, breakdown, measure, padding, width, height );
        /* ----------------------------------- vis ---------------------------------- */
       
        this._x = x;
        this._y = y;

        // add the x Axis
        let axisX = drawXAsix(seriesData, factData, breakdown, x);
        // custom x Axis
        svg.append("g")
            .attr("class", "xAxis")
            .attr("transform", "translate(0," + height + ")")
            .call(axisX)
            .call(g => {
                g.attr('font-size', tickSize)
                g.attr("font-family", ENGFONT);
                g.selectAll(".tick")
                    .selectAll('line')
                    .remove()
                g.selectAll("text")
                 .attr("y", Math.max(9 * chartSize.height / 320, dotR * 1.5))
                
                
                paddingXAsix(g, 0);
                
                if(this.size() === 'large') {
                    // 检查够不够放
                    let tickWidth = 0;
                    let xRange = x.range()[1]-x.range()[0];
                    g.selectAll(".tick")
                        .each(function(d, i) {
                            let _thisWidth = d3.select(this).node().getBBox().width
                            tickWidth += _thisWidth
                        });
                    if(tickWidth > xRange*0.99) { //横的不够放
                        g.selectAll("text")
                            .attr("transform", `translate(-${5},0)rotate(-45)`)
                            .attr("text-anchor", "end");
                        // 检查斜着有没有遮挡
                        let prev = g.select("text").node().getBBox().height;
                        
                        let rotateAble = Math.floor(xRange/ prev) >= g.selectAll(".tick").size();
                        // 如果遮挡 摆回正的
                        if(!rotateAble) {
                            g.selectAll("text")
                                .attr("transform", "")
                                .attr("text-anchor", "middle");
                            removeOverlapX(g, x)
                        }
                    }
                    
                } else removeOverlapX(g, x)
                return g
        });

        let sortSeriesData = seriesData.sort(function(a, b) {
                    return a.values[a.values.length-1] - b.values[b.values.length-1];
        });

        let sortKey = {};
        sortSeriesData.forEach((d, i)=> {
            sortKey[d.key] = i+1;
        })
        // svg.append("g")
        //     .attr("class", "gridline")
        //     .attr("transform", "translate(0," + height + ")")
        //     .call(axisX)
        //     .call(g => {
        //         g.selectAll(".tick")
        //             .selectAll('line')
        //             .attr("color", Color.BACKGROUND)
        //             .attr("y2", -height)
        //             .attr("y1", -1);
        //         g.selectAll(".domain")
        //             .remove()
        //         g.selectAll(".tick")
        //             .selectAll("text")
        //             .remove()
        // })
        // add invisible y Axis
        let axisY = d3.axisLeft(y)
        svg.append("g")
            .call(axisY)
            .call(g => {
                g.selectAll(".tick")
                    .selectAll('line')
                    .remove();
                    // .attr("color", Color.BACKGROUND)
                    // .attr("x2", width);
                g.selectAll(".tick")
                    .filter((_, i) => i === 0)
                    .attr("display", "none")
                    .attr("y1", -1);
                g.selectAll(".tick")
                    .selectAll("text")
                    .attr("display", "none");
                g.select('path')
                    .attr("display", "none")
                
                return g
            });

            if (this.size() === "wide") {
                svg.append("foreignObject")
                    .attr("x", width + 20)
                    .attr("y", 0)
                    .attr("width", margin.right - 10)
                    .attr("height", height)
                    .append("xhtml:div")
                    .attr("class", "legends")
                    .style("display", "flex")
                    .style("flex-direction", "column")
                    .style("flex-wrap", "wrap")
                    .style("align-content", "space-around")
                    .style("justify-content",  "space-evenly")
                    .style("height", Math.round(height) + "px")
                    .selectAll(".legend")
                    .data(seriesName)
                    .enter()
                    .append("xhtml:div")
                    .attr("class", "legend")
                    .style("line-height", 0)
                    .style("margin-right", 5 * chartSize.width / 320 + "px")
                    .each(function (d, i) {
                        let legend = d3.select(svg.selectAll(".legend").nodes()[i]).append("svg")
                        legend.append("rect")
                            .attr("fill", d => Color.CATEGORICAL[seriesName.indexOf(d)])
                            .attr("width", tickSize + 1)
                            .attr('height', tickSize + 1)
                            .attr("rx", 1.5 * chartSize.width / 320)
                            .attr("ry", 1.5 * chartSize.width / 320)
                        legend.append("text")
                            .attr("x", tickSize + 3)
                            .text(d => d.length > 12 ? d.substring(0, 11) + "…" : d)
                            .attr("font-size", tickSize + 1)
                            .attr("font-family", ENGFONT)
                            .attr("dominant-baseline", "hanging");
                        legend.attr("width", Math.round(legend.node().getBBox().width))
                        legend.attr("height", Math.floor(legend.node().getBBox().height))
    
    
                    })
            } else {
            
                let thisSize = this.size();
                let isRotate = svg.selectAll(".xAxis").select("text").attr("transform") ? true : false
                let xAxisHeightOffset;
                if(this.size() === 'small')
                    xAxisHeightOffset = svg.selectAll(".xAxis").node().getBBox().height * 1.35
                else if(this.size() === 'middle')
                    xAxisHeightOffset = svg.selectAll(".xAxis").node().getBBox().height * 1.35
                else {
                    if(isRotate) xAxisHeightOffset = svg.selectAll(".xAxis").node().getBBox().height * 1.2 
                    else xAxisHeightOffset = svg.selectAll(".xAxis").node().getBBox().height * 1.5
                }
                svg.append("foreignObject")
                    .attr("x", -margin.left * 0.5)
                    .attr("y", height + xAxisHeightOffset)
                    .attr("width", width + margin.left * 0.5 * 2)
                    .attr("height", (margin.bottom - xAxisHeightOffset))
                    .append("xhtml:div")
                    .attr("class", "legends")
                    .style("display", "grid")
                    .style("grid-template-columns", `repeat(${thisSize==="large"? 1: 3}, auto)`)
                    .style("justify-content", "center")
                    .style("align-items", "center")
                    .style("justify-items",thisSize === "large" ? "start" : "center")
                    .style("padding-top", "0px")
                    .style("height", Math.round((margin.bottom - xAxisHeightOffset)) + "px")
                    .selectAll(".legend")
                    .data(seriesName)
                    .enter()
                    .append("xhtml:div")
                    .attr("class", "legend")
                    .style("line-height", 0)
                    .style("margin-right", 5 * chartSize.width / 320 + "px")
                    .each(function (d, i) {
    
                        let legend = d3.select(svg.selectAll(".legend").nodes()[i]).append("svg")
                        legend.append("rect")
                            .attr("fill", d => Color.CATEGORICAL[seriesName.indexOf(d)])
                            .attr("width", thisSize === 'large' ? tickSize + 1 : tickSize)
                            .attr('height', thisSize === 'large' ? tickSize + 1 : tickSize)
                            .attr("rx", 1.5 * chartSize.width / 320)
                            .attr("ry", 1.5 * chartSize.width / 320)
                        // .attr("cy", -5);
                        legend.append("text")
                            .attr("x", thisSize === 'large' ? tickSize + 3 : tickSize+1)
                            .text(d => {
                                if(thisSize === 'large') {
                                    let rankId = "#"+ sortKey[d] + " "
                                    let allTextLength = seriesName.reduce((a, b) => a + b.length, 0)
                                    let enoughForOneLongText = allTextLength > 23? false:true;
                                    if(enoughForOneLongText)    return d.length > 14 ? rankId + d.substring(0, 13) + "…" : rankId + d;
                                    return d.length > 11 ? rankId + d.substring(0, 10) + "…" : rankId + d;
                                }
                                else if(thisSize === 'middle') {
                                    let allTextLength = seriesName.reduce((a, b) => a + b.length, 0)
                                    let enoughForOneLongText = allTextLength > 22? false:true;
                                    if(enoughForOneLongText)    return d.length > 14 ? d.substring(0, 13) + "…" : d;
                                    return d.length > 9 ? d.substring(0, 8) + "…" : d;
                                }
                                else {
                                    let allTextLength = seriesName.reduce((a, b) => a + b.length, 0)
                                    let enoughForOneLongText = allTextLength > 20? false:true;
                                    if(enoughForOneLongText)    return d.length > 14 ? d.substring(0, 13) + "…" : d;
                                    return d.length > 7 ? d.substring(0, 6) + "…" : d
                                }
                            })
                            .attr("font-size", thisSize === "small" || thisSize === "middle" ? tickSize  : tickSize+1)
                            .attr("font-family", ENGFONT)
                            .attr("dominant-baseline", "hanging");
                        legend.attr("width", Math.round(legend.node().getBBox().width))
                        legend.attr("height", Math.floor(legend.node().getBBox().height))
    
                    })
    
            }

        // line Function
        var lineGen = lineGeneration(breakdown, measure, x, y);
        
        let lingGraph = svg.append("g").attr("class", "lineGraph");
        lingGraph.selectAll(".series")
                .data(seriesData)
                .enter()
                .append("g")
                .attr("class", d=>"series "+ d.key.replace(/\s/g, ""))
                .each(function(d, i) {
                    // append the line for the line chartdrawLines(d3.select(this), d, hasSeries, seriesName, lineGen, strokeWidth, true);
                    drawLines(d3.select(this), d, hasSeries, seriesName, lineGen, strokeWidth);
                    // append the dot for the line chart
                    let isDotSolid = false;
                    drawDots(d3.select(this), d, breakdown, measure, [], hasSeries, seriesName, x, y, dotR, isDotSolid, strokeWidth, Color);

                })
        
        if(this.style() === Style.COMICS){
            let metaphorWidth = width*0.3,
                metaphorHeight = 1.23*metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor19);
        
            if(this.size() === Size.WIDE){
                metaphorWidth = width*0.3;
                metaphorHeight = 1.23*metaphorWidth;
                svg.select("foreignObject")
                    .attr("x", width/0.8 + 10);
            }else{
                if(this.size() === Size.MIDDLE || this.size() === Size.SMALL){
                    metaphorWidth = width*0.35;
                    metaphorHeight = 1.23*metaphorWidth;
                }
                svg.select("foreignObject")
                    .attr("width",  width/0.8 + margin.left * 0.5 * 2);
            }
            
            metaphor.attr("width", metaphorWidth)
                .attr("height", metaphorHeight)
                .attr("x", width + metaphorWidth*0.2)
                .attr("y", height - metaphorHeight*0.96);
        }
        return svg;
    }
    displayDifference() {
        /* -------------------------------- init data ------------------------------- */
        let factData = this.factdata();
        let measure = this.measure();
        let breakdown = this.breakdown();
        let focus = this.focus();
        if(focus.length < 2 || breakdown.length < 2) {
            return 
        }
        for(let f of focus) {
            if(f.field !== breakdown[1].field) return
        }
        let measureName = measure[0] && measure[0].aggregate === 'count' ? "COUNT" : measure[0].field

        /* ----------------------- graph set up (size, margin) ---------------------- */
        let chartSize = { width: this.width(), height: this.height()};
        let { margin, tickSize, dotR, strokeWidth, padding } = getSizeBySize(this.size(), chartSize , "difference", true),
            width = chartSize.width - margin.left - margin.right,
            height = chartSize.height - margin.top - margin.bottom;
        /* ----------------------------- data prosessing ---------------------------- */
        factData = factData.filter(d => {
            return d[breakdown[1].field] === focus[0].value || d[breakdown[1].field] === focus[1].value 
        });

        let seriesName = d3.map(factData, function(d){return d[breakdown[1].field];}).keys();
        let seriesData = d3.nest()
                            .key(function(d) { return d[breakdown[1].field]; })
                            .sortValues(sortByDateAscending(breakdown))
                            .entries(factData);
        // make up difference data
        let differenceData = [], arr1=seriesData[0].values, arr2 = seriesData[1].values

        for(let i=0; i<arr1.length; i++) {
            if(arr2.find((d) => d[breakdown[0].field] === arr1[i][breakdown[0].field])) {
                differenceData.push([arr1[i], arr2.find((d) => d[breakdown[0].field] === arr1[i][breakdown[0].field])])
            }
        }
        
        /* ----------------------------------- vis ---------------------------------- */
        let svg = initSvg(this.container(), width, height, margin);
        if(this.style() === Style.COMICS) width = 0.8* width;
        /* ------------------------------ axis setting ------------------------------ */
        let { x, y } = setupXAsix(seriesData, factData, breakdown, measure, padding, width, height );

        // add the x Axis
        let axisX = drawXAsix(seriesData, factData, breakdown, x);

        let xAxisOffset = height;
        if(y.domain()[0] !== 0) {
            xAxisOffset = y(0)
        }
        // custom x Axis    
        svg.append("g")
            .attr("class", "xAxis")
            .attr("transform", "translate(0," + xAxisOffset + ")")
            .call(axisX)
            .call(g => {
                g.attr('font-size', tickSize)
                g.attr("font-family", ENGFONT);
                let domainD = g.selectAll(".domain").attr("d");
                let start = domainD.split(",")[0].substr(1);
                let end = domainD.split("H")[1].split("V")[0];
                g.selectAll(".domain")
                    .attr("stroke-width", 1)
                    .attr('stroke', Color.AXIS)
                    .attr('d', "M"+(+start-padding)+",0H"+(+end+padding));

                g.selectAll("text")
                    .attr("y", 9 * chartSize.height / 320);
                g.selectAll(".tick")
                    .selectAll("line")
                    .attr("y2", 6 * chartSize.height / 320);
                
                removeOverlapX(g, x)
                return g
            });
        
        // add y Axis
        let axisY = d3.axisLeft(y).ticks(5).tickFormat(function (d) {
            if ((d / 1000000) >= 1) {
                d = d / 1000000 + "M";
            } else if ((d / 1000) >= 1) {
                d = d / 1000 + "K";
            }
            return d;
        });
        // gridline
        svg.append("g")
            .attr("class", "gridline")
            .call(axisY)
            .call(g => {
                g.selectAll(".tick")
                    .selectAll("line")
                    .attr("x1", 0)
                    .attr("x2", width)
                    .attr("stroke-width", 0.5)
                    .attr("stroke", Color.DIVIDER);
                g.selectAll(".domain")
                    .remove()
                g.selectAll(".tick")
                    .selectAll("text")
                    .remove()
        })
        // y axis
        if(this.size() !== 'small') {
            svg.append("g")
            .attr("class", "yAxis")
            .call(axisY)
            .call(g => {
                g.attr('font-size', tickSize)
                g.attr("font-family", ENGFONT);
                g.selectAll(".tick")
                    .filter((_, i) => i === 0)
                    .selectAll("line")
                    .attr("display", "none");
                let domainDY = g.selectAll(".domain").attr("d");
                let startY = domainDY.split(",")[1].split("H")[0];
                let endY = domainDY.split("V")[1].split("H")[0];
                g.selectAll(".domain")
                    .attr("stroke-width", 1)
                    .attr('stroke', Color.AXIS)
                    .attr('d', "M0, "+startY+"V"+endY);
                g.selectAll(".tick")
                    .selectAll("line")
                    .attr("x2", -6 * chartSize.height / 320);
                g.selectAll("text")
                    .attr("x", -9 * chartSize.height / 320)
                return g
            });
        }

        // add difference line
        svg.append("g")
            .attr("class", "differences")
            .selectAll(".difference")
            .data(differenceData)
            .enter()
            .append("line")
            .attr("class", "difference")
            .attr("x1", d => x(parseTime(d[0][breakdown[0].field])))
            .attr("x2", d => x(parseTime(d[1][breakdown[0].field])))
            .attr("y1", d => y(d[0][measureName]))
            .attr("y2", d => y(d[1][measureName]))
            .attr("stroke", Color.DASHLINE)
            .attr("stroke-dasharray","5,5");
        
        // line Function
        var lineGen = lineGeneration(breakdown, measure, x, y);

        let lingGraph = svg.append("g").attr("class", "lineGraph")
        lingGraph.selectAll(".series")
                .data(seriesData)
                .enter()
                .append("g")
                .attr("class", d=>"series "+ d.key.replace(/\s/g, ""))
                .each(function(d, i) {
                    // append the line for the line chart
                    drawLines(d3.select(this), d, true, seriesName, lineGen, strokeWidth);

                    // append the dot for the line chart
                    let isDotSolid = true;
                    drawDots(d3.select(this), d, breakdown, measure, [], true, seriesName, x, y, dotR, isDotSolid, strokeWidth, Color);

                })
        
                if (this.size() === "wide") {
                    svg.append("foreignObject")
                        .attr("x", width + 10)
                        .attr("y", 0)
                        .attr("width", margin.right - 10)
                        .attr("height", height + margin.bottom * 0.5)
                        .append("xhtml:div")
                        .attr("class", "legends")
                        .style("display", "flex")
                        .style("flex-direction", "column")
                        .style("flex-wrap", "wrap")
                        .style("align-content", "space-around")
                        .style("justify-content", "space-evenly")
                        .style("height", Math.round(height + margin.bottom * 0.6) + "px")
                        .selectAll(".legend")
                        .data(seriesName)
                        .enter()
                        .append("xhtml:div")
                        .attr("class", "legend")
                        .style("line-height", 1)
                        .style("margin-right", 5 * chartSize.width / 320 + "px")
                        .each(function (d, i) {
                            let legend = d3.select(svg.selectAll(".legend").nodes()[i]).append("svg")
                            legend.append("rect")
                                .attr("fill", d => Color.CATEGORICAL[seriesName.indexOf(d)])
                                .attr("width", 10 * tickSize / 12)
                                .attr('height', 10 * tickSize / 12)
                                .attr("rx", 1.5 * chartSize.width / 320)
                                .attr("ry", 1.5 * chartSize.width / 320)
                            legend.append("text")
                                .attr("x", 12 * tickSize / 12)
                                .text(d => d.length > 12 ? d.substring(0, 10) + "..." : d)
                                .attr("font-size", tickSize)
                                .attr("font-family", ENGFONT)
                                .attr("dominant-baseline", "hanging");
                            legend.attr("width", Math.round(legend.node().getBBox().width))
                            legend.attr("height", Math.floor(legend.node().getBBox().height))
        
        
                        })
                } else {
                    let xAxisHeight = svg.selectAll(".xAxis").node().getBBox().height;
                    let thisSize = this.size();
                    let xAxisHeightOffset
                    if(this.size() === 'small')
                    xAxisHeightOffset = xAxisHeight * 1.35
                    else if(this.size() === 'middle')
                        xAxisHeightOffset = xAxisHeight * 1.3
                    else 
                        xAxisHeightOffset = xAxisHeight * 2 
                    svg.append("foreignObject")
                        .attr("x", 0)
                        .attr("y", height + xAxisHeightOffset)
                        .attr("width", width)
                        .attr("height", (margin.bottom - xAxisHeightOffset))
                        .append("xhtml:div")
                        .attr("class", "legends")
                        .style("display", "grid")
                        .style("grid-template-columns", `repeat(2, auto)`)
                        .style("justify-content", "center")
                        .style("align-items", "center")
                        .style("justify-items","center")
                    
                        // .style("justify-content", thisSize === 'middle' ? "flex-start": "space-evenly")
                        .style("padding-top", "1px")
                        .style("height", Math.round((margin.bottom - xAxisHeightOffset)) + "px")
                        .selectAll(".legend")
                        .data(seriesName)
                        .enter()
                        .append("xhtml:div")
                        .attr("class", "legend")
                        .style("line-height", 0)
                        .style("margin-right", 5 * chartSize.width / 320 + "px")
                        .each(function (d, i) {
        
                            let legend = d3.select(svg.selectAll(".legend").nodes()[i]).append("svg")
                            legend.append("rect")
                                .attr("fill", d => Color.CATEGORICAL[seriesName.indexOf(d)])
                                .attr("width", thisSize === 'large' ? tickSize + 2 : tickSize + 1)
                                .attr('height', thisSize === 'large' ? tickSize + 2 : tickSize + 1)
                                .attr("rx", 1.5 * chartSize.width / 320)
                                .attr("ry", 1.5 * chartSize.width / 320)
                            // .attr("cy", -5);
                            legend.append("text")
                                .attr("x", thisSize === 'large' ? tickSize + 4 : tickSize + 2)
                                .text(d => {
                                    if(thisSize === 'large') return d.length > 22 ? d.substring(0, 21) + "..." : d;
                                    else if(thisSize === 'middle') return d.length > 16 ? d.substring(0, 15) + "..." : d;
                                    else return d.length > 17 ? d.substring(0, 16) + "..." : d
                                })
                                .attr("font-size", thisSize === 'large' ? tickSize + 2 : tickSize + 1)
                                .attr("font-family", ENGFONT)
                                .attr("dominant-baseline", "hanging");
                            legend.attr("width", Math.round(legend.node().getBBox().width))
                            legend.attr("height", Math.floor(legend.node().getBBox().height))
        
                        });
                }

        if(this.style() === Style.COMICS){
            let metaphorWidth = width*0.25,
                metaphorHeight = 1.5*metaphorWidth;

            let metaphor = svg.append("image")
                .attr('xlink:href', metaphor11)
                .attr("width", metaphorWidth)
                .attr("height", metaphorHeight);

            if(this.size() === Size.WIDE){
                metaphor.attr("x", width)
                    .attr("y", height - metaphorHeight);
                svg.select("foreignObject")
                    .attr("x", width/0.8 + 10);
            }else if (this.size() === Size.MIDDLE){
                metaphorWidth = width*0.3;
                metaphorHeight = 1.5*metaphorWidth;

                metaphor.attr("width", metaphorWidth)
                    .attr("height", metaphorHeight)
                    .attr("x", width*1.01)
                    .attr("y", height - metaphorHeight);
            }else{
                metaphor.attr("x", width*1.01)
                    .attr("y", height - metaphorHeight);
            }
        }

        return svg;
        

    }
    animateTrend() {        
        /* -------------------------------- init data ------------------------------- */
        let breakdown = this.breakdown();
        let hasSeries = breakdown.length > 1 ? true : false;
        

        let ticks = 10;
        
        let duration = this.duration();
        let chartSize = { width: this.width(), height: this.height()};
        let { margin, dotR } = getSizeBySize(this.size(), chartSize , "trend", hasSeries),
            width = chartSize.width - margin.left - margin.right,
            height = chartSize.height - margin.top - margin.bottom;
        /* -------------------------------- basic vis ------------------------------- */
        let svg = this.displayTrend();
        if(!svg) return;
        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            axisFadeIn: {
                duration: 1,
                index: 0
            },
            majorSwipe: {
                duration: breakdown.length === 1 ? 5 : 9,
                index: 1
            },
            trendlineFadeIn: {
                duration: 4,
                index: 2
            }
        }
        let everyTick = duration / ticks;

        /* ---------------------------- animation of axis --------------------------- */
        let xAxis = svg.selectAll(".xAxis");
        
        // disable xAxis tick line
        xAxis.selectAll("line")
            .remove()
        
        // hide xAxis text first
        xAxis.selectAll("text")
            .attr("opacity", 0);
            
        // fade in axis 
        xAxis.selectAll(".domain")
            .attr("opacity", 0)
            .transition()
            .duration(everyTick * animation.axisFadeIn.duration)
            .attr("opacity", 1)

        // fade in axis text one by one
        setTimeout(()=> {
            xAxis.selectAll("text")
            .attr("opacity", 0)
            .transition()
            .delay((_, i) => everyTick * animation.majorSwipe.duration / (xAxis.selectAll("text").size()) * i)
            .duration(everyTick * animation.majorSwipe.duration / (xAxis.selectAll("text").size()))
            .attr("opacity", 1)
        }, everyTick * countTicksBeforeIndex(animation, animation.majorSwipe.index));

        /* ---------------------------- animation of line --------------------------- */
        let lineGraph = svg.selectAll(".lineGraph");
        let uuid = uuidv4();

        // defs
        lineGraph.attr('id', 'lineGraphClip')
                .attr('clip-path', 'url(#clip_lineGraph_'+uuid+')');
        lineGraph.append('defs')
                .attr('class', 'trend_defs')
                .append('clipPath')
                .attr('id', 'clip_lineGraph_'+uuid)
                .append('rect')
                .attr('x', 0)
                .attr("y", -dotR)
                .attr('width', 0)
                .attr('height', height + 2 * dotR);
        
        setTimeout(()=>{
            lineGraph.select("#clip_lineGraph_"+uuid+" rect")
            .attr('width',0)
            .transition()
            .duration(everyTick * animation.majorSwipe.duration)
            .ease(d3.easeLinear)
            .attr('width', width + dotR);
        }, everyTick * countTicksBeforeIndex(animation, animation.majorSwipe.index))
        
        /* ------------------------- animation of trendline ------------------------- */
        if(breakdown.length === 1) {
            let trendlineLayer = svg.selectAll(".trendlineLayer");
            let trendline = trendlineLayer.selectAll("line");

            let originEndX2 = trendline.attr("x2");
            let originEndY2 = trendline.attr("y2");
            let originEndX1 = trendline.attr("x1");
            let originEndY1 = trendline.attr("y1");

            // add triangle
            let finalPosition = `${originEndX2}, ${originEndY2}`
            let f_x = originEndX2,
                f_y = originEndY2,
                s_x = originEndX1,
                s_y = originEndY1;
            function getTanDeg(tan) {
                var result = Math.atan(tan) / (Math.PI / 180);
                result = Math.round(result);
                return result;
            }
            let slope = (f_y - s_y) / (f_x - s_x)
            let deg;
            if (getTanDeg(slope) < 0) {
                deg = 90-Math.abs(getTanDeg(slope));
            } else {
                deg = 90+getTanDeg(slope);
            }
            
            trendlineLayer.append("path")
                .attr("class", "triangle")
                .attr("transform", "translate(" + finalPosition + ")rotate(" + deg + ")")
                .attr("d", d3.symbol().type(d3.symbolTriangle).size(0.16 * height))
                .attr("fill", Color.HIGHLIGHT);

            trendline.attr("stroke", Color.HIGHLIGHT)
                    .attr("stroke-width", trendline.attr("stroke-width")*2)
            trendlineLayer.node().parentNode.appendChild(trendlineLayer.node());

            // trendline.attr("x2", originEndX1)
            //          .attr("y2", originEndY1);

            let defsX = trendlineLayer.node().getBBox().x,
                defsY = trendlineLayer.node().getBBox().y,
                defsHeight = trendlineLayer.node().getBBox().height,
                defsWidth = trendlineLayer.node().getBBox().width;
            let uuid = uuidv4();
            trendlineLayer.attr("id", "trendSVGClip")
                .attr("clip-path", "url(#clip_trend_"+uuid+")");

            trendlineLayer.append("defs")
                .attr("class", "trend_defs")
                .append("clipPath")
                .attr("id", "clip_trend_"+uuid)
                .append("rect")
                .attr("x", defsX - 10)
                .attr("y", defsY - 10)
                .attr("width", 0)
                .attr("height", defsHeight + 10);

            
            let lineGraph = svg.selectAll(".lineGraph");
            setTimeout(()=>{
                lineGraph.attr("opacity", 1)
                        .transition()
                        .duration(everyTick * animation.trendlineFadeIn.duration * 0.4)
                        .attr("opacity", 0.1);

                trendlineLayer.select("#clip_trend_"+uuid+" rect")
                    .attr("width", 0)
                    .transition()
                    .delay(everyTick * animation.trendlineFadeIn.duration * 0.4)
                    .duration(everyTick * animation.trendlineFadeIn.duration * 0.6)
                    .ease(d3.easeLinear)
                    .attr("width", defsWidth+10)
                // trendline.attr("x2", originEndX1)
                //     .attr("y2", originEndY1)
                //     .transition()
                //     .delay(everyTick * animation.trendlineFadeIn.duration * 0.5)
                //     .duration(everyTick * animation.trendlineFadeIn.duration)
                //     .attr("x2", originEndX2)
                //     .attr("y2", originEndY2);
            }, everyTick * countTicksBeforeIndex(animation, animation.trendlineFadeIn.index));
        }
    }
    animateOutlier() {
        /* -------------------------------- init data ------------------------------- */
        let ticks = 10;
        let duration = this.duration();
        let factData = this.factdata();
        let breakdown = this.breakdown();
        let measure = this.measure();
        let focus = this.focus();
        let measureName = measure[0] && measure[0].aggregate === 'count' ? "COUNT" : measure[0].field

        let seriesData = [];
        seriesData.push({
            key: "All",
            values: factData.sort(sortByDateAscending(breakdown)),
        });
        
        /* -------------------------------- basic vis ------------------------------- */
        let svg = this.displayOutlier();
        if(!svg) return;

        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            axisFadeIn: {
                duration: 1,
                index: 0
            },
            majorFadeIn: {
                duration: 2,
                index: 1
            },
            regLineDraw: {
                duration: 2,
                index: 2
            },
            otherFadeOut: {
                duration: 2,
                index: 3
            },
            drawCircle: {
                duration: 3,
                index: 4
            },
            showDate: {
                duration: 3,
                index: 4
            }
        }
        let everyTick = duration / ticks;

        /* ---------------------------- step 0 axisFadeIn --------------------------- */
        let xAxis = svg.selectAll(".xAxis");

        // disable xAxis tick line
        xAxis.selectAll("line")
            .remove();
        // disable xAxis label text
        xAxis.selectAll(".xLabel")
            .remove();
        // disable xAxis arrow
        xAxis.selectAll(".xArrow")
            .remove();

        // disable xAxis tick line
        xAxis.selectAll("line")
            .remove();

        xAxis.select(".tick")
            .attr("opacity", 0);
        
        // fade in axis 
        xAxis.selectAll(".domain")
            .attr("opacity", 0)
            .transition()
            .duration(everyTick * animation.axisFadeIn.duration)
            .attr("opacity", 1);
        
        let yAxis = svg.selectAll(".yAxis");
        yAxis.attr("opacity", 0)
            .transition()
            .duration(everyTick * animation.axisFadeIn.duration)
            .attr("opacity", 1);

        /* ---------------------------- step 1 majorFadeIn ---------------------------- */
        let dots = svg.selectAll(".dots");
        // dots.attr("opacity", 0);
        dots.selectAll("circle")
            .each(function() {
                d3.select(this).attr("fill", Color.DEFAULT)
            })

        let lineGraph = svg.selectAll(".lineGraph");
        lineGraph.attr("opacity", 0)
        setTimeout(() => {
            lineGraph.transition()
                .duration(everyTick * animation.majorFadeIn.duration)
                .attr("opacity", 1)
        }, everyTick * countTicksBeforeIndex(animation, animation.majorFadeIn.index));

        /* --------------------------- step 2 regLineDraw -------------------------- */
        let y = this._y,
            width = this._width,
            // height = this._height,
            strokeWidth = this._strokeWidth;
        // let ret = getLeastSquares(factData.map(d => x(parseTime(d[breakdown[0].field]))),
        //         factData.map(d => y(d[measureName])));
        
        let avgY = y(d3.sum(factData, d => d[measureName]) / factData.length);

        let x1 = 0,
            x2 = width;

        svg.append("g")
            .attr("class", "trendlineLayer").selectAll(".trendline")
            .data(seriesData)
            .enter()
            .append("line")
            .attr("class", d => "trendline " + d.key)
            .attr("x1", x1)
            .attr("x2", x1)
            .attr("y1", avgY)
            .attr("y2", avgY)
            .attr("stroke", Color.DASHLINE)
            .attr("stroke-width", strokeWidth)
            .attr("stroke-dasharray", `${strokeWidth*2}, ${strokeWidth}`)
            .transition()
            .duration(everyTick * animation.regLineDraw.duration)
            .delay(everyTick * countTicksBeforeIndex(animation, animation.regLineDraw.index))
            .attr("x2", x2)
            .attr("y2", avgY);

        let fontSize = xAxis.select("text").attr("font-size");
        let avgFontSize = fontSize * 0.8;
        svg.append("g")
            .append("text")
            .attr("x", x2)
            .attr("y", avgY-avgFontSize*0.5)
            .attr("text-anchor", "end")
            // .attr()
            .text("average")
            .attr("fill", Color.DASHLINE)
            .attr("font-size", avgFontSize)
            .attr("opacity", 0)
            .transition()
            .duration(everyTick * animation.regLineDraw.duration)
            .delay(everyTick * countTicksBeforeIndex(animation, animation.regLineDraw.index))
            .attr("opacity", 1)



        let dotFocus = dots.selectAll("#dotFocus");

        /* ---------------------------- step 3 otherFadeOut --------------------------- */
        let lines = svg.selectAll(".lines");
        let otherDots = svg.selectAll(".dot").filter(function(d, i) {
            return !d3.select(this).attr("id")
        });

        lines.selectAll("path").attr("opacity", 1)
            .transition()
            .duration(everyTick * animation.otherFadeOut.duration)
            .delay(everyTick * countTicksBeforeIndex(animation, animation.otherFadeOut.index))
            .attr("stroke", "#e8f4fc");

        otherDots.attr("opacity", 1)
            .transition()
            .duration(everyTick * animation.otherFadeOut.duration)
            .delay(everyTick * countTicksBeforeIndex(animation, animation.otherFadeOut.index))
            .attr("fill", "#e8f4fc");
        

        let refline = svg.selectAll(".refline").selectAll("line");
        refline.attr("opacity", 0)

        /* ---------------------------- step 4 drawCircle --------------------------- */
        let circleR = dotFocus.attr("r") * 2,
            circleX = dotFocus.attr("cx"),
            circleY = dotFocus.attr("cy");
        setTimeout(()=>{
            
            
            let circle_data = {
                    "x": parseFloat(circleX),
                    "y": parseFloat(circleY),
                    "startAngle":0,
                    "endAngle": 2 * Math.PI
                };
            let arc = d3.arc()
                .innerRadius(circleR * 0.9)
                .outerRadius(circleR);

            svg.append("path")
                    .datum(circle_data)
                    .attr("class", "animation")
                    .attr("stroke", Color.ANNOTATION)
                    .attr("fill", Color.ANNOTATION)
                    .attr("x", circleX)
                    .attr("y", circleY)
                    .attr("transform", "translate("+ circleX + "," + circleY + ")")
                    .attr("d", arc)
                    .transition()
                    .duration(everyTick * animation.drawCircle.duration * 0.5)
                    .attrTween('d', function(d) {
                        var i = d3.interpolate(d.startAngle, d.endAngle);
                        return function(t) {
                            d.endAngle = i(t);
                            return arc(d);
                        }
                    });
            
        }, everyTick * (countTicksBeforeIndex(animation, animation.drawCircle.index)))


        /* ---------------------------- step 4 showDate --------------------------- */
        let tooltip = svg.selectAll(".tooltip");
        tooltip.attr("opacity", 0);

        
        svg.append("text")
            .attr("fill", Color.ANNOTATION)
            .attr("font-size", fontSize)
            .attr("x", circleX)
            .attr("y", circleY - circleR * 2)
            .attr("dominant-baseline", "baseline")
            .attr("text-anchor", "middle")
            .text(focus[0].value)
            .attr("fill-opacity", 0)
            .transition()
            .duration(everyTick * animation.showDate.duration)
            .delay(everyTick * countTicksBeforeIndex(animation, animation.showDate.index))
            .attr("fill-opacity", 1)
        


        
    }
    animateExtreme() {
        /* -------------------------------- init data ------------------------------- */
        let ticks = 10;
        let duration = this.duration();

        /* -------------------------------- basic vis ------------------------------- */
        let svg = this.displayOutlier();
        if(!svg) return;

        /* ------------------------------ start animate ----------------------------- */
        /* ----------------------- animation frame arrangement ---------------------- */
        let animation = {
            axisFadeIn: {
                duration: 1,
                index: 0
            },
            majorFadeIn: {
                duration: 1,
                index: 1
            },
            fillDotColor: {
                duration: 2,
                index: 2
            },
            reflineDraw: {
                duration: 2,
                index: 3
            },
            tickFadeIn: {
                duration: 2,
                index: 3
            },
            tooltipFadeIn: {
                duration: 1,
                index: 4
            },
            popUpDot: {
                duration: 3,
                index: 5
            }
        }
        let everyTick = duration / ticks;

        /* ---------------------------- step 0 axisFadeIn --------------------------- */
        let xAxis = svg.selectAll(".xAxis");

        // disable xAxis tick line
        xAxis.selectAll("line")
            .remove();
        // disable xAxis label text
        xAxis.selectAll(".xLabel")
            .remove();
        // disable xAxis arrow
        xAxis.selectAll(".xArrow")
            .remove();

        // disable xAxis tick line
        xAxis.selectAll("line")
            .remove();

        // fade in axis 
        xAxis.selectAll(".domain")
            .attr("opacity", 0)
            .transition()
            .duration(everyTick * animation.axisFadeIn.duration)
            .attr("opacity", 1);
        
        let yAxis = svg.selectAll(".yAxis");
        yAxis.attr("opacity", 0)
            .transition()
            .duration(everyTick * animation.axisFadeIn.duration)
            .attr("opacity", 1);

        /* ---------------------------- step 1 majorFadeIn ---------------------------- */
        let dots = svg.selectAll(".dots");
        // dots.attr("opacity", 0);
        dots.selectAll("circle")
            .each(function() {
                d3.select(this).attr("fill", Color.DEFAULT)
            })

        let lineGraph = svg.selectAll(".lineGraph");
        lineGraph.attr("opacity", 0)
        setTimeout(() => {
            lineGraph.transition()
                .duration(everyTick * animation.majorFadeIn.duration)
                .attr("opacity", 1)
        }, everyTick * countTicksBeforeIndex(animation, animation.majorFadeIn.index));

        /* --------------------------- step 2 fillDotColor -------------------------- */
        let dotFocus = dots.selectAll("#dotFocus");
        setTimeout(()=>{
            dotFocus.attr("fill", Color.DEFAULT)
                    .transition()
                    .duration(everyTick * animation.fillDotColor.duration)
                    .attr("fill", Color.HIGHLIGHT)
        }, everyTick * countTicksBeforeIndex(animation, animation.fillDotColor.index))  

        /* ---------------------------- step 3 reflineDraw --------------------------- */
        let refline = svg.selectAll(".refline").selectAll("line");
        let reflineY2 = refline.attr("y2"),
            reflineY1 = refline.attr("y1");
        refline.attr("y2", reflineY1);
        refline.transition()
                .duration(everyTick * animation.reflineDraw.duration)
                .delay(everyTick * countTicksBeforeIndex(animation, animation.reflineDraw.index))
                .attr("y2", reflineY2);

        /* ---------------------------- step 4 tickFadeIn --------------------------- */
        // hide xAxis text first
        let xticks = xAxis.selectAll(".tick")
        xticks.selectAll("text").attr("fill", Color.ANNOTATION)
        xticks.attr("opacity", 0);
        // fade in axis text one by one
        setTimeout(()=> {
            xticks.transition()
                .duration(everyTick * animation.tickFadeIn.duration)
                .attr("opacity", 1)
        }, everyTick * countTicksBeforeIndex(animation, animation.tickFadeIn.index));

        /* ---------------------------- step 4 textFadeIn --------------------------- */
        let tooltip = svg.selectAll(".tooltip");
        tooltip.attr("opacity", 0);
        setTimeout(()=>{
            tooltip.transition()
                    .duration(everyTick * animation.tooltipFadeIn.duration)
                    .attr("opacity", 1)
        }, everyTick * (countTicksBeforeIndex(animation, animation.tooltipFadeIn.index)));

        
        /* ----------------------------- step 5 popUpDot ---------------------------- */
        let originalR = dotFocus.attr("r");
        let shortest = 150;
        setTimeout(() => {
            let count = 0;
            
            let poping = setInterval(()=>{
                count += shortest*2;
                dotFocus.transition()
                        .duration(shortest)
                        .attr("r", originalR * 1.5)
                        .transition()
                        .duration(shortest)
                        .attr("r", originalR)
                if(count >= everyTick * animation.popUpDot.duration) {
                    clearInterval(poping)
                }

            }, shortest * 2)

        }, everyTick * countTicksBeforeIndex(animation, animation.popUpDot.index));


    }
    animateRank() {

        /* -------------------------------- init data ------------------------------- */
        let factData = this.factdata();
        let measure = this.measure();
        let breakdown = this.breakdown();
        let focus = this.focus()
        // let ticks = 10;
        let duration = this.duration();
        let chartSize = { width: this.width(), height: this.height()};
        const chartMargin = {
            "small":{
                "top": 5,
                "right": 33,
                "bottom": 50,
                "left": 33
            },
            "middle":{
                "top":  10,
                "right": 50,
                "bottom": 55,
                "left": 50
            },
            "wide":{
                "top": 12,
                "right": 180, 
                "bottom": 40,
                "left": 15 + 20 * chartSize.width / 320
                // fact === "difference" ? 30 : 20
            },
            "large":{
                "top": 30,
                "right": 20 + 20 * chartSize.width / 320,
                "bottom": 150 + 50,
                "left": 20 + 20 * chartSize.width / 320
            }
        };
        let margin = chartMargin[this.size()] 
        let {strokeWidth} = getSizeBySize(this.size(), chartSize , "rank", true),
        width = chartSize.width - margin.left - margin.right,
        height = chartSize.height - margin.top - margin.bottom;
        let padding = 0;
        let dotR = 6;

        let seriesData = d3.nest()
                            .key(function(d) { return d[breakdown[1].field]; })
                            .sortValues(sortByDateAscending(breakdown))
                            .entries(factData);
        let focusOrder = focus.map(d=>d.value)
        seriesData.sort((a, b)=> focusOrder.indexOf(a.key) - focusOrder.indexOf(b.key))
        
        let svg = this.displayRank();
        if(!svg) return
        /* ----------------------- animation frame arrangement ---------------------- */
        let xAxis = svg.selectAll(".xAxis");

        let tickPosition = xAxis.selectAll(".tick")
            .nodes()
            .map(function(d) {
                let trans = d3.select(d).attr("transform");
                return +trans.split('(')[1].split(",")[0]

            })
        let ticksShowed = xAxis.selectAll(".tick>text")
            .nodes()
            .map(function(t){
            return parseTime(t.innerHTML) ;
            })
        
        // 获取当前axis是不是rotate的
        
        let isRotate = !xAxis.select("text").attr("transform") || xAxis.select("text").attr("transform").length ===0? false : true;
        
        let format_TicksCount = formatTicksCount(factData[0][breakdown[0].field]);
        let allTicks = this._x.ticks();
        if(format_TicksCount === d3.timeYear) {
            for(let i=0; i<allTicks.length; i++) {
                // console.log(d3.timeFormat("%Y")(allTicks[i]))
                allTicks[i] = format_TicksCount(allTicks[i])
            }
        }
        
        let i = 1;
        let x = this._x;
        let y = this._y;
        x.domain([ticksShowed[0], ticksShowed[1]]);
        let axisXCalled = drawXAsix(seriesData, factData, breakdown, x, null, ticksShowed);
        xAxis.call(axisXCalled);

        // let gridCalled = drawXAsix(seriesData, factData, breakdown, x);
        // svg.selectAll(".gridline")
        //     .call(gridCalled.tickFormat("")).call(g => {
        //         g.selectAll(".tick")
        //             .selectAll('line')
        //             .attr("color", Color.BACKGROUND)
        //             .attr("y2", -height)
        //             .attr("y1", -1);
        //         g.selectAll(".domain")
        //             .remove()
        //         g.selectAll(".tick")
        //             .selectAll("text")
        //             .remove()
        // })

        let dots = svg.selectAll(".dots");
        let lines = svg.selectAll(".lines");

        dots.selectAll("circle")
            .attr("cx",d=>x(parseTime(d[breakdown[0].field])))
        var lineGen = lineGeneration(breakdown, measure, x, y);
        lines.selectAll("path")
            .attr("d", d => lineGen(d.values))

        let uuid = uuidv4();

        let lineGraph = svg.selectAll(".lineGraph");
        lineGraph.attr('id', 'lineGraphClip')
                .attr('clip-path', 'url(#clip_lineGraph_'+uuid+')');
        lineGraph.append('defs')
                .attr('class', 'trend_defs')
                .append('clipPath')
                .attr('id', 'clip_lineGraph_'+uuid)
                .append('rect')
                .attr('x', padding - dotR - strokeWidth *2)
                .attr('y', -dotR - strokeWidth*2)
                .attr('width', 0)//width - padding + 2*dotR)
                .attr('height', height + dotR * 2 + strokeWidth *4);
        repeat();

        function repeat()
        {   
            
            lineGraph.select("#clip_lineGraph_"+uuid+" rect")
                    .attr('width', tickPosition[i-1]+2*dotR)
                    .transition()
                    .duration(duration/ticksShowed.length)
                    .ease(d3.easeLinear)
                    .attr('width', i === ticksShowed.length-1 ? width-padding+2*dotR+4*strokeWidth : tickPosition[i]+2*dotR+4*strokeWidth);
            i += 1;
            if(i >= ticksShowed.length) {
                // showRank()
                return
            }
            x.domain([ticksShowed[0], ticksShowed[i]]);
            let axisXCalled = drawXAsix(seriesData, factData, breakdown, x, null, ticksShowed);
            let gridCalled = drawXAsix(seriesData, factData, breakdown, x);
            let noTrans = svg
            var t0 = svg.transition().duration(duration/ticksShowed.length).ease(d3.easeLinear);
            t0.selectAll(".xAxis")
                .call(axisXCalled)
                .call(g => {
                    g.selectAll("text").attr("y", Math.max(9 * chartSize.height / 320, dotR * 1.5))
                })
            if(isRotate) {
                noTrans.select(".xAxis").selectAll("text")
                        .attr("transform", `translate(-${5},0)rotate(-45)`)
                        .attr("text-anchor", "end"); 
            }
            noTrans.select(".xAxis")
                    .selectAll(".tick")
                    .selectAll('line')
                    .remove();
            noTrans.select(".xAxis").selectAll("text")
                    // .attr("dy", 9 * chartSize.height / 320)
                    .attr("y", Math.max(9 * chartSize.height / 320, dotR * 1.5))
            t0.selectAll(".gridline")
                .call(gridCalled.tickFormat(""))
                .call(g => {
                    g.selectAll(".tick")
                        .selectAll('line')
                        .attr("color", Color.BACKGROUND)
                        .attr("y2", -height)
                        .attr("y1", -1);
                    g.selectAll(".domain")
                        .remove()
                    g.selectAll(".tick")
                        .selectAll("text")
                        .remove()
                })
            t0.selectAll(".dots").selectAll("circle")
                .attr("cx",d=>x(parseTime(d[breakdown[0].field])))
            var lineGen = lineGeneration(breakdown, measure, x, y);
            t0.selectAll(".lines").selectAll("path")
                .attr("d", d => lineGen(d.values))
            t0.on("end", repeat);

            
        }
    }
}

const getSizeBySize = (size, chartSize, fact, hasSeries=false, moreThan=false) => {
    let margin, tickSize, annotationSize, dotR, strokeWidth, padding, tickWidth;
    switch (size) {
        case "wide":
            tickSize = 16;
            annotationSize = 26;
            dotR = 7;
            strokeWidth = 3;
            tickWidth = 2;
            break;
        case "small":
            tickSize = 12;
            annotationSize = 16;
            dotR = 5;
            strokeWidth = 2;
            tickWidth = 1.5;
            break;
        case "middle":
            tickSize = 14;
            annotationSize = 20;
            dotR = 6;
            strokeWidth = 3;
            tickWidth = 3;
            break;
        case "large":
        default:
            tickSize = 20;
            annotationSize = 40;
            dotR = fact === 'rank' ? 8 : 10;
            strokeWidth = 3;
            tickWidth = 3;
            break;
    }
    switch (fact) {
        case "extreme":
        case "outlier":
            padding = Math.round(20 * chartSize.width / 320)
            break
        case "trend":
            padding = Math.round(20 * chartSize.width / 320)
            break
        case "rank":
            padding = 0 // 20 * chartSize.width / 320
            break
        case "difference":
            padding = Math.round(20 * chartSize.width / 320)
            break
        default:
            padding = Math.round(20 * chartSize.width / 320)
            break
    }

    const chartMargin = {
        "small":{
            "top": 5,
            "right": fact === 'rank'? 22 :12,
            "bottom": hasSeries? fact==='rank' || fact==='difference'? 35 : 55 : 15,
            "left": fact === "rank"? 22 : 12
        },
        "middle":{
            "top":  10,
            "right": fact === 'rank' || fact === 'difference' ? 30  : 15,
            "bottom": hasSeries ? fact==='rank' || fact==='difference' ? 50 :80 : 20,
            "left": fact === "rank" ? 30 : fact==='difference' ? 50  : 15
        },
        "wide":{
            "top": 12,
            "right": hasSeries ? 160:20,// hasSeries?  moreThan ? 160 : 100 : 20,
            "bottom": 30,
            "left": hasSeries ? fact === 'rank' ? 20 +  20 * chartSize.width / 320 : fact === 'difference' ? 30 +  20 * chartSize.width / 320 : 10 : 20
            // fact === "difference" ? 30 : 20
        },
        "large":{
            "top": 30,
            "right": fact === "rank" ? 20+ 20 * chartSize.width / 320 : 30,
            "bottom": hasSeries ? 150 + 50 : 80 + 50,
            "left": fact === "rank" ? 20 + 20 * chartSize.width / 320 : fact === "difference" ? 80 :30
        }
    };
    margin = chartMargin[size] 
    return { margin: margin, tickSize: tickSize, annotationSize: annotationSize, dotR: dotR, strokeWidth: strokeWidth, padding: padding , tickWidth}
    
}


const initSvg = (container, width, height, margin) => {
    let svg = d3.select(container)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    return svg
}

const drawLines = (svg, seriesData, hasSeries, seriesName, lineGen, strokeWidth, isTrend = false) => {
    svg.append("g")
            .attr("class", "lines")
            // .selectAll(".line")
            // .data(seriesData)
            // .enter()
            .append("path")
            .attr("class", d => {return "line " + d.key.replace(/\s/g, "")})
            .attr("d", d => lineGen(d.values))
            .attr("stroke", d => hasSeries ? isTrend ? Color.CATEGORICAL[seriesName.indexOf(d.key)] : Color.CATEGORICAL[seriesName.indexOf(d.key)] : Color.DEFAULT)
            .attr("stroke-width", strokeWidth)
            .attr('fill', 'none');
}

const drawDots = (svg, seriesData, breakdown, measure, focus, hasSeries, seriesName, x, y, r, dotSolid, strokeWidth, Color, isTrend = false) => {
    let measureName = measure[0] && measure[0].aggregate === 'count' ? "COUNT" : measure[0].field

    svg.append("g")
    .attr("class", "dots")
    // .selectAll(".dot")
    // .data(seriesData)
    // .enter()
    // .append("g")
    .selectAll(".dot")
    .data(function(d){ return d.values; })
    .enter()
    .append("circle")
    .attr("class", d => hasSeries ? "dot " + d[breakdown[1].field].replace(/\s/g, "") : "dot All")
    .attr("id", d => focus.length !== 0 && d[breakdown[0].field] === focus[0].value ? "dotFocus" : null)
    .attr("cx", d=> x(parseTime(d[breakdown[0].field])))
    .attr("cy", d=>y(d[measureName]))
    .attr("r", r)
    .attr("fill", d => {
        if (!dotSolid) return "white";
        if(focus.length !== 0 && d[breakdown[0].field] === focus[0].value)  return Color.HIGHLIGHT;
        else if (hasSeries) return Color.CATEGORICAL[seriesName.indexOf(d[breakdown[1].field])]
        else return Color.DEFAULT
    })
    .attr("stroke", d=>{
        if(dotSolid) return "none";
        return Color.CATEGORICAL[seriesName.indexOf(d[breakdown[1].field])]
    })
    .attr("stroke-width", d=>{
        if(dotSolid) return 0;
        return strokeWidth
    });
}

// const drawArrow = (size, width) => {
//     let shift;
//     switch( size ) {
//         case "small":
//             shift = 3;
//             break;
//         case "middle":
//             shift = 4;
//             break;
//         case "wide":
//             shift = 4;
//             break;
//         case "large":
//         default:
//             shift = 5;
//             break;
//     }

//     let x0 = width;
//     let y0 = 0 + shift;
//     let x1 = width + shift / Math.tan(30 * Math.PI/180);
//     let y1 = 0;
//     let x2 = width;
//     let y2 = 0 - shift;

//     if(size === "small") {
//         return "M" + (x0 - shift) + "," + y0
//          + "L" + (x1 -shift) + "," + y1 
//          + "L" + (x2 - shift) + "," + y2
//         //  + "Z"
//     }
//     return "M" + x0 + "," + y0
//          + "L" + x1 + "," + y1
//          + "L" + x2 + "," + y2
//          + "Z"
// }

const sortByDateAscending = (breakdown) => {
    // Dates will be cast to numbers automagically:
    return function(a, b) {
        return parseTime(a[breakdown[0].field]) - parseTime(b[breakdown[0].field]);
    }
}

const setupXAsix = (seriesData, factData, breakdown, measure, padding, width, height, isCenter=false ) => {
    // get max Y value
    let measureName = measure[0] && measure[0].aggregate === 'count' ? "COUNT" : measure[0].field
    let maxYValue = d3.max(seriesData, d => {
        return d3.max(d.values, function(_d) {
            return _d[measureName];
          });
        //d[measureName]
    })
    let minYValue = d3.min(seriesData, d => {
        return d3.min(d.values, function(_d) {
            return _d[measureName];
          });
        // return d[measureName]
    })

    if(maxYValue === minYValue) maxYValue = 10

    let minDate =  d3.min(seriesData, d => {
        return d3.min(d.values, function(_d) {
            return parseTime(_d[breakdown[0].field]);
          });
    })
    let maxDate =  d3.max(seriesData, d => {
        return d3.max(d.values, function(_d) {
            return parseTime(_d[breakdown[0].field]);
          });
        // return d[measureName]
    })

    let x = d3.scaleTime()
        .range([0+padding, width-padding])
        .domain([minDate, maxDate])
    // .domain(d3.extent(factData, function(d) { 
    //     return parseTime(d[breakdown[0].field]);
    // }));

    let y;
    if(!isCenter) {
        y = d3.scaleLinear()
            .range([height, 0])
            .domain([minYValue < 0 ?minYValue:0, maxYValue]);
    } else { 
        y = d3.scaleLinear()
            .range([height, 0])
            .domain([minYValue < 0 ?minYValue:0, maxYValue+Math.abs(minYValue)*0.3]);
    }
    if(minYValue>=0) y.nice(5)
    
    return {x: x, y: y}
}

const drawXAsix = (seriesData, factData, breakdown, x, focus=null, rankAni=[]) => {
    // let tick_format = formatTick(factData[0][breakdown[0].field])
    let format_TicksCount = formatTicksCount(factData[0][breakdown[0].field]);

    let tick_format = formatTick(seriesData[0].values[seriesData[0].values.length - 1][breakdown[0].field]);

    let axisX;

    axisX = d3.axisBottom(x)
        // .ticks(d3.timeDay.filter(d => {return d3.timeDay.count(0, d) % 5 === 0}))
        // .ticks(possibleTick)
        // .tickValues(tickValues)
        .tickFormat(tick_format);
    if(format_TicksCount === d3.timeYear) {
            axisX.ticks(format_TicksCount)
    } 
    else if(format_TicksCount === d3.timeDay) {
        axisX.ticks(format_TicksCount)
    }
    if(focus) {
        // 如果不在当前的列表里，就添加
        let i;
        for(i=0; i<x.ticks().length; i++) {
            if(x.ticks()[i].toString() === parseTime(focus).toString()) break;
        }
        if(i === x.ticks().length) {
            let tickValues = x.ticks();
            tickValues.push(parseTime(focus))
            axisX.tickValues(tickValues)
        }
        
    }
    if(rankAni.length !== 0) {
        let ticksArray = rankAni.map(d=>d.getTime())

        let lastTick = x.domain()[1];
        let indexInShowed = ticksArray.indexOf(lastTick.getTime())
        let tickValues = rankAni.slice(0, indexInShowed+1);

        axisX.tickValues(tickValues);
    }
    
    return axisX
}

const removeOverlapX = (g, x) => {
    // get tick width;
    let tickWidth = 0;
    g.selectAll(".tick")
        .each(function(d, i) {
            let _thisWidth = d3.select(this).node().getBBox().width
            if(tickWidth < _thisWidth) tickWidth = _thisWidth;
        });
    tickWidth = tickWidth * 1.5;
    let tickCount = g.selectAll(".tick").size();
    let xAxisWidth = x.range()[1] - x.range()[0];
    
    if(x.range()[0] === 0) xAxisWidth = xAxisWidth * 0.8;
    else xAxisWidth = xAxisWidth * 0.9;
    let possibleTickCount =  Math.round((xAxisWidth) / tickWidth) + 1;
    let enough = tickCount * tickWidth >= xAxisWidth ? false : true;
    let largestInterval = Math.floor((tickCount%2? tickCount:tickCount-1) / (possibleTickCount - 1));
    // TODO 只有一个tick 显示首尾 但是如何避免真的两个都放不下呢？
    if(possibleTickCount === 1 && 2 * tickWidth < xAxisWidth) {
        let size = g.selectAll(".tick").size()
        g.selectAll(".tick")
        .each(function(d, i) {
            if(!enough &&  (i !== 0 && i !== size-1)) {
                this.remove();
            } 
        })
    } else {
        if((g.selectAll(".tick").size()-1) % Math.floor(tickCount/possibleTickCount) ===0) {
            // 最后一个保证会被显示
            // 间隔少
            g.selectAll(".tick")
            .each(function(d, i) {
                if(!enough && i % Math.floor(tickCount/possibleTickCount) !== 0) {
                    this.remove();
                } 
            })
        } else {
            // 最后一个不保证会被显示
            // 间隔尽量长少
            g.selectAll(".tick")
                .each(function(d, i) {
                    if(!enough && i % largestInterval !== 0 ) { 
                        this.remove();
                        // d3.select(this).attr("opacity", 0)
                    } 
                })
        }
        
    }
}

const paddingXAsix = (g, padding) => {
    let domainD = g.selectAll(".domain").attr("d");
    let start = domainD.split(",")[0].substr(1);
    let end = domainD.split("H")[1].split("V")[0];
    g.selectAll(".domain")
        .attr("stroke-width", 2)
        .attr('stroke', Color.AXIS)
        .attr('d', "M"+(+start-padding)+",0H"+(+end+padding));
}

const lineGeneration = (breakdown, measure, x, y) => {
    let measureName = measure[0] && measure[0].aggregate === 'count' ? "COUNT" : measure[0].field

    return d3.line()
            .x(function(d) {
                return x(parseTime(d[breakdown[0].field]));
            })
            .y(function(d) {
                return y(d[measureName]);
            });
}

const countTicksBeforeIndex = (animation, index) => {
    let count = 0;
    let visited = []
    for (let key in animation) {
        if(animation[key].index < index && visited.indexOf(animation[key].index) === -1) {
            count += animation[key].duration
            visited.push(animation[key].index)
        };
    }
    return count
}

const parseTime = (date) => {
    if (d3.timeParse("%Y-%m-%d")(date)) 
        return d3.timeParse("%Y-%m-%d")(date);
    else if (d3.timeParse("%Y/%m/%d")(date)) 
        return d3.timeParse("%Y/%m/%d")(date);
    else if (d3.timeParse("%Y-%m")(date)) 
        return d3.timeParse("%Y-%m")(date);
    else if (d3.timeParse("%Y/%m")(date)) 
        return d3.timeParse("%Y/%m")(date);
    else if (d3.timeParse("%Y")(date))
        return d3.timeParse("%Y")(date);
    else return date
} 
const formatTick = (date) => {
    if (d3.timeParse("%Y-%m-%d")(date)) 
        return d3.timeFormat("%Y-%-m-%-d");
    else if (d3.timeParse("%Y/%m/%d")(date))
        return d3.timeFormat("%Y/%-m/%-d") 
    else if (d3.timeParse("%Y-%m")(date))
        return d3.timeFormat("%Y-%m")
    else if (d3.timeParse("%Y/%m")(date))
        return d3.timeFormat("%Y/%m")
    else if (d3.timeParse("%Y")(date))
        return d3.timeFormat("%Y")
}

const formatTicksCount = (date) => {
    if (d3.timeParse("%Y-%m-%d")(date)) 
        return d3.timeDay
    else if (d3.timeParse("%Y/%m/%d")(date))
        return d3.timeDay
    else if (d3.timeParse("%Y-%m")(date))
        return d3.timeMonth
    else if (d3.timeParse("%Y/%m")(date))
        return d3.timeMonth
    else if (d3.timeParse("%Y")(date))
        return d3.timeYear
}

const getLeastSquares = (X, Y) => {
    let ret = {}

    let sumX = 0
    let sumY = 0
    let sumXY = 0
    let sumXSq = 0
    let N = X.length

    for (let i = 0; i < N; ++i) {
        sumX += X[i]
        sumY += Y[i]
        sumXY += X[i] * Y[i]
        sumXSq += X[i] * X[i]
    }

    ret.m = ((sumXY - sumX * sumY / N)) / (sumXSq - sumX * sumX / N)
    ret.b = sumY / N - ret.m * sumX / N

    return ret;
}


export default LineChart;